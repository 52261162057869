import Dexie from "dexie";

import generalConfig from "@/domains/local-db/global/general/config";

const STORE_NAME = "mem/global";
const STORE_VERSION = 1; // Must increment anytime schema is changed.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let store: any;

const pendingStore = new Promise((resolve, reject) => {
  const dexie = new Dexie(STORE_NAME, { chromeTransactionDurability: "relaxed" });

  dexie.version(STORE_VERSION).stores({
    [generalConfig.TABLE_NAME]: generalConfig.TABLE_SCHEMA,
  });

  dexie.open().then(db => {
    store = db;
    resolve(store);
  }, reject);
});

const getStore = async () => store || (await pendingStore);

const isAvailable = () => {
  if (!store) {
    return false;
  }

  return store.isOpen() && !store.hasFailed();
};

export { getStore, isAvailable };
