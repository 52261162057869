import React, { FC, useRef, MouseEvent } from "react";
import { css } from "@/domains/emotion";
import { ZIndex } from "@/domains/design/constants";
import { AnimatePresence, motion } from "framer-motion";

interface InterstitialOverlayProps {
  children?: React.ReactNode;
  delay?: number;
  isOpen?: boolean;
  handleClose?: () => void;
}

export const InterstitialOverlay: FC<InterstitialOverlayProps> = ({
  children,
  delay,
  isOpen = true,
  handleClose,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (handleClose && e.target === overlayRef.current) {
      handleClose();
    }
  };

  return (
    <AnimatePresence>
      {!!isOpen && (
        <motion.div
          className={wrapperStyles}
          onClick={handleClick}
          ref={overlayRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.12, ease: "easeInOut", delay: delay ?? 0 }}
          exit={{
            opacity: 0,
            transition: { delay: 0.08, duration: 0.12, ease: "easeInOut" },
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const wrapperStyles = css({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: ZIndex.InterstitialOverlay,
  color: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(2px)",
});
