import { generateKeyBetween } from "fractional-indexing";
import { orderBy } from "lodash-es";
import { computed, makeObservable, action } from "mobx";
import { DraggableLocation } from "@hello-pangea/dnd";
import { Uuid } from "@/domains/global/identifiers";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { FavoriteItemModelData } from "@/store/favorite-items/types";
import { AppSubStoreArgs } from "@/store/types";
import { UpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/favorites/UpdateFavoriteItemSortKeyOperation";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreFavoriteItemsStore extends BaseSyncModelStore<
  FavoriteItemObservable,
  FavoriteItemModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.FavoriteItem, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      getFavoriteItemObservableById: false,
      sortedFavoriteItems: computed,
      reorderFavoriteItems: action,
    });
  }

  createSyncModel(updateValue: SyncUpdateValue<FavoriteItemModelData>): FavoriteItemObservable {
    return new FavoriteItemObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  public getFavoriteItemObservableById({
    favoriteItemId,
  }: {
    favoriteItemId?: Uuid;
  }): FavoriteItemObservable | undefined {
    return favoriteItemId ? this.pool.get(favoriteItemId) : undefined;
  }

  get sortedFavoriteItems(): FavoriteItemObservable[] {
    return orderBy(
      Array.from(this.pool.values()).filter(e => !e.isItemTrashedOrDeleted),
      favoriteItem => favoriteItem.sortKey,
      "asc"
    );
  }

  reorderFavoriteItems = (destination: DraggableLocation, source: DraggableLocation) => {
    if (destination.index > this.sortedFavoriteItems.length) return;

    const sortOrder = (() => {
      if (destination.index > source.index) {
        // Key between the destination and the next item.
        const start = this.sortedFavoriteItems[destination.index].sortKey;
        const end = this.sortedFavoriteItems[destination.index + 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      } else if (destination.index < source.index) {
        // Key between the destination and the previous item.
        const end = this.sortedFavoriteItems[destination.index].sortKey;
        const start = this.sortedFavoriteItems[destination.index - 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      }
    })();

    const favoriteItem = this.sortedFavoriteItems[source.index];
    if (!favoriteItem || !sortOrder) return;

    new UpdateFavoriteItemSortKeyOperation({
      store: this.store,
      payload: {
        favorite_item_id: favoriteItem.id,
        sort_key: sortOrder,
      },
    }).execute();
  };
}
