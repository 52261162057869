import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors } from "@/design-system/foundations/colors";

export interface MdsHorizontalDividerProps extends EmotionClassStyles {}

const wrapperStyles = css({
  width: "100%",
  height: 1,
  backgroundColor: mdsColors().grey.x100,
});

export const MdsHorizontalDivider: FC<MdsHorizontalDividerProps> = ({ className }) => {
  const combinedStyles = cx(wrapperStyles, className);

  return <div className={combinedStyles} />;
};
