import { NoteViewer } from "@/components/note/viewer/NoteViewer";
import { css, keyframes } from "@/domains/emotion";
import { INoteObservable } from "@/store/note/types";
import { useAppStore } from "@/store/utils/hooks";
import { observer } from "mobx-react-lite";

interface QuickSearchModalNotePreviewProps {
  noteObservable?: INoteObservable;
}

export const QuickSearchModalNotePreview = observer(function QuickSearchModalNotePreview({
  noteObservable,
}: QuickSearchModalNotePreviewProps) {
  const { store } = useAppStore();
  if (!noteObservable) {
    return <div className={previewWrapperStyles} />;
  }

  return (
    <div className={previewWrapperStyles}>
      <NoteViewer
        className={noteViewerStyles}
        noteObservable={noteObservable}
        goToMention={store.navigation.goToMention}
        observeChanges={false}
      />
    </div>
  );
});

const previewWrapperStyles = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  margin: "16px auto 8px auto",
  overflow: "hidden",
  padding: "8px 20px 20px 20px",
  width: "clamp(0px, 640px, 100% - 8px)",
  height: "calc(32vh + 20px)",

  "@media (max-width: 900px)": {
    height: 0,
    visibility: "hidden",
  },
});

const growAndFadeAnimation = keyframes({
  from: {
    opacity: 0,
    transform: "scale(0.97)",
  },
  to: {
    opacity: 1,
    transform: "scale(1)",
  },
});

const noteViewerStyles = css({
  animation: `${growAndFadeAnimation} .2s ease-out`,
  backgroundColor: "white",
  border: "1px solid #f3f3f5",
  borderRadius: "16px",
  boxShadow: "0 8px 20px #454f6814",
  boxSizing: "border-box",
  flex: 1,
  height: "100%",
  overflow: "hidden",
  padding: "16px",
  overflowY: "auto",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});
