import { _parseUuidValue } from "@/modules/uuid/strings/parseValue";
import { isUndefined } from "lodash-es";
import { Uuid } from "@/domains/global/identifiers";

export const _maybeParseUuidValue = (value?: string): Uuid | undefined => {
  if (isUndefined(value)) {
    return undefined;
  }

  return _parseUuidValue(value);
};
