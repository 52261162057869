import { action, computed, makeObservable, observable } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";

export class MakeCollectionPrivateModalStore extends AppSubStore {
  collectionId: Uuid | undefined = undefined;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      collectionId: observable,
      collection: computed,
      isOpen: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get isOpen() {
    return !!this.collection;
  }

  get collection() {
    if (!this.collectionId) return undefined;
    return this.store.collections.get(this.collectionId);
  }

  public handleSubmit() {
    if (!this.collection) return;
    this.collection.makePrivate();
    this.close();
  }

  public open({ collectionId }: { collectionId?: Uuid }) {
    this.collectionId = collectionId;
  }

  public close() {
    this.collectionId = undefined;
  }
}
