export type CriticalErrorHandler = ({ message }: { message: string }) => void;

export enum CriticalErrorCode {
  DBConnectionLostMustRefresh = "DBConnectionLostMustRefresh",
  DBConnectionIsClosing = "DBConnectionIsClosing",
  DiskQuotaExceeded = "DiskQuotaExceeded",
  IncompatibleLocalDbVersion = "IncompatibleLocalDbVersion",
  ReadOnlyDB = "ReadOnlyDB",
  ReopenDBTimeout = "ReopenDBTimeout",
  ProgramError = "ProgramError",
  MultipleFirestoreSnapshotSubscriptionFailuresLockingDexieAndApp = "MultipleFirestoreSnapshotSubscriptionFailuresLockingDexieAndApp",
  FirebasePermissionDenied = "FirebasePermissionDenied",
}
