import { MdsDropdownItem, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownButtonItemComponent } from "@/design-system/components/dropdown/MdsDropdownButtonItemComponent";
import { MdsDropdownDetailItemComponent } from "@/design-system/components/dropdown/MdsDropdownDetailItemComponent";
import { MdsDropdownDividerItemComponent } from "@/design-system/components/dropdown/MdsDropdownDividerItemComponent";
import { MdsDropdownScrollableSection } from "@/design-system/components/dropdown/types";
import { mdsColors, mdsFontSizes, mdsLineHeights } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { useVerticalScroll } from "@/domains/react/useVerticalScroll";
import styled from "@emotion/styled";
import { FC } from "react";

interface MdsDropdownItemComponentProps {
  className?: string;
  tooltipId: string;
  item: MdsDropdownItem;
  onOpenChange?: (value: boolean) => void;
  onHover?: ({ itemId }: { itemId?: string }) => void;
}

const MdsDropdownItemComponent: FC<MdsDropdownItemComponentProps> = ({
  className,
  tooltipId,
  item,
  onOpenChange,
  onHover,
}) => {
  switch (item.kind) {
    case MdsDropdownItemKind.Button: {
      return (
        <MdsDropdownButtonItemComponent
          className={className}
          item={item}
          onOpenChange={onOpenChange}
          tooltipId={tooltipId}
          onHover={onHover}
        />
      );
    }
    case MdsDropdownItemKind.Divider: {
      return <MdsDropdownDividerItemComponent className={className} item={item} />;
    }
    case MdsDropdownItemKind.Detail: {
      return <MdsDropdownDetailItemComponent className={className} item={item} />;
    }
    case MdsDropdownItemKind.Other: {
      return (
        <div className={cx(mdsDropdownItemContentDefaultStyles, className)}>{item.content}</div>
      );
    }
    case MdsDropdownItemKind.ScrollableSection: {
      return (
        <MdsDropdownScrollableSecionItemComponent
          item={item}
          tooltipId={tooltipId}
          onHover={onHover}
          onOpenChange={onOpenChange}
        />
      );
    }
  }
};

interface MdsDropdownScrollableSectionItemComponentProps {
  tooltipId: string;
  item: MdsDropdownScrollableSection;
  onOpenChange?: (value: boolean) => void;
  onHover?: ({ itemId }: { itemId?: string }) => void;
}

const MdsDropdownScrollableSecionItemComponent: FC<
  MdsDropdownScrollableSectionItemComponentProps
> = ({ tooltipId, item, onOpenChange, onHover }) => {
  const { scrollContainerRef, scrollState, handleScroll } = useVerticalScroll();
  return (
    <PositionRelative>
      <ScrollableSection
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className={item.className}
      >
        {item.items.map((item: MdsDropdownItem) =>
          mdsDropdownItemComponentMapper({
            item,
            onOpenChange,
            tooltipId,
            onHover,
            className: fullWidthStyles,
          })
        )}
      </ScrollableSection>
      {scrollState.topVisible && <FadeTop />}
      {scrollState.bottomVisible && <FadeBottom />}
    </PositionRelative>
  );
};

export const mdsDropdownItemComponentMapper = (args: {
  item: MdsDropdownItem;
  onOpenChange?: (value: boolean) => void;
  tooltipId: string;
  onHover?: ({ itemId }: { itemId?: string }) => void;
  className?: string;
}) => <MdsDropdownItemComponent {...args} key={args.item.id} />;

const mdsDropdownItemContentDefaultStyles = css({
  fontSize: mdsFontSizes().small,
  lineHeight: mdsLineHeights().small,
  color: mdsColors().grey.x600,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const fullWidthStyles = css({
  width: "100%",
});

const PositionRelative = styled.div({
  position: "relative",
  width: "100%",
});

const ScrollableSection = styled.div({
  overflowY: "auto",
  scrollbarWidth: "none",
  width: "100%",

  "::-webkit-scrollbar": {
    display: "none",
  },
});

const FadeBottom = styled.div({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "28px",
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.854167) 85.42%, #ffffff 100%)",
  userSelect: "none",
  pointerEvents: "none",
});

const FadeTop = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  height: "28px",
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.854167) 85.42%, #ffffff 100%)",
  transform: "rotate(180deg)",
  userSelect: "none",
  pointerEvents: "none",
});
