import { Uuid } from "@/domains/global/identifiers";
import { NoteObservable } from "@/store/note/NoteObservable";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";

export class SpaceAccountNoteObservable extends BaseSyncModel<SpaceAccountNoteModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountNoteModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      modelKind: computed,
      itemId: computed,
      item: computed,
      isItemTrashedOrDeleted: computed,
      lastViewedAt: computed,
      lastInteractedAt: computed,
    });
  }

  // PROPERTIES
  get modelKind() {
    return "SPACE_ACCOUNT_NOTE";
  }

  get itemId() {
    return this.modelData.note_id;
  }

  get item(): NoteObservable | undefined {
    return this.store.notes.getNoteObservableById({ noteId: this.itemId });
  }

  get isItemTrashedOrDeleted() {
    return (
      this.isDeleted ||
      !this.item ||
      this.item.isTrashed ||
      this.item.isDeleted ||
      !this.item.canAccess
    );
  }

  get lastViewedAt() {
    return this.modelData.last_viewed_at || "";
  }

  get lastInteractedAt() {
    return this.lastViewedAt;
  }
}
