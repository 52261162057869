import styled from "@emotion/styled";
import { inflect } from "inflection";

interface Props {
  count: number;
  description?: string;
}

export function CollectionItemSubtitle({ count, description }: Props) {
  return <Wrapper>{getCollectionItemSubtitle(count, description)}</Wrapper>;
}

const Wrapper = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.xxsmall,
}));

export const getCollectionItemSubtitle = (count: number, description?: string) => {
  if (count === 0) {
    if (description) {
      return description;
    }

    return "No items";
  }

  const countText = `${count} ${inflect("item", count)}`;

  if (!description) {
    return countText;
  }

  return `${countText} • ${description}`;
};
