import { FC, useCallback, useMemo, useState } from "react";
import { useAppStore } from "@/store";
import { StripeSubscriptionInterstitialOverlayContent } from "@/components/subscriptions/StripeSubscriptionInterstitialOverlayContent";
import { observer } from "mobx-react-lite";
import {
  STRIPE_SUBSCRIPTION_PLANS,
  StripeSubscriptionPlan,
} from "@/components/subscriptions/types";
import { PlatformSubscriptionBillingStrategy } from "@/store/platform-subscription/types";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { StripeFormPaymentSuccessHandler } from "@/components/subscriptions/form/types";

interface StripeSubscriptionInterstitialOverlayWrapper {
  isOpen: boolean;
  openedManually: boolean;
  onCloseInterstitialHandler: () => void;
}

export const StripeSubscriptionInterstitialOverlayWrapper: FC<StripeSubscriptionInterstitialOverlayWrapper> =
  observer(({ isOpen, onCloseInterstitialHandler, openedManually }) => {
    const { store } = useAppStore();

    const planConfig = useMemo(() => STRIPE_SUBSCRIPTION_PLANS, []);

    const plans = useMemo(() => Object.values(planConfig), [planConfig]);

    const [activePlan, setActivePlan] = useState<StripeSubscriptionPlan>(
      () => planConfig[PlatformSubscriptionBillingStrategy.Yearly]
    );

    useEffectOnMount(() => {
      store.platformSubscription.fetchStripeSetupIntent();
    });

    const stripeFormPaymentSuccessHandler: StripeFormPaymentSuccessHandler = useCallback(
      async ({ billingStrategy }) => {
        await store.platformSubscription.createAndChargeStripeSubscription({
          billingStrategy,
        });
      },
      [store.platformSubscription]
    );

    return (
      <StripeSubscriptionInterstitialOverlayContent
        isOpen={isOpen}
        openedManually={openedManually}
        onCloseInterstitialHandler={onCloseInterstitialHandler}
        setActivePlan={setActivePlan}
        activePlan={activePlan}
        plans={plans}
        stripeFormInfoState={store.platformSubscription.fetchStripeSetupIntentState}
        stripeFormPaymentSuccessHandler={stripeFormPaymentSuccessHandler}
        getHelpHandler={store.navigation.openExternalHelpAndSupport}
      />
    );
  });
