import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store";
import { ModalDefinition, MessageModal, ModalDefinitionKind } from "@/store/modals/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { isEqual } from "lodash-es";
import { action, makeObservable, observable, computed } from "mobx";

export class ModalsStore extends AppSubStore<AppStore> {
  queuedModals: ModalDefinition[] = [];

  constructor(args: AppSubStoreArgs<AppStore>) {
    super(args);

    makeObservable(this, {
      queuedModals: observable,
      addModal: action,
      removeModal: action,
      current: computed,
      message: computed,
      shareSheet: computed,
      syncError: computed,
    });
  }

  addModal(modal: ModalDefinition, opts?: { priority: boolean }) {
    if (!opts?.priority || !this.queuedModals.length) {
      this.queuedModals.push(modal);
    } else {
      // Show right after the active modal.
      this.queuedModals.splice(1, 0, modal);
    }
  }

  removeModal(modal: ModalDefinition) {
    this.queuedModals = this.queuedModals.filter(e => !isEqual(e, modal));
  }

  get current(): Maybe<ModalDefinition> {
    return this.queuedModals[0];
  }

  get message(): Maybe<MessageModal> {
    return this.current?.kind === ModalDefinitionKind.Message ? this.current.modal : undefined;
  }

  get shareSheet(): Maybe<ShareSheetModalStore> {
    return this.current?.kind === ModalDefinitionKind.ShareSheet ? this.current.store : undefined;
  }

  get syncError() {
    return this.current?.kind === ModalDefinitionKind.SyncError
      ? this.current.syncError
      : undefined;
  }
}
