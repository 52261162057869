import { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { SubscriptionPlanSelectorButtonContent } from "@/components/subscriptions/plan-selector/SubscriptionPlanSelectorButtonContent";
import { StripeSubscriptionPlan } from "@/components/subscriptions/types";

interface SubscriptionPlanSelectorButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  activePlan: StripeSubscriptionPlan;
  setActivePlan: (plan: StripeSubscriptionPlan) => void;
  plan: StripeSubscriptionPlan;
}

export const SubscriptionPlanSelectorButton: FC<SubscriptionPlanSelectorButtonProps> = observer(
  ({ plan, activePlan, setActivePlan, ...props }) => {
    const onClick = useCallback(() => {
      setActivePlan(plan);
    }, [setActivePlan, plan]);

    const isActive = useMemo(
      () => activePlan.billingStrategy === plan.billingStrategy,
      [activePlan, plan.billingStrategy]
    );

    return (
      <SubscriptionPlanSelectorButtonContent
        priceText={plan.priceText}
        captionText={plan.captionText}
        bannerText={plan.bannerText}
        onClick={onClick}
        isActive={isActive}
        {...props}
      />
    );
  }
);
