import { moduleFactory } from "@/factories/module";
import {
  parseHomeParams,
  stringifyHomeParams,
} from "@/modules/url-params/home-params/transformation";
import {
  parseSearchEngineParams,
  stringifySearchEngineParams,
} from "@/modules/url-params/search-engine-params/transformation";

export const urlParamsModule = moduleFactory.create({
  home: {
    parse: parseHomeParams,
    stringify: stringifyHomeParams,
  },
  search: {
    parse: parseSearchEngineParams,
    stringify: stringifySearchEngineParams,
  },
});
