import { appRoutes } from "@/app/router";
import localDb from "@/domains/local-db";
import { localStorageModule } from "@/modules/local-storage";

/**
 * @TODO - Right now this just uses the authToken from the global config.
 * In the future, we should use the authenticated account-id instead.
 */
export const getApiAuthIdentifier = async (): Promise<string | null> => {
  const authInfo = await localDb.global.getCurrentAuthInfo();
  const accountId = authInfo?.accountId;

  if (!accountId) {
    return null;
  }

  return accountId;
};

/**
 * Force a log-out.
 * (In the future, it could make sense to redirect to a page like "your session has expired", ...)
 */
export const forceLogOutNotAuthenticatedUser = async (): Promise<void> => {
  await localStorageModule.writePendingRedirectPath({
    pathname: location.pathname,
  });

  const logOutPath = appRoutes.logOut({}).path;

  /**
   * We use `window` directly (rather than useNavigate) because we don't have
   * access to react when we're calling this function.
   */
  window.location.assign(logOutPath);

  return;
};
