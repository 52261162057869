export interface IAsyncData<Data = unknown> {
  readonly data?: Data;
  readonly dataLoadedAt?: Date;
  readonly isLoading?: boolean;
  readonly failedAt?: Date;
}

export interface AsyncData<Data = unknown> extends IAsyncData<Data> {
  fromCache: (data: Data) => AsyncData<Data>;
  loading: () => AsyncData<Data>;
  loaded: (data: Data) => AsyncData<Data>;
  failed: () => AsyncData<Data>;
}

const AsyncDataPrototype = {
  fromCache: asyncDataSinkFromCache,
  loading: asyncDataLoading,
  loaded: asyncDataLoaded,
  failed: asyncDataFailed,
};

function wrapAsyncData<Data>(data: IAsyncData<Data>): AsyncData<Data> {
  return Object.create(
    Object.freeze({
      data: data.data,
      dataLoadedAt: data.dataLoadedAt,
      isLoading: data.isLoading,
    }),
    AsyncDataPrototype
  );
}

export function buildAsyncData<Data>(data: Omit<IAsyncData<Data>, "failedAt">) {
  return wrapAsyncData({
    data: data.data,
    dataLoadedAt: data.dataLoadedAt,
    isLoading: data.isLoading,
  });
}

export function asyncDataSinkFromCache<Data>(asyncData: IAsyncData<Data>, data: Data) {
  return wrapAsyncData({
    ...asyncData,
    data: data,
  });
}

export function asyncDataLoading<Data>(asyncData: IAsyncData<Data>) {
  return wrapAsyncData({
    ...asyncData,
    isLoading: true,
    failedAt: undefined,
  });
}

export function asyncDataLoaded<Data>(asyncData: IAsyncData<Data>, data: Data) {
  return wrapAsyncData({
    ...asyncData,
    data: data,
    dataLoadedAt: new Date(),
    isLoading: false,
    failedAt: undefined,
  });
}

export function asyncDataFailed<Data>(asyncData: IAsyncData<Data>) {
  return wrapAsyncData({
    ...asyncData,
    isLoading: false,
    failedAt: new Date(),
  });
}
