/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MdsButtonProps,
  MdsButton,
  MdsButtonVariant,
  MdsButtonSize,
} from "@/design-system/components/button";
import { MdsButtonShape } from "@/design-system/components/button/types";
import {
  MdsDropdownButtonProps,
  MdsDropdownButtonItem,
  MdsDropdownButton,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { ReactNode, memo } from "react";

export type MdsDropdownSelectorProps = Pick<
  MdsDropdownButtonProps,
  "className" | "contentList" | "iconKind"
> & {
  label: MdsButtonProps["label"];
  variant?: MdsButtonProps["variant"];
  isDisabled?: MdsButtonProps["isDisabled"];
  onResetSelection?: MdsButtonProps["onClick"];
  selectedIconKind?: MdsButtonProps["iconKind"] | null;
  selectedId?: string;
};

export const MdsDropdownSelector = memo<MdsDropdownSelectorProps>(
  ({
    className,
    contentList,
    iconKind: inputIconKind,
    label,
    variant,
    isDisabled,
    onResetSelection,
    selectedIconKind,
    selectedId,
  }) => {
    const selected = contentList.items.find((e: any) => e.id === selectedId) as
      | MdsDropdownButtonItem
      | undefined;
    if (selected?.label) {
      const iconKind = (() => {
        if (selectedIconKind === null) return;

        if (selectedIconKind) return selectedIconKind;

        if (selected.iconKind) return selected.iconKind;

        if (typeof inputIconKind === "function") {
          const ret = inputIconKind(!!selectedId);
          if (Object.values(MdsIconKind).includes(ret as MdsIconKind)) {
            const MdsIconKind = ret as MdsIconKind;
            return MdsIconKind;
          }

          if (ret) {
            const reactNode = ret as ReactNode;
            return () => reactNode;
          }
        }

        if (inputIconKind) {
          return inputIconKind as MdsIconKind;
        }
      })();

      return (
        <MdsButton
          className={className}
          variant={variant ?? MdsButtonVariant.OutlinedAccent}
          label={selected.label}
          iconKind={iconKind}
          isDisabled={isDisabled}
          shape={MdsButtonShape.Round}
          dropdown
          onClick={onResetSelection}
        />
      );
    }
    return (
      <MdsDropdownButton
        className={className}
        contentList={contentList}
        variant={
          variant ??
          ((isOpen: any) => (isOpen ? MdsButtonVariant.FilledDark : MdsButtonVariant.Outlined))
        }
        size={MdsButtonSize.Medium}
        label={label}
        shape={MdsButtonShape.Round}
        iconKind={inputIconKind}
        isDisabled={isDisabled}
        dropdownIcon
      />
    );
  }
);
