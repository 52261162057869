import React, { ReactNode, FC } from "react";

import { ToggleAnimator } from "@/design-system/components/animation/ToggleAnimator";
import { AnimationConfig } from "@/design-system/components/animation/types";
import { EmotionClassStyles } from "@/domains/emotion/types";

export interface LoadingAnimatorProps extends EmotionClassStyles {
  loading: boolean;
  loadingElement: ReactNode;
  readyElement: ReactNode;
  animationConfig?: AnimationConfig;
}

/**
 * Starts by showing the `loadingElement`.
 *
 * When `loading` flips from false to true, we start fading out `loadingElement`.
 *
 * After `loadingElement` fades out, we hide it (display=none) then begin fading-in
 * the `readyElement`.
 */
export const LoadingAnimator: FC<LoadingAnimatorProps> = ({
  loading,
  loadingElement,
  readyElement,
  ...rest
}) => {
  return (
    <ToggleAnimator
      {...rest}
      toggled={!loading}
      falseElement={loadingElement}
      trueElement={readyElement}
    />
  );
};
