import { useMemo, FC } from "react";

import {
  MdsSkeletonLoaderKind,
  MdsSkeletonLoaderShape,
} from "@/design-system/components/loader/types";
import { css, cx, keyframes } from "@/domains/emotion";
import { mdsBorderRadius } from "@/design-system/foundations/common";

export interface MdsSkeletonLoaderProps {
  className?: string;
  kind?: MdsSkeletonLoaderKind;
  height?: number | string;
  width?: number | string;
  shape?: MdsSkeletonLoaderShape;
}

const wrapperStyles = css({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
});

export const MdsSkeletonLoader: FC<MdsSkeletonLoaderProps> = ({
  className,
  height = 15,
  width = "100%",
  shape = MdsSkeletonLoaderShape.Rectangle,
}) => {
  const skeletonLoaderShapeStylesMapping: {
    [key in MdsSkeletonLoaderShape]: string;
  } = {
    [MdsSkeletonLoaderShape.Circle]: css({
      borderRadius: mdsBorderRadius().maximum,
      width: height,
    }),
    [MdsSkeletonLoaderShape.Square]: css({
      borderRadius: 4,
      width: height,
    }),
    [MdsSkeletonLoaderShape.Rectangle]: css({
      borderRadius: 8,
      width: width,
    }),
  };
  const skeletonShapeStyles = skeletonLoaderShapeStylesMapping[shape];

  const skeletonLoaderStyles = useMemo(() => {
    const shimmerAnimation = keyframes({
      "100%": {
        transform: `translateX(100%)`,
      },
    });
    /**
     * Implementation inspired by: https://codepen.io/JCLee/pen/dyPejGV
     */
    return css({
      display: "inline-block",
      position: "relative",
      overflow: "hidden",
      backgroundColor: "#DDDBDD",
      height: height,

      "&::after": {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform: "translateX(-100%)",
        backgroundImage:
          "linear-gradient(90deg, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0.0) 100%)",
        animation: `${shimmerAnimation} 2s infinite`,
        content: "''",
        animationDelay: "0s",
      },
    });
  }, [height]);

  const combinedStyles = cx(skeletonShapeStyles, skeletonLoaderStyles, className);

  return (
    <div className={wrapperStyles}>
      <span className={combinedStyles} />
    </div>
  );
};
