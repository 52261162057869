import Icons from "@/assets/icons/Icons";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { FacetFilterDefinition } from "@/store/pages/SearchPageStore/types";

export const linkKindDefs: Record<string, FacetFilterDefinition> = {
  Airbnb: {
    icon: <Icons.Airbnb />,
    dropdownLabel: "Airbnb",
    buttonLabel: "With Airbnb links",
  },
  Amazon: {
    icon: <Icons.Amazon viewBox="0 0 17 16" fontSize="16px" style={{ fontSize: "16px" }} />,
    dropdownLabel: "Amazon",
    buttonLabel: "With Amazon links",
  },
  Asana: {
    icon: <Icons.Asana />,
    dropdownLabel: "Asana",
    buttonLabel: "With Asana links",
  },
  Docusign: {
    icon: <Icons.Docusign />,
    dropdownLabel: "Docusign",
    buttonLabel: "With Docusign links",
  },
  Dropbox: {
    icon: <Icons.Dropbox />,
    dropdownLabel: "Dropbox",
    buttonLabel: "With Dropbox links",
  },
  Figma: {
    icon: <Icons.Figma viewBox="0 0 16 16" fontSize="16px" style={{ fontSize: "16px" }} />,
    dropdownLabel: "Figma",
    buttonLabel: "With Figma links",
  },
  Giphy: {
    icon: <Icons.Giphy viewBox="0 0 16 16" fontSize="16px" style={{ fontSize: "16px" }} />,
    dropdownLabel: "Giphy",
    buttonLabel: "With Giphy links",
  },
  GitHub: {
    icon: <Icons.GitHub />,
    dropdownLabel: "GitHub",
    buttonLabel: "With GitHub links",
  },
  "Google Docs": {
    icon: <Icons.GoogleDocs viewBox="0 0 17 16" fontSize="18px" style={{ fontSize: "18px" }} />,
    dropdownLabel: "Google Docs",
    buttonLabel: "With Google Docs links",
  },
  "Google Drive": {
    icon: <Icons.GoogleDrive viewBox="0 0 17 16" fontSize="18px" style={{ fontSize: "18px" }} />,
    dropdownLabel: "Google Drive",
    buttonLabel: "With GoogleDrive links",
  },
  "Google Sheets": {
    icon: <Icons.GoogleSheets viewBox="0 0 17 16" fontSize="18px" style={{ fontSize: "18px" }} />,
    dropdownLabel: "Google Sheets",
    buttonLabel: "With GoogleSheets links",
  },
  "Google Slides": {
    icon: <Icons.GoogleSlides viewBox="0 0 17 16" fontSize="18px" style={{ fontSize: "18px" }} />,
    dropdownLabel: "Google Slides",
    buttonLabel: "With GoogleSlides links",
  },
  LinkedIn: {
    icon: <Icons.LinkedIn />,
    dropdownLabel: "LinkedIn",
    buttonLabel: "With LinkedIn links",
  },
  Loom: {
    icon: <Icons.Loom />,
    dropdownLabel: "Loom",
    buttonLabel: "With Loom links",
  },
  Twitter: {
    icon: <Icons.Twitter />,
    dropdownLabel: "Twitter",
    buttonLabel: "With Twitter links",
  },
  Yelp: {
    icon: <Icons.Yelp />,
    dropdownLabel: "Yelp",
    buttonLabel: "With Yelp links",
  },
  YouTube: {
    icon: <Icons.YouTube />,
    dropdownLabel: "YouTube",
    buttonLabel: "With YouTube links",
  },
};

export const mediaKindDefs: Record<string, FacetFilterDefinition> = {
  image: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Image} />,
    dropdownLabel: "Images",
    buttonLabel: "Contains Images",
  },
  gif: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Gif} />,
    dropdownLabel: "GIFs",
    buttonLabel: "Contains GIFs",
  },
  video: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Film} />,
    dropdownLabel: "Videos",
    buttonLabel: "Contains Videos",
  },
  opentask: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.CheckboxEmpty} />,
    dropdownLabel: "Open Tasks",
    buttonLabel: "Contains Open Tasks",
  },
  task: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Checkbox} />,
    dropdownLabel: "All Tasks",
    buttonLabel: "Contains Tasks",
  },
  code: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Code} />,
    dropdownLabel: "Code",
    buttonLabel: "Contains Code",
  },
  quote: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Quote} />,
    dropdownLabel: "Quotes",
    buttonLabel: "Contains Quotes",
  },
  pdf: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Pdf} />,
    dropdownLabel: "PDFs",
    buttonLabel: "Contains PDFs",
  },
  link: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.LinkSolid} />,
    dropdownLabel: "Links",
    buttonLabel: "Contains Links",
  },
};

export const captureSourceDefs: Record<string, FacetFilterDefinition> = {
  data_import: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Import} />,
    dropdownLabel: "From Imports",
    buttonLabel: "Imports",
  },
  sms: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.PhoneSms} />,
    dropdownLabel: "From Texts",
    buttonLabel: "Texts",
  },
  email: {
    icon: <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Envelope} />,
    dropdownLabel: "From Emails",
    buttonLabel: "Emails",
  },
  app: {
    icon: <Icons.Mem />,
    dropdownLabel: "From App",
    buttonLabel: "App",
  },
};
