import styled from "@emotion/styled";
import { api } from "@/modules/api";
import { useAsync } from "@/modules/use-async";
import { MdsLink } from "@/mds";

interface Props {
  chatConversationId: string;
}

export function Error({ chatConversationId }: Props) {
  const { isProcessing, result, invoke } = useAsync({
    fn: apiFn,
  });

  const showRetry = !isProcessing && !result;
  const retry = () => invoke([chatConversationId]);

  return (
    <Wrapper>Error sending reply. {showRetry && <MdsLink onPress={retry}>Retry</MdsLink>}</Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  fontSize: "15px", // following chat content font size
  color: theme.colors.grey.x500,
  lineHeight: theme.lineHeights.medium,
}));

const apiFn = (chatConversationId: string) =>
  api.post("/v2/chat-conversations/{chat_conversation_id}/retry", {
    params: {
      path: {
        chat_conversation_id: chatConversationId,
      },
    },
  });
