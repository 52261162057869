import { BackendApiClientType } from "@/manager/backend-api-client/types";
import { BackendApiErrorKind } from "@/modules/api/types";
import { validationModule } from "@/modules/validation";

/**
 * Using `GET` as an example, but this function should work with any method.
 */
type UnknownResponse = Awaited<ReturnType<BackendApiClientType["GET"]>>;

export const responseMatchesBackendApiErrorKind = ({
  response,
  errorKind,
}: {
  response: UnknownResponse;
  errorKind: BackendApiErrorKind;
}) => {
  if (!validationModule.objectHasKey(response, "error")) {
    return false;
  }

  const errorInfo = response.error as unknown;

  if (!validationModule.objectHasKey(errorInfo, "error_metadata")) {
    return false;
  }

  const errorMetadata = errorInfo.error_metadata;

  if (!validationModule.objectHasKey(errorMetadata, "error_kind")) {
    return false;
  }

  return errorMetadata.error_kind === errorKind;
};
