import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { NoteModelData, NoteUpsertedSyncUpdateValue } from "@/store/note/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { ITrashNoteOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class TrashNoteOperation extends BaseSyncOperation<ITrashNoteOperation> {
  get operationKind(): SyncOperationKind {
    return "TRASH_NOTE";
  }

  generateOptimisticUpdates() {
    const existingNote = this.store.notes.get(this.payload.id);
    if (!existingNote) return [];

    const value: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: existingNote.modelVersion,
      model_data: {
        ...existingNote.modelData,
        trashed_at: this.committedAt,
      },
      model_scopes: existingNote.modelScopes,
    };
    const syncUpdate: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  execute() {
    const existingNote = this.store.notes.get(this.payload.id);
    if (!existingNote) return;
    super.execute();
  }

  get successToastMessage() {
    if (!this.store.notes.get(this.payload.id)?.isOwnedByMe || !this.triggerSuccessToast) {
      return null;
    }
    return (
      <>
        {this.mediumTitle} moved to the{" "}
        <a onClick={() => this.store.navigation.goToTrash()}>Trash</a>
      </>
    );
  }

  get title() {
    return this.store.notes.get(this.payload.id)?.title || UNTITLED_NOTE_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be moved to the trash. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to move {this.mediumTitle} to the trash. Try removing the note
        instead.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be moved to the trash. If this error continues, please contact
        support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
