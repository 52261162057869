const TABLE_NAME = "sync";
const TABLE_SCHEMA = "";

const NOTE_CONTENT_PRELOADING_STATE_KEY = "note_content_preloading_state";

export default {
  TABLE_NAME,
  TABLE_SCHEMA,
  NOTE_CONTENT_PRELOADING_STATE_KEY,
};
