import { BaseError } from "@/domains/errors";
import { objectModule } from "@/modules/object";
import { isObjectLike } from "lodash-es";

export const parseUnknownErrorIntoBaseError = ({
  unknownError,
}: {
  unknownError: unknown;
}): BaseError => {
  if (unknownError instanceof BaseError) {
    return unknownError;
  }

  if (unknownError instanceof Error) {
    return new BaseError({
      message: unknownError.message,
      originalError: unknownError,
    });
  }

  if (typeof unknownError === "string") {
    return new BaseError({
      message: unknownError,
    });
  }

  if (typeof unknownError === "number") {
    return new BaseError({
      message: String(unknownError),
    });
  }

  if (isObjectLike(unknownError)) {
    return new BaseError({
      message: objectModule.safeToJson({ unknownError }),
    });
  }

  try {
    const stringError = String(unknownError);

    return new BaseError({
      message: stringError,
    });
  } catch {
    return new BaseError({
      message: "[parseUnknownError] Failed to parse source error.",
    });
  }
};
