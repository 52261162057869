import { observer } from "mobx-react-lite";
import { MdsButton } from "@/design-system/components/button";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

export const SettingsSubscriptionManagementPage = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.settingsSubscriptionManagementPage;

  useEffectOnMount(() => {
    if (page.isSubscribed) page.fetchBillingPortalUrl();
  });

  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Manage your subscription">
        <div className={sectionStyles}>
          <MdsText size={MdsTextSize.Small} color={mdsColors().grey.x700}>
            Subscribe to Mem below. If you&#39;re subscribed, you can view and manage your billing
            details below.
          </MdsText>
          {page.isSubscribed && (
            <MdsButton
              label="Manage subscription"
              isLoading={page.fetchBillingPortalUrlState.loading}
              isDisabled={page.billingPortalDisabled}
              onClick={() => page.openBillingPortal()}
            />
          )}
          {!page.isSubscribed && (
            <MdsButton label="Subscribe to Mem" onClick={() => page.openSubscriptionModal()} />
          )}
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

const sectionStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 16,
});
