import deepEqual from "deep-equal";

export const _FORCE_KEYS: string[] = [
  // useContextRecentlyChanged only works if this property is always overwritten:
] as const;

export const mergeUpdates = (
  originalObject = Object.create(null),
  updates: Record<string, unknown>
) => {
  const object = { ...originalObject };

  for (const fieldPath in updates) {
    const updatedFieldValue = updates[fieldPath];

    const fieldPathSegments = fieldPath.split(".");

    if (!_FORCE_KEYS.includes(fieldPath)) {
      const leafObject = fieldPathSegments.reduce(
        (o, fieldPathSegment) => (o ? o[fieldPathSegment] : undefined),
        object
      );
      if (deepEqual(leafObject, updatedFieldValue, { strict: true })) {
        // Keep intact parts of the object tree that were not changed.
        // This is important so components that depend only on some properties
        // (e.g, contexts), can avoid re-rendering when other properties change
        // (e.g, body or viewers).
        continue;
      }
    }

    if (fieldPathSegments.length === 1) {
      object[fieldPath] = updatedFieldValue;
      continue;
    }

    let previousObject = object;
    let previousObjectOriginal = originalObject;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let fieldPathObject: any;
    let fieldPathObjectOriginal;

    let finalSegment: string;

    for (const fieldPathSegment of fieldPathSegments) {
      fieldPathObject = previousObject;
      fieldPathObjectOriginal = previousObjectOriginal;

      finalSegment = fieldPathSegment;

      if (
        fieldPathObject[finalSegment] === undefined ||
        fieldPathObject[finalSegment] === fieldPathObjectOriginal[finalSegment]
      ) {
        fieldPathObject[finalSegment] = {
          ...fieldPathObjectOriginal[finalSegment],
        };
      }

      previousObject = fieldPathObject[finalSegment];
      previousObjectOriginal = fieldPathObjectOriginal[finalSegment] || Object.create(null);
    }

    /** This was added as part of the JS -> TS migration. */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fieldPathObject[finalSegment] = updatedFieldValue;

    // When updating an object some of its children might not have changed.
    // But even though it's possible to improve the algorithm to keep parts of
    // the original subtree as well, optimizing components to only re-render
    // when an specific todo or contexts.topicFamilies item is updated does not
    // seem as important right now.
  }

  return object;
};
