import { makeAutoObservable, computed } from "mobx";

import { MdsItemKind } from "@/design-system/components/item-list/types";
import { AppStore } from "@/store";
import { FloatingNotePreview } from "@/design-system/components/item-list/rows/item-preview/FloatingNotePreview";
import { HoveringState } from "@/design-system/components/item-list/rows/item-preview/HoveringState";
import { IItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/types";

export class ItemPreviewState implements IItemPreviewState {
  private store: AppStore;
  private id: string;
  private kind: MdsItemKind;
  private hoveringOverTarget: HoveringState;
  private hoveringOverPreview: HoveringState;

  constructor({ store, id, kind }: { store: AppStore; id: string; kind: MdsItemKind }) {
    this.store = store;
    this.id = id;
    this.kind = kind;

    this.hoveringOverTarget = new HoveringState();
    this.hoveringOverPreview = new HoveringState();

    makeAutoObservable(this, {
      visible: computed,
      previewUi: computed,
    });
  }

  get enabled() {
    return this.store.publicAppStore.interface.isHoverable && this.kind === MdsItemKind.Note;
  }

  get visible() {
    return this.enabled && (this.hoveringOverTarget.hovering || this.hoveringOverPreview.hovering);
  }

  get note() {
    if (!this.visible || this.kind !== MdsItemKind.Note) return;

    return this.store.notes.getNoteObservableById({ noteId: this.id });
  }

  get anchorPreviewAtTheEnd() {
    return this.store.publicAppStore.interface.isScreenNarrowForRegularNotePreviews;
  }

  get previewUi() {
    return <FloatingNotePreview noteObservable={this.note} state={this.hoveringOverTarget} />;
  }

  get handleMouseEnter() {
    if (!this.enabled) return undefined;
    return this.hoveringOverTarget.handleMouseEnter;
  }

  get handleMouseLeave() {
    if (!this.enabled) return undefined;
    return this.hoveringOverTarget.handleMouseLeave;
  }
}
