import { GLOBAL_ENV } from "@/modules/global-state/globalEnv";

export const clientEnvPackageVersion = (): string | null => {
  const globalPackageVersion = GLOBAL_ENV.PACKAGE_VERSION;

  if (!globalPackageVersion) {
    console.warn("[clientEnvPackageVersion] No package version found in GLOBAL_ENV.");

    return null;
  }

  return globalPackageVersion;
};
