import table from "@/domains/local-db/account/sync/adapter";
import config from "@/domains/local-db/account/sync/config";
import { PreloadingState } from "@/store/sync/types";

export const setNoteContentPreloadingState = (noteContentPreloadingState: PreloadingState) => {
  return Promise.all([
    table.set(config.NOTE_CONTENT_PRELOADING_STATE_KEY, {
      [config.NOTE_CONTENT_PRELOADING_STATE_KEY]: noteContentPreloadingState,
    }),
  ]);
};

export const clear = () => table.clearAll();
