import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespace } from "@/modules/uuid/sync-models/resolveSyncModelNamespace";
import { SyncModelKind } from "@/store/sync/types";
import { v5 as uuidv5 } from "uuid";

export const resolvePrimaryChatConversationSyncModelUuid = ({
  spaceAccountId,
}: {
  spaceAccountId: Uuid;
}): Uuid => {
  const uppercaseSpaceAccountId = spaceAccountId.toUpperCase();
  const namespace = resolveSyncModelNamespace({
    modelKind: { value: SyncModelKind.ChatConversation },
  });
  const name = `PRIMARY::${uppercaseSpaceAccountId}`;
  return uuidv5(name, namespace);
};
