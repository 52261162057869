import { action, computed, makeObservable, observable } from "mobx";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { toastModule } from "@/modules/toast";

export class DeleteCollectionModalStore extends AppSubStore {
  private onClose?: () => void;
  public isOpen: boolean = false;
  public collectionId: Uuid | null = null;

  constructor(injectedDeps: AppSubStoreArgs, { onClose }: { onClose?: () => void }) {
    super(injectedDeps);

    this.onClose = onClose;

    makeObservable<this, "onClose">(this, {
      onClose: false,
      isOpen: observable,
      collectionId: observable,
      collection: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get collection(): CollectionObservable | undefined {
    if (!this.collectionId) return undefined;
    return this.store.collections.getCollectionObservableById({
      collectionId: this.collectionId,
    });
  }

  public handleSubmit() {
    if (!this.collection) return;
    const collectionTitle = this.collection.title;
    this.collection.delete();
    this.onClose?.();
    this.store.navigation.goToCollections();
    toastModule.triggerToast({ content: `${collectionTitle} deleted.` });
  }

  public open({ collectionId }: { collectionId?: Uuid }) {
    if (!collectionId) return;
    this.collectionId = collectionId;
    this.isOpen = true;
  }

  public close() {
    this.collectionId = null;
    this.isOpen = false;
    this.onClose?.();
  }
}
