import { SyncQueueItem, SyncQueueItemKind } from "@/store/sync/types";

export interface SerializedSyncQueueItem {
  kind: SyncQueueItemKind;
  stringifiedData: string;
}

export function serializeSyncQueueItem({ kind, data }: SyncQueueItem): SerializedSyncQueueItem {
  return { kind, stringifiedData: JSON.stringify(data) };
}

export function deserializeSyncQueueItemData(
  stringifiedData: SerializedSyncQueueItem["stringifiedData"]
) {
  return JSON.parse(stringifiedData);
}
