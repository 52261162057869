export const GOOGLE_IDENTITY_ACCOUNT_URL = `https://accounts.google.com`;

export const GOOGLE_IDENTITY_OAUTH_URL = `${GOOGLE_IDENTITY_ACCOUNT_URL}/o/oauth2/v2/auth`;

/**
 * Note that this client id needs to match the server configuration for
 * the memmy client.
 */
export const GOOGLE_IDENTITY_WEB_CLIENT_ID =
  "229790426180-tpd59ta6jrk51cttnnv5mkn2i7siqk5p.apps.googleusercontent.com";
