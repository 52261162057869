import { generateRecentDateString } from "@/domains/date/date";
import { renderArrayWithAnds } from "@/domains/string/renderArrayWithAnds";
import { IContact } from "@/store/contacts/types";
import { INoteObservable } from "@/store/note/types";

export const getNoteMetadata = ({
  authorNames,
  note,
  updateOnly,
}: {
  authorNames?: string;
  note: INoteObservable;
  updateOnly?: boolean;
}) => {
  return `${generateRecentDateString(note.createdAt)}${updateOnly ? "" : ` | ${generateRecentDateString(note.modifiedAt)}`} — ${authorNames ?? getAuthorNames({ note })}`;
};

export const getContactDisplayNames = ({
  contacts,
}: {
  contacts: Pick<IContact, "profileDisplayName">[];
}) => {
  return renderArrayWithAnds(contacts.map(e => e.profileDisplayName));
};

export const getAuthorNames = ({ note }: { note: INoteObservable }) => {
  return getContactDisplayNames({ contacts: note.authors });
};
