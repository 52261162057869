import { makeObservable, observable } from "mobx";
import { DateTime } from "luxon";
import { PlatformTrialState } from "@/store/platform-info/types";
import { ApiSchema } from "@/modules/api";
import { dateTimeModule } from "@/modules/date-time";

export class PlatformTrialInfoObservable {
  trialEnd: DateTime | null;
  trialState: PlatformTrialState;

  constructor({
    trialEnd,
    trialState,
  }: {
    trialEnd: DateTime | null;
    trialState: PlatformTrialState;
  }) {
    this.trialEnd = trialEnd;
    this.trialState = trialState;

    makeObservable(this, {
      trialEnd: observable,
      trialState: observable,
    });
  }

  public static createFromRemoteData({ data }: { data: ApiSchema["PlatformInfoTrial"] }) {
    const trialEnd: DateTime | null = (() => {
      if (!data.trial_end) {
        return null;
      }

      return dateTimeModule.parseValue(data.trial_end);
    })();

    return new PlatformTrialInfoObservable({
      trialEnd,
      trialState: data.trial_state as PlatformTrialState,
    });
  }
}
