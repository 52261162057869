import { CurrentEnvironment, LoggerInstance } from "@/domains/logging/types";
import { datadogLoggingClientManager } from "@/manager/datadog-logging-client";
import { objectModule } from "@/modules/object";
import { Logger } from "@datadog/browser-logs";
import { noop } from "lodash-es";

export const initializeLoggerClient = async () => {
  /**
   * The datadog logging client needs to be initialized first.
   */
  const datadogLoggingClient = datadogLoggingClientManager.instance();

  let env: CurrentEnvironment | undefined;

  type ConsoleLogFun = Console["error"] | Console["warn"] | Console["info"] | Console["debug"];
  type DatadogLoggerFun = Logger["error"] | Logger["warn"] | Logger["info"] | Logger["debug"];

  const logAnSendToDatadog = async (
    message: string,
    info: Record<string, unknown>,
    person: unknown,
    consoleLogFun: ConsoleLogFun,
    datadogLoggerFun?: DatadogLoggerFun,
    isError = false
  ) => {
    const consoleLogDetails = objectModule.compactNullOrUndefined({
      ...info,
      person,
    });
    consoleLogFun(message, consoleLogDetails);

    if (!datadogLoggerFun) return;

    const datadogLogDetails = objectModule.compactNullOrUndefined({
      ...info,
      person,
      env,
    });

    /**
     * We also send the error to Datadog.
     */
    const errorObj = (() => {
      if (!isError) return;

      const errorObj = Error(message);
      const stackInfo = objectModule.safeToJson(datadogLogDetails);
      errorObj.stack = `${errorObj.stack}\n\nInfo: ${stackInfo}`;
      return errorObj;
    })();
    datadogLoggerFun(message, datadogLogDetails, errorObj);
  };

  const datadogLoggerInfo = datadogLoggingClient.logger.info.bind(datadogLoggingClient.logger);
  const datadogLoggerWarn = datadogLoggingClient.logger.warn.bind(datadogLoggingClient.logger);
  const datadogLoggerError = datadogLoggingClient.logger.error.bind(datadogLoggingClient.logger);

  const loggerClient: LoggerInstance = {
    setEnvironment(environment: CurrentEnvironment) {
      env = environment;
    },
    trace: async ({ message, info = {}, person }) => {
      logAnSendToDatadog(message, info, person, console.trace);
    },
    debug: async ({ message, info = {}, person }) => {
      logAnSendToDatadog(message, info, person, console.debug);
    },
    info: async ({ message, info = {}, person }) => {
      logAnSendToDatadog(message, info, person, console.info, datadogLoggerInfo);
    },
    warn: async ({ message, info = {}, person }) => {
      logAnSendToDatadog(message, info, person, console.warn, datadogLoggerWarn);
    },
    error: async ({ message, info = {}, person }) => {
      logAnSendToDatadog(message, info, person, console.error, datadogLoggerError, true);
    },
    flushLogs: async () => {
      return noop();
    },
  };

  return loggerClient;
};
