import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntryWithStatus,
  SyncModelScope,
  SyncScopeRoleKind,
} from "@/domains/sync-scopes/types";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";
import { GrantNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/GrantNoteAclViaEmailAddressOperation";
import { GrantNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/GrantNoteAclViaSpaceAccountOperation";
import { RevokeNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaEmailAddressOperation";
import { RevokeNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaSpaceAccountOperation";
import { UpdateNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaSpaceAccountOperation";
import { GrantCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaEmailAddressOperation";
import { GrantCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaSpaceAccountOperation";
import { RevokeCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaEmailAddressOperation";
import { RevokeCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaSpaceAccountOperation";
import { UpdateCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaEmailAddressOperation";
import { UpdateCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaSpaceAccountOperation";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import { UpdateNoteAclViaCollectionOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaCollectionOperation";
import { AppStore, GuestAppStore } from "@/store";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";

export function getLabelForRoleKind(roleKind: SyncScopeRoleKind) {
  if (roleKind === "OWNER") return "Owner";
  if (roleKind === "EDITOR") return "Can edit";
  if (roleKind === "VIEWER") return "Can view";
  if (roleKind === "REVOKED") return "No access";
}

export function getRoleNameForRoleKind(roleKind: SyncScopeRoleKind) {
  if (roleKind === "OWNER") return "Owner";
  if (roleKind === "EDITOR") return "Editor";
  if (roleKind === "VIEWER") return "Viewer";
  if (roleKind === "ADMIN") return "Admin";
  if (roleKind === "CONTRIBUTOR") return "Contributor";
  if (roleKind === "DIRECT_CONTACT" || roleKind === "INDIRECT_CONTACT") return "Contact";
  if (roleKind === "REVOKED") return "Revoked";
  return "User";
}

export function generateSyncScopePermissionEntryWithStatusFromSyncOperation(
  operation: SyncOperationGeneric
): SyncModelPermissionEntry {
  switch (operation.operationKind) {
    case "GRANT_NOTE_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as GrantNoteAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as GrantNoteAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "UPDATE_NOTE_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as UpdateNoteAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "UPDATE_NOTE_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as UpdateNoteAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "UPDATE_NOTE_ACL_VIA_COLLECTION": {
      const grantAclOperation = operation as UpdateNoteAclViaCollectionOperation;
      return {
        scope_kind: "COLLECTION_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        collection_id: grantAclOperation.payload.collection_id,
      };
    }
    case "REVOKE_NOTE_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as RevokeNoteAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: "REVOKED",
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as RevokeNoteAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: "REVOKED",
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "GRANT_COLLECTION_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as GrantCollectionAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "GRANT_COLLECTION_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as GrantCollectionAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "UPDATE_COLLECTION_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as UpdateCollectionAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "UPDATE_COLLECTION_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as UpdateCollectionAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: grantAclOperation.payload.role_kind,
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "REVOKE_COLLECTION_ACL_VIA_SPACE_ACCOUNT": {
      const grantAclOperation = operation as RevokeCollectionAclViaSpaceAccountOperation;
      return {
        scope_kind: "SPACE_ACCOUNT_SCOPE",
        role_kind: "REVOKED",
        space_account_id: grantAclOperation.payload.space_account_id,
      };
    }
    case "REVOKE_COLLECTION_ACL_VIA_EMAIL_ADDRESS": {
      const grantAclOperation = operation as RevokeCollectionAclViaEmailAddressOperation;
      return {
        scope_kind: "EMAIL_SCOPE",
        role_kind: "REVOKED",
        email_address: grantAclOperation.payload.email_address,
      };
    }
    case "ADD_NOTE_TO_COLLECTION": {
      const addNoteToCollectionOperation = operation as AddNoteToCollectionOperation;
      return {
        scope_kind: "COLLECTION_SCOPE",
        role_kind: "EDITOR", // TODO: This should be based on operation
        collection_id: addNoteToCollectionOperation.payload.collection_id,
      };
    }
    case "REMOVE_NOTE_FROM_COLLECTION": {
      const removeNoteFromCollectionOperation = operation as RemoveNoteFromCollectionOperation;
      return {
        scope_kind: "COLLECTION_SCOPE",
        role_kind: "REVOKED",
        collection_id: removeNoteFromCollectionOperation.payload.collection_id,
      };
    }
    default: {
      throw new Error(
        "[SyncScopePermissionEntryWithStatusFromSyncOperation] Invalid SyncOperation"
      );
    }
  }
}

export function generateSyncScopePermissionEntryWithStatusFromSyncModelScope(
  syncModelScope: SyncModelScope
): SyncModelPermissionEntry {
  if ("space_account_id" in syncModelScope.value) {
    return {
      scope_kind: "SPACE_ACCOUNT_SCOPE",
      role_kind: syncModelScope.role_kind,
      space_account_id: syncModelScope.value.space_account_id,
    };
  }
  if ("collection_id" in syncModelScope.value) {
    return {
      scope_kind: "COLLECTION_SCOPE",
      role_kind: syncModelScope.role_kind,
      collection_id: syncModelScope.value.collection_id,
    };
  }
  if ("space_id" in syncModelScope.value) {
    return {
      scope_kind: "SPACE_SCOPE",
      role_kind: syncModelScope.role_kind,
      space_id: syncModelScope.value.space_id,
    };
  }
  if ("guest_account_id" in syncModelScope.value) {
    return {
      scope_kind: "GUEST_ACCOUNT_SCOPE",
      role_kind: syncModelScope.role_kind,
      guest_account_id: syncModelScope.value.guest_account_id,
    };
  }
  throw new Error("Invalid SyncModelScope");
}

export function getPermissionEntryDisplayLabel(
  store: AppStore | GuestAppStore,
  entry: SyncModelPermissionEntryWithStatus
): string {
  // TODO: Revisit implementation when Guest Mode is implemented
  if (entry.scope_kind === "SPACE_ACCOUNT_SCOPE") {
    const spaceAccountId = entry.space_account_id;
    if (store instanceof AppStore) {
      const spaceAccountContact =
        store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);
      return spaceAccountContact?.profileDisplayName || "User";
    } else {
      const spaceAccount =
        store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);
      return spaceAccount?.profileDisplayName || "User";
    }
  }
  if (entry.scope_kind === "COLLECTION_SCOPE") {
    if (store instanceof AppStore) {
      const collection = store.collections.getCollectionObservableById({
        collectionId: entry.collection_id,
      });
      const collectionMetadata =
        store.collectionMetadata.getCollectionMetadataObservableByCollectionId(entry.collection_id);
      return collection?.title || collectionMetadata?.title || UNTITLED_COLLECTION_TITLE;
    } else {
      return "Unknown Collection";
    }
  }
  if (entry.scope_kind === "EMAIL_SCOPE") {
    return entry.email_address;
  }
  return "Unknown";
}

export function sortSyncScopePermissionEntries(
  store: AppStore | GuestAppStore,
  entries: SyncModelPermissionEntryWithStatus[]
): SyncModelPermissionEntryWithStatus[] {
  const ownerEntries = entries.filter(e => e.role_kind === "OWNER");
  const nonOwnerEntries = entries
    .filter(e => e.role_kind !== "OWNER")
    .sort((a, b) =>
      getPermissionEntryDisplayLabel(store, a).localeCompare(
        getPermissionEntryDisplayLabel(store, b)
      )
    );
  return [...ownerEntries, ...nonOwnerEntries];
}
