import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface AccountInfoDetailsProps {
  profileEmailAddress: string;
}

export const AccountInfoDetails: FC<AccountInfoDetailsProps> = observer(
  ({ profileEmailAddress }) => {
    return (
      <div className={wrapperStyles}>
        <span className={emailStyles}>{profileEmailAddress}</span>
      </div>
    );
  }
);

const wrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  flexWrap: "nowrap",
  fontSize: 12,
  lineHeight: "20px",
  marginTop: 1,
  whiteSpace: "nowrap",
});

const emailStyles = css({
  color: "#696E7D",
  maxWidth: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
