import { useContext } from "react";
import { useState } from "react";

import {
  AppStoreContext,
  GuestAppStoreContext,
  PublicAppStoreContext,
} from "@/store/utils/context";
import { AppStore } from "@/store/AppStore";

import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { pusherClientModule } from "@/modules/pusher-client";
import { RuntimeBaseError } from "@/domains/errors";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { PublicAppStore } from "@/store/PublicAppStore";
import { PageStore } from "@/store/PageStore";
import { GuestAppStore } from "@/store/GuestAppStore";
import { PrivateAppStore } from "@/store";
import { backendApiClientManager } from "@/manager/backend-api-client";
import { logger } from "@/modules/logger";
import { api } from "@/modules/api";

let _hotPrivateAppStore: PrivateAppStore | null = null;

export const useInitializeAppStore = ({
  publicAppStore,
}: {
  publicAppStore: PublicAppStore;
}): AsyncResult<PrivateAppStore> => {
  const [client, setClient] = useState<PrivateAppStore | null>();

  useEffectOnMount(() => {
    const asyncLogic = async () => {
      /**
       * If hot-reloading is enabled, we can keep the same store
       * between reloads to keep the state consistent.
       */
      if (import.meta.hot) {
        // set the global API client's auth token after HMR
        api.setAuthToken(publicAppStore.auth.activeAuthToken);

        if (_hotPrivateAppStore) {
          setClient(_hotPrivateAppStore);
          return;
        }
      }

      const apiClient = await backendApiClientManager.initialize({});
      const pusherClient = await pusherClientModule.initialize();

      const store = new AppStore({
        publicAppStore,
        api: apiClient,
        pusher: pusherClient,
      });

      await store.initialize();

      const pageStore = new PageStore({
        store,
        publicAppStore,
        api: apiClient,
        pusher: pusherClient,
      });

      setClient({ store, pageStore });

      if (import.meta.hot) {
        _hotPrivateAppStore = { store, pageStore };
      }
    };

    asyncLogic();
  });

  if (!client) {
    return asyncResultModule.setLoading();
  }

  return asyncResultModule.setReady(client);
};

export const useAppStore = (): PrivateAppStore => {
  const client = useContext(AppStoreContext);

  if (!client) {
    throw new RuntimeBaseError({
      message: `"AppStoreContext" was not initialized!`,
    });
  }

  return { store: client.store, pageStore: client.pageStore };
};

export const useInitializePublicAppStore = (): AsyncResult<PublicAppStore> => {
  const [client, setClient] = useState<PublicAppStore | null>();

  useEffectOnMount(() => {
    const asyncLogic = async () => {
      if (client) {
        return;
      }

      const apiClient = await backendApiClientManager.initialize({});
      const pusherClient = await pusherClientModule.initialize();

      const store = new PublicAppStore({ api: apiClient, pusher: pusherClient });
      await store.initialize();

      setClient(store);
    };

    asyncLogic();
  });

  if (!client) {
    return asyncResultModule.setLoading();
  }

  return asyncResultModule.setReady(client);
};

export const usePublicAppStore = (): { publicStore: PublicAppStore } => {
  const publicStore = useContext(PublicAppStoreContext);

  if (!publicStore) {
    throw new RuntimeBaseError({
      message: `"PublicAppStoreContext" was not initialized!`,
    });
  }

  return { publicStore: publicStore };
};

export const useInitializeGuestAppStore = ({
  publicAppStore,
}: {
  publicAppStore: PublicAppStore;
}): AsyncResult<GuestAppStore> => {
  const [client, setClient] = useState<GuestAppStore | null>();

  useEffectOnMount(() => {
    const asyncLogic = async () => {
      const apiClient = await backendApiClientManager.initialize({});
      const pusherClient = await pusherClientModule.initialize();

      const store = new GuestAppStore({
        publicAppStore,
        api: apiClient,
        pusher: pusherClient,
      });

      await store.initialize();

      setClient(store);
    };

    asyncLogic();
  });

  if (!client) {
    return asyncResultModule.setLoading();
  }

  return asyncResultModule.setReady(client);
};

export const useGuestAppStore = (): { guestStore: GuestAppStore } => {
  const publicStore = useContext(GuestAppStoreContext);

  if (!publicStore) {
    throw new RuntimeBaseError({
      message: `"GuestAppStoreContext" was not initialized!`,
    });
  }

  return { guestStore: publicStore };
};

/**
 * If we have modified the store directly, we just want to force a hard refresh of the page.
 * (Instead of allowing the store to be hot-reloaded).
 */
if (import.meta.hot) {
  /**
   * This is a set of modules which we want to force a hard-refresh on.
   */
  import.meta.hot.accept(
    ["../PageStore", "../PublicAppStore", "../GuestAppStore", "../AppStore", "../index"],
    newModule => {
      if (newModule) {
        logger.debug({
          message: "[HMR] Stores modified - forcing page reload...",
        });

        window.location.reload();
      }
    }
  );
}
