import { useState } from "react";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import styled from "@emotion/styled";

interface SearchResultDebugInfoProps {
  rawScoreFromSource: number;
  rerankerScore: number;
  preAdjustedScore: number;
  source: string;
}

const SearchResultDebugInfo: React.FC<SearchResultDebugInfoProps> = ({
  rawScoreFromSource,
  rerankerScore,
  preAdjustedScore,
  source,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const HoverableContainer = styled.div({
    position: "relative",
    display: "inline-block",
  });

  const DebugInfoContainer = styled.div<{ isHovered: boolean }>(({ isHovered }) => ({
    visibility: isHovered ? "visible" : "hidden",
    backgroundColor: "#555",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    padding: "5px",
    position: "absolute",
    zIndex: 1000,
    bottom: "150%",
    left: "50%",
    transform: "translateX(-50%)",
    opacity: isHovered ? 1 : 0,
    transition: "opacity 0.3s, visibility 0.3s",
    whiteSpace: "nowrap",
    fontSize: "12px",
  }));

  return (
    <HoverableContainer onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <MdsIconButton iconKind={MdsIconKind.InfoCircle} />
      <DebugInfoContainer isHovered={isHovered}>
        <div>
          <div>
            <strong>Raw Score:</strong> {rawScoreFromSource}
          </div>
          <div>
            <strong>Reranker Score:</strong> {rerankerScore}
          </div>
          <div>
            <strong>Pre-Adjusted Score:</strong> {preAdjustedScore}
          </div>
          <div>
            <strong>Source:</strong> {source}
          </div>
        </div>
      </DebugInfoContainer>
    </HoverableContainer>
  );
};

export default SearchResultDebugInfo;
