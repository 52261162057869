import { GlobalPusherClientConfig } from "@/modules/pusher-client/types";

export const GLOBAL_PUSHER_CLIENT_CONFIG: GlobalPusherClientConfig = {
  pusherInstance: undefined,
};

/**
 * This is safe to expose to clients.
 */
export const GLOBAL_PUSHER_APP_KEY = "5a5b85460184abfef1ed";

export const GLOBAL_PUSHER_APP_CLUSTER = "us3";
