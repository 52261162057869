import { actions } from "@/actions";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { MakeCollectionPrivateModalStore } from "@/components/modal/make-collection-private/MakeCollectionPrivateStore";
import { RemoveNotesFromSharedCollectionModalStore } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
  MdsSharedByVariant,
} from "@/design-system/components/item-list/types";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { getObjectAfterDeletion } from "@/hooks/useObjectAfterDeletion";
import { lensModule, notesLensModule } from "@/modules/lenses";
import { SortByKind } from "@/modules/lenses/types";
import { uuidModule } from "@/modules/uuid";
import { resolveCollectionItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveCollectionItemSyncModelUuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { NoteObservable } from "@/store/note/NoteObservable";
import { LeaveCollectionMessageModalStore } from "@/store/pages/CollectionsViewPageStore/LeaveCollectionMessageModalStore";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { MountedStore } from "@/store/pages/MountedStore";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable, reaction } from "mobx";

interface CollectionsViewPageParams {
  sortBy: SortByKind;
}

export class CollectionsViewPageStore extends AppSubStore {
  params: CollectionsViewPageParams;

  supportsAddToCollectionListAction = true;
  supportsRemoveFromCollectionListAction = true;

  listState: ListStateObservable;
  deleteCollectionModal: DeleteCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  removeNotesFromSharedCollectionModal: RemoveNotesFromSharedCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;
  makeCollectionPrivateModal: MakeCollectionPrivateModalStore;
  leaveCollectionMessageModal: LeaveCollectionMessageModalStore;

  mountState = new MountedStore();

  /** This is a proxy with isDeleted=true after the note is gone. */
  collectionObservable: Maybe<CollectionObservable>;
  couldAccessNoteOnMount: Maybe<boolean>;

  shareSheetEntityId: Uuid | undefined;
  shareSheetEntityKind: ShareSheetEntityKind | undefined;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: SortByKind.LastModified };

    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });
    this.deleteCollectionModal = new DeleteCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.removeNotesFromSharedCollectionModal = new RemoveNotesFromSharedCollectionModalStore(
      injectedDeps,
      {}
    );
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.makeCollectionPrivateModal = new MakeCollectionPrivateModalStore(injectedDeps);

    makeObservable<this, "generateItemDropdown">(this, {
      params: observable,
      supportsAddToCollectionListAction: false,
      supportsRemoveFromCollectionListAction: false,

      listState: false,
      deleteCollectionModal: false,
      deleteSharedNotesModal: false,
      removeNotesFromSharedCollectionModal: false,
      shareSheetModal: false,
      makeCollectionPrivateModal: false,
      leaveCollectionMessageModal: false,
      mountState: false,

      collectionObservable: observable,
      couldAccessNoteOnMount: observable,
      shareSheetEntityId: observable,
      shareSheetEntityKind: observable,

      setCollectionObservable: action,
      setCouldAccessNoteOnMount: action,

      amIMounted: computed,
      showNotFound: computed,
      canAccessCollection: computed,
      collection: computed,
      collectionMultiSelectActionsContext: computed,
      submitChatMessage: action,
      generateItemDropdown: false,
      collectionItemRows: computed,
      orderedItemIds: computed,

      headerDropdownButtonContentList: computed,
      handleCreateNewNote: action,
      useEffects: false,

      // PAGE PARAMS
      setParams: action,
      setSortBy: action,
      sortBy: computed,
      sortLabel: computed,
      sortOptions: computed,
    });

    // We need this to be already observable to react to changes its observable props.
    this.leaveCollectionMessageModal = new LeaveCollectionMessageModalStore(this);

    reaction(
      () => ({ collection: this.collection, amIMounted: this.amIMounted }),
      ({ collection: maybeCollection, amIMounted }, { collection: previousCollection }) => {
        const collection = getObjectAfterDeletion({
          current: maybeCollection,
          previous: previousCollection,
          active: amIMounted,
        });
        this.setCollectionObservable(collection);
      }
    );

    reaction(
      () => this.collectionObservable?.canAccess,
      (canAccess, previousCanAccess) => {
        const couldAccessNoteOnMount = (() => {
          // Getting access later is fine.
          if (canAccess) return true;
          // Prefer the previous value if it's already set.
          if (typeof previousCanAccess === "boolean") return previousCanAccess;
          // Either undefined (_unknown_) or false.
          return canAccess;
        })();
        this.setCouldAccessNoteOnMount(couldAccessNoteOnMount);
      }
    );
  }

  setCollectionObservable(collectionObservable?: CollectionObservable) {
    this.collectionObservable = collectionObservable;
  }

  setCouldAccessNoteOnMount(couldAccessNoteOnMount?: boolean) {
    this.couldAccessNoteOnMount = couldAccessNoteOnMount;
  }

  get amIMounted() {
    return this.mountState.isMounted;
  }

  get showNotFound() {
    if (!this.couldAccessNoteOnMount) return true;

    if (this.leaveCollectionMessageModal.isModalOpen) return false;

    return !this.canAccessCollection;
  }

  get canAccessCollection(): boolean {
    // Return true when it's unknown.
    if (!this.mountState.isMounted || !this.store.routing.collectionIdParam) return true;

    const doesNotExist = this.store.collections.doesNotExist(this.store.routing.collectionIdParam);
    if (doesNotExist) return false;

    const canAccess = this.store.collections.getCollectionObservableById({
      collectionId: this.store.routing.collectionIdParam,
    })?.canAccess;

    return canAccess !== false;
  }

  get collection(): Maybe<CollectionObservable> {
    if (!this.mountState.isMounted) return;
    const collectionId = this.store.routing.collectionIdParam;
    if (!collectionId) return;
    return this.store.collections.getCollectionObservableById({ collectionId });
  }

  get collectionMultiSelectActionsContext() {
    return this.collection;
  }

  submitChatMessage = async ({ markdownContent }: { markdownContent: string }) => {
    // TODO: add correct context.
    await this.store.chatMessages.sendNewMessage(markdownContent);
  };

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: NoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `remove-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Remove note from collection",
          onClick: () => {
            if (!this.collection) return;
            if (this.collection.isShared) {
              this.removeNotesFromSharedCollectionModal.open({
                notes: [noteObservable],
                collection: this.collection,
              });
            } else {
              actions.removeNoteFromCollection({
                note: noteObservable,
                collection: this.collection,
                store: this.store,
              });
            }
          },
          iconKind: MdsIconKind.Exit,
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
            }),
          iconKind: MdsIconKind.Share,
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: () => noteObservable.toggleFavorite(),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Delete",
          onClick: () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              noteObservable.moveToTrash();
            }
          },
          iconKind: MdsIconKind.Trash,
        },
      ],
    };
  }

  get collectionItemRows(): MdsItemListRowData[] {
    if (!this.collection) return [];

    const output: MdsItemListRowData[] = [];

    if (this.collection.canWrite) {
      output.push({
        key: "add-note-to-collection",
        type: MdsItemListRowType.ActionButton,
        payload: {
          label: "Create note in collection",
          icon: MdsIconKind.ComposeInline,
          onClick: () => this.handleCreateNewNote(),
          listState: this.listState,
        },
      });
    }

    const collectionId = this.collection.id;
    this.collection.itemList.allItems
      .filter(note => note.isAvailable)
      .sort(notesLensModule.sortFunctionMap[this.sortBy])
      .forEach(item => {
        const collectionItemId = resolveCollectionItemSyncModelUuid({
          collectionId,
          itemId: item.id,
        });
        const collectionItemObservable = this.store.collectionItems.get(collectionItemId);
        output.push({
          key: item.id,
          type: MdsItemListRowType.Item,
          size: MdsItemListSize.XLarge,
          payload: {
            id: item.id,
            kind: MdsItemKind.Note,
            createPreviewState: () =>
              new ItemPreviewState({
                store: this.store,
                id: item.id,
                kind: MdsItemKind.Note,
              }),
            label: item.title,
            onClick: () => this.store.navigation.goToNote({ noteId: item.id }),
            sharedBy: collectionItemObservable?.addedToCollectionBy,
            sharedByVariant: MdsSharedByVariant.AddedToCollectionBy,
            dateLabel: notesLensModule.dateLabelMap[this.sortBy](item),
            dropdown: this.generateItemDropdown({ noteObservable: item }),
            listState: this.listState,
            extraRows: [
              {
                id: `note-${item.id}-content`,
                content: () => (
                  <MdsItemListRowFeaturedContent
                    collections={sortCollectionsForChips(item.collectionList.allCollections)}
                    snippet={
                      item.collectionList.allCollections.length
                        ? undefined
                        : [{ text: item.secondaryTitle || "No additional text" }]
                    }
                  />
                ),
              },
            ],
          },
        });
      });
    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });
    return output;
  }

  get orderedItemIds() {
    return this.collectionItemRows.map(row => row.key);
  }

  // HEADER
  get headerDropdownButtonContentList(): MdsDropdownContentList {
    const ownerActions: MdsDropdownItem[] = this.collection?.isOwnedByMe
      ? [
          { id: "collection-divider", kind: MdsDropdownItemKind.Divider },
          {
            id: "delete-collection",
            kind: MdsDropdownItemKind.Button,
            label: "Delete",
            onClick: () => this.deleteCollectionModal.open({ collectionId: this.collection?.id }),
            iconKind: MdsIconKind.Trash,
          },
        ]
      : [];

    if (this.collection?.isShared && this.collection.isOwnedByMe) {
      ownerActions.splice(1, 0, {
        id: "make-collection-private",
        kind: MdsDropdownItemKind.Button,
        label: "Make this collection private",
        onClick: () => this.makeCollectionPrivateModal.open({ collectionId: this.collection?.id }),
        iconKind: MdsIconKind.Lock,
      });
    }

    return {
      items: [
        {
          id: "copy-collection-link",
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            if (!this.collection) return;
            actions.copyCollectionLinkToClipboard({ collectionId: this.collection.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        ...ownerActions,
      ],
    };
  }

  public handleCreateNewNote() {
    if (!this.collection) return;
    const noteId = uuidModule.generate();
    this.store.notes.createNote({ noteId });
    const noteObservable = this.store.notes.getNoteObservableById({ noteId });
    if (!noteObservable) return;
    noteObservable.collectionList.addCollection({ collectionId: this.collection.id });
    this.store.navigation.goToNote({ noteId: noteId });
  }

  useEffects() {
    this.mountState.useEffects();
  }

  // PAGE PARAMS
  setParams = (params: CollectionsViewPageParams) => {
    this.params = params;
  };

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ ...this.params, sortBy: itemId as SortByKind });
  };

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: SortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastModified],
          isChecked: this.sortBy === SortByKind.LastModified,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastCreated],
          isChecked: this.sortBy === SortByKind.LastCreated,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastViewed],
          isChecked: this.sortBy === SortByKind.LastViewed,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.Alphabetical,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.Alphabetical],
          isChecked: this.sortBy === SortByKind.Alphabetical,
          onClick: this.setSortBy,
        },
      ],
    };
  }
}
