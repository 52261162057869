import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { ZIndex } from "@/domains/design/constants";
import {
  autoUpdate,
  FloatingNode,
  FloatingPortal,
  safePolygon,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingOptions,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { memo, useEffect, useMemo } from "react";

interface MdsFloatingDropdownContentProps {
  className?: string;
  clientRect?: DOMRect;
  contentListClassName?: string;
  contentList: MdsDropdownContentList;
  placement?: UseFloatingOptions["placement"];
  onHover?: ({ isHovering, itemId }: { isHovering?: boolean; itemId?: string }) => void;
}

export const MdsFloatingDropdownContent = memo<MdsFloatingDropdownContentProps>(
  function ActionsDropdown({
    className,
    clientRect,
    contentListClassName,
    contentList,
    placement,
    onHover,
  }) {
    const nodeId = useFloatingNodeId();

    const { x, y, refs, strategy, context } = useFloating({
      whileElementsMounted: autoUpdate,
      nodeId,
      open: true,
      onOpenChange: value => {
        if (value) {
          /** We don't want to open on hover states. */
          return;
        }

        // (onOpenChange)(false);
      },
      placement: placement ?? "bottom-start",
    });

    useEffect(() => {
      if (!clientRect) return;

      refs.setPositionReference({
        getBoundingClientRect() {
          return clientRect;
        },
      });
    }, [refs, clientRect]);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(context, {
        move: false,
        handleClose: safePolygon(),
        delay: {
          close: 100,
        },
      }),
      useDismiss(context),
    ]);

    const portalContent = useMemo(() => {
      return (
        <div
          className={className}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: ZIndex.Dropdown,
          }}
          {...getFloatingProps()}
        >
          <MdsDropdownContent
            className={contentListClassName}
            contentList={contentList}
            onHover={onHover}
          />
        </div>
      );
    }, [
      className,
      contentList,
      contentListClassName,
      getFloatingProps,
      onHover,
      refs.setFloating,
      strategy,
      x,
      y,
    ]);

    if (contentList.items.length === 0) return null;

    return (
      <FloatingNode id={nodeId}>
        <div ref={refs.setReference} {...getReferenceProps()}></div>
        <FloatingPortal>{portalContent}</FloatingPortal>
      </FloatingNode>
    );
  }
);
