import * as Y from "yjs";
import { toUint8Array, fromUint8Array } from "js-base64";

export function mergeDiffsWithRemoteContent(remoteContent: string | null, diffs: string[]): string {
  const ydoc = new Y.Doc();
  if (remoteContent) Y.applyUpdate(ydoc, toUint8Array(remoteContent));
  for (const diff of diffs) Y.applyUpdate(ydoc, toUint8Array(diff));
  const newEncodedContent = Y.encodeStateAsUpdate(ydoc);
  return fromUint8Array(newEncodedContent);
}
