import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { ChatMessageContext, ChatMessageContextKind } from "@/store/chat/types";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

interface ChatContextProps {
  className?: string;
  context?: ChatMessageContext;
}

export const ChatContext = observer<ChatContextProps>(function ChatContext({ className, context }) {
  const contextTitle = getContextTitle({ context });
  if (!contextTitle) return null;
  return (
    <Context className={className}>
      In
      <StyledMdsIcon
        kind={
          context?.kind === ChatMessageContextKind.CollectionDetailView
            ? MdsIconKind.Collection
            : MdsIconKind.Document
        }
      />
      {contextTitle}
    </Context>
  );
});

const Context = styled.span(({ theme }) => ({
  color: theme.colors.chat.header,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.xxsmall,
}));

const StyledMdsIcon = styled(MdsIcon)(({ theme }) => ({
  color: theme.colors.chat.header,
  display: "inline",
  height: "12px",
  marginLeft: "6px",
  marginRight: "2px",
  width: "12px",
}));

const getContextTitle = ({ context }: { context?: ChatMessageContext }) => {
  if (!context || context.kind === ChatMessageContextKind.Global) return;

  const title = context.observable?.()?.title;
  if (title) return title;

  return context.kind === ChatMessageContextKind.CollectionDetailView
    ? UNTITLED_COLLECTION_TITLE
    : UNTITLED_NOTE_TITLE;
};
