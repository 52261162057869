import { useMemo, useEffect } from "react";

import { usePublicAppStore } from "@/store";
import { googleIdentityModule } from "@/modules/google-identity";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageModule } from "@/modules/local-storage";
import { appRoutes } from "@/app/router";

export const useGoogleOAuthRedirectPageBehavior = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { publicStore } = usePublicAppStore();

  const { redirectURI, code } = useMemo(() => {
    const baseUrl = window.location.href;
    const redirectURI = new URL(location.pathname, baseUrl).href;

    const { code } = googleIdentityModule.parseOAuthCode({ location });

    return {
      redirectURI,
      code,
    };
  }, [location]);

  useEffect(() => {
    const asyncData = publicStore.auth.authenticateAccountUsingGoogleOAuthCodeState;

    /**
     * If we've already made a request, don't make another one.
     */
    if (!!asyncData.isLoading || !!asyncData.failedAt || !!asyncData.data) {
      return;
    }

    /**
     * Async.
     */
    publicStore.auth.authenticateAccountUsingGoogleOAuthCode({
      code,
      redirectURI,
      isSignUp: false,
    });
  }, [code, publicStore.auth, redirectURI]);

  const state = publicStore.auth.authenticateAccountUsingGoogleOAuthCodeState;

  useEffect(() => {
    if (!publicStore.auth.isAuthenticated) {
      return;
    }

    const asyncLogic = async () => {
      const { pathname } = await localStorageModule.readPendingRedirectPath();
      await localStorageModule.clearPendingRedirectPath();

      const defaultRedirectPath = appRoutes.notesList({}).path;
      const targetPath = pathname ?? defaultRedirectPath;

      /**
       * Using this because we don't have access to the `navigation` store when
       * on a public route.
       */
      navigate(targetPath);
    };

    asyncLogic();
  }, [navigate, publicStore.auth.isAuthenticated]);

  return state;
};
