import styled from "@emotion/styled";

export const MdsPanelContainer = styled.div(({ theme }) => ({
  width: "100%",
  height: "100%",
  transition: "padding 0.2s ease-in-out",
  paddingInline: theme.spacing.sm,
  "@container mds-panel (min-width: 600px)": {
    paddingInline: theme.spacing.lg,
  },
}));
