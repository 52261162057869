import { SyncModelScopeSpaceAccount } from "@/domains/sync-scopes/types";
import { AppStore } from "@/store";
import { getOperationKindToClassMap } from "@/store/sync/operations/map";
import { SerializedSyncOperation, SyncOperationGeneric } from "@/store/sync/operations/types";
import { SyncOperationKind } from "@/store/sync/types";

export function generateDefaultOwnerScopes({
  store,
}: {
  store: AppStore;
}): SyncModelScopeSpaceAccount {
  return {
    role_kind: "OWNER",
    scope_kind: "SPACE_ACCOUNT_SCOPE",
    value: {
      space_account_id: store.spaceAccounts.myPersonalSpaceAccountId,
    },
  };
}

export function serializeSyncOperation(operation: SyncOperationGeneric): SerializedSyncOperation {
  return {
    operationKind: operation.operationKind,
    payload: operation.payload,
    operationId: operation.operationId,
    committed_at: operation.committedAt,
    latestSpaceAccountSequenceId: operation.latestSpaceAccountSequenceId,
  };
}

export function deserializeSyncOperation(
  store: AppStore,
  data: SerializedSyncOperation
): SyncOperationGeneric {
  const operationKind = data.operationKind as SyncOperationKind;
  const OperationClass = getOperationKindToClassMap()[operationKind];

  if (!OperationClass) {
    throw new Error(`[deserializeSyncOperation] Unknown operationKind=${operationKind}`);
  }

  return new OperationClass({
    store,
    payload: data.payload,
    operationId: data.operationId,
    committedAt: data.committed_at,
    latestSpaceAccountSequenceId: data.latestSpaceAccountSequenceId,
  });
}
