import { BaseError } from "@/domains/errors/kinds/BaseError";
import { AppErrorCode } from "@/domains/errors/constants";

export class RuntimeBaseError extends BaseError {
  constructor({ ...rest }) {
    super({
      ...rest,
      code: AppErrorCode.RUNTIME,
    });
  }
}
