import { useMemo, useState } from "react";
import { ZIndex } from "@/domains/design/constants";
import { ScrollDirection, ScrollState } from "@/domains/react/useHorizontalScroll";
import { css, cx } from "@/domains/emotion";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";

interface ScrollButtonProps {
  direction: ScrollDirection;
  scrollState: ScrollState;
  startScrolling: (direction: ScrollDirection) => void;
  stopScrolling: () => void;
  scrollForDuration: (direction: ScrollDirection, duration: number) => void;
}

export const ScrollButton = ({
  direction,
  scrollState,
  startScrolling,
  stopScrolling,
  scrollForDuration,
}: ScrollButtonProps) => {
  const [pressStartTime, setPressStartTime] = useState<number | null>(null);

  const handleMouseDown = () => {
    setPressStartTime(Date.now());
    startScrolling(direction);
  };

  const handleMouseUp = () => {
    if (pressStartTime && Date.now() - pressStartTime < 100) {
      scrollForDuration(direction, 600);
    } else {
      setPressStartTime(null);
      stopScrolling();
    }
  };

  const { isVisible, buttonPositionStyles, iconKind } = useMemo(() => {
    if (direction === ScrollDirection.Left)
      return {
        isVisible: scrollState.leftVisible,
        buttonPositionStyles: buttonLeftStyles,
        iconKind: MdsIconKind.AngleLeft,
      };
    return {
      isVisible: scrollState.rightVisible,
      buttonPositionStyles: buttonRightStyles,
      iconKind: MdsIconKind.AngleRight,
    };
  }, [direction, scrollState]);

  return (
    <button
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => {
        setPressStartTime(null);
        stopScrolling();
      }}
      className={cx(
        buttonStyles,
        buttonPositionStyles,
        !isVisible && hiddenButtonStyles,
        scrollState.activeButton === direction && activeButtonStyles
      )}
    >
      <MdsIcon kind={iconKind} className={iconStyles} />
    </button>
  );
};

const iconStyles = css({
  color: mdsColors().grey.x600,
});

const buttonStyles = css({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(255, 255, 255, 1)",
  border: "none",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  zIndex: ZIndex.FloatingButton,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.2s ease",

  "&:hover": {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
});

const buttonLeftStyles = css({
  left: "3px",
});

const buttonRightStyles = css({
  right: "3px",
});

const hiddenButtonStyles = css({
  opacity: 0,
  cursor: "default",
  pointerEvents: "none",
});

const activeButtonStyles = css({
  boxShadow: "0 1px 2px rgba(100, 100, 100, 0.4)",
});
