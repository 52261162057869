import { debounce, DebouncedFunc } from "lodash-es";

import {
  getCriticalErrorCode,
  raiseCriticalError,
} from "@/domains/critical-errors/critical-errors";

const hasLocalDbInitializationFailed = (() => {
  let lastLocalDbErrorCode: string | undefined;

  return () => {
    const localDbErrorCode = getCriticalErrorCode();
    if (localDbErrorCode && localDbErrorCode != lastLocalDbErrorCode) {
      raiseCriticalError({ message: "Data is not ready for access." });
    }
    lastLocalDbErrorCode = localDbErrorCode;
    return !!lastLocalDbErrorCode;
  };
})();

const FLUSH_DEBOUNCE_WAIT_MS = 1000;
const FLUSH_DEBOUNCE_MAX_WAIT_MS = 5000;
const FLUSH_MAX_ERROR_DURATION_MS = 10000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DataFlusher<T extends Record<string, unknown> = any> {
  private data: T;
  private pendingData: T | null;
  private isFlushing: boolean;
  private mustFlushAgainImmediately: boolean;
  private pendingMutationResolvers: ((value?: unknown) => void)[] = [];
  private onFlush: (data: T) => Promise<void>;
  private onError?: (err: unknown) => void;
  private onInitData: () => T;

  private flushDebounceWaitMs = FLUSH_DEBOUNCE_WAIT_MS;
  private flushDebounceMaxWaitMs = FLUSH_DEBOUNCE_MAX_WAIT_MS;
  private flushMaxErrorDurationMs = FLUSH_MAX_ERROR_DURATION_MS;

  private firstConsecutiveFailureTimestamp: number | undefined = undefined;

  private flushDebounced: DebouncedFunc<() => Promise<void>>;

  constructor({
    onFlush,
    onError,
    onInitData,
    options,
  }: {
    onFlush: (data: T) => Promise<void>;
    onError?: (err: unknown) => void;
    onInitData?: () => T;
    options?: {
      urgency?: number;
      // If urgency is supplied, the next three options will be ignored
      flushDebounceWaitMs?: number;
      flushDebounceMaxWaitMs?: number;
      flushMaxErrorDurationMs?: number;
    };
  }) {
    this.onFlush = onFlush;
    this.onError = onError;
    this.onInitData = onInitData || (() => Object.create(null) as T);
    this.data = this.onInitData();
    this.pendingData = null;
    this.isFlushing = false;
    this.mustFlushAgainImmediately = false;

    if (options?.urgency) {
      this.flushDebounceWaitMs *= options.urgency;
      this.flushDebounceMaxWaitMs *= options.urgency;
      this.flushMaxErrorDurationMs *= options.urgency;
    } else {
      if (options?.flushDebounceWaitMs) {
        this.flushDebounceWaitMs = options?.flushDebounceWaitMs;
      }
      if (options?.flushDebounceMaxWaitMs) {
        this.flushDebounceMaxWaitMs = options?.flushDebounceMaxWaitMs;
      }
      if (options?.flushMaxErrorDurationMs) {
        this.flushMaxErrorDurationMs = options?.flushMaxErrorDurationMs;
      }
    }

    this.flushDebounced = debounce(
      async () => {
        this.flushImmediately();
      },
      this.flushDebounceWaitMs,
      {
        maxWait: this.flushDebounceMaxWaitMs,
      }
    );
  }

  public async flushImmediately() {
    try {
      if (this.isFlushing || hasLocalDbInitializationFailed()) {
        this.mustFlushAgainImmediately = true;
        return;
      }

      this.isFlushing = true;

      if (!this.pendingData) {
        this.pendingData = this.data;
        this.data = this.onInitData();
      }

      const pendingResolvers = [...this.pendingMutationResolvers];

      await this.onFlush(this.pendingData);

      this.pendingMutationResolvers = this.pendingMutationResolvers.slice(pendingResolvers.length);
      pendingResolvers.forEach(resolve => {
        resolve();
      });

      this.pendingData = null;
      this.isFlushing = false;
      this.firstConsecutiveFailureTimestamp = undefined;

      const willFlushAgainImmediately = this.mustFlushAgainImmediately;
      this.mustFlushAgainImmediately = false;
      if (willFlushAgainImmediately) {
        this.flushImmediately();
      }
    } catch (err) {
      this.isFlushing = false;

      if (this.firstConsecutiveFailureTimestamp === undefined) {
        this.firstConsecutiveFailureTimestamp = Date.now();
      }

      if (Date.now() - this.firstConsecutiveFailureTimestamp >= this.flushMaxErrorDurationMs) {
        if (this.onError) {
          this.onError(err);
        }
      }

      this.flushDebounced();
    }
  }

  public queryData<Result>(querier: (pendingData: T | null, data: T) => Result) {
    return querier(this.pendingData, this.data);
  }

  public async mutateData(mutator: (data: T) => void) {
    return new Promise(resolve => {
      this.pendingMutationResolvers.push(resolve);
      mutator(this.data);
      this.flushDebounced();
    });
  }
}
