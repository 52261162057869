import { FC } from "react";
import { usePublicAppStore } from "@/store";

export const PageElementRouter: FC<{
  guestModeElement: JSX.Element;
  standardModeElement: JSX.Element;
}> = ({ guestModeElement, standardModeElement }) => {
  const { publicStore } = usePublicAppStore();

  if (publicStore.auth.isGuestMode) {
    return guestModeElement;
  }

  return standardModeElement;
};
