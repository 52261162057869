import { useLayoutEffect, useState } from "react";

import { DebounceSettings, debounce } from "lodash-es";
import { useEventListener } from "@/domains/react/useEventListener";

interface WindowSize {
  width: number;
  height: number;
}

type UseWindowSize = (options?: {
  debounce?: {
    wait?: number;
    options?: DebounceSettings;
  };
}) => WindowSize;

export const useWindowResize: UseWindowSize = (options = {}) => {
  const { debounce: debounceArgs } = options;

  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const debouncedHandleSize = debounceArgs
    ? debounce(handleSize, debounceArgs.wait, debounceArgs.options)
    : handleSize;

  useEventListener("resize", debouncedHandleSize);

  // Set size at the first client-side load
  useLayoutEffect(() => {
    handleSize();
  }, []);

  return windowSize;
};
