import { BaseError } from "@/domains/errors";
import { api } from "@/modules/api";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import {
  AccountNotificationPreferenceData,
  NotificationPreference,
} from "@/store/pages/SettingsEmailPreferencesPageStore/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import { FetchValue } from "@/store/queries/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { computed, makeObservable, observable, reaction, runInAction, action } from "mobx";
import pRetry from "p-retry";

export class SettingsEmailPreferencesPageStore extends AppSubStore {
  accountNotificationPreferencesData: AsyncResult<AccountNotificationPreferenceData>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    this.accountNotificationPreferencesData = asyncResultModule.setInitial();
    makeObservable(this, {
      accountNotificationPreferencesData: observable,
      updateNotificationPreference: action,
      query: computed,
    });
    reaction(
      () => this.query.data,
      data => {
        this.accountNotificationPreferencesData = asyncResultModule.setReady(data);
      },
      { fireImmediately: true }
    );
  }

  async updateNotificationPreference(
    notification_preference: NotificationPreference,
    is_enabled: boolean
  ) {
    await pRetry(
      async () => {
        const response = await api.post("/v2/me/notification-preferences", {
          body: {
            notification_preference,
            is_enabled,
          },
        });
        if (response.data) {
          runInAction(() => {
            this.accountNotificationPreferencesData = asyncResultModule.setReady(response.data);
          });
        }
        if (response.error) {
          const err = new BaseError({
            message: "Failed to save notification preference for current account.",
            info: { result: objectModule.safeAsJson(response) },
          });
          throw err;
        }
      },
      {
        retries: 3,
        factor: 2,
        minTimeout: 500,
        onFailedAttempt: () => {},
      }
    );
  }

  get query() {
    const id = `notification-preferences`;

    type QueryValue = AccountNotificationPreferenceData;

    const fetchValue: FetchValue<QueryValue> = async signal => {
      if (signal.aborted) return;

      runInAction(() => (this.accountNotificationPreferencesData = asyncResultModule.setLoading()));

      const response = await api.get(`/v2/me/notification-preferences`, {});

      logger.debug({
        message: "[SettingsEmailPreferencesPageStore] Query.",
        info: { data: response.data ?? null },
      });

      if (signal.aborted) return;

      if (response.error)
        throw new Error("[EmailPreferencesPageStore] query error: " + response.error.type);

      runInAction(() => {
        this.accountNotificationPreferencesData = asyncResultModule.setReady(response.data);
      });

      return { data: response.data };
    };

    const createQuery = () =>
      new QueryObservable<QueryValue>({
        auth: this.store.auth,
        queriesCache: this.store.queriesCache,
        id,
        fetchValue,
      });

    return this.store.queriesCache.get<QueryValue>(id, createQuery);
  }
}
