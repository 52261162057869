import React, { ReactNode, FC } from "react";
import { css, cx } from "@/domains/emotion";
import { SettingsSubsectionTitle } from "@/pages/settings/components/SettingsSubsectionTitle";

interface SettingsSubsectionWrapperProps {
  title: string;
  children: ReactNode;
  titleClassName?: string;
  className?: string;
}

export const SettingsSubsectionWrapper: FC<SettingsSubsectionWrapperProps> = ({
  title,
  children,
  titleClassName,
  className,
}) => {
  return (
    <div className={cx(wrapperStyles, className)}>
      <SettingsSubsectionTitle title={title} className={titleClassName} />
      {children}
    </div>
  );
};

const wrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  width: "100%",
});
