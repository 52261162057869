import React, { ReactNode, FC } from "react";
import { css } from "@/domains/emotion";

interface SettingsSectionWrapperProps {
  children: ReactNode;
}

export const SettingsSectionWrapper: FC<SettingsSectionWrapperProps> = ({ children }) => {
  return <div className={wrapperStyles}>{children}</div>;
};

const wrapperStyles = css({
  width: "100%",
  minWidth: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "24px",
  overflow: "hidden",
  userSelect: "none",
});
