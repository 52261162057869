import { Base64 } from "js-base64";
import { externalBindingsModule } from "@mem-labs/common-editor";

interface ExtractedNoteContent {
  primaryLabel: string | null;
  secondaryLabel: string | null;
  markdown: string;
  plaintext: string;
  encodedContent: string;
}

export function convertMarkdownToNoteContent(markdownContent: string): ExtractedNoteContent {
  const encodedContent = convertMarkdownToEncodedContent(markdownContent);
  return convertEncodedContentToNoteContent(encodedContent);
}

export function convertMarkdownToEncodedContent(markdownContent: string): string {
  const base64MarkdownContent = Base64.encode(markdownContent);
  return externalBindingsModule.convertBase64MarkdownContentToEncodedContent({
    base64MarkdownContent,
  });
}

export function convertEncodedContentToNoteContent(encodedContent: string): ExtractedNoteContent {
  const { primaryLabel, secondaryLabel, plaintext, markdown } =
    externalBindingsModule.extractNoteContentFromEncodedContent({
      encodedContent,
    });
  return {
    primaryLabel,
    secondaryLabel,
    markdown,
    plaintext,
    encodedContent,
  };
}
