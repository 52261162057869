import {
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFilter,
} from "@/store/pages/SearchPageStore/types";

export type HomeFilter = SearchEngineRequestFilter;
export type HomeFacetFilter = SearchEngineRequestFacetFilter;

export enum HomeLensKind {
  All = "ALL",
  AddedByMe = "ADDED_BY_ME",
  SharedWithMe = "SHARED_WITH_ME",
  Unorganized = "UNORGANIZED",
}

export type HomeParams = {
  lens: HomeLensKind;
  sortBy: HomeSortByKind;
  filters: HomeFilter[];
  facetFilters: HomeFacetFilter[];
};

export enum HomeSortByKind {
  LastCreated = "LAST_CREATED",
  LastModified = "LAST_MODIFIED",
  LastViewed = "LAST_VIEWED",
}

export const DEFAULT_HOME_LENS = HomeLensKind.All;
export const DEFAULT_HOME_SORT_BY_KIND = HomeSortByKind.LastModified;

export enum HomeFilterKind {
  CreatedAfter = "CREATED_AFTER",
  CreatedBefore = "CREATED_BEFORE",
  ModifiedAfter = "MODIFIED_AFTER",
  ModifiedBefore = "MODIFIED_BEFORE",
}

export enum HomeFacetFilterKind {
  OwnedBySpaceAccount = "OWNED_BY_SPACE_ACCOUNT",
  ModifiedBySpaceAccount = "MODIFIED_BY_SPACE_ACCOUNT",
  InCollection = "IN_COLLECTION",
  ContainsLinkKind = "CONTAINS_LINK_KIND",
  ContainsMediaKind = "CONTAINS_MEDIA_KIND",
  FromCaptureSource = "FROM_CAPTURE_SOURCE",
}

/** @todo - Make this consistent. */
export enum HomeParamKey {
  Lens = "lens",
  SortBy = "sort_by",
  Filter = "filter",
  FacetFilter = "facet-filter",
}

export const HOME_PARAM_KEY_SORT_ORDER: Record<HomeParamKey, number> = {
  [HomeParamKey.Lens]: 0,
  [HomeParamKey.SortBy]: 1,
  [HomeParamKey.Filter]: 2,
  [HomeParamKey.FacetFilter]: 3,
};
