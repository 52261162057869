export type ShortcutConfig = {
  key: string;
  ctrlModifier?: boolean;
  altModifier?: boolean;
  shiftModifier?: boolean;
  metaModifier?: boolean;
};

export const registerGlobalKeyboardShortcutHandler = ({
  shortcut,
  handler,
}: {
  shortcut: ShortcutConfig;
  handler: (event: KeyboardEvent) => void;
}): { unregisterShortcut: () => void } => {
  const {
    key,
    ctrlModifier = false,
    altModifier = false,
    shiftModifier = false,
    metaModifier = false,
  } = shortcut;

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (
      event.key.toLowerCase() === key.toLowerCase() &&
      event.ctrlKey === ctrlModifier &&
      event.altKey === altModifier &&
      event.shiftKey === shiftModifier &&
      event.metaKey === metaModifier
    ) {
      handler(event);
    }
  };

  document.addEventListener("keydown", handleKeyDown);

  const unregisterShortcut = () => {
    document.removeEventListener("keydown", handleKeyDown);
  };

  return { unregisterShortcut };
};
