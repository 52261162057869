import { ScreenSize, mdsBreakpoints } from "@/design-system/foundations";

export const mdsMediaQueries = () => ({
  rules: Object.fromEntries(
    Object.entries(mdsBreakpoints()).map(([mode, breakpoint]) => [
      mode as ScreenSize,
      `@media ${breakpoint}`,
    ])
  ) as Record<ScreenSize, string>,
  exclusion: {
    rules: Object.fromEntries(
      Object.entries(mdsBreakpoints()).map(([mode, breakpoint]) => [
        mode as ScreenSize,
        `@media not all and ${breakpoint}`,
      ])
    ) as Record<ScreenSize, string>,
  },
});
