import { useEffect, useRef, useState } from "react";
import { MessageItem, Messages } from "@/components/messages";
import { ItemLocation, type VirtuosoMessageListMethods } from "@virtuoso.dev/message-list";
import type { ChatItemContext } from "@/domains/chat";
import type { ChatHistory } from "@/store/chat/ChatHistory";
import type { ChatItem } from "@/store/chat/types";
import { observer } from "mobx-react-lite";

interface Props {
  chatHistory: ChatHistory;
  style?: React.CSSProperties;
  inSidePanel?: boolean;
}

export const ChatMessagesList = observer(({ style, chatHistory, inSidePanel }: Props) => {
  const messagesRef = useRef<VirtuosoMessageListMethods<ChatItem, ChatItemContext>>(null);
  const isInitialRender = useRef(true);
  const [initialDataRendered, setInitialDataRendered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // there's no guarantee if virtuoso sets the initial data before / after first render
    // to accommodate that we check twice: one check for initial data being set, and another for initial render
    if (!initialDataRendered) {
      return;
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const bottomOffset = messagesRef?.current?.getScrollLocation().bottomOffset || 0;
    const initialLocation: ItemLocation = {
      index: "LAST",
      align: "end",
      behavior: customSmoothScroll,
    };

    messagesRef?.current?.data.replace(chatHistory.items, {
      initialLocation: bottomOffset < 100 ? initialLocation : undefined,
    });
  }, [chatHistory.items, initialDataRendered]);

  return (
    <Messages
      virtuosoRef={messagesRef}
      ItemContent={MessageItem}
      context={{ inSidePanel }}
      initialData={chatHistory.items}
      dataTestId="chat-messages-list"
      computeItemKey={item => item.data.id}
      isVisible={isVisible}
      style={style}
      initialLocation={{
        index: "LAST",
        align: "end",
        behavior: "auto",
        done: () => {
          setTimeout(() => {
            messagesRef?.current?.scrollIntoView({
              index: "LAST",
              align: "end",
              behavior: "auto",
            });
            setIsVisible(true);
            setInitialDataRendered(true);
          }, 300);
        },
      }}
    />
  );
});

const customSmoothScroll = () => {
  const easeOutQuad = (x: number): number => 1 - (1 - x) * (1 - x);

  return {
    // increase the animation frame count to smoothen and slow down the scroll.
    animationFrameCount: 50,
    easing: easeOutQuad,
  };
};
