import { moduleFactory } from "@/factories/module";
import { _asyncResultSetError } from "@/modules/async-result/setError";
import { _asyncResultSetInitial } from "@/modules/async-result/setInitial";
import { _asyncResultSetLoading } from "@/modules/async-result/setLoading";
import { _asyncResultSetReady } from "@/modules/async-result/setReady";

export const asyncResultModule = moduleFactory.create({
  setInitial: _asyncResultSetInitial,
  setLoading: _asyncResultSetLoading,
  setReady: _asyncResultSetReady,
  setError: _asyncResultSetError,
});
