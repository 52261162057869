import { useCallback, useRef, useState, useLayoutEffect } from "react";

export enum ScrollDirection {
  Left = "left",
  Right = "right",
}

export interface ScrollState {
  topVisible: boolean;
  bottomVisible: boolean;
}

export function useVerticalScroll() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [scrollState, setScrollState] = useState<ScrollState>({
    topVisible: false,
    bottomVisible: false,
  });

  const updateScrollButtonsVisibility = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight } = container;
    const { clientHeight } = container.parentElement ?? { clientHeight: scrollHeight };

    setScrollState(prevState => ({
      ...prevState,
      topVisible: scrollTop > 0,
      bottomVisible: scrollTop < scrollHeight - clientHeight - 1,
    }));
  }, []);

  useLayoutEffect(() => {
    updateScrollButtonsVisibility();
    const container = scrollContainerRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver(updateScrollButtonsVisibility);
    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [updateScrollButtonsVisibility]);

  return {
    scrollContainerRef,
    scrollState,
    handleScroll: updateScrollButtonsVisibility,
  };
}
