import { inflect } from "inflection";
import { computed, reaction, makeObservable, observable, action, override } from "mobx";
import { CollectionItemListObservable } from "@/store/collection-items/CollectionItemListObservable";
import { CollectionModelData } from "@/store/collections/types";
import { WithAppStore } from "@/store/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { SyncUpdateValue } from "@/store/sync/types";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { Uuid } from "@/domains/global/identifiers";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { GrantableSyncScopeRoleKind } from "@/domains/sync-scopes/types";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { DeleteCollectionOperation } from "@/store/sync/operations/collections/DeleteCollectionOperation";
import { AddCollectionToFavoritesOperation } from "@/store/sync/operations/favorites/AddCollectionToFavoritesOperation";
import { RemoveCollectionFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveCollectionFromFavoritesOperation";
import { MarkCollectionViewedOperation } from "@/store/sync/operations/recents/MarkCollectionViewedOperation";
import { GrantCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaEmailAddressOperation";
import { GrantCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaSpaceAccountOperation";
import { UpdateCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaEmailAddressOperation";
import { UpdateCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaSpaceAccountOperation";
import { RevokeCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaEmailAddressOperation";
import { RevokeCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaSpaceAccountOperation";
import { MakeCollectionPrivateOperation } from "@/store/sync/operations/collections/MakeCollectionPrivateOperation";
import { compact, maxBy } from "lodash-es";
import { appRoutes } from "@/app/router";

export class CollectionObservable extends BaseSyncModel<CollectionModelData> {
  isLocked: boolean = false;
  title: string | undefined;
  description: string | undefined;
  itemList: CollectionItemListObservable;

  constructor({
    id,
    data,
    store,
  }: {
    id: string;
    data: SyncUpdateValue<CollectionModelData>;
  } & WithAppStore) {
    super({ id, data, store });
    this.id = id;

    this.title = data.model_data.title;
    this.description = data.model_data.description;
    this.itemList = new CollectionItemListObservable({ collectionId: this.id, store });

    makeObservable(this, {
      // OBSERVABLES
      isLocked: observable,
      title: observable,
      description: observable,
      itemList: observable,

      // OVERRIDES
      isAvailable: override,
      isShared: override,

      // EDITABLE PROPERTIES
      lock: action,
      unlock: action,
      setTitle: action,
      setDescription: action,
      save: action,

      // PROPERTIES
      label: computed,
      modifiedAt: computed,
      createdAt: computed,
      sharedAt: computed,
      receivedAt: computed,
      lastAddedToAt: computed,
      lastViewedAt: computed,
      lastMentionedAt: computed,
      lastInteractedAt: computed,
      lastInteractedAtByMe: computed,
      existsRemotely: computed,
      isTrashed: computed,
      path: computed,

      // FAVORITES
      favoriteItemId: computed,
      favoriteItem: computed,
      isFavorited: computed,

      // SHARING
      overview: computed,

      // SPACE ACCOUNT COLLECTION
      spaceAccountCollectionId: computed,
      spaceAccountCollection: computed,

      // ACTIONS
      delete: action,
      grantAccessViaEmailAddress: action,
      grantAccessViaSpaceAccount: action,
      updateAccessViaEmailAddress: action,
      updateAccessViaSpaceAccount: action,
      revokeAccessViaEmailAddress: action,
      revokeAccessViaSpaceAccount: action,
      makePrivate: action,
      toggleFavorite: action,
      addToRecents: action,
    });

    reaction(
      () => this.modelData,
      modelData => {
        if (!this.isLocked) {
          this.title = modelData.title;
          this.description = modelData.description;
        }
      },
      { fireImmediately: true }
    );
  }

  // EDITABLE PROPERTIES
  public lock() {
    this.isLocked = true;
  }

  public unlock() {
    this.isLocked = false;
  }

  setTitle(title: string) {
    this.lock();
    this.title = title;
  }

  setDescription(description: string) {
    this.lock();
    this.description = description;
  }

  public save() {
    if (this.title !== this.modelData.title || this.description !== this.modelData.description)
      new UpdateCollectionOperation({
        store: this.store,
        payload: {
          id: this.id,
          title: this.title,
          description: this.description,
        },
      }).execute();
    this.unlock();
  }

  // PROPERTIES
  get label() {
    return this.title || UNTITLED_COLLECTION_TITLE;
  }

  get modifiedAt() {
    const optimisticUpdates =
      this.store.sync.actionQueue.optimisticUpdatesByModelId.get(this.id) || [];
    const latestOperationDate = optimisticUpdates.reduce((latest, update) => {
      const actionDate = update.locally_committed_at;
      return actionDate > latest ? actionDate : latest;
    }, this.modelData.locally_modified_at);
    return latestOperationDate;
  }

  get createdAt() {
    return this.modelData.locally_created_at;
  }

  get sharedAt(): string | null | undefined {
    return this.modelData.shared_at;
  }

  // TODO: Implement proper received_at when ready
  get receivedAt(): string | null | undefined {
    return this.sharedAt;
  }

  get lastAddedToAt() {
    return this.modelData.last_added_to_at;
  }

  get lastViewedAt(): string {
    return this.spaceAccountCollection?.modelData.last_viewed_at || "";
  }

  get lastMentionedAt(): string | undefined {
    // TODO
    return undefined;
  }

  get isAvailable(): boolean {
    return !this.isTrashed && !this.isDeleted && this.canAccess;
  }

  get lastInteractedAt(): string {
    const timestamps = compact([
      this.spaceAccountCollection?.lastInteractedAt,
      this.lastAddedToAt,
      this.sharedAt,
    ]);
    return maxBy(timestamps, timestamp => new Date(timestamp)) || this.createdAt;
  }

  get lastInteractedAtByMe(): string | undefined {
    return this.spaceAccountCollection?.lastInteractedAt;
  }

  get existsRemotely() {
    return this.remoteData.model_version > 0;
  }

  get isTrashed() {
    // Collections don't go into the trash. They are deleted immediately.
    return false;
  }

  get path(): string {
    return appRoutes.collectionsView({ params: { collectionId: this.id } }).path;
  }

  get favoriteItemId(): Uuid {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get favoriteItem(): FavoriteItemObservable | undefined {
    return this.store.favoriteItems.pool.get(this.favoriteItemId);
  }

  get isFavorited(): boolean {
    return this.store.favoriteItems.pool.has(this.favoriteItemId);
  }

  get isShared(): boolean {
    return this.modelData.shared_at !== null;
  }

  get overview() {
    return `${this.isShared ? "Shared" : "Private"} Collection · ${this.itemList.size} ${inflect("item", this.itemList.size)}`;
  }

  get spaceAccountCollectionId(): Uuid {
    return resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.id,
    });
  }

  get spaceAccountCollection(): SpaceAccountCollectionObservable | undefined {
    return this.store.spaceAccountCollections.getSpaceAccountCollectionObservableById({
      spaceAccountCollectionId: this.spaceAccountCollectionId,
    });
  }

  public delete() {
    new DeleteCollectionOperation({ store: this.store, payload: { id: this.id } }).execute();
  }

  public grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new GrantCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public grantAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: Uuid;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new GrantCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public updateAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: Uuid;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public revokeAccessViaEmailAddress({ targetEmailAddress }: { targetEmailAddress: string }) {
    new RevokeCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public revokeAccessViaSpaceAccount({ targetSpaceAccountId }: { targetSpaceAccountId: Uuid }) {
    new RevokeCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public makePrivate() {
    new MakeCollectionPrivateOperation({
      store: this.store,
      payload: {
        id: this.id,
      },
    }).execute();
  }

  public toggleFavorite() {
    if (this.isFavorited)
      new RemoveCollectionFromFavoritesOperation({
        store: this.store,
        payload: { collection_id: this.id },
      }).execute();
    else
      new AddCollectionToFavoritesOperation({
        store: this.store,
        payload: { collection_id: this.id },
      }).execute();
  }

  public addToRecents = () => {
    new MarkCollectionViewedOperation({
      store: this.store,
      payload: { collection_id: this.id },
    }).execute();
  };
}
