import React from "react";
import { observer } from "mobx-react-lite";
import {
  GridSelectionForm,
  GridOption,
} from "@/components/onboarding/components/GridSelectionForm";
import { useAppStore } from "@/store";

export const SelectReferrerStage = observer(() => {
  const { store } = useAppStore();

  const handleSubmit = async ({ selectedOptions }: { selectedOptions: GridOption[] }) => {
    const referrer = selectedOptions[0]?.id;
    if (!referrer) return;
    store.platformInfo.onboardingInfo.setReferrer({ referrer });
    await store.platformInfo.onboardingInfo.saveAndContinue();
  };

  return (
    <GridSelectionForm
      prompt="How did you hear about Mem?"
      sub=""
      multiselect={false}
      shuffle={true}
      options={referrers}
      onSubmit={handleSubmit}
    />
  );
});

const referrers: GridOption[] = [
  {
    id: "twitter",
    label: "X (Twitter)",
  },
  {
    id: "youtube",
    label: "YouTube",
  },
  {
    id: "linkedin",
    label: "LinkedIn",
  },
  {
    id: "medium",
    label: "Medium",
  },
  {
    id: "newsletter",
    label: "Email Newsletter",
  },
  {
    id: "instagram_facebook",
    label: "Instagram / Facebook",
  },
  {
    id: "tiktok",
    label: "TikTok",
  },
  {
    id: "google",
    label: "Google Search",
  },
  {
    id: "friend_family",
    label: "Friend / Family",
  },
  {
    id: "coworker",
    label: "Coworker",
  },
];
