/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep, isFunction, isObject, mapValues } from "lodash-es";

// Define types for clarity
type AnyFunction = (...args: any[]) => never;

type NestedMethods<T> = {
  [K in keyof T]: T[K] extends AnyFunction
    ? T[K]
    : T[K] extends object
      ? NestedMethods<T[K]>
      : never;
};

/**
 * Creates an immutable module with nested methods.
 * @param moduleMethods - The object containing the module's methods.
 * @returns A readonly version of the module.
 */
export const _createModule = <TModuleMethods extends NestedMethods<TModuleMethods>>(
  moduleMethods: TModuleMethods
): Readonly<TModuleMethods> => {
  const processedModule = processModuleMethods(moduleMethods);
  return cloneDeep(processedModule);
};

/**
 * Recursively processes the module methods, handling nested structures.
 * @param methods - The methods to process.
 * @returns The processed methods.
 */
function processModuleMethods<T extends NestedMethods<T>>(methods: T): T {
  return mapValues(methods, value => {
    if (isFunction(value)) {
      return value;
    }

    if (isObject(value)) {
      return processModuleMethods(value as NestedMethods<T>);
    }

    return value;
  }) as T;
}
