import React from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import {
  ProfileInfo,
  ProfileKind,
  ProfileSize,
} from "@/components/layout/components/account-profile/types";

interface AccountProfileImageProps {
  className?: string;
  profile?: ProfileInfo;
  size?: ProfileSize;
}

export const AccountProfileImage: React.FC<AccountProfileImageProps> = observer(
  ({ className, size = ProfileSize.Standard, profile }) => {
    const { store } = useAppStore();

    const { photoUrl, displayName } = getProfileInfo({ profile, store });

    return (
      <AccountProfileImageContent
        className={className}
        size={size}
        photoUrl={photoUrl}
        displayName={displayName}
      />
    );
  }
);

interface GetProfileInfoArgs {
  profile: ProfileInfo | undefined;
  store: ReturnType<typeof useAppStore>["store"];
}

const getProfileInfo = ({
  profile,
  store,
}: GetProfileInfoArgs): { photoUrl: string | null; displayName: string } => {
  switch (profile?.kind) {
    case ProfileKind.Me:
      return {
        photoUrl: store.account.myAccount.profilePhotoUrl,
        displayName: store.account.myAccount.profileDisplayName,
      };
    case ProfileKind.Contact:
    case ProfileKind.Account:
    case ProfileKind.SpaceAccount:
      return {
        photoUrl: profile.data.profilePhotoUrl ?? "",
        displayName: profile.data.profileDisplayName ?? "",
      };
    default:
      return { photoUrl: null, displayName: "" };
  }
};
