import { ContactObservable } from "@/store/contacts/ContactObservable";
import { AccountObservable } from "@/store/account/observables/AccountObservable";
import { SpaceAccountObservable } from "@/store/space-account/observables/SpaceAccountObservable";

export enum ProfileSize {
  Small = "small",
  Standard = "default",
  Large = "large",
}

export enum ProfileVariant {
  Rounded = "rounded",
  Square = "square",
}

export enum ProfileKind {
  Me = "ME",
  Contact = "CONTACT",
  Account = "ACCOUNT",
  SpaceAccount = "SPACE_ACCOUNT",
}

export type ProfileInfo =
  | { kind: ProfileKind.Me }
  | { kind: ProfileKind.Contact; data: ContactObservable }
  | { kind: ProfileKind.Account; data: AccountObservable }
  | { kind: ProfileKind.SpaceAccount; data: SpaceAccountObservable };
