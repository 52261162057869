import { observer } from "mobx-react-lite";
import React from "react";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { mdsColors, mdsSpacings } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { FadeInFadeHorizontalScroll } from "@/components/horizontal-scroll/FadeInFadeHorizontalScroll";
import styled from "@emotion/styled";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";

export const SearchPageHeader = observer(function SearchPageHeader() {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;
  const queryString = page.activeSearchEngineParams.queryString;

  return (
    <Wrapper>
      <MdsPanelHeader
        heading={queryString ? queryString : "Notes"}
        icon={queryString ? MdsIconKind.Search : MdsIconKind.Notebook}
        actions={
          <MdsIconButton
            iconKind={page.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack}
            size={MdsIconButtonSize.Small}
            variant={MdsIconButtonVariant.Transparent}
            className={page.isFavorited ? undefined : unStarredButtonStyles}
            tooltipConfig={{
              content: page.isFavorited ? "Unpin from sidebar" : "Pin to sidebar",
              placement: MdsTooltipPlacement.Top,
            }}
            onClick={page.toggleFavorite}
          />
        }
      />
      <WrapperBottom>
        <WrapperBottomLeft>
          <FadeInFadeHorizontalScroll filters={page.uiFilters} className={horizontalScrollStyles} />
        </WrapperBottomLeft>
        <WrapperBottomRight>
          <MdsDropdownButton
            contentList={page.sortOptions}
            label={page.sortLabel}
            iconKind={MdsIconKind.AngleDown}
            iconPosition={MdsButtonIconPosition.Right}
            placement="below-right-alignment"
          />
        </WrapperBottomRight>
      </WrapperBottom>
    </Wrapper>
  );
});

const Wrapper = styled.div({});

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
  gap: theme.spacing.md,
}));

const WrapperBottomLeft = styled.div({
  minWidth: 0,
});

const WrapperBottomRight = styled.div({
  flexShrink: 0,
});

const horizontalScrollStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: mdsSpacings().xs,
});

const unStarredButtonStyles = css({
  "&> div": {
    "&> div": {
      "&> svg": {
        color: mdsColors().grey.x400,
      },
    },
  },
});
