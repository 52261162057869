import { Uuid } from "@/domains/global/identifiers";
import { sortBy } from "lodash-es";
import { v5 as uuidv5 } from "uuid";

export const combineUuidsUsingNamespace = ({
  namespace,
  uuids,
}: {
  namespace: Uuid;
  uuids: Uuid[];
}): Uuid => {
  /**
   * Note - this exactly matches our backend implementation.
   *
   * We also have some tests for this.
   */
  const upperCasedUuids = uuids.map(id => id.toUpperCase());
  const sortedUuids = sortBy(upperCasedUuids);
  const combinedUuidStr = sortedUuids.join("::");

  const namespaceStr = namespace.toUpperCase();

  return uuidv5(combinedUuidStr, namespaceStr);
};
