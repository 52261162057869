import { Maybe } from "@/domains/common/types";
import { useEffect, useState } from "react";

/** A custom React hook that manages an object's state after deletion.
 * When the object becomes undefined (deleted), it returns a proxy of the last known state
 * with 'isDeleted' set to true. */
export function useObjectAfterDeletion<T extends object>(maybeObject?: T): Maybe<T> {
  const [object, setObject] = useState(maybeObject);
  useEffect(
    () =>
      setObject(prevObject =>
        getObjectAfterDeletion({ current: maybeObject, previous: prevObject })
      ),
    [maybeObject]
  );
  return object;
}

export type ObjectWithIsDeleted<T extends object> = T & { isDeleted?: boolean };

export const getObjectAfterDeletion = <T extends object>({
  current,
  previous,
  active = true,
}: {
  current?: T;
  previous?: T;
  active?: boolean;
}): Maybe<T> => {
  if (!active) return;
  if (current) return current;
  if (!previous) return;
  return createProxyWithIsDeleted(previous);
};

/**
 * Creates a proxy object that appears to be deleted.
 * This proxy will return true for 'isDeleted' property and
 * behave normally for all other properties.
 */
export const createProxyWithIsDeleted = <T extends object>(obj: T): ObjectWithIsDeleted<T> => {
  return new Proxy<ObjectWithIsDeleted<T>>(obj, {
    get(target, prop) {
      if (prop === "isDeleted") return true;
      return Reflect.get(target, prop);
    },
  });
};
