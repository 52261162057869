import { WithAppStore } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  override,
  reaction,
  runInAction,
} from "mobx";
import { INoteObservable, NoteModelData } from "@/store/note/types";
import { Uuid } from "@/domains/global/identifiers";
import { SyncUpdateValue } from "@/store/sync/types";
import {
  GrantableSyncScopeRoleKind,
  SyncModelPermissionEntryWithStatus,
} from "@/domains/sync-scopes/types";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { filter, uniq } from "lodash-es";
import { NoteCollectionListObservable } from "@/store/collection-items/NoteCollectionListObservable";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { IContactModel } from "@/store/contacts/types";
import { getQueuedDocumentUpdates } from "@/store/sync/operations/helpers/notes/getQueuedDocumentUpdates";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { RestoreNoteOperation } from "@/store/sync/operations/notes/RestoreNoteOperation";
import { AddNoteToFavoritesOperation } from "@/store/sync/operations/favorites/AddNoteToFavoritesOperation";
import { RemoveNoteFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveNoteFromFavoritesOperation";
import { MarkNoteViewedOperation } from "@/store/sync/operations/recents/MarkNoteViewedOperation";
import { GrantNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/GrantNoteAclViaSpaceAccountOperation";
import { GrantNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/GrantNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaSpaceAccountOperation";
import { UpdateNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaCollectionOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaCollectionOperation";
import { RevokeNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaSpaceAccountOperation";
import { RevokeNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaEmailAddressOperation";
import { logger } from "@/modules/logger";
import { getPermissionsForNoteSyncModel } from "@/store/sync/operations/helpers/permissions/getPermissionsForModel";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import {
  AsyncData,
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
} from "@/domains/async/AsyncData";
import { appRoutes } from "@/app/router";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";

export class NoteObservable extends BaseSyncModel<NoteModelData> implements INoteObservable {
  public collectionList: NoteCollectionListObservable;
  public noteContentDocument: AsyncData<NoteContentDocumentObservable> = asyncDataLoading({});

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<NoteModelData>;
  } & WithAppStore) {
    super({ id, data, store });
    this.collectionList = new NoteCollectionListObservable({ noteId: id, store });

    makeObservable(this, {
      // OBSERVABLES
      noteContentDocument: observable,
      collectionList: observable,

      // OVERRIDES
      isAvailable: override,
      isShared: override,
      permissions: override,

      // PROPERTIES
      createdAt: computed,
      receivedAt: computed,
      lastMentionedAt: computed,
      modifiedAt: computed,
      trashedAt: computed,
      lastViewedAt: computed,
      isTrashed: computed,

      title: computed,
      secondaryTitle: computed,
      path: computed,
      authors: computed,
      metadata: computed,

      // NOTE CONTENT
      noteContentDocumentId: computed,
      isNoteContentDocumentLoaded: computed,
      fetchNoteContentDocument: action,
      remoteContent: computed,
      isNoteContentInOriginalState: computed,
      queuedDocumentUpdates: computed,

      // FAVORITES
      favoriteItemId: computed,
      favoriteItem: computed,
      isFavorited: computed,

      // SPACE ACCOUNT
      spaceAccountNoteId: computed,
      spaceAccountNote: computed,

      // ACTIONS
      updateContentUsingDiff: action,
      saveAsNewNote: action,
      delete: action,
      deleteEmptyNote: action,
      moveToTrash: action,
      restoreFromTrash: action,
      grantAccessViaSpaceAccount: action,
      grantAccessViaEmailAddress: action,
      updateAccessViaSpaceAccount: action,
      updateAccessViaEmailAddress: action,
      updateAccessViaCollection: action,
      revokeAccessViaSpaceAccount: action,
      revokeAccessViaEmailAddress: action,
      toggleFavorite: action,
      addToRecents: action,
    });

    onBecomeObserved(this, "isNoteContentDocumentLoaded", () => {
      this.fetchNoteContentDocument();
    });

    reaction(
      () => this.store.noteContentDocuments.get(this.noteContentDocumentId),
      (noteContentDocument, prevNoteContentDocument) => {
        if (noteContentDocument && prevNoteContentDocument != noteContentDocument) {
          this.noteContentDocument = asyncDataLoaded(this.noteContentDocument, noteContentDocument);
        }
      }
    );
  }

  // PROPERTIES
  get createdAt(): string {
    return this.modelData.locally_created_at;
  }

  // TODO: Implement proper received_at when ready
  get receivedAt(): string | null | undefined {
    return this.modelData.created_at;
  }

  get lastMentionedAt(): string | undefined {
    return undefined;
  }

  get modifiedAt(): string {
    const optimisticUpdates =
      this.store.sync.actionQueue.optimisticUpdatesByModelId.get(this.id) || [];
    const latestOperationDate = optimisticUpdates.reduce((latest, update) => {
      const actionDate = update.locally_committed_at;
      return actionDate > latest ? actionDate : latest;
    }, this.modelData.locally_modified_at);
    return latestOperationDate;
  }

  get trashedAt(): string | null {
    return this.modelData.trashed_at || null;
  }

  get lastViewedAt(): string {
    return this.spaceAccountNote?.lastViewedAt || this.createdAt;
  }

  get isTrashed(): boolean {
    return this.modelData.trashed_at !== null;
  }

  get isAvailable(): boolean {
    return super.isAvailable && !this.isTrashed && !this.isDeleted && this.canAccess;
  }

  get isShared(): boolean {
    const output = super.isShared;
    for (const collection of this.collections) if (collection.isShared) return true;
    return output;
  }

  get permissions(): SyncModelPermissionEntryWithStatus[] {
    return getPermissionsForNoteSyncModel({
      id: this.id,
      remoteData: this.remoteData,
      store: this.store,
      actionQueue: this.store.sync.actionQueue,
    });
  }

  get title(): string {
    return this.modelData.primary_label || UNTITLED_NOTE_TITLE;
  }

  get secondaryTitle(): string {
    return this.modelData.secondary_label || "";
  }

  get path(): string {
    return appRoutes.notesView({ params: { noteId: this.id } }).path;
  }

  get authors(): IContactModel[] {
    const spaceAccountIds = uniq([
      this.data.model_data.owned_by_space_account_id,
      ...this.data.model_data.modified_by_space_account_ids,
    ]);
    const getContactObservableByContactSpaceAccountId = (spaceAccountId: string) => {
      if (spaceAccountId === this.store.spaceAccounts.myPersonalSpaceAccountId) {
        return this.store.spaceAccounts.myPersonalSpaceAccount;
      }
      return this.store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);
    };
    const contacts = spaceAccountIds.map(getContactObservableByContactSpaceAccountId);
    return filter(contacts) as IContactModel[];
  }

  get metadata(): string {
    // TODO: implement
    return "";
  }

  // NOTE CONTENT
  get noteContentDocumentId(): Uuid {
    return resolveNoteContentDocumentSyncModelUuid({ noteId: this.id });
  }

  get isNoteContentDocumentLoaded(): boolean {
    return !this.noteContentDocument.isLoading && !!this.noteContentDocument.data;
  }

  async fetchNoteContentDocument() {
    if (this.isNoteContentDocumentLoaded) return;
    const noteContentDocument = this.store.noteContentDocuments.get(this.noteContentDocumentId);
    runInAction(() => {
      this.noteContentDocument = noteContentDocument
        ? asyncDataLoaded(this.noteContentDocument, noteContentDocument)
        : asyncDataLoading(this.noteContentDocument);
    });
    if (!noteContentDocument) {
      const fetchedNoteContentDocument = await this.store.noteContentDocuments.fetch(
        this.noteContentDocumentId
      );
      runInAction(() => {
        this.noteContentDocument = fetchedNoteContentDocument
          ? asyncDataLoaded(this.noteContentDocument, fetchedNoteContentDocument)
          : asyncDataFailed(this.noteContentDocument);
      });
    }
  }

  get remoteContent(): string | null {
    return this.noteContentDocument?.data?.remoteContent || null;
  }

  get isNoteContentInOriginalState() {
    // Versions:
    // 0: Created locally.
    // 1: Synced to server.
    // 2+: Content updates.
    return this.modelVersion <= 1;
  }

  get queuedDocumentUpdates() {
    return getQueuedDocumentUpdates({
      operationsByModelId: this.store.sync.actionQueue.operationsByModelId,
      id: this.id,
    });
  }

  // FAVORITES
  get favoriteItemId(): Uuid {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get favoriteItem(): FavoriteItemObservable | undefined {
    return this.store.favoriteItems.getFavoriteItemObservableById({
      favoriteItemId: this.favoriteItemId,
    });
  }

  get isFavorited(): boolean {
    return this.store.favoriteItems.pool.has(this.favoriteItemId);
  }

  // SPACE ACCOUNT NOTE
  get spaceAccountNoteId(): Uuid {
    return resolveSpaceAccountNoteSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      noteId: this.id,
    });
  }

  get spaceAccountNote(): SpaceAccountNoteObservable | undefined {
    return this.store.spaceAccountNotes.get(this.spaceAccountNoteId);
  }

  // ACTIONS
  public updateContentUsingDiff({
    encodedContentDiff,
    primaryLabel,
    secondaryLabel,
  }: {
    encodedContentDiff: string | null;
    primaryLabel: string;
    secondaryLabel: string;
  }) {
    // TODO: Handle null properly
    new UpdateNoteContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: this.id,
        encoded_content_diff: encodedContentDiff || "",
      },
      primaryLabel,
      secondaryLabel,
    }).execute();
  }

  public saveAsNewNote() {
    const remoteContent = this.remoteContent;
    const diffs = this.queuedDocumentUpdates.map(update => update.encodedContentDiff);
    const newEncodedContent = notesModule.mergeDiffsWithRemoteContent(remoteContent, diffs);
    const { primaryLabel, secondaryLabel } =
      notesModule.convertEncodedContentToNoteContent(newEncodedContent);
    const newNoteId = uuidModule.generate();
    this.store.notes.createNote({ noteId: newNoteId });
    new UpdateNoteContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: newNoteId,
        encoded_content_diff: newEncodedContent,
      },
      primaryLabel: primaryLabel || "",
      secondaryLabel: secondaryLabel || "",
    }).execute();
  }

  public delete() {
    new DeleteNoteOperation({ store: this.store, payload: { id: this.id } }).execute();
  }

  public deleteEmptyNote() {
    if (this.isDeleted || this.isTrashed || !this.isOwnedByMe || !this.isNoteContentDocumentLoaded)
      return;

    if (this.isNoteContentInOriginalState) {
      logger.debug({ message: `[${this.id}] Deleting note` });
      this.delete();
    } else {
      logger.debug({ message: `[${this.id}] Thrashing note` });
      this.moveToTrash();
    }
  }

  public moveToTrash(args?: { triggerSuccessToast?: boolean }) {
    new TrashNoteOperation({ ...args, store: this.store, payload: { id: this.id } }).execute();
  }

  public restoreFromTrash(args?: { triggerSuccessToast?: boolean }) {
    new RestoreNoteOperation({ ...args, store: this.store, payload: { id: this.id } }).execute();
  }

  public grantAccessViaSpaceAccount({
    roleKind,
    targetSpaceAccountId,
  }: {
    roleKind: GrantableSyncScopeRoleKind;
    targetSpaceAccountId: string;
  }) {
    new GrantNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new GrantNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public updateAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
        role_kind: roleKind,
      },
    }).execute();
  }

  public updateAccessViaCollection({
    collectionId,
    roleKind,
  }: {
    collectionId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateNoteAclViaCollectionOperation({
      store: this.store,
      payload: {
        id: this.id,
        collection_id: collectionId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public revokeAccessViaSpaceAccount({ targetSpaceAccountId }: { targetSpaceAccountId: string }) {
    new RevokeNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public revokeAccessViaEmailAddress({ targetEmailAddress }: { targetEmailAddress: string }) {
    new RevokeNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public toggleFavorite() {
    if (this.isFavorited)
      new RemoveNoteFromFavoritesOperation({
        store: this.store,
        payload: { note_id: this.id },
      }).execute();
    else
      new AddNoteToFavoritesOperation({
        store: this.store,
        payload: { note_id: this.id },
      }).execute();
  }

  public addToRecents = () => {
    new MarkNoteViewedOperation({
      store: this.store,
      payload: { note_id: this.id },
    }).execute();
  };
}
