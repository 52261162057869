import { observer } from "mobx-react-lite";
import { FC, ReactNode } from "react";
import { css } from "@/domains/emotion";
import {
  mdsFontSizes,
  mdsFontWeights,
  mdsLineSpacings,
} from "@/design-system/foundations/typography";
import { MdsCard } from "@/design-system/components/card/MdsCard";
import { MdsCardKind } from "@/design-system/components/card/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { MemLogoWithShadow } from "@/components/static/MemLogoWithShadow";
import { AuthFooter } from "@/components/auth";

export interface AuthCardBaseProps {
  title: string;
  children: ReactNode;
}

export const AuthCardBase: FC<AuthCardBaseProps> = observer(({ title, children }) => {
  return (
    <MdsCard className={wrapperStyles} kind={MdsCardKind.LargeShadow}>
      <MemLogoWithShadow />

      <div className={titleStyles}>{title}</div>
      <div className={descriptionStyles}>
        The only notes app designed to keep busy professionals organized with AI
      </div>

      {children}
      <AuthFooter />
    </MdsCard>
  );
});

const wrapperStyles = css({
  flexGrow: 0,
  width: "400px",
  minWidth: "400px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  padding: "40px",
  borderRadius: mdsBorderRadius().large,
  overflow: "hidden",
});

const titleStyles = css({
  color: mdsColors().grey.x600,
  fontSize: mdsFontSizes().xlarge,
  fontWeight: mdsFontWeights().bold,
  lineHeight: "32px",
  marginTop: "16px",
  letterSpacing: mdsLineSpacings().large,
});

const descriptionStyles = css({
  fontSize: mdsFontSizes().medium,
  fontWeight: mdsFontWeights().regular,
  color: mdsColors().grey.x500,
  lineHeight: "24px",
  textAlign: "center",
  padding: "8px 0",
  letterSpacing: mdsLineSpacings().medium,
});
