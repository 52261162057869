import { Maybe } from "@/domains/common/types";
import localDb from "@/domains/local-db";
import { api } from "@/modules/api";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { ModalDefinitionKind, SyncErrorModalDefinition } from "@/store/modals/types";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { serializeSyncOperation } from "@/store/sync/operations/helpers/common";
import { SyncOperation, SyncOperationGeneric } from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncCustomErrorData } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { compact, isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { action, computed, makeObservable, override, reaction, runInAction } from "mobx";
import { JsonObject } from "type-fest";

export class AppSyncActionQueue extends BaseSyncActionQueue<AppStore> {
  constructor(args: { getSpaceId: () => string } & AppSubStoreArgs<AppStore>) {
    super(args);

    makeObservable<this, "formatSyncOperation">(this, {
      formatSyncOperation: false,

      // ABSTRACT METHODS
      syncErrorModalFields: computed,
      saveSyncOperation: action,
      saveAcknowledgedSyncOperation: action,
      removeSyncOperation: action,
      processOperation: action,
      loadAllItems: action,

      syncError: computed,
      reset: override,

      fullDebugInfo: computed,
      friendlyDebugInfo: computed,
      toggleDebugging: action,
    });

    reaction(
      () => this.syncError,
      (syncError, previousSyncError) => {
        runInAction(() => {
          if (isEqual(syncError, previousSyncError)) return;

          if (previousSyncError) this.store.modals.removeModal(previousSyncError);
          if (syncError) this.store.modals.addModal(syncError);
        });
      }
    );
  }

  get syncError(): Maybe<SyncErrorModalDefinition> {
    if (!this.isFailing) return;

    const syncError = this.processingError?.syncErrorModalFieldsGenerator?.(this.store);
    if (!syncError) return;

    return {
      kind: ModalDefinitionKind.SyncError,
      syncError,
    };
  }

  get syncErrorModalFields(): Maybe<SyncErrorModalFields> {
    // Use the syncError in modals because there might be another error queued in front of it.
    return this.store.modals.syncError;
  }

  saveSyncOperation(syncOperation: SyncOperation) {
    const data = serializeSyncOperation(syncOperation);
    localDb.queue.saveSyncOperation(syncOperation.id, data);
  }

  saveAcknowledgedSyncOperation(syncOperation: SyncOperation) {
    const data = serializeSyncOperation(syncOperation);
    localDb.queue.saveAcknowledgedSyncOperation(syncOperation.id, data);
  }

  removeSyncOperation(id: string) {
    localDb.queue.removeSyncOperation(id);
  }

  loadAllItems(): Promise<{
    acknowledgedSyncOperations: SyncOperationGeneric[];
    syncOperations: SyncOperationGeneric[];
    optimisticUpdates: OptimisticSyncUpdate[];
  }> {
    return localDb.queue.getAllItems(this.store);
  }

  async processOperation(operation: SyncOperation): Promise<Maybe<SyncOperation>> {
    const response = await api.post(`/v2/sync/operations/submit`, {
      params: { query: { space_id: this.getSpaceId() } },
      body: operation.generateSyncOperation(),
    });
    console.debug("[SYNC][SyncActionQueue] Synced operation", operation, response);

    // HANDLE COMPLETED OPERATIONS
    if (
      !response.error &&
      response.data.status === "COMPLETED" &&
      "sync_operation" in response.data
    ) {
      operation.acknowledge(response.data.sync_operation.value.latest_space_account_sequence_id);
      return operation;
    }

    // HANDLE SKIPPED OPERATIONS
    if (!response.error && response.data.status === "SKIPPED") {
      this.skipAndRevertOperation(operation);
      return;
    }

    // HANDLE CUSTOM ERRORS
    if (
      !response.error &&
      response.data.status === "FAILED" &&
      "info" in response.data &&
      "error_data" in response.data.info
    ) {
      const errorInfo = response.data.info.error_data as SyncCustomErrorData;
      this.handleCustomError(operation, errorInfo);
      return;
    }

    // HANDLE UNKNOWN ERRORS
    this.handleCustomError(operation, { kind: "UNKNOWN" });
  }

  // DEBUGGING
  get fullDebugInfo() {
    return {
      info: {
        account_id: `${this.store.account.myAccountId}`,
        space_id: `${this.getSpaceId()}`,
        space_account_id: `${this.store.spaceAccounts.getSpaceAccountBySpaceId(this.getSpaceId())?.id}`,
        current_time: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      },
      stats: {
        state: this.processingState,
        enqueued_count: this.processing.length,
        awaiting_count: this.pending.length,
        last_processed_at_start: this.lastProcessingItemStart
          ? DateTime.fromJSDate(this.lastProcessingItemStart).toLocaleString(DateTime.TIME_SIMPLE)
          : null,
        last_processed_at_end: this.lastProcessingItemStop
          ? DateTime.fromJSDate(this.lastProcessingItemStop).toLocaleString(DateTime.TIME_SIMPLE)
          : null,
      },
      errors: {
        is_failing: this.isFailing,
        processing_error: this.processingError
          ? objectModule.safeErrorAsJson(this.processingError)
          : null,
      },
      queue: {
        enqueued_operations: this.processing.map(op => this.formatSyncOperation(op)),
        awaiting_operations: this.pending.map(op => this.formatSyncOperation(op)),
      },
    } as const;
  }

  get friendlyDebugInfo() {
    const fullInfo = this.fullDebugInfo;
    const visibleOperationCount = 2;

    const formatOperations = (operations: (JsonObject | null)[]) => {
      return compact([
        ...operations.slice(0, visibleOperationCount),
        operations.length > visibleOperationCount
          ? `...and ${operations.length - visibleOperationCount} more`
          : null,
      ]);
    };

    return {
      ...fullInfo,
      queue: {
        enqueued_operations: formatOperations(fullInfo.queue.enqueued_operations),
        awaiting_operations: formatOperations(fullInfo.queue.awaiting_operations),
      },
    } as const;
  }

  private formatSyncOperation(syncOperation?: SyncOperationGeneric): JsonObject | null {
    if (!syncOperation) return null;

    return {
      operation_id: `${syncOperation.id}`,
      operation_kind: `${syncOperation.operationKind}`,
      payload: syncOperation.payload as JsonObject,
    };
  }

  toggleDebugging = () => {
    this.store.debug.toggleDebugMode();
  };

  async reset() {
    return super.reset();
  }
}
