export class ExponentialBackoff {
  public static defaultMinMs = 10000;
  public static defaultMaxMs = 32 * 1000;
  public static defaultMaxEntropyMs = 1000;
  public static multiplier = 2;

  private minMs: number;
  private maxMs: number;
  private maxEntropyMs: number;
  private currentMs: number;

  constructor(
    minMs = ExponentialBackoff.defaultMinMs,
    maxMs = ExponentialBackoff.defaultMaxMs,
    maxEntropyMs = ExponentialBackoff.defaultMaxEntropyMs
  ) {
    this.minMs = minMs;
    this.maxMs = maxMs;
    this.maxEntropyMs = maxEntropyMs;
    this.currentMs = this.minMs;
  }

  getNextDelayMs() {
    const delayMs = this.calculateCurrentDelayMs();
    this.currentMs = this.calculateNextDelayMs();
    return delayMs;
  }

  reset() {
    this.currentMs = this.minMs;
  }

  calculateCurrentDelayMs() {
    return this.currentMs + Math.random() * Math.min(this.currentMs, this.maxEntropyMs);
  }

  calculateNextDelayMs() {
    return Math.min(this.currentMs * ExponentialBackoff.multiplier, this.maxMs);
  }
}
