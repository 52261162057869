import { FC } from "react";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { observer } from "mobx-react-lite";

interface SubscriptionPlanSelectorButtonContentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  priceText: string;
  captionText: string;
  bannerText?: string | null;
  isActive?: boolean;
}

export const SubscriptionPlanSelectorButtonContent: FC<SubscriptionPlanSelectorButtonContentProps> =
  observer(({ isActive, priceText, captionText, bannerText, ...props }) => {
    const savingsBannerStyle = css({
      ":after": {
        position: "absolute",
        right: "-1px",
        fontSize: "12px",
        fontWeight: 500,
        top: "8px",
        content: `'${bannerText}'`,
        borderRadius: "6px 0 0 6px",
        backgroundColor: mdsColors().primary.x500,
        color: mdsColors().white,
        padding: "4px 8px",
      },
    });
    return (
      <button
        {...props}
        aria-selected={isActive}
        className={cx([buttonStyles, bannerText ? savingsBannerStyle : null, props.className])}
      >
        <strong>{priceText}</strong>
        <span>{captionText}</span>
      </button>
    );
  });

const fadeIn = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      scale: 0.98;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
`;

const buttonStyles = css({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  color: mdsColors().grey.x600,
  fontSize: 14,
  lineHeight: "16px",
  gap: "4px",
  padding: "16px",
  borderRadius: "8px",
  border: `1px solid ${mdsColors().grey.x100}`,
  transition: "all 0.2s ease-in-out",
  animation: `${fadeIn} 0.2s ease-in-out`,
  ":hover": {
    backgroundColor: mdsColors().grey.x25,
  },
  ":active": {
    backgroundColor: mdsColors().grey.x100,
  },
  "&[aria-selected=true]": {
    border: `1px solid ${mdsColors().primary.x500}`,
    backgroundColor: "#FFFCFD",
  },
});
