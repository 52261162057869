import { Uuid } from "@/domains/global/identifiers";
import { urlParamsModule } from "@/modules/url-params";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { SavedSearchModelData } from "@/store/saved-searches/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";

export class SavedSearchObservable extends BaseSyncModel<SavedSearchModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SavedSearchModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      title: computed,
      savedSearchParams: computed,
      savedSearchQueryString: computed,
      isTrashed: computed,
    });
  }

  // PROPERTIES
  get title() {
    return this.modelData.label;
  }

  get savedSearchParams(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.modelData.query_string,
    });
  }

  get savedSearchQueryString(): string {
    /** We parse it out-and-back to ensure consistency. */
    return urlParamsModule.search.stringify({
      searchParams: this.savedSearchParams,
    });
  }

  get isTrashed() {
    return false;
  }
}
