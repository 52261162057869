import { MdsIconKind } from "@/design-system/components/icon";

export const getIconKind = ({
  isSelected,
  isHighlighted,
  defaultIcon,
}: {
  isSelected?: boolean;
  isHighlighted?: boolean;
  defaultIcon: MdsIconKind;
}) => {
  if (isSelected) {
    return MdsIconKind.Check;
  }

  if (isHighlighted) {
    return MdsIconKind.CheckboxEmpty;
  }

  return defaultIcon;
};
