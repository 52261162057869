import { ChatItem, ChatItemKind } from "@/store/chat/types";
import type { ChatItemContext } from "@/domains/chat";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "@/components/messages/item/SectionHeader";
import { Message } from "@/components/messages/item/Message";

export interface MessageItemProps {
  /**
   * The index of the item in the list data array.
   */
  index: number;
  /**
   * The data item to render.
   */
  data: ChatItem;
  /**
   * The value of the `context` prop passed to the list.
   */
  context: ChatItemContext;

  /**
   * The previous data item (if available).
   */
  prevData: ChatItem | null;
  /**
   * The next data item (if available).
   */
  nextData: ChatItem | null;
}

export const MessageItem = observer<MessageItemProps>(function MessageItem({
  data: item,
  context,
}) {
  if (item.kind === ChatItemKind.SectionHeader) {
    return <SectionHeader sectionHeader={item.header} />;
  }

  return <Message message={item.message} inSidePanel={context.inSidePanel} />;
});
