import { uuidModule } from "@/modules/uuid";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import {
  SpaceAccountChatMessageModelData,
  SpaceAccountChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ISaveChatMessageOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";
import {
  NoteContentDocumentModelData,
  NoteContentDocumentUpsertedSyncUpdateValue,
  NoteModelData,
  NoteUpsertedSyncUpdateValue,
} from "@/store/note/types";
import { notesModule } from "@/modules/notes";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";

export class SaveChatMessageOperation extends BaseSyncOperation<ISaveChatMessageOperation> {
  get operationKind(): SyncOperationKind {
    return "SAVE_CHAT_MESSAGE";
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const spaceAccountChatMessageUuid = resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.payload.chat_message_id,
    });
    const spaceAccountChatMessage = this.store.spaceAccountChatMessages.get(
      spaceAccountChatMessageUuid
    );
    const chatMessage = this.store.chatMessages.get(this.payload.chat_message_id);
    if (!chatMessage) return [];

    // Upsert SpaceAccountChatMessage
    const spaceAccountChatMessageValue: SpaceAccountChatMessageUpsertedSyncUpdateValue = {
      model_id: spaceAccountChatMessageUuid,
      model_kind: "SPACE_ACCOUNT_CHAT_MESSAGE",
      model_version: spaceAccountChatMessage?.modelVersion ?? 0,
      model_data: {
        ...spaceAccountChatMessage?.modelData,
        space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        chat_message_id: this.payload.chat_message_id,
        saved_as_note_id: this.payload.saved_note_id,
        saved_as_note_at: this.committedAt,
        feedback_responded_at: null,
        feedback_response: null,
        feedback_selected_suggestions: [],
        feedback_user_text: null,
        sections: spaceAccountChatMessage?.modelData.sections ?? [],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const spaceAccountChatMessageUpsertedSyncUpdate: SyncUpdate<SpaceAccountChatMessageModelData> =
      {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: spaceAccountChatMessageValue,
      };

    // Create a new note
    const { primaryLabel, secondaryLabel } = notesModule.convertEncodedContentToNoteContent(
      this.payload.encoded_content
    );
    const noteUpsertedValue: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.saved_note_id,
      model_kind: "NOTE",
      model_version: 0,
      model_data: {
        trashed_at: null,
        created_at: this.committedAt,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        modified_by_space_account_ids: [],
        primary_label: primaryLabel ?? "",
        primary_label_strategy: "INFERRED_FROM_CONTENT",
        secondary_label: secondaryLabel ?? "",
        secondary_label_strategy: "INFERRED_FROM_CONTENT",
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const noteUpsertedSyncUpdate: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteUpsertedValue,
    };

    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.saved_note_id,
    });
    const noteContentDocumentUpsertedValue: NoteContentDocumentUpsertedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
      model_data: {
        note_id: this.payload.saved_note_id,
        encoded_content: this.payload.encoded_content,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const noteContentDocumentUpsertedSyncUpdate: SyncUpdate<NoteContentDocumentModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteContentDocumentUpsertedValue,
    };

    return [
      noteUpsertedSyncUpdate,
      noteContentDocumentUpsertedSyncUpdate,
      spaceAccountChatMessageUpsertedSyncUpdate,
    ];
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to save chat message as a note. Please try again.");
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to save this chat message as a note.");
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to save chat message as a note. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "An unexpected error occurred while saving the chat message as a note. Please try again."
    );
  }
}
