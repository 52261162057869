import { MdsButton } from "@/design-system/components/button";
import { MdsTextField } from "@/design-system/components/text-field";
import { css } from "@/domains/emotion";
import { api } from "@/modules/api";
import { toastModule } from "@/modules/toast";
import {
  SettingsDebugAccountInfoFormData,
  settingsDebugAccountInfoFormConfig,
} from "@/pages/settings/components/debug-account-info-form/formConfig";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";

export const SettingsDebugAccountInfoForm: FC = () => {
  const {
    register,
    handleSubmit: handleFormSubmission,
    setError,
    resetField,
    formState: { errors },
  } = useForm<SettingsDebugAccountInfoFormData>();

  const { password: passwordFieldError } = errors;

  const submitForm = handleFormSubmission(async body => {
    const result = await api.post("/v2/auth/email-password/link-account", { body });
    if (result.error)
      setError("password", {
        type: "manual",
        message: "There was an error setting the password",
      });
    else {
      toastModule.triggerToast({ content: "Password set successfully" });
      resetField("password");
    }
  });

  const handleEnterKeyPress = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const passwordFieldHandlers = register(
    "password",
    settingsDebugAccountInfoFormConfig.passwordFieldOptions
  );
  const nameFieldErrorMessage = passwordFieldError?.message;

  return (
    <>
      <div>Update Account Password</div>
      <div className={updateAccountPasswordStyles}>
        <MdsTextField
          {...passwordFieldHandlers}
          onEnterKeyPress={handleEnterKeyPress}
          placeholder="Password"
          autoFocus={true}
        />
        <MdsButton onClick={submitForm} label="Save" />
      </div>
      {nameFieldErrorMessage && <div>{nameFieldErrorMessage}</div>}
    </>
  );
};

const updateAccountPasswordStyles = css({
  marginTop: 12,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: 8,
});
