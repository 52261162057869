import { createAppRoute } from "@/app/router/utils";
import { Uuid } from "@/domains/global/identifiers";
import { ImportKind } from "@/domains/integrations/imports";

/**
 * These are all the paths that are used in the app.
 *
 * Note - when adding new paths, you should also add new navigation helper functions
 * to our NavigationStore.
 *
 *   - store.navigate.goHome()
 *   - store.navigate.goToNote({ noteId: ... })
 *   - ...
 */
export const appRoutes = {
  root: createAppRoute(`/`),
  logIn: createAppRoute(`/login`),
  logInEmailPassword: createAppRoute(`/login/email-password`),
  signUp: createAppRoute(`/sign-up`),
  logOut: createAppRoute(`/logout`),
  googleOAuthRedirect: createAppRoute(`/google-oauth-redirect`),

  /**
   * Core Workflows
   */
  chat: createAppRoute("/chat"),
  search: createAppRoute(`/search`),
  trash: createAppRoute("/trash"),

  /**
   * Note Endpoints
   */
  notesList: createAppRoute(`/notes`),
  notesView: createAppRoute<{ noteId: Uuid }>(({ noteId }) => `/notes/${noteId}`),

  /**
   * Collection Endpoints
   */
  collections: createAppRoute("/collections"),
  collectionsView: createAppRoute<{ collectionId: Uuid }>(
    ({ collectionId }) => `/collections/${collectionId}`
  ),

  /**
   * Integrations
   */
  integrations: createAppRoute("/integrations"),
  integrationsImports: createAppRoute("/integrations/imports"),
  integrationsImportsEvernote: createAppRoute(`/integrations/imports?kind=${ImportKind.EVERNOTE}`),
  integrationsImportsNotion: createAppRoute(`/integrations/imports?kind=${ImportKind.NOTION}`),
  integrationsImportsMarkdown: createAppRoute(`/integrations/imports?kind=${ImportKind.MARKDOWN}`),
  integrationsTwitter: createAppRoute("/integrations/twitter"),
  integrationsTexts: createAppRoute("/integrations/texts"),
  integrationsZapier: createAppRoute("/integrations/zapier"),
  integrationsApi: createAppRoute("/integrations/api"),
  importFrom1dot0: createAppRoute("/settings/account-migration"),

  /**
   * Settings
   */
  settings: createAppRoute("/settings"),
  settingsEmailPreferences: createAppRoute("/settings/email-preferences"),
  settingsSubscriptionManagement: createAppRoute("/settings/subscription-management"),
  settingsAccountManagement: createAppRoute("/settings/account-management"),
  settingsExports: createAppRoute("/settings/exports"),
  settingsAccountMigration: createAppRoute("/settings/account-migration"),
  settingsDebug: createAppRoute("/settings/debug"),
  settingsDebugAccount: createAppRoute("/settings/debug/account"),

  /**
   * Admin Endpoints
   */
  admin: createAppRoute(`/admin`),

  /**
   * Deprecated endpoints
   */
  home: createAppRoute(`/home`), // DEPRECATED and redirects to /notes
  everything: createAppRoute(`/everything`), // DEPRECATED and redirects to /notes
  oldNotesView: createAppRoute<{ noteId: Uuid }>(({ noteId }) => `/m/${noteId}`), // DEPRECATED and redirects to /notes/:noteId
} as const;
