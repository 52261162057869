export enum DevicePlatform {
  Web = "web",
  Mobile = "mobile",
  Unknown = "unknown",
}

export type DeviceDimensions = {
  height: number;
  width: number;
};
