import { Uuid } from "@/domains/global/identifiers";
import {
  SpaceAccountChatMessageModelData,
  SpaceAccountChatMessageSection,
} from "@/store/chat/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { makeObservable, computed } from "mobx";

export class SpaceAccountChatMessageObservable extends BaseSyncModel<SpaceAccountChatMessageModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountChatMessageModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      sections: computed,
      savedAsNoteId: computed,
      feedback: computed,
    });
  }

  get savedAsNoteId(): Uuid | undefined {
    return this.modelData.saved_as_note_id || undefined;
  }

  get sections(): SpaceAccountChatMessageSection[] {
    return this.modelData.sections || [];
  }

  get feedback() {
    return {
      response: this.modelData.feedback_response,
      selected_suggestions: this.modelData.feedback_selected_suggestions,
      user_text: this.modelData.feedback_user_text,
      responded_at: this.modelData.feedback_responded_at,
    };
  }
}
