import React, { memo, forwardRef, useMemo, useRef, useCallback } from "react";

import { MdsTextFieldFloatingPlaceholder } from "@/design-system/components/text-field/MdsTextFieldFloatingPlaceholder";
import { _useMdsTextFieldChangeHandlers } from "@/design-system/components/text-field/utils/changeHandlers";
import {
  OnBlur,
  OnChange,
  OnFocus,
  ReactChangeEvent,
  ReactKeyboardEvent,
} from "@/design-system/constants/handlers/types";
import {
  mdsFontSizes,
  mdsFontWeights,
  mdsBorderRadius,
  mdsColors,
} from "@/design-system/foundations";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { css, cx } from "@/domains/emotion";
import { MdsTextFieldError } from "@/design-system/components/text-field/MdsTextFieldError";
import { useMergeRefs } from "@/domains/react/useMergeRefs";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";

export enum MdsTextFieldSize {
  Standard = "medium",
  Large = "large",
}

export interface MdsTextFieldProps extends EmotionClassStyles {
  name?: string;
  value?: string;
  onFocus?: OnFocus;
  onBlur?: OnBlur;
  onChange?: OnChange<ReactChangeEvent<HTMLInputElement>>;
  onClearText?: () => void;
  onEnterKeyPress?: OnChange<ReactKeyboardEvent<HTMLInputElement>>;
  onEscapeKeyPress?: OnChange<ReactKeyboardEvent<HTMLInputElement>>;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  size?: MdsTextFieldSize;
  errorMessage?: string;
  grow?: boolean;
  floatingPlaceholder?: boolean;
  iconKind?: MdsIconKind;
  showClearTextButton?: boolean;
}

export const MdsTextField = memo(
  forwardRef<HTMLInputElement, MdsTextFieldProps>((props, propRef) => {
    const {
      value,
      name,
      disabled,
      readOnly,
      placeholder,
      className,
      grow,
      size = MdsTextFieldSize.Standard,
      errorMessage,
      floatingPlaceholder,
      iconKind,
      showClearTextButton,
      onClearText,
    } = props;

    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRef = useMergeRefs([propRef, innerRef]);

    const dynamicWrapperStyles = css({
      width: grow ? "100%" : "auto",
    });

    const dynamicStyles = css({
      height: size === MdsTextFieldSize.Standard ? 36 : 48,
      "&:disabled": {
        transition: "background-color 1s ease",
        backgroundColor: readOnly ? "initial" : mdsColors().grey.x100,
        color: mdsColors().grey.x400,
      },
    });

    const dynamicFloatingStyles = css({
      top: size === MdsTextFieldSize.Standard ? 0 : -6,
    });

    const { onChangeHandler, onBlurHandler, onFocusHandler, onKeyPressHandler } =
      _useMdsTextFieldChangeHandlers(props);

    const isDisabled = disabled || readOnly;
    const shouldShowFloatingPlaceholder = useMemo(
      () => Boolean(floatingPlaceholder) && Boolean(value),
      [floatingPlaceholder, value]
    );
    const combinedWrapperStyles = cx(wrapperStyles, dynamicWrapperStyles, className);
    const combinedInputStyles = cx(inputStyles, dynamicStyles);

    const onClick = useCallback(() => {
      innerRef.current?.focus();
    }, []);

    const handleClearText = useCallback(() => {
      onClearText?.();
      if (innerRef.current) {
        innerRef.current.value = "";
        innerRef.current.focus();
      }
    }, [onClearText]);

    return (
      <div className={combinedWrapperStyles} onClick={onClick}>
        <MdsTextFieldFloatingPlaceholder
          className={dynamicFloatingStyles}
          shouldShowFloatingPlaceholder={shouldShowFloatingPlaceholder}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />

        {iconKind ? (
          <div className={iconWrapperStyles}>
            <MdsIcon kind={iconKind} />
          </div>
        ) : null}

        <input
          name={name}
          placeholder={placeholder}
          className={combinedInputStyles}
          value={value}
          ref={combinedRef}
          disabled={isDisabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onKeyDown={onKeyPressHandler}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
        />

        {showClearTextButton ? (
          <MdsIconButton
            variant={MdsIconButtonVariant.Transparent}
            size={MdsIconButtonSize.XSmall}
            iconKind={MdsIconKind.Exit}
            className={clearTextButtonStyles}
            onClick={handleClearText}
            isDisabled={!value}
          />
        ) : null}

        <MdsTextFieldError errorMessage={errorMessage} />
      </div>
    );
  })
);

const wrapperStyles = css({
  paddingLeft: 4,
  paddingRight: 4,
  position: "relative",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "flex-start",
  cursor: "text",
  transition: "0.08s ease",
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
  },
  "&:placeholder": {
    color: mdsColors().grey.x400,
  },
  backgroundColor: mdsColors().grey.x0,
  color: mdsColors().grey.x700,
  borderColor: mdsColors().grey.x100,
  borderWidth: 1,
  borderStyle: "solid",
  "&:hover": {
    borderColor: mdsColors().secondary.x400,
  },
  "&:focus": {
    borderColor: mdsColors().secondary.x400,
  },
  userSelect: "none",
  borderRadius: mdsBorderRadius().medium,
});

const inputStyles = css({
  display: "block",
  paddingLeft: 8,
  paddingRight: 8,
  fontWeight: mdsFontWeights().regular,
  fontSize: mdsFontSizes().small,
});

const iconWrapperStyles = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: 12,
});

const clearTextButtonStyles = css({
  marginRight: 6,
  maxWidth: 24,
  maxHeight: 24,
});
