import { useMemo, FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import {
  MdsBadgeSize,
  MdsBadgeKind,
  MdsBadgeVariant,
} from "@/design-system/components/badge/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { mdsGradients } from "@/design-system/foundations/gradient";
import { mdsFontWeights, mdsFontSizes } from "@/design-system/foundations/typography";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsTooltip, MdsTooltipConfig } from "@/design-system/components/tooltip";

export interface MdsBadgeProps extends EmotionClassStyles {
  label: string;
  size?: MdsBadgeSize;
  kind?: MdsBadgeKind;
  variant?: MdsBadgeVariant;
  iconKind?: MdsIconKind;
  tooltipConfig?: MdsTooltipConfig;
}

const wrapperStyles = css({
  height: "100%",
  width: "fit-content",
  backgroundColor: mdsColors().grey.x100,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "nowrap",
  borderRadius: mdsBorderRadius().medium,
  whiteSpace: "nowrap",
  userSelect: "none",
});

const badgeSizeStyleMapping: { [key in MdsBadgeSize]: string } = {
  [MdsBadgeSize.Medium]: css({
    height: 24,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: mdsFontSizes().xsmall,
  }),
  [MdsBadgeSize.Small]: css({
    height: 18,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: mdsFontSizes().xxxsmall,
  }),
  [MdsBadgeSize.RoundedSmall]: css({
    borderRadius: mdsBorderRadius().large,
    height: 18,
    paddingLeft: 5,
    paddingRight: 7,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: mdsFontSizes().xxxsmall,
  }),
};

const badgeKindStyleMapping: { [key in MdsBadgeKind]: string } = {
  [MdsBadgeKind.Brand]: css({
    color: mdsColors().grey.x0,
    background: mdsGradients().brand.normal,
  }),
  [MdsBadgeKind.Primary]: css({
    color: mdsColors().primary.x500,
    backgroundColor: mdsColors().primary.x200,
  }),
  [MdsBadgeKind.Secondary]: css({
    color: mdsColors().secondary.x500,
    backgroundColor: mdsColors().secondary.x100,
  }),
  [MdsBadgeKind.Tertiary]: css({
    color: mdsColors().grey.x700,
    backgroundColor: mdsColors().grey.x25,
  }),
};

const iconWrapperSizeStyleMapping: { [key in MdsBadgeSize]: string } = {
  [MdsBadgeSize.Medium]: css({
    marginRight: 6,
  }),
  [MdsBadgeSize.Small]: css({
    marginRight: 2,
  }),
  [MdsBadgeSize.RoundedSmall]: css({}),
};

const iconSizeStyleMapping: { [key in MdsBadgeSize]: string } = {
  [MdsBadgeSize.Medium]: css({
    color: "unset",
  }),
  [MdsBadgeSize.Small]: css({
    color: "unset",
    height: 12,
  }),
  [MdsBadgeSize.RoundedSmall]: css({
    color: "unset",
    height: 10,
  }),
};

const variantStyleMapping: { [key in MdsBadgeVariant]: string } = {
  [MdsBadgeVariant.Standard]: css({
    fontWeight: mdsFontWeights().semiBold,
  }),
  [MdsBadgeVariant.Light]: css({
    fontWeight: mdsFontWeights().medium,
  }),
};

export const MdsBadge: FC<MdsBadgeProps> = ({
  label,
  size = MdsBadgeSize.Medium,
  kind = MdsBadgeKind.Secondary,
  variant = MdsBadgeVariant.Standard,
  iconKind,
  tooltipConfig,
  className,
}) => {
  const sizeStyles = badgeSizeStyleMapping[size];
  const kindStyles = badgeKindStyleMapping[kind];
  const iconWrapperStyles = iconWrapperSizeStyleMapping[size];
  const iconStyles = iconSizeStyleMapping[size];
  const variantStyles = variantStyleMapping[variant];

  const combinedStyles = cx(wrapperStyles, sizeStyles, kindStyles, variantStyles, className);

  const iconContent = useMemo(() => {
    if (!iconKind) {
      return null;
    }

    return (
      <span className={iconWrapperStyles}>
        <MdsIcon kind={iconKind} innerStyles={{ Icon: { className: iconStyles } }} />
      </span>
    );
  }, [iconKind, iconWrapperStyles, iconStyles]);

  const fullBadgeContent = useMemo(() => {
    return (
      <div className={combinedStyles}>
        {iconContent}
        <span>{label}</span>
      </div>
    );
  }, [combinedStyles, iconContent, label]);

  if (tooltipConfig) {
    return <MdsTooltip config={tooltipConfig}>{fullBadgeContent}</MdsTooltip>;
  }

  return fullBadgeContent;
};
