import { observer } from "mobx-react-lite";

import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsText } from "@/design-system/components/text";
import { MdsTextOverflow, MdsTextSize } from "@/design-system/components/text/types";
import { MdsDraggableListItem } from "@/design-system/components/draggable-list/types";
import { css } from "@/domains/emotion";

export interface MdsDraggableItemContentProps {
  item: MdsDraggableListItem;
}

export const MdsDraggableItemContent = observer<MdsDraggableItemContentProps>(
  function MdsDraggableItemContent({ item }) {
    return (
      <>
        <MdsIcon kind={item.iconKind} />
        <MdsText
          size={MdsTextSize.Small}
          overflow={MdsTextOverflow.Ellipsis}
          className={css({ width: "100%", marginRight: "8px" })}
        >
          {item.label}
        </MdsText>
        <div className="grip">
          <MdsIcon kind={MdsIconKind.Grip} />
        </div>
      </>
    );
  }
);
