import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveNoteFromFavoritesOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class RemoveNoteFromFavoritesOperation extends BaseSyncOperation<IRemoveNoteFromFavoritesOperation> {
  get operationKind(): SyncOperationKind {
    return "REMOVE_NOTE_FROM_FAVORITES";
  }

  get successToastMessage() {
    return <>Removed {this.mediumTitle} from your pins</>;
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const favoriteItemId = uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.note_id,
    });
    const favoriteItemObservable = this.store.favoriteItems.get(favoriteItemId);
    if (!favoriteItemObservable) return [];

    const value: FavoriteItemDeletedSyncUpdateValue = {
      model_id: favoriteItemId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
    };
    const syncUpdate: SyncUpdate<FavoriteItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value,
    };
    return [syncUpdate];
  }

  get title() {
    return this.store.notes.get(this.payload.note_id)?.title || UNTITLED_NOTE_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  protected getToastMessage() {
    return (
      <>
        {this.mediumTitle} could not be removed from your pins. If this error continues, please
        contact support.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }
}
