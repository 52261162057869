import { mdsColors } from "@/design-system/foundations/colors";
import { ZIndex } from "@/domains/design/constants";
import { css, cx } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC, ReactNode, useMemo } from "react";

const wrapperStyles = css({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: ZIndex.ScreenOverlay,
  opacity: 1,
  background: mdsColors().grey.x0,
  transition: "opacity 1.08s ease",
});

interface ScreenOverlayProps {
  hideOverlay?: boolean;
  removeOverlay?: boolean;
  children: ReactNode;
}

export const ScreenOverlay: FC<ScreenOverlayProps> = observer(
  ({ children, hideOverlay, removeOverlay }) => {
    const combinedStyles = useMemo(
      () =>
        cx(
          wrapperStyles,
          hideOverlay && css({ opacity: 0 }),
          removeOverlay && css({ display: "none" })
        ),
      [hideOverlay, removeOverlay]
    );

    return <div className={combinedStyles}>{children}</div>;
  }
);
