import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IMakeCollectionPrivateOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind } from "@/store/sync/types";

export class MakeCollectionPrivateOperation extends BaseSyncOperation<IMakeCollectionPrivateOperation> {
  get operationKind(): SyncOperationKind {
    return "MAKE_COLLECTION_PRIVATE";
  }

  get successToastMessage() {
    return <>Access updated to {this.mediumTitle} and converted to a private collection.</>;
  }

  get title() {
    return this.store.collections.get(this.payload.id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to update access to {this.mediumTitle}. Please contact the note
        owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
