import { Uuid } from "@/domains/global/identifiers";
import { isString } from "lodash-es";
import { validate } from "uuid";

export const _stringIsValidUuid = (value: unknown): value is Uuid => {
  if (!isString(value)) {
    return false;
  }

  if (!validate(value)) {
    return false;
  }

  return true;
};
