import { appRoutes } from "@/app/router";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateCollectionOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class CreateCollectionOperation extends BaseSyncOperation<ICreateCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_COLLECTION";
  }

  // To support optional fields in the payload, we need to override the constructor.
  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
  }: Omit<BaseSyncOperationParams<ICreateCollectionOperation>, "payload"> & {
    payload: Partial<ICreateCollectionOperation["payload"]>;
  }) {
    super({
      store,
      operationId: operationId ?? uuidModule.generate(),
      committedAt,
      latestSpaceAccountSequenceId,
      payload: {
        id: payload.id ?? uuidModule.generate(),
        description: payload.description ?? "",
        title: payload.title ?? "",
        schema_version: 1,
      },
    });
  }

  generateOptimisticUpdates(): SyncUpdate<CollectionItemModelData>[] {
    const value: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: 0,
      model_data: {
        title: this.payload.title,
        description: this.payload.description ?? "",
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        last_updated_at: this.committedAt,
        last_removed_from_at: null,
        last_added_to_at: null,
        shared_at: null,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: SyncUpdate<CollectionItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.syncErrorModalFieldsGenerator);
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.syncErrorModalFieldsGenerator);
  }

  get syncErrorModalFieldsGenerator() {
    return (store: AppStore) =>
      new CreateCollectionSyncErrorModalFieldsGenerator(store, this.payload.id);
  }
}

class CreateCollectionSyncErrorModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected collectionId: string;

  constructor(store: AppStore, collectionId: string) {
    super(store);

    this.collectionId = collectionId;
  }

  get title() {
    const collection = this.store.collections.get(this.collectionId);
    return `“${collection?.title || UNTITLED_COLLECTION_TITLE}” could not be created`;
  }

  get message() {
    return `The collection will be removed from your Mem`;
  }

  modalActionHandler = () => {
    this.store.sync.actionQueue.skipAndRevertUnsyncedOperationsForModelId(this.collectionId);
    this.store.navigation.goUpIfActivePathIs(
      appRoutes.collectionsView({ params: { collectionId: this.collectionId } }).path
    );
  };
}
