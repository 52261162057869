import { action, computed, makeObservable, observable } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { NoteObservable } from "@/store/note/NoteObservable";
import { actions } from "@/actions";

export class DeleteSharedNotesModalStore extends AppSubStore {
  public notes: NoteObservable[] = [];
  private handleConfirm?: () => void;
  private onClose?: () => void;

  constructor(
    injectedDeps: AppSubStoreArgs,
    { handleConfirm, onClose }: { handleConfirm?: () => void; onClose?: () => void }
  ) {
    super(injectedDeps);

    this.handleConfirm = handleConfirm;
    this.onClose = onClose;

    makeObservable<this, "handleConfirm" | "onClose">(this, {
      handleConfirm: false,
      onClose: false,
      notes: observable,
      isOpen: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get isOpen() {
    return this.notes.length > 0;
  }

  public handleSubmit() {
    if (!this.notes.length) return;
    actions.moveNotesToTrash({ notes: this.notes, store: this.store });
    this.handleConfirm?.();
    this.close();
  }

  public open(...notes: NoteObservable[]) {
    this.notes = notes;
  }

  public close() {
    this.notes = [];
    this.onClose?.();
  }
}
