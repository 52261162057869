import { useMemo, FC } from "react";

import {
  MdsDotPulseLoaderKind,
  MdsDotPulseLoaderSize,
} from "@/design-system/components/loader/types";
import { css, keyframes } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations/colors";

export interface MdsDotPulseLoaderProps {
  kind?: MdsDotPulseLoaderKind;
  size?: MdsDotPulseLoaderSize;
}

const wrapperStyles = css({
  position: "absolute",
  width: "10px",
  height: "10px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
});

export const MdsDotPulseLoader: FC<MdsDotPulseLoaderProps> = ({
  kind = MdsDotPulseLoaderKind.Dark,
  size = MdsDotPulseLoaderSize.Medium,
}) => {
  let DOT_PULSE_MAX_SIZE = 3;
  let DOT_PULSE_MIN_SIZE = 2;

  if (size === MdsDotPulseLoaderSize.Small) {
    DOT_PULSE_MAX_SIZE = 2;
    DOT_PULSE_MIN_SIZE = 1.5;
  } else if (size === MdsDotPulseLoaderSize.Medium) {
    DOT_PULSE_MAX_SIZE = 3;
    DOT_PULSE_MIN_SIZE = 2;
  }

  const DOT_PULSE_OFFSET = 9999;
  const DOT_PULSE_SPACING = DOT_PULSE_MAX_SIZE * 3 - 2;
  const BEFORE_DOT_PULSE_OFFSET = DOT_PULSE_OFFSET - DOT_PULSE_SPACING - DOT_PULSE_MAX_SIZE * 3;
  const AFTER_DOT_PULSE_OFFSET = DOT_PULSE_OFFSET + DOT_PULSE_SPACING + DOT_PULSE_MAX_SIZE + 5;

  const dotLoaderStyles = useMemo(() => {
    const dotPulseColor = (() => {
      if (kind === MdsDotPulseLoaderKind.Light) {
        return mdsColors().grey.x0;
      } else if (kind === MdsDotPulseLoaderKind.Brand) {
        return mdsColors().primary.x500;
      }

      return mdsColors().grey.x700;
    })();

    const dotPulseBeforeAnimation = keyframes({
      "0%": {
        boxShadow: `${BEFORE_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
      "30%": {
        boxShadow: `${BEFORE_DOT_PULSE_OFFSET}px 0 0 ${DOT_PULSE_MIN_SIZE}px ${dotPulseColor}`,
      },
      "60%,100%": {
        boxShadow: `${BEFORE_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
    });

    const dotPulseAnimation = keyframes({
      "0%": {
        boxShadow: `9999px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
      "30%": {
        boxShadow: `9999px 0 0 ${DOT_PULSE_MIN_SIZE}px ${dotPulseColor}`,
      },
      "60%,100%": {
        boxShadow: `9999px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
    });

    const dotPulseAfterAnimation = keyframes({
      "0%": {
        boxShadow: `${AFTER_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
      "30%": {
        boxShadow: `${AFTER_DOT_PULSE_OFFSET}px 0 0 ${DOT_PULSE_MIN_SIZE}px ${dotPulseColor}`,
      },
      "60%,100%": {
        boxShadow: `${AFTER_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      },
    });

    /**
     * Implementation inspired by: https://github.com/nzbin/three-dots
     */
    return css({
      position: "absolute",
      left: `-${DOT_PULSE_OFFSET - DOT_PULSE_MAX_SIZE + 2}px`,
      width: DOT_PULSE_MAX_SIZE * 2,
      height: DOT_PULSE_MAX_SIZE * 2,
      borderRadius: DOT_PULSE_MAX_SIZE,
      backgroundColor: dotPulseColor,
      color: dotPulseColor,
      boxShadow: `${DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
      animation: `${dotPulseAnimation} 1.5s infinite linear`,
      animationDelay: ".25s",

      "&::before, &::after": {
        content: "''",
        display: "inline-block",
        position: "absolute",
        top: "0",
        width: DOT_PULSE_MAX_SIZE * 2,
        height: DOT_PULSE_MAX_SIZE * 2,
        borderRadius: DOT_PULSE_MAX_SIZE,
        backgroundColor: dotPulseColor,
        color: dotPulseColor,
      },

      "&::before": {
        boxShadow: `${BEFORE_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
        animation: `${dotPulseBeforeAnimation} 1.5s infinite linear`,
        animationDelay: "0s",
      },

      "&::after": {
        boxShadow: `${AFTER_DOT_PULSE_OFFSET}px 0 0 -${DOT_PULSE_MAX_SIZE}px ${dotPulseColor}`,
        animation: `${dotPulseAfterAnimation} 1.5s infinite linear`,
        animationDelay: ".5s",
      },
    });
  }, [
    AFTER_DOT_PULSE_OFFSET,
    BEFORE_DOT_PULSE_OFFSET,
    DOT_PULSE_MAX_SIZE,
    DOT_PULSE_MIN_SIZE,
    kind,
  ]);

  return (
    <span className={wrapperStyles}>
      <span className={dotLoaderStyles} />
    </span>
  );
};
