import { MdsTabBarHorizontalItemProps } from "@/design-system/components/tab-bar-horizontal/types";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import styled from "@emotion/styled";

export const MdsTabBarHorizontalItem = ({
  id,
  label,
  onClick,
  isActive,
}: MdsTabBarHorizontalItemProps) => {
  return (
    <Button onClick={() => onClick({ itemId: id })} isActive={isActive}>
      <MdsText
        size={MdsTextSize.Small}
        weight={MdsTextWeight.SemiBold}
        color={isActive ? mdsColors().grey.x600 : mdsColors().grey.x400}
      >
        {label}
      </MdsText>
    </Button>
  );
};

const Button = styled.button<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: "flex",
  padding: theme.spacing.sm,
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  borderBottom: isActive ? `2px solid ${theme.colors.grey.x600}` : "2px solid transparent",
  "&:hover": {
    borderBottom: `2px solid ${theme.colors.grey.x400}`,
  },
  "&:active": {
    borderBottom: `2px solid ${theme.colors.grey.x600}`,
  },
}));
