import styled from "@emotion/styled";

interface ContainerProps {
  hasBorder?: boolean;
}

export const Panel = styled.div<ContainerProps>(({ hasBorder }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
  ...(hasBorder
    ? {
        border: `1px solid #f8f8fa`,
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
        boxShadow: `0px 2px 4px rgba(69, 79, 104, 0.1)`,
        width: "100%",
      }
    : {}),
}));
