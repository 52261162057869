import { CACHE_DB_IDENTIFIER, CACHE_VERSION } from "@/modules/cache/constants";
import { CacheClient } from "@/manager/cache-client/CacheClient";

export const initializeCacheClient = async () => {
  const db = new CacheClient({
    databaseId: CACHE_DB_IDENTIFIER,
    version: CACHE_VERSION,
  });

  await db.initialize();

  return db;
};
