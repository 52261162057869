import { computed, makeObservable, observable, runInAction, action } from "mobx";
import { OnboardingStage, PlatformOnboardingState } from "@/store/platform-info/types";
import { ApiSchema } from "@/modules/api";
import { PlatformOnboardingInfoProfileObservable } from "@/store/platform-info/PlatformOnboardingInfoProfileObservable";
import { isNull } from "lodash-es";
import { WithAppStore } from "@/store/types";
import { AppStore } from "@/store";

export class PlatformOnboardingInfoObservable {
  store: AppStore;
  onboardingState: PlatformOnboardingState;
  onboardingProfile: PlatformOnboardingInfoProfileObservable;
  activeOnboardingStage: OnboardingStage;

  constructor({
    onboardingState,
    onboardingProfile,
    store,
  }: {
    onboardingState: PlatformOnboardingState;
    onboardingProfile: PlatformOnboardingInfoProfileObservable;
  } & WithAppStore) {
    this.onboardingState = onboardingState;
    this.onboardingProfile = onboardingProfile;
    this.store = store;

    this.activeOnboardingStage = PlatformOnboardingInfoObservable.calculateOnboardingStage({
      onboardingProfile: this.onboardingProfile,
    });

    makeObservable(this, {
      store: false,
      onboardingState: observable,
      onboardingProfile: observable,
      activeOnboardingStage: observable,
      onboardingIsCompleted: computed,
      setDesiredUseCases: action,
      setJobRole: action,
      setReferrer: action,
      saveAndContinue: action,
    });
  }

  get onboardingIsCompleted() {
    return this.onboardingState === PlatformOnboardingState.OnboardingCompleted;
  }

  setDesiredUseCases({ desiredUseCases }: { desiredUseCases: string[] }) {
    runInAction(() => {
      this.onboardingProfile.desiredUseCases = desiredUseCases;
    });
  }

  setJobRole({ jobRole }: { jobRole: string }) {
    runInAction(() => {
      this.onboardingProfile.jobRole = jobRole;
    });
  }

  setReferrer({ referrer }: { referrer: string }) {
    runInAction(() => {
      this.onboardingProfile.referrer = referrer;
    });
  }

  async saveAndContinue() {
    /**
     * If we are on the last stage, we want to complete the onboarding,
     * instead of just upserting.
     */
    if (this.activeOnboardingStage == OnboardingStage.ActivateTrial) {
      runInAction(() => {
        this.onboardingState = PlatformOnboardingState.OnboardingCompleted;
      });

      await this.store.platformInfo.completeOnboardingAndActivatePlatformTrial();

      return;
    }

    await this.store.platformInfo.upsertOnboardingProfile({
      onboardingProfile: this.onboardingProfile,
    });

    runInAction(() => {
      this.activeOnboardingStage = PlatformOnboardingInfoObservable.calculateOnboardingStage({
        onboardingProfile: this.onboardingProfile,
      });
    });
  }

  public static calculateOnboardingStage({
    onboardingProfile,
  }: {
    onboardingProfile: PlatformOnboardingInfoProfileObservable;
  }) {
    if (isNull(onboardingProfile.desiredUseCases)) {
      return OnboardingStage.SelectUseCases;
    }

    if (isNull(onboardingProfile.jobRole)) {
      return OnboardingStage.SelectJob;
    }

    if (isNull(onboardingProfile.referrer)) {
      return OnboardingStage.SelectReferrer;
    }

    return OnboardingStage.ActivateTrial;
  }

  public static createFromRemoteData({
    data,
    store,
  }: { data: ApiSchema["PlatformInfoOnboarding"] } & WithAppStore) {
    const onboardingProfile = PlatformOnboardingInfoProfileObservable.createFromRemoteData({
      data: data.onboarding_profile,
    });

    return new PlatformOnboardingInfoObservable({
      onboardingState: data.onboarding_state as PlatformOnboardingState,
      onboardingProfile: onboardingProfile,
      store,
    });
  }
}
