import table from "@/domains/local-db/account/sync/adapter";
import config from "@/domains/local-db/account/sync/config";
import { PreloadingState } from "@/store/sync/types";

export const getNoteContentPreloadingState = async (): Promise<PreloadingState | null> => {
  const noteContentPreloadingStateResponse = await table.get(
    config.NOTE_CONTENT_PRELOADING_STATE_KEY
  );

  if (!noteContentPreloadingStateResponse) return null;

  const noteContentPreloadingState =
    noteContentPreloadingStateResponse[config.NOTE_CONTENT_PRELOADING_STATE_KEY];

  if (!noteContentPreloadingState) return null;

  return noteContentPreloadingState;
};
