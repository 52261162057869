import { PusherClient } from "@/modules/pusher-client/types";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store/GuestAppStore";
import { PublicAppStore } from "@/store/PublicAppStore";

export interface WithAppStore<Store = AppStore> {
  store: Store;
}

export interface WithPublicAppStore {
  store: PublicAppStore;
}

export type WithGuestAppStore = WithAppStore<GuestAppStore>;

/** @TODO UPDATE THIS. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiClient = any;

export interface WithApiClient {
  api: ApiClient;
}

export interface WithPusherClient {
  pusher: PusherClient;
}

interface SharedArgs extends WithApiClient, WithPusherClient {}

export interface AppStoreConstructorArgs extends SharedArgs {
  publicAppStore: PublicAppStore;
}

export interface PageStoreConstructorArgs extends AppStoreConstructorArgs {
  store: AppStore;
}

export interface PublicAppStoreConstructorArgs extends SharedArgs {}

export interface PublicAppSubStoreArgs extends WithPublicAppStore, SharedArgs {}

export interface GuestAppStoreConstructorArgs extends SharedArgs {
  publicAppStore: PublicAppStore;
}

export interface GuestAppSubStoreArgs extends WithGuestAppStore, SharedArgs {}

export interface AppSubStoreArgs<Store = AppStore> extends WithAppStore<Store>, SharedArgs {}

export class AppSubStore<Store extends AppStore | GuestAppStore = AppStore> {
  store: Store;
  api: ApiClient;
  pusher: PusherClient;

  constructor({ store, api, pusher }: AppSubStoreArgs<Store>) {
    this.store = store;
    this.api = api;
    this.pusher = pusher;
  }

  public stopSync() {}

  public resetSync() {}
}

export class PublicAppSubStore {
  store: PublicAppStore;
  api: ApiClient;
  pusher: PusherClient;

  constructor({ store, api, pusher }: PublicAppSubStoreArgs) {
    this.store = store;
    this.api = api;
    this.pusher = pusher;
  }
}

export class GuestAppSubStore {
  store: GuestAppStore;
  api: ApiClient;
  pusher: PusherClient;

  constructor({ store, api, pusher }: GuestAppSubStoreArgs) {
    this.store = store;
    this.api = api;
    this.pusher = pusher;
  }
}
