import { Uuid } from "@/domains/global/identifiers";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import {
  SyncModelData,
  SyncUpdate,
  SyncUpdateValue,
  SyncUpdatePartialValue,
  SyncModelKind,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { ObservableMap, action, computed, makeObservable, observable } from "mobx";

export abstract class BaseSyncModelStore<
  SyncModel extends BaseSyncModel<ModelData>,
  ModelData extends SyncModelData,
> extends AppSubStore {
  public remotePool: ObservableMap<Uuid, SyncModel> = new ObservableMap<Uuid, SyncModel>();
  public optimisticPool: ObservableMap<Uuid, SyncModel> = new ObservableMap<Uuid, SyncModel>();
  public modelKind: SyncModelKind;

  public constructor({
    modelKind,
    ...injectedDeps
  }: { modelKind: SyncModelKind } & AppSubStoreArgs) {
    super(injectedDeps);
    this.modelKind = modelKind;

    makeObservable(this, {
      remotePool: observable,
      optimisticPool: observable,
      modelKind: observable,
      doesNotExist: false,
      createSyncModel: false,
      get: false,
      processSyncUpdate: action,
      pool: computed,
      resetSync: action,
    });
  }

  public doesNotExist = (id?: string) => {
    const model = id && this.get(id);
    return this.store.sync.isUpToDate && id ? !model || model.isDeleted : undefined;
  };

  abstract createSyncModel(updateValue: SyncUpdateValue<ModelData>): SyncModel;

  public get(id: Uuid): SyncModel | undefined {
    return this.pool.get(id);
  }

  public get pool() {
    const output = new Map<Uuid, SyncModel>(this.remotePool);
    const optimisticUpdates =
      this.store.sync.actionQueue.optimisticUpdatesByModelKind.get(this.modelKind) || [];

    for (const update of optimisticUpdates) {
      if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED") {
        const updateValue = update.value as SyncUpdateValue<ModelData>;
        // If the model is in the output, just let the Observable update itself
        if (!output.has(updateValue.model_id)) {
          if (this.optimisticPool.has(updateValue.model_id)) {
            const observable = this.optimisticPool.get(updateValue.model_id)!;
            output.set(updateValue.model_id, observable);
          } else {
            const observable = this.createSyncModel(updateValue);
            this.optimisticPool.set(updateValue.model_id, observable);
            output.set(updateValue.model_id, observable);
          }
        }
      }
      if (update.kind === "DELETED") {
        const updateValue = update.value as SyncUpdatePartialValue;
        const observable =
          this.remotePool.get(update.value.model_id) ||
          this.optimisticPool.get(update.value.model_id);
        if (observable) observable.deleteFromRemote();
        output.delete(updateValue.model_id);
      }
      if (update.kind === "ACL_REVOKED") {
        const observable =
          this.remotePool.get(update.value.model_id) ||
          this.optimisticPool.get(update.value.model_id);
        if (observable) observable.setCanAccess(false);
      }
    }
    return output;
  }

  public processSyncUpdate(update: SyncUpdate<ModelData>) {
    switch (update.kind) {
      case "ACL_UPSERTED":
      case "UPSERTED": {
        const updateValue = update.value as SyncUpdateValue<ModelData>;
        if (this.remotePool.has(updateValue.model_id)) {
          const observable = this.remotePool.get(updateValue.model_id)!;
          // Due to the new hydration process, we may receive updates with a lower model version
          if (observable.modelVersion < updateValue.model_version) {
            observable.updateFromRemote({ data: updateValue });
          }
        } else if (this.optimisticPool.has(updateValue.model_id)) {
          const observable = this.optimisticPool.get(updateValue.model_id)!;
          observable.updateFromRemote({ data: updateValue });
          this.remotePool.set(updateValue.model_id, observable);
          this.optimisticPool.delete(updateValue.model_id);
        } else {
          const syncModel = this.createSyncModel(updateValue);
          this.remotePool.set(updateValue.model_id, syncModel);
        }
        break;
      }
      case "DELETED": {
        const observable =
          this.remotePool.get(update.value.model_id) ||
          this.optimisticPool.get(update.value.model_id);
        if (observable) observable.deleteFromRemote();
        this.remotePool.delete(update.value.model_id);
        break;
      }
      case "ACL_REVOKED": {
        const updateValue = update.value as SyncUpdateValue<ModelData>;
        const modelId = updateValue.model_id;
        const observable = this.remotePool.get(modelId) ?? this.optimisticPool.get(modelId);
        observable?.setCanAccess(false);
        break;
      }
    }
  }

  public resetSync() {
    this.remotePool.clear();
    this.optimisticPool.clear();
  }
}
