import { AssertTrue, ValidateEnumMatchesType } from "@/domains/typing/types";
import {
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFilter,
  ValidSearchEngineRequestFacetFilterKind,
  ValidSearchEngineRequestFilterKind,
  ValidSearchSortByValue,
} from "@/store/pages/SearchPageStore/types";
import { ApiSchema } from "@/modules/api";

export type SearchEngineRequest = ApiSchema["SearchEngineRequest"];
export type SearchEngineResponse = ApiSchema["SearchEngineResponse"];
export type SearchEngineResult = ApiSchema["SearchEngineResult"];

export type SearchEngineParams = {
  queryString: string;
  sortBy: SearchEngineSortByKind;
  filters: SearchEngineRequestFilter[];
  facetFilters: SearchEngineRequestFacetFilter[];
};

export type SearchEnginePaginationInfo = {
  search_session_cursor?: string;
  session_id: string;
};

export enum SearchEngineSortByKind {
  Relevance = "RELEVANCE",
  LastCreated = "LAST_CREATED",
  LastModified = "LAST_MODIFIED",
}

type _SearchEngineSortBy = AssertTrue<
  ValidateEnumMatchesType<Exclude<ValidSearchSortByValue, undefined>, SearchEngineSortByKind>
>;

export const DEFAULT_SEARCH_SYSTEM_SORT_BY_KIND = SearchEngineSortByKind.Relevance;

export enum SearchEngineFilterKind {
  CreatedAfter = "CREATED_AFTER",
  CreatedBefore = "CREATED_BEFORE",
  ModifiedAfter = "MODIFIED_AFTER",
  ModifiedBefore = "MODIFIED_BEFORE",
}

type _SearchEngineFilter = AssertTrue<
  ValidateEnumMatchesType<ValidSearchEngineRequestFilterKind, SearchEngineFilterKind>
>;

export enum SearchEngineFacetFilterKind {
  OwnedBySpaceAccount = "OWNED_BY_SPACE_ACCOUNT",
  ModifiedBySpaceAccount = "MODIFIED_BY_SPACE_ACCOUNT",
  InCollection = "IN_COLLECTION",
  ContainsLinkKind = "CONTAINS_LINK_KIND",
  ContainsMediaKind = "CONTAINS_MEDIA_KIND",
  FromCaptureSource = "FROM_CAPTURE_SOURCE",
}

type _SearchEngineFacetFilter = AssertTrue<
  ValidateEnumMatchesType<ValidSearchEngineRequestFacetFilterKind, SearchEngineFacetFilterKind>
>;

/** @todo - Make this consistent. */
export enum SearchEngineParamKey {
  QueryStr = "q",
  SortBy = "sort_by",
  Filter = "filter",
  FacetFilter = "facet-filter",
}

export const SEARCH_ENGINE_PARAM_KEY_SORT_ORDER: Record<SearchEngineParamKey, number> = {
  [SearchEngineParamKey.QueryStr]: 0,
  [SearchEngineParamKey.SortBy]: 1,
  [SearchEngineParamKey.Filter]: 2,
  [SearchEngineParamKey.FacetFilter]: 3,
};
