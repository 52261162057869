import { AnimationProps, HTMLMotionProps, motion } from "framer-motion";
import React, { forwardRef } from "react";

const initialTooltipOuterStyles: AnimationProps["initial"] = {
  opacity: 0,
};

const animateTooltipOuterStyles: AnimationProps["initial"] = {
  opacity: 1,
};

const initialTooltipInnerStyles: AnimationProps["initial"] = {
  transform: "scale(0.97) translateY(-2px)",
};

const animateTooltipInnerStyles: AnimationProps["initial"] = {
  transform: "scale(1) translateY(0)",
};

export type SpringAnimatorProps = HTMLMotionProps<"div"> & {
  transitionOpenDelay?: number;
  innerStyle?: React.CSSProperties;
};

export const SpringAnimator = forwardRef<HTMLDivElement, SpringAnimatorProps>(
  ({ children, transitionOpenDelay, innerStyle = {}, ...props }, ref) => {
    const baseTransition = {
      duration: 0.12,
      ease: "easeInOut",
      delay: transitionOpenDelay ?? 0,
    };

    const exitTransition = {
      ...baseTransition,
      delay: 0.08,
    };

    return (
      <motion.div
        transition={baseTransition}
        initial={initialTooltipOuterStyles}
        animate={animateTooltipOuterStyles}
        exit={{
          ...initialTooltipOuterStyles,
          transition: exitTransition,
        }}
        style={{ position: "relative" }}
        ref={ref}
        {...props}
      >
        <motion.div
          initial={initialTooltipInnerStyles}
          animate={animateTooltipInnerStyles}
          transition={baseTransition}
          exit={{
            ...initialTooltipInnerStyles,
            transition: exitTransition,
          }}
          style={innerStyle}
        >
          {children}
        </motion.div>
      </motion.div>
    );
  }
);
