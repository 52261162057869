import { computed, makeObservable, observable, runInAction, action } from "mobx";

import { JsonObject } from "type-fest";
import {
  ApiQueryConfig,
  ApiQueryFetchDataHandler,
  ApiRequestFetchDataHandler,
} from "@/store/queries/types";
import { ApiRequestObservable } from "@/store/queries/common/ApiRequestObservable";
import { last } from "lodash-es";

export interface ApiQueryObservableArgs<TData extends JsonObject> {
  handleFetchData: ApiQueryFetchDataHandler<TData>;
  config: ApiQueryConfig;
}

export class ApiQueryObservable<TData extends JsonObject> {
  private handleFetchData: ApiQueryFetchDataHandler<TData>;
  private config: ApiQueryConfig;

  requests: ApiRequestObservable<TData>[] = [];

  constructor({ handleFetchData, config }: ApiQueryObservableArgs<TData>) {
    this.handleFetchData = handleFetchData;
    this.config = config;

    makeObservable<this, "handleFetchData" | "config">(this, {
      handleFetchData: false,
      config: false,
      fetchData: action,
      cancel: action,
      canFetchMoreData: computed,
      requests: observable,
      latestRequest: computed,
      latestSuccessfulRequest: computed,
      isCalled: computed,
      isLoading: computed,
      isSuccess: computed,
      data: computed,
      wrappedHandleFetchData: computed,
    });
  }

  get latestRequest(): ApiRequestObservable<TData> | undefined {
    return last(this.requests);
  }

  get latestSuccessfulRequest(): ApiRequestObservable<TData> | undefined {
    return this.requests.findLast(request => request.isSuccess);
  }

  get isCalled(): boolean {
    return this.latestRequest?.isCalled ?? false;
  }

  get isLoading(): boolean {
    return this.latestRequest?.isLoading ?? false;
  }

  get isSuccess(): boolean {
    return this.latestSuccessfulRequest?.isSuccess ?? false;
  }

  get data(): TData | undefined {
    return this.latestSuccessfulRequest?.data;
  }

  get wrappedHandleFetchData(): ApiRequestFetchDataHandler<TData> {
    const latestSuccessfulRequestData = this.latestSuccessfulRequest?.data;

    return async () => {
      return await this.handleFetchData({
        latestRequest: latestSuccessfulRequestData,
      });
    };
  }

  fetchData = async () => {
    if (this.isLoading) {
      return;
    }

    if (!this.canFetchMoreData) {
      return;
    }

    const nextRequest = new ApiRequestObservable<TData>({
      handleFetchData: this.wrappedHandleFetchData,
      config: this.config,
    });

    runInAction(async () => {
      this.requests.push(nextRequest);
    });

    await nextRequest.fetchData();
  };

  cancel = () => {
    this.requests.forEach(request => {
      request.cancel();
    });
  };

  /**
   * Always true - override this method in subclasses if needed.
   */
  get canFetchMoreData(): boolean {
    return true;
  }
}
