import { makeObservable, observable } from "mobx";
import { PlatformStripeSubscriptionStatus } from "@/store/platform-info/types";
import { Uuid } from "@/domains/global/identifiers";

export class PlatformStripeSubscriptionInfoObservable {
  id: Uuid;
  stripeSubscriptionId: Uuid;
  stripeSubscriptionStatus: PlatformStripeSubscriptionStatus;

  constructor({
    id,
    stripeSubscriptionId,
    stripeSubscriptionStatus,
  }: {
    id: Uuid;
    stripeSubscriptionId: Uuid;
    stripeSubscriptionStatus: PlatformStripeSubscriptionStatus;
  }) {
    this.id = id;
    this.stripeSubscriptionId = stripeSubscriptionId;
    this.stripeSubscriptionStatus = stripeSubscriptionStatus;

    makeObservable(this, {
      id: observable,
      stripeSubscriptionId: observable,
      stripeSubscriptionStatus: observable,
    });
  }
}
