import { computed, makeObservable, observable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreSpaceAccountNotesStore extends BaseSyncModelStore<
  SpaceAccountNoteObservable,
  SpaceAccountNoteModelData
> {
  private searchTitleLimit = 100;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountNote, ...injectedDeps });
    makeObservable<this, "searchTitleLimit">(this, {
      searchTitleLimit: observable,
      createSyncModel: false,
      getSpaceAccountNoteObservableById: false,
      allSpaceAccountNotes: computed,
    });
  }

  createSyncModel(data: SyncUpdateValue<SpaceAccountNoteModelData>): SpaceAccountNoteObservable {
    return new SpaceAccountNoteObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get allSpaceAccountNotes(): SpaceAccountNoteObservable[] {
    return Array.from(this.pool.values());
  }

  public getSpaceAccountNoteObservableById({
    spaceAccountNoteId,
  }: {
    spaceAccountNoteId?: Uuid;
  }): SpaceAccountNoteObservable | undefined {
    return spaceAccountNoteId ? this.pool.get(spaceAccountNoteId) : undefined;
  }
}
