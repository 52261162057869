import { ChatMessageUpsertedSyncUpdateValue } from "@/store/chat/types";

export enum PusherServicePrefix {
  BACKEND_API = "BACKEND_API",
  AUTHENTICATED_BACKEND_API = "private-BACKEND_API",
}

export enum PusherDomainPrefix {
  IMAGE_FILE = "IMAGE_FILE",
  SPACE_SCOPED_SYNC_UPDATE = "SPACE_SCOPED_SYNC_UPDATE",
  COLLECTION_SCOPED_SYNC_UPDATE = "COLLECTION_SCOPED_SYNC_UPDATE",
  SPACE_ACCOUNT_SCOPED_SYNC_UPDATE = "SPACE_ACCOUNT_SCOPED_SYNC_UPDATE",
  CHAT_CONVERSATION_SCOPED_SYNC_UPDATE = "CHAT_CONVERSATION_SCOPED_SYNC_UPDATE",
  CHAT_CONVERSATION_LIVE_SYNC_UPDATE = "CHAT_CONVERSATION_LIVE_SYNC_UPDATE",
}

export enum PusherEventKind {
  BACKEND_IMAGE_FILE_INDEXING_STATUS_UPDATE = "INDEXING_STATUS_UPDATE",
  SYNC_UPDATE_PUBLISHED = "SYNC_UPDATE_PUBLISHED",
  CHAT_CONVERSATION_MESSAGE_UPSERTED = "CHAT_CONVERSATION_MESSAGE_UPSERTED",
}

export type PusherEventData<TKind extends PusherEventKind> = {
  [PusherEventKind.BACKEND_IMAGE_FILE_INDEXING_STATUS_UPDATE]: {
    image_file_id: string;
    processing_status: string;
    indexing_status: string;
    suggested_search: string | null;
  };
  [PusherEventKind.SYNC_UPDATE_PUBLISHED]: {
    id: string;
  };
  [PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED]: {
    sync_operation_id: string;
    value: ChatMessageUpsertedSyncUpdateValue;
  };
}[TKind];
