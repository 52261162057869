import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import EmptyCollections from "@/assets/images/empty-collections.svg";
import { MdsButtonShape, MdsButtonSize } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";
import { HomeLensKind } from "@/modules/url-params/home-params/types";

const COPY: Record<HomeLensKind, string> = {
  ALL: "You haven't created any notes",
  ADDED_BY_ME: "You haven't created any notes",
  SHARED_WITH_ME: "No notes have been shared with you",
  UNORGANIZED: "You don't have any unorganized notes",
} as const;

interface NotesListPageListEmptyProps {
  area: HomeLensKind;
}

export const NotesListPageListEmpty = observer<NotesListPageListEmptyProps>(({ area }) => {
  const { store } = useAppStore();
  const copy = COPY[area];

  return (
    <EmptyContainer dataTestId="notes-list-page-list-empty">
      <img src={EmptyCollections} alt={copy} />
      <EmptyText>{copy}</EmptyText>
      {area !== HomeLensKind.SharedWithMe && (
        <MdsButton
          label="Create note"
          variant={MdsButtonVariant.FilledDark}
          shape={MdsButtonShape.Square}
          size={MdsButtonSize.Medium}
          onClick={() => store.notes.composeNewNote()}
          iconKind={MdsIconKind.ComposeInline}
        />
      )}
    </EmptyContainer>
  );
});
