import { useCallback, useEffect, useRef, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyArray = any[];

type AsyncFunction<Data> = (...args: AnyArray) => Promise<Data>;

interface Options {
  invokeOnMount?: boolean;
  invokeOnMountWith?: AnyArray;
  key?: string;
}

interface UseAsyncArg<Data> {
  fn: AsyncFunction<Data>;
  options?: Options;
}

export function useAsync<Data>({ fn, options }: UseAsyncArg<Data>) {
  const [isProcessing, setProcessing] = useState(false);
  const [result, setResult] = useState<Data | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const cacheRef = useRef<Map<string, Data>>(new Map());
  const key = options?.key;

  const invoke = useCallback(
    async (...params: AnyArray) => {
      if (key && cacheRef.current.has(key)) {
        setResult(cacheRef.current.get(key)!);
        return;
      }

      // @todo @RickDT: Possible future enhancement: I wonder if we should have a per-key error map?
      setProcessing(true);
      setError(null);
      try {
        const result = await fn(...params);
        setResult(result);
        if (key) {
          cacheRef.current.set(key, result);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setProcessing(false);
      }
    },
    [fn, key]
  );

  useEffect(() => {
    if (options?.invokeOnMount) {
      invoke(options.invokeOnMountWith);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoke, options?.invokeOnMount]);

  return { isProcessing, result, error, invoke };
}
