/**
 * Code heavily inspired by the `string-hash` package:
 *   https://github.com/darkskyapp/string-hash/blob/master/index.js
 */
export const _generateIntegerHashForString = ({ str }: { str: string }) => {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
};
