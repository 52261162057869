import React from "react";
import { DebouncedState } from "@/domains/react/useDebouncedCallback";

type DelayedHide = DebouncedState<(e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void>;

export class MdsHoverContentGroup {
  hideFns: DelayedHide[] = [];

  hideAll = ({ exceptHideFn }: { exceptHideFn?: DelayedHide }) => {
    for (const hideFn of this.hideFns) {
      if (hideFn !== exceptHideFn) {
        hideFn();
        hideFn.flush();
      }
    }
  };
}
