import { moduleFactory } from "@/factories/module";
import { clientEnvBuildInfo } from "@/modules/client-env/buildInfo";
import { clientEnvClientId } from "@/modules/client-env/clientId";
import {
  clientEnvNodeEnvironment,
  clientEnvNodeEnvironmentIsDev,
  clientEnvNodeEnvironmentIsProd,
  clientEnvNodeEnvironmentIsTest,
} from "@/modules/client-env/nodeEnvironment";
import { clientEnvPackageVersion } from "@/modules/client-env/packageVersion";
import { clientEnvStripeConfig } from "@/modules/client-env/stripeConfig";
import { clientEnvLocalBackendApiIsEnabled } from "@/modules/client-env/viteConfig";

export const clientEnvModule = moduleFactory.create({
  nodeEnv: clientEnvNodeEnvironment,
  isProd: clientEnvNodeEnvironmentIsProd,
  isDev: clientEnvNodeEnvironmentIsDev,
  isTest: clientEnvNodeEnvironmentIsTest,
  localBackendApiIsEnabled: clientEnvLocalBackendApiIsEnabled,
  clientId: clientEnvClientId,
  stripe: clientEnvStripeConfig,
  buildInfo: clientEnvBuildInfo,
  packageVersion: clientEnvPackageVersion,
});
