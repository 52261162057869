import React, { forwardRef } from "react";

import { _useMdsTextFieldChangeHandlers } from "@/design-system/components/text-field/utils/changeHandlers";
import { css, cx, ClassNamesArg } from "@/domains/emotion";
import {
  OnBlur,
  OnChange,
  OnFocus,
  ReactChangeEvent,
  ReactKeyboardEvent,
} from "@/design-system/constants/handlers/types";
import { mdsFontWeights, mdsColors } from "@/design-system/foundations";

export interface MdsEditableTextFieldProps {
  name?: string;
  placeholder?: string;
  onFocus?: OnFocus;
  onBlur?: OnBlur;
  onChange?: OnChange<ReactChangeEvent<HTMLInputElement>>;
  onEnterKeyPress?: OnChange<ReactKeyboardEvent<HTMLInputElement>>;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  autoFocus?: boolean;
  className?: ClassNamesArg;
}

const wrapperStyles = css({
  position: "relative",
});

const baseInputStyles = css({
  display: "block",
  maxWidth: 450,
  padding: "2px 4px",
  borderRadius: 4,
  textOverflow: "ellipsis",
  fontWeight: mdsFontWeights().bold,
  letterSpacing: "-0.02em",
  cursor: "text",
  transition: "0.08s ease",
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
    backgroundColor: mdsColors().grey.x300,
  },
  "&:placeholder": {
    color: mdsColors().grey.x300,
  },
  backgroundColor: mdsColors().white,
  color: mdsColors().grey.x600,
  "&:not(:read-only):hover, &:not(:read-only):focus": {
    backgroundColor: mdsColors().grey.x50,
    color: mdsColors().grey.x600,
    transition: "0.02s",
  },
});

export const MdsEditableTextField = forwardRef<HTMLInputElement, MdsEditableTextFieldProps>(
  (props, ref) => {
    const { name, value, placeholder = "", autoFocus = false, className } = props;

    const { onChangeHandler, onBlurHandler, onFocusHandler, onKeyPressHandler } =
      _useMdsTextFieldChangeHandlers(props);

    const inputStyles = className ? cx(baseInputStyles, className) : baseInputStyles;
    return (
      <div className={wrapperStyles}>
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          className={inputStyles}
          ref={ref}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onKeyPress={onKeyPressHandler}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          autoFocus={autoFocus}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      </div>
    );
  }
);
