import { MdsSkeleton } from "@/design-system/components/loader/MdsSkeleton";

export function NoteSkeleton() {
  return (
    <div>
      <h1>
        <MdsSkeleton />
      </h1>
      <p>
        <MdsSkeleton count={3} />
      </p>
      <p>
        <MdsSkeleton count={5} />
      </p>
    </div>
  );
}
