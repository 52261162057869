import { moduleFactory } from "@/factories/module";
import { _parseUuidFromByteBuffer } from "@/modules/uuid/buffer/fromByteBuffer";
import { _generateRandomUuid } from "@/modules/uuid/generation/generate";
import { _maybeParseUuidValue } from "@/modules/uuid/strings/maybeParseValue";
import { _parseUuidValue } from "@/modules/uuid/strings/parseValue";
import { _serializeUuid } from "@/modules/uuid/strings/serialize";
import { _convertUuidToByteBuffer } from "@/modules/uuid/buffer/toByteBuffer";
import { _validateAndNormalizeUuid } from "@/modules/uuid/validation/validateAndNormalize";
import { _combineUuids } from "@/modules/uuid/generation/combineUuids";
import { generatePersonalSpaceIdFromAccountId } from "@/modules/uuid/generation/generatePersonalSpaceIdFromAccountId";
import { resolveSpaceAccountUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountUuid";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { resolveSyncModelNamespace } from "@/modules/uuid/sync-models/resolveSyncModelNamespace";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import { combineUuidsUsingNamespace } from "@/modules/uuid/sync-models/combineUuidsUsingNamespace";
import { resolveCollectionItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveCollectionItemSyncModelUuid";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { resolveSavedSearchSyncModelUuid } from "@/modules/uuid/sync-models/resolveSavedSearchSyncModelUuid";

export const uuidModule = moduleFactory.create({
  serialize: _serializeUuid,
  parseValue: _parseUuidValue,
  maybeParseValue: _maybeParseUuidValue,
  generate: _generateRandomUuid,
  validateAndNormalize: _validateAndNormalizeUuid,
  toByteBuffer: _convertUuidToByteBuffer,
  fromByteBuffer: _parseUuidFromByteBuffer,
  combine: _combineUuids,
  generatePersonalSpaceIdFromAccountId: generatePersonalSpaceIdFromAccountId,
  combineUuidsUsingNamespace: combineUuidsUsingNamespace,
  resolveCollectionItemSyncModelUuid: resolveCollectionItemSyncModelUuid,
  resolveFavoriteItemSyncModelUuid: resolveFavoriteItemSyncModelUuid,
  resolveSavedSearchSyncModelUuid: resolveSavedSearchSyncModelUuid,
  resolveSpaceAccountCollectionSyncModelUuid: resolveSpaceAccountCollectionSyncModelUuid,
  resolveSpaceAccountNoteSyncModelUuid: resolveSpaceAccountNoteSyncModelUuid,
  resolveSpaceAccountUuid: resolveSpaceAccountUuid,
  resolveSyncModelNamespace: resolveSyncModelNamespace,
  resolveSyncModelNamespacedUuid: resolveSyncModelNamespacedUuid,
});
