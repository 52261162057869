import { FC, useState } from "react";
import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { DeleteAccountModal } from "@/pages/settings/components/DeleteAccountModal";
import { observer } from "mobx-react-lite";
import { mdsColors } from "@/design-system/foundations";

export const SettingsAccountManagementPage: FC = observer(() => {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  return (
    <>
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        handleClose={() => setIsDeleteAccountModalOpen(false)}
      />
      <SettingsSectionWrapper>
        <SettingsSubsectionWrapper title="Manage account">
          <div className={wrapperStyles}>
            By clicking the button below you will be asked to confirm that you want to delete your
            account. <b>This action is irreversible.</b>
            <br />
            <MdsButton
              variant={MdsButtonVariant.Tertiary}
              label="Continue with account deletion"
              onClick={() => setIsDeleteAccountModalOpen(true)}
            />
          </div>
        </SettingsSubsectionWrapper>
      </SettingsSectionWrapper>
    </>
  );
});

const wrapperStyles = css({
  fontSize: 14,
  lineHeight: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: mdsColors().grey.x700,
});
