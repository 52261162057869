export enum ScreenSize {
  Phone = "phone",
  Tablet = "tablet",
  Desktop = "desktop",
}

export enum PanelMode {
  Narrow = "narrow",
  MediumSplitView = "medium-split-view",
  MediumSinglePanel = "medium-single-panel",
  WideSplitView = "wide-split-view",
  WideSinglePanel = "wide-single-panel",
}
