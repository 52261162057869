import React, { ReactNode } from "react";

import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { usePublicAppStore } from "@/store";
import { computed } from "mobx";

export interface MdsAppStyleWrapperProps {
  children: ReactNode;
}

const wrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  padding: 0,
  margin: 0,
  overflow: "hidden",
  /**
   * Mobile Safari has a weird behavior when scrolling while the onscreen keyboard is open.
   * First it doesn't let you scroll all the way down (remaining area is the same size as
   * its address and toolbar) when it lets you scroll more but shows 2 scrollbars.
   * The extra scrolled area remains part of the page until you leave the editor.
   * Fixing the position improves it slightly, avoiding the inner scrollbar and dropping the extra
   * scrolled area once you scroll up.
   *
   * https://linear.app/mem-labs/issue/MEM-8060/try-to-remove-whitespace-under-focused-editor
   * might improve it further.
   */
  position: "fixed",
  bottom: 0,
  top: 0,
  width: "100%",
});

export const MdsAppStyleWrapper = observer<MdsAppStyleWrapperProps>(function MdsAppStyleWrapper({
  children,
}) {
  const { publicStore } = usePublicAppStore();

  const innerHeight = publicStore.interface.dimensionsState.data?.height;

  const height = computed(() => (innerHeight ? `${innerHeight}px` : "100dvh")).get();

  return (
    <div className={wrapperStyles} style={{ height }}>
      {children}
    </div>
  );
});
