import { computed, makeObservable, observable } from "mobx";

import { Uuid } from "@/domains/global/identifiers";
import { IContact } from "@/store/contacts/types";

export class SpaceAccountObservable implements IContact {
  id: Uuid;
  accountId: Uuid;
  spaceId: Uuid;
  profileEmailAddress: string;
  profileDisplayName: string;
  profilePhotoUrl: string | null;

  constructor({
    id,
    accountId,
    spaceId,
    profileEmailAddress,
    profileDisplayName,
    profilePhotoUrl,
  }: {
    id: Uuid;
    accountId: Uuid;
    spaceId: Uuid;
    profileEmailAddress: string;
    profileDisplayName: string;
    profilePhotoUrl?: string | null;
  }) {
    this.id = id;
    this.accountId = accountId;
    this.spaceId = spaceId;
    this.profileEmailAddress = profileEmailAddress;
    this.profileDisplayName = profileDisplayName;
    this.profilePhotoUrl = profilePhotoUrl ?? null;

    makeObservable(this, {
      id: observable,
      accountId: observable,
      spaceId: observable,
      profileEmailAddress: observable,
      profileDisplayName: observable,
      profilePhotoUrl: observable,
      displayName: computed,
      secondaryLabel: computed,
      photoUrl: computed,
    });
  }

  get displayName() {
    return this.profileDisplayName;
  }

  get secondaryLabel() {
    return this.profileEmailAddress;
  }

  get photoUrl() {
    return this.profilePhotoUrl || undefined;
  }

  static fromApiData({
    data,
  }: {
    data: {
      id: Uuid;
      account_id: Uuid;
      space_id: Uuid;
      profile_display_name: string;
      profile_email_address: string;
      profile_photo_url?: string | null;
    };
  }) {
    return new SpaceAccountObservable({
      id: data.id,
      accountId: data.account_id,
      spaceId: data.space_id,
      profileEmailAddress: data.profile_email_address,
      profileDisplayName: data.profile_display_name,
      profilePhotoUrl: data.profile_photo_url,
    });
  }
}
