import GoogleIcon from "@/assets/icons/brands/google-icon.svg?react";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { css } from "@/domains/emotion";
import { MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { mdsFontSizes } from "@/design-system/foundations";
import { MdsButtonShape } from "@/design-system/components/button/types";
import { TestableProps } from "@/domains/testing/types";

export interface AuthCardGoogleButtonBaseProps extends TestableProps {
  onClick: () => void;
  label: string;
}

export const AuthCardGoogleButtonBase: FC<AuthCardGoogleButtonBaseProps> = observer(
  ({ onClick, label, testId }) => {
    return (
      <MdsButton
        label={null}
        size={MdsButtonSize.Large}
        shape={MdsButtonShape.Round}
        variant={MdsButtonVariant.Outlined}
        onClick={onClick}
        className={buttonStyles}
        testId={testId}
      >
        <div className={actionTextStyles}>
          <GoogleIcon viewBox="0 0 25 25" height={20} className={actionIconStyles} />
          {label}
        </div>
      </MdsButton>
    );
  }
);

const buttonStyles = css({
  width: 200,
});

const actionTextStyles = css({
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  fontSize: mdsFontSizes().medium,
});

const actionIconStyles = css({
  marginRight: 8,
});
