import { AsyncResultInitial } from "@/modules/async-result/types";

export const _asyncResultSetInitial = (): AsyncResultInitial => {
  return {
    called: false,
    loading: false,
    error: undefined,
    data: undefined,
  };
};
