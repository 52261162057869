import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateCollectionOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class UpdateCollectionOperation extends BaseSyncOperation<IUpdateCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "UPDATE_COLLECTION";
  }

  generateOptimisticUpdates(): SyncUpdate<CollectionModelData>[] {
    const existingCollection = this.store.collections.get(this.payload.id);
    if (!existingCollection) return [];

    const value: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: existingCollection.modelVersion,
      model_data: {
        ...existingCollection.modelData,
        title: this.payload.title ?? existingCollection.modelData.title,
        description: this.payload.description ?? existingCollection.modelData.description,
        locally_modified_at: this.committedAt,
        last_updated_at: this.committedAt,
      },
      model_scopes: existingCollection.modelScopes,
    };
    const syncUpdate: SyncUpdate<CollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  execute() {
    const existingCollection = this.store.collections.get(this.payload.id);
    if (!existingCollection) return;
    super.execute();
  }

  get title() {
    return this.store.collections.get(this.payload.id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }
  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be updated. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to update {this.mediumTitle}. Please contact the collection
        owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be updated. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
