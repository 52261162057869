import { PusherEventData, PusherEventKind } from "@/domains/pusher/constants";
import { resolvePrimaryChatConversationSyncModelUuid } from "@/modules/uuid/sync-models/resolvePrimaryChatConversationSyncModelUuid";
import { ChatConversationObservable } from "@/store/chat/ChatConversationObservable";
import { ChatConversationModelData } from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateChatConversationOperation } from "@/store/sync/operations/chat/CreateChatConversationOperation";
import { SyncModelKind, SyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import {
  generateChatConversationLiveSyncUpdatePusherChannelKey,
  generateSyncActionChatConversationScopedPusherChannelKey,
} from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import { makeObservable, action, override, computed, observable } from "mobx";
import { Channel } from "pusher-js";

export class AppStoreChatConversationStore extends BaseSyncModelStore<
  ChatConversationObservable,
  ChatConversationModelData
> {
  private chatConversationPusherChannels = new Map<string, Channel>();
  private liveChatConversationPusherChannels = new Map<string, Channel>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatConversation, ...injectedDeps });
    makeObservable<
      AppStoreChatConversationStore,
      | "chatConversationPusherChannels"
      | "liveChatConversationPusherChannels"
      | "handleChatConversationLiveSyncUpdate"
    >(this, {
      chatConversationPusherChannels: observable,
      liveChatConversationPusherChannels: observable,
      createSyncModel: false,
      handleChatConversationLiveSyncUpdate: action,
      processSyncUpdate: override,
      primaryChatConversation: computed,
      generatePrimaryChatConversationIfNeeded: action,
      subscribeToChatConversation: action,
      unsubscribeFromChatConversation: action,
      subscribeToLiveChatConversation: action,
      unsubscribeFromLiveChatConversation: action,
    });
  }

  createSyncModel(data: SyncUpdateValue<ChatConversationModelData>): ChatConversationObservable {
    return new ChatConversationObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  processSyncUpdate(update: SyncUpdate<ChatConversationModelData>) {
    super.processSyncUpdate(update);
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED")
      this.subscribeToChatConversation(update.value.model_id);
    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED")
      this.unsubscribeFromChatConversation(update.value.model_id);
  }

  get primaryChatConversation(): ChatConversationObservable | undefined {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const primaryChatConversationId = resolvePrimaryChatConversationSyncModelUuid({
      spaceAccountId,
    });
    return this.pool.get(primaryChatConversationId);
  }

  async generatePrimaryChatConversationIfNeeded() {
    if (this.primaryChatConversation) return;
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const primaryChatConversationId = resolvePrimaryChatConversationSyncModelUuid({
      spaceAccountId,
    });
    new CreateChatConversationOperation({
      store: this.store,
      payload: { id: primaryChatConversationId, is_primary_chat_conversation: true },
    }).execute();
  }

  // CHAT CONVERSATION SUBSCRIPTION - Always subscribed for chat-conversation scoped sync updates
  public subscribeToChatConversation(chatConversationId: string) {
    if (this.chatConversationPusherChannels.has(chatConversationId)) return;
    const chatConversationPusherChannelKey =
      generateSyncActionChatConversationScopedPusherChannelKey({
        chatConversationId,
      });
    const channel = this.pusher.subscribe(chatConversationPusherChannelKey);
    console.debug(
      "[SYNC][AppStoreChatConversationStore] Subscribing to chat conversation",
      chatConversationId
    );
    channel.bind(PusherEventKind.SYNC_UPDATE_PUBLISHED, this.store.sync.queryForSyncActions);
    this.chatConversationPusherChannels.set(chatConversationId, channel);
  }

  public unsubscribeFromChatConversation(chatConversationId: string) {
    const channel = this.chatConversationPusherChannels.get(chatConversationId);
    if (channel)
      console.debug(
        "[SYNC][AppStoreChatConversationStore] Unsubscribing from chat conversation",
        chatConversationId
      );
    channel?.unsubscribe();
    this.chatConversationPusherChannels.delete(chatConversationId);
  }

  // LIVE CHAT CONVERSATION SUBSCRIPTION - Subscribed when the chat UI is mounted
  public subscribeToLiveChatConversation(chatConversationId: string) {
    const chatConversationPusherChannelKey = generateChatConversationLiveSyncUpdatePusherChannelKey(
      { chatConversationId }
    );
    const liveChannel = this.pusher.subscribe(chatConversationPusherChannelKey);
    this.liveChatConversationPusherChannels.set(chatConversationId, liveChannel);
    liveChannel.bind(
      PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED,
      this.handleChatConversationLiveSyncUpdate
    );
  }

  public unsubscribeFromLiveChatConversation(chatConversationId: string) {
    const liveChannel = this.liveChatConversationPusherChannels.get(chatConversationId);
    if (!liveChannel) return;
    liveChannel.unsubscribe();
    this.liveChatConversationPusherChannels.delete(chatConversationId);
  }

  private handleChatConversationLiveSyncUpdate = ({
    value,
    sync_operation_id,
  }: PusherEventData<PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED>) => {
    this.store.chatMessages.processLiveSyncUpdate(sync_operation_id, value);
  };
}
