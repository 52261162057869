import { Uuid } from "@/domains/global/identifiers";
import { UuidNamespace } from "@/modules/uuid/constants";
import { v5 as uuidv5 } from "uuid";

/**
 * Generates a personal-space-id from a user's account id.
 * (Note that this does _not_ match our previous golang implementation.)
 */
export const generatePersonalSpaceIdFromAccountId = ({ accountId }: { accountId: Uuid }): Uuid => {
  const uuid = uuidv5(accountId, UuidNamespace.AccountToPersonalSpaceNamespace);

  return uuid;
};
