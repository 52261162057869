import { action, makeObservable, observable, runInAction } from "mobx";

import { useEffect } from "react";
import { useMatch } from "react-router";
import { isString } from "lodash-es";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { appRoutes } from "@/app/router";
import { validationModule } from "@/modules/validation";

export class AppStoreRoutingStore extends AppSubStore {
  noteIdParam: string | null;
  collectionIdParam: string | null;
  spaceIdParam: string | null;
  createSpaceIdParam: string | null;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.noteIdParam = null;
    this.collectionIdParam = null;
    this.spaceIdParam = null;
    this.createSpaceIdParam = null;

    makeObservable(this, {
      noteIdParam: observable,
      collectionIdParam: observable,
      spaceIdParam: observable,
      createSpaceIdParam: observable,
      setNoteIdParam: action,
      setCollectionIdParam: action,
      setSpaceIdParam: action,
      setCreateSpaceIdParam: action,
      useSyncRoutingParams: action,
      resetState: action,
    });
  }

  setNoteIdParam(maybeNoteIdParam: string | undefined) {
    if (!isString(maybeNoteIdParam)) {
      runInAction(() => {
        this.noteIdParam = null;
      });

      return;
    }

    runInAction(() => {
      this.noteIdParam = maybeNoteIdParam;
    });
  }

  setCollectionIdParam(maybeCollectionIdParam: string | undefined) {
    if (!isString(maybeCollectionIdParam)) {
      runInAction(() => {
        this.collectionIdParam = null;
      });

      return;
    }

    runInAction(() => {
      this.collectionIdParam = maybeCollectionIdParam;
    });
  }

  setSpaceIdParam(maybeSpaceIdParam?: string) {
    if (!isString(maybeSpaceIdParam) || !validationModule.isUuid(maybeSpaceIdParam)) {
      runInAction(() => {
        this.spaceIdParam = null;
      });

      return;
    }

    runInAction(() => {
      this.spaceIdParam = maybeSpaceIdParam;
    });
  }

  setCreateSpaceIdParam(maybeCreateSpaceIdParam?: string) {
    if (!isString(maybeCreateSpaceIdParam) || !validationModule.isUuid(maybeCreateSpaceIdParam)) {
      runInAction(() => {
        this.createSpaceIdParam = null;
      });

      return;
    }

    runInAction(() => {
      this.createSpaceIdParam = maybeCreateSpaceIdParam;
    });
  }

  /**
   * We need to wire up each parameter independently, based on the target routes.
   */
  useSyncRoutingParams() {
    const noteIdMatch = useMatch(
      appRoutes.notesView({ params: { noteId: ":noteId" }, starPattern: true })
    );

    const collectionIdMatch = useMatch(
      appRoutes.collectionsView({ params: { collectionId: ":collectionId" }, starPattern: true })
    );

    // const spaceIdMatch = useMatch(
    //   memRoutes.spacesRoot({ params: { spaceId: ":spaceId" }, starPattern: true })
    // );

    // const createSpaceIdMatch = useMatch(
    //   memRoutes.spacesCreateFlow({ params: { spaceId: ":spaceId" }, starPattern: true })
    // );

    const maybeNoteIdParam = noteIdMatch?.params.noteId;
    const maybeCollectionIdParam = collectionIdMatch?.params.collectionId;
    const maybeSpaceIdParam = undefined; //  spaceIdMatch?.params.spaceId;
    const maybeCreateSpaceIdParam = undefined; // createSpaceIdMatch?.params.spaceId;

    useEffect(() => {
      this.setNoteIdParam(maybeNoteIdParam);
    }, [maybeNoteIdParam]);

    useEffect(() => {
      this.setCollectionIdParam(maybeCollectionIdParam);
    }, [maybeCollectionIdParam]);

    useEffect(() => {
      this.setSpaceIdParam(maybeSpaceIdParam);
    }, [maybeSpaceIdParam]);

    useEffect(() => {
      this.setCreateSpaceIdParam(maybeCreateSpaceIdParam);
    }, [maybeCreateSpaceIdParam]);
  }

  resetState() {
    runInAction(() => {
      this.spaceIdParam = null;
    });
  }
}
