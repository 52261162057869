import { Uuid } from "@/domains/global/identifiers";
import { UuidNamespace } from "@/modules/uuid/constants";
import { combineUuidsUsingNamespace } from "@/modules/uuid/sync-models/combineUuidsUsingNamespace";
import { v5 as uuidv5 } from "uuid";

export const resolveSpaceAccountUuid = ({
  spaceId,
  accountId,
}: {
  spaceId: Uuid;
  accountId: Uuid;
}): Uuid => {
  /**
   * Note - SpaceAccount isn't technically a SyncModel kind yet, so this is a workaround
   * for now.
   */
  const namespace = uuidv5("SPACE_ACCOUNT", UuidNamespace.SyncModelNamespace);

  return combineUuidsUsingNamespace({
    namespace,
    uuids: [spaceId, accountId],
  });
};
