import ColorHash from "color-hash";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { Uuid } from "@/domains/global/identifiers";

export function generateCollectionIconBackgroundStyles(collectionId?: Uuid) {
  if (!collectionId) return css({ backgroundColor: mdsColors().grey.x200 });
  const colorHash = new ColorHash({ lightness: 0.5 });
  const backgroundColor = colorHash.hsl(collectionId);
  // Darken the icon background on hover by reducing the luminance by 15%
  const darkenedLuminance = backgroundColor[2] - 0.15;
  return css({
    background: `hsl(${backgroundColor[0]}, ${backgroundColor[1] * 100}%, ${
      darkenedLuminance * 100
    }%)`,
    ".mds-btn-icon, svg": {
      color: mdsColors().grey.x0,
    },
  });
}
