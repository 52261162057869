import { observer } from "mobx-react-lite";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useAppStore } from "@/store";
import { SearchPageNotesListEmpty } from "./SearchPageNotesListEmpty";

export const SearchPageNotesList = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;

  if (!page.isLoading && page.hasNoItems) {
    return (
      <SearchPageNotesListEmpty
        query={page.activeSearchEngineParams.queryString}
        isSuccess={page.isSuccess}
        onRetryClick={page.load}
      />
    );
  }

  return (
    <MdsVirtualizedItemList
      rows={page.itemRows}
      listState={page.listState}
      endReached={page.load}
      loading={page.isLoading}
    />
  );
});
