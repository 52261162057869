import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { ChatView } from "@/components/chat/ChatView";
import { NoteEditor } from "@/pages/notes-view/NoteEditor";
import { SidePanelRouteType } from "@/store/routing/types";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";

export const SidePanelRouter = observer(function SidePanelRouter() {
  const { store } = useAppStore();
  const { activeRoute, isSidePanelOpen, activeTabId } = store.sidePanel;

  if (activeRoute.type === SidePanelRouteType.NOTE_DETAIL) {
    const noteObservable = store.notes.get(activeRoute.noteId);
    if (!noteObservable) {
      return (
        <NotFoundPage entityType={EntityNotFoundType.Note} withBackButton={store.sidePanel.goUp} />
      );
    }
    return (
      <NoteEditor
        key={activeTabId}
        isSidePanelOpen={isSidePanelOpen}
        getAvailableChips={store.chatMessages.chatHistory.getAvailableChips}
        noteObservable={noteObservable}
        myAccount={store.account.myAccount}
        inSidePanel
      />
    );
  }

  return <ChatView key={activeTabId} chatHistory={store.chatMessages.chatHistory} inSidePanel />;
});
