import { computed, makeObservable, observable, action } from "mobx";
import {
  groupLens,
  TimelineWithInboxStatusGroup,
  TimelineWithInboxStatusItemFull,
} from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsItemKind,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { generateShortDateString } from "@/domains/date/date";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { css, cx } from "@/domains/emotion";
import { OnClick } from "@/design-system/constants/handlers/types";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { MdsButton, MdsButtonVariant, MdsButtonSize } from "@/design-system/components/button";
import {
  MdsButtonShape,
  MdsButtonIconPosition,
  MdsSelectionVariant,
} from "@/design-system/components/button/types";
import { filter, orderBy } from "lodash-es";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { NoteObservable } from "@/store/note/NoteObservable";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { DateTime } from "luxon";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { urlParamsModule } from "@/modules/url-params";
import { HomeLensKind, HomeParams, HomeSortByKind } from "@/modules/url-params/home-params/types";
import { enumModule } from "@/modules/enum";

export interface NotesListPage {
  supportsAddToCollectionListAction: boolean;
  listState: ListStateObservable;
  hasNoItems: boolean;
  activeHomeParams: HomeParams;
}

export class NotesListPageStore extends AppSubStore implements NotesListPage {
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  searchQuery = "";
  addToCollectionModal: AddToCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  shareSheetModal: ShareSheetModalStore;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    makeObservable<this, "generateItemDropdown">(this, {
      supportsAddToCollectionListAction: observable,
      supportsMoveToTrashListAction: observable,
      searchQuery: observable,
      listState: false,
      addToCollectionModal: false,
      deleteSharedNotesModal: false,
      shareSheetModal: false,

      activeHomeParams: computed,
      activeHomeQueryString: computed,
      updateHomeParams: action,
      setSortOrder: action,
      setLensFilter: action,
      sortOptions: computed,
      sortLabel: computed,

      isLoading: computed,
      uiFilters: computed,
      generateItemDropdown: false,
      itemRows: computed,
      orderedItemIds: computed,
      groupedHomeLensItems: computed,
      hasNoItems: computed,
    });
  }

  get activeHomeParams(): HomeParams {
    return urlParamsModule.home.parse({
      searchQueryStr: this.store.navigation.activeSearchQuery,
    });
  }

  get activeHomeQueryString(): string {
    return urlParamsModule.home.stringify({
      homeParams: this.activeHomeParams,
    });
  }

  public updateHomeParams({ lens, filters, facetFilters, sortBy }: Partial<HomeParams>) {
    const homeParams = {
      ...this.activeHomeParams,
    };

    if (lens) {
      homeParams.lens = lens;
    }

    if (filters) {
      homeParams.filters = filters;
    }

    if (facetFilters) {
      homeParams.facetFilters = facetFilters;
    }

    if (sortBy) {
      homeParams.sortBy = sortBy;
    }

    this.store.navigation.goToNotes({
      homeParams,
      config: {
        replace: true,
      },
    });
  }

  setSortOrder = ({ itemId }: { itemId: string }) => {
    const sortBy = enumModule.findMatchingStringValue(HomeSortByKind, itemId);

    this.updateHomeParams({
      sortBy,
    });
  };

  setLensFilter = ({ lensFilter }: { lensFilter: HomeLensKind }) => {
    this.updateHomeParams({
      lens: lensFilter,
    });
  };

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: HomeSortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: "Last created",
          isChecked: this.activeHomeParams.sortBy === "LAST_CREATED",
          onClick: this.setSortOrder,
        },
        {
          id: HomeSortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: "Last modified",
          isChecked: this.activeHomeParams.sortBy === "LAST_MODIFIED",
          onClick: this.setSortOrder,
        },
        {
          id: HomeSortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: "Last viewed",
          isChecked: this.activeHomeParams.sortBy === "LAST_VIEWED",
          onClick: this.setSortOrder,
        },
      ],
    };
  }

  get sortLabel(): string {
    const selectedSortOption = this.sortOptions.items.find(
      e => e.id === this.activeHomeParams.sortBy
    );

    return selectedSortOption && "label" in selectedSortOption ? selectedSortOption.label : "-";
  }

  get isLoading() {
    /** Currently, always false (we serve data from the grouped lenses in-memory.) */
    return false;
  }

  get uiFilters() {
    const filters: { id: string; kind: string; content: React.ReactNode }[] = [];

    const iconLabel = (icon: React.ReactNode, label: string) => () => (
      <div className={cx(compositeLabelStyles)}>
        {icon}
        <span>{label}</span>
      </div>
    );
    const addButton = (
      label: string,
      icon: React.ReactNode,
      onClick: OnClick,
      kind: string,
      id: string,
      selected: boolean
    ) => {
      filters.push({
        id,
        kind,
        content: (
          <div>
            <MdsButton
              className={lensKindButtonStyles}
              shape={MdsButtonShape.Round}
              variant={MdsButtonVariant.Outlined}
              size={MdsButtonSize.Medium}
              label={iconLabel(icon, label)}
              iconPosition={MdsButtonIconPosition.Right}
              iconKind={selected ? MdsIconKind.Exit : undefined}
              onClick={onClick}
              isSelected={selected}
              selectionVariant={MdsSelectionVariant.Accent}
            />
          </div>
        ),
      });
    };

    if (this.activeHomeParams.lens !== HomeLensKind.SharedWithMe) {
      addButton(
        "Added by me",
        <AccountProfileImage
          profile={{
            kind: ProfileKind.Me,
          }}
          size={ProfileSize.Small}
          className={negativeMarginStyles}
        />,
        () =>
          this.setLensFilter({
            lensFilter:
              this.activeHomeParams.lens === HomeLensKind.AddedByMe
                ? HomeLensKind.All
                : HomeLensKind.AddedByMe,
          }),
        "OWNED_BY_SPACE_ACCOUNT",
        this.store.account.myAccountId,
        this.activeHomeParams.lens === HomeLensKind.AddedByMe
      );
    }

    if (this.activeHomeParams.lens !== HomeLensKind.AddedByMe) {
      addButton(
        "Shared with me",
        <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Shared} />,
        () =>
          this.setLensFilter({
            lensFilter:
              this.activeHomeParams.lens === HomeLensKind.SharedWithMe
                ? HomeLensKind.All
                : HomeLensKind.SharedWithMe,
          }),
        "OWNED_BY_SPACE_ACCOUNT",
        HomeLensKind.SharedWithMe,
        this.activeHomeParams.lens === HomeLensKind.SharedWithMe
      );
    }

    return filters;
  }

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: NoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: () => noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () => this.addToCollectionModal.open({ noteIds: [noteObservable.id] }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              noteObservable.moveToTrash();
            }
          },
        },
      ],
    };
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.groupedHomeLensItems) {
      // Add header for each section
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });
      // Add each item afterwards
      for (const item of group.items) {
        if (item.kind === "full") {
          const noteObservable = this.store.notes.getNoteObservableById({
            noteId: item.item.id,
          });
          if (!noteObservable) continue;
          output.push({
            type: MdsItemListRowType.Item,
            key: noteObservable.id,
            size: MdsItemListSize.XLarge,
            payload: {
              id: noteObservable.id,
              kind: MdsItemKind.Note,
              createPreviewState: () =>
                new ItemPreviewState({
                  store: this.store,
                  id: item.item.id,
                  kind: MdsItemKind.Note,
                }),
              label: noteObservable.title,
              onClick: () => this.store.navigation.goToNote({ noteId: item.item.id }),
              sharedBy: noteObservable.sharedBy,
              dateLabel: generateShortDateString(DateTime.fromJSDate(item.dateTime)),
              dropdown: this.generateItemDropdown({ noteObservable }),
              listState: this.listState,
              extraRows: [
                {
                  id: `note-${noteObservable.id}-content`,
                  content: () => (
                    <MdsItemListRowFeaturedContent
                      collections={sortCollectionsForChips(
                        noteObservable.collectionList.allCollections
                      )}
                      snippet={
                        noteObservable.collectionList.allCollections.length
                          ? undefined
                          : [{ text: noteObservable.secondaryTitle || "No additional text" }]
                      }
                    />
                  ),
                },
              ],
            },
          });
        }
      }
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get groupedHomeLensItems(): TimelineWithInboxStatusGroup[] {
    const sortedItems = ((): TimelineWithInboxStatusItemFull[] => {
      if (this.activeHomeParams.sortBy !== HomeSortByKind.LastViewed) {
        return orderBy(
          this.store.notes.allNotes
            .filter(
              note =>
                !note.isTrashed &&
                !note.isDeleted &&
                note.canAccess &&
                (this.activeHomeParams.sortBy !== HomeSortByKind.LastViewed ||
                  !!note.spaceAccountNote?.lastViewedAt)
            )
            .map(note => ({
              kind: "full",
              item: note,
              dateTime:
                this.activeHomeParams.sortBy === HomeSortByKind.LastCreated
                  ? new Date(note.createdAt)
                  : new Date(note.modifiedAt),
            })),
          e => e.dateTime,
          "desc"
        );
      }
      return filter(this.store.recentItems.sortedRecentNotesInteractedWithByMe).map(recentItem => ({
        kind: "full",
        item: recentItem as NoteObservable,
        dateTime: new Date(recentItem.lastViewedAt),
      }));
    })();
    const items = filter(
      sortedItems,
      item =>
        this.activeHomeParams.lens === HomeLensKind.All ||
        (this.activeHomeParams.lens === HomeLensKind.AddedByMe && item.item.isOwnedByMe) ||
        (this.activeHomeParams.lens === HomeLensKind.SharedWithMe && !item.item.isOwnedByMe) ||
        (this.activeHomeParams.lens === HomeLensKind.Unorganized &&
          item.item instanceof NoteObservable &&
          item.item.collectionList.allCollections.length === 0)
    );
    return groupLens(items);
  }

  get hasNoItems() {
    return this.itemRows.filter(row => row.type === MdsItemListRowType.Item).length === 0;
  }
}

const lensKindButtonStyles = css({
  whiteSpace: "nowrap",
  ".mds-btn-label": {
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ".mds-btn-icon": {
    height: "12px",
    width: "12px",
  },
});

const compositeLabelStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const negativeMarginStyles = css({
  margin: "-2px 0",
});
