import { makeObservable, observable, reaction, runInAction, override } from "mobx";

import { JsonObject, JsonValue } from "type-fest";
import {
  ApiQueryMapDataToItemsHandler,
  ApiQueryFetchDataHandler,
  ApiQueryItemEqualityComparator,
  ApiQueryNextPageAvailabilityChecker,
} from "@/store/queries/types";
import {
  ApiQueryObservable,
  ApiQueryObservableArgs,
} from "@/store/queries/common/ApiQueryObservable";
import { logger } from "@/modules/logger";
import { isEqual } from "lodash-es";

export interface PaginatableApiQueryObservableArgs<
  TData extends JsonObject,
  TItem extends JsonValue,
> extends Omit<ApiQueryObservableArgs<TData>, "handleFetchData"> {
  handleFetchPageData: ApiQueryFetchDataHandler<TData>;
  mapPageDataToItems: ApiQueryMapDataToItemsHandler<TData, TItem>;
  compareItemEquality?: ApiQueryItemEqualityComparator<TItem>;
  checkNextPageAvailability: ApiQueryNextPageAvailabilityChecker<TData>;
}
import { SearchEnginePaginationInfo } from "@/modules/url-params/search-engine-params/types";

class SearchSessionData {
  searchSessionId: string;
  searchSessionCursor: string | undefined;

  constructor(searchSessionId: string, searchSessionCursor: string | undefined) {
    this.searchSessionId = searchSessionId;
    this.searchSessionCursor = searchSessionCursor;
  }
}

export class PaginatableApiQueryObservable<
  TData extends JsonObject,
  TItem extends JsonValue,
> extends ApiQueryObservable<TData> {
  /**
   * Whenever we get a new response from the server, we transform the response data
   * into an array of items.
   *
   * We add those to our running set of results.
   */
  private mapPageDataToItems: ApiQueryMapDataToItemsHandler<TData, TItem>;

  /**
   * This is used to determine if any items from the server are duplicates of items
   * we already have.
   */
  private compareItemEquality: ApiQueryItemEqualityComparator<TItem>;

  /**
   * Used to check if there are more pages available to fetch.
   */
  private checkNextPageAvailability: ApiQueryNextPageAvailabilityChecker<TData>;

  items: TItem[] = [];
  latestSearchSessionData: SearchSessionData | null = null;

  constructor({
    handleFetchPageData,
    mapPageDataToItems,
    compareItemEquality,
    checkNextPageAvailability,
    ...rest
  }: PaginatableApiQueryObservableArgs<TData, TItem>) {
    super({
      handleFetchData: handleFetchPageData,
      ...rest,
    });

    this.mapPageDataToItems = mapPageDataToItems;
    this.compareItemEquality = compareItemEquality ?? defaultItemEqualityComparator;
    this.checkNextPageAvailability = checkNextPageAvailability;

    makeObservable<
      this,
      "mapPageDataToItems" | "compareItemEquality" | "checkNextPageAvailability"
    >(this, {
      mapPageDataToItems: false,
      compareItemEquality: false,
      checkNextPageAvailability: false,
      canFetchMoreData: override,
      items: observable,
      latestSearchSessionData: observable,
    });

    reaction(
      () => this.data,
      async data => {
        if (!data) {
          return;
        }
        const paginationInfo = data.pagination_info as SearchEnginePaginationInfo;
        if (paginationInfo) {
          this.latestSearchSessionData = new SearchSessionData(
            paginationInfo.session_id,
            paginationInfo.search_session_cursor
          );
        }

        try {
          const newItems = await this.mapPageDataToItems({ data });

          /**
           * New items are added to the end of the list of items.
           * (We don't allow duplicates)
           */
          runInAction(() => {
            newItems.forEach(newItem => {
              const alreadyInserted = this.items.some(item =>
                this.compareItemEquality({ itemA: item, itemB: newItem })
              );

              if (!alreadyInserted) {
                this.items.push(newItem);
              }
            });
          });
        } catch (unknownErr) {
          const error = unknownErr as Error;

          logger.warn({
            message: `[PaginatableApiQueryObservable] [mapPageDataToItems] Failed: ${error.message}`,
          });
        }
      }
    );
  }

  get canFetchMoreData(): boolean {
    return this.checkNextPageAvailability({ latestRequest: this?.data });
  }
}

const defaultItemEqualityComparator: ApiQueryItemEqualityComparator<JsonValue> = ({
  itemA,
  itemB,
}) => {
  return isEqual(itemA, itemB);
};
