import { observer } from "mobx-react-lite";
import { appRoutes } from "@/app/router";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { css } from "@/domains/emotion";
import { usePublicAppStore } from "@/store";
import { AuthCardSignUp } from "@/components/auth/AuthCardSignUp";

const wrapperStyles = css({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
});

interface SignUpPageProps {}

export const SignUpPage: FC<SignUpPageProps> = observer(() => {
  const { publicStore } = usePublicAppStore();

  if (publicStore.auth.isAuthenticated) {
    return <Navigate to={appRoutes.notesList({}).path} />;
  }

  return (
    <div className={wrapperStyles}>
      <AuthCardSignUp />
    </div>
  );
});
