import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors } from "@/design-system/foundations/colors";

export interface MdsVerticalDividerProps extends EmotionClassStyles {}

const wrapperStyles = css({
  width: 1,
  height: "100%",
  backgroundColor: mdsColors().grey.x100,
});

export const MdsVerticalDivider: FC<MdsVerticalDividerProps> = ({ className }) => {
  const combinedStyles = cx(wrapperStyles, className);

  return <div className={combinedStyles} />;
};
