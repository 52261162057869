import { css, cx } from "@/domains/emotion";
import { useCallback, useEffect, useMemo, useState, FC, useRef } from "react";
import { shuffle as _shuffle } from "lodash-es";
import { mdsColors } from "@/design-system/foundations";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import MemIconSquare from "@/assets/images/mem-icon-square.png";

export interface GridOption {
  id: string;
  label: string | React.ReactNode;
}

export interface GridSelectionFormProps {
  prompt: string;
  sub: string;
  shuffle?: boolean;
  multiselect?: boolean;
  options: GridOption[];
  onSubmit: ({ selectedOptions }: { selectedOptions: GridOption[] }) => void;
}

export const GridSelectionForm: FC<GridSelectionFormProps> = ({
  prompt,
  shuffle,
  multiselect,
  options,
  onSubmit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<GridOption[]>([]);
  const [otherValue, setOtherValue] = useState<string>("");

  const otherInputRef = useRef<HTMLInputElement>(null);

  const isContinueEnabled = Boolean(selectedOptions.length || otherValue);

  const handleContinue = useCallback(() => {
    onSubmit({
      selectedOptions: [
        ...selectedOptions,
        ...(otherValue ? [{ id: otherValue, label: otherValue }] : []),
      ],
    });
  }, [onSubmit, otherValue, selectedOptions]);

  const toggleOption = useCallback(
    ({ option }: { option: GridOption }) => {
      setSelectedOptions(selectedOptions => {
        if (multiselect) {
          if (selectedOptions.some(selectedOption => selectedOption.id === option.id)) {
            return selectedOptions.filter(selectedOption => selectedOption.id !== option.id);
          }
          return [...selectedOptions, option];
        } else {
          setOtherValue("");
          return [option];
        }
      });
    },
    [multiselect]
  );

  const focusOtherInputOnClickHandler = useCallback(() => {
    if (otherInputRef.current) {
      otherInputRef.current.focus();
    }
  }, [otherInputRef]);

  useEffect(() => {
    if (multiselect) return;
    if (otherValue && selectedOptions.length) setSelectedOptions([]);
  }, [otherValue, multiselect, selectedOptions.length]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (isContinueEnabled && e.key === "Enter") handleContinue();
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [handleContinue, isContinueEnabled]);

  const shuffledOptions = useMemo(() => {
    return shuffle ? _shuffle(options) : options;
  }, [options, shuffle]);

  return (
    <div className={container}>
      <div className={messageContainer}>
        <img className={messageSender} src={MemIconSquare} />
        <div className={messageBubble}>{prompt}</div>
      </div>
      <div className={gridSection}>
        {shuffledOptions.map(option => (
          <div
            className={css(gridCard, selectedOptions.includes(option) && gridCardSelected)}
            key={option.id}
            onClick={() => toggleOption({ option })}
          >
            <div className={gridCardLabel}>{option.label}</div>
          </div>
        ))}
        <div
          onClick={focusOtherInputOnClickHandler}
          className={cx(css(gridCard, otherValue && gridCardSelected), gridInputStyles)}
        >
          <input
            ref={otherInputRef}
            className={otherInput}
            placeholder="Other..."
            type="text"
            value={otherValue}
            onChange={e => setOtherValue(e.target.value)}
          />
        </div>
      </div>
      <div className={continueSection}>
        <MdsButton
          label="Next"
          variant={MdsButtonVariant.Brand}
          size={MdsButtonSize.Medium}
          onClick={handleContinue}
          isDisabled={!isContinueEnabled}
        />
        <div
          className={cx(
            isContinueEnabled ? keyboardHint : keyboardHintDisabled,
            keyboardHintStyles
          )}
        >
          Press <strong>Enter ↵</strong>
        </div>
      </div>
    </div>
  );
};

const gridInputStyles = css({
  cursor: "text",
  userSelect: "none",
});

const container = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "420px",
  maxWidth: "100%",
  "@media (min-width: 672px) and (min-height: 632px)": {
    justifyContent: "center",
  },
});

const gridSection = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: "100%",
  gap: "8px",
  margin: "16px 0 24px",
});

const gridCard = css({
  boxSizing: "border-box",
  background: "#FFF",
  boxShadow: "0px 2px 4px rgba(69, 79, 104, 0.04)",
  border: `1px solid ${mdsColors().grey.x100}`,
  borderRadius: "8px",
  textAlign: "left",
  alignItems: "flex-start",
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  cursor: "pointer",
  transition: "transform 0.1s ease-in",
  transform: "scale(1.0)",
  img: {
    pointerEvents: "none",
    userSelect: "none",
  },
  "&:hover": {
    border: `1px solid ${mdsColors().grey.x50}`,
    boxShadow: "0px 3px 4px rgba(69, 79, 104, 0.09)",
    transform: "scale(1.01)",
    transition: "0.1s all ease-in",
  },
});

const gridCardSelected = css({
  border: `1px solid ${mdsColors().secondary.x400}`,
  transform: "scale(1.00)",
  background: "#F5F8FF",
  transition: "0.1s all cubic-bezier(0.36, 0, 0.66, -0.56)",
  "&:hover": {
    border: `1px solid ${mdsColors().secondary.x400}`,
  },
});

const gridCardLabel = css({
  color: mdsColors().grey.x600,
  fontSize: "14px",
  lineHeight: "20px",
  flex: "1",
  userSelect: "none",
});

const otherInput = css({
  width: "100%",
  background: "transparent",
  height: "16px",
  border: "none",
  color: mdsColors().grey.x600,
  fontSize: "14px",
  lineHeight: "20px",
  flex: "1",
});

const continueSection = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
});

const keyboardHint = css({
  fontSize: "12px",
  color: mdsColors().grey.x500,
  fontWeight: 500,
});

const keyboardHintDisabled = css({
  fontSize: "12px",
  color: mdsColors().grey.x300,
  fontWeight: 500,
});

const messageContainer = css({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const messageSender = css({
  borderRadius: "4px",
  width: "32px",
  height: "32px",
});

const messageBubble = css({
  borderRadius: "16px",
  padding: "12px",
  backgroundColor: "#E8E9ED",
  fontSize: "15px",
  lineHeight: "22px",
  color: mdsColors().grey.x600,
  fontWeight: 500,
});

const keyboardHintStyles = css({
  userSelect: "none",
});
