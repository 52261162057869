export const APP_CONFIG = {
  URLS: {
    LANDING_PAGE: "https://get.mem.ai",
    PRODUCT_UPDATES: "https://get.mem.ai/product-updates",
    FAQ: "https://get.mem.ai/faq",
    HELP_AND_SUPPORT: "https://support.mem.ai",
    YOUTUBE: "https://mem.ai/youtube",
    ZAPIER: "https://zapier.com/apps/mem/integrations",
    FEATURE_REQUESTS: "https://support.mem.ai/share-feedback/",
    BUG_REPORTS: "https://support.mem.ai/request-support/",
    SHARE_FEEDBACK: "https://support.mem.ai/share-feedback-form",
    REQUEST_SUPPORT: "https://support.mem.ai/article/7-request-support",
    REDIRECT: "https://get.mem.ai/redirect",
    IOS_APP: "https://apps.apple.com/app/mem-ai-note-taking-app/id6446449972",
  },
  SYSTEM: {
    HEALTH_CHECK_URL: "https://mem-api-prod.mem.engineering/health/ready",
  },
};
