import { css, cx } from "@/domains/emotion";

interface EmptyContainerProps {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export function EmptyContainer({ children, className, dataTestId }: EmptyContainerProps) {
  return (
    <div className={cx(containerStyles, className)} data-test-id={dataTestId}>
      {children}
    </div>
  );
}

const containerStyles = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  marginTop: "80px",
  img: {
    width: "80px",
  },
});
