export enum MdsBadgeSize {
  Small = "small",
  RoundedSmall = "rounded-small",
  Medium = "medium",
}

export enum MdsBadgeKind {
  Brand = "brand",
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
}

export enum MdsBadgeVariant {
  Standard = "standard",
  Light = "light",
}
