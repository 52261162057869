import { isNumber } from "lodash-es";

import { RuntimeParseError } from "@/domains/errors";
import { _extractValueFromObject } from "@/modules/object/extractors/extractValue";

export const _extractNumberFromObject = (value: unknown, prop: string): number => {
  const extractedValue = _extractValueFromObject(value, prop);

  if (!isNumber(extractedValue)) {
    throw new RuntimeParseError({
      message: "[_extractNumberFromObject] extractedValue was not a number.",
      info: {
        extractedValue,
        value,
        prop,
      },
    });
  }

  return extractedValue;
};
