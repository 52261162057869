import { globalToastStyleOverrides } from "@/components/toast/styles";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface GlobalToastContainerProps {}

const DEFAULT_TOAST_TIMEOUT = 2_500;

export const GlobalToastContainer: FC<GlobalToastContainerProps> = observer(() => {
  return (
    <div className={globalToastStyleOverrides}>
      <ToastContainer
        position="bottom-center"
        autoClose={DEFAULT_TOAST_TIMEOUT}
        hideProgressBar
        icon={false}
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        stacked
        pauseOnHover
        theme="dark"
        className={globalToastStyleOverrides}
      />
    </div>
  );
});
