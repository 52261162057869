import { makeAutoObservable, observable, runInAction } from "mobx";

export class HoveringState {
  hovering = false;
  hoveredRect: DOMRect | null = null;

  constructor() {
    makeAutoObservable(this, {
      hovering: observable,
    });
  }

  private hoveringDelayed = false;
  private timeoutHandle = 0;

  setHoveringDelayed = (hovering: boolean) => {
    if (this.hoveringDelayed === hovering) {
      return;
    }

    clearTimeout(this.timeoutHandle);
    this.hoveringDelayed = hovering;
    this.timeoutHandle = window.setTimeout(runInAction, 300, () => {
      this.hovering = this.hoveringDelayed;
    });
  };

  handleMouseLeave = () => {
    this.setHoveringDelayed(false);
  };

  handleMouseEnter = () => {
    this.setHoveringDelayed(true);
  };
}
