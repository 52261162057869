import { MdsChip } from "@/design-system/components/input/types";
import { SyncScopeRoleKind } from "@/domains/sync-scopes/types";

export enum ShareSheetEntityKind {
  Note = "note",
  Collection = "collection",
}

export enum ShareSheetChipType {
  Email = "email",
  SpaceAccount = "space_account",
  Collection = "collection",
}

export type ShareChip = MdsChip & {
  id: string;
  type: ShareSheetChipType;
  displayName: string;
  secondaryLabel?: string;
  photoUrl?: string;
  role?: SyncScopeRoleKind;
};
