import { fontInterBold, fontInterMedium, fontInterRegular, fontInterSemiBold } from "@/assets";
import { injectGlobal } from "@/domains/emotion";

export const MEM_FONT_FAMILY_ATTRIBUTE_STRING = `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

export const _injectGlobalProviders = () => {
  injectGlobal({
    "@font-face": {
      fontFamily: "Inter",
      fontStyle: `normal`,
      fontWeight: 400,
      fontDisplay: `auto`,
      src: `url("${fontInterRegular}") format("truetype")`,
    },
  });

  injectGlobal({
    "@font-face": {
      fontFamily: "Inter",
      fontStyle: `normal`,
      fontWeight: 500,
      fontDisplay: `auto`,
      src: `url("${fontInterMedium}") format("truetype")`,
    },
  });

  injectGlobal({
    "@font-face": {
      fontFamily: "Inter",
      fontStyle: `normal`,
      fontWeight: 600,
      fontDisplay: `auto`,
      src: `url("${fontInterSemiBold}") format("truetype")`,
    },
  });

  injectGlobal({
    "@font-face": {
      fontFamily: "Inter",
      fontStyle: `normal`,
      fontWeight: 700,
      fontDisplay: `auto`,
      src: `url("${fontInterBold}") format("truetype")`,
    },
  });

  injectGlobal({
    "*:focus-visible": {
      // Avoid blue outline on modals and other elements.
      outline: "none",
    },

    html: {
      textRendering: "optimizeLegibility",
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
    },

    body: {
      margin: 0,
    },

    input: {
      outline: "none",
      border: "none",
    },

    button: {
      outline: "none",
      border: "none",
      cursor: "pointer",
      userSelect: "none",
      color: "unset",
      backgroundColor: "unset",
    },

    submit: {
      border: "none",
    },

    a: {
      textDecoration: "none",
    },

    "*": {
      boxSizing: "border-box",
      fontFamily: MEM_FONT_FAMILY_ATTRIBUTE_STRING,
    },
  });
};
