export const withLineBreaks = (text: string, maxLength = 40): string => {
  const tokens = text.split(" ");
  const htmlParts: string[] = [];

  let lineLength = 0;
  tokens.forEach((token, i) => {
    if (lineLength > maxLength) {
      htmlParts.push(`<br>${token}`);
      lineLength = token.length;
      return;
    }

    if (i === 0) {
      htmlParts.push(token);
      lineLength += token.length;
      return;
    }

    htmlParts.push(` ${token}`);
    lineLength += token.length + 1;
  });

  return htmlParts.join("");
};
