import { LoggerInstance } from "@/domains/logging/types";
import { loggerClientManager } from "@/manager/logging-client";

export const GLOBAL_LOGGER_INSTANCE: LoggerInstance = {
  setEnvironment: async (...args) => loggerClientManager.instance().setEnvironment(...args),
  trace: async (...args) => loggerClientManager.instance().trace(...args),
  debug: async (...args) => loggerClientManager.instance().debug(...args),
  info: async (...args) => loggerClientManager.instance().info(...args),
  warn: async (...args) => loggerClientManager.instance().warn(...args),
  error: async (...args) => loggerClientManager.instance().error(...args),
  flushLogs: async () => loggerClientManager.instance().flushLogs(),
};
