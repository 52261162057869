import { action, makeObservable, observable, computed } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { NoteObservable } from "@/store/note/NoteObservable";
import { actions } from "@/actions";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export class RemoveNotesFromSharedCollectionModalStore extends AppSubStore {
  public notes: NoteObservable[] = [];
  public collection: CollectionObservable | null = null;
  private handleConfirm?: () => void;
  private onClose?: () => void;

  constructor(
    injectedDeps: AppSubStoreArgs,
    { handleConfirm, onClose }: { handleConfirm?: () => void; onClose?: () => void }
  ) {
    super(injectedDeps);

    this.handleConfirm = handleConfirm;
    this.onClose = onClose;

    makeObservable<this, "handleConfirm" | "onClose">(this, {
      handleConfirm: false,
      onClose: false,
      notes: observable,
      collection: observable,
      isOpen: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get isOpen() {
    return this.notes.length > 0 && !!this.collection;
  }

  public handleSubmit() {
    if (!this.notes.length || !this.collection) return;
    actions.removeNotesFromCollection({
      noteIds: this.notes.map(note => note.id),
      collection: this.collection,
      store: this.store,
    });
    this.handleConfirm?.();
    this.close();
  }

  public open({
    notes,
    collection,
  }: {
    notes: NoteObservable[];
    collection?: CollectionObservable;
  }) {
    this.notes = notes;
    this.collection = collection || null;
  }

  public close() {
    this.notes = [];
    this.collection = null;
    this.onClose?.();
  }
}
