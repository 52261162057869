import { animated, useTransition } from "@react-spring/web";
import React, { memo } from "react";

import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { css, cx } from "@/domains/emotion";

interface MdsTextFieldFloatingPlaceholderProps extends EmotionClassStyles {
  shouldShowFloatingPlaceholder: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

const floatingPlaceholderStyles = css({
  position: "absolute",
  top: 0,
  left: 11,
  marginTop: 1,
  /**
   * Fades in-and-out from top to bottom.
   */
  background:
    "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(255, 255, 255, 0) 100%)",
  padding: "0 2px",
  fontSize: "11px",
  color: mdsColors().grey.x400,
  fontWeight: mdsFontWeights().regular,
  transition: "all 1s",
  userSelect: "none",
});

export const MdsTextFieldFloatingPlaceholder = memo(
  ({
    shouldShowFloatingPlaceholder,
    isDisabled,
    placeholder,
    className,
  }: MdsTextFieldFloatingPlaceholderProps) => {
    const transitions = useTransition(shouldShowFloatingPlaceholder, {
      from: { itemVisibility: 0 },
      enter: { itemVisibility: 1 },
      leave: { itemVisibility: 0 },
      config: { duration: 80 },
    });

    if (!placeholder) {
      return null;
    }

    const disabledStyles = css({
      opacity: 0,
    });

    const combinedStyles = cx(floatingPlaceholderStyles, className, isDisabled && disabledStyles);

    return transitions(
      ({ itemVisibility }, showItem) =>
        showItem && (
          <animated.div
            style={{
              opacity: itemVisibility.to({ output: [0, 1] }),
              translateY: itemVisibility.to({ output: [-3, -6] }),
            }}
          >
            <div className={combinedStyles}>{placeholder}</div>
          </animated.div>
        )
    );
  }
);
