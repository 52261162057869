import React, { ReactNode, FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors } from "@/design-system/foundations/colors";
import {
  mdsFontWeights,
  mdsFontSizes,
  mdsLineHeights,
} from "@/design-system/foundations/typography";
import {
  MdsTextAlign,
  MdsTextOverflow,
  MdsTextSize,
  MdsTextStylingMode,
  MdsTextWeight,
} from "@/design-system/components/text/types";
import { MdsColor } from "@/design-system/constants/colors/types";

export interface MdsTextStylingProps extends EmotionClassStyles {}

export interface MdsTextProps extends MdsTextStylingProps {
  stylingMode?: MdsTextStylingMode;
  align?: MdsTextAlign;
  lineHeight?: MdsTextSize;
  size?: MdsTextSize;
  weight?: MdsTextWeight;
  overflow?: MdsTextOverflow;
  children: ReactNode;
  color?: MdsColor;
  numberOfLines?: number;
  onClick?: () => void;
}

const defaultStyles = css({
  color: mdsColors().grey.x700,
  fontWeight: mdsFontWeights().regular,
});

const inlineStyles = css({
  display: "inline",
});

const clickableTextStyles = css({
  color: mdsColors().blue.x500,
  cursor: "pointer",
  textDecoration: "underline",
  userSelect: "none",
});

const mdsTextAlignStyleMapping: { [key in MdsTextAlign]: string } = {
  [MdsTextAlign.Left]: css({
    textAlign: "left",
  }),
  [MdsTextAlign.Center]: css({
    textAlign: "center",
  }),
  [MdsTextAlign.Right]: css({
    textAlign: "right",
  }),
};

const mdsTextLineHeightStyleMapping: { [key in MdsTextSize]: string } = {
  [MdsTextSize.XXLarge]: css({
    lineHeight: mdsLineHeights().xxlarge,
  }),
  [MdsTextSize.XLarge]: css({
    lineHeight: mdsLineHeights().xlarge,
  }),
  [MdsTextSize.Large]: css({
    lineHeight: mdsLineHeights().large,
  }),
  [MdsTextSize.Medium]: css({
    lineHeight: mdsLineHeights().medium,
  }),
  [MdsTextSize.Small]: css({
    lineHeight: mdsLineHeights().small,
  }),
  [MdsTextSize.XSmall]: css({
    lineHeight: mdsLineHeights().xsmall,
  }),
  [MdsTextSize.XXSmall]: css({
    lineHeight: mdsLineHeights().xxsmall,
  }),
};

const mdsTextSizeStyleMapping: { [key in MdsTextSize]: string } = {
  [MdsTextSize.XXLarge]: css({
    fontSize: mdsFontSizes().xxlarge,
  }),
  [MdsTextSize.XLarge]: css({
    fontSize: mdsFontSizes().xlarge,
  }),
  [MdsTextSize.Large]: css({
    fontSize: mdsFontSizes().large,
  }),
  [MdsTextSize.Medium]: css({
    fontSize: mdsFontSizes().medium,
  }),
  [MdsTextSize.Small]: css({
    fontSize: mdsFontSizes().small,
  }),
  [MdsTextSize.XSmall]: css({
    fontSize: mdsFontSizes().xsmall,
  }),
  [MdsTextSize.XXSmall]: css({
    fontSize: mdsFontSizes().xxsmall,
  }),
};

const mdsTextWeightStyleMapping: { [key in MdsTextWeight]: string } = {
  [MdsTextWeight.Bold]: css({
    fontWeight: mdsFontWeights().bold,
  }),
  [MdsTextWeight.SemiBold]: css({
    fontWeight: mdsFontWeights().semiBold,
  }),
  [MdsTextWeight.Medium]: css({
    fontWeight: mdsFontWeights().medium,
  }),
  [MdsTextWeight.Regular]: css({
    fontWeight: mdsFontWeights().regular,
  }),
};

const mdsTextOverflowStyleMapping: { [key in MdsTextOverflow]: string } = {
  [MdsTextOverflow.Ellipsis]: css({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  [MdsTextOverflow.EllipsisWrap]: css({
    overflow: "hidden",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
  }),
  [MdsTextOverflow.Regular]: css({}),
};

export const MdsText: FC<MdsTextProps> = ({
  stylingMode = MdsTextStylingMode.ApplyDefaultStyles,
  className,
  lineHeight,
  onClick,
  children,
  numberOfLines,
  ...extraProps
}) => {
  const applyDefaultStyles = stylingMode === MdsTextStylingMode.ApplyDefaultStyles;
  const { align, size, weight, overflow, color } = !applyDefaultStyles
    ? extraProps
    : {
        align: MdsTextAlign.Left,
        size: MdsTextSize.Medium,
        weight: MdsTextWeight.Regular,
        overflow: MdsTextOverflow.Regular,
        color: mdsColors().grey.x700,
        ...extraProps,
      };
  const alignStyles = align && mdsTextAlignStyleMapping[align];
  const lineHeightStyles = lineHeight && mdsTextLineHeightStyleMapping[lineHeight];
  const sizeStyles = size && mdsTextSizeStyleMapping[size];
  const weightStyles = weight && mdsTextWeightStyleMapping[weight];
  const overflowStyles = overflow && mdsTextOverflowStyleMapping[overflow];
  const numberOfLinesStyles =
    !!numberOfLines &&
    css({
      overflow: "hidden",
      /**
       * Using the older `-webkit-line-clamp` APIs.
       * https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
       *
       * Requires `display: "-webkit-box"` and `-webkit-box-orient: "vertical"`
       * to be set in order to work.
       */
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: numberOfLines,
      maxHeight: `${numberOfLines}lh`,
    });

  const colorsStyles =
    color &&
    css({
      color: color,
    });

  const clickableStyles = onClick && clickableTextStyles;

  const combinedStyles = cx(
    applyDefaultStyles ? defaultStyles : inlineStyles,
    alignStyles,
    lineHeightStyles,
    sizeStyles,
    weightStyles,
    overflowStyles,
    colorsStyles,
    clickableStyles,
    numberOfLinesStyles,
    className
  );

  return (
    <div className={combinedStyles} onClick={onClick}>
      {children}
    </div>
  );
};
