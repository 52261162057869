import { Uuid } from "@/domains/global/identifiers";
import { GuestAppStore } from "@/store";
import { ContactModelData, IContactModel } from "@/store/contacts/types";
import { SyncUpdateValue } from "@/store/sync/types";
import { action, computed, makeObservable, observable } from "mobx";

export class GuestContactObservable implements IContactModel {
  store: GuestAppStore;
  id: Uuid;
  remoteData: SyncUpdateValue<ContactModelData>;
  isDeleted: boolean = false;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<ContactModelData>;
    store: GuestAppStore;
  }) {
    this.store = store;

    this.id = id;
    this.remoteData = data;

    makeObservable(this, {
      store: false,
      contactSpaceAccountId: computed,
      isDirect: computed,
      id: observable,
      remoteData: observable,
      updateFromRemote: action,
      deleteFromRemote: action,
      isDeleted: observable,
      modelData: computed,
      profileDisplayName: computed,
      profileEmailAddress: computed,
      profilePhotoUrl: computed,
    });
  }

  updateFromRemote({ data }: { data: SyncUpdateValue<ContactModelData> }) {
    this.remoteData = data;
    this.isDeleted = false;
  }

  deleteFromRemote() {
    this.isDeleted = true;
  }

  // PROPERTIES
  get modelData() {
    return this.remoteData.model_data;
  }

  get contactSpaceAccountId(): string {
    return this.modelData.space_account_id;
  }

  get profileDisplayName(): string {
    return this.modelData.profile_display_name;
  }

  get profileEmailAddress(): string {
    return this.modelData.profile_email_address;
  }

  get profilePhotoUrl(): string | null {
    return this.modelData.profile_photo_url;
  }

  get isDirect(): boolean {
    // Fix this
    return true;
  }
}
