import { mdsSidebarWidths, PanelMode } from "@/design-system/foundations";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { SidebarExpanded } from "@/components/layout/components/sidebar/SidebarExpanded";
import { SidebarCollapsed } from "@/components/layout/components/sidebar/SidebarCollapsed";
import { AnimatePresence, motion } from "framer-motion";

enum SidebarVariant {
  Expanded = "expanded",
  Collapsed = "collapsed",
  Hidden = "hidden",
}

const sidebarVariants = {
  [SidebarVariant.Expanded]: { width: mdsSidebarWidths().expanded, x: 0, opacity: 1 },
  [SidebarVariant.Collapsed]: { width: mdsSidebarWidths().collapsed, x: 0, opacity: 1 },
  [SidebarVariant.Hidden]: { width: 0, x: -mdsSidebarWidths().expanded, opacity: 0 },
};

export const Sidebar = observer(() => {
  const { store } = useAppStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const isSplitView = store.sidePanel.isSplitView;

  const breakpoints = store.interface.matchesPanelModeBreakpoint;
  const matchesNarrowView = !!breakpoints.get(PanelMode.Narrow);
  const matchesMediumSplitView = !!breakpoints.get(PanelMode.MediumSplitView);
  const matchesWideSplitView = !!breakpoints.get(PanelMode.WideSplitView);

  const { isWideEnough, currentVariant } = useMemo(() => {
    if (matchesNarrowView) return { isWideEnough: false, currentVariant: SidebarVariant.Hidden };

    const isWideEnough = isSplitView
      ? matchesWideSplitView
      : matchesMediumSplitView || matchesWideSplitView;

    return {
      isWideEnough,
      currentVariant:
        isWideEnough && isExpanded ? SidebarVariant.Expanded : SidebarVariant.Collapsed,
    };
  }, [isSplitView, isExpanded, matchesNarrowView, matchesMediumSplitView, matchesWideSplitView]);

  return (
    <motion.div
      layout
      initial={false}
      animate={currentVariant}
      variants={sidebarVariants}
      style={{ overflow: "hidden", flexShrink: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <AnimatePresence mode="wait">
        {currentVariant === SidebarVariant.Collapsed ? (
          <SidebarCollapsed
            shouldHideToggle={!isWideEnough}
            toggleSidebar={() => setIsExpanded(true)}
            key="collapsed"
          />
        ) : (
          <SidebarExpanded toggleSidebar={() => setIsExpanded(false)} key="expanded" />
        )}
      </AnimatePresence>
    </motion.div>
  );
});
