import { observer } from "mobx-react-lite";
import { FC } from "react";
import { appRoutes } from "@/app/router";
import { localStorageModule } from "@/modules/local-storage";
import { usePublicAppStore } from "@/store";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { StandardModePadding } from "@/app/outlets/StandardModePadding";

interface RequireStandardModeOutletProps {}

export const RequireStandardModeOutlet: FC<RequireStandardModeOutletProps> = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { publicStore } = usePublicAppStore();

  const hasSufficientAuth = publicStore.auth.isStandardMode;

  useEffect(() => {
    if (hasSufficientAuth) {
      return;
    }

    /**
     * We redirect them to the /login page, but save the current location they were
     * trying to go to when they were redirected. This allows us to send them
     * along to that page after they login, which is a nicer user experience
     * than dropping them off on the home page.
     */
    const asyncLogic = async () => {
      await localStorageModule.writePendingRedirectPath({
        pathname: location.pathname,
      });

      /**
       * Using this because we don't have access to the `navigation` store when
       * on a public route.
       */
      navigate(appRoutes.logIn({}).path, { replace: true });
    };

    asyncLogic();
  }, [location.pathname, navigate, hasSufficientAuth]);

  if (!hasSufficientAuth) {
    return null;
  }

  return (
    <StandardModePadding>
      <Outlet />
    </StandardModePadding>
  );
});
