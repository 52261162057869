import { Maybe } from "@/domains/common/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import {
  QueueProcessingState,
  SyncModelData,
  SyncUpdate,
  SyncUpdateResponse,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  IReactionDisposer,
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";

import { standardizeSyncId } from "@/store/sync/utils";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store";
import { DateTime, Duration } from "luxon";
import {
  InboundSyncState,
  InboundSyncStatus,
  OutboundSyncState,
  OutboundSyncStatus,
} from "@/components/sync/types";
import { first } from "lodash-es";

const ONE_MINUTE = 60 * 1000;

const MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED = Duration.fromObject({ seconds: 90 });
const SYNC_IS_UP_TO_DATE_DURATION = Duration.fromObject({ seconds: 10 });

export interface BaseQueryValue {
  results: SyncUpdateResponse[];
}

export abstract class AppStoreBaseSyncStore<
  Store extends AppStore | GuestAppStore,
  SyncActionQueue extends BaseSyncActionQueue<AppStore | GuestAppStore>,
  QueryValue extends BaseQueryValue,
> extends AppSubStore<Store> {
  public actionQueue: SyncActionQueue;

  /**
   * These are "Inbound" sync attributes.
   * "Outbound" sync attributes are stored on our ActionQueue.
   */
  protected lastSyncId: Maybe<string> = undefined;
  latestSpaceAccountSequenceId: Maybe<number> = undefined;

  lastSyncedAt: Maybe<DateTime> = undefined;
  isBootstrapping = false;
  isSyncing = false;
  hasCompletedInitialSync = false;

  private pollingDisposer: Maybe<IReactionDisposer> = undefined;
  protected pollingInterval = ONE_MINUTE;

  abstract createSyncActionQueue(): SyncActionQueue;
  abstract finishProcessingQueryResponse(_: QueryValue): void;
  abstract syncQuery: QueryObservable<QueryValue>;
  abstract subscribe(): void;
  abstract unsubscribe(): void;
  abstract processSyncUpdate(
    update: SyncUpdate<SyncModelData>,
    options?: { hydrating?: boolean }
  ): void;
  abstract hydrateFromStorage(): Promise<void>;
  abstract fetchBootstrapEvents(): Promise<{
    lastSyncId: Maybe<string>;
    allEvents: SyncUpdate<SyncModelData>[];
  }>;
  abstract saveBootstrapEvents(events: SyncUpdate<SyncModelData>[]): Promise<void>;

  constructor(injectedDeps: AppSubStoreArgs<Store>) {
    super(injectedDeps);

    this.actionQueue = this.createSyncActionQueue();

    makeObservable<this, "lastSyncId" | "pollingDisposer" | "pollingInterval">(this, {
      actionQueue: observable,
      lastSyncId: observable,
      latestSpaceAccountSequenceId: observable,

      pollingDisposer: false,
      pollingInterval: true,

      createSyncActionQueue: false,
      finishProcessingQueryResponse: action,
      syncQuery: computed,
      subscribe: action,
      unsubscribe: action,

      processSyncUpdate: action,
      hydrateFromStorage: action,
      fetchBootstrapEvents: action,
      saveBootstrapEvents: action,
      startPolling: action,
      bootstrap: action,
      stopSync: action,
      resetSync: action,
      isBootstrapping: observable,
      hasCompletedInitialSync: observable,
      isSyncing: observable,
      lastSyncedAt: observable,
      initialize: action,
      isReady: computed,
      isUpToDate: computed,

      inboundSyncStatus: computed,
      inboundSyncState: computed,
      outboundSyncStatus: computed,
      outboundLastSyncedAt: computed,
      pendingOutboundOperationCount: computed,
      earliestPendingOutboundOperationCommittedAt: computed,
      outboundSyncState: computed,
    });
  }

  public startPolling() {
    if (this.pollingDisposer) return;
    console.debug("[SYNC][AppStoreSyncStore] Initializing sync updates polling...");
    this.pollingDisposer = reaction(
      () => this.syncQuery?.data,
      async data => {
        runInAction(() => {
          this.isSyncing = true;
        });

        runInAction(() => {
          // Re: writes across collections
          // - processSyncUpdate writes to localDb.syncUpdates
          // - confirmSyncUpdatesUntil writes to localDb.queue
          // Since there's no transaction support across IndexedDB collections it's possible that
          // a crash between both leaves pending queue items which should be automatically fixed
          // once new sync updates are received from the network.
          if (data?.results?.length) {
            for (const update of data.results) {
              this.processSyncUpdate(update);
            }

            this.finishProcessingQueryResponse(data);
          }
        });

        runInAction(() => {
          this.isSyncing = false;
          this.lastSyncedAt = DateTime.utc();
          /**
           * We keep track of "when it completes the first sync after bootstrapping."
           * If the user hasn't connected in a while, this may take longer than usual.
           */
          this.hasCompletedInitialSync = true;
        });
      },
      { fireImmediately: true }
    );
  }

  public async initialize() {
    runInAction(() => {
      this.isBootstrapping = true;
    });

    try {
      await this.hydrateFromStorage();
      await this.bootstrap();
      this.actionQueue.start();
      this.startPolling();
      this.subscribe();
    } catch (err) {
      logger.error({
        message: "[useEffectOnMount] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err as Error) },
      });
    } finally {
      runInAction(() => {
        this.isBootstrapping = false;
      });
    }
  }

  public async bootstrap() {
    if (this.lastSyncId !== undefined) return;

    console.debug("[SYNC][AppStoreSyncStore] Starting Bootstrap...");

    const { lastSyncId, allEvents } = await this.fetchBootstrapEvents();

    if (lastSyncId === undefined) return; // Bootstrap failed, fallback on sync with undefined lastSyncId

    // NOTE: All actions need to be persisted at once because bootstrapSyncEvents are only valid as a full set
    runInAction(() => {
      for (const event of allEvents) this.processSyncUpdate(event);
      this.lastSyncedAt = DateTime.now();
      this.lastSyncId = standardizeSyncId(lastSyncId);
    });

    await this.saveBootstrapEvents(allEvents);
    console.debug("[SYNC][AppStoreSyncStore] Completed Bootstrap", {
      lastSyncId: this.lastSyncId,
      events: allEvents.length,
    });
  }

  get isReady() {
    return !!this.lastSyncedAt;
  }

  get isUpToDate() {
    const lastSync = this.lastSyncedAt ?? DateTime.fromMillis(0);

    /**
     * If we've synced within the last "SYNC_IS_UP_TO_DATE_DURATION" seconds,
     * we consider ourselves "up to date."
     */
    return lastSync.diffNow().milliseconds <= SYNC_IS_UP_TO_DATE_DURATION.milliseconds;
  }

  public stopSync() {
    this.actionQueue.pause();
    this.unsubscribe();
    this.pollingDisposer?.();
    this.pollingDisposer = undefined;
  }

  public resetSync() {
    this.actionQueue.reset();
  }

  get inboundSyncStatus(): InboundSyncStatus {
    /**
     * @todo - Check network status here and return "PausedOffline" if we're offline.
     * See - https://linear.app/mem-labs/issue/MEM-8072/introduce-an-online-offline-network-checker-which-is-tied-into-mobx
     */

    if (this.isBootstrapping) {
      return InboundSyncStatus.Bootstrapping;
    }

    if (this.isSyncing) {
      if (!this.hasCompletedInitialSync) {
        return InboundSyncStatus.PerformingInitialSync;
      }

      return InboundSyncStatus.Syncing;
    }

    return InboundSyncStatus.Idle;
  }

  get inboundSyncState(): InboundSyncState {
    return {
      status: this.inboundSyncStatus,
      info: {
        lastSyncedAt: this.lastSyncedAt,
      },
    };
  }

  get outboundSyncStatus(): OutboundSyncStatus {
    /**
     * @todo - Check network status here and return "PausedOffline" if we're offline.
     * See - https://linear.app/mem-labs/issue/MEM-8072/introduce-an-online-offline-network-checker-which-is-tied-into-mobx
     */

    const queueProcessingState = this.actionQueue.processingState;

    /**
     * If we have a pending operation which has been in queue for over a minute...
     */
    if (
      this.earliestPendingOutboundOperationCommittedAt &&
      this.earliestPendingOutboundOperationCommittedAt <
        DateTime.utc().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED)
    ) {
      /**
       *  ...and we haven't synced in over a minute, we mark our status as "paused"
       */
      if (
        !this.outboundLastSyncedAt ||
        this.outboundLastSyncedAt <
          DateTime.utc().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED)
      ) {
        return OutboundSyncStatus.PausedUnknown;
      }
    }

    /**
     * Otherwise, we can just return a mapping of the queue processing state.
     */
    switch (queueProcessingState) {
      case QueueProcessingState.NotReady:
        return OutboundSyncStatus.PausedUnknown;

      case QueueProcessingState.Paused:
        return OutboundSyncStatus.PausedUnknown;

      case QueueProcessingState.Processing:
        return OutboundSyncStatus.Syncing;

      case QueueProcessingState.Ready:
        return OutboundSyncStatus.Idle;

      default:
        return OutboundSyncStatus.Unknown;
    }
  }

  get outboundLastSyncedAt(): Maybe<DateTime> {
    return this.actionQueue.lastProcessedAt;
  }

  get pendingOutboundOperationCount(): number {
    return this.actionQueue.processing.length;
  }

  get earliestPendingOutboundOperationCommittedAt(): Maybe<DateTime> {
    const firstOperation = first(this.actionQueue.processing);

    if (!firstOperation) {
      return undefined;
    }

    return DateTime.fromISO(firstOperation.committedAt);
  }

  get outboundSyncState(): OutboundSyncState {
    return {
      status: this.outboundSyncStatus,
      info: {
        lastSyncedAt: this.outboundLastSyncedAt,
        pendingOperationCount: this.pendingOutboundOperationCount,
        earliestPendingOperationCommittedAt: this.earliestPendingOutboundOperationCommittedAt,
      },
    };
  }
}
