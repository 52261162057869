import { PlatformSubscriptionBillingStrategy } from "@/store/platform-subscription/types";

export type StripeSubscriptionPlan<
  BillingStrategy extends PlatformSubscriptionBillingStrategy = PlatformSubscriptionBillingStrategy,
> = {
  priceText: string;
  captionText: string;
  dueTodayText: string;
  bannerText: string | null;
  billingStrategy: BillingStrategy;
};

export const STRIPE_SUBSCRIPTION_PLANS: {
  [BillingStrategy in PlatformSubscriptionBillingStrategy]: StripeSubscriptionPlan<BillingStrategy>;
} = {
  [PlatformSubscriptionBillingStrategy.Yearly]: {
    billingStrategy: PlatformSubscriptionBillingStrategy.Yearly,
    priceText: "$8.33 / month",
    dueTodayText: "$99.99",
    captionText: "$99.99 per year, billed yearly",
    bannerText: "Save 44%",
  },
  [PlatformSubscriptionBillingStrategy.Monthly]: {
    billingStrategy: PlatformSubscriptionBillingStrategy.Monthly,
    priceText: "$14.99 / month",
    dueTodayText: "$14.99",
    captionText: "$179.88 per year, billed monthly",
    bannerText: null,
  },
};
