import { appRoutes } from "@/app/router";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { usePublicAppStore } from "@/store";

interface LandingPageProps {}

export const LandingPage: FC<LandingPageProps> = observer(() => {
  const { publicStore } = usePublicAppStore();

  if (publicStore.auth.isAuthenticated) {
    return <Navigate to={appRoutes.notesList({}).path} />;
  }

  return <Navigate to={appRoutes.logIn({}).path} />;
});
