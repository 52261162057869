import { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { mdsBorderRadius, mdsColors, mdsFontSizes, mdsSpacings } from "@/design-system/foundations";

interface Props extends PropsWithChildren, ChipProps {
  onClick: (e: React.MouseEvent) => void;
}
export const FeedbackChip = ({ onClick, children, isSelected }: Props) => {
  return (
    <Chip onClick={onClick} isSelected={isSelected}>
      {children}
    </Chip>
  );
};

interface ChipProps {
  isSelected: boolean;
}
const Chip = styled.button<ChipProps>(({ isSelected }) => ({
  display: "inline-flex",
  width: "fit-content",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${isSelected ? mdsColors().grey.x200 : mdsColors().grey.x100}`,
  padding: `${mdsSpacings().sm} ${mdsSpacings().md}`,
  borderRadius: mdsBorderRadius().mediumLarge,
  color: mdsColors().grey.x875,
  fontSize: mdsFontSizes().small,
  backgroundColor: isSelected ? mdsColors().grey.x50 : "transparent",
}));
