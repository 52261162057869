import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountContactModelData } from "@/store/contacts/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountContactObservable } from "@/store/contacts/SpaceAccountContactObservable";

export class AppStoreSpaceAccountContactsStore extends BaseSyncModelStore<
  SpaceAccountContactObservable,
  SpaceAccountContactModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountContact, ...injectedDeps });
  }

  createSyncModel(data: SyncUpdateValue<SpaceAccountContactModelData>) {
    return new SpaceAccountContactObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
