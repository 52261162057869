import { CriticalErrorCode } from "@/domains/critical-errors/types";

export const errorMessagesToCriticalErrorCodes = [
  {
    errorName: "VersionError",
    code: CriticalErrorCode.IncompatibleLocalDbVersion,
  },
  {
    errorName: "QuotaExceededError",
    text: "Encountered full disk while opening backing store for indexedDB.open.",
    code: CriticalErrorCode.DiskQuotaExceeded,
  },
  {
    text: "Connection to Indexed Database server lost. Refresh the page to try again",
    code: CriticalErrorCode.DBConnectionLostMustRefresh,
  },
  {
    text: "A mutation operation was attempted on a database that did not allow mutations.",
    code: CriticalErrorCode.ReadOnlyDB,
  },
  {
    text: "The database connection is closing.",
    code: CriticalErrorCode.DBConnectionIsClosing,
    onlyAfterTimeout: true,
  },
  {
    text: "Internal error opening backing store for indexedDB.open.",
    code: CriticalErrorCode.DBConnectionIsClosing,
    onlyAfterTimeout: true,
  },
  {
    // This can happen when a laptop returns from sleep.
    text: "Transaction timed out due to inactivity.",
    code: CriticalErrorCode.ReopenDBTimeout,
    onlyAfterTimeout: true,
  },
  // Program errors:
  {
    // PK in Dexie has changed.
    errorName: "UpgradeError",
    text: "Not yet support for changing primary key",
    code: CriticalErrorCode.ProgramError,
  },
];
