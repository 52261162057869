import { Panel } from "react-resizable-panels";
import styled from "@emotion/styled";

export const MdsPanel = styled(Panel)({
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  border: "1px solid #F3F4F6",
  containerType: "inline-size",
  containerName: "mds-panel",
});
