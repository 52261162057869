import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { RemoveNotesFromSharedCollectionModalStore } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModalStore";
import { NoteChip } from "@/components/chips/NoteChip";
import { useMemo } from "react";
import { hasAccessToNoteAfterRemovingFromCollection } from "@/store/sync/operations/helpers/collections";
import { MdsButtonShape } from "@/design-system/components/button/types";

export interface RemoveNoteFromSharedCollectionModalProps {
  store: RemoveNotesFromSharedCollectionModalStore;
}

export const RemoveNotesFromSharedCollectionModal =
  observer<RemoveNoteFromSharedCollectionModalProps>(({ store }) => {
    const isMultipleNotes = store.notes.length > 1;

    const removeWarningText = useMemo(() => {
      if (store.notes.length > 1)
        return "By removing these notes from the collection, you and others may lose access.";
      if (!store.notes[0] || !store.collection)
        return "By removing this note from the collection, you and others may lose access.";
      if (
        hasAccessToNoteAfterRemovingFromCollection({
          store: store.store,
          note: store.notes[0],
          collection: store.collection,
        })
      ) {
        return "By removing this note from the collection, others may lose access.";
      } else {
        return "By removing this note from the collection, you and others may lose access.";
      }
    }, [store.notes, store.collection, store.store]);

    if (!store.notes.length || !store.collection) return null;
    return (
      <MdsModal
        rootElementId="root"
        isModalOpen={store.isOpen && !!store.notes.length && !!store.collection}
        handleCloseModal={() => store.close()}
      >
        <div className={modalContainerStyles}>
          <div className={headerStyles}>
            <div className={titleStyles}>
              <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
                Remove{" "}
                {isMultipleNotes ? "notes" : <NoteChip title={store.notes[0].title} inline />} from
                collection?
              </MdsText>
            </div>
            <MdsSpacer />
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
          </div>
          <MdsText
            lineHeight={MdsTextSize.Small}
            color={mdsColors().grey.x600}
            size={MdsTextSize.Small}
          >
            {removeWarningText}
          </MdsText>
          <div className={buttonSectionStyles}>
            <MdsButton
              label="Cancel"
              variant={MdsButtonVariant.Tertiary}
              shape={MdsButtonShape.Round}
              onClick={() => store.close()}
            />
            <MdsButton
              label={`Remove ${isMultipleNotes ? "notes" : "note"}`}
              variant={MdsButtonVariant.Danger}
              shape={MdsButtonShape.Round}
              onClick={() => store.handleSubmit()}
            />
          </div>
        </div>
      </MdsModal>
    );
  });

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "30px",
  maxWidth: 500,
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
