import { uuidModule } from "@/modules/uuid";
import {
  SpaceAccountNoteModelData,
  SpaceAccountNoteUpsertedSyncUpdateValue,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IMarkNoteViewedOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class MarkNoteViewedOperation extends BaseSyncOperation<IMarkNoteViewedOperation> {
  get operationKind(): SyncOperationKind {
    return "MARK_NOTE_VIEWED";
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const spaceAccountNoteId = uuidModule.resolveSpaceAccountNoteSyncModelUuid({
      spaceAccountId,
      noteId: this.payload.note_id,
    });
    const spaceAccountNoteObservable = this.store.spaceAccountNotes.get(spaceAccountNoteId);

    const value: SpaceAccountNoteUpsertedSyncUpdateValue = {
      model_id: spaceAccountNoteId,
      model_kind: "SPACE_ACCOUNT_NOTE",
      model_version: spaceAccountNoteObservable?.modelVersion ?? 0,
      model_data: {
        space_account_id: spaceAccountId,
        note_id: this.payload.note_id,
        last_viewed_at: this.committedAt,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: SyncUpdate<SpaceAccountNoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
