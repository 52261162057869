import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { GuestAppStore } from "@/store/GuestAppStore";
import { SpaceAccountContactSyncUpdate } from "@/store/contacts/types";
import { NoteSyncUpdate } from "@/store/note/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import { FetchValue } from "@/store/queries/types";
import { AppStoreBaseSyncStore } from "@/store/sync/AppStoreBaseSyncStore";
import { GuestSyncActionQueue } from "@/store/sync/GuestSyncActionQueue";
import {
  BootstrapGuestModeSyncUpdateResponse,
  GuestListSyncUpdatesResponse,
  SyncModelData,
  SyncUpdate,
} from "@/store/sync/types";

export class AppStoreGuestSyncStore extends AppStoreBaseSyncStore<
  GuestAppStore,
  GuestSyncActionQueue,
  GuestListSyncUpdatesResponse
> {
  get spaceId() {
    return this.store.guestAccount.myPersonalSpaceAccountId;
  }

  createSyncActionQueue(): GuestSyncActionQueue {
    return new GuestSyncActionQueue({
      getSpaceId: () => this.spaceId,
      api: this.api,
      pusher: this.pusher,
      store: this.store,
    });
  }

  get syncQuery() {
    const id = `sync-updates`;
    type QueryValue = GuestListSyncUpdatesResponse;
    const fetchValue: FetchValue<QueryValue> = async signal => {
      if (signal.aborted) return;
      console.debug("[SYNC][AppStoreGuestNoteStore] Querying for sync actions...");

      const response = await api.get(`/v2/sync/guest-mode/updates`, {
        params: { query: { last_sync_id: this.lastSyncId, space_id: this.spaceId } },
      });

      if (signal.aborted) return;
      if (response.error)
        throw new Error("[SYNC][AppStoreGuestNoteStore] syncQuery error: " + response.error.type);
      /**
       * @TODO - Once the API is updated to return the correct response,
       * we can remove the type assertion.
       */
      return { data: response.data as GuestListSyncUpdatesResponse };
    };

    const createQuery = () =>
      new QueryObservable<QueryValue>({
        auth: this.store.auth,
        queriesCache: this.store.queriesCache,
        id,
        refreshInterval: this.pollingInterval,
        fetchValue,
      });

    return this.store.queriesCache.get<QueryValue>(id, createQuery);
  }

  finishProcessingQueryResponse() {}

  processSyncUpdate(update: SyncUpdate<SyncModelData>): void {
    switch (update.value.model_kind) {
      case "CONTACT":
        this.store.contacts.processSyncUpdate(update as SpaceAccountContactSyncUpdate);
        break;
      case "NOTE":
        this.store.note.processSyncUpdate(update as NoteSyncUpdate);
        break;
    }
  }

  subscribe() {}
  unsubscribe() {}
  async hydrateFromStorage() {}
  async saveBootstrapEvents() {}

  async fetchBootstrapEvents() {
    let lastSyncId: string | undefined = undefined;
    let nextPageToken: string | undefined = undefined;
    const allEvents: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        const response: BootstrapGuestModeSyncUpdateResponse = await api.get(
          `/v2/sync/guest-mode/updates/bootstrap`,
          {
            params: {
              query: {
                space_id: this.spaceId,
                bootstrap_session_cursor: nextPageToken,
              },
            },
          }
        );
        const actions = response.data?.results || [];
        allEvents.push(...(actions as SyncUpdate<SyncModelData>[]));
        nextPageToken = response.data?.bootstrap_session_cursor || undefined;
        lastSyncId = response.data?.bootstrap_session_final_sync_id || undefined;
      } catch (e) {
        logger.warn({
          message: "[SYNC][AppStoreSyncStore] Error bootstrapping",
          info: objectModule.safeAsJson({ e }),
        });
        lastSyncId = undefined;
        break;
      }
    } while (nextPageToken);

    return { lastSyncId, allEvents };
  }
}
