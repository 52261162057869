import { observer } from "mobx-react-lite";
import { MdsModal } from "@/design-system/components/modal";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { MakeCollectionPrivateShareSheetContent } from "@/components/modal/share-sheet/MakeCollectionPrivateShareSheetContent";
import { ShareSheetModalContent } from "@/components/modal/share-sheet/ShareSheetModalContent";

export interface ShareSheetModalProps {
  store: ShareSheetModalStore;
}

export const ShareSheetModal = observer<ShareSheetModalProps>(function ShareSheetModal({ store }) {
  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={!!store.modal?.isOpen}
      handleCloseModal={() => store.modal?.close()}
    >
      {store?.makeCollectionPrivateModal.isOpen ? (
        <MakeCollectionPrivateShareSheetContent store={store.makeCollectionPrivateModal} />
      ) : (
        <ShareSheetModalContent store={store} />
      )}
    </MdsModal>
  );
});
