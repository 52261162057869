import { BaseError } from "@/domains/errors";
import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { toastModule } from "@/modules/toast";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, makeObservable, observable, computed } from "mobx";

export class SettingsAccountManagementPageStore extends AppSubStore {
  public isDeletingAccount = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    makeObservable(this, {
      isDeletingAccount: observable,
      hasActiveSubscription: computed,
      currentAccountEmail: computed,
      handleDeleteAccount: action,
    });
  }

  get hasActiveSubscription() {
    return this.store.platformInfo.accountState === "ACTIVE";
  }

  get currentAccountEmail() {
    return this.store.account.myAccount.profileEmailAddress;
  }

  handleDeleteAccount = async () => {
    const accountId = this.store.account.myAccountId;
    try {
      this.isDeletingAccount = true;
      const response = await api.delete("/v1/accounts/{id}/", {
        params: { path: { id: accountId } },
      });
      if (response.data) {
        this.store.navigation.goToLogOut();
      }
      if (response.error) {
        const err = new BaseError({
          message: "Failed to delete account.",
          info: { result: objectModule.safeAsJson(response) },
        });
        throw err;
      }
    } catch (unknownErr: unknown) {
      const error = unknownErr as BaseError;
      toastModule.triggerToast({
        content: "Failed to delete account. Please try again or contact support.",
      });
      logger.error({
        message: "[SettingsAccountManagementPageStore][handleDeleteAccount]: Error.",
        info: { error: objectModule.safeAsJson({ ...error }) },
      });
    } finally {
      this.isDeletingAccount = false;
    }
  };
}
