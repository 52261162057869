import { observer } from "mobx-react-lite";
import { FC } from "react";

import { TopBarContent } from "@/components/layout/components/TopBarContent";
import { useGuestAppStore, usePublicAppStore } from "@/store";

export interface GuestModeTopBarProps {}

export const GuestModeTopBar: FC<GuestModeTopBarProps> = observer(() => {
  const { publicStore } = usePublicAppStore();
  const { guestStore } = useGuestAppStore();

  return (
    <TopBarContent
      screenSize={publicStore.interface.screenSizeState}
      isTouchDevice={publicStore.interface.isTouchDevice}
      isChatPath={false}
      isCollectionPath={false}
      isNotesPath={false}
      isSearchPath={false}
      isLoading={guestStore.sync.actionQueue.isLoading}
      hoverGroup={undefined}
      renderRecentNotes={() => <div />}
      renderRecentCollections={() => <div />}
      renderFavorites={() => <div />}
      handleToggleSidebar={publicStore.interface.toggleSidebar}
      handleClickCompose={guestStore.interface.openSignUpUi}
      handleClickNotes={guestStore.interface.openSignUpUi}
      handleClickCollections={guestStore.interface.openSignUpUi}
      handleClickChat={guestStore.interface.openSignUpUi}
      handleClickIntegrations={guestStore.interface.openSignUpUi}
      handleClickSearch={guestStore.interface.openSignUpUi}
      isGuestMode={true}
      shouldShowTrialInfoBadge={false}
    />
  );
});
