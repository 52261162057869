import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { TrialInfoBadgeContent } from "@/components/trial/badge/TrialInfoBadgeContent";
import { useAppStore } from "@/store";

interface TrialInfoBadgeContentProps extends EmotionClassStyles {}

export const TrialInfoBadgeWrapper: FC<TrialInfoBadgeContentProps> = observer(() => {
  const { store } = useAppStore();

  const trialInfo = store.platformInfo.trialInfo;

  const subscribeNowHandler = useCallback(() => {
    store.platformInfo.openSubscribeNowInterstitial();
  }, [store.platformInfo]);

  return (
    <TrialInfoBadgeContent
      subscribeNowHandler={subscribeNowHandler}
      trialEnd={trialInfo.trialEnd}
      trialState={trialInfo.trialState}
    />
  );
});
