import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsSeparatorKind } from "@/design-system/components/separator/types";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { FC, memo } from "react";

interface MdsSeparatorProps extends EmotionClassStyles {
  kind: MdsSeparatorKind;
  flexGrow?: number;
  iconClassName?: string;
}

const mdsSeparatorKindIconKindMapping: Record<MdsSeparatorKind, MdsIconKind> = {
  [MdsSeparatorKind.Dot]: MdsIconKind.Dot,
  [MdsSeparatorKind.Pipe]: MdsIconKind.Pipe,
};

const mdsSeparatorKindInnerStylesMapping: Record<MdsSeparatorKind, string> = {
  [MdsSeparatorKind.Dot]: css({
    fontSize: "0.3em",
  }),
  [MdsSeparatorKind.Pipe]: css({
    marginTop: "-0.15em",
    fontSize: "0.9em",
  }),
};

export const MdsSeparator: FC<MdsSeparatorProps> = memo(function MdsSeparator({
  kind,
  className,
  iconClassName,
}) {
  const mdsIconKind = mdsSeparatorKindIconKindMapping[kind];
  const iconInnerStyles = mdsSeparatorKindInnerStylesMapping[kind];
  const combinedInnerStyles = cx(baseInnerStyles, iconInnerStyles, iconClassName);

  return (
    <MdsIcon
      className={cx(wrapperStyles, className)}
      innerStyles={{ Icon: { className: combinedInnerStyles } }}
      kind={mdsIconKind}
    />
  );
});

const wrapperStyles = css({
  height: "100%",
  display: "inline",
  paddingLeft: "6px",
  paddingRight: "6px",
});

const baseInnerStyles = css({
  verticalAlign: "middle",
});
