import localDb from "@/domains/local-db";
import { api } from "@/modules/api";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import { NoteContentDocumentModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  HydrationSyncUpdateResponse,
  PreloadingState,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { isClientUpgradeError } from "@/store/utils/errors";
import { makeObservable, runInAction, action, observable } from "mobx";

const NOTE_CONTENT_PRELOADING_DISABLED_KEY = "note_content_preloading_disabled";

export class AppStoreNoteContentDocumentStore extends BaseSyncModelStore<
  NoteContentDocumentObservable,
  NoteContentDocumentModelData
> {
  public isPreloadingDisabled: boolean = false;
  public preloadingState: PreloadingState = PreloadingState.NotStarted;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.NoteContentDocument, ...injectedDeps });
    makeObservable(this, {
      isPreloadingDisabled: observable,
      preloadingState: observable,
      createSyncModel: false,
      fetch: action,
      initializePreloadingState: action,
      setIsPreloadingDisabled: action,
      togglePreloading: action,
      preloadAll: action,
    });
    this.initializePreloadingState();
  }

  createSyncModel(
    updateValue: SyncUpdateValue<NoteContentDocumentModelData>
  ): NoteContentDocumentObservable {
    return new NoteContentDocumentObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  async fetch(id: string): Promise<NoteContentDocumentObservable | undefined> {
    let hasError = false;
    let nextPageToken: string | undefined = undefined;
    const updates: SyncUpdate<SyncModelData>[] = [];
    do {
      try {
        const response = await api.get(`/v2/sync/updates/hydrate`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              sync_model_kind: SyncModelKind.NoteContentDocument,
              sync_model_ids: [id],
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) {
            this.store.sync.handleClientUpgradeError();
          }
          hasError = true;
          break;
        }

        const actions = response.data?.results || [];
        updates.push(...actions);
        nextPageToken = response.data?.hydration_session_cursor || undefined;
      } catch (e) {
        hasError = true;
        break;
      }
    } while (nextPageToken);

    if (hasError) return; // Returning undefined should be treated as an error

    runInAction(() => {
      for (const update of updates) {
        this.processSyncUpdate(update);
        localDb.syncUpdates.save(update.sync_id, update);
      }
    });

    return this.get(id);
  }

  public async initializePreloadingState() {
    const storedValue = await localStorage.getItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY);
    this.isPreloadingDisabled = storedValue === "true";
  }

  public setIsPreloadingDisabled(value: boolean) {
    this.isPreloadingDisabled = value;
    localStorage.setItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY, value.toString());
  }

  public togglePreloading() {
    this.setIsPreloadingDisabled(!this.isPreloadingDisabled);
  }

  async preloadAll() {
    if (this.isPreloadingDisabled) return;

    const noteContentPreloadingState = await localDb.sync.getNoteContentPreloadingState();
    if (noteContentPreloadingState === PreloadingState.Complete) return;

    if (
      this.preloadingState === PreloadingState.InProgress ||
      this.preloadingState === PreloadingState.Complete
    )
      return;

    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents...");
    runInAction(() => (this.preloadingState = PreloadingState.InProgress));

    let hasError = false;
    let nextPageToken: string | undefined = undefined;
    const updates: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        const response: HydrationSyncUpdateResponse = await api.get(`/v2/sync/updates/hydrate`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              bootstrap_session_cursor: nextPageToken,
              only_sync_models_kinds: SyncModelKind.NoteContentDocument,
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) this.store.sync.handleClientUpgradeError();
          hasError = true;
          break;
        }

        const actions = response.data?.results || [];
        updates.push(...actions);
        nextPageToken = response.data?.hydration_session_cursor || undefined;
      } catch (e) {
        hasError = true;
        break;
      }
    } while (nextPageToken);

    if (hasError) {
      runInAction(() => (this.preloadingState = PreloadingState.Failed));
      return;
    }

    runInAction(() => {
      for (const update of updates) this.processSyncUpdate(update);
      localDb.syncUpdates.saveMany(updates);
      this.preloadingState = PreloadingState.Complete;
    });

    localDb.sync.setNoteContentPreloadingState(PreloadingState.Complete);
    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloaded");
  }
}
