import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { mdsLineSpacings } from "@/design-system/foundations/typography";
import { css } from "@/domains/emotion";
import { APP_CONFIG } from "@/domains/global/config";
import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";
import React from "react";

const containerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "24px",
  width: "min(530px, 90vw)",
});

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "flex-end",
  marginTop: "24px",
});

export interface SyncErrorOverlayContentProps {
  isModalOpen: boolean;
  title?: React.ReactNode;
  message?: React.ReactNode;
  resetActionLabel?: string | null;
  extraActionButtons?: React.ReactNode;
  includeContactSupportButton?: boolean;
  lastSyncActionTime?: string;
  handleResetClick: () => void;
}

export const SyncErrorOverlayContent = observer(function SyncErrorOverlayContent({
  isModalOpen,
  title,
  message,
  resetActionLabel,
  extraActionButtons,
  includeContactSupportButton = true,
  lastSyncActionTime = "",
  handleResetClick: handleClick,
}: SyncErrorOverlayContentProps) {
  return (
    <MdsModal isModalOpen={isModalOpen} handleCloseModal={noop} showOverflow={true}>
      <div className={containerStyles}>
        {title && (
          <MdsText
            size={MdsTextSize.Large}
            weight={MdsTextWeight.Bold}
            color={mdsColors().grey.x600}
            lineHeight={MdsTextSize.Large}
            className={css({
              letterSpacing: mdsLineSpacings().mediumLarge,
            })}
          >
            {title}
          </MdsText>
        )}
        <div className={contentStyles}>
          <MdsText
            color={mdsColors().grey.x600}
            lineHeight={MdsTextSize.Small}
            size={MdsTextSize.Small}
          >
            {message ??
              "Mem hasn't been able to sync your recent activity. In order to prevent future data loss, please reset syncing."}
          </MdsText>
          {lastSyncActionTime && (
            <MdsText
              color={mdsColors().grey.x500}
              lineHeight={MdsTextSize.Small}
              size={MdsTextSize.Small}
            >
              {`By resetting syncing, changes made since ${lastSyncActionTime} will be undone.`}
            </MdsText>
          )}
        </div>
        <div className={buttonSectionStyles}>
          {includeContactSupportButton && (
            <MdsButton
              variant={MdsButtonVariant.Outlined}
              label="Contact Support"
              onClick={() => window.open(APP_CONFIG.URLS.HELP_AND_SUPPORT, "_blank")}
            />
          )}
          {extraActionButtons}
          {resetActionLabel !== null && (
            <MdsButton
              variant={MdsButtonVariant.FilledDark}
              label={resetActionLabel ?? "Reset Syncing"}
              onClick={handleClick}
            />
          )}
        </div>
      </div>
    </MdsModal>
  );
});
