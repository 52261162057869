import { observer } from "mobx-react-lite";
import { FC } from "react";
import { AppRouter } from "@/app/router/AppRouter";
import { useInitializePublicAppStore } from "@/store";
import { PublicAppStoreContext } from "@/store/utils/context";
import { GlobalToastContainer } from "@/components/toast";
import { MdsAppStyleWrapper, MdsGlobalProvider } from "@/components/styling";
import { ThemeProvider } from "@emotion/react";
import { mdsThemeLight } from "@/mds";

interface AppCoreProps {}

export const AppCore: FC<AppCoreProps> = observer(() => {
  const state = useInitializePublicAppStore();

  if (state.loading) {
    /**
     * Loading.
     * @todo - Introduce some form of loading-interstitial here.
     */
    return <div />;
  }

  if (state.error || !state.data) {
    return <div>Something went wrong.</div>;
  }

  return (
    <PublicAppStoreContext.Provider value={state.data}>
      <MdsGlobalProvider>
        <MdsAppStyleWrapper>
          <ThemeProvider theme={mdsThemeLight}>
            <AppRouter />
            <GlobalToastContainer />
          </ThemeProvider>
        </MdsAppStyleWrapper>
      </MdsGlobalProvider>
    </PublicAppStoreContext.Provider>
  );
});
