import { moduleFactory } from "@/factories/module";
import { _extractArrayFromObject } from "@/modules/object/extractors/extractArray";
import { _extractNumberFromObject } from "@/modules/object/extractors/extractNumber";
import { _extractNumberArrayFromObject } from "@/modules/object/extractors/extractNumberArray";
import { _extractStringFromObject } from "@/modules/object/extractors/extractString";
import { _extractStringArrayFromObject } from "@/modules/object/extractors/extractStringArray";
import { _extractValueFromObject } from "@/modules/object/extractors/extractValue";
import { _parseObjectFromJson } from "@/modules/object/parsers/fromJson";
import { _maybeTransformObjectToJson, _transformObjectToJson } from "@/modules/object/toJson";
import {
  _safeTransformObjectToJsonFriendlyFormat,
  _transformObjectToJsonFriendlyFormat,
} from "@/modules/object/transformers/asJson";
import {
  _compactObjectNullOrUndefined,
  _compactObjectUndefined,
} from "@/modules/object/transformers/compact";
import {
  _safeTransformErrorAsJson,
  _safeTransformErrorToJson,
  _safeTransformObjectToJson,
} from "@/modules/object/transformers/safeToJson";

export const objectModule = moduleFactory.create({
  asJson: _transformObjectToJsonFriendlyFormat,
  safeAsJson: _safeTransformObjectToJsonFriendlyFormat,
  toJson: _transformObjectToJson,
  maybeToJson: _maybeTransformObjectToJson,
  fromJson: _parseObjectFromJson,
  compactUndefined: _compactObjectUndefined,
  compactNullOrUndefined: _compactObjectNullOrUndefined,
  safeToJson: _safeTransformObjectToJson,
  safeErrorToJson: _safeTransformErrorToJson,
  safeErrorAsJson: _safeTransformErrorAsJson,
  extractValue: _extractValueFromObject,
  extractNumber: _extractNumberFromObject,
  extractArray: _extractArrayFromObject,
  extractNumberArray: _extractNumberArrayFromObject,
  extractString: _extractStringFromObject,
  extractStringArray: _extractStringArrayFromObject,
});
