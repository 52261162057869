import { FC } from "react";
import { OnboardingRouter } from "@/components/onboarding/OnboardingRouter";
import { InterstitialOverlay } from "@/components/overlay";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { css } from "@/domains/emotion";

export const OnboardingOverlay: FC = observer(() => {
  const { store } = useAppStore();

  return (
    <InterstitialOverlay isOpen={!store.platformInfo.onboardingInfo.onboardingIsCompleted}>
      <div className={container}>
        <OnboardingRouter />
      </div>
    </InterstitialOverlay>
  );
});

const container = css({
  position: "relative",
  zIndex: 1,
  padding: "40px",
  borderRadius: "24px",
  border: "1px solid #F3F3F5",
  background: "white",
  boxShadow: "0px 11px 24px -8px rgba(69, 78, 104, 0.20)",
  maxWidth: "100%",
  maxHeight: "100dvh",
  overflowY: "auto",
  "@media (max-width: 425px)": {
    padding: "32px 16px",
  },
});
