import { MdsSkeleton } from "@/design-system/components/loader/MdsSkeleton";
import { css } from "@/domains/emotion";

export const MdsSkeletonItemList = () => (
  <div className={skeletonStyles}>
    <MdsSkeleton height={60} />
    <MdsSkeleton height={100} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={250} />
    <MdsSkeleton height={150} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={30} />
    <MdsSkeleton height={250} />
    <MdsSkeleton height={60} />
    <MdsSkeleton height={100} />
    <MdsSkeleton height={100} />
  </div>
);

const skeletonStyles = css({
  margin: `40px 100px`,
  overflowY: "hidden",
  "> span": {
    display: "block",
    margin: `20px 0`,
  },
});
