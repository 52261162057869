import React from "react";
import { observer } from "mobx-react-lite";
import {
  GridOption,
  GridSelectionForm,
} from "@/components/onboarding/components/GridSelectionForm";
import { shuffle } from "lodash-es";
import { useAppStore } from "@/store";

export const SelectUseCasesStage = observer(() => {
  const { store } = useAppStore();

  const handleSubmit = async ({ selectedOptions }: { selectedOptions: GridOption[] }) => {
    const desiredUseCases = selectedOptions.map(({ id }) => id);
    store.platformInfo.onboardingInfo.setDesiredUseCases({ desiredUseCases });
    await store.platformInfo.onboardingInfo.saveAndContinue();
  };

  return (
    <GridSelectionForm
      prompt="What can I help you do first?"
      sub="Pick one to get started"
      multiselect={false}
      shuffle={true}
      options={useCases}
      onSubmit={handleSubmit}
    />
  );
});

const useCases = shuffle([
  {
    id: "improve-meeting-note-organization",
    label: "Improve meeting note organization",
  },
  {
    id: "capture-and-categorize-internet-content",
    label: "Capture and categorize internet content",
  },
  {
    id: "enhance-voice-note-organization",
    label: "Enhance voice note organization",
  },
  {
    id: "capture-fleeting-thoughts",
    label: "Capture fleeting thoughts",
  },
  {
    id: "clean-up-and-format-notes",
    label: "Clean up and format notes",
  },
  {
    id: "consolidate-notes-effectively",
    label: "Consolidate notes effectively",
  },
  {
    id: "categorize-notes-logically",
    label: "Categorize notes logically",
  },
  {
    id: "access-recent-notes-quickly",
    label: "Access recent notes quickly",
  },
  {
    id: "follow-through-on-unfinished-notes",
    label: "Follow through on unfinished notes",
  },
  {
    id: "track-open-action-items",
    label: "Track open action items",
  },
  {
    id: "find-specific-information-in-notes",
    label: "Find specific information in notes",
  },
  {
    id: "gather-comprehensive-topic-notes",
    label: "Gather comprehensive topic notes",
  },
  {
    id: "browse-notes-efficiently",
    label: "Browse notes efficiently",
  },
  {
    id: "draft-documents-from-notes",
    label: "Draft documents from notes",
  },
  {
    id: "synthesize-knowledge-coherently",
    label: "Synthesize knowledge coherently",
  },
  {
    id: "apply-note-insights-in-problem-solving",
    label: "Apply note insights in problem-solving",
  },
]).slice(0, 6);
