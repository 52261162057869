import { Uuid } from "@/domains/global/identifiers";
import { _parseUuidFromByteBuffer } from "@/modules/uuid/buffer/fromByteBuffer";
import { _convertUuidToByteBuffer } from "@/modules/uuid/buffer/toByteBuffer";
import { zip } from "lodash-es";

/**
 * Implementation inspired by the `v4` implementation from the uuid module.
 * - https://github.com/uuidjs/uuid/blob/main/src/v4.js
 */
export const _combineUuids = (...uuids: Uuid[]) => {
  const byteBuffers = uuids.map(uuid => {
    return _convertUuidToByteBuffer(uuid);
  });

  const zippedByteBuffers = zip(...byteBuffers);

  const xorBytes = zippedByteBuffers.map(byteBuffers => {
    /**
     * Assuming both uuids are valid, they will have the same number of bytes.
     */
    const compactedBytes = byteBuffers as number[];

    const combinedBytes = compactedBytes.reduce((acc, byte) => {
      return acc ^ byte;
    }, 0);

    return combinedBytes;
  });

  const xorNormalizedBytes = xorBytes.map((byte, byteIndex) => {
    /**
     * The 7th hex char represents the "version" field.
     */
    if (byteIndex === 6) {
      return (byte ^ 0x0f) | 0x40;
    }

    /**
     * The 9th hex char represents the "variant" field.
     * It needs to be: { 8, 9, a, b }.
     * 4 represents the "clock_seq_hi_and_reserved" variant.
     */
    if (byteIndex === 8) {
      return (byte ^ 0x3f) | 0x80;
    }

    return byte;
  }, 0);

  const xorBuffer = Uint8Array.from(xorNormalizedBytes);

  const combinedUuid = _parseUuidFromByteBuffer(xorBuffer);

  return combinedUuid;
};
