import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { forceNetworkCheck, getIsOnline, getWhenOnline } from "@/domains/network/status";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store/GuestAppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  SyncError,
  SyncErrorDisplayType,
  SyncErrorHandlingType,
} from "@/store/sync/operations/errors/SyncError";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncOperationKind,
  SyncModelKind,
  SyncUpdate,
  SyncModelData,
  QueueProcessingState,
  SyncCustomErrorData,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { DateTime } from "luxon";
import { ObservableMap, action, computed, makeObservable, observable, runInAction } from "mobx";
import pRetry from "p-retry";

const SPECIAL_OPTIMISTIC_OPERATION_KINDS = [
  "ADD_NOTE_TO_COLLECTION",
  "REMOVE_NOTE_FROM_COLLECTION",
  "MARK_NOTE_VIEWED",
];
const MAXIMUM_RETRY_TIMEOUT = import.meta.env.VITE_MAXIMUM_SYNC_ENGINE_RETRY_TIMEOUT ?? 5 * 1000; // 5 seconds

export abstract class BaseSyncActionQueue<
  Store extends AppStore | GuestAppStore,
> extends AppSubStore<Store> {
  public processing: SyncOperationGeneric[] = [];
  public pending: SyncOperationGeneric[] = [];

  public operationsByOperationKind: ObservableMap<SyncOperationKind, SyncOperationGeneric[]> =
    new ObservableMap();
  public operationsByModelId: ObservableMap<Uuid, SyncOperationGeneric[]> = new ObservableMap();

  public optimisticUpdatesBySyncOperationId: ObservableMap<Uuid, OptimisticSyncUpdate[]> =
    new ObservableMap();
  public optimisticUpdatesByModelId: ObservableMap<Uuid, OptimisticSyncUpdate[]> =
    new ObservableMap();
  public optimisticUpdatesByModelKind: ObservableMap<SyncModelKind, OptimisticSyncUpdate[]> =
    new ObservableMap();

  public lastProcessingItemStart?: Date;
  public lastProcessingItemStop?: Date;
  public lastSentOperation: SyncOperationGeneric | undefined;
  public processingError?: SyncError;
  public isFailing = false;

  public processingState: QueueProcessingState = QueueProcessingState.NotReady;

  protected getSpaceId: () => string;

  abstract get syncErrorModalFields(): Maybe<SyncErrorModalFields>;
  abstract saveSyncOperation(syncOperation: SyncOperationGeneric): void;
  abstract saveAcknowledgedSyncOperation(acknowledgedSyncOperation: SyncOperationGeneric): void;
  abstract removeSyncOperation(id: string): void;
  abstract processOperation(operation: SyncOperationGeneric): Promise<Maybe<SyncOperationGeneric>>;
  abstract loadAllItems(): Promise<{
    acknowledgedSyncOperations: SyncOperationGeneric[];
    syncOperations: SyncOperationGeneric[];
    optimisticUpdates: OptimisticSyncUpdate[];
  }>;

  constructor({
    getSpaceId,
    ...injectedDeps
  }: { getSpaceId: () => string } & AppSubStoreArgs<Store>) {
    super(injectedDeps);
    this.getSpaceId = getSpaceId;

    makeObservable<
      this,
      | "getSpaceId"
      | "getLoggableOperation"
      | "addToOperationsMap"
      | "removeFromOperationsMap"
      | "addToOptimisticUpdatesMap"
      | "removeFromOptimisticUpdatesMap"
      | "isOperationGone"
    >(this, {
      getSpaceId: false,

      syncErrorModalFields: false,
      saveSyncOperation: false,
      saveAcknowledgedSyncOperation: false,
      removeSyncOperation: false,
      processOperation: false,
      loadAllItems: false,
      getLoggableOperation: false,

      // PROCESSING + PENDING QUEUES
      processing: observable,
      pending: observable,
      push: action,
      process: action,
      addToProcessing: action,
      removeFromProcessing: action,
      addToPending: action,
      removeFromPending: action,
      start: action,

      // OPTIMISTIC UPDATES
      operationsByOperationKind: observable,
      operationsByModelId: observable,
      optimisticUpdatesByModelId: observable,
      optimisticUpdatesByModelKind: observable,
      optimisticUpdatesBySyncOperationId: observable,
      addToOperationsMap: action,
      removeFromOperationsMap: action,
      addToOptimisticUpdatesMap: action,
      removeFromOptimisticUpdatesMap: action,
      removeOptimisticUpdatesByModelId: action,
      removeOptimisticUpdatesByModelKind: action,
      applyOptimisticUpdate: action,
      removeOptimisticUpdates: action,
      saveOptimisticOperation: action,
      removeOptimisticOperation: action,
      confirmSyncUpdatesUntil: action,

      // ERROR HANDLING
      lastSentOperation: observable,
      lastProcessedAt: computed,
      processingError: observable,
      skipAndRevertOperation: action,
      skipAndRevertOperationById: action,
      skipAndRevertRelatedOperations: action,
      skipAndRevertRelatedOperationsById: action,
      skipAndRevertUnsyncedOperationsForModelId: action,
      handleCustomError: action,
      handleSyncError: action,
      isOperationGone: false,

      // QUEUE STATUS
      lastProcessingItemStart: observable,
      lastProcessingItemStop: observable,
      isFailing: observable,
      processingState: observable,
      isLoading: computed,
      didFail: action,
      setState: action,
      pause: action,
      resume: action,

      // HYDRATION AND INITIALIZATION
      hydrateFromStorage: action,
      reset: action,
    });
  }

  public push(syncOperation: SyncOperationGeneric) {
    this.addToProcessing(syncOperation);
    this.saveOptimisticOperation(syncOperation);
    if (this.processing.length > 0) this.process();
  }

  addToProcessing(syncOperation: SyncOperationGeneric) {
    this.processing.push(syncOperation);
    this.saveSyncOperation(syncOperation);
  }

  removeFromProcessing(predicate: (e: SyncOperationGeneric) => boolean) {
    const removed: SyncOperationGeneric[] = [];
    const processing = this.processing.filter(e => {
      if (predicate(e)) {
        removed.push(e);
        localDb.queue.removeSyncOperation(e.id);
        return false;
      }
      return true;
    });
    if (removed.length > 0) this.processing = processing;
  }

  addToPending(acknowledgedSyncOperation: SyncOperationGeneric) {
    this.pending.push(acknowledgedSyncOperation);
    this.saveAcknowledgedSyncOperation(acknowledgedSyncOperation);
  }

  removeFromPending(predicate: (e: SyncOperationGeneric) => boolean) {
    const removed: SyncOperationGeneric[] = [];
    const pending = this.pending.filter(e => {
      if (predicate(e)) {
        removed.push(e);
        this.removeSyncOperation(e.id);
        return false;
      }
      return true;
    });
    if (removed.length > 0) this.pending = pending;
  }

  public start() {
    logger.debug({ message: "[Queue] Start" });
    this.setState(QueueProcessingState.Ready);
    this.process();
  }

  private getLoggableOperation(operation?: SyncOperationGeneric) {
    const { ...o } = { ...operation } as unknown as Record<string, unknown>;
    delete o.store;
    o.operationKind = operation?.operationKind || "<missing>";
    return objectModule.safeAsJson(o);
  }

  public async process() {
    if (this.processingState === QueueProcessingState.NotReady) {
      logger.debug({ message: "[Queue] Not ready" });
      return;
    }

    if (
      [QueueProcessingState.Processing, QueueProcessingState.Paused].includes(this.processingState)
    )
      return;

    logger.debug({ message: "[Queue] Process" });
    this.setState(QueueProcessingState.Processing);

    try {
      while (this.processing.length > 0 && this.processingState !== QueueProcessingState.Paused) {
        const operation = this.processing[0];

        const deadline = DateTime.now().plus({ seconds: 30 });

        const acknowledgedOperation = await pRetry<SyncOperationGeneric | SyncError | undefined>(
          async () => {
            if (!this.processing.length || this.processingState === QueueProcessingState.Paused)
              return;
            if (!getIsOnline()) await getWhenOnline();

            runInAction(() => {
              this.lastProcessingItemStart = new Date();
            });
            logger.debug({ message: `[Queue] Processing ${operation.id}` });
            return this.processOperation(operation);
          },
          {
            forever: true,
            maxTimeout: MAXIMUM_RETRY_TIMEOUT,
            randomize: true,
            onFailedAttempt: error => {
              logger.error({
                message: "[Queue] Error syncing operation",
                info: {
                  operation: this.getLoggableOperation(this.processing[0]),
                  error: objectModule.safeErrorAsJson(error),
                },
              });
              forceNetworkCheck();
            },
            shouldRetry: error => {
              if (!(error instanceof SyncError)) {
                this.handleCustomError(operation, { kind: "UNKNOWN" });
                return false;
              }

              if (error.handlingType === SyncErrorHandlingType.RetryWithLimit) {
                return deadline.diffNow().milliseconds > 0;
              }
              if (error.handlingType === SyncErrorHandlingType.RetryForever) {
                // TRANSIENT errors are retried indefinitely but should display a toast eventually.
                // Display toast on the 5th, 15th, 25th, etc error.
                if (
                  error.attemptNumber % 10 === 5 &&
                  error.displayType === SyncErrorDisplayType.Toast
                ) {
                  // Replace a previous toast for the same operation.
                  toastModule.triggerToast({
                    content: error.toastMessage ?? error.message,
                    toastId: error.operationId,
                  });
                }
                return true;
              }

              return false;
            },
          }
        )
          .catch(error => {
            return error;
          })
          .finally(() => {
            runInAction(() => {
              this.lastProcessingItemStop = new Date();
            });
          });

        // If this returns undefined, we keep the operation in the processing queue and retry indefinitely
        if (acknowledgedOperation === undefined) {
          continue;
        }

        // If there is a sync error, we handle it a bit differently
        if (acknowledgedOperation instanceof SyncError) {
          this.handleSyncError(operation, acknowledgedOperation);
          continue;
        }

        // If the action is acknowledged, we need to move it to the pending queue and move on to the next operation
        if (acknowledgedOperation instanceof BaseSyncOperationGeneric) {
          logger.debug({
            message: "[SYNC][SyncActionQueue] acknowledgedOperation",
            info: { acknowledgedOperation: this.getLoggableOperation(acknowledgedOperation) },
          });
          runInAction(() => {
            this.removeFromProcessing(e => e.id === operation.id);
            this.addToPending(acknowledgedOperation);
            this.isFailing = false;
            this.lastSentOperation = acknowledgedOperation;
          });
          if (
            this.store.sync.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId <=
              this.store.sync.latestSpaceAccountSequenceId
          ) {
            this.confirmSyncUpdatesUntil(this.store.sync.latestSpaceAccountSequenceId);
          }
        }
      }
    } finally {
      if (this.processingState === QueueProcessingState.Processing) {
        logger.debug({ message: "[Queue] Done" });
        this.setState(QueueProcessingState.Ready);
      }
    }
  }

  // OPTIMISTIC UPDATES
  private addToOperationsMap(
    map: ObservableMap<Uuid, SyncOperationGeneric[]>,
    key: Uuid,
    operation: SyncOperationGeneric
  ) {
    runInAction(() => {
      const existingOperations = map.get(key) || [];
      map.set(key, [...existingOperations, operation]);
    });
  }

  private removeFromOperationsMap(
    map: ObservableMap<Uuid, SyncOperationGeneric[]>,
    key: Uuid,
    operation: SyncOperationGeneric
  ) {
    runInAction(() => {
      const existingOperations = map.get(key) || [];
      const filteredOperations = existingOperations.filter(e => e.id !== operation.id);
      map.set(key, filteredOperations);
    });
  }

  private addToOptimisticUpdatesMap(
    map: ObservableMap<Uuid, OptimisticSyncUpdate[]>,
    key: Uuid,
    update: OptimisticSyncUpdate
  ) {
    runInAction(() => {
      const existingUpdates = map.get(key) || [];
      map.set(key, [...existingUpdates, update]);
    });
  }

  private removeFromOptimisticUpdatesMap(
    map: ObservableMap<Uuid, OptimisticSyncUpdate[]>,
    key: Uuid,
    syncOperationId: Uuid
  ) {
    runInAction(() => {
      const existingUpdates = map.get(key) || [];
      const filteredUpdates = existingUpdates.filter(e => e.syncOperationId !== syncOperationId);
      map.set(key, filteredUpdates);
    });
  }

  public applyOptimisticUpdate(
    syncOperationId: Uuid,
    update: SyncUpdate<SyncModelData>,
    options?: { hydrating: boolean }
  ) {
    const optimisticSyncUpdate = { ...update, syncOperationId: syncOperationId };
    logger.debug({
      message: "[SYNC][SyncActionQueue] applyOptimisticUpdate",
      info: { optimisticSyncUpdate: objectModule.safeAsJson(optimisticSyncUpdate) },
    });
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesBySyncOperationId,
      syncOperationId,
      optimisticSyncUpdate
    );
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesByModelId,
      update.value.model_id,
      optimisticSyncUpdate
    );
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesByModelKind,
      update.value.model_kind,
      optimisticSyncUpdate
    );
    if (!options?.hydrating) {
      localDb.queue.saveOptimisticUpdate(optimisticSyncUpdate);
    }
  }

  public removeOptimisticUpdatesByModelId(modelId: string) {
    const optimisticUpdates = this.optimisticUpdatesByModelId.get(modelId) || [];
    for (const optimisticUpdate of optimisticUpdates) {
      this.removeOptimisticUpdates(optimisticUpdate.syncOperationId);
    }
  }

  public removeOptimisticUpdatesByModelKind(modelKind: SyncModelKind) {
    const optimisticUpdates = this.optimisticUpdatesByModelKind.get(modelKind) || [];
    for (const optimisticUpdate of optimisticUpdates) {
      this.removeOptimisticUpdates(optimisticUpdate.syncOperationId);
    }
  }

  public removeOptimisticUpdates(syncOperationId: string) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeOptimisticUpdates",
      info: { syncOperationId },
    });
    const optimisticUpdates = this.optimisticUpdatesBySyncOperationId.get(syncOperationId) || [];
    for (const optimisticUpdate of optimisticUpdates) {
      this.removeFromOptimisticUpdatesMap(
        this.optimisticUpdatesBySyncOperationId,
        syncOperationId,
        syncOperationId
      );
      this.removeFromOptimisticUpdatesMap(
        this.optimisticUpdatesByModelId,
        optimisticUpdate.value.model_id,
        syncOperationId
      );
      this.removeFromOptimisticUpdatesMap(
        this.optimisticUpdatesByModelKind,
        optimisticUpdate.value.model_kind,
        syncOperationId
      );
      this.optimisticUpdatesBySyncOperationId.delete(syncOperationId);
      localDb.queue.removeOptimisticUpdate(optimisticUpdate.sync_id);
    }
  }

  public saveOptimisticOperation(syncOperation: SyncOperationGeneric) {
    this.addToOperationsMap(
      this.operationsByOperationKind,
      syncOperation.operationKind,
      syncOperation
    );
    if ("id" in syncOperation.payload) {
      this.addToOperationsMap(this.operationsByModelId, syncOperation.payload.id, syncOperation);
    }

    // Handle revert for collection-related operations
    if ("collection_id" in syncOperation.payload) {
      this.addToOperationsMap(
        this.operationsByModelId,
        syncOperation.payload.collection_id,
        syncOperation
      );
    }

    // Handle special case of adding/removing from a collection affecting ACL
    if (
      SPECIAL_OPTIMISTIC_OPERATION_KINDS.includes(syncOperation.operationKind) &&
      "note_id" in syncOperation.payload
    ) {
      this.addToOperationsMap(
        this.operationsByModelId,
        syncOperation.payload.note_id,
        syncOperation
      );
    }
  }

  public removeOptimisticOperation(syncOperation: SyncOperationGeneric) {
    this.removeFromOperationsMap(
      this.operationsByOperationKind,
      syncOperation.operationKind,
      syncOperation
    );

    if ("id" in syncOperation.payload) {
      this.removeFromOperationsMap(
        this.operationsByModelId,
        syncOperation.payload.id,
        syncOperation
      );
    }

    // Handle revert for collection-related operations
    if ("collection_id" in syncOperation.payload) {
      this.removeFromOperationsMap(
        this.operationsByModelId,
        syncOperation.payload.collection_id,
        syncOperation
      );
    }

    // Handle special case of adding/removing from a collection affecting ACL
    if (
      SPECIAL_OPTIMISTIC_OPERATION_KINDS.includes(syncOperation.operationKind) &&
      "note_id" in syncOperation.payload
    ) {
      this.removeFromOperationsMap(
        this.operationsByModelId,
        syncOperation.payload.note_id,
        syncOperation
      );
    }
  }

  public confirmSyncUpdatesUntil(latestSequenceId: number) {
    // Find all of the acknowledged operations on pending to remove
    logger.debug({
      message: "[SYNC][SyncActionQueue] confirmSyncUpdatesUntil ",
      info: { latestSequenceId },
    });
    const confirmedSyncOperations = new Set<SyncOperationGeneric>();
    this.removeFromPending(e => {
      if (e.latestSpaceAccountSequenceId && e.latestSpaceAccountSequenceId <= latestSequenceId) {
        confirmedSyncOperations.add(e);
        return true;
      }
      return false;
    });
    // Remove all optimistic updates
    for (const syncOperation of confirmedSyncOperations) {
      this.removeOptimisticOperation(syncOperation);
      this.removeOptimisticUpdates(syncOperation.id);
    }
  }

  public async hydrateFromStorage() {
    const { acknowledgedSyncOperations, syncOperations, optimisticUpdates } =
      await this.loadAllItems();

    runInAction(() => {
      this.processing = syncOperations;
      this.pending = acknowledgedSyncOperations;
    });
    for (const operation of this.processing) {
      this.saveOptimisticOperation(operation);
    }
    for (const operation of this.pending) {
      this.lastSentOperation = operation;
      this.saveOptimisticOperation(operation);
    }
    for (const update of optimisticUpdates) {
      this.applyOptimisticUpdate(update.syncOperationId, update, { hydrating: true });
    }
    console.debug("[SYNC][SyncActionQueue] Hydrated from storage:", {
      acknowledgedSyncOperations,
      syncOperations,
      optimisticUpdates,
    });
  }

  // ERROR HANDLING
  private isOperationGone(operation: SyncOperationGeneric) {
    return !this.processing.find(e => e.id === operation.id);
  }

  // Without resume to avoid UI flickering these are suitable for toasts only.

  skipAndRevertOperationById = (id: string): boolean => {
    const operation = this.processing.find(e => e.id === id);
    if (!operation) return false;

    return this.skipAndRevertOperation(operation);
  };

  skipAndRevertOperation = (operation: SyncOperationGeneric): boolean => {
    if (this.isOperationGone(operation)) return false;

    logger.debug({
      message: "[Queue] Reverting...",
      info: { operation: this.getLoggableOperation(operation) },
    });

    this.removeFromProcessing(e => e.id === operation.id);
    this.removeOptimisticOperation(operation);
    this.removeOptimisticUpdates(operation.id);
    return true;
  };

  skipAndRevertRelatedOperations = (operation: SyncOperationGeneric): boolean => {
    if (operation instanceof TrashNoteOperation) {
      const operations = this.operationsByModelId.get((operation as TrashNoteOperation).payload.id);
      const filtered = operations?.filter(
        e => ["TRASH_NOTE", "DELETE_NOTE"].includes(e.operationKind) && !e.acknowledged
      );
      filtered?.forEach(this.skipAndRevertOperation);
    }
    if (operation instanceof UpdateNoteContentUsingDiffOperation) {
      const operations = this.operationsByModelId.get(
        (operation as UpdateNoteContentUsingDiffOperation).payload.id
      );
      const filtered = operations?.filter(
        e =>
          ["CREATE_NOTE", "UPDATE_NOTE_CONTENT_USING_DIFF", "TRASH_NOTE", "DELETE_NOTE"].includes(
            e.operationKind
          ) && !e.acknowledged
      );
      filtered?.forEach(this.skipAndRevertOperation);
    }
    return this.skipAndRevertOperation(operation);
  };

  skipAndRevertUnsyncedOperationsForModelId = (id: string) => {
    const operations = this.operationsByModelId.get(id)?.filter(e => !e.acknowledged);
    if (operations?.length) {
      // May drop the first operation too even if it's running right now.
      const revertibleOperations = operations;
      // const isSyncing = this.isProcessing && !this.isPaused;
      // const revertibleOperations = isSyncing ? operations.slice(1) : operations;

      revertibleOperations.forEach(this.skipAndRevertOperation);
    }

    this.resume();
  };

  skipAndRevertRelatedOperationsById = (id: string) => {
    const operation = this.processing.find(e => e.id === id);
    if (operation) {
      this.skipAndRevertRelatedOperations(operation);
    }
    this.resume();
  };

  handleCustomError(operation: SyncOperationGeneric, errorData: SyncCustomErrorData): void {
    if (errorData.kind === "NOT_FOUND") return operation.handleNotFoundError(errorData);
    if (errorData.kind === "INVALID") return operation.handleInvalidError(errorData);
    if (errorData.kind === "PERMISSION_DENIED")
      return operation.handlePermissionDeniedError(errorData);
    if (errorData.kind === "TRANSIENT") return operation.handleTransientError(errorData);
    if (errorData.kind === "UNKNOWN") return operation.handleUnknownError(errorData);
  }

  // Handle SyncError after all retries are exhausted
  handleSyncError(operation: SyncOperationGeneric, error: SyncError): void {
    logger.debug({
      message: "[Queue] handleSyncError",
      info: {
        operation: this.getLoggableOperation(operation),
        error: objectModule.safeErrorAsJson(error),
      },
    });

    switch (error.handlingType) {
      case SyncErrorHandlingType.Revert: {
        this.skipAndRevertOperation(operation);
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        }
        break;
      }
      case SyncErrorHandlingType.RetryWithLimit:
      case SyncErrorHandlingType.RetryForever: {
        error.retryEndActionHandler?.();
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        } else if (error.displayType === SyncErrorDisplayType.None) {
          this.skipAndRevertOperation(operation);
        }
        break;
      }
      case SyncErrorHandlingType.Fail: {
        this.didFail(error);
        break;
      }
    }
  }

  didFail(syncError?: SyncError) {
    this.isFailing = true;
    if (syncError) this.processingError = syncError;
    this.pause();
  }

  setState = (state: QueueProcessingState) => {
    this.processingState = state;
  };

  // QUEUE STATUS
  get isLoading() {
    return this.processingState === QueueProcessingState.NotReady;
  }

  get lastProcessedAt(): Maybe<DateTime> {
    return this.lastProcessingItemStop
      ? DateTime.fromJSDate(this.lastProcessingItemStop)
      : undefined;
  }

  public pause() {
    logger.debug({ message: "[SYNC][SyncActionQueue] Pausing..." });
    this.setState(QueueProcessingState.Paused);
  }

  public resume = () => {
    logger.debug({
      message: "[SYNC][SyncActionQueue] Resuming...",
      info: objectModule.safeAsJson({
        state: this.processingState,
        processingError: this.processingError,
        isFailing: this.isFailing,
        lastProcessingItemStart: this.lastProcessingItemStart?.toISOString(),
        lastProcessingItemStop: this.lastProcessingItemStop?.toISOString(),
      }),
    });

    if (this.processingState !== QueueProcessingState.Paused) return;

    this.setState(QueueProcessingState.Ready);
    this.processingError = undefined;
    this.isFailing = false;
    this.process();
  };

  async reset() {
    logger.debug({ message: "[Queue] Reset" });
    await localDb.queue.clear();
    runInAction(() => {
      this.pending = [];
      this.processing = [];
      this.lastSentOperation = undefined;
      this.isFailing = false;
      this.processingError = undefined;
      this.lastProcessingItemStart = undefined;
      this.lastProcessingItemStop = undefined;
      this.setState(QueueProcessingState.NotReady);
    });
    this.resume();
  }
}
