import nodeCrypto from "crypto";

/**
 * UUID v4 generation is random.
 */
export const _generateRandomUuid = () => {
  /**
   * Implementation using .bind is inspired by:
   * - https://github.com/uuidjs/uuid/blob/aef75d70846976af9b37bd40fbdc4b9223583f6a/src/native-browser.js
   */
  if (typeof crypto !== "undefined" && crypto.randomUUID) {
    return crypto.randomUUID.bind(crypto)();
  }

  /**
   * Otherwise, we need to import it.
   */
  if (typeof nodeCrypto !== "undefined" && nodeCrypto.randomUUID) {
    return nodeCrypto.randomUUID.bind(nodeCrypto)();
  }

  throw new Error(
    "[_generateRandomUuid] Failed to generate uuid - `crypto` is not available in this environment."
  );
};
