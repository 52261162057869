import { isEmpty } from "lodash-es";
import { FC } from "react";

import { css } from "@/domains/emotion";
import { MdsCardKind, MdsCardRadius, MdsCard } from "@/design-system/components/card";
import { MdsCardBorder } from "@/design-system/components/card/types";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { MdsItemListRowRenderer } from "@/design-system/components/item-list";
import {
  MdsItemListRowData,
  MdsItemListKind,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";

export interface MdsItemListProps {
  rows: MdsItemListRowData[];
  kind?: MdsItemListKind;
  size?: MdsItemListSize;
  mustClickOnRowTitle?: boolean;
}

const cardStyles = css({
  borderRadius: mdsBorderRadius().large,
});

const wrapperStyles = css({
  /**
   * Remove the bottom border.
   */
  "& > div:last-child": {
    borderBottomStyle: "none",
  },
});

const itemListKindCardConfigMapping: {
  [key in MdsItemListKind]: {
    kind: MdsCardKind;
    border: MdsCardBorder;
    radius: MdsCardRadius;
  };
} = {
  [MdsItemListKind.Flat]: {
    kind: MdsCardKind.Flat,
    border: MdsCardBorder.Hidden,
    radius: MdsCardRadius.None,
  },
  [MdsItemListKind.Card]: {
    kind: MdsCardKind.Flat,
    border: MdsCardBorder.Hidden,
    radius: MdsCardRadius.Large,
  },
};

export const MdsItemList: FC<MdsItemListProps> = ({ rows, kind = MdsItemListKind.Flat }) => {
  const cardConfig = itemListKindCardConfigMapping[kind];

  return (
    <MdsCard className={cardStyles} {...cardConfig}>
      <div className={wrapperStyles}>
        {rows.map((row, i) => {
          if (isEmpty(rows)) return <div key="not-found">No items found.</div>;
          return <MdsItemListRowRenderer index={i} key={row.key} data={row} />;
        })}
      </div>
    </MdsCard>
  );
};
