import { observer } from "mobx-react-lite";

import { NoteObservableEditor } from "@/components/note/NoteObservableEditor";
import { INoteObservable } from "@/store/note/types";
import { NoteEditorMode } from "@/components/note/types";
import { Loader } from "@/components/messages/item/loader";
import { css, cx } from "@/domains/emotion";
import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";

export interface NoteEditorProps {
  className?: string;
  footer?: React.ReactNode;
  noteObservable: INoteObservable;
  goToMention?: AppStoreNavigationStore["goToMention"];
  showMetadata?: boolean;
  observeChanges?: boolean;
  highlightText?: string;
}

export const NoteViewer = observer(function NoteViewer({
  className,
  footer,
  noteObservable,
  goToMention,
  showMetadata,
  highlightText,
}: NoteEditorProps) {
  if (!noteObservable.isNoteContentDocumentLoaded) {
    return (
      <div className={cx(className, loaderStyles)}>
        <Loader />
      </div>
    );
  }

  return (
    <NoteObservableEditor
      className={className}
      footer={footer}
      noteObservable={noteObservable}
      goToMention={goToMention}
      showMetadata={showMetadata}
      showTopPadding={false}
      autoFocus={false}
      mode={NoteEditorMode.Static}
      highlightText={highlightText}
    />
  );
});

const loaderStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
