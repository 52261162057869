import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import {
  SpaceAccountCollectionModelData,
  SpaceAccountCollectionUpsertedSyncUpdateValue,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveNoteFromCollectionOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class RemoveNoteFromCollectionOperation extends BaseSyncOperation<IRemoveNoteFromCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "REMOVE_NOTE_FROM_COLLECTION";
  }

  get successToastMessage() {
    return this.triggerSuccessToast ? <>Removed from {this.mediumCollectionTitle}</> : null;
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const collection = this.store.collections.get(this.payload.collection_id);
    const spaceAccountCollection = collection?.spaceAccountCollection;
    if (!collection || !spaceAccountCollection) return [];

    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.note_id,
    });
    const collectionItemObservable = this.store.collectionItems.get(collectionItemId);

    const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
      model_id: collectionItemId,
      model_kind: "COLLECTION_ITEM",
      model_version: collectionItemObservable?.modelVersion ?? 0,
    };
    const collectionItemUpdate: SyncUpdate<CollectionItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: collectionItemValue,
    };

    // Collection
    const collectionValue: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.collection_id,
      model_kind: "COLLECTION",
      model_version: collection.modelVersion,
      model_data: {
        ...collection.modelData,
        last_removed_from_at: this.committedAt,
      },
      model_scopes: collection.modelScopes,
    };
    const collectionUpdate: SyncUpdate<CollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionValue,
    };

    // SpaceAccountCollection
    const spaceAccountCollectionValue: SpaceAccountCollectionUpsertedSyncUpdateValue = {
      model_id: spaceAccountCollection.id,
      model_kind: "SPACE_ACCOUNT_COLLECTION",
      model_version: spaceAccountCollection.modelVersion,
      model_data: {
        ...spaceAccountCollection.modelData,
        last_removed_from_at: this.committedAt,
      },
      model_scopes: spaceAccountCollection.modelScopes,
    };
    const spaceAccountCollectionUpdate: SyncUpdate<SpaceAccountCollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: spaceAccountCollectionValue,
    };

    return [collectionItemUpdate, collectionUpdate, spaceAccountCollectionUpdate];
  }

  get collectionTitle() {
    return (
      this.store.collections.get(this.payload.collection_id)?.title || UNTITLED_COLLECTION_TITLE
    );
  }

  get noteTitle() {
    return this.store.notes.get(this.payload.note_id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumCollectionTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.collectionTitle}
      </MdsText>
    );
  }

  get mediumNoteTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.noteTitle}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be removed from {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to remove {this.mediumNoteTitle} from{" "}
        {this.mediumCollectionTitle}. Please contact the collection owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be removed from {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
