import { uniqueId } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DragDropContext, DraggableLocation, DropResult, Droppable } from "@hello-pangea/dnd";

import { MdsDraggableListItem } from "@/design-system/components/draggable-list/types";
import { MdsDraggableItem } from "@/design-system/components/draggable-list/MdsDraggableItem";

export interface MdsDraggableListProps {
  items: MdsDraggableListItem[];
  reorderItems: (destination: DraggableLocation, source: DraggableLocation) => void;
}

export const MdsDraggableList = observer<MdsDraggableListProps>(function MdsDraggableList({
  items,
  reorderItems,
}) {
  const droppableId = useMemo(() => uniqueId("droppable"), []);

  const handleStarredDragEnd = useCallback(
    ({ destination, source }: DropResult) => {
      if (!source || !destination || source.index === destination.index) return;

      reorderItems(destination, source);
    },
    [reorderItems]
  );

  return (
    <DragDropContext onDragEnd={handleStarredDragEnd}>
      <Droppable droppableId={droppableId}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <MdsDraggableItem key={item.id} draggableIndex={index} item={item} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
