import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { clientEnvModule } from "@/modules/client-env";
import { GuestAppStore } from "@/store";
import { AppStore } from "@/store/AppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  GuestModeSupportedSyncOperationKind,
  ISyncOperation,
  ISyncOperationGuestMode,
} from "@/store/sync/operations/types";
import { SyncModelData, SyncUpdate } from "@/store/sync/types";

export interface BaseSyncOperationGuestModeParams<
  T extends ISyncOperation | ISyncOperationGuestMode = ISyncOperation | ISyncOperationGuestMode,
> {
  store: AppStore | GuestAppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number;
}

export abstract class BaseSyncOperationGuestMode<
  SyncOperation extends ISyncOperation,
  SyncOperationGuestMode extends ISyncOperationGuestMode,
> extends BaseSyncOperationGeneric<SyncOperation> {
  abstract get operationKind(): GuestModeSupportedSyncOperationKind;

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
  }: BaseSyncOperationGuestModeParams<SyncOperation>) {
    super({ store, payload, operationId, committedAt, latestSpaceAccountSequenceId });
  }

  public generateSyncOperation(): SyncOperation {
    const operation: SyncOperation = {
      id: this.operationId,
      client_id: clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperation;
    return operation;
  }

  public generateSyncOperationGuestMode(): SyncOperationGuestMode {
    const operation: SyncOperationGuestMode = {
      id: this.operationId,
      client_id: clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperationGuestMode;
    return operation;
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    return [];
  }

  public handleCustomError(_errorInfo: Record<string, unknown>) {}

  public execute(): void {
    const syncOp = this.generateSyncOperation();
    if (!syncOp) return;
    this.store.sync.actionQueue.push(this);
    const optimisticUpdates = this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      this.store.sync.actionQueue.applyOptimisticUpdate(syncOp.id, optimisticUpdate);
    }
  }
}
