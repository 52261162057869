import { mockResponseForPost } from "@/modules/api/globalApiClient";
import { keyboardModule } from "@/modules/keyboard";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";
import { CustomSyncErrorKind } from "@/store/sync/operations/errors/SyncError";
import { SyncOperationKind } from "@/store/sync/types";
import { PublicAppSubStoreArgs, PublicAppSubStore } from "@/store/types";
import { action, makeObservable, observable, reaction, runInAction } from "mobx";
import localforage from "localforage";

enum LocalStorageKey {
  DebugModeEnabled = "debugModeEnabled",
  DebugModeToolbarExpanded = "debugModeToolbarExpanded",
}

export class AppStoreDebugStore extends PublicAppSubStore {
  debugModeEnabled = false;
  debugModeToolbarExpanded = false;
  shortcutListener: { unregisterShortcut: () => void } | null = null;
  operationsSubmitApiMockErrorKind?: CustomSyncErrorKind;
  operationsSubmitApiMockErrorOperationKind?: SyncOperationKind;

  constructor(injectedDeps: PublicAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      debugModeEnabled: observable,
      debugModeToolbarExpanded: observable,
      shortcutListener: observable,
      operationsSubmitApiMockErrorKind: observable,
      operationsSubmitApiMockErrorOperationKind: observable,
      setOperationsSubmitApiMockErrorKind: action,
      setOperationsSubmitApiMockErrorOperationKind: action,
      toggleDebugMode: action,
      toggleDebugModeToolbar: action,
      initialize: action,
      registerShortcut: action,
      resetState: action,
    });

    reaction(
      () => ({
        operationsSubmitApiMockErrorKind: this.operationsSubmitApiMockErrorKind,
        operationsSubmitApiMockErrorOperationKind: this.operationsSubmitApiMockErrorOperationKind,
      }),
      ({ operationsSubmitApiMockErrorKind, operationsSubmitApiMockErrorOperationKind }) => {
        const OPERATIONS_SUBMIT_URL = "/v2/sync/operations/submit";
        if (!operationsSubmitApiMockErrorKind) {
          mockResponseForPost(OPERATIONS_SUBMIT_URL);
          return;
        }
        mockResponseForPost(OPERATIONS_SUBMIT_URL, operationsSubmitApiMockErrorOperationKind, {
          data: {
            status: "FAILED",
            info: {
              error_category: "CUSTOM_ERROR",
              error_data: {
                kind: operationsSubmitApiMockErrorKind,
                value: {},
              },
            },
          },
        });
      }
    );
  }

  setOperationsSubmitApiMockErrorKind = (kind?: CustomSyncErrorKind) => {
    this.operationsSubmitApiMockErrorKind = kind;
  };

  setOperationsSubmitApiMockErrorOperationKind = (kind?: SyncOperationKind) => {
    this.operationsSubmitApiMockErrorOperationKind = kind;
  };

  toggleDebugMode = async () => {
    const nextValue = !this.debugModeEnabled;

    logger.info({
      message: `[AppStoreDebugStore] [toggleDebugMode] { value: ${nextValue} }`,
    });

    toastModule.triggerToast({
      content: `Debug Mode ${nextValue ? "Enabled" : "Disabled"}`,
    });

    runInAction(() => {
      this.debugModeEnabled = nextValue;
    });

    await localforage.setItem(LocalStorageKey.DebugModeEnabled, nextValue);
  };

  toggleDebugModeToolbar = async () => {
    const nextValue = !this.debugModeToolbarExpanded;

    runInAction(() => {
      this.debugModeToolbarExpanded = nextValue;
    });

    await localforage.setItem(LocalStorageKey.DebugModeToolbarExpanded, nextValue);
  };

  initialize = async () => {
    // Load persisted values
    const [persistedDebugModeEnabled, persistedDebugModeToolbarExpanded] = await Promise.all([
      localforage.getItem<boolean>(LocalStorageKey.DebugModeEnabled),
      localforage.getItem<boolean>(LocalStorageKey.DebugModeToolbarExpanded),
    ]);

    runInAction(() => {
      this.debugModeEnabled = persistedDebugModeEnabled ?? false;
      this.debugModeToolbarExpanded = persistedDebugModeToolbarExpanded ?? false;
    });

    // Register global keyboard handler
    this.registerShortcut();
  };

  registerShortcut = () => {
    if (this.shortcutListener) {
      this.shortcutListener.unregisterShortcut();
    }

    this.shortcutListener = keyboardModule.registerGlobalShortcutHandler({
      shortcut: { key: "|", ctrlModifier: true, metaModifier: true, shiftModifier: true },
      handler: this.toggleDebugMode,
    });
  };

  resetState = async () => {
    runInAction(() => {
      this.debugModeEnabled = false;
      this.debugModeToolbarExpanded = false;
    });

    await Promise.all([
      localforage.removeItem(LocalStorageKey.DebugModeEnabled),
      localforage.removeItem(LocalStorageKey.DebugModeToolbarExpanded),
    ]);

    this.registerShortcut();
  };
}
