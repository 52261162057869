import styled from "@emotion/styled";
import { MdsText, MdsTextProps, MdsTextSize } from "@/design-system/components/text";
import { ChatMessageSource } from "@/domains/chat";
import { AppStore, useAppStore } from "@/store";
import { AppStoreNoteStore } from "@/store/note/AppStoreNoteStore";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { AppStoreCollectionsStore } from "@/store/collections/AppStoreCollectionsStore";

interface Props {
  sources?: ChatMessageSource[];
}

export function FactCardSources({ sources }: Props) {
  const { store } = useAppStore();

  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <Wrapper overflow={MdsTextOverflow.Ellipsis}>
      From:{" "}
      {sources.map(source => (
        <span key={source.id}>{getSources(source, store)}</span>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(MdsText)<MdsTextProps>(({ theme }) => ({
  color: theme.colors.grey.x500,
  size: MdsTextSize.Large,
  gap: theme.spacing.xs,
  // TODO: this should be dynamic
  maxWidth: 400,
}));

const iconStyles = css({
  color: mdsColors().grey.x500,
  display: "inline",
});

const getNoteTitle = (noteId: string, store: AppStoreNoteStore) =>
  store.getNoteObservableById({ noteId })?.title || "Unknown note";

const getCollectionTitle = (collectionId: string, store: AppStoreCollectionsStore) =>
  store.getCollectionObservableById({ collectionId })?.title || "Unknown collection";

const getSources = (source: ChatMessageSource, store: AppStore) => {
  switch (source.item_kind) {
    case "NOTE": {
      return (
        <span key={source.id}>
          <MdsIcon kind={MdsIconKind.Document} className={iconStyles} />{" "}
          {getNoteTitle(source.item_id, store.notes)}
        </span>
      );
    }
    case "COLLECTION": {
      return (
        <span>
          <MdsIcon kind={MdsIconKind.Document} className={iconStyles} />{" "}
          {getCollectionTitle(source.item_id, store.collections)}
        </span>
      );
    }
  }
};
