import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";
import { OnChange, ReactChangeEvent } from "@/domains/react/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTextFilterField } from "@/design-system/components/text-filter-field/MdsTextFilterField";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { css } from "@/domains/emotion";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { LensKind, SortByKind } from "@/modules/lenses/types";

export const CollectionsListPageHeader = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsListPage;

  const onCollectionsFilterChange: OnChange<ReactChangeEvent<HTMLInputElement>> = useCallback(
    event => {
      page.setCollectionsFilterQuery({ query: event.target.value });
    },
    [page]
  );

  const clearCollectionsFilterQuery = useCallback(() => {
    page.setCollectionsFilterQuery({ query: "" });
  }, [page]);

  const clearCollectionsFilterOnEscapeKeyPress = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    event => {
      event.stopPropagation();
      clearCollectionsFilterQuery();
    },
    [clearCollectionsFilterQuery]
  );

  return (
    <Wrapper>
      <MdsPanelHeader
        heading="Collections"
        icon={MdsIconKind.Collection}
        actions={
          <MdsPanelResponsiveBtn
            threshold={mdsPanelBreakpoint}
            fullSizeLabel="Create collection"
            fullSizeIcon={MdsIconKind.Plus}
            collapsedIcon={MdsIconKind.Plus}
            collapsedTooltipConfig={{
              label: "Create collection",
              placement: MdsTooltipPlacement.Top,
              delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
            }}
            onClick={page.handleCreateNewCollection}
          />
        }
        filters={[
          {
            label: "All",
            onClick: () => page.setParams({ lens: LensKind.All, sortBy: SortByKind.LastModified }),
            isActive: page.lens === LensKind.All,
          },
          {
            label: "Created by me",
            onClick: () =>
              page.setParams({ lens: LensKind.AddedByMe, sortBy: SortByKind.LastModified }),
            isActive: page.lens === LensKind.AddedByMe,
          },
          {
            label: "Shared with me",
            onClick: () =>
              page.setParams({ lens: LensKind.SharedWithMe, sortBy: SortByKind.LastModified }),
            isActive: page.lens === LensKind.SharedWithMe,
          },
        ]}
      />
      <FiltersContainer>
        <MdsTextFilterField
          placeholder="Filter by name"
          className={filterFieldStyles}
          value={page.collectionsFilterQuery}
          onChange={onCollectionsFilterChange}
          onClearText={clearCollectionsFilterQuery}
          onEscapeKeyPress={clearCollectionsFilterOnEscapeKeyPress}
          autoFocus={false}
        />
        <MdsDropdownButton
          contentList={page.sortOptions}
          label={page.sortLabel}
          iconKind={MdsIconKind.AngleDown}
          iconPosition={MdsButtonIconPosition.Right}
          placement="below-right-alignment"
        />
      </FiltersContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div({});

const FiltersContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
}));

const filterFieldStyles = css({
  borderRadius: 100,
});
