import { Color } from "@/modules/color/types";
import { Hsluv } from "@/modules/color/utils/hsluv";
import { _hexToColor } from "@/modules/color/hexToColor";

/**
 * Implementation inspired by: https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
 */
export const hsluvToColor = ({ hsluvColor }: { hsluvColor: Color }): Color => {
  const instance = new Hsluv();

  instance.hsluv_h = hsluvColor.hue;
  instance.hsluv_s = hsluvColor.saturation * 100;
  instance.hsluv_l = hsluvColor.lightness * 100;

  instance.hsluvToHex();

  const hexValue = instance.hex;

  return _hexToColor({ hex: hexValue });
};
