import { AsyncResultReady } from "@/modules/async-result/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _asyncResultSetReady = <TData = any>(data: TData): AsyncResultReady<TData> => {
  return {
    called: true,
    loading: false,
    error: undefined,
    data,
  };
};
