import { moduleFactory } from "@/factories/module";
import { _createNewDateTimeNow } from "@/modules/date-time/create";
import { _maybeParseDateTimeValue } from "@/modules/date-time/maybeParseValue";
import { _parseDateTimeValue } from "@/modules/date-time/parseValue";
import { _serializeDateTime } from "@/modules/date-time/serialize";

export const dateTimeModule = moduleFactory.create({
  create: _createNewDateTimeNow,
  serialize: _serializeDateTime,
  parseValue: _parseDateTimeValue,
  maybeParseValue: _maybeParseDateTimeValue,
});
