import showdown from "showdown";

const converter = new showdown.Converter();
converter.setOption("simpleLineBreaks", false);
converter.setOption("completeHTMLDocument", true);

export const copyNote = async (content: string) => {
  const html = converter.makeHtml(content);

  const item = new ClipboardItem({
    ["text/html"]: new Blob([html], { type: "text/html" }),
    ["text/plain"]: new Blob([content], { type: "text/plain" }),
  });

  await navigator.clipboard.write([item]);
};
