import { BaseError } from "@/domains/errors";
import { objectModule } from "@/modules/object";
import { logger } from "@/modules/logger";

export const copyTextToClipboard = async ({ text }: { text: string }) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (unknownErr) {
    const err = unknownErr as BaseError;

    logger.error({
      message: "[_copyTextToClipboard] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });
  }
};
