import { moduleFactory } from "@/factories/module";
import { GLOBAL_CACHE } from "@/modules/cache/globalCache";

const cacheModule = moduleFactory.create({
  ...GLOBAL_CACHE,
});

/**
 * We use shorthand for this module, because it is used so often.
 */
export const cache = cacheModule;
