import { Maybe } from "@/domains/common/types";
import { ModalDefinitionKind, SyncErrorModalDefinition } from "@/store/modals/types";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { isEqual } from "lodash-es";
import { computed, makeObservable, reaction } from "mobx";

export class ProactiveLostAccessModalStore extends AppSubStore {
  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      firstProactiveLostAccessSyncError: computed,
    });

    reaction(
      () => this.firstProactiveLostAccessSyncError,
      (current, previous) => {
        if (isEqual(current, previous)) return;

        if (previous) this.store.modals.removeModal(previous);
        if (current) this.store.modals.addModal(current);
      }
    );
  }

  get firstProactiveLostAccessSyncError(): Maybe<SyncErrorModalDefinition> {
    const checkedNoteIds = new Set<string>();

    // Unacknowledged sync ops.
    for (const operation of this.store.sync.actionQueue.processing) {
      if (
        // Note edits.
        !(operation instanceof UpdateNoteContentUsingDiffOperation) ||
        // Don't check again.
        checkedNoteIds.has(operation.payload.id)
      ) {
        continue;
      }

      const id = operation.payload.id;
      // LeaveNoteMessageModalStore handles it.
      if (id === this.store.routing.noteIdParam) {
        checkedNoteIds.add(id);
        continue;
      }

      const note = this.store.notes.get(id);
      // Not checking isDeleted because it can be set optimistically from queued operations.
      if (note?.canWrite) {
        checkedNoteIds.add(id);
        continue;
      }

      const syncError = operation.getSyncErrorModalFieldsGenerator("PERMISSION_DENIED", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })(this.store);

      return {
        kind: ModalDefinitionKind.SyncError,
        syncError,
      };
    }
  }
}
