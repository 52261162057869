import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { SyncModelKind } from "@/store/sync/types";

export const resolveCollectionItemSyncModelUuid = ({
  collectionId,
  itemId,
}: {
  collectionId: Uuid;
  itemId: Uuid;
}): Uuid => {
  return resolveSyncModelNamespacedUuid({
    modelKind: { value: SyncModelKind.CollectionItem },
    uuids: [collectionId, itemId],
  });
};
