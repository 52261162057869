import styled from "@emotion/styled";
import { Button } from "react-aria-components";
import { IMDSButtonProps } from "./button.h";

// TODO: Temp example
export const StyledButton = styled(Button)<IMDSButtonProps>(props => ({
  display: "flex",
  textWrap: "nowrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  borderRadius: props.theme.borderRadius.medium,
  fontWeight: props.theme.fontWeights.semiBold,
  fontSize: props.theme.fontSizes.small,
  cursor: "pointer",
  transition: "0.25s ease",
  userSelect: "none",
  padding: getPadding(props),
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
    backgroundColor: props.theme.colors.grey.x300,
  },
  backgroundColor: props.theme.colors.primary.x500,
  color: props.theme.colors.grey.x0,
  "&:hover": {
    backgroundColor: props.theme.colors.primary.x400,
    transition: "0.2s all",
  },
  "&:active": {
    backgroundColor: props.theme.colors.primary.x600,
  },
  "& .mds-btn-icon": {
    color: props.theme.colors.grey.x0,
  },
  borderColor: "transparent",

  ...getExtras(props),
}));

const getPadding = (props: IMDSButtonProps): string => {
  switch (props.size) {
    case "sm":
      return "6px 12px";
    case "lg":
      return "12px";
    default:
      return "10px 12px";
  }
};

const getExtras = (props: IMDSButtonProps) => {
  switch (props.size) {
    case "lg":
      return {
        "& .mds-btn-icon": {
          fontSize: 16,
        },
        "& .mds-btn-label": {
          fontSize: 16,
          lineHeight: "24px",
        },
      };
  }
};
