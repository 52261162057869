import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store";
import {
  FavoriteItemModelData,
  FavoriteItemUpsertedSyncUpdateValue,
} from "@/store/favorite-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IAddCollectionToFavoritesOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";
import { generateKeyBetween } from "fractional-indexing";

export class AddCollectionToFavoritesOperation extends BaseSyncOperation<IAddCollectionToFavoritesOperation> {
  get operationKind(): SyncOperationKind {
    return "ADD_COLLECTION_TO_FAVORITES";
  }

  // Need to override constructor to make sort_key optional
  constructor({
    store,
    payload: providedPayload,
    operationId: providedOperationId,
  }: {
    payload: Optional<IAddCollectionToFavoritesOperation["payload"], "schema_version" | "sort_key">;
    operationId?: Uuid;
    store: AppStore;
  }) {
    const lastItemSortKey = store.favoriteItems.sortedFavoriteItems.at(-1)?.sortKey || null;
    const payload = {
      ...providedPayload,
      sort_key: providedPayload.sort_key ?? generateKeyBetween(lastItemSortKey, null),
      schema_version: providedPayload.schema_version ?? 1,
    };

    super({ store, payload, operationId: providedOperationId || uuidModule.generate() });
  }

  get successToastMessage() {
    return <>Pinned {this.mediumTitle} to sidebar</>;
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const modelId = uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.collection_id,
    });
    const favoriteItem = this.store.favoriteItems.getFavoriteItemObservableById({
      favoriteItemId: modelId,
    });

    const value: FavoriteItemUpsertedSyncUpdateValue = {
      model_id: modelId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItem?.modelVersion ?? 0,
      model_data: {
        space_account_id: spaceAccountId,
        item_id: this.payload.collection_id,
        item_kind: "COLLECTION",
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        sort_key: this.payload.sort_key,
      },
      model_scopes: favoriteItem
        ? favoriteItem.modelScopes
        : [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: SyncUpdate<FavoriteItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  get title() {
    return (
      this.store.collections.get(this.payload.collection_id)?.title || UNTITLED_COLLECTION_TITLE
    );
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  protected getToastMessage() {
    return (
      <>
        {this.mediumTitle} could not be pinned to the sidebar. If this error continues, please
        contact support.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }
}
