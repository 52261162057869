import styled from "@emotion/styled";
import { MdsDotPulseLoader, MdsDotPulseLoaderKind } from "@/design-system/components/loader";

interface Props {
  dataTestId?: string;
}

export function Loader({ dataTestId }: Props): JSX.Element {
  return (
    <Wrapper data-test-id={`${dataTestId}-loader`}>
      <MdsDotPulseLoader kind={MdsDotPulseLoaderKind.Brand} />
    </Wrapper>
  );
}

const Wrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 1rem",
  height: "24px",
});
