import { observer } from "mobx-react-lite";
import { FC } from "react";
import { PlatformTrialState } from "@/store/platform-info/types";
import { DateTime } from "luxon";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";

interface TrialingTooltipConfigContentProps extends EmotionClassStyles {
  trialEnd: DateTime | null;
  trialState: PlatformTrialState;
  subscribeNowHandler: () => void;
}

export const TrialingTooltipConfigContent: FC<TrialingTooltipConfigContentProps> = observer(
  ({ subscribeNowHandler }) => {
    return (
      <div className={hintContentWrapper}>
        <div className={hintTextTitle}>{"You're trying out Mem"}</div>
        <div className={hintTextBody}>
          {"Mem is the only notes app designed to keep busy professionals organized with AI."}
        </div>

        <MdsButton
          label="Subscribe now"
          onClick={subscribeNowHandler}
          variant={MdsButtonVariant.Brand}
        />
      </div>
    );
  }
);

interface UseTrialingTooltipConfigProps
  extends Pick<TrialingTooltipConfigContentProps, "subscribeNowHandler"> {}

export const useTrialingTooltipConfig = ({
  subscribeNowHandler,
}: UseTrialingTooltipConfigProps) => {
  return {
    placement: MdsTooltipPlacement.Bottom,
    content: (
      <TrialingTooltipConfigContent
        trialEnd={DateTime.now().plus({ days: 3 })}
        trialState={PlatformTrialState.TrialActive}
        subscribeNowHandler={subscribeNowHandler}
      />
    ),
  };
};

const hintContentWrapper = css({
  maxWidth: 320,
  /**
   * Pulling this up to ensure the hover-state covers the gap.
   */
  marginTop: -18,
  paddingTop: 30,
  paddingBottom: 12,
  paddingLeft: 10,
  paddingRight: 10,
  overflow: "hidden",
});

const hintTextTitle = css({
  color: "#F3F3F5",
  marginBottom: 8,
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
});

const hintTextBody = css({
  color: "#D3D5DA",
  marginBottom: 8,
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 400,
});
