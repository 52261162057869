import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { ZIndex } from "@/domains/design/constants";
import { SidebarContents } from "@/components/layout/components/sidebar/SidebarContents";
import { useState } from "react";
import { SidebarAccountInfo } from "@/components/layout/components/sidebar/SidebarAccountInfo";
import { SidebarDropdownMenu } from "@/components/layout/components/sidebar/SidebarDropdownMenu";
import { css } from "@/domains/emotion";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { motion } from "framer-motion";

export const SidebarMobile = observer(({ onClick }: { onClick: () => void }) => {
  const { store } = useAppStore();
  const { profileDisplayName, profilePhotoUrl, profileEmailAddress } = store.account.myAccount;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Wrapper
      initial={{ opacity: 0, scale: 0.97 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.12, ease: "easeInOut" }}
    >
      <ContainerTop>
        {isMenuOpen && (
          <MdsIconButton iconKind={MdsIconKind.LeftArrow} onClick={() => setIsMenuOpen(false)} />
        )}
        <SidebarAccountInfo
          profileSize={ProfileSize.Large}
          profileDisplayName={profileDisplayName}
          profilePhotoUrl={profilePhotoUrl}
          profileEmailAddress={profileEmailAddress}
        />
        {!isMenuOpen && (
          <MdsIconButton
            iconKind={MdsIconKind.AngleRight}
            onClick={() => setIsMenuOpen(true)}
            className={alignRightStyles}
          />
        )}
      </ContainerTop>
      {isMenuOpen ? (
        <SidebarDropdownMenu className={dropdownStyles} onClick={onClick} />
      ) : (
        <SidebarContentsWrapper>
          <SidebarContents onClick={onClick} />
        </SidebarContentsWrapper>
      )}
    </Wrapper>
  );
});

const Wrapper = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing.xs,
  backgroundColor: theme.colors.white,
  height: "calc(100dvh - 56px)",
  width: "calc(100dvw - 16px)",
  position: "absolute",
  top: 48,
  borderRadius: theme.borderRadius.medium,
  border: theme.borders.default,
  boxShadow: theme.shadows.default,
  zIndex: ZIndex.Sidebar,
}));

const ContainerTop = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  padding: theme.spacing.md,
  gap: theme.spacing.xs,
  borderBottom: `1px solid ${theme.colors.grey.x100}`,
}));

const SidebarContentsWrapper = styled.div(({ theme }) => ({
  overflowY: "scroll",
  padding: theme.spacing.sm,
}));

const dropdownStyles = css({
  backgroundColor: `transparent`,
  boxShadow: `none`,
  width: `100%`,
  border: 0,
  height: "auto",
});

const alignRightStyles = css({
  marginLeft: "auto",
});
