import { BaseError } from "@/domains/errors";
import { api } from "@/modules/api";
import { asyncResultModule } from "@/modules/async-result";
import { objectModule } from "@/modules/object";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { AsyncResult } from "@/modules/async-result/types";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

export class SettingsSubscriptionManagementPageStore extends AppSubStore {
  fetchBillingPortalUrlState: AsyncResult<string>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    this.fetchBillingPortalUrlState = asyncResultModule.setInitial();
    makeObservable(this, {
      fetchBillingPortalUrlState: observable,
      isSubscribed: computed,
      billingPortalDisabled: computed,
      openBillingPortal: action,
      openSubscriptionModal: action,
      fetchBillingPortalUrl: action,
    });
  }

  get isSubscribed() {
    return this.store.platformInfo.accountState === "ACTIVE";
  }

  get billingPortalDisabled() {
    return !this.fetchBillingPortalUrlState.data;
  }

  public openBillingPortal() {
    if (this.fetchBillingPortalUrlState.data) {
      const openedWindow = window.open();
      if (openedWindow) openedWindow.location.href = this.fetchBillingPortalUrlState.data;
    }
  }

  public openSubscriptionModal() {
    this.store.platformInfo.openSubscribeNowInterstitial();
  }

  public async fetchBillingPortalUrl() {
    if (this.fetchBillingPortalUrlState.called) return;

    runInAction(() => {
      this.fetchBillingPortalUrlState.called = true;
      this.fetchBillingPortalUrlState.error = undefined;
      this.fetchBillingPortalUrlState.loading = true;
    });

    const spaceId = this.store.spaces.myPersonalSpaceId;
    const response = await api.get("/v2/me/subscriptions/stripe/billing_portal", {
      params: { query: { space_id: spaceId } },
    });

    if (response.data) {
      runInAction(
        () => (this.fetchBillingPortalUrlState.data = response.data.stripe_billing_portal_url)
      );
    }
    if (response.error) {
      const err = new BaseError({
        message:
          "[SettingsSubscriptionManagementPageStore] Failed to fetch billing portal url for current account.",
        info: { result: objectModule.safeAsJson(response) },
      });
      runInAction(() => (this.fetchBillingPortalUrlState.error = err));
    }
    runInAction(() => (this.fetchBillingPortalUrlState.loading = false));
  }
}
