import {
  computed,
  makeObservable,
  observable,
  ObservableMap,
  ObservableSet,
  runInAction,
  toJS,
  action,
} from "mobx";

import { SpaceObservable } from "@/store/space/observables/SpaceObservable";
import { api } from "@/modules/api";
import { cache } from "@/modules/cache";
import { CacheKey } from "@/modules/cache/constants";
import { BaseError, RuntimeAssertionError, StoreError } from "@/domains/errors";
import { SpaceAccountObservable } from "@/store/space-account/observables/SpaceAccountObservable";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { Result } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";

export class AppStoreSpaceStore extends AppSubStore {
  spaceMap: ObservableMap<Uuid, SpaceObservable>;
  fetchMySpacesState: AsyncResult<ObservableSet<Uuid>>;
  fetchSpaceStateMap: ObservableMap<Uuid, AsyncResult<true>>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.spaceMap = new ObservableMap();
    this.fetchMySpacesState = asyncResultModule.setInitial();
    this.fetchSpaceStateMap = new ObservableMap();

    makeObservable(this, {
      spaceMap: observable,
      fetchMySpacesState: observable,
      fetchSpaceStateMap: observable,
      activeSpaceId: computed,
      myPersonalSpaceId: computed,
      isOnActiveSpaceRoute: computed,
      activeSpace: computed,
      getSpace: false,
      createSpaceAndSpaceAccount: action,
      addSpace: action,
      fetchSpace: action,
      fetchMySpaces: action,
      persistMySpacesToCache: action,
      hydrateMySpacesFromCache: action,
      initialize: action,
      mySpaceIds: computed,
      mySpaces: computed,
      resetState: action,
    });
  }

  /**
   * This will return the active space id param if it is set,
   * or it tries other space-id formats (e.g. the creation flow).
   * If there are no matches, it falls back to the "Personal Space ID".
   */
  get activeSpaceId(): Uuid {
    const spaceIdParam = this.store.routing.spaceIdParam;
    const createSpaceIdParam = this.store.routing.createSpaceIdParam;

    if (spaceIdParam) {
      return spaceIdParam;
    }

    if (createSpaceIdParam) {
      return createSpaceIdParam;
    }

    return this.myPersonalSpaceId;
  }

  get myPersonalSpaceId(): Uuid {
    const myAccountId = this.store.account.myAccountId;

    const personalSpaceId = uuidModule.generatePersonalSpaceIdFromAccountId({
      accountId: myAccountId,
    });

    return personalSpaceId;
  }

  get isOnActiveSpaceRoute(): boolean {
    const spaceIdParam = this.store.routing.spaceIdParam;

    if (!spaceIdParam) {
      return false;
    }

    return true;
  }

  get activeSpace(): SpaceObservable | null {
    if (!this.activeSpaceId) {
      return null;
    }

    const maybeActiveSpace = this.getSpace(this.activeSpaceId);

    if (!maybeActiveSpace) {
      return null;
    }

    return maybeActiveSpace;
  }

  getSpace = (spaceId: Uuid): SpaceObservable | undefined => {
    return this.spaceMap.get(spaceId);
  };

  createSpaceAndSpaceAccount = async ({
    space: { id: spaceId, title: spaceTitle },
  }: {
    space: { id: Uuid; title: string };
  }): Promise<Result<true, StoreError>> => {
    const result = await api.post("/v1/spaces", {
      body: {
        id: spaceId,
        title: spaceTitle,
      },
    });

    if (result.error) {
      return {
        error: new StoreError({
          message: "Failed to fetch current account.",
          info: {
            result: objectModule.safeAsJson(result),
          },
        }),
      };
    }

    const spaceData = result.data.space;
    const spaceAccountData = result.data.space_account;
    // const spaceInviteSharingUrlData = result.data.space_invite_sharing_url;

    const space = new SpaceObservable({
      id: spaceData.id,
      title: spaceData.title,
    });

    const spaceAccount = new SpaceAccountObservable({
      id: spaceAccountData.id,
      accountId: spaceAccountData.account_id,
      spaceId: spaceAccountData.space_id,
      profileEmailAddress: spaceAccountData.profile_email_address,
      profileDisplayName: spaceAccountData.profile_display_name,
      profilePhotoUrl: spaceAccountData.profile_photo_url ?? null,
    });

    // const spaceInviteSharingUrl = SpaceInviteSharingUrlObservable.fromApiData({
    //   data: spaceInviteSharingUrlData,
    // });

    this.addSpace({
      space,
    });

    this.store.spaceAccounts.addSpaceAccount({
      spaceAccount,
    });

    // this.store.spaceInviteSharingUrls.addSpaceInviteSharingUrl({
    //   spaceInviteSharingUrl,
    // });

    return {
      data: true,
    };
  };

  addSpace = ({ space }: { space: SpaceObservable }) => {
    runInAction(() => {
      this.spaceMap.set(space.id, space);
    });
  };

  fetchSpace = async ({ spaceId }: { spaceId: Uuid }) => {
    if (this.fetchSpaceStateMap.get(spaceId)?.loading) {
      return;
    }

    runInAction(() => {
      this.fetchSpaceStateMap.set(spaceId, asyncResultModule.setLoading());
    });

    const result = await api.get("/v1/spaces/{space_id}", {
      params: {
        path: {
          space_id: spaceId,
        },
      },
    });

    if (result.error) {
      return {
        error: new StoreError({
          message: "Failed to fetch space.",
          info: {
            result: objectModule.safeAsJson(result),
          },
        }),
      };
    }

    const spaceData = result.data;

    const space = new SpaceObservable({
      id: spaceData.id,
      title: spaceData.title,
    });

    this.addSpace({
      space,
    });

    runInAction(() => {
      this.fetchSpaceStateMap.set(spaceId, asyncResultModule.setReady(true));
    });
  };

  fetchMySpaces = async () => {
    runInAction(() => {
      this.fetchMySpacesState = asyncResultModule.setLoading();
    });

    const result = await api.get("/v1/me/spaces", {});

    if (result.data) {
      const spaceDataArray = result.data;

      runInAction(() => {
        spaceDataArray.forEach(spaceData => {
          const space = new SpaceObservable({
            id: spaceData.id,
            title: spaceData.title,
          });

          this.spaceMap.set(space.id, space);
        });

        const spaceIds = spaceDataArray.map(spaceData => spaceData.id);
        this.fetchMySpacesState.data = new ObservableSet(spaceIds);
      });
    } else {
      const err = new BaseError({
        message: "Failed to fetch spaces.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.fetchMySpacesState.error = err;
      });
    }

    runInAction(() => {
      this.fetchMySpacesState.loading = false;
    });

    await this.persistMySpacesToCache();
  };

  persistMySpacesToCache = async () => {
    await cache.set(
      CacheKey.MySpaces,
      this.mySpaces.map(space => toJS(space))
    );
  };

  hydrateMySpacesFromCache = async (): Promise<boolean> => {
    const mySpaces = await cache.get(CacheKey.MySpaces);

    if (!mySpaces) {
      return false;
    }

    const spaces = mySpaces.map(spaceData => {
      const space = new SpaceObservable({
        id: spaceData.id,
        title: spaceData.title,
      });

      return space;
    });

    runInAction(() => {
      spaces.forEach(space => {
        this.spaceMap.set(space.id, space);
      });

      const spaceIds = spaces.map(space => space.id);

      this.fetchMySpacesState.data = new ObservableSet(spaceIds);
    });

    return true;
  };

  initialize = async () => {
    const fetchMySpacesPromise = this.fetchMySpaces();
    const hydrationSuccess = await this.hydrateMySpacesFromCache();

    /**
     * If we were able to hydrate from cache, we don't need to wait for the API call.
     */
    if (hydrationSuccess) {
      return;
    }

    await fetchMySpacesPromise;

    if (this.fetchMySpacesState.error) {
      throw new RuntimeAssertionError({
        message: "[SpaceStore.initialize] Failed.",
        info: { originalError: objectModule.safeErrorAsJson(this.fetchMySpacesState.error) },
      });
    }
  };

  get mySpaceIds(): Uuid[] {
    const spaceIds = this.fetchMySpacesState.data;

    if (!spaceIds) {
      throw new RuntimeAssertionError({
        message:
          "[SpaceAccountStore.spaceIds] spaceIds was not set - make sure that `initialize` was called.",
      });
    }

    if (!spaceIds.size) {
      throw new RuntimeAssertionError({
        message:
          "[SpaceAccountStore.spaceIds] spaceIds was empty - make sure that `initialize` was called.",
      });
    }

    return Array.from(spaceIds);
  }

  get mySpaces(): SpaceObservable[] {
    const spaces = this.mySpaceIds.map(spaceId => {
      const space = this.getSpace(spaceId);

      if (!space) {
        throw new RuntimeAssertionError({
          message:
            "[SpaceStore.mySpaces] space was not set - make sure that `initialize` was called.",
        });
      }

      return space;
    });

    return spaces;
  }

  resetState = () => {
    runInAction(() => {
      this.spaceMap = new ObservableMap();
      this.fetchMySpacesState = asyncResultModule.setInitial();
    });
  };
}
