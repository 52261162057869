import { appRoutes } from "@/app/router";
import { AppStore } from "@/store";

export const openSearchInNewTab = ({
  savedSearchId,
  store,
}: {
  savedSearchId: string;
  store: AppStore;
}) => {
  const savedSearchObservable = store.savedSearches.getSavedSearchObservableById({
    savedSearchId,
  });
  if (!savedSearchObservable) return;
  const url = `${window.location.origin}${appRoutes.search({}).path}?${savedSearchObservable.savedSearchQueryString}`;
  window.open(url, "_blank");
};
