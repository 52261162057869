import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { mdsMediaQueries, mdsSpacings, PanelMode } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { SidebarMobile } from "@/components/layout/components/sidebar/SidebarMobile";
import { useState } from "react";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { ProfileVariant } from "@/components/layout/components/account-profile/types";

export const TopBar = observer(() => {
  const { store, pageStore } = useAppStore();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isNarrowView = !!store.interface.matchesPanelModeBreakpoint.get(PanelMode.Narrow);

  const isChatPath = !!useMatch(appRoutes.chat({ starPattern: true }));
  const isChatToggleDisabled = isChatPath;
  const searchQuery = pageStore.searchPage.activeSearchEngineParams.queryString;

  const { profileDisplayName, profilePhotoUrl } = store.account.myAccount;

  return (
    <>
      {isNarrowView && isSidebarOpen && <SidebarMobile onClick={() => setIsSidebarOpen(false)} />}
      <Wrapper>
        {isNarrowView && (
          <>
            <div onClick={() => setIsSidebarOpen(prev => !prev)}>
              <AccountProfileImageContent
                size={ProfileSize.Standard}
                photoUrl={profilePhotoUrl}
                displayName={profileDisplayName}
                variant={ProfileVariant.Square}
              />
            </div>
            <MdsIconButton
              iconKind={MdsIconKind.ComposeInline}
              onClick={store.notes.composeNewNote}
            />
          </>
        )}
        <SearchButton
          label={searchQuery || "Search your Mem"}
          iconKind={MdsIconKind.Search}
          variant={MdsButtonVariant.TextTertiaryOutlined}
          onClick={
            searchQuery
              ? () => pageStore.quickSearchModal.openWithQuery({ query: searchQuery })
              : pageStore.quickSearchModal.toggleIsOpen
          }
        />
        <RightButtons>
          {isNarrowView ? (
            <MdsIconButton iconKind={MdsIconKind.Message} onClick={store.navigation.goToChat} />
          ) : (
            <MdsIconButton
              iconKind={
                store.sidePanel.isSidePanelOpen
                  ? MdsIconKind.ArrowRightFromLine
                  : MdsIconKind.Message
              }
              tooltipConfig={{
                content: store.sidePanel.isSidePanelOpen ? "Close chat" : "Open chat",
                placement: MdsTooltipPlacement.Bottom,
              }}
              onClick={store.sidePanel.toggle}
              isDisabled={isChatToggleDisabled}
            />
          )}
        </RightButtons>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div({
  width: "100%",
  paddingBottom: mdsSpacings().sm,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: mdsSpacings().sm,
  height: "40px",
});

const RightButtons = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: mdsSpacings().sm,
});

const SearchButton = styled(MdsButton)(({ theme }) => ({
  height: "32px",
  width: 360,
  alignItems: "flex-start",
  backgroundColor: theme.colors.grey.x100,
  fontWeight: theme.fontWeights.regular,
  [mdsMediaQueries().rules.phone]: {
    width: "100%",
  },
}));
