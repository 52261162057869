import { observer } from "mobx-react-lite";
import { useAppStore, useGuestAppStore } from "@/store";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { StandardModePadding } from "@/app/outlets/StandardModePadding";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { NoteEditor } from "@/pages/notes-view/NoteEditor";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { ChatHistory } from "@/store/chat/ChatHistory";

export const StandardNotesViewPage = observer(function StandardNotesViewPage() {
  const { store, pageStore } = useAppStore();
  pageStore.notesViewPage.useEffects();

  return (
    <NotesViewPage
      getAvailableChips={store.chatMessages.chatHistory.getAvailableChips}
      store={pageStore.notesViewPage}
      isSidePanelOpen={store.interface.isChatSidebarOpen}
    />
  );
});

export const GuestNotesViewPage = observer(function GuestNotesViewPage() {
  const { guestStore } = useGuestAppStore();
  guestStore.notesViewPage.useNoteIdFromRoutePath();
  return <NotesViewPage store={guestStore.notesViewPage} />;
});

interface NotesViewPageProps {
  store: INotesViewPageStore;
  isSidePanelOpen?: boolean;
  getAvailableChips?: ChatHistory["getAvailableChips"];
}

const NotesViewPage = observer<NotesViewPageProps>(
  ({ store, isSidePanelOpen, getAvailableChips }) => {
    if (store.showNotFound) {
      // Apply consistent padding for the NotFoundPage.
      return (
        <StandardModePadding>
          <NotFoundPage entityType={EntityNotFoundType.Note} />
        </StandardModePadding>
      );
    }

    return (
      <>
        {store.addToCollectionModal && <AddToCollectionModal store={store.addToCollectionModal} />}
        <NoteEditor
          isSidePanelOpen={!!isSidePanelOpen}
          getAvailableChips={getAvailableChips}
          noteObservable={store.noteObservable}
          myAccount={store.myAccount}
          goToMention={store.goToMention}
        />
      </>
    );
  }
);
