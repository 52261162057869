import { moduleFactory } from "@/factories/module";
import {
  convertEncodedContentToNoteContent,
  convertMarkdownToEncodedContent,
  convertMarkdownToNoteContent,
} from "@/modules/notes/conversions";
import { mergeDiffsWithRemoteContent } from "@/modules/notes/yjs";

export const notesModule = moduleFactory.create({
  convertMarkdownToNoteContent,
  convertEncodedContentToNoteContent,
  convertMarkdownToEncodedContent,
  mergeDiffsWithRemoteContent,
});
