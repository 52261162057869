import { appRoutes } from "@/app/router";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";

export const copyNoteLinkToClipboard = async ({ noteId }: { noteId: string }) => {
  await copyTextToClipboard({
    text: `${window.location.origin}${appRoutes.notesView({ params: { noteId } }).path}`,
  });
  toastModule.triggerToast({ content: "Link copied to clipboard" });
};
