import { useFloatingTooltip } from "@/design-system/components/tooltip/utils/useFloatingTooltip";
import * as React from "react";

type ContextType = ReturnType<typeof useFloatingTooltip> | null;

export const FloatingTooltipContext = React.createContext<ContextType>(null);

/**
 * Implementation heavily inspired from the floating-ui example docs here:
 * - https://floating-ui.com/docs/tooltip
 *
 * Prefer to use MdsTooltip directly, instead of using this.
 */
export const useFloatingTooltipContext = () => {
  const context = React.useContext(FloatingTooltipContext);

  if (context == null) {
    throw new Error("FloatingTooltip components must be wrapped in <FloatingTooltip />");
  }

  return context;
};
