import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsDropdownContentList, MdsDropdownIconButton } from "@/design-system/components/dropdown";
import { Uuid } from "@/domains/global/identifiers";
import { FC } from "react";
import { MdsIconButtonSize } from "@/design-system/components/icon-button";

export interface MdsItemListRowActionProps {
  itemId: Uuid;
  dropdown?: MdsItemDropdown;
}

export const MdsItemListRowDropdown: FC<MdsItemListRowActionProps> = ({ dropdown }) => {
  if (!dropdown) {
    return null;
  }

  const contentList: MdsDropdownContentList = {
    items: dropdown.items,
  };

  return (
    <MdsDropdownIconButton
      contentList={contentList}
      placement="below-right-alignment"
      size={MdsIconButtonSize.Small}
    />
  );
};
