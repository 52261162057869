import { isObjectLike, isUndefined } from "lodash-es";

export const _objectHasKey = <X, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> => {
  if (isUndefined(obj)) {
    return false;
  }

  if (!isObjectLike(obj)) {
    return false;
  }

  return prop in (obj as Record<string, unknown>);
};
