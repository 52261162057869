import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_CONTACT_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IGrantCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class GrantCollectionAclViaSpaceAccountOperation extends BaseSyncOperation<IGrantCollectionAclViaSpaceAccountOperation> {
  get operationKind(): SyncOperationKind {
    return "GRANT_COLLECTION_ACL_VIA_SPACE_ACCOUNT";
  }

  get successToastMessage() {
    return (
      <>
        Sharing {this.mediumTitle} with {this.mediumContactName}...
      </>
    );
  }

  get title() {
    return this.store.collections.get(this.payload.id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get contactName() {
    return (
      this.store.contacts.getContactObservableByContactSpaceAccountId(this.payload.space_account_id)
        ?.profileDisplayName || UNTITLED_CONTACT_TITLE
    );
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  get mediumContactName() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.contactName}
      </MdsText>
    );
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const collection = this.store.collections.get(this.payload.id);
    if (!collection || collection.isShared) return [];

    const collectionValue: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: collection.modelVersion,
      model_data: {
        ...collection.modelData,
        shared_at: null, // Don't update shared_at optimistically
      },
      model_scopes: collection.modelScopes,
    };
    const collectionUpdate: SyncUpdate<CollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionValue,
    };

    return [collectionUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>You do not have permissions to share {this.mediumTitle}. Please contact the note owner.</>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
