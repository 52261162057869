import React, { useMemo, FC } from "react";

import { MdsTabBarSectionContent } from "@/design-system/components/tab-bar/MdsTabBarSectionContent";
import { MdsTabBarSection } from "@/design-system/components/tab-bar/types";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";

export interface MdsTabBarProps extends EmotionClassStyles {
  selectedItemId: string;
  sections: MdsTabBarSection[];
}

const wrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: 300,
  height: "100%",
});

export const MdsTabBar: FC<MdsTabBarProps> = ({ selectedItemId, sections, className }) => {
  const combinedWrapperStyles = cx(wrapperStyles, className);

  const sectionContent = useMemo(
    () =>
      sections.map(section => (
        <MdsTabBarSectionContent
          key={section.id}
          selectedItemId={selectedItemId}
          section={section}
        />
      )),
    [sections, selectedItemId]
  );

  return <div className={combinedWrapperStyles}>{sectionContent}</div>;
};
