import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { action, makeObservable, observable } from "mobx";

export class MountedStore {
  /** A component is rendering this. */
  isMounted: boolean = false;

  constructor() {
    makeObservable(this, {
      useEffects: false,
      isMounted: observable,
      setIsMounted: action,
    });
  }

  setIsMounted(isMounted: boolean) {
    this.isMounted = isMounted;
  }

  useEffects() {
    useEffectOnMount(() => {
      // Delay mounted state to avoid flashing "not found".
      const h = setTimeout(() => this.setIsMounted(true), 0);
      return () => {
        clearTimeout(h);
        this.setIsMounted(false);
      };
    });
  }
}
