import { Uuid } from "@/domains/global/identifiers";
import { UuidNamespace } from "@/modules/uuid/constants";
import { v5 as uuidv5 } from "uuid";

export const resolveSyncModelNamespace = ({
  modelKind,
}: {
  modelKind: { value: string };
}): Uuid => {
  return uuidv5(modelKind.value, UuidNamespace.SyncModelNamespace);
};
