import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  useFloating,
  offset,
  shift,
  flip,
  useHover,
  useFocus,
  useInteractions,
  FloatingPortal,
  Placement as FloatingPlacement,
  safePolygon,
} from "@floating-ui/react";
import { MdsHoverContentGroup } from "@/design-system/components/hover-content/MdsHoverContentGroup";
import { css, cx } from "@/domains/emotion";
import useDebouncedCallback from "@/domains/react/useDebouncedCallback";
import { ZIndex } from "@/domains/design/constants";

export enum MdsHoverContentPlacement {
  Above = "above",
  Below = "below",
  BelowRightAlignment = "below-right-alignment",
  Right = "right",
}

export interface MdsHoverContentProps {
  isTouchDevice?: boolean;
  children: React.ReactNode;
  content: (args: IMdsHoverContentArgs) => React.ReactNode;
  group?: MdsHoverContentGroup;
  contentClassName?: string;
  requireClick?: boolean;
  placement?: MdsHoverContentPlacement;
}

export interface IMdsHoverContentArgs {
  hide: () => void;
}

const getPlacement = (placement?: MdsHoverContentPlacement): FloatingPlacement => {
  switch (placement) {
    case MdsHoverContentPlacement.Above:
      return "top-start";
    case MdsHoverContentPlacement.Below:
      return "bottom-start";
    case MdsHoverContentPlacement.BelowRightAlignment:
      return "bottom-end";
    case MdsHoverContentPlacement.Right:
      return "right-start";
  }
  return "bottom-start";
};

export const MdsHoverContent = memo<MdsHoverContentProps>(function MdsHoverContent({
  children,
  isTouchDevice,
  content,
  group,
  contentClassName,
  requireClick,
  placement,
}) {
  const [show, setShow] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open: show,
    onOpenChange: setShow,
    placement: getPlacement(placement),
    middleware: [offset(10), flip(), shift()],
  });

  const hover = useHover(context, {
    enabled: !requireClick,
    handleClose: safePolygon({ requireIntent: true }),
  });
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  const hideWithDelay = useDebouncedCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      setShow(false);
    },
    250,
    {
      leading: false,
      trailing: true,
    }
  );

  const hideImmediately = useCallback(() => {
    hideWithDelay();
    hideWithDelay.flush();
  }, [hideWithDelay]);

  const activateHoveringMode = useCallback(() => {
    hideWithDelay.cancel();
    group?.hideAll({ exceptHideFn: hideWithDelay });
    setShow(true);
  }, [hideWithDelay, group]);

  const toggleVisibility = useCallback(() => {
    if (show) hideImmediately();
    else activateHoveringMode();
  }, [show, hideImmediately, activateHoveringMode]);

  useEffect(() => {
    if (!group) return;

    group.hideFns.push(hideWithDelay);

    return () => {
      group.hideFns = group.hideFns.filter(hide => hide !== hideWithDelay);
    };
  }, [hideWithDelay, group]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.target) return;
      // If the target is in the floating element, do nothing
      if (refs.floating.current?.contains(event.target as Node)) return;
      // If the target is in the floating element, do nothing
      if (refs.domReference.current?.contains(event.target as Node)) return;
      hideImmediately();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideImmediately, refs.domReference, refs.floating]);

  const renderedContent = useMemo(
    () =>
      show ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={cx(containerStyles, contentClassName)}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {content({ hide: hideImmediately })}
          </div>
        </FloatingPortal>
      ) : null,
    [
      content,
      contentClassName,
      hideImmediately,
      show,
      strategy,
      x,
      y,
      getFloatingProps,
      refs.setFloating,
    ]
  );

  if (isTouchDevice) {
    return <>{children}</>;
  }

  return (
    <div
      ref={refs.setReference}
      className={toggleStyles}
      onClick={requireClick ? toggleVisibility : undefined}
      {...getReferenceProps()}
    >
      {children}
      {renderedContent}
    </div>
  );
});

const toggleStyles = css({
  position: "relative",
});

const containerStyles = css({
  position: "absolute",
  top: "100%",
  left: "0",
  zIndex: ZIndex.HoverCard,
});
