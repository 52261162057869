import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import debounce from "lodash-es/debounce";

export function SectionWrapper({
  children,
  isProcessing,
  isSystemMessage,
}: React.PropsWithChildren<{
  isProcessing: boolean;
  isSystemMessage: boolean;
}>) {
  const [height, setHeight] = useState<number | "auto">(isProcessing ? 0 : "auto");
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = contentRef.current;
    if (!currentRef) return;

    const handleResize = debounce((entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      if (entry) setHeight(entry.contentRect.height);
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(currentRef);

    return () => {
      handleResize.cancel();
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <OuterWrapper
      height={height}
      isProcessing={isProcessing}
      shouldShowGradient={isProcessing && isSystemMessage}
    >
      <div ref={contentRef}>{children}</div>
    </OuterWrapper>
  );
}

const OuterWrapper = styled.div<{
  height: number | "auto";
  isProcessing: boolean;
  shouldShowGradient: boolean;
}>(({ height, isProcessing, shouldShowGradient }) => ({
  height: isProcessing ? height : "auto",
  transition: "height 0.5s linear",
  overflow: "hidden",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: shouldShowGradient ? "2lh" : 0,
    background: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
    pointerEvents: "none",
    opacity: shouldShowGradient ? 1 : 0,
    transition: "opacity 0.3s linear, height 0.3s linear",
  },
}));
