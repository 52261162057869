// eslint-disable-next-line no-restricted-imports
import {
  flush as emotionFlush,
  hydrate as emotionHydrate,
  cx as emotionCx,
  merge as emotionMerge,
  getRegisteredStyles as emotionGetRegisteredStyles,
  css as emotionCss,
  injectGlobal as emotionInjectGlobal,
  keyframes as emotionKeyframes,
  sheet as emotionSheet,
  cache as emotionCache,
  type ClassNamesArg,
  type CSSObject,
} from "@emotion/css";

// eslint-disable-next-line no-restricted-imports
import createEmotion from "@emotion/css/create-instance";

type Emotion = {
  flush: typeof emotionFlush;
  hydrate: typeof emotionHydrate;
  cx: typeof emotionCx;
  merge: typeof emotionMerge;
  getRegisteredStyles: typeof emotionGetRegisteredStyles;
  css: typeof emotionCss;
  injectGlobal: typeof emotionInjectGlobal;
  keyframes: typeof emotionKeyframes;
  sheet: typeof emotionSheet;
  cache: typeof emotionCache;
};

type CreateEmotion = (args: { key: string }) => Emotion;

/**
 * The typescript typings for CreateEmotion seem to be broken, so we
 * define it ourselves here.
 */
const patchedCreateEmotion = createEmotion as unknown as CreateEmotion;

/**
 * We create a new instance of emotion here so that we don't conflict with
 * the global emotion utilities from any other packages.
 */
export const {
  flush,
  hydrate,
  cx,
  merge,
  getRegisteredStyles,
  css,
  injectGlobal,
  keyframes,
  sheet,
  cache,
} = patchedCreateEmotion({
  key: "mem-client",
});

export type { ClassNamesArg, CSSObject };
