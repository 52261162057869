import { BaseError } from "@/domains/errors";
import { Location } from "react-router-dom";

export const _googleIdentityParseOAuthCode = ({
  location,
}: {
  location: Location;
}): { code: string } => {
  const urlSearchParams = new URLSearchParams(location.search);
  const code = urlSearchParams.get("code");

  if (!code) {
    throw new BaseError({ message: "Unable to parse code." });
  }

  return { code };
};
