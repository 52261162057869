import { Maybe } from "@/domains/common/types";
import {
  MessageModal,
  MessageModalDefinition,
  ModalDefinition,
  ModalDefinitionKind,
} from "@/store/modals/types";
import { NotesViewPageStore } from "@/store/pages/NotesViewPageStore/NotesViewPageStore";
import { ObservableObjectCondition } from "@/store/pages/types";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { isEqual } from "lodash-es";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";

export class LeaveNoteMessageModalStore implements MessageModalDefinition, MessageModal {
  private notesViewPageStore: NotesViewPageStore;

  get kind(): ModalDefinitionKind.Message {
    return ModalDefinitionKind.Message;
  }

  get modal(): MessageModal {
    return this;
  }

  closed = false;
  userNeedsToAcknowledgeLostWriteAccess = false;

  constructor(notesViewPageStore: NotesViewPageStore) {
    this.notesViewPageStore = notesViewPageStore;

    makeObservable<this, "notesViewPageStore">(this, {
      notesViewPageStore: false,
      kind: computed,
      modal: computed,
      noteObservable: computed,
      condition: computed,
      buttonLabel: computed,
      closed: observable,
      userNeedsToAcknowledgeLostWriteAccess: observable,
      title: computed,
      message: computed,
      isModalOpen: computed,
      hasLostAccess: computed,
      maybePendingNoteUpdateOperation: computed,
      modalDefinition: computed,
      setClosed: action,
      handleButtonClick: action,
      handleClose: action,
    });

    reaction(
      () => this.notesViewPageStore.noteExists,
      (exists, previousExists) => {
        if (!exists && previousExists && this.closed) {
          this.setClosed(false);
        }
      }
    );

    reaction(
      () => this.condition,
      (condition, previousCondition) => {
        this.setClosed(false);
        if (
          condition === ObservableObjectCondition.ReadAccess &&
          previousCondition === ObservableObjectCondition.WriteAccess
        ) {
          // Users needs to confirm they are aware.
          runInAction(() => {
            this.userNeedsToAcknowledgeLostWriteAccess = true;
          });
        }
      }
    );

    reaction(
      () => this.modalDefinition,
      (current, previous) => {
        if (isEqual(current, previous)) return;

        if (previous) this.notesViewPageStore.store?.modals.removeModal(previous);
        if (current) this.notesViewPageStore.store?.modals.addModal(current, { priority: true });
      }
    );
  }

  get noteObservable() {
    return this.notesViewPageStore.noteObservable;
  }

  get title() {
    const maybeEdit = this.noteObservable?.canAccess ? "edit" : "";
    const noteTitle = this.noteObservable ? `“${this.noteObservable?.title}”` : "this note";
    return `You no longer have ${maybeEdit} access to ${noteTitle}`;
  }

  get message() {
    switch (this.condition) {
      case ObservableObjectCondition.NoAccess:
      case ObservableObjectCondition.ReadAccess:
        return "You can request access again from the owner if this was an error";
      case ObservableObjectCondition.InTrash:
        return "The note was moved to the trash by the note owner";
      case ObservableObjectCondition.Deleted:
        return "The note was permanently deleted";
      case ObservableObjectCondition.WriteAccess:
      case ObservableObjectCondition.Unknown:
        return "";
    }
  }

  get condition(): ObservableObjectCondition {
    if (!this.noteObservable) return ObservableObjectCondition.Unknown;
    if (this.noteObservable.isDeleted) return ObservableObjectCondition.Deleted;
    if (!this.noteObservable.canAccess) return ObservableObjectCondition.NoAccess;
    if (this.noteObservable.isTrashed) return ObservableObjectCondition.InTrash;
    if (!this.noteObservable.canWrite) return ObservableObjectCondition.ReadAccess;
    return ObservableObjectCondition.WriteAccess;
  }

  get buttonLabel() {
    return "Got it";
  }

  get isModalOpen() {
    return this.modalDefinition === this;
  }

  get hasLostAccess() {
    if (
      // If the user didn't have access initially we show not found instead.
      !this.notesViewPageStore.couldAccessNoteOnMount ||
      // Wait until note is loaded.
      !this.noteObservable ||
      // Owner shouldn't see this.
      this.noteObservable.isOwnedByMe
    ) {
      return false;
    }

    switch (this.condition) {
      case ObservableObjectCondition.Unknown:
      case ObservableObjectCondition.WriteAccess:
        // Everything is OK.
        return false;

      case ObservableObjectCondition.NoAccess:
      case ObservableObjectCondition.InTrash:
      case ObservableObjectCondition.Deleted:
        // User must confirm and we leave the note.
        return true;

      case ObservableObjectCondition.ReadAccess:
        // User can confirm and continue reading.
        return this.userNeedsToAcknowledgeLostWriteAccess;
    }
  }

  get maybePendingNoteUpdateOperation() {
    if (!this.hasLostAccess || !this.noteObservable) return;

    const operations = this.notesViewPageStore.store.sync.actionQueue.operationsByModelId.get(
      this.noteObservable.id
    );
    const maybePendingUpdateOperation = operations?.find(
      e => e.operationKind === "UPDATE_NOTE_CONTENT_USING_DIFF" && !e.acknowledged
    );
    return maybePendingUpdateOperation as Maybe<UpdateNoteContentUsingDiffOperation>;
  }

  get modalDefinition(): Maybe<ModalDefinition> {
    // We can drop this modal automatically if the operation was being processed and succeds.
    const syncError = this.maybePendingNoteUpdateOperation?.getSyncErrorModalFieldsGenerator(
      "PERMISSION_DENIED",
      {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
        onAfterClose: this.handleButtonClick,
      }
    )(this.notesViewPageStore.store);

    if (syncError) {
      return {
        kind: ModalDefinitionKind.SyncError,
        syncError,
      };
    }

    // User dismissed lost access modal.
    if (this.closed) return;

    if (this.hasLostAccess) return this;
  }

  setClosed(closed: boolean) {
    this.closed = closed;
  }

  handleButtonClick = () => {
    this.handleClose();

    this.userNeedsToAcknowledgeLostWriteAccess = false;

    const userHasReadOnlyAccess =
      this.noteObservable &&
      this.noteObservable.canAccess &&
      !this.noteObservable.canWrite &&
      !this.noteObservable.isDeleted &&
      !this.noteObservable.isTrashed;

    if (!userHasReadOnlyAccess) {
      this.notesViewPageStore.store.navigation.goUp();
    }
  };

  handleClose() {
    this.setClosed(true);
    this.userNeedsToAcknowledgeLostWriteAccess = false;
  }
}
