import { MdsPrimaryChip } from "@/design-system/components/chip/primary";
import { MdsChip } from "@/design-system/components/input/types";
import { css } from "@/domains/emotion";
import { useCallback } from "react";

export const MdsChipInputChip = ({
  chip,
  onRemoveChip,
  isDisabled,
}: {
  chip: MdsChip;
  onRemoveChip: (chip: MdsChip) => void;
  isDisabled?: boolean;
}) => {
  const handleRemoveChip = useCallback(() => {
    onRemoveChip(chip);
  }, [chip, onRemoveChip]);

  return (
    <div className={chipWrapper}>
      <MdsPrimaryChip onRemove={handleRemoveChip} isDisabled={isDisabled}>
        {chip.icon && <div className={chipIconWrapper}>{chip.icon(18)}</div>}
        <div className={chipContent}>{chip.label}</div>
      </MdsPrimaryChip>
    </div>
  );
};

const chipWrapper = css({
  maxWidth: "100%",
  overflow: "hidden",
  margin: "2px",
});

const chipContent = css({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const chipIconWrapper = css({
  marginRight: "8px",
  flexShrink: 0,
});
