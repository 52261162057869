import { Uuid } from "@/domains/global/identifiers";
import general from "@/domains/local-db/global/general/adapter";
import config from "@/domains/local-db/global/general/config";

export const getCurrentAuthInfo = async (): Promise<{
  accountId: Uuid;
  authToken: string;
  authStrategy: string;
} | null> => {
  const accountIdResponse = await general.get(config.CURRENT_ACCOUNT_ID_KEY);
  const authTokenResponse = await general.get(config.CURRENT_AUTH_TOKEN_KEY);
  const authStrategyResponse = await general.get(config.CURRENT_AUTH_STRATEGY_KEY);

  if (!accountIdResponse || !authTokenResponse || !authStrategyResponse) {
    return null;
  }

  const accountId = accountIdResponse[config.CURRENT_ACCOUNT_ID_KEY];
  const authToken = authTokenResponse[config.CURRENT_AUTH_TOKEN_KEY];
  const authStrategy = authStrategyResponse[config.CURRENT_AUTH_STRATEGY_KEY];

  if (!accountId || !authToken || !authStrategy) {
    return null;
  }

  return {
    accountId,
    authToken,
    authStrategy,
  };
};
