export enum MdsIconButtonSize {
  XXSmall = "xxsmall",
  XSmall = "xsmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge",
}

export enum MdsIconButtonVariant {
  Brand = "brand",
  Dark = "dark",
  Outlined = "outlined",
  Transparent = "transparent",
  FullyTransparent = "fully-transparent",
}

export enum MdsIconButtonShape {
  Round = "round",
  Square = "square",
}
