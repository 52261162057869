import { RuntimeAssertionError } from "@/domains/errors";
import { CACHE_TABLE_IDENTIFIER } from "@/modules/cache/constants";
import Dexie from "dexie";

type CacheItem = {
  /**
   * The scope of the cache (e.g. a specific user.)
   */
  scopeId: string;
  /**
   * The key used for read/write.
   * Unique, when paired with a scopeId.
   */
  cacheKey: string;
  /**
   * The id for the zod schema.
   * Used for serialization/deserialization.
   * For our default getters/setters, this will be the same as the key.
   */
  schemaId: string;
  /**
   * The value to be stored.
   * Serialized to JSON.
   */
  payload: string;
};

export class CacheClientDB extends Dexie {
  _dbConnection: Dexie | null = null;

  constructor({ id, version }: { id: string; version: number }) {
    super(id, { chromeTransactionDurability: "relaxed", autoOpen: false });

    this.version(version)
      .stores({
        [CACHE_TABLE_IDENTIFIER]: "[scopeId+cacheKey], schemaId, payload",
      })
      .upgrade(async trx => {
        for (const storeName of trx.storeNames) {
          await trx.table(storeName).clear();
        }
      });
  }

  initialize = async () => {
    const dbConnection = await this.open();

    if (!dbConnection) {
      throw new RuntimeAssertionError({
        message: "[Cache] Failed to initialize DB.",
      });
    }

    this._dbConnection = dbConnection;
  };

  getConnection = (): Dexie => {
    const conn = this._dbConnection;

    if (!conn) {
      throw new RuntimeAssertionError({
        message: "[Cache] DB connection not initialized. Ensure DB is initialized first.",
      });
    }

    return conn;
  };

  cacheTable = () => {
    const table = this.getConnection().table<CacheItem>(CACHE_TABLE_IDENTIFIER);

    if (!table) {
      throw new RuntimeAssertionError({
        message: "[Cache] Failed to get cache table. Ensure DB is initialized first.",
      });
    }

    return table;
  };
}
