import { IResource, fromResource } from "mobx-utils";
import { useEffect } from "react";
import { useMatch } from "react-router-dom";

import { QueryObservable } from "@/store/queries/QueryObservable";
import { CreateQuery } from "@/store/queries/types";

import { Maybe } from "@/domains/common/types";
import { appRoutes } from "@/app/router";

interface CachedQueryValue<Value = unknown> {
  id: string;
  value: Value;
  activePages: number[];
  internalState: unknown;
}

export class AppStoreQueriesCacheStore {
  private queries = new Map<string, IResource<QueryObservable>>();
  private cachedQueryValue: Maybe<CachedQueryValue> = undefined;

  private make<Value>(
    id: string,
    createQuery: CreateQuery<Value>
  ): IResource<QueryObservable<Value>> {
    const query = createQuery();
    const observable = fromResource<QueryObservable<Value>>(
      async sink => {
        console.debug(`[Query ${id}]: Initializing...`);
        query.startPolling();
        sink(query);
      },
      async () => {
        console.debug(`[Query ${id}]: Disposing...`);
        query.stopPolling();
        this.queries.delete(id);
      },
      query
    );
    return observable;
  }

  get<Value>(id: string, createQuery: CreateQuery<Value>): QueryObservable<Value> {
    const typedQueries = this.queries as Map<string, IResource<QueryObservable<Value>>>;
    let resource = typedQueries.get(id);
    if (!resource) {
      resource = this.make(id, createQuery);
      typedQueries.set(id, resource);
    }
    return resource.current();
  }

  setCachedValue(cachedValue?: CachedQueryValue) {
    // Only restore scroll position of the last query so:
    // List > Item > Back to list restores scroll position.
    this.cachedQueryValue = cachedValue;
  }

  getCachedValue<Value>(id: string): Maybe<CachedQueryValue<Value>> {
    const { cachedQueryValue } = this;
    if (cachedQueryValue?.id === id) {
      return cachedQueryValue as CachedQueryValue<Value>;
    }
  }

  useCacheCancellation() {
    const isNotesPath = useMatch(appRoutes.notesList({}));
    const isSearchPath = useMatch(appRoutes.search({ starPattern: true }));
    const isNotePath = useMatch("/notes/*");
    const resetCache = !isNotesPath && !isSearchPath && !isNotePath;

    useEffect(() => {
      if (!resetCache) return;

      const id = this.cachedQueryValue?.id;
      if (!id) return;

      console.debug(`[Query ${id}]: Resetting cache`);
      this.setCachedValue();

      // DISABLED FOR NOW.
      // Reset slice scroll position:
      // this.store.ux.setSliceViewScrollState(id);
    }, [resetCache]);
  }
}
