import { ValidateEnumMatchesType, AssertTrue } from "@/domains/typing/types";
import { ApiSchema } from "@/modules/api";

export enum PlatformAccountState {
  Initial = "INITIAL",
  Onboarding = "ONBOARDING",
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

type ValidPlatformAccountStates = ApiSchema["AccountStateEnum"];

type _ValidatePlatformAccountStates = AssertTrue<
  ValidateEnumMatchesType<ValidPlatformAccountStates, PlatformAccountState>
>;

export enum PlatformTrialState {
  Unknown = "UNKNOWN",
  TrialActive = "TRIAL_ACTIVE",
  TrialCompleted = "TRIAL_COMPLETED",
}

type ValidPlatformTrialStates = ApiSchema["TrialStateEnum"];

type _ValidatePlatformTrialStates = AssertTrue<
  ValidateEnumMatchesType<ValidPlatformTrialStates, PlatformTrialState>
>;

export enum PlatformOnboardingState {
  Unknown = "UNKNOWN",
  Onboarding = "ONBOARDING",
  OnboardingCompleted = "ONBOARDING_COMPLETED",
}

type ValidPlatformOnboardingStates = ApiSchema["OnboardingStateEnum"];

type _ValidatePlatformTrialState = AssertTrue<
  ValidateEnumMatchesType<ValidPlatformOnboardingStates, PlatformOnboardingState>
>;

export enum PlatformStripeSubscriptionStatus {
  Active = "ACTIVE",
  Unknown = "UNKNOWN",
  Trialing = "TRIALING",
  PastDue = "PAST_DUE",
  Unpaid = "UNPAID",
  Incomplete = "INCOMPLETE",
  IncompleteExpired = "INCOMPLETE_EXPIRED",
  Canceled = "CANCELED",
  Paused = "PAUSED",
}

type ValidPlatformStripeSubscriptionStatuses = ApiSchema["StatusE47Enum"];

type _ValidatePlatformStripeSubscriptionStatuses = AssertTrue<
  ValidateEnumMatchesType<ValidPlatformStripeSubscriptionStatuses, PlatformStripeSubscriptionStatus>
>;

export enum OnboardingStage {
  SelectUseCases = "SELECT_USE_CASES",
  SelectJob = "SELECT_JOB",
  SelectReferrer = "SELECT_REFERRER",
  ActivateTrial = "ACTIVATE_TRIAL",
}
