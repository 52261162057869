import { ReactNode } from "react";

export enum MdsTooltipPlacement {
  Top = "TOP",
  TopRight = "TOP_RIGHT",
  TopLeft = "TOP_LEFT",
  Bottom = "BOTTOM",
  BottomRight = "BOTTOM_RIGHT",
  BottomLeft = "BOTTOM_LEFT",
  Left = "LEFT",
  Right = "RIGHT",
}

export type MdsTooltipConfig = {
  placement: MdsTooltipPlacement;
  delaySeconds?: number;
} & (
  | {
      label: string;
      content?: undefined;
    }
  | {
      label?: undefined;
      content: ReactNode;
    }
);
