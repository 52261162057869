import { FC } from "react";
import { observer } from "mobx-react-lite";

import { SelectUseCasesStage } from "@/components/onboarding/stages/SelectUseCasesStage";
import { SelectJobStage } from "@/components/onboarding/stages/SelectJobStage";
import { SelectReferrerStage } from "@/components/onboarding/stages/SelectReferrerStage";
import { ActivateTrialStage } from "@/components/onboarding/stages/ActivateTrialStage";
import { useAppStore } from "@/store";
import { OnboardingStage } from "@/store/platform-info/types";

export const OnboardingRouter: FC = observer(() => {
  const { store } = useAppStore();

  const activeStage = store.platformInfo.onboardingInfo.activeOnboardingStage;

  return (
    <>
      {activeStage === OnboardingStage.SelectUseCases && <SelectUseCasesStage />}
      {activeStage === OnboardingStage.SelectJob && <SelectJobStage />}
      {activeStage === OnboardingStage.SelectReferrer && <SelectReferrerStage />}
      {activeStage === OnboardingStage.ActivateTrial && <ActivateTrialStage />}
    </>
  );
});
