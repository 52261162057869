import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { getIconKind } from "@/design-system/components/item-list/rows/icon/icon-kind";
import { mdsBorderRadius, mdsColors } from "@/design-system/foundations";
import { generateCollectionIconBackgroundStyles } from "@/domains/collections/generateCollectionIconBackgroundStyles";
import { css, cx } from "@/domains/emotion";

interface Props {
  itemId: string;
  isSelected?: boolean;
  isHighlighted?: boolean;
  iconStyles?: string;
  toggleSelected: (event: React.MouseEvent) => void;
}

export function CollectionIcon({
  itemId,
  isSelected,
  isHighlighted,
  iconStyles,
  toggleSelected,
}: Props) {
  return (
    <MdsIconButton
      onClick={toggleSelected}
      size={MdsIconButtonSize.Medium}
      iconStyles={css({ color: "white" })}
      variant={MdsIconButtonVariant.FullyTransparent}
      iconKind={getIconKind({ isSelected, isHighlighted, defaultIcon: MdsIconKind.Collection })}
      className={getCombinesStyles({ itemId, iconStyles, isSelected })}
    />
  );
}

const getColorStyles = (itemId: string) => {
  return cx(
    generateCollectionIconBackgroundStyles(itemId),
    css({ borderRadius: mdsBorderRadius().maximum })
  );
};

const getCombinesStyles = ({
  itemId,
  iconStyles,
  isSelected,
}: {
  itemId: string;
  iconStyles?: string;
  isSelected?: boolean;
}) => {
  const styles = cx(iconStyles, css({ color: mdsColors().grey.x500 }));

  return cx(styles, isSelected && "selected", getColorStyles(itemId));
};
