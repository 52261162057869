import { computed, makeObservable, observable, action } from "mobx";

import { Uuid } from "@/domains/global/identifiers";
import { mergeUpdates } from "@/domains/object-utils/mergeUpdates";
import pRetry from "p-retry";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { api } from "@/modules/api";

export class AccountObservable {
  id: Uuid;
  profileEmailAddress: string;
  profileDisplayName: string;
  profilePhotoUrl: string | null;
  settings: Record<string, unknown>;

  constructor({
    id,
    profileEmailAddress,
    profileDisplayName,
    profilePhotoUrl,
    settings,
  }: {
    id: Uuid;
    profileEmailAddress: string;
    profileDisplayName: string;
    profilePhotoUrl: string | null;
    settings?: Record<string, unknown>;
  }) {
    this.id = id;
    this.profileEmailAddress = profileEmailAddress;
    this.profileDisplayName = profileDisplayName;
    this.profilePhotoUrl = profilePhotoUrl;
    this.settings = settings ?? Object.create({});

    makeObservable(this, {
      id: observable,
      profileEmailAddress: observable,
      profileDisplayName: observable,
      profilePhotoUrl: observable,
      settings: observable,
      displayName: computed,
      secondaryLabel: computed,
      photoUrl: computed,
      updateSettings: action,
    });
  }

  get displayName() {
    return this.profileDisplayName;
  }

  get secondaryLabel() {
    return this.profileEmailAddress;
  }

  get photoUrl() {
    return this.profilePhotoUrl || undefined;
  }

  async updateSettings(settings: Record<string, unknown>) {
    this.settings = mergeUpdates(this.settings, settings);
    try {
      await pRetry(
        async () => {
          const response = await api.patch("/v1/accounts/{id}/", {
            params: { path: { id: this.id } },
            body: objectModule.safeAsJson(this.settings),
          });
          if (response.error) {
            logger.debug({
              message: "[AccountObservable.updateSettings] Failed to update user profile",
              info: objectModule.safeAsJson({ error: response.error }),
            });
            throw new Error(`Failed to update user profile`);
          }
        },
        {
          retries: 3,
          factor: 2,
          minTimeout: 500,
          onFailedAttempt: () => {},
        }
      );
      return true;
    } catch (err) {
      logger.error({
        message: "Failed to update account settings",
        info: objectModule.safeAsJson({ err, settings: this.settings }),
      });
      return false;
    }
  }
}
