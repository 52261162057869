import { AsyncResultLoading } from "@/modules/async-result/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _asyncResultSetLoading = (): AsyncResultLoading<any> => {
  return {
    called: true,
    loading: true,
    error: undefined,
    data: undefined,
  };
};
