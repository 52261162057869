import { uuidModule } from "@/modules/uuid";
import {
  ChatConversationModelData,
  ChatConversationUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateChatConversationOperation } from "@/store/sync/operations/types";
import { SyncModelData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class CreateChatConversationOperation extends BaseSyncOperation<ICreateChatConversationOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_CHAT_CONVERSATION";
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const value: ChatConversationUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "CHAT_CONVERSATION",
      model_version: 0,
      model_data: {
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        is_primary_chat_conversation: true,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: SyncUpdate<ChatConversationModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }
}
