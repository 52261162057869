import {
  generateSyncScopePermissionEntryWithStatusFromSyncOperation,
  generateSyncScopePermissionEntryWithStatusFromSyncModelScope,
} from "@/domains/sync-scopes";
import { SyncModelPermissionEntryWithStatus } from "@/domains/sync-scopes/types";
import { AppStore, GuestAppStore } from "@/store";
import { NoteModelData } from "@/store/note/types";
import { AppSyncActionQueue } from "@/store/sync/AppSyncActionQueue";
import { GuestSyncActionQueue } from "@/store/sync/GuestSyncActionQueue";
import {
  ACL_SYNC_OPERATIONS,
  ACL_SYNC_OPERATIONS_FOR_NOTES,
  SyncModelData,
  SyncUpdateValue,
} from "@/store/sync/types";
import { uniqBy } from "lodash-es";

export const getRemotePermissionsForModel = <ModelData extends SyncModelData>({
  remoteData,
}: {
  remoteData: SyncUpdateValue<ModelData>;
}): SyncModelPermissionEntryWithStatus[] => {
  const output: SyncModelPermissionEntryWithStatus[] = [];
  for (const modelScope of remoteData.model_scopes) {
    const entry = generateSyncScopePermissionEntryWithStatusFromSyncModelScope(modelScope);
    output.push({ ...entry, status: "CONFIRMED" });
  }
  // Deduplicate permissions with the same scope and return
  return uniqBy(output, permission => {
    if (permission.scope_kind === "COLLECTION_SCOPE") return permission.collection_id;
    if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") return permission.space_account_id;
    if (permission.scope_kind === "EMAIL_SCOPE") return permission.email_address;
    if (permission.scope_kind === "SPACE_SCOPE") return permission.space_id;
    if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") return permission.guest_account_id;
  });
};

export const getPermissionsForModel = <ModelData extends SyncModelData>({
  id,
  remoteData,
  actionQueue,
}: {
  id: string;
  remoteData: SyncUpdateValue<ModelData>;
  actionQueue: AppSyncActionQueue | GuestSyncActionQueue;
}): SyncModelPermissionEntryWithStatus[] => {
  const output: SyncModelPermissionEntryWithStatus[] = [];

  // Add all pending permissions from the queue first for priority
  const queue = actionQueue.operationsByModelId.get(id) || [];
  for (const operation of queue.slice().reverse()) {
    if (ACL_SYNC_OPERATIONS.includes(operation.operationKind)) {
      const entry = generateSyncScopePermissionEntryWithStatusFromSyncOperation(operation);
      output.push({
        ...entry,
        status: "PENDING",
        operationKind: operation.operationKind,
        operationId: operation.id,
      });
    }
  }
  // Then add back the remote entries
  for (const modelScope of remoteData.model_scopes) {
    const entry = generateSyncScopePermissionEntryWithStatusFromSyncModelScope(modelScope);
    output.push({ ...entry, status: "CONFIRMED" });
  }
  // Deduplicate permissions with the same scope and return
  return uniqBy(output, permission => {
    if (permission.scope_kind === "COLLECTION_SCOPE") return permission.collection_id;
    if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") return permission.space_account_id;
    if (permission.scope_kind === "EMAIL_SCOPE") return permission.email_address;
    if (permission.scope_kind === "SPACE_SCOPE") return permission.space_id;
    if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") return permission.guest_account_id;
  });
};

export const getPermissionsForNoteSyncModel = ({
  id,
  remoteData,
  store,
  actionQueue,
}: {
  id: string;
  remoteData: SyncUpdateValue<NoteModelData>;
  store: AppStore | GuestAppStore;
  actionQueue: AppSyncActionQueue | GuestSyncActionQueue;
}): SyncModelPermissionEntryWithStatus[] => {
  const output: SyncModelPermissionEntryWithStatus[] = [];

  // Add all pending permissions from the queue first for priority
  const queue = actionQueue.operationsByModelId.get(id) || [];
  for (const operation of queue.slice().reverse()) {
    if (ACL_SYNC_OPERATIONS_FOR_NOTES.includes(operation.operationKind)) {
      // Do not generate ACL entries for notes in private collections
      if (
        store instanceof AppStore &&
        "collection_id" in operation.payload &&
        (operation.operationKind === "ADD_NOTE_TO_COLLECTION" ||
          operation.operationKind === "REMOVE_NOTE_FROM_COLLECTION")
      ) {
        const collection = store.collections.get(operation.payload.collection_id);
        if (!collection?.isShared) continue;
      }

      const entry = generateSyncScopePermissionEntryWithStatusFromSyncOperation(operation);
      output.push({
        ...entry,
        status: "PENDING",
        operationKind: operation.operationKind,
        operationId: operation.id,
      });
    }
  }
  // Then add back the remote entries
  for (const modelScope of remoteData.model_scopes) {
    const entry = generateSyncScopePermissionEntryWithStatusFromSyncModelScope(modelScope);
    output.push({ ...entry, status: "CONFIRMED" });
  }
  // Deduplicate permissions with the same scope and return
  return uniqBy(output, permission => {
    if (permission.scope_kind === "COLLECTION_SCOPE") return permission.collection_id;
    if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") return permission.space_account_id;
    if (permission.scope_kind === "EMAIL_SCOPE") return permission.email_address;
    if (permission.scope_kind === "SPACE_SCOPE") return permission.space_id;
    if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") return permission.guest_account_id;
  });
};
