import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsDropdownDetailItem } from "@/design-system/components/dropdown";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsFontSizes, mdsLineHeights } from "@/design-system/foundations/typography";

export interface MdsDropdownDetailItemComponentProps {
  className?: string;
  item: MdsDropdownDetailItem;
}

const wrapperStyles = css({
  maxWidth: 220,
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "center",
});

const detailStyles = css({
  fontSize: mdsFontSizes().xxsmall,
  lineHeight: mdsLineHeights().xsmall,
  color: mdsColors().grey.x500,
  fontWeight: 600,
  padding: 6,
});

export const MdsDropdownDetailItemComponent: FC<MdsDropdownDetailItemComponentProps> = ({
  className,
  item,
}) => {
  const styles = cx(className, detailStyles, item.className);
  return (
    <div className={wrapperStyles}>
      <div className={styles}>{item.text}</div>
    </div>
  );
};
