/* eslint-disable @typescript-eslint/no-explicit-any */
import { enumModule } from "@/modules/enum";

export const findMatchingEnumByValue = <Enum extends Record<string, any>>(
  enumObject: Enum,
  value: Enum[keyof Enum]
): Enum[keyof Enum] | undefined => {
  const values = enumModule.values(enumObject);

  const matchingValue = values.find(v => v === value);

  return matchingValue as Enum[keyof Enum] | undefined;
};

/**
 * This variation of the above function also supports string-based inputs.
 *
 * (This is useful when working with things from external sources, such as URL parameters,
 * API responses, etc.)
 */
export const findMatchingEnumStringValue = <Enum extends Record<string, any>>(
  enumObject: Enum,

  value: Enum[keyof Enum] | string
): Enum[keyof Enum] | undefined => {
  const matchingValue = findMatchingEnumByValue<Record<string, any>>(enumObject, value);

  return matchingValue as Enum[keyof Enum] | undefined;
};
