import { FC } from "react";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { StripeSubscriptionInterstitialOverlayWrapper } from "@/components/subscriptions/StripeSubscriptionInterstitialOverlayWrapper";
import { HasStripeSubscriptionInterstitialOverlayContent } from "@/components/subscriptions/HasStripeSubscriptionInterstitialOverlayContent";
import { PlatformAccountState } from "@/store/platform-info/types";

export const StripeSubscriptionInterstitialOverlay: FC = observer(() => {
  const { store } = useAppStore();

  const {
    accountState,
    shouldShowStripeSubscriptionInterstitial,
    subscribeNowInterstitialOpenedManually,
  } = store.platformInfo;
  if (!shouldShowStripeSubscriptionInterstitial) return null;

  const hasSubscription = accountState === PlatformAccountState.Active;

  return (
    <>
      <HasStripeSubscriptionInterstitialOverlayContent
        isOpen={hasSubscription}
        onCloseInterstitialHandler={store.platformInfo.dismissStripeSubscriptionInterstitial}
      />
      <StripeSubscriptionInterstitialOverlayWrapper
        isOpen={!hasSubscription || subscribeNowInterstitialOpenedManually}
        openedManually={subscribeNowInterstitialOpenedManually}
        onCloseInterstitialHandler={store.platformInfo.dismissStripeSubscriptionInterstitial}
      />
    </>
  );
});
