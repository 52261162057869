import { CoercionError } from "@/domains/errors";
import { DateTime } from "luxon";

export const _serializeDateTime = (value: unknown): string => {
  if (!DateTime.isDateTime(value)) {
    throw new CoercionError({
      message: `[_serializeDateTime]: Could not serialize DateTime with value - ${value}`,
    });
  }

  const isoValue = value.toISO();

  if (isoValue === null) {
    throw new CoercionError({
      message: `[_serializeDateTime]: Could not serialize DateTime with value - ${value}`,
    });
  }

  return isoValue;
};
