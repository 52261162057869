import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import {
  MdsCarouselItem,
  MdsItemListCarouselRowData,
} from "@/design-system/components/item-list/types";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export interface MdsItemListCarouselRowProps {
  data: MdsItemListCarouselRowData;
  className?: string;
}

export const MdsItemListCarouselRow = observer<MdsItemListCarouselRowProps>(
  ({ data, className }) => {
    return (
      <FadeHorizontalScroll className={cx(carouselStyles, className)}>
        {data.payload.items.map(item => (
          <MdsItemListCarouselRowItem key={item.id} {...item} />
        ))}
      </FadeHorizontalScroll>
    );
  }
);

const MdsItemListCarouselRowItem: FC<MdsCarouselItem> = ({
  id: itemId,
  className,
  innerStyles,
  icon,
  label,
  secondary,
  onClick,
  ...props
}) => {
  return (
    <div className={cx(carouselItemStyles, className)} onClick={() => onClick({ itemId })}>
      {icon()}
      <div {...props} className={columnStyles}>
        <MdsText
          size={MdsTextSize.Small}
          overflow={MdsTextOverflow.Ellipsis}
          className={cx(labelStyles, innerStyles?.Label?.className)}
        >
          {label}
        </MdsText>
        <MdsText
          size={MdsTextSize.XSmall}
          overflow={MdsTextOverflow.Ellipsis}
          className={cx(secondaryStyles, innerStyles?.Secondary?.className)}
        >
          {secondary}
        </MdsText>
      </div>
    </div>
  );
};

const carouselStyles = css({
  gap: "16px",
  padding: "16px",
  maxWidth: "100%",
});

const carouselItemStyles = css({
  userSelect: "none",
  borderRadius: "16px",
  border: `1px solid ${mdsColors().grey.x25}`,
  boxShadow: `0 2px 5px #454f680d`,
  cursor: "pointer",
  display: "flex",
  gap: "12px",
  padding: "16px",

  "&:hover": {
    backgroundColor: mdsColors().grey.x25,
    transition: "0.2s all",
  },
});

const columnStyles = css({});

const labelStyles = css({
  fontWeight: mdsFontWeights().bold,
  lineHeight: "20px",
});

const secondaryStyles = css({});
