import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespace } from "@/modules/uuid/sync-models/resolveSyncModelNamespace";
import { SyncModelKind } from "@/store/sync/types";
import { v5 as uuidv5 } from "uuid";

export const resolveSavedSearchSyncModelUuid = ({
  spaceAccountId,
  queryString,
}: {
  spaceAccountId: Uuid;
  queryString: Uuid;
}): Uuid => {
  const uppercaseQueryString = queryString.toUpperCase();
  const uppercaseSpaceAccountId = spaceAccountId.toUpperCase();
  const namespace = resolveSyncModelNamespace({ modelKind: { value: SyncModelKind.SavedSearch } });
  const name = `ACCOUNT::${uppercaseSpaceAccountId}::QUERY::${uppercaseQueryString}`;
  return uuidv5(name, namespace);
};
