import { MdsIconKind } from "@/design-system/components/icon";
import { mdsSpacings, mdsSidebarWidths } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "@emotion/styled";
import MemLogo from "@/assets/images/mem-logo-bw.svg";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsHoverContent } from "@/design-system/components/hover-content/MdsHoverContent";
import { SidebarDropdownMenu } from "@/components/layout/components/sidebar/SidebarDropdownMenu";
import { SidebarAccountInfo } from "@/components/layout/components/sidebar/SidebarAccountInfo";
import { SidebarContents } from "@/components/layout/components/sidebar/SidebarContents";
import { Link } from "react-router-dom";
import { appRoutes } from "@/app/router";

export const SidebarExpanded = observer(({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const { store } = useAppStore();
  const { profileDisplayName, profilePhotoUrl } = store.account.myAccount;

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <SidebarWrapper onClick={stopPropagation}>
      <SidebarTopWrapper>
        <Link to={appRoutes.notesList({}).path}>
          <img src={MemLogo} className={logoStyles} alt="Mem Logo" />
        </Link>
        <MdsIconButton iconKind={MdsIconKind.Sidebar} onClick={toggleSidebar} />
      </SidebarTopWrapper>
      <SidebarMainWrapper>
        <MdsHoverContent
          requireClick
          content={() => <SidebarDropdownMenu className={dropdownStyles} />}
        >
          <AccountInfoWrapper>
            <SidebarAccountInfo
              withDropdownIcon
              profileDisplayName={profileDisplayName}
              profilePhotoUrl={profilePhotoUrl}
            />
          </AccountInfoWrapper>
        </MdsHoverContent>
        <SidebarContents />
      </SidebarMainWrapper>
    </SidebarWrapper>
  );
});

const SidebarWrapper = styled.div({
  color: `#323640`,
  display: `flex`,
  flexDirection: `column`,
  height: `100dvh`,
  opacity: 1,
  transition: `opacity 0.25s ease-in-out, transform 0.25s ease-in-out`,
  width: mdsSidebarWidths().expanded,
  flexShrink: 0,
  zIndex: ZIndex.Sidebar,
});

const SidebarTopWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBlock: mdsSpacings().sm,
  paddingInline: mdsSpacings().md,
  marginBottom: mdsSpacings().md,
});

const SidebarMainWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  paddingLeft: mdsSpacings().md,
  paddingRight: mdsSpacings().md,
});

const AccountInfoWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: mdsSpacings().xs,
  padding: mdsSpacings().sm,
});

const dropdownStyles = css({
  minWidth: `calc(${mdsSidebarWidths().expanded}px - ${mdsSpacings().md} * 2)`,
});

const logoStyles = css({
  height: 20,
});
