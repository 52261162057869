import { observer } from "mobx-react-lite";
import { FC, FormEvent, useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { appRoutes } from "@/app/router";
import { AuthCardBase } from "@/components/auth/AuthCardBase";
import { authCardStyles } from "@/components/auth/utils/authCardStyles";
import { MdsInput } from "@/design-system/components/input/MdsInput";
import { css } from "@/domains/emotion";
import { MdsButton } from "@/design-system/components/button";
import { usePublicAppStore } from "@/store";

export interface AuthCardLogInProps {}

export const AuthCardEmailPassword: FC<AuthCardLogInProps> = observer(() => {
  const { publicStore } = usePublicAppStore();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault;
    await publicStore.auth.authenticateAccountUsingEmailPassword({ email, password });
  };

  const isLoading = publicStore.auth.authenticateAccountUsingEmailPasswordState.isLoading;

  return (
    <AuthCardBase title="Log in to Mem">
      <div className={formStyles}>
        <MdsInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          disabled={isLoading}
        />
        <MdsInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          disabled={isLoading}
        />
        <MdsButton label="Login" isLoading={isLoading} onClick={handleLogin} />
      </div>
      <div className={authCardStyles.altText}>
        <span>Don&apos;t have an account?</span>{" "}
        <span>
          <Link className={authCardStyles.link} to={appRoutes.signUp({}).path}>
            Sign up
          </Link>
        </span>
      </div>
    </AuthCardBase>
  );
});

const formStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  margin: "24px 0",
  width: "100%",
});
