import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { MdsPanelResponsive } from "@/design-system/components/panel";
import { MdsTooltipConfig } from "@/design-system/components/tooltip";

interface Props {
  fullSizeLabel: string;
  fullSizeIcon?: MdsIconKind;
  collapsedIcon: MdsIconKind;
  collapsedTooltipConfig?: MdsTooltipConfig;
  threshold: string;
  onClick: () => void;
}

// This component is used to render a button that switches between a button and an icon button based on the container width
export function MdsPanelResponsiveBtn({
  fullSizeLabel,
  fullSizeIcon,
  collapsedIcon,
  collapsedTooltipConfig,
  threshold,
  onClick,
}: Props) {
  return (
    <>
      <MdsPanelResponsive minWidth={threshold}>
        <MdsButton
          onClick={onClick}
          label={fullSizeLabel}
          iconKind={fullSizeIcon}
          size={MdsButtonSize.Small}
          variant={MdsButtonVariant.Tertiary}
        />
      </MdsPanelResponsive>
      <MdsPanelResponsive maxWidth={threshold}>
        <MdsIconButton
          onClick={onClick}
          iconKind={collapsedIcon}
          size={MdsIconButtonSize.Small}
          variant={MdsIconButtonVariant.Transparent}
          tooltipConfig={collapsedTooltipConfig}
        />
      </MdsPanelResponsive>
    </>
  );
}
