import { BackendApiClientPaths } from "@/manager/backend-api-client/types";
import { clientEnvModule } from "@/modules/client-env";
import createClient from "openapi-fetch";

export const getBackendBaseUrl = () => {
  const isProd = clientEnvModule.isProd();
  const localBackendApiIsEnabled = clientEnvModule.localBackendApiIsEnabled();

  if (localBackendApiIsEnabled) {
    return "http://localhost:8000/api";
  }

  if (isProd) {
    return "https://backend-prod.mem.engineering/api";
  }

  /**
   * Note that for now, we actually always just return the
   * production endpoint.
   *
   * In the future, once staging is in a solid state, we will
   * plan to have the frontend default to hitting the staging
   * backend.
   *
   * For now, we use production because it is expected (eg. matches
   * how we develop with mem-api, firestore, etc.)
   */
  return "https://backend-prod.mem.engineering/api";
  /**
   * Staging Endpoint:
   *   return "https://backend-stg.mem.engineering/api";
   */
};

export const initializeBackendApiClient = () => {
  const baseUrl = getBackendBaseUrl();

  const apiClient = createClient<BackendApiClientPaths>({
    baseUrl,
  });

  return apiClient;
};
