import { makeObservable, observable } from "mobx";

import { Uuid } from "@/domains/global/identifiers";

export class SpaceObservable {
  id: string;
  title: string;
  photoUrl: string | null;

  constructor({ id, title, photoUrl }: { id: Uuid; title: string; photoUrl?: string | null }) {
    this.id = id;
    this.title = title;
    this.photoUrl = photoUrl ?? null;

    makeObservable(this, {
      id: observable,
      title: observable,
      photoUrl: observable,
    });
  }

  static fromApiData({
    data,
  }: {
    data: {
      id: Uuid;
      title: string;
      photo_url?: string | null;
    };
  }) {
    return new SpaceObservable({
      id: data.id,
      title: data.title,
      photoUrl: data.photo_url,
    });
  }
}
