import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntrySpaceAccount,
  SyncModelPermissionEntryStatus,
} from "@/domains/sync-scopes/types";
import { useAppStore } from "@/store";
import { ShareSheetPermissionRowLayout } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetPermissionRowLayout";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { MdsTextOverflow } from "@/design-system/components/text/types";

interface ShareSheetSpaceAccountPermissionRowProps {
  permission: SyncModelPermissionEntrySpaceAccount & SyncModelPermissionEntryStatus;
  isLoading: boolean;
  canUpdateAccess: boolean;
  canRevokeAccess: boolean;
  handleRevokeAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleUpdateAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleCancelOperation: (syncOperationId: string) => void;
  shareSheetModalStore: ShareSheetModalStore;
}

export const ShareSheetSpaceAccountPermissionRow =
  observer<ShareSheetSpaceAccountPermissionRowProps>(
    ({
      permission,
      isLoading,
      canUpdateAccess,
      canRevokeAccess,
      handleRevokeAccess,
      handleUpdateAccess,
      handleCancelOperation,
      shareSheetModalStore,
    }) => {
      const { store } = useAppStore();
      const spaceAccountId = permission.space_account_id;
      const spaceAccountObservable = store.spaceAccounts.getSpaceAccount(spaceAccountId);
      const contact = store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);

      if (spaceAccountObservable) {
        const { profileDisplayName, profileEmailAddress } = spaceAccountObservable;
        return (
          <ShareSheetPermissionRowLayout
            permission={permission}
            isLoading={isLoading}
            canUpdateAccess={canUpdateAccess}
            canRevokeAccess={canRevokeAccess}
            handleRevokeAccess={noop}
            handleUpdateAccessToReadOnly={noop}
            handleUpdateAccessToReadWrite={noop}
            handleCancelOperation={noop}
          >
            <div className={container}>
              <div className={profilePhoto}>
                <AccountProfileImage
                  size={ProfileSize.Large}
                  profile={{
                    kind: ProfileKind.Me,
                  }}
                />
              </div>
              <MdsText size={MdsTextSize.Small} overflow={MdsTextOverflow.EllipsisWrap}>
                {profileDisplayName}
                {profileEmailAddress && (
                  <span className={secondaryTextStyles}> ∙ {profileEmailAddress}</span>
                )}
              </MdsText>
            </div>
          </ShareSheetPermissionRowLayout>
        );
      }

      if (contact) {
        return (
          <ShareSheetPermissionRowLayout
            permission={permission}
            isLoading={isLoading}
            canUpdateAccess={canUpdateAccess}
            canRevokeAccess={canRevokeAccess}
            handleRevokeAccess={() => {
              if (
                shareSheetModalStore.collection &&
                shareSheetModalStore.collection.sharedWithSpaceAccountsCount === 1
              ) {
                shareSheetModalStore.makeCollectionPrivateModal.open({
                  collectionId: shareSheetModalStore.collection.id,
                  targetSpaceAccountId: spaceAccountId,
                });
              } else {
                handleRevokeAccess({
                  scope_kind: "SPACE_ACCOUNT_SCOPE",
                  space_account_id: spaceAccountId,
                  role_kind: "REVOKED",
                });
              }
            }}
            handleUpdateAccessToReadOnly={() =>
              handleUpdateAccess({
                scope_kind: "SPACE_ACCOUNT_SCOPE",
                space_account_id: spaceAccountId,
                role_kind: "VIEWER",
              })
            }
            handleUpdateAccessToReadWrite={() =>
              handleUpdateAccess({
                scope_kind: "SPACE_ACCOUNT_SCOPE",
                space_account_id: spaceAccountId,
                role_kind: "EDITOR",
              })
            }
            handleCancelOperation={handleCancelOperation}
          >
            <div className={container}>
              <div className={profilePhoto}>
                <AccountProfileImage
                  size={ProfileSize.Large}
                  profile={{
                    kind: ProfileKind.Contact,
                    data: contact,
                  }}
                />
              </div>
              <MdsText
                size={MdsTextSize.Small}
                overflow={MdsTextOverflow.EllipsisWrap}
                numberOfLines={2}
              >
                {contact.profileDisplayName}
                {contact.profileEmailAddress && (
                  <span className={secondaryTextStyles}> ∙ {contact.profileEmailAddress}</span>
                )}
              </MdsText>
            </div>
          </ShareSheetPermissionRowLayout>
        );
      }

      return null;
    }
  );

const container = css({
  display: "flex",
  alignItems: "center",
  minWidth: 0,
});

const profilePhoto = css({
  marginRight: "8px",
});

const secondaryTextStyles = css({
  color: "rgb(104, 110, 125)",
});
