import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export const deleteCollectionPermanently = async ({
  collection,
  store,
}: {
  collection: CollectionObservable;
  store: AppStore;
}) => {
  store.collections.deleteCollection({ collectionId: collection.id });
  store.navigation.goToNotes();
  toastModule.triggerToast({
    content: <div>Collection deleted permanently</div>,
  });
};
