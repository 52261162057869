import styled from "@emotion/styled";
import { MdsCard, MdsCardKind, MdsCardProps, MdsCardRadius } from "@/design-system/components/card";
import { observer } from "mobx-react-lite";
import { MemCommonMarkdown, MemCommonMarkdownProps } from "@mem-labs/common-editor";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { AppStore, useAppStore } from "@/store";
import { uuidModule } from "@/modules/uuid";
import { SpaceAccountChatMessageSection } from "@/store/chat/types";
import { ChatMessageSection } from "@/domains/chat";
import { NoteSavedIndicator } from "@/components/messages/item/section/draft-note/note-saved-indicator";
import { copyNote } from "@/modules/copy-note";
import { useCallback, useState } from "react";

interface Props {
  section: ChatMessageSection;
  spaceAccountSection?: SpaceAccountChatMessageSection;
  inSidePanel?: boolean;
  isProcessing: boolean;
  isIncomplete?: boolean;
  dataTestId?: string;
}

export const DraftNote = observer(function ({
  section,
  spaceAccountSection,
  inSidePanel,
  isProcessing,
  dataTestId,
  isIncomplete,
}: Props): JSX.Element {
  const { content } = section.value;
  const savedAsNoteId = spaceAccountSection?.saved_as_note_id;
  const { store } = useAppStore();

  const [saveDraftNotePressed, setSaveDraftNotePressed] = useState(false);
  const onSave = useCallback(() => setSaveDraftNotePressed(true), []);
  const showSavedIndicator = saveDraftNotePressed && savedAsNoteId;

  return (
    <Wrapper
      kind={MdsCardKind.SmallShadow}
      radius={MdsCardRadius.Large}
      dataTestId={`${dataTestId}-draft-note`}
    >
      <Markdown isIncomplete={isIncomplete}>{content}</Markdown>
      <BottomRowWrapper>
        {showSavedIndicator ? <NoteSavedIndicator /> : <div />}
        {!isProcessing && (
          <Buttons>
            <MdsButton
              label="Copy"
              onClick={() => copyNote(content)}
              iconKind={MdsIconKind.Copy}
              variant={MdsButtonVariant.Outlined}
              data-testid={`${dataTestId}-copy-button`}
            />
            {savedAsNoteId ? (
              <MdsButton
                label="Open Note"
                onClick={() =>
                  inSidePanel
                    ? store.sidePanel.goToNote({ noteId: savedAsNoteId })
                    : store.navigation.goToNote({ noteId: savedAsNoteId })
                }
                iconKind={MdsIconKind.ArrowUpRight}
                variant={MdsButtonVariant.FilledDark}
                data-testid={`${dataTestId}-saved-button`}
              />
            ) : (
              <MdsButton
                label="Save"
                onClick={() => saveNote({ store, section, onSave })}
                iconKind={MdsIconKind.Bookmark}
                variant={MdsButtonVariant.Outlined}
                data-testid={`${dataTestId}-save-button`}
              />
            )}
          </Buttons>
        )}
      </BottomRowWrapper>
    </Wrapper>
  );
});

const Wrapper = styled(MdsCard)<MdsCardProps>(({ theme }) => ({
  padding: theme.spacing.md,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  maxWidth: "600px",
  width: "100%",
}));

const BottomRowWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));

interface MarkdownProps extends MemCommonMarkdownProps {
  isIncomplete?: boolean;
}

const Markdown = styled(MemCommonMarkdown)<MarkdownProps>(({ theme, isIncomplete }) => ({
  width: "100%",
  color: isIncomplete ? theme.colors.grey.x500 : theme.colors.grey.x600,
}));

const Buttons = styled.div(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-end",
  gap: theme.spacing.sm,
}));

const saveNote = ({
  store,
  section,
  onSave,
}: {
  store: AppStore;
  section: ChatMessageSection;
  onSave: () => void;
}) => {
  const noteId = uuidModule.generate();
  store.chatMessages.saveDraftNoteSection({
    sectionId: section.id,
    chatMessageId: section.chat_message_id,
    noteId,
  });
  onSave();
};
