import { useFloatingTooltipContext } from "@/design-system/components/tooltip/utils/useFloatingTooltipContext";
import { FloatingPortal, useMergeRefs } from "@floating-ui/react";
import React from "react";
import { AnimatePresence } from "framer-motion";

import { css } from "@/domains/emotion";
import { ZIndex } from "@/domains/design/constants";
import { SpringAnimator } from "@/design-system/components/animation";

/**
 * Implementation heavily inspired from the floating-ui example docs here:
 * - https://floating-ui.com/docs/tooltip
 *
 * Prefer to use MdsTooltip directly, instead of using this.
 */
export const FloatingTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useFloatingTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  return (
    <AnimatePresence>
      {context.open && (
        <FloatingPortal>
          <div
            ref={ref}
            style={{
              zIndex: ZIndex.FloatingTooltip,
              ...context.floatingStyles,
            }}
          >
            <SpringAnimator
              className={baseTooltipContentWrapperStyles}
              transitionOpenDelay={context.transitionDelay}
            >
              <div
                style={{
                  ...style,
                }}
                {...context.getFloatingProps(props)}
              />
            </SpringAnimator>
          </div>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
});

const baseTooltipContentWrapperStyles = css({});
