import { ChatMessage } from "@/domains/chat/message/message";
import { getChatItems } from "@/store/chat/getChatItems";
import { AppStore } from "@/store/AppStore";
import { ChatMessageContext, ChatItem } from "@/store/chat/types";
import { makeObservable, observable, action } from "mobx";
import { MentionChip } from "@/pages/chat/ChatInput";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { MENTION_PREFIX_COLLECTION, MENTION_PREFIX_NOTE } from "@/store/chat/constants";
import { SearchSuggestion, SearchSuggestions } from "@/domains/search";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { getCollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { Maybe } from "@/domains/common/types";
import styled from "@emotion/styled";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { uuidModule } from "@/modules/uuid";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note/note";
import { noop } from "lodash-es";
import { css } from "@/domains/emotion";
import { mdsFontSizes } from "@/design-system/foundations";
import { generateRecentDateString } from "@/domains/date/date";
import { DateTime } from "luxon";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { notesModule } from "@/modules/notes";
import { NoteObservable } from "@/store/note/NoteObservable";

export class ChatHistory {
  private store: AppStore;
  private searchSuggestions = new SearchSuggestions();

  context?: ChatMessageContext;
  items: ChatItem[] = [];

  constructor({
    allMessages,
    context,
    store,
  }: {
    allMessages: ChatMessage[];
    context?: ChatMessageContext;
    store: AppStore;
  }) {
    this.context = context;
    this.store = store;

    this.items = getChatItems(allMessages);

    makeObservable<ChatHistory, "store" | "searchSuggestions">(this, {
      store: false,
      searchSuggestions: false,
      getAvailableChips: false,
      context: observable,
      items: observable,
      submitChatMessage: action,
    });
  }

  getAvailableChips = (
    mentionQuery: string,
    opts?: { inlineCreation?: boolean }
  ): MentionChip[] => {
    const mentionChar = mentionQuery[0];
    const isSearchingCollections = mentionQuery.startsWith("#");
    const iconKind = isSearchingCollections ? MdsIconKind.Collection : MdsIconKind.Document;

    const mentionQueryText = mentionQuery.slice(1).trim();
    const lowercaseMentionQueryText = mentionQueryText.toLowerCase();

    const MAX_RESULTS = 100;

    let addCreateNew = !!opts?.inlineCreation && !!lowercaseMentionQueryText.length;

    const getDropdownButtonContentForNote = (
      id: string,
      label: string
    ): Maybe<{
      isOwnedByMe: NoteObservable["isOwnedByMe"];
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
    }> => {
      const note = this.store.notes.get(id);
      if (note) {
        const subtitle = generateRecentDateString(
          DateTime.fromISO(note.lastMentionedAt || note.lastViewedAt || note.createdAt || ""),
          { skipFullDayName: true }
        );
        return {
          isOwnedByMe: note.isOwnedByMe,
          iconKind: () => <NoteIcon toggleSelected={noop} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {note.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>{subtitle}</MentionSubtitle>
            </MentionContent>
          ),
        };
      }
    };

    const getDropdownButtonContentForCollection = (
      id: string,
      label: string
    ): Maybe<{
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
      isOwnedByMe: CollectionObservable["isOwnedByMe"];
    }> => {
      const collection = this.store.collections.get(id);
      if (collection) {
        return {
          iconKind: () => <CollectionIcon collectionId={id} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {collection.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>
                {getCollectionItemSubtitle(collection.itemList.allItems.length)}
              </MentionSubtitle>
            </MentionContent>
          ),
          isOwnedByMe: collection.isOwnedByMe,
        };
      }
    };

    const searchSuggestions = (
      items: SearchSuggestion[],
      getExtraInfo?: typeof getDropdownButtonContentForCollection
    ) => {
      const suggestions = this.searchSuggestions.search(
        lowercaseMentionQueryText,
        items.filter(e => e.id !== this.context?.id)
      );
      const exactMatchIndex = suggestions.findIndex(
        suggestion => suggestion.label.trim().toLowerCase() === lowercaseMentionQueryText
      );
      if (exactMatchIndex >= 0) {
        const exactMatch = suggestions[exactMatchIndex];
        suggestions.splice(exactMatchIndex, 1);
        suggestions.unshift(exactMatch);
      }
      suggestions.slice(0, MAX_RESULTS).forEach(suggestion => {
        const { id, label } = suggestion;
        const { iconKind: icon, content, isOwnedByMe } = getExtraInfo?.(id, label) ?? {};
        chips.push({
          id,
          label,
          iconKind: icon ?? iconKind,
          content,
        });
        if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
          addCreateNew = false;
        }
      });
    };

    const chips: MentionChip[] = [];
    switch (mentionChar) {
      case MENTION_PREFIX_NOTE: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentNotesInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, MAX_RESULTS)
            .forEach(collection => {
              const { id, title } = collection;
              const label = title || UNTITLED_NOTE_TITLE;
              const {
                iconKind: icon,
                content,
                isOwnedByMe,
              } = getDropdownButtonContentForNote(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
              if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
                addCreateNew = false;
              }
            });
          break;
        }

        searchSuggestions(this.store.notes.allDataForMention, getDropdownButtonContentForNote);
        break;
      }
      case MENTION_PREFIX_COLLECTION: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentCollectionsInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, 5)
            .forEach(collection => {
              const { id, title } = collection;
              const label = title || UNTITLED_COLLECTION_TITLE;
              const { iconKind: icon, content } =
                getDropdownButtonContentForCollection(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
            });
          break;
        }

        searchSuggestions(
          this.store.collections.allDataForMention,
          getDropdownButtonContentForCollection
        );
        break;
      }
    }
    const exactMatchIndex = chips.findIndex(
      chip => chip.label.trim().toLowerCase() === lowercaseMentionQueryText
    );
    if (exactMatchIndex >= 0) {
      const exactMatch = chips[exactMatchIndex];
      chips.splice(exactMatchIndex, 1);
      chips.unshift(exactMatch);
    }
    if (addCreateNew) {
      const id = uuidModule.generate();
      chips.push({
        alwaysVisible: true,
        id: id,
        label: mentionQueryText,
        iconKind: () => (
          <PlusIconWrapper>
            <MdsIcon kind={MdsIconKind.Plus} />
          </PlusIconWrapper>
        ),
        content: () => (
          <MentionContent>
            <MentionTitle>“{mentionQueryText}”</MentionTitle>
            <MentionSubtitle>
              Create new {isSearchingCollections ? "collection" : "note"}
            </MentionSubtitle>
          </MentionContent>
        ),
        beforeSelection: () => {
          if (isSearchingCollections) {
            this.store.collections.createCollection({
              collectionId: id,
              title: mentionQueryText,
              description: "",
            });
            return;
          }
          this.store.notes.createNote({
            noteId: id,
          });
          const encodedContent = notesModule.convertMarkdownToEncodedContent(
            "# " + mentionQueryText
          );
          new UpdateNoteContentUsingDiffOperation({
            store: this.store,
            payload: {
              id,
              encoded_content_diff: encodedContent || "",
            },
            primaryLabel: mentionQueryText,
            secondaryLabel: "",
          }).execute();
        },
      });
    }
    return chips;
  };

  submitChatMessage = async ({ markdownContent }: { markdownContent: string }) => {
    if (!markdownContent.trim()) return;

    await this.store.chatMessages.sendNewMessage(markdownContent, this.context);
  };
}

const MentionContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const MentionTitle = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey.x600,
  display: "flex",
  gap: theme.spacing.sm,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const SmallerIcon = styled(MdsIcon)({
  height: 12,
  width: 12,
});

const smallerIconFontSizeStyles = css({
  fontSize: mdsFontSizes().xxsmall,
  width: 12,
});

const MentionSubtitle = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const PlusIconWrapper = styled.div({
  width: 40,
  height: 40,
  // borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
