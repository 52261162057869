import { MdsSharedByVariant } from "@/design-system/components/item-list/types";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { asFirstName } from "@/domains/string/name";
import { observer } from "mobx-react-lite";
import { ACCOUNT_PROFILE_DISPLAY_NAME_FALLBACK } from "@/domains/common/constants";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import styled from "@emotion/styled";
import { ContactObservable } from "@/store/contacts/ContactObservable";

export interface MdsItemListItemSharedByBoxProps {
  sharedBy?: ContactObservable;
  sharedByVariant?: MdsSharedByVariant;
}

export const MdsItemListItemSharedByBox = observer(
  ({
    sharedBy,
    sharedByVariant = MdsSharedByVariant.SharedBy,
  }: MdsItemListItemSharedByBoxProps) => {
    const displayName = sharedBy?.profileDisplayName ?? ACCOUNT_PROFILE_DISPLAY_NAME_FALLBACK;

    const sharingFirstNameText =
      sharedByVariant === "SHARED_BY"
        ? `Shared by ${asFirstName(displayName)}`
        : `Added by ${asFirstName(displayName)}`;

    const sharingFullText =
      sharedByVariant === "SHARED_BY" ? `Shared by ${displayName}` : `Added by ${displayName}`;

    if (!sharedBy) {
      return null;
    }

    return (
      <Wrapper>
        <Container title={sharingFullText}>
          <AccountProfileImage
            size={ProfileSize.Small}
            profile={{
              kind: ProfileKind.Contact,
              data: sharedBy,
            }}
          />

          <Text>{sharingFirstNameText}</Text>
        </Container>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  alignItems: "center",
  display: "flex",
  marginLeft: "16px",
  minWidth: "140px",
  marginTop: "2px",
  justifyContent: "end",
  "@container (max-width: 575px)": {
    minWidth: "24px",
  },
});

const Container = styled.div({
  fontSize: "12px",
  lineHeight: "16px",
  color: "#696e7d",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "4px",
  borderRadius: "4px",
  gap: "8px",
  "@container (max-width: 575px)": {
    gap: "0px",
  },
});

const Text = styled.div({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  "@container (max-width: 575px)": {
    width: "0",
  },
});
