import { RegisterOptions } from "react-hook-form";

export interface SettingsDebugAccountInfoFormData {
  password: string;
}

const passwordFieldOptions: RegisterOptions<SettingsDebugAccountInfoFormData> = {
  validate: value => {
    if (value.trim()) {
      return true;
    }

    return "Password can't be blank";
  },
  minLength: {
    value: 8,
    message: "Password must be at least 8 characters.",
  },
  maxLength: {
    value: 64,
    message: "Password must be less than 65 characters.",
  },
};

export const settingsDebugAccountInfoFormConfig = {
  passwordFieldOptions,
};
