import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import React, { FC } from "react";

export interface MdsLinkButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
}

export const MdsLinkButton: FC<MdsLinkButtonProps> = ({ children, onClick, isDisabled }) => {
  return (
    <span className={css(link, isDisabled && disabled)} onClick={onClick}>
      {children}
    </span>
  );
};

const link = css({
  position: "relative",
  padding: 0,
  cursor: "pointer",
  fontWeight: 500,
  display: "inline-flex",
  alignItems: "center",
  "&> *": {
    margin: "2px",
  },
  "&:hover": {
    color: mdsColors().blue.x400,
    textDecoration: "underline",
  },
  "&> svg": {
    padding: 0,
    width: "16px",
    height: "16px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const disabled = css({
  color: mdsColors().grey.x400,
  cursor: "default",
  "&:hover": {
    color: mdsColors().grey.x400,
    textDecoration: "none",
  },
});
