import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsDropdownDividerItem } from "@/design-system/components/dropdown";
import { mdsColors } from "@/design-system/foundations/colors";

export interface MdsDropdownDividerItemComponentProps {
  className?: string;
  item: MdsDropdownDividerItem;
}

const wrapperStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "center",
  padding: "4px 6px",
});

const dividerStyles = css({
  width: "100%",
  height: 1,
  backgroundColor: mdsColors().grey.x100,
});

export const MdsDropdownDividerItemComponent: FC<MdsDropdownDividerItemComponentProps> = ({
  className,
  item,
}) => {
  return (
    <div className={cx(className, wrapperStyles, item.className)}>
      <div className={dividerStyles} />
    </div>
  );
};
