import styled from "@emotion/styled";
import { ChatMessageSection } from "@/domains/chat";
import { Text } from "@/components/messages/item/section/text";
import { DraftNote } from "@/components/messages/item/section/draft-note";
import { FactCard } from "@/components/messages/item/section/fact-card";
import { SpaceAccountChatMessageSection } from "@/store/chat/types";
import { SectionWrapper } from "@/components/messages/item/section/section-wrapper";
import { UserMessage } from "@/components/messages/item/section/user-message";

interface Props {
  spaceAccountSection?: SpaceAccountChatMessageSection;
  section: ChatMessageSection;
  isSystemMessage: boolean;
  inSidePanel?: boolean;
  dataTestId?: string;
  isIncomplete?: boolean;
}

export function Section({
  section,
  spaceAccountSection,
  isSystemMessage,
  inSidePanel,
  dataTestId: rootTestId,
  isIncomplete,
}: Props) {
  const dataTestId = `${rootTestId}-section`;

  const isProcessing = isSystemMessage && section.status === "PROCESSING";

  if (isProcessing && !section.value.content) return null;

  return (
    <SectionWrapper isProcessing={isProcessing} isSystemMessage={isSystemMessage}>
      <Content>
        {!isSystemMessage && <UserMessage data={section.value} />}
        {isSystemMessage && section.kind === "TEXT" && (
          <Text data={section.value} isIncomplete={isIncomplete} dataTestId={dataTestId} />
        )}
        {isSystemMessage && section.kind === "FACT_CARD" && (
          <FactCard data={section.value} isIncomplete={isIncomplete} dataTestId={dataTestId} />
        )}
        {isSystemMessage && section.kind === "DRAFT_NOTE" && (
          <DraftNote
            isProcessing={isProcessing}
            dataTestId={dataTestId}
            section={section}
            spaceAccountSection={spaceAccountSection}
            inSidePanel={inSidePanel}
            isIncomplete={isIncomplete}
          />
        )}
      </Content>
    </SectionWrapper>
  );
}

const Content = styled.div(_ => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));
