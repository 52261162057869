import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { MdsModal } from "@/design-system/components/modal";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { css } from "@/domains/emotion";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { NoteChip } from "@/components/chips/NoteChip";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { truncate } from "@/modules/truncate";

interface AddToCollectionModalProps {
  store: AddToCollectionModalStore;
}

export const AddToCollectionModal = observer<AddToCollectionModalProps>(({ store }) => {
  const title = store.selectedNote?.title || UNTITLED_NOTE_TITLE;
  const extraInfo =
    store.selectedNoteIds.length > 1
      ? ` and ${store.selectedNoteIds.length - 1} other note${store.selectedNoteIds.length > 2 ? "s" : ""} `
      : " ";
  const [noteTitle, setNoteTitle] = useState(title);
  const [extraNotesInfo, setExtraNotesInfo] = useState(title);

  useEffect(() => {
    if (store.isOpen) {
      setNoteTitle(title);
      setExtraNotesInfo(extraInfo);
      store.setSearchQuery("");
    }
  }, [extraInfo, store.isOpen, title, store]);

  return (
    <MdsModal isModalOpen={store.isOpen} handleCloseModal={() => store.close()} showOverflow={true}>
      <div className={containerStyles} onKeyDown={store.onKeyDown}>
        <div className={containerTopStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Organize{" "}
              <NoteChip inline title={truncate(noteTitle, !extraNotesInfo.trim() ? 25 : 10)} />{" "}
              {extraNotesInfo}
            </MdsText>
          </div>
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
        </div>
        <div>
          <CollectionsSearchField store={store} />
        </div>
      </div>
    </MdsModal>
  );
});

const CollectionsSearchField = observer<AddToCollectionModalProps>(({ store }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => inputRef.current?.focus(), []);
  return (
    <>
      <div className={searchContainerStyles}>
        <MdsIcon kind={MdsIconKind.Search} />
        <input
          ref={inputRef}
          className={collectionsSearchInputStyles}
          value={store.searchQuery}
          onChange={e => store.setSearchQuery(e.target.value)}
          placeholder="Add to existing or new collection"
        />
      </div>
      <div className={dropdownStyles}>
        {store.dropdownContentList.items.length > 0 && (
          <MdsDropdownContent
            contentList={store.dropdownContentList}
            className={dropdownWidthStyles}
          />
        )}
      </div>
    </>
  );
});

const containerStyles = css({
  display: "flex",
  flexDirection: "column",
  padding: 30,
  gap: 8,
  width: "min(600px, 90vw)",
});

const containerTopStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const dropdownStyles = css({
  position: "absolute",
  // 60 is the padding of the container
  width: "calc(100% - 60px)",
  marginTop: 8,
});

const searchContainerStyles = css({
  display: "flex",
  alignItems: "center",
  borderRadius: 6,
  gap: 8,
  padding: "8px 8px 8px 16px",
  background: "#F8F8FA",
  border: "1px solid #E8E9ED",
  cursor: "text",
  ":hover": {
    background: "#F3F3F5",
  },
});

const collectionsSearchInputStyles = css({
  width: "100%",
  background: "transparent",
  fontSize: 16,
  lineHeight: "32px",
  fontWeight: 500,
});

const dropdownWidthStyles = css({
  width: "100%",
});
