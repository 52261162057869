import { mdsMediaQueries } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface TrialInfoBadgeIconWithLabelProps extends EmotionClassStyles {
  label?: string;
}

export const TrialInfoBadgeIconWithLabel: FC<TrialInfoBadgeIconWithLabelProps> = observer(
  ({ className, label }) => {
    return (
      <div className={cx(badgeStyles, className)}>
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.24219 3.5H5.35156C7.20312 0.429688 10.0859 0.3125 11.8203 0.640625C12.1016 0.6875 12.3125 0.898438 12.3594 1.17969C12.6875 2.91406 12.5703 5.79688 9.5 7.64844V9.75781C9.5 10.3438 9.17188 10.9062 8.65625 11.2109L6.59375 12.4297C6.40625 12.5234 6.19531 12.5469 6.03125 12.4297C5.84375 12.3359 5.75 12.1484 5.75 11.9375V9.26562C5.75 8.72656 5.53906 8.21094 5.16406 7.83594C4.78906 7.46094 4.27344 7.25 3.73438 7.25H1.0625C0.851562 7.25 0.664062 7.15625 0.570312 6.96875C0.453125 6.80469 0.453125 6.59375 0.570312 6.40625L1.78906 4.34375C2.09375 3.82812 2.65625 3.5 3.24219 3.5ZM9.5 2.5625C8.96094 2.5625 8.5625 2.98438 8.5625 3.5C8.5625 4.03906 8.96094 4.4375 9.5 4.4375C10.0156 4.4375 10.4375 4.03906 10.4375 3.5C10.4375 2.98438 10.0156 2.5625 9.5 2.5625ZM4.39062 11.5156C3.24219 12.6875 0.5 12.5 0.5 12.5C0.5 12.5 0.3125 9.75781 1.46094 8.60938C2.28125 7.8125 3.59375 7.8125 4.39062 8.60938C5.1875 9.40625 5.1875 10.7188 4.39062 11.5156ZM3.28906 9.71094C3.03125 9.42969 2.58594 9.42969 2.32812 9.71094C1.92969 10.0859 2 11 2 11C2 11 2.91406 11.0703 3.28906 10.6719C3.57031 10.4141 3.57031 9.96875 3.28906 9.71094Z"
            fill="url(#paint0_linear_3442_114766)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3442_114766"
              x1="10.4101"
              y1="-1.09409e-06"
              x2="2.28652"
              y2="12"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EB2487" />
              <stop offset="1" stopColor="#F93939" />
            </linearGradient>
          </defs>
        </svg>
        <span className={labelStyles}>{label}</span>
      </div>
    );
  }
);

const badgeStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  background: "#FCF2F6",
  borderRadius: 4,
  padding: "4px 8px",
  fontSize: 12,
  fontWeight: 600,
  color: "#323640",
  userSelect: "none",
  whiteSpace: "nowrap",
  "&:hover": {
    background: "#ffe5ee",
  },
  transition: "0.08s",
});

const labelStyles = css({
  [mdsMediaQueries().rules.phone]: {
    display: "none",
  },
});
