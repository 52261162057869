import { cx } from "@/domains/emotion";
import styled from "@emotion/styled";
import { MdsItemListSectionHeaderRowData } from "@/design-system/components/item-list/types";

interface Props {
  data: MdsItemListSectionHeaderRowData;
  className?: string;
}

export const MdsItemListSectionHeaderRow = ({ data, className }: Props) => {
  const { icon, title, description } = data.payload;

  const primary = typeof title === "string" ? title : title.primary;
  const secondary = typeof title === "string" ? "" : title.secondary;
  const key = typeof title === "string" ? title : `${title.primary}::${title.secondary}`;
  const hasStrDescription = typeof description === "string";

  return (
    <Wrapper className={cx(className)} key={key}>
      {icon && <span>{icon}</span>}
      <PrimaryText>{primary}</PrimaryText>
      {secondary && <SecondaryText>{secondary}</SecondaryText>}
      {hasStrDescription ? <SecondaryText>{description}</SecondaryText> : description}
    </Wrapper>
  );
};

const Wrapper = styled.header(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.small,
  padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm}`,
  userSelect: "none",
}));

const PrimaryText = styled.span(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: "-0.01em",
  color: theme.colors.grey.x600,
  lineHeight: theme.lineHeights.small,
}));

const SecondaryText = styled.span(({ theme }) => ({
  marginLeft: theme.spacing.xs,
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: "-0.01em",
  color: theme.colors.grey.x600,
}));
