import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { orderBy } from "lodash-es";

export function sortCollectionsForChips(
  collections: CollectionObservable[]
): CollectionObservable[] {
  const privateCollections = collections.filter(collection => !collection.isShared);
  const sharedCollections = collections.filter(collection => collection.isShared);
  const sorted = [
    // Private collections sorted by "last added to by me" timestamp
    ...orderBy(
      privateCollections,
      collection => collection.spaceAccountCollection?.lastAddedToAt,
      "desc"
    ),
    // Shared collections sorted by "last added to by anyone" timestamp
    ...orderBy(sharedCollections, collection => collection.lastAddedToAt, "desc"),
  ];
  return sorted;
}
