import { moduleFactory } from "@/factories/module";
import { GLOBAL_LOGGER_INSTANCE } from "@/modules/logger/globalLoggerInstance";

const loggerModule = moduleFactory.create({
  ...GLOBAL_LOGGER_INSTANCE,
});

/**
 * We use shorthand for this module, because it is used so often.
 */
export const logger = loggerModule;
