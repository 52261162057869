import { pickBy } from "lodash-es";

/**
 * Remove any keys with undefined values from an object
 */
export const _compactObjectUndefined = <TInputObject extends Record<string, unknown>>(
  inputObject: TInputObject
): {
  [key in keyof TInputObject]: undefined extends TInputObject[key]
    ? NonNullable<TInputObject[key]> | undefined
    : TInputObject[key];
} => {
  const result = pickBy(inputObject, val => val !== undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { ...result } as any;
};

/**
 * Remove any keys with null or undefined values from an object
 */
export const _compactObjectNullOrUndefined = <TInputObject extends Record<string, unknown>>(
  inputObject: TInputObject
): {
  [key in keyof TInputObject]: null extends TInputObject[key]
    ? NonNullable<TInputObject[key]> | undefined
    : undefined extends TInputObject[key]
      ? NonNullable<TInputObject[key]> | undefined
      : TInputObject[key];
} => {
  const result = pickBy(inputObject, val => val !== undefined && val !== null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { ...result } as any;
};
