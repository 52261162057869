import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionModelData } from "@/store/collections/types";
import { action, computed, makeObservable, observable, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";
import { CreateCollectionOperation } from "@/store/sync/operations/collections/CreateCollectionOperation";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { DeleteCollectionOperation } from "@/store/sync/operations/collections/DeleteCollectionOperation";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { generateSyncActionCollectionScopedPusherChannelKey } from "@/store/sync/utils";
import { PusherEventKind } from "@/domains/pusher/constants";
import { Channel } from "pusher-js";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";

export class AppStoreCollectionsStore extends BaseSyncModelStore<
  CollectionObservable,
  CollectionModelData
> {
  private collectionPusherChannels = new Map<string, Channel>();
  private searchTitleLimit = 100;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Collection, ...injectedDeps });
    makeObservable<
      this,
      "collectionPusherChannels" | "searchTitleLimit" | "getSearchableData" | "getSearchableSortKey"
    >(this, {
      collectionPusherChannels: observable,
      searchTitleLimit: observable,

      createSyncModel: false,
      getCollectionObservableById: false,
      getMatchingCollections: false,
      getMatchingSharedCollections: false,
      getSearchableData: false,
      getSearchableSortKey: false,

      processSyncUpdate: override,
      subscribeToCollection: action,
      unsubscribeFromCollection: action,

      allCollections: computed,
      createCollection: action,
      updateCollection: action,
      deleteCollection: action,
      grantAccessToCollection: action,
      updateAccessToCollection: action,
      revokeAccessToCollection: action,
      allDataForSearch: computed,
      allDataForMention: computed,
    });
  }

  createSyncModel(data: SyncUpdateValue<CollectionModelData>): CollectionObservable {
    return new CollectionObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public processSyncUpdate(update: SyncUpdate<CollectionModelData>): void {
    super.processSyncUpdate(update);
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED")
      this.subscribeToCollection(update.value.model_id);
    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED")
      this.unsubscribeFromCollection(update.value.model_id);
  }

  public subscribeToCollection(collectionId: string) {
    if (this.collectionPusherChannels.has(collectionId)) return;
    console.debug("[SYNC][AppStoreCollectionsStore] Subscribing to collection", collectionId);
    const collectionPusherChannelKey = generateSyncActionCollectionScopedPusherChannelKey({
      collectionId,
    });
    const channel = this.pusher.subscribe(collectionPusherChannelKey);
    channel.bind(PusherEventKind.SYNC_UPDATE_PUBLISHED, this.store.sync.queryForSyncActions);
    this.collectionPusherChannels.set(collectionId, channel);
  }

  public unsubscribeFromCollection(collectionId: string) {
    const channel = this.collectionPusherChannels.get(collectionId);
    if (channel)
      console.debug("[SYNC][AppStoreCollectionsStore] Unsubscribing from collection", collectionId);
    channel?.unsubscribe();
    this.collectionPusherChannels.delete(collectionId);
  }

  public getCollectionObservableById = ({
    collectionId,
  }: {
    collectionId?: Uuid;
  }): CollectionObservable | undefined => {
    return collectionId ? this.get(collectionId) : undefined;
  };

  public get allCollections(): CollectionObservable[] {
    return Array.from(this.pool.values());
  }

  public getMatchingCollections = (query: string) => {
    if (!query) return [];
    return this.allCollections.filter(collection =>
      collection.title?.toLowerCase().includes(query.toLowerCase())
    );
  };

  public getMatchingSharedCollections = (query: string): CollectionObservable[] => {
    if (!query) return [];
    return this.allCollections.filter(
      collection =>
        collection.isShared && collection.title?.toLowerCase().includes(query.toLowerCase())
    );
  };

  get allDataForSearch(): SearchSuggestion[] {
    return this.getSearchableData(false);
  }

  get allDataForMention(): SearchSuggestion[] {
    return this.getSearchableData(true);
  }

  // ACTIONS
  public createCollection({
    collectionId,
    title,
    description,
  }: {
    collectionId?: Uuid;
    title?: string;
    description?: string;
  }) {
    new CreateCollectionOperation({
      store: this.store,
      payload: {
        id: collectionId,
        title,
        description,
      },
    }).execute();
  }

  public updateCollection({
    collectionId,
    title,
    description,
  }: {
    collectionId: Uuid;
    title?: string;
    description?: string;
  }) {
    new UpdateCollectionOperation({
      store: this.store,
      payload: {
        id: collectionId,
        title,
        description,
      },
    }).execute();
  }

  public deleteCollection({ collectionId }: { collectionId: Uuid }) {
    new DeleteCollectionOperation({ store: this.store, payload: { id: collectionId } }).execute();
  }

  public grantAccessToCollection() {
    // TODO: Implement
    console.log("[AppStoreCollectionsStore] grantAccessToCollection - Not Implemented Yet");
  }

  public updateAccessToCollection() {
    // TODO: Implement
    console.log("[AppStoreCollectionsStore] updateAccessToCollection - Not Implemented Yet");
  }

  public revokeAccessToCollection() {
    // TODO: Implement
    console.log("[AppStoreCollectionsStore] revokeAccessToCollection - Not Implemented Yet");
  }

  private getSearchableData(includeMentionedAt: boolean): SearchSuggestion[] {
    return this.allCollections
      .filter(collection => collection.isAvailable)
      .map(collection => ({
        id: collection.id,
        label: collection.title?.slice(0, this.searchTitleLimit) || "",
        type: SearchSuggestionType.COLLECTION,
        lastViewedAt: collection.lastViewedAt,
        sortKey: this.getSearchableSortKey(collection, includeMentionedAt),
      }));
  }

  private getSearchableSortKey(
    collection: CollectionObservable,
    includeMentionedAt: boolean
  ): number {
    let sortKey = collection.lastViewedAt || collection.receivedAt;
    if (includeMentionedAt) {
      sortKey = collection.lastAddedToAt || collection.lastMentionedAt || sortKey;
    }

    return new Date(sortKey || collection.createdAt).getTime();
  }
}
