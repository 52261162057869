import { action, computed, makeObservable, observable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { WithAppStore } from "@/store/types";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";

export class NoteCollectionListObservable {
  private store: AppStore;
  private noteId: Uuid;

  constructor({ store, noteId }: { noteId: Uuid } & WithAppStore) {
    this.store = store;
    this.noteId = noteId;

    makeObservable<this, "store" | "noteId">(this, {
      store: false,
      noteId: observable,
      hasCollection: false,
      allCollectionIds: computed,
      allCollections: computed,
      allCollectionItems: computed,
      addCollection: action,
      removeCollection: action,
    });
  }

  public hasCollection({ collectionId }: { collectionId: string }) {
    return this.allCollectionIds.has(collectionId);
  }

  get allCollectionIds(): Set<Uuid> {
    return new Set(this.allCollections.map(collection => collection.id));
  }

  get allCollections(): CollectionObservable[] {
    return this.allCollectionItems
      .map(item => item.collection)
      .filter((collection): collection is CollectionObservable => collection !== undefined);
  }

  get allCollectionItems(): CollectionItemObservable[] {
    return this.store.collectionItems.itemsToCollectionItems.get(this.noteId) ?? [];
  }

  public addCollection({
    collectionId,
    triggerSuccessToast,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
  }) {
    if (this.hasCollection({ collectionId })) return;
    new AddNoteToCollectionOperation({
      store: this.store,
      payload: {
        collection_id: collectionId,
        note_id: this.noteId,
      },
      triggerSuccessToast,
    }).execute();
  }

  public removeCollection({
    collectionId,
    triggerSuccessToast,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
  }) {
    if (!this.hasCollection({ collectionId })) return;
    new RemoveNoteFromCollectionOperation({
      store: this.store,
      payload: {
        collection_id: collectionId,
        note_id: this.noteId,
      },
      triggerSuccessToast,
    }).execute();
  }
}
