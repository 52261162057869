import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  ObservableSet,
  runInAction,
  toJS,
} from "mobx";

import { SpaceAccountObservable } from "@/store/space-account/observables/SpaceAccountObservable";
import { api } from "@/modules/api";
import { cache } from "@/modules/cache";
import { CacheKey } from "@/modules/cache/constants";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { BaseError, RuntimeAssertionError } from "@/domains/errors";
import { Uuid } from "@/domains/global/identifiers";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";

export class AppStoreSpaceAccountStore extends AppSubStore {
  spaceAccountMap: ObservableMap<Uuid, SpaceAccountObservable>;
  fetchMySpaceAccountsState: AsyncResult<ObservableSet<Uuid>>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.spaceAccountMap = new ObservableMap();
    this.fetchMySpaceAccountsState = asyncResultModule.setInitial();

    makeObservable(this, {
      spaceAccountMap: observable,
      fetchMySpaceAccountsState: observable,

      getSpaceAccount: false,
      getSpaceAccountBySpaceId: false,
      addSpaceAccount: action,
      fetchMySpaceAccounts: action,
      persistMySpaceAccountsToCache: action,
      hydrateMySpacesAccountsFromCache: action,
      initialize: action,

      myPersonalSpaceAccount: computed,
      myPersonalSpaceAccountId: computed,
      mySpaceAccountSpaceIds: computed,
      mySpaceAccountIds: computed,
      mySpaceAccounts: computed,
      resetState: action,
    });
  }

  getSpaceAccount = (spaceAccountId: Uuid): SpaceAccountObservable | undefined => {
    return this.spaceAccountMap.get(spaceAccountId);
  };

  getSpaceAccountBySpaceId = (spaceId: Uuid): SpaceAccountObservable | undefined => {
    const values = this.spaceAccountMap.values();

    const spaceAccount = Array.from(values).find(value => value.spaceId === spaceId);

    return spaceAccount;
  };

  addSpaceAccount = ({ spaceAccount }: { spaceAccount: SpaceAccountObservable }) => {
    runInAction(() => {
      this.spaceAccountMap.set(spaceAccount.id, spaceAccount);
      this.fetchMySpaceAccountsState.data?.add(spaceAccount.id);
    });
  };

  fetchMySpaceAccounts = async () => {
    runInAction(() => {
      this.fetchMySpaceAccountsState.called = true;
      this.fetchMySpaceAccountsState.error = undefined;
      this.fetchMySpaceAccountsState.loading = true;
    });

    const result = await api.get("/v1/me/space-accounts", {});

    if (result.data) {
      const spaceAccountDataArray = result.data;

      runInAction(() => {
        spaceAccountDataArray.forEach(spaceAccountData => {
          const spaceAccount = new SpaceAccountObservable({
            id: spaceAccountData.id,
            accountId: spaceAccountData.account_id,
            spaceId: spaceAccountData.space_id,
            profileEmailAddress: spaceAccountData.profile_email_address,
            profileDisplayName: spaceAccountData.profile_display_name,
            profilePhotoUrl: spaceAccountData.profile_photo_url ?? null,
          });

          this.spaceAccountMap.set(spaceAccount.id, spaceAccount);
        });

        const spaceAccountIds = spaceAccountDataArray.map(spaceAccountData => spaceAccountData.id);
        this.fetchMySpaceAccountsState.data = new ObservableSet(spaceAccountIds);
      });
    } else {
      const err = new BaseError({
        message: "Failed to fetch current account space accounts.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.fetchMySpaceAccountsState.error = err;
      });
    }

    runInAction(() => {
      this.fetchMySpaceAccountsState.loading = false;
    });

    await this.persistMySpaceAccountsToCache();
  };

  persistMySpaceAccountsToCache = async () => {
    await cache.set(
      CacheKey.MySpaceAccounts,
      this.mySpaceAccounts.map(spaceAccount => toJS(spaceAccount))
    );
  };

  hydrateMySpacesAccountsFromCache = async (): Promise<boolean> => {
    const mySpaceAccounts = await cache.get(CacheKey.MySpaceAccounts);

    if (!mySpaceAccounts) {
      return false;
    }

    const spaceAccounts = mySpaceAccounts.map(spaceAccountData => {
      const spaceAccount = new SpaceAccountObservable({
        id: spaceAccountData.id,
        accountId: spaceAccountData.accountId,
        spaceId: spaceAccountData.spaceId,
        profileEmailAddress: spaceAccountData.profileEmailAddress,
        profileDisplayName: spaceAccountData.profileDisplayName,
        profilePhotoUrl: spaceAccountData.profilePhotoUrl ?? null,
      });

      return spaceAccount;
    });

    runInAction(() => {
      spaceAccounts.forEach(spaceAccount => {
        this.spaceAccountMap.set(spaceAccount.id, spaceAccount);
      });

      const spaceAccountIds = spaceAccounts.map(spaceAccount => spaceAccount.id);

      this.fetchMySpaceAccountsState.data = new ObservableSet(spaceAccountIds);
    });

    return true;
  };

  initialize = async () => {
    const fetchMySpaceAccountsPromise = this.fetchMySpaceAccounts();
    const hydrationSuccess = await this.hydrateMySpacesAccountsFromCache();

    /**
     * If we were able to hydrate from cache, we don't need to wait for the API call.
     */
    if (hydrationSuccess) {
      return;
    }

    await fetchMySpaceAccountsPromise;

    if (this.fetchMySpaceAccountsState.error) {
      throw new RuntimeAssertionError({
        message: "[SpaceAccountStore.initialize] Failed.",
        info: { originalError: objectModule.safeErrorAsJson(this.fetchMySpaceAccountsState.error) },
      });
    }
  };

  get myPersonalSpaceAccountId(): Uuid {
    const personalSpaceAccount = this.myPersonalSpaceAccount;

    if (!personalSpaceAccount) {
      /**
       * @todo - This shouldn't be necessary, but keeping this here until we have time
       * to verify the boot flow works well. We should technically just be able to
       * throw an error, because this should always be loaded.
       */
      const personalSpaceId = this.store.spaces.myPersonalSpaceId;
      const fallback = uuidModule.resolveSpaceAccountUuid({
        spaceId: personalSpaceId,
        accountId: this.store.account.myAccountId,
      });

      return fallback;
    }

    return personalSpaceAccount.id;
  }

  get myPersonalSpaceAccount() {
    const personalSpaceId = this.store.spaces.myPersonalSpaceId;

    const personalSpaceAccount = this.mySpaceAccounts.find(
      spaceAccount => spaceAccount.spaceId === personalSpaceId
    );

    return personalSpaceAccount;
  }

  get mySpaceAccountSpaceIds(): Uuid[] {
    const spaceAccountSpaceIds = this.mySpaceAccounts.map(spaceAccount => spaceAccount.spaceId);

    return spaceAccountSpaceIds;
  }

  get mySpaceAccountIds(): Uuid[] {
    const spaceAccountIds = this.fetchMySpaceAccountsState.data;

    if (!spaceAccountIds) {
      throw new RuntimeAssertionError({
        message:
          "[SpaceAccountStore.mySpaceAccountIds] spaceAccountIds was not set - make sure that `initialize` was called.",
      });
    }

    if (!spaceAccountIds.size) {
      throw new RuntimeAssertionError({
        message:
          "[SpaceAccountStore.mySpaceAccountIds] spaceAccountIds was empty - make sure that `initialize` was called.",
      });
    }

    return Array.from(spaceAccountIds);
  }

  get mySpaceAccounts(): SpaceAccountObservable[] {
    const spaceAccounts = this.mySpaceAccountIds.map(spaceAccountId => {
      const spaceAccount = this.getSpaceAccount(spaceAccountId);

      if (!spaceAccount) {
        throw new RuntimeAssertionError({
          message:
            "[SpaceAccountStore.mySpaceAccounts] spaceAccount was not set - make sure that `initialize` was called.",
        });
      }

      return spaceAccount;
    });

    return spaceAccounts;
  }

  resetState = () => {
    runInAction(() => {
      this.spaceAccountMap = new ObservableMap();
      this.fetchMySpaceAccountsState = asyncResultModule.setInitial();
    });
  };
}
