import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsModal } from "@/design-system/components/modal";
import {
  mdsFontSizes,
  mdsFontWeights,
  mdsMediaQueries,
  mdsSpacings,
} from "@/design-system/foundations";
import { ChatMessage } from "@/domains/chat";
import styled from "@emotion/styled";
import { AppStore, useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import {
  MdsItemKind,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  sources: ChatMessage["sources"];
  inSidePanel?: boolean;
}

const AVERAGE_LIST_ITEM_HEIGHT = 62;

export const ChatMessageInfoModal = observer(
  ({ isOpen, onClose, sources, inSidePanel = false }: Props) => {
    const { store } = useAppStore();
    const sourcesRows = makeRowFromSources(sources, store, inSidePanel);

    return (
      <MdsModal rootElementId="root" isModalOpen={isOpen} handleCloseModal={onClose}>
        <ModalBodyWrapper>
          <ModalTitlebar>
            <Title>Sources</Title>
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={onClose} />
          </ModalTitlebar>

          {sources?.length > 0 && (
            <SourceWrapper
              data-test-id="sources-wrapper"
              style={{
                // I don't love setting the height like this--Rick.
                // I got the idea from Claude: https://claude.ai/share/e9d7bbd6-86b3-4888-9e8d-50debe8bc417
                height: AVERAGE_LIST_ITEM_HEIGHT * sourcesRows.length,
              }}
            >
              <MdsVirtualizedItemList rows={sourcesRows} />
            </SourceWrapper>
          )}
        </ModalBodyWrapper>
      </MdsModal>
    );
  }
);

// basically copied from HomePageStore.tsx -> get itemRows()
const makeRowFromSources = (sources: Props["sources"], store: AppStore, inSidePanel?: boolean) => {
  const output: MdsItemListRowData[] = [];

  sources.forEach(source => {
    const noteObservable = store.notes.getNoteObservableById({
      noteId: source.item_id,
    });
    if (!noteObservable) return;

    output.push({
      type: MdsItemListRowType.Item,
      key: noteObservable.id,
      size: MdsItemListSize.XLarge,
      payload: {
        id: noteObservable.id,
        kind: MdsItemKind.Note,
        createPreviewState: () =>
          new ItemPreviewState({
            store,
            id: source.item_id,
            kind: MdsItemKind.Note,
          }),
        label: noteObservable.title,
        onClick: () =>
          inSidePanel
            ? store.sidePanel.goToNote({ noteId: source.item_id })
            : store.navigation.goToNote({ noteId: source.item_id }),
        sharedBy: noteObservable.sharedBy,
        // If we want to implement more features here, like dropdown or list state, check out HomePageStore.tsx -> get itemRows()
        // dropdown: this.generateItemDropdown({ noteObservable }),
        // listState: ...,
        extraRows: [
          {
            id: `note-${noteObservable.id}-content`,
            content: () => (
              <MdsItemListRowFeaturedContent
                collections={sortCollectionsForChips(noteObservable.collectionList.allCollections)}
                snippet={
                  noteObservable.collectionList.allCollections.length
                    ? undefined
                    : [{ text: noteObservable.secondaryTitle || "No additional text" }]
                }
              />
            ),
          },
        ],
      },
    });
  });

  return output;
};

const ModalBodyWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  maxHeight: "85dvh",
  minWidth: "90dvw",
  maxWidth: "90dvw",
  gap: mdsSpacings().md,
  padding: mdsSpacings().md,
  [mdsMediaQueries().rules.tablet]: {
    minWidth: "500px",
  },
  [mdsMediaQueries().rules.desktop]: {
    padding: mdsSpacings().lg,
    minWidth: "650px",
  },
});

const ModalTitlebar = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: mdsSpacings().sm,
});

const SourceWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

const Title = styled.h2({
  fontSize: mdsFontSizes().large,
  fontWeight: mdsFontWeights().bold,
  lineHeight: "1rem",
  margin: 0,
});
