import { DeviceDimensions, DevicePlatform } from "@/modules/device/types";
import { PublicAppSubStoreArgs, PublicAppSubStore } from "@/store/types";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { action, computed, makeObservable, observable, ObservableMap, runInAction } from "mobx";
import { deviceModule } from "@/modules/device";
import {
  PanelMode,
  ScreenSize,
  mdsBreakpoints,
  mdsPanelModeBreakpoints,
} from "@/design-system/foundations";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { logger } from "@/modules/logger";
import { useWindowResize } from "@/domains/react/useWindowResize";
import { useEffect } from "react";
import { IAsyncData, asyncDataLoaded, buildAsyncData } from "@/domains/async/AsyncData";
import { IResource, fromResource } from "mobx-utils";
import { MININIMUM_WIDTH_FOR_REGULAR_NOTE_PREVIEW } from "@/store/interface/constants";

export class AppStoreInterfaceStore extends PublicAppSubStore {
  private reloadRequiredListeners = new Map<string, Set<() => void>>();

  backNavigationOnEscapeDisabled = false;
  isSidebarOpen = false;
  isChatSidebarOpen = false;
  dimensionsState: AsyncResult<DeviceDimensions> = asyncResultModule.setInitial();
  platformState: AsyncResult<DevicePlatform> = asyncResultModule.setInitial();
  hoverableState: IAsyncData<boolean> = buildAsyncData({});
  screenSizeState: IAsyncData<ScreenSize> = buildAsyncData({});
  initializationState: AsyncResult<true> = asyncResultModule.setInitial();
  matchesPanelModeBreakpoint: ObservableMap<PanelMode, boolean> = new ObservableMap();

  constructor(injectedDeps: PublicAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<
      this,
      "reloadRequiredListeners" | "backNavigationOnEscapeKeydownDisabledResource"
    >(this, {
      reloadRequiredListeners: observable,
      backNavigationOnEscapeDisabled: observable,
      isSidebarOpen: observable,
      isChatSidebarOpen: observable,
      dimensionsState: observable,
      platformState: observable,
      hoverableState: observable,
      screenSizeState: observable,
      initializationState: observable,
      isScreenNarrowForRegularNotePreviews: computed,
      addReloadRequiredListener: action,
      removeReloadRequiredListener: action,
      notifyReloadRequired: action,
      backNavigationOnEscapeKeydownDisabledResource: observable,
      withBackNavigationOnEscapeDisabled: action,
      isHoverable: computed,
      isTouchDevice: computed,
      initialize: action,
      toggleChatSidebar: action,
      toggleSidebar: action,
      setIsSidebarOpen: action,
      setIsChatSidebarOpen: action,
      setHoverableState: action,
      setScreenSizeState: action,
      updateDeviceDimensions: action,
      useScreenSizeUpdater: action,
      matchesPanelModeBreakpoint: observable,
    });
  }

  get isScreenNarrowForRegularNotePreviews() {
    const width = this.dimensionsState.data?.width ?? 0;
    return width < MININIMUM_WIDTH_FOR_REGULAR_NOTE_PREVIEW;
  }

  addReloadRequiredListener(id: string, listener: () => void) {
    let listeners = this.reloadRequiredListeners.get(id);
    if (!listeners) {
      listeners = new Set();
      this.reloadRequiredListeners.set(id, listeners);
    }
    listeners.add(listener);
  }

  removeReloadRequiredListener(id: string, listener: () => void) {
    const listeners = this.reloadRequiredListeners.get(id);
    if (!listeners) return;

    listeners.delete(listener);
    if (listeners.size === 0) {
      this.reloadRequiredListeners.delete(id);
    }
  }

  notifyReloadRequired(id: string) {
    const listeners = this.reloadRequiredListeners.get(id) ?? new Set();
    logger.debug({ message: `[Interface] reload: ${id}, ${listeners.size} listeners` });

    for (const listener of listeners) {
      listener();
    }
  }

  private backNavigationOnEscapeKeydownDisabledResource: IResource<string | undefined> | undefined;

  withBackNavigationOnEscapeDisabled() {
    if (!this.backNavigationOnEscapeKeydownDisabledResource) {
      this.backNavigationOnEscapeKeydownDisabledResource = fromResource<string>(
        sink => {
          runInAction(() => {
            this.backNavigationOnEscapeDisabled = true;
          });
          sink(`back-navigation-on-escape-disabled`);
        },
        () =>
          runInAction(() => {
            this.backNavigationOnEscapeDisabled = false;
          })
      );
    }
    return this.backNavigationOnEscapeKeydownDisabledResource.current();
  }

  get isHoverable() {
    return this.hoverableState.data === true;
  }

  get isTouchDevice() {
    return this.hoverableState.data === false;
  }

  initialize = async () => {
    this.initializationState = asyncResultModule.setLoading();

    const devicePlatform = deviceModule.determinePlatform();

    this.platformState = asyncResultModule.setReady(devicePlatform);
    this.initializationState = asyncResultModule.setReady(true);
  };

  toggleChatSidebar = () => {
    this.isChatSidebarOpen = !this.isChatSidebarOpen;
  };

  toggleSidebar = () => {
    this.setIsSidebarOpen(!this.isSidebarOpen);
  };

  setIsSidebarOpen = (isOpen: boolean) => {
    this.isSidebarOpen = isOpen;
  };

  setIsChatSidebarOpen = (isOpen: boolean) => {
    this.isChatSidebarOpen = isOpen;
  };

  setHoverableState = (hoverableState: IAsyncData<boolean>) => {
    logger.debug({ message: `[Interface] hoverable? ${hoverableState.data}` });
    this.hoverableState = hoverableState;
  };

  setScreenSizeState = (screenSizeState: IAsyncData<ScreenSize>) => {
    logger.debug({ message: `[Interface] screen size: ${screenSizeState.data}` });
    this.screenSizeState = screenSizeState;
  };

  updateDeviceDimensions = async ({ deviceDimensions }: { deviceDimensions: DeviceDimensions }) => {
    this.dimensionsState = asyncResultModule.setReady(deviceDimensions);
  };

  useScreenSizeUpdater() {
    const { height, width } = useWindowResize({
      debounce: { wait: 100, options: { maxWait: 400 } },
    });

    useEffect(() => {
      this.updateDeviceDimensions({
        deviceDimensions: {
          height,
          width,
        },
      });
    }, [height, width]);

    useEffectOnMount(() => {
      // Using media queries instead of the above hook to ensure breakpoints match CSS rules.
      const removeScreenSizeListeners = Object.entries(mdsBreakpoints()).map(
        ([screenSize, value]) => {
          const q = window.matchMedia(value);
          const f = () => {
            if (!q.matches) return;

            this.setScreenSizeState(
              asyncDataLoaded(this.screenSizeState, screenSize as ScreenSize)
            );
            if (screenSize === ScreenSize.Desktop) {
              this.setIsSidebarOpen(false);
            }
          };
          f();
          q.addEventListener("change", f);
          return () => {
            q.removeEventListener("change", f);
          };
        }
      );

      const removePanelModeListeners = Object.entries(mdsPanelModeBreakpoints()).map(
        ([panelMode, value]) => {
          const q = window.matchMedia(value);
          const f = () => this.matchesPanelModeBreakpoint.set(panelMode as PanelMode, !!q.matches);
          f();
          q.addEventListener("change", f);
          return () => {
            q.removeEventListener("change", f);
          };
        }
      );

      const hoverMediaQuery = window.matchMedia("(hover: hover) and (pointer: fine)");
      const hoverMediaQueryChangeCallback = () => {
        this.setHoverableState(asyncDataLoaded(this.hoverableState, hoverMediaQuery.matches));
      };
      hoverMediaQueryChangeCallback();
      hoverMediaQuery.addEventListener("change", hoverMediaQueryChangeCallback);

      return () => {
        hoverMediaQuery.removeEventListener("change", hoverMediaQueryChangeCallback);
        for (const removeListener of removeScreenSizeListeners) removeListener();
        for (const removeListener of removePanelModeListeners) removeListener();
        this.setScreenSizeState(buildAsyncData({}));
        this.setHoverableState(buildAsyncData({}));
      };
    });
  }
}
