import ColorHash from "color-hash";
import { FC, Fragment } from "react";

import { SnippetChunk, TopicInfo } from "@/design-system/components/item-list/types";
import { mdsColors, mdsFontSizes, mdsFontWeights } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { isEmpty } from "lodash-es";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";

export interface MdsItemListRowFeaturedContentProps {
  collections?: TopicInfo[];
  snippet?: SnippetChunk[];
}

export const MdsItemListRowFeaturedContent: FC<MdsItemListRowFeaturedContentProps> = ({
  collections,
  snippet,
}) => {
  const bgColorHash = new ColorHash({ lightness: 0.92, saturation: 0.45 });
  const textColorHash = new ColorHash({ lightness: 0.35, saturation: 0.45 });

  const anyCollections = !isEmpty(collections);
  const firstTwoCollections = collections?.slice(0, 2);
  const remainingCollections = collections?.slice(2);
  const additionalCollectionsCount = remainingCollections?.length;
  const shouldShowAdditionalCollections = !!additionalCollectionsCount;

  return (
    <div className={containerStyles}>
      {firstTwoCollections?.map(featuredCollection => {
        const bgColor = bgColorHash.hex(featuredCollection.id);
        const textColor = textColorHash.hex(featuredCollection.id);

        return (
          <span
            key={featuredCollection.id}
            className={collectionStyles}
            style={{ backgroundColor: bgColor, color: textColor }}
          >
            {featuredCollection.label}
          </span>
        );
      })}

      {shouldShowAdditionalCollections && (
        <span className={collectionOverflowStyles}>+{additionalCollectionsCount}</span>
      )}
      {anyCollections && snippet && snippet.length > 0 && (
        <MdsIcon
          className={dotSeparatorStylesStyles}
          innerStyles={{ Icon: { className: dotSeparatorInnerStyles } }}
          kind={MdsIconKind.Dot}
        />
      )}
      {snippet?.map(({ text, isHighlight }, index) => (
        <Fragment key={index}>
          {isHighlight ? (
            <span className={highlightedSnippetStyles}>{text}</span>
          ) : (
            <span className={standardSnippetStyles}>{text}</span>
          )}
        </Fragment>
      ))}
    </div>
  );
};

const dotSeparatorStylesStyles = css({
  height: "100%",
  marginRight: "2px",
});

const dotSeparatorInnerStyles = css({
  fontSize: "4px",
});

const standardSnippetStyles = css({
  whiteSpace: "pre",
});

const highlightedSnippetStyles = css({
  whiteSpace: "pre",
  fontWeight: mdsFontWeights().semiBold,
});

const containerStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().small,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const collectionStyles = css({
  display: "inline-block",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  paddingLeft: "4px",
  paddingRight: "3px",
  borderRadius: "4px",
  marginRight: "4px",
  marginBottom: "1px",
});

const collectionOverflowStyles = css({
  display: "inline-block",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  paddingLeft: "4px",
  paddingRight: "3px",
  borderRadius: "4px",
  marginRight: "7px",
  color: "rgba(105, 110, 125, 1)",
  backgroundColor: "rgba(243, 243, 245, 1)",
});
