import { moduleFactory } from "@/factories/module";
import { sortKindLabelMap } from "@/modules/lenses/common";
import {
  collectionsLensSortFunctionMap,
  collectionsLensDateLabelMap,
  collectionsLensFilterFunctionMap,
} from "@/modules/lenses/collections";
import {
  notesLensDateLabelMap,
  notesLensFilterFunctionMap,
  notesLensSortFunctionMap,
} from "@/modules/lenses/notes";

export const collectionsLensModule = moduleFactory.create({
  sortFunctionMap: collectionsLensSortFunctionMap,
  filterFunctionMap: collectionsLensFilterFunctionMap,
  dateLabelMap: collectionsLensDateLabelMap,
});

export const notesLensModule = moduleFactory.create({
  sortFunctionMap: notesLensSortFunctionMap,
  filterFunctionMap: notesLensFilterFunctionMap,
  dateLabelMap: notesLensDateLabelMap,
});

export const lensModule = {
  sortKindLabelMap,
};
