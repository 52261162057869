import { _parseDateTimeValue } from "@/modules/date-time/parseValue";
import { DateTime } from "luxon";

export const _maybeParseDateTimeValue = (value?: string): DateTime | undefined => {
  if (!value) {
    return undefined;
  }

  return _parseDateTimeValue(value);
};
