import { Uuid } from "@/domains/global/identifiers";
import { combineUuidsUsingNamespace } from "@/modules/uuid/sync-models/combineUuidsUsingNamespace";
import { resolveSyncModelNamespace } from "@/modules/uuid/sync-models/resolveSyncModelNamespace";
import { SyncModelKind } from "@/store/sync/types";

export const resolveSyncModelNamespacedUuid = ({
  modelKind,
  uuids,
}: {
  modelKind: { value: SyncModelKind };
  uuids: Uuid[];
}): Uuid => {
  const namespace = resolveSyncModelNamespace({ modelKind });

  return combineUuidsUsingNamespace({ namespace, uuids });
};
