export interface SearchSuggestion {
  id: string;
  type: SearchSuggestionType;
  label: string;
  lastViewedAt: string;
  sortKey: number;
}

export enum SearchSuggestionType {
  NOTE = "NOTE",
  COLLECTION = "COLLECTION",
}
