import { css } from "@/domains/emotion";

export const FadeBottom = () => {
  return <div className={fadeBottomStyles} />;
};

const fadeBottomStyles = css({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "28px",
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.854167) 85.42%, #ffffff 100%)",
});
