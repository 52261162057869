import Dexie, { liveQuery } from "dexie";

import { errorMessagesToCriticalErrorCodes } from "@/domains/critical-errors/error-messages";
import { MatchMode } from "@/domains/local-db/lib/types";

/**
 * IMPORTANT NOTES on how reads & writes are ordered:
 *
 * Reads and writes will complete OUT OF ORDER relative to how they were called. This is
 * because, in Dexie, reads and writes normally block each other. To prioritize read speed, we
 * always ensure there are zero reads in flight at the time we begin a write. This way, fewer reads
 * are blocked on pending writes.
 *
 * As a result, given the following call order occurring nearly simultaneously:
 *
 * R1 -> W1 -> R2 -> R3 -> W2
 *
 * The reads and writes will execute in the following order:
 *
 * R1 -> R2 -> R3 -> W1 -> W2
 *
 * The following behavior is guaranteed:
 *
 * 1. Reads always complete in the order they were started.
 * 2. Writes always occur in the order they were written.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRetryableIndexedDBError = (err: any) => {
  const { message } = err;
  for (const { onlyAfterTimeout, text } of errorMessagesToCriticalErrorCodes) {
    if (onlyAfterTimeout && message.indexOf(text) >= 0) {
      return true;
    }
  }
  return false;
};

class LocalDbTableAdapter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStore: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: any;
  numPendingReads: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendingReadsPromise: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendingReadsPromiseResolver: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callsById: Map<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendingCallById: Map<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static MIN_DATE: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static MAX_DATE: any;
  static RESET_CONNECTION_DELAY_MS: number;
  static RETRY_READ_WAIT_MS: number;
  static RETRY_READ_WAIT_MAX_TIMES: number;
  static RETRY_WRITE_MIN_MS: number;
  static RETRY_WRITE_MAX_MS: number;
  static RETRY_WRITE_MAX_TIMES: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(getStore: any, table: any) {
    this.getStore = getStore;
    this.table = table;

    this.numPendingReads = 0;
    this.pendingReadsPromise;
    this.pendingReadsPromiseResolver;

    this.callsById = new Map();
    this.pendingCallById = new Map();
  }

  async untilNoPendingReads() {
    if (!this.pendingReadsPromise) {
      return;
    }
    await this.pendingReadsPromise;
  }

  markReadPending() {
    this.numPendingReads++;
    if (!this.pendingReadsPromise) {
      this.pendingReadsPromise = new Promise(resolve => {
        this.pendingReadsPromiseResolver = resolve;
      });
    }
  }

  markReadComplete() {
    this.numPendingReads--;
    if (this.numPendingReads === 0) {
      this.pendingReadsPromise = undefined;
      this.pendingReadsPromiseResolver();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async wrapRead(readFn: any, options?: { mayFail?: boolean; limit?: number }) {
    this.markReadPending();
    let response;
    let firstErr;
    for (let retries = 0; retries <= 1; retries++) {
      let store;
      try {
        store = await this.getStore(firstErr);
        response = await readFn(store);
        break;
      } catch (err) {
        if (store && options?.mayFail) {
          throw err;
        }
        if (err === firstErr) {
          // Error is critical so getStore re-threw it.
          break;
        }
        // Only log read errors?
        console.error(`LocalDB read failed (${retries + 1}):`, err);
        if (!isRetryableIndexedDBError(err)) {
          break;
        }
        firstErr = err;
      }
    }
    this.markReadComplete();
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async wrapWrite(writeFn: any) {
    await this.untilNoPendingReads();
    let firstErr;
    for (let retries = 0; retries <= 1; retries++) {
      try {
        const store = await this.getStore(firstErr);
        await writeFn(store);
      } catch (err) {
        if (retries) {
          if (err !== firstErr) {
            // Error is not critical so getStore did not re-throw it.
            console.error("LocalDB write failed:", err);
          }
          throw firstErr;
        }
        if (!isRetryableIndexedDBError(err)) {
          throw err;
        }
        firstErr = err;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapLastWriteCallWins(id: any, writeFn: any) {
    this.callsById.set(id, writeFn);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (...args: any) => {
      const pendingCall = this.pendingCallById.get(id);
      if (pendingCall) {
        try {
          await pendingCall;
        } catch (err) {
          // Fail silently, the actual caller of the write fn will catch this.
        }
        if (this.pendingCallById.has(id)) {
          this.pendingCallById.delete(id);
        }
      }
      const lastCallWriteFn = this.callsById.get(id);
      if (lastCallWriteFn !== writeFn) {
        return;
      }
      this.callsById.delete(id);
      const pendingCallPromise = lastCallWriteFn(...args);
      this.pendingCallById.set(id, pendingCallPromise);
      return await pendingCallPromise;
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get(id: any) {
    return await this.wrapRead(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (store: any) =>
        await store[this.table].get(id).catch(() => {
          /* TODO: Remove catch */
        })
    );
  }

  async getCount() {
    const store: Dexie = await this.getStore();
    return await store.table(this.table).count();
  }

  async getAllIds() {
    const store: Dexie = await this.getStore();
    return await store.table(this.table).toCollection().primaryKeys();
  }

  async getAll(options?: { mayFail?: boolean }) {
    return await this.wrapRead(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (store: any) => {
        let p = store[this.table].toArray();
        if (!options?.mayFail) {
          p = p.catch(() => {
            /* TODO: Remove catch */
            return [];
          });
        }
        return await p;
      },
      options
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllByIds(ids: any) {
    return await this.wrapRead(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (store: any) =>
        await store[this.table].bulkGet(ids).catch(() => {
          /* TODO: Remove catch */
          return [];
        })
    );
  }

  async getAllOrderedByFieldAsc(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orderByField: any,
    options?: { mayFail?: boolean; limit?: number }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      let collection = await store[this.table].orderBy(orderByField);

      if (options?.limit) {
        collection = collection.limit(options.limit);
      }

      let p = collection.toArray();
      if (!options?.mayFail) {
        p = p.catch(() => {
          /* TODO: Remove catch */
          return [];
        });
      }
      return await p;
    }, options);
  }

  async getAllOrderedByFieldDesc(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orderByField: any,
    options?: { mayFail?: boolean; limit?: number }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      let collection = await store[this.table].orderBy(orderByField).reverse();

      if (options?.limit) {
        collection = collection.limit(options.limit);
      }

      let p = collection.toArray();
      if (!options?.mayFail) {
        p = p.catch(() => {
          /* TODO: Remove catch */
          return [];
        });
      }
      return await p;
    }, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllWhereFieldIsTrueOrderedByFieldDesc(whereField: any, orderByField: any, limit = 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      let collection = await store[this.table]
        .where(`[${whereField}+${orderByField}]`)
        .between([1, LocalDbTableAdapter.MIN_DATE], [1, LocalDbTableAdapter.MAX_DATE], true, true)
        .reverse();

      if (limit) {
        collection = collection.limit(limit);
      }

      return await collection.toArray().catch(() => {
        /* TODO: Remove catch */
        return [];
      });
    });
  }

  async getAllWhere(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    whereField: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    whereFieldValue: any,
    options?: { mayFail?: boolean; limit?: number }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      const collection = await store[this.table].where({ [whereField]: whereFieldValue });
      let p = collection.toArray();
      if (!options?.mayFail) {
        p = p.catch(() => {
          /* TODO: Remove catch */
          return [];
        });
      }
      return await p;
    }, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllCompoundWhere(whereConditionsMap: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      const collection = await store[this.table].where(whereConditionsMap);
      return await collection.toArray().catch(() => {
        /* TODO: Remove catch */
        return [];
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getOneCompoundWhere(whereConditionsMap: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      const collection = await store[this.table].where(whereConditionsMap);
      return await collection.first().catch(() => {
        /* TODO: Remove catch */
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllUniqueKeysWithPrefix(key: any, prefix: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      const collection = await store[this.table].where(key).startsWithIgnoreCase(prefix);
      return await collection.uniqueKeys().catch(() => {
        /* TODO: Remove catch */
        return [];
      });
    });
  }

  async getPrimaryKeysWhere(
    fieldName: string,
    searchValue: unknown,
    matchMode?: MatchMode,
    maxIds?: number,
    preferGreaterValuesInFieldName?: string
  ): Promise<string[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapRead(async (store: any) => {
      const table = store[this.table];
      const whereClause = table.where(fieldName);
      let collection;
      switch (matchMode) {
        case MatchMode.StartsWithIgnoreCase: {
          collection = whereClause.startsWithIgnoreCase(searchValue);
          break;
        }
        case MatchMode.StartsWith: {
          collection = whereClause.startsWith(searchValue);
          break;
        }
        case MatchMode.Between: {
          collection = whereClause.between(...(searchValue as unknown[]));
          break;
        }
        case MatchMode.AnyOf: {
          collection = whereClause.anyOf(searchValue);
          break;
        }
        case MatchMode.Equals: {
          collection = whereClause.equals(searchValue);
          break;
        }
        case MatchMode.EqualsIgnoreCase:
        default: {
          collection = whereClause.equalsIgnoreCase(searchValue);
          break;
        }
      }
      let limitInfo = "";
      if (!preferGreaterValuesInFieldName && maxIds) {
        collection = collection.limit(maxIds);
        limitInfo = `[${maxIds ?? -1}]`;
      }
      // Sadly we cannot orderBy (updated field) and where at the same time.
      // Even if collection has sortBy, it fetches entire documents which is slower than only
      // fetching PKs.
      const promiseAllIds = (async () => {
        const before = Date.now();
        const pks = await collection.primaryKeys();
        console.debug(
          `[PKs] ${fieldName}.${matchMode}${limitInfo}:"${searchValue}" took ${
            Date.now() - before
          } ms, ${pks.length} IDs`
        );
        return pks;
      })();
      if (!maxIds || !preferGreaterValuesInFieldName) {
        return await promiseAllIds;
      }

      const before = Date.now();
      const orderedIds = await table
        .orderBy(preferGreaterValuesInFieldName)
        .reverse()
        .primaryKeys();
      console.debug(
        `[PKs] ${preferGreaterValuesInFieldName}.sortedBy[${maxIds ?? -1}] took ${
          Date.now() - before
        } ms`
      );

      const set = new Set(await promiseAllIds);
      const ids = [];
      for (const id of orderedIds) {
        if (!set.has(id)) {
          continue;
        }
        ids.push(id);
        if (ids.length >= maxIds) {
          break;
        }
      }
      return ids;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setAllByIds(ids: any, values: any) {
    return await this.wrapWrite(async (store: Dexie) => {
      /**
       * WARNING: bulkPut() ignores errors if you catch the rejected promise, and wrapWrite does
       * that. To avoid it we have to include bulkPut in a transaction and catch the transaction's
       * promise instead.
       *
       * Docs (https://dexie.org/docs/Table/Table.bulkPut()#errors):
       *
       * If some operations fail, bulkPut() will ignore those failures and return a rejected
       * Promise with a Dexie.BulkError referencing the failures. If the caller does not catch the
       * error, the transaction will abort. If the caller wants to ignore the failures, the
       * bulkPut() operations must be caught. NOTE: If you call bulkPut() outside a transaction
       * scope and an error occur on one of the operations, the successful operations will still be
       * persisted to DB! If this is not desired, surround your call to bulkPut() in a transaction
       * and catch transaction’s promise instead of the bulkPut() operation.
       */
      await store.transaction("rw", this.table, async () => {
        await store.table(this.table).bulkPut(values, ids);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeAllByIds(ids: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].bulkDelete(ids));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async set(id: any, value: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].put(value, id));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put(value: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].put(value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async putAll(values: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].bulkPut(values));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(id: any, updates: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].update(id, updates));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async remove(id: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].delete(id));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeWhere(field: any, value: any) {
    return await this.wrapWrite(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (store: any) => await store[this.table].where({ [field]: value }).delete()
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async replaceWithWhere(newValues: any, field: any, fieldValue: any) {
    return await this.wrapWrite(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (store: any) =>
        await store.transaction("rw", store[this.table], async () => {
          await store[this.table].where({ [field]: fieldValue }).delete();
          return await store[this.table].bulkPut(newValues);
        })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async clearAndSetAllByIds(ids: any, values: any) {
    return await this.wrapWrite(
      this.wrapLastWriteCallWins(
        "clearAndSetAllByIds",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (store: any) =>
          await store.transaction("rw", store[this.table], async () => {
            await store[this.table].clear();
            return await store[this.table].bulkPut(values, ids);
          })
      )
    );
  }

  async clearAll() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await this.wrapWrite(async (store: any) => await store[this.table].clear());
  }

  async observeQuery<T>(querier: { (table: Dexie.Table): Promise<T> }) {
    let response;
    let firstErr;
    for (let retries = 0; retries <= 1; retries++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let store: any;
      try {
        store = await this.getStore(firstErr);
        response = liveQuery(() => querier(store[this.table]));
        break;
      } catch (err) {
        if (err === firstErr) {
          // Error is critical so getStore re-threw it.
          break;
        }
        // Only log read errors?
        console.error(`LocalDB read failed (${retries + 1}):`, err);
        if (!isRetryableIndexedDBError(err)) {
          break;
        }
        firstErr = err;
      }
    }
    return response;
  }
}

LocalDbTableAdapter.MIN_DATE = new Date(-8640000000000000);
LocalDbTableAdapter.MAX_DATE = new Date(8640000000000000);
LocalDbTableAdapter.RESET_CONNECTION_DELAY_MS = 1000;
LocalDbTableAdapter.RETRY_READ_WAIT_MS = 2000;
LocalDbTableAdapter.RETRY_READ_WAIT_MAX_TIMES = 3;
LocalDbTableAdapter.RETRY_WRITE_MIN_MS = 200;
LocalDbTableAdapter.RETRY_WRITE_MAX_MS = 1600;
LocalDbTableAdapter.RETRY_WRITE_MAX_TIMES = 4;

export default LocalDbTableAdapter;
