import { AssertTrue, ValidateEnumMatchesType } from "@/domains/typing/types";
import { ApiSchema } from "@/modules/api";

export enum PlatformSubscriptionBillingStrategy {
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
}

type ValidPlatformSubscriptionBillingStrategies = ApiSchema["BillingStrategyEnum"];

type _ValidatePlatformSubscriptionBillingStrategy = AssertTrue<
  ValidateEnumMatchesType<
    ValidPlatformSubscriptionBillingStrategies,
    PlatformSubscriptionBillingStrategy
  >
>;
