import { useFloatingTooltipContext } from "@/design-system/components/tooltip/utils/useFloatingTooltipContext";
import { useMergeRefs } from "@floating-ui/react";
import React from "react";

/**
 * Implementation heavily inspired from the floating-ui example docs here:
 * - https://floating-ui.com/docs/tooltip
 *
 * Prefer to use MdsTooltip directly, instead of using this.
 */
export const FloatingTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useFloatingTooltipContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  return (
    <div
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </div>
  );
});
