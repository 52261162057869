export function easeInCubic({
  currentTime,
  startValue,
  changeInValue,
  duration,
}: {
  currentTime: number;
  startValue: number;
  changeInValue: number;
  duration: number;
}): number {
  currentTime /= duration;
  return changeInValue * currentTime * currentTime * currentTime + startValue;
}
