import { generateShortDateString } from "@/domains/date/date";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export const collectionsLensSortFunctionMap: {
  [key in SortByKind]: (a: CollectionObservable, b: CollectionObservable) => number;
} = {
  [SortByKind.LastModified]: (a, b) =>
    new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime(),
  [SortByKind.LastCreated]: (a, b) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  [SortByKind.LastViewed]: (a, b) =>
    new Date(b.spaceAccountCollection?.lastViewedAt || b.createdAt).getTime() -
    new Date(a.spaceAccountCollection?.lastViewedAt || a.createdAt).getTime(),
  [SortByKind.Alphabetical]: (a, b) => a.label.localeCompare(b.label),
};

export const collectionsLensDateLabelMap: {
  [key in SortByKind]: (collection: CollectionObservable) => string;
} = {
  [SortByKind.LastModified]: collection => generateShortDateString(collection.modifiedAt),
  [SortByKind.LastCreated]: collection => generateShortDateString(collection.createdAt),
  [SortByKind.LastViewed]: collection =>
    generateShortDateString(
      collection.spaceAccountCollection?.lastViewedAt || collection.createdAt
    ),
  [SortByKind.Alphabetical]: collection => generateShortDateString(collection.modifiedAt),
};

export const collectionsLensFilterFunctionMap: {
  [key in LensKind]: (collection: CollectionObservable) => boolean;
} = {
  [LensKind.All]: () => true,
  [LensKind.AddedByMe]: collection => collection.isOwnedByMe,
  [LensKind.SharedWithMe]: collection => !collection.isOwnedByMe,
};
