import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { Optional } from "@/domains/common/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import {
  FavoriteItemModelData,
  FavoriteItemUpsertedSyncUpdateValue,
} from "@/store/favorite-items/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IAddNoteToFavoritesOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";
import { generateKeyBetween } from "fractional-indexing";

export class AddNoteToFavoritesOperation extends BaseSyncOperation<IAddNoteToFavoritesOperation> {
  get operationKind(): SyncOperationKind {
    return "ADD_NOTE_TO_FAVORITES";
  }

  // Need to override constructor to make sort_key optional
  constructor({
    store,
    payload: providedPayload,
    ...params
  }: Omit<BaseSyncOperationParams<IAddNoteToFavoritesOperation>, "payload"> & {
    payload: Optional<IAddNoteToFavoritesOperation["payload"], "schema_version" | "sort_key">;
  }) {
    const lastItemSortKey = store.favoriteItems.sortedFavoriteItems.at(-1)?.sortKey || null;
    const payload = {
      ...providedPayload,
      sort_key: providedPayload.sort_key ?? generateKeyBetween(lastItemSortKey, null),
      schema_version: providedPayload.schema_version ?? 1,
    };
    super({ ...params, store, payload });
  }

  get successToastMessage() {
    return <>Pinned {this.mediumTitle} to sidebar</>;
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const modelId = uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.note_id,
    });
    const favoriteItem = this.store.favoriteItems.getFavoriteItemObservableById({
      favoriteItemId: modelId,
    });

    const value: FavoriteItemUpsertedSyncUpdateValue = {
      model_id: modelId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItem?.modelVersion ?? 0,
      model_data: {
        space_account_id: spaceAccountId,
        item_id: this.payload.note_id,
        item_kind: "NOTE",
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        sort_key: this.payload.sort_key,
      },
      model_scopes: favoriteItem
        ? favoriteItem.modelScopes
        : [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: SyncUpdate<FavoriteItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  get title() {
    return this.store.notes.get(this.payload.note_id)?.title || UNTITLED_NOTE_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  protected getToastMessage() {
    return (
      <>
        {this.mediumTitle} could not be pinned to the sidebar. If this error continues, please
        contact support.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }
}
