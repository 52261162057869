import { isString } from "lodash-es";

import { RuntimeParseError } from "@/domains/errors";
import { _extractArrayFromObject } from "@/modules/object/extractors/extractArray";

export const _extractStringArrayFromObject = (value: unknown, prop: string): string[] => {
  const extractedValue = _extractArrayFromObject(value, prop);

  const extractedValues = extractedValue.map(val => {
    if (!isString(val)) {
      throw new RuntimeParseError({
        message: "[_extractStringArrayFromObject] extractedValue in array was not a string.",
        info: {
          extractedValue,
          value,
          prop,
        },
      });
    }

    return val;
  });

  return extractedValues;
};
