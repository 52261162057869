import { SyncActionQueueInfoOverlay } from "@/components/error-handling/SyncActionQueueInfoOverlay";
import { StandardSyncErrorOverlay } from "@/components/error-handling/SyncErrorOverlay";
import { OnboardingOverlay } from "@/components/onboarding/OnboardingOverlay";
import { MessageOverlay } from "@/components/overlay/message-overlay/MessageOverlay";
import { StripeSubscriptionInterstitialOverlay } from "@/components/subscriptions/StripeSubscriptionInterstitialOverlay";

export const AuthOverlay = () => {
  return (
    <>
      <StripeSubscriptionInterstitialOverlay />
      <OnboardingOverlay />
      <MessageOverlay />
      <StandardSyncErrorOverlay />
      <SyncActionQueueInfoOverlay />
    </>
  );
};
