import { AuthCardEmailPasswordButtonBase } from "@/components/auth/email-password/AuthCardEmailPasswordButtonBase";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export interface AuthCardLogInWithEmailPasswordButtonProps {
  onClick: () => void;
}

export const AuthCardLogInWithEmailPasswordButton: FC<AuthCardLogInWithEmailPasswordButtonProps> =
  observer(({ onClick }) => {
    return <AuthCardEmailPasswordButtonBase label="Log in with Email" onClick={onClick} />;
  });
