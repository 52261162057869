import styled from "@emotion/styled";

interface MdsPanelResponsiveProps {
  minWidth?: string;
  maxWidth?: string;
}

export const MdsPanelResponsive = styled.div<MdsPanelResponsiveProps>(
  ({ minWidth, maxWidth }) => `
      @container mds-panel ${getContainerQuery(minWidth, maxWidth)} {
          && {
              display: none;
          }
      }
    `
);

function getContainerQuery(minWidth?: string, maxWidth?: string): string {
  // Because this wrapper does NOT render what is inside at the given breakpoint,
  // the container query must be the inverse of what you want to hide.
  if (minWidth && maxWidth) {
    return `not ((min-width: calc(${minWidth})) and (max-width: calc(${maxWidth})))`;
  } else if (minWidth) {
    return `(max-width: calc(${minWidth}))`;
  } else if (maxWidth) {
    return `(min-width: calc(${maxWidth}))`;
  }
  return "";
}
