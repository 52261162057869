export enum MdsTextAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

export enum MdsTextSize {
  XXLarge = "xxlarge",
  XLarge = "xlarge",
  Large = "large",
  Medium = "medium",
  Small = "small",
  XSmall = "xsmall",
  XXSmall = "xxsmall",
}

export enum MdsTextWeight {
  Bold = "bold",
  SemiBold = "semi-bold",
  Medium = "medium",
  Regular = "regular",
}

export enum MdsTextOverflow {
  Regular = "regular",
  Ellipsis = "ellipsis",
  EllipsisWrap = "ellipsis-wrap",
}

export enum MdsTextStylingMode {
  /** @description Use align, size, etc for MdsText. */
  ApplyDefaultStyles = "apply-default-styles",
  /** @description Don't use default align, size, etc for MdsText, preferring to inherit those from the CSS context. */
  InheritStyles = "inherit-styles",
}
