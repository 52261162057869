import { FloatingNotePreview } from "@/design-system/components/item-list/rows/item-preview/FloatingNotePreview";
import { HoveringState } from "@/design-system/components/item-list/rows/item-preview/HoveringState";
import { ChatMessageSectionValue } from "@/domains/chat";
import { Maybe } from "@/domains/common/types";
import { useAppStore } from "@/store";
import { NoteObservable } from "@/store/note/NoteObservable";
import styled from "@emotion/styled";
import {
  MemCommonMarkdown,
  MemRoutableItemKind,
  type OnMemItemOver,
  type OnMemItemOut,
  MemCommonMarkdownProps,
} from "@mem-labs/common-editor";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";

interface Props {
  data: ChatMessageSectionValue;
  dataTestId?: string;
  className?: string;
  isIncomplete?: boolean;
}

export const Text = observer<Props>(function Text({ data, dataTestId, className, isIncomplete }) {
  const { content } = data;
  const [hoverState] = useState(new HoveringState());
  const [hoveredNote, setHoveredNote] = useState<Maybe<NoteObservable>>();
  const [hoveredText, setHoveredText] = useState<string | undefined>(undefined);
  const appStore = useAppStore();

  const handleMemItemOver: OnMemItemOver = useCallback(
    (itemKind, itemId, target) => {
      if (itemKind === MemRoutableItemKind.NOTES) {
        hoverState.hoveredRect = target.getBoundingClientRect();
        setHoveredText(target.innerText);

        setHoveredNote(appStore.store.notes.getNoteObservableById({ noteId: itemId }));
        hoverState.handleMouseEnter();
      }

      // TODO: implement Collection preview?
    },
    [appStore.store.notes, hoverState]
  );

  const handleMemItemOut: OnMemItemOut = useCallback(() => {
    hoverState.hoveredRect = null;
    setHoveredNote(undefined);
    hoverState.handleMouseLeave();
  }, [hoverState]);

  return (
    <>
      <div data-test-id={`${dataTestId}-text`}>
        <Markdown
          onMemItemOver={handleMemItemOver}
          onMemItemOut={handleMemItemOut}
          className={className}
          isIncomplete={isIncomplete}
        >
          {content}
        </Markdown>
      </div>
      <FloatingNotePreview
        state={hoverState}
        noteObservable={hoveredNote}
        placement="right"
        highlightText={hoveredText}
      />
    </>
  );
});

interface MarkdownProps extends MemCommonMarkdownProps {
  isIncomplete?: boolean;
}

const Markdown = styled(MemCommonMarkdown)<MarkdownProps>(({ theme, isIncomplete }) => ({
  width: "100%",
  color: isIncomplete ? theme.colors.grey.x500 : theme.colors.grey.x600,
}));
