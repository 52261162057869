import styled from "@emotion/styled";
import { VirtuosoMessageListLicense, VirtuosoMessageList } from "@virtuoso.dev/message-list";
import type {
  ContextAwareComponent,
  ItemContent,
  VirtuosoMessageListMethods,
  VirtuosoMessageListProps,
} from "@virtuoso.dev/message-list";

export interface MessagesProps<Message, MessageContext>
  extends VirtuosoMessageListProps<Message, MessageContext> {
  ItemContent?: ItemContent;
  EmptyPlaceholder?: ContextAwareComponent;
  virtuosoRef?: React.RefObject<VirtuosoMessageListMethods>;
  dataTestId?: string;
  style?: React.CSSProperties;
  StickyFooter?: ContextAwareComponent;
  Footer?: ContextAwareComponent;
  Header?: ContextAwareComponent;
  StickyHeader?: ContextAwareComponent;
  isVisible?: boolean;
}

export function Messages<Message, MessageContext>({
  initialData,
  context,
  ItemContent,
  virtuosoRef,
  EmptyPlaceholder,
  StickyFooter,
  Footer,
  StickyHeader,
  Header,
  initialLocation,
  style,
  dataTestId,
  isVisible,
}: MessagesProps<Message, MessageContext>) {
  return (
    <VirtuosoMessageListLicense licenseKey={import.meta.env.VITE_VIRTUOSO_KEY}>
      <StyledVirtuosoMessageList
        ref={virtuosoRef}
        context={context}
        initialData={initialData}
        initialLocation={initialLocation}
        ItemContent={ItemContent}
        EmptyPlaceholder={EmptyPlaceholder}
        StickyFooter={StickyFooter}
        StickyHeader={StickyHeader}
        Header={Header}
        Footer={Footer}
        shortSizeAlign="bottom-smooth"
        data-test-id={dataTestId}
        style={style}
        isVisible={isVisible}
      />
    </VirtuosoMessageListLicense>
  );
}

interface StyledVirtuosoMessageListProps {
  isVisible?: boolean;
}

const StyledVirtuosoMessageList = styled(VirtuosoMessageList, {
  shouldForwardProp: prop => prop !== "isVisible",
})<StyledVirtuosoMessageListProps>(({ theme, style, isVisible }) => ({
  color: theme.colors.secondary.x200,
  opacity: isVisible ? 1 : 0,
  transition: "opacity 0.3s ease-in-out",

  "::-webkit-scrollbar": {
    display: "none",
  },

  ...style,
}));
