import { observer } from "mobx-react-lite";
import { FC } from "react";
import { AuthCardGoogleButtonBase } from "@/components/auth/google/AuthCardGoogleButtonBase";

export interface AuthCardSignUpWithGoogleButtonProps {
  onClick: () => void;
}

export const AuthCardSignUpWithGoogleButton: FC<AuthCardSignUpWithGoogleButtonProps> = observer(
  ({ onClick }) => {
    return (
      <AuthCardGoogleButtonBase
        label="Sign up with Google"
        onClick={onClick}
        testId="sign-up-with-google-button"
      />
    );
  }
);
