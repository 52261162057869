import { FC, FormEvent, useCallback, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { css, cx } from "@/domains/emotion";
import { MdsButton } from "@/design-system/components/button";
import { mdsColors, mdsFontSizes, mdsFontWeights } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import { StripeFormPaymentSuccessHandler } from "@/components/subscriptions/form/types";
import { isNull } from "lodash-es";
import { PlatformSubscriptionBillingStrategy } from "@/store/platform-subscription/types";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";

const stripeCardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      fontSize: "15px",
      color: "#424770",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
  },
};

export interface StripePaymentFormContentProps {
  totalText: string;
  billingStrategy: PlatformSubscriptionBillingStrategy;
  stripeClientSecret: string | null;
  stripeFormPaymentSuccessHandler: StripeFormPaymentSuccessHandler;
}

export const StripePaymentFormContent: FC<StripePaymentFormContentProps> = observer(
  ({ totalText, billingStrategy, stripeClientSecret, stripeFormPaymentSuccessHandler }) => {
    const stripe = useStripe();
    const elements = useElements();
    const card = elements?.getElement(CardElement);

    const [stripeIsLoading, setStripeIsLoading] = useState<boolean>(true);

    const setStripeIsReady = useCallback(() => {
      setStripeIsLoading(false);
    }, []);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const handleSubmit = useCallback(
      async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !card || !!errorMessage || isNull(stripeClientSecret)) return;

        setIsProcessing(true);
        try {
          const { error, setupIntent } = await stripe.confirmCardSetup(stripeClientSecret, {
            payment_method: { card },
          });

          if (error) {
            logger.error({
              message: "[StripePaymentFormContent]: Error.",
              info: { error: objectModule.safeAsJson({ ...error }) },
            });

            setErrorMessage(error.message);
          } else if (setupIntent?.status === "succeeded") {
            await stripeFormPaymentSuccessHandler({
              billingStrategy,
            });

            setErrorMessage(undefined);
          } else {
            const errorMessage = setupIntent?.last_setup_error?.message ?? "Unknown error";

            logger.error({
              message: "[StripePaymentFormContent]: Unknown Status.",
              info: { errorMessage },
            });

            setErrorMessage(errorMessage);
          }
        } catch (unknownErr: unknown) {
          const err = unknownErr as Error;

          logger.error({
            message: "[StripePaymentFormContent]: Failure.",
            info: { err: objectModule.safeErrorAsJson(err) },
          });

          setErrorMessage(err.message);
        } finally {
          setIsProcessing(false);
        }
      },
      [
        errorMessage,
        stripeClientSecret,
        stripe,
        card,
        stripeFormPaymentSuccessHandler,
        billingStrategy,
      ]
    );

    if (isNull(stripeClientSecret)) {
      return null;
    }

    return (
      <form className={containerStyles} onSubmit={handleSubmit}>
        <section className={paymentSectionStyles}>
          <div className={cardElementWrapperStyles}>
            <CardElement options={stripeCardElementOptions} onReady={setStripeIsReady} />
          </div>
          {errorMessage && (
            <div className={cx(cardElementCaptionStyles, errorMessageStyles)}>{errorMessage}</div>
          )}
          <div className={cardElementCaptionStyles}>Powered by Stripe</div>
        </section>
        <div className={billingSummaryStyles}>
          <span>Today{"'"}s total:</span>
          <span>{totalText}</span>
        </div>
        <MdsButton label="Subscribe" isDisabled={stripeIsLoading || isProcessing} />
      </form>
    );
  }
);

const containerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 24,
});

const paymentSectionStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
});

const cardElementCaptionStyles = css({
  fontWeight: mdsFontWeights().medium,
  fontSize: mdsFontSizes().xsmall,
  color: mdsColors().grey.x500,
  textAlign: "left",
});

const errorMessageStyles = css({
  color: mdsColors().primary.x500,
});

const cardElementWrapperStyles = css({
  width: "100%",
  borderRadius: "8px",
  padding: "12px 8px 12px 8px",
  border: "1px solid #E8E9ED",
});

const billingSummaryStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignSelf: "flex-start",
  width: "100%",
  margin: "16px 0 0 0",
  fontSize: mdsFontSizes().small,
  fontWeight: mdsFontWeights().semiBold,
  color: mdsColors().grey.x600,
});
