import {
  GOOGLE_IDENTITY_OAUTH_URL,
  GOOGLE_IDENTITY_WEB_CLIENT_ID,
} from "@/domains/google-identity/constants";
import { _googleIdentityOAuthRedirectUrl } from "@/modules/google-identity/redirect";

/**
 * Details about the oauth flow can be found here:
 * - https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
 */
export const googleIdentityTriggerLogIn = () => {
  const redirectUrl = _googleIdentityOAuthRedirectUrl();

  const params = new URLSearchParams({
    client_id: GOOGLE_IDENTITY_WEB_CLIENT_ID,
    response_type: "code",
    scope: "email profile",
    access_type: "offline",
    redirect_uri: redirectUrl,
    prompt: "select_account",
  });

  const logInUrl = GOOGLE_IDENTITY_OAUTH_URL + `?${params}`;

  /**
   * Note that this redirects the user to the google log-in page.
   * (Active state in the app will be lost, but recovered on re-direct.)
   */
  window.location.assign(logInUrl);
};
