import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { EmotionClassStyles } from "@/domains/emotion/types";
import {
  InboundSyncState,
  InboundSyncStatus,
  OutboundSyncState,
  OutboundSyncStatus,
} from "@/components/sync/types";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { css } from "@/domains/emotion";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { SyncStatusLastSyncedText } from "@/components/sync/SyncStatusLastSyncedText";

interface SyncStatusLabelProps extends EmotionClassStyles {
  inboundSyncState: InboundSyncState;
  outboundSyncState: OutboundSyncState;
}

export const SyncStatusLabel: FC<SyncStatusLabelProps> = observer(
  ({ inboundSyncState, outboundSyncState }) => {
    const iconKind = useMemo(() => {
      /**
       * This shouldn't happen, but including for completeness.
       */
      if (
        inboundSyncState.status === InboundSyncStatus.Unknown ||
        outboundSyncState.status === OutboundSyncStatus.Unknown
      ) {
        return MdsIconKind.Question;
      }

      if (
        inboundSyncState.status === InboundSyncStatus.PausedOffline ||
        outboundSyncState.status === OutboundSyncStatus.PausedOffline ||
        outboundSyncState.status === OutboundSyncStatus.PausedUnknown
      ) {
        return MdsIconKind.CloudSlash;
      }

      return null;
    }, [inboundSyncState.status, outboundSyncState.status]);

    return (
      <div className={labelStyles}>
        {iconKind && <MdsIcon kind={iconKind} className={iconStyles} />}
        <SyncStatusLastSyncedText
          inboundLastSyncedAt={inboundSyncState.info.lastSyncedAt}
          outboundLastSyncedAt={outboundSyncState.info.lastSyncedAt}
        />
      </div>
    );
  }
);

const labelStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: 6,
  fontSize: mdsFontSizes().small,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 6,
  paddingBottom: 6,
  color: mdsColors().grey.x500,
});

const iconStyles = css({
  color: mdsColors().grey.x500,
});
