import { CacheKey, CACHE_SCHEMA, CacheSchemaType } from "@/modules/cache/constants";
import { CacheClientDB } from "@/manager/cache-client/db/CacheClientDB";
import { objectModule } from "@/modules/object";
import { logger } from "@/modules/logger";
import { enumModule } from "@/modules/enum";

export class CacheClient {
  db: CacheClientDB;

  constructor({ databaseId, version }: { databaseId: string; version: number }) {
    const db = new CacheClientDB({
      id: databaseId,
      version,
    });

    this.db = db;
  }

  initialize = async () => {
    await this.db.initialize();
  };

  get cache() {
    return this.db.cacheTable();
  }

  set =
    (scopeId: string) =>
    async <TCacheKey extends CacheKey, TValue extends CacheSchemaType[TCacheKey]>(
      cacheKey: TCacheKey,
      payload: TValue
    ): Promise<boolean> => {
      const schemaId = cacheKey;
      const schema = CACHE_SCHEMA[schemaId];

      try {
        const parsedValue = schema.parse(payload);
        const serializedData = objectModule.toJson({ data: parsedValue });

        await this.cache.put({
          scopeId,
          cacheKey,
          schemaId,
          payload: serializedData,
        });

        return true;
      } catch (unknownErr) {
        const err = unknownErr as Error;
        const originalError = objectModule.safeErrorAsJson(err);

        const errorMessage = "[Cache.set] Failed to serialize cache data using Zod schema.";
        const errorInfo = { cacheKey, payload, originalError };
        logger.warn({ message: errorMessage, info: objectModule.safeAsJson({ errorInfo }) });

        return false;
      }
    };

  get =
    (scopeId: string) =>
    async <TCacheKey extends CacheKey, TValue extends CacheSchemaType[TCacheKey]>(
      cacheKey: TCacheKey
    ): Promise<TValue | null> => {
      const cacheItem = await this.cache.get([scopeId, cacheKey]);

      if (!cacheItem) {
        return null;
      }

      const schemaId = cacheItem.schemaId;
      const targetSchemaId = enumModule.values(CacheKey).find(cacheKey => cacheKey === schemaId);
      const targetSchema = targetSchemaId && CACHE_SCHEMA[targetSchemaId];

      if (!targetSchema) {
        const errorMessage = "[Cache] Failed to parse cache schema.";
        const errorInfo = { cacheKey, cacheItem };
        logger.warn({ message: errorMessage, info: { errorInfo } });

        return null;
      }

      try {
        const jsonData = objectModule.fromJson(cacheItem.payload);
        const jsonPayload = jsonData.data;
        const payload = targetSchema.parse(jsonPayload);

        /**
         * We cast this because we know zod will enforce the correct mappings.
         */
        return payload as TValue;
      } catch (unknownErr) {
        const err = unknownErr as Error;
        const originalError = objectModule.safeErrorAsJson(err);

        const errorMessage = "[Cache] Failed to parse cache data using Zod schema.";
        const errorInfo = { cacheKey, cacheItem, originalError };
        logger.warn({ message: errorMessage, info: { errorInfo } });

        /**
         * We clear out the bad data.
         */
        await this.del(scopeId)(cacheKey);

        return null;
      }
    };

  del =
    (scopeId: string) =>
    async <TCacheKey extends CacheKey>(cacheKey: TCacheKey): Promise<boolean> => {
      try {
        await this.cache.delete([scopeId, cacheKey]);
        return true;
      } catch (unknownErr) {
        const err = unknownErr as Error;
        const originalError = objectModule.safeErrorAsJson(err);

        const errorMessage = "[Cache.delete] Failed to clear cached data.";
        const errorInfo = { cacheKey, originalError };
        logger.warn({ message: errorMessage, info: { errorInfo } });

        return false;
      }
    };
}
