import { Color } from "@/modules/color/types";
import { stringModule } from "@/modules/string";
import { castArray } from "lodash-es";

/** Note that 359 is prime */
const maxHue = 359;

/** Buckets which colors are sorted into. */
const DEFAULT_LIGHTNESS_LEVELS = [0.35, 0.5, 0.65];
const DEFAULT_SATURATION_LEVELS = [0.35, 0.5, 0.65];

type HashStringToColorConfig = {
  lightness?: number | number[];
  saturation?: number | number[];
};

export const _hashStringToColor = ({
  str,
  config,
}: {
  str: string;
  config?: HashStringToColorConfig;
}): Color => {
  const integerHash = stringModule.generateIntegerHash({
    str,
  });

  const lightnessLevels = castArray(config?.lightness ?? DEFAULT_LIGHTNESS_LEVELS);

  const saturationLevels = castArray(config?.saturation ?? DEFAULT_SATURATION_LEVELS);

  /**
   * Matches the transformations applied by the `color-hash` library.
   * - https://github.com/zenozeng/color-hash
   */
  const hueHash = integerHash;
  const saturationHash = Math.ceil(integerHash / 360);
  const lightnessHash = Math.ceil(saturationHash / saturationLevels.length);

  const hueValue = hueHash % maxHue;

  const saturationLevel = saturationHash % saturationLevels.length;
  const saturationValue = lightnessLevels[saturationLevel];

  const lightnessLevel = lightnessHash % lightnessLevels.length;
  const lightnessValue = lightnessLevels[lightnessLevel];

  return {
    hue: hueValue,
    saturation: saturationValue,
    lightness: lightnessValue,
  };
};
