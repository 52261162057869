import { SpringAnimator } from "@/design-system/components/animation";

interface DropdownAnimationProps {
  children: React.ReactNode | React.ReactNode[];
  innerStyle?: React.CSSProperties;
  skipAnimation?: boolean;
}

export function DropdownAnimation({
  children,
  innerStyle,
  skipAnimation = false,
}: DropdownAnimationProps) {
  if (skipAnimation) return children;
  return (
    <SpringAnimator
      innerStyle={{
        marginTop: "4px",
        transformOrigin: "top center",
        willChange: "transform",
        ...innerStyle,
      }}
    >
      {children}
    </SpringAnimator>
  );
}
