import { ScreenSize, PanelMode } from "@/design-system/foundations/types";
import { memoize } from "lodash-es";

export const mdsBreakpoints = (): Record<ScreenSize, string> =>
  ({
    [ScreenSize.Phone]: "(max-width: 520px)",
    [ScreenSize.Tablet]: "(min-width: 521px) and (max-width: 900px)",
    [ScreenSize.Desktop]: "(min-width: 901px)",
  }) as const;

export const mdsSidebarWidths = () =>
  ({
    expanded: 240,
    collapsed: 48,
  }) as const;

export const mdsPanelBreakpoint = "688px";

export const mdsPanelWidths = () =>
  ({
    min: 360,
    headerBreakpoint: 688,
    gutter: 8,
  }) as const;

export const mdsPanelModeBreakpoints = memoize(() => {
  const NARROW_WIDTH = 480;
  const PANEL_COUNT = 2;
  const { expanded: sidebarExpanded } = mdsSidebarWidths();
  const { min: panelMin, gutter } = mdsPanelWidths();
  return {
    [PanelMode.Narrow]: `(max-width: ${NARROW_WIDTH - 1}px)`,
    [PanelMode.MediumSinglePanel]: `(min-width: ${NARROW_WIDTH}px) and (max-width: ${sidebarExpanded + 1.5 * panelMin + gutter - 1}px)`,
    [PanelMode.MediumSplitView]: `(min-width: ${NARROW_WIDTH}px) and (max-width: ${sidebarExpanded + PANEL_COUNT * panelMin + PANEL_COUNT * gutter - 1}px)`,
    [PanelMode.WideSinglePanel]: `(min-width: ${sidebarExpanded + 1.5 * panelMin + gutter}px)`,
    [PanelMode.WideSplitView]: `(min-width: ${sidebarExpanded + PANEL_COUNT * panelMin + PANEL_COUNT * gutter}px)`,
  } as const;
});
