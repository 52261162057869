import { AsyncResultError } from "@/modules/async-result/types";
import { BaseError } from "@/domains/errors/kinds/BaseError";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _asyncResultSetError = <TError extends BaseError = any>(
  error: TError
): AsyncResultError => {
  return {
    called: true,
    loading: false,
    error,
    data: undefined,
  };
};
