import { FC } from "react";
import { observer } from "mobx-react-lite";
import { CollectionsViewPageHeader } from "@/pages/collections-view/components/CollectionsViewPageHeader";
import { CollectionsViewPageList } from "@/pages/collections-view/components/CollectionsViewPageList";
import { DeleteCollectionModal } from "@/components/modal/delete-collection/DeleteCollectionModal";
import { DeleteSharedNotesModal } from "@/components/modal/delete-shared-notes/DeleteSharedNotesModal";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { useAppStore } from "@/store";
import { RemoveNotesFromSharedCollectionModal } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModal";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { MakeCollectionPrivateModal } from "@/components/modal/make-collection-private/MakeCollectionPrivateModal";
import { MdsPanelContainer } from "@/design-system/components/panel";

interface CollectionsViewPageProps {}

export const CollectionsViewPage: FC<CollectionsViewPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsViewPage;
  page.useEffects();

  if (page.showNotFound) {
    return <NotFoundPage entityType={EntityNotFoundType.Collection} />;
  }

  return (
    <>
      <MakeCollectionPrivateModal store={page.makeCollectionPrivateModal} />
      <ShareSheetModal store={page.shareSheetModal} />
      <DeleteCollectionModal state={page.deleteCollectionModal} />
      <DeleteSharedNotesModal store={page.deleteSharedNotesModal} />
      <RemoveNotesFromSharedCollectionModal store={page.removeNotesFromSharedCollectionModal} />
      <CollectionsViewPageHeader />
      <MdsPanelContainer>
        <CollectionsViewPageList />
      </MdsPanelContainer>
    </>
  );
});
