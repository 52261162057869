import { Maybe } from "@/domains/common/types";
import { ApiSchema, api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { GuestAppStore } from "@/store/GuestAppStore";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { SyncOperationGuestMode } from "@/store/sync/operations/types";

export class GuestSyncActionQueue extends BaseSyncActionQueue<GuestAppStore> {
  get syncErrorModalFields(): SyncErrorModalFields {
    return {};
  }

  // https://chriscoyier.net/2023/11/15/careful-about-chrome-119-and-beforeunload-event-listeners/
  private beforeUnloadListenerAction = false;

  private registerBeforeUnloadEventListener = () => {
    if (this.beforeUnloadListenerAction) return;

    const beforeUnload = (e: BeforeUnloadEvent) => {
      const hasUnsavedData = this.processing.length > 0;
      if (!hasUnsavedData) return;

      logger.warn({ message: "[CDE][Dexie] Preventing exit due to queued guest mode changes." });
      e.preventDefault();
      return "Changes are being saved. Are you sure you want to leave?";
    };
    window.addEventListener("beforeunload", beforeUnload);
    this.beforeUnloadListenerAction = true;
  };

  saveSyncOperation() {
    this.registerBeforeUnloadEventListener();
  }

  saveAcknowledgedSyncOperation() {
    this.registerBeforeUnloadEventListener();
  }

  removeSyncOperation() {
    this.registerBeforeUnloadEventListener();
  }

  async loadAllItems() {
    return {
      acknowledgedSyncOperations: [],
      syncOperations: [],
      optimisticUpdates: [],
    };
  }

  async processOperation(
    operation: SyncOperationGuestMode
  ): Promise<Maybe<SyncOperationGuestMode>> {
    const response = await api.post(`/v2/sync/guest-mode/operations/submit`, {
      params: { query: { space_id: this.getSpaceId() } },
      body: operation.generateSyncOperationGuestMode(),
    });
    console.debug("[SYNC][SyncActionQueue] Synced operation", operation, response);

    // HANDLE COMPLETED OPERATIONS
    if (
      !response.error &&
      response.data.status === "COMPLETED" &&
      "sync_operation" in response.data
    ) {
      operation.acknowledge(response.data.sync_operation.value.latest_space_account_sequence_id);
      return operation;
    }

    // HANDLE SKIPPED OPERATIONS
    if (!response.error && response.data.status === "SKIPPED") {
      this.skipAndRevertOperation(operation);
      return;
    }

    // HANDLE CUSTOM ERRORS
    if (
      !response.error &&
      response.data.status === "FAILED" &&
      "info" in response.data &&
      "error_data" in response.data.info
    ) {
      const errorInfo = response.data.info
        .error_data as ApiSchema["SubmitSyncOperationFailedCustomResponseData"];
      this.handleCustomError(operation, errorInfo);
      return;
    }

    // HANDLE UNKNOWN ERRORS
    this.handleCustomError(operation, { kind: "UNKNOWN" });
  }
}
