import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { MdsIconButtonShape } from "@/design-system/components/icon-button/types";
import { getIconKind } from "@/design-system/components/item-list/rows/icon/icon-kind";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import styled from "@emotion/styled";

interface Props {
  isSelected?: boolean;
  isHighlighted?: boolean;
  toggleSelected: (event: React.MouseEvent) => void;
}

export function NoteIcon({ isSelected, isHighlighted, toggleSelected }: Props) {
  return (
    <Wrapper isSelected={isSelected}>
      <MdsIconButton
        onClick={toggleSelected}
        size={MdsIconButtonSize.Medium}
        selected={isHighlighted}
        shape={MdsIconButtonShape.Square}
        variant={MdsIconButtonVariant.FullyTransparent}
        iconStyles={getIconStyles(isSelected)}
        iconKind={getIconKind({ isSelected, isHighlighted, defaultIcon: MdsIconKind.Document })}
      />
    </Wrapper>
  );
}

interface WrapperProps {
  isSelected?: boolean;
}

const Wrapper = styled.div<WrapperProps>(({ theme, isSelected }) => ({
  width: 40,
  height: 40,
  backgroundColor: isSelected ? theme.colors.blue.x400 : theme.colors.grey.x50,
  borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const getIconStyles = (isSelected?: boolean) => {
  return css({
    fontSize: mdsFontSizes().large,
    color: isSelected ? mdsColors().white : mdsColors().grey.x400,

    transition: "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
  });
};
