import { MdsDropdownItem } from "@/design-system/components/dropdown";
import { OnClick } from "@/domains/react/types";

export enum MdsItemListKind {
  Flat = "Flat",
  Card = "Card",
}

export enum MdsItemListSize {
  Medium = "Medium",
  Large = "Large",
  XLarge = "XLarge",
}

export type MdsItemAction = {
  label: string;
  onClick: OnClick<{ itemId: string }>;
};

export type MdsItemDropdown = {
  items: MdsDropdownItem[];
};

export enum MdsVirtualizedItemListMode {
  Regular = "Regular",
  Chat = "Chat",
}
