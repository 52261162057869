import { appRoutes } from "@/app/router/appRoutes";
import { Maybe } from "@/domains/common/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { AppStore } from "@/store";
import { NoteObservable } from "@/store/note/NoteObservable";
import {
  NoteContentDocumentModelData,
  NoteContentDocumentUpsertedSyncUpdateValue,
  NoteModelData,
  NoteUpsertedSyncUpdateValue,
} from "@/store/note/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { ICreateNoteOperation } from "@/store/sync/operations/types";
import { SyncUpdate, SyncOperationKind, SyncCustomErrorData } from "@/store/sync/types";

export class CreateNoteOperation extends BaseSyncOperation<ICreateNoteOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_NOTE";
  }

  generateOptimisticUpdates(): SyncUpdate<NoteModelData>[] {
    const noteValue: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: 0,
      model_data: {
        trashed_at: null,
        created_at: this.committedAt,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        modified_by_space_account_ids: [],
        primary_label: "",
        primary_label_strategy: "INFERRED_FROM_CONTENT",
        secondary_label: "",
        secondary_label_strategy: "INFERRED_FROM_CONTENT",
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const noteUpserted: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteValue,
    };

    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.id,
    });
    const noteContentDocumentValue: NoteContentDocumentUpsertedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
      model_data: {
        note_id: this.payload.id,
        encoded_content: "",
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const noteContentDocumentUpserted: SyncUpdate<NoteContentDocumentModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteContentDocumentValue,
    };
    return [noteUpserted, noteContentDocumentUpserted];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.getSyncErrorModalFieldsGenerator("INVALID"));
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.getSyncErrorModalFieldsGenerator("PERMISSION_DENIED"));
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  private getSyncErrorModalFieldsGenerator(kind: SyncCustomErrorData["kind"]) {
    return (store: AppStore) => {
      const operations = this.store.sync.actionQueue.operationsByModelId.get(this.payload.id);
      const maybePendingUpdateOperation = operations?.find(
        e => e.operationKind === "UPDATE_NOTE_CONTENT_USING_DIFF" && !e.acknowledged
      ) as Maybe<UpdateNoteContentUsingDiffOperation>;

      if (!maybePendingUpdateOperation) {
        return new CreateNoteSyncErrorModalFieldsGenerator(store, this.payload.id);
      }

      return maybePendingUpdateOperation?.getSyncErrorModalFieldsGenerator(kind, {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })(store);
    };
  }
}

class CreateNoteSyncErrorModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected noteId: string;
  protected note?: NoteObservable;

  constructor(store: AppStore, noteId: string) {
    super(store);

    this.noteId = noteId;
    this.note = this.store.notes.get(noteId);
  }

  get title() {
    return `“${this.note?.title || UNTITLED_NOTE_TITLE}” could not be created`;
  }

  get message() {
    return `The note will be removed from your Mem`;
  }

  modalActionHandler = () => {
    this.store.sync.actionQueue.skipAndRevertUnsyncedOperationsForModelId(this.noteId);
    this.store.navigation.goUpIfActivePathIs(
      appRoutes.notesView({ params: { noteId: this.noteId } }).path
    );
  };
}
