import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import { orderBy } from "lodash-es";
import { ObservableMap } from "mobx";

export const getQueuedDocumentUpdates = ({
  operationsByModelId,
  id,
}: {
  operationsByModelId: ObservableMap<string, SyncOperationGeneric[]>;
  id: string;
}) => {
  const output: { id: string; encodedContentDiff: string }[] = [];
  const pendingOperations = orderBy(
    operationsByModelId.get(id) || [],
    "locally_committed_at",
    "asc"
  );
  for (const operation of pendingOperations) {
    if (operation.operationKind !== "UPDATE_NOTE_CONTENT_USING_DIFF") continue;
    const op = operation as UpdateNoteContentUsingDiffOperation;
    if (op.payload.id !== id) continue;
    output.push({ id: op.id, encodedContentDiff: op.payload.encoded_content_diff });
  }
  return output;
};
