import { computed, makeObservable } from "mobx";

import { GuestAppSubStore, GuestAppSubStoreArgs } from "@/store/types";
import { Maybe } from "@/domains/common/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { IContact } from "@/store/contacts/types";

export class AppStoreGuestAccountStore extends GuestAppSubStore {
  constructor(injectedDeps: GuestAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      getContactByContactSpaceAccountId: false,
      isGuest: computed,
      sharingAccount: computed,
      sharingNoteTitle: computed,
      myPersonalSpaceAccountId: computed,
    });
  }

  get isGuest() {
    return this.store.auth.isGuestMode;
  }

  get sharingAccount() {
    return this.store.auth.guestInfo?.inviter;
  }

  get sharingNoteTitle() {
    return this.store.auth.guestInfo?.noteTitle ?? UNTITLED_NOTE_TITLE;
  }

  get myPersonalSpaceAccountId() {
    return this.store.auth.guestInfo?.spaceAccountId ?? "fake-personal-space-account-id";
  }

  getContactByContactSpaceAccountId(id: string): Maybe<IContact> {
    return this.sharingAccount?.spaceAccountId === id ? this.sharingAccount : undefined;
  }
}
