import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
} from "@/design-system/foundations";
import { css } from "@/domains/emotion";

/**
 * Note that individual toast styles are set in the `triggerGlobalToast` function.
 */
export const globalToastStyleOverrides = css({
  "--toastify-toast-width": "0",

  ".Toastify__toast-container": {
    position: "fixed",

    ".Toastify__toast": {
      borderRadius: "8px",
      border: `1px solid ${mdsColors().grey.x100}`,
      boxShadow: `0px 2px 5px 0px rgba(69, 79, 104, 0.05)`,
      color: `${mdsColors().grey.x600} !important`,
      display: "inline-flex",
      fontHeight: mdsFontSizes().small,
      fontWeight: mdsFontWeights().regular,
      gap: "16px",
      lineHeight: mdsLineHeights().xsmall,
      maxWidth: "min(800px, 100vw - 48px)",
      minWidth: "340px",
      padding: "10px 16px",
      width: "max-content",

      "@media only screen and (max-width: 480px)": {
        boxShadow: `0px 0px 12px ${mdsColors().grey.x200}`,
        maxWidth: "100vw",
      },

      ".Toastify__toast-body": {
        padding: 0,
      },
    },
  },
});
