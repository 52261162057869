import { moduleFactory } from "@/factories/module";
import { BackendApiClientSchema, BackendApiClientPaths } from "@/manager/backend-api-client/types";
import { GLOBAL_API_CLIENT, GlobalAPIClientType } from "@/modules/api/globalApiClient";

/**
 * Example usage:
 *   const result = await api.get("/v1/health", {});
 *
 * More info can be found here: https://openapi-ts.pages.dev/
 */
const apiModule = moduleFactory.create({
  ...GLOBAL_API_CLIENT,
});

/**
 * We use shorthand for this module, because it is used so often.
 */
export const api = apiModule;

export type ApiClient = GlobalAPIClientType;
export type ApiSchema = BackendApiClientSchema;
export type ApiPaths = BackendApiClientPaths;
