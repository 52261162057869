const TABLE_NAME = "general";
const TABLE_SCHEMA = "";

const CURRENT_AUTH_TOKEN_KEY = "current_auth_token";
const CURRENT_ACCOUNT_ID_KEY = "current_account_id";
const CURRENT_AUTH_STRATEGY_KEY = "current_auth_strategy";

export default {
  TABLE_NAME,
  TABLE_SCHEMA,
  CURRENT_AUTH_TOKEN_KEY,
  CURRENT_ACCOUNT_ID_KEY,
  CURRENT_AUTH_STRATEGY_KEY,
};
