import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsText } from "@/design-system/components/text";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { useGuestAppStore } from "@/store";
import { observer } from "mobx-react-lite";

export const GUEST_MODE_BANNER_HEIGHT = "40px";

export const GuestModeTopBanner = observer(() => {
  const { guestStore } = useGuestAppStore();
  return (
    <div className={containerStyles}>
      <MdsText className={textStyles} overflow={MdsTextOverflow.Ellipsis}>
        Sign up to try all of Mem: The AI notes app that keeps you organized
      </MdsText>
      <MdsButton
        className={buttonStyles}
        innerStyles={{ Label: labelStyles }}
        variant={MdsButtonVariant.Transparent}
        label="Sign up"
        onClick={guestStore.interface.openSignUpUi}
      />
      <MdsButton
        className={buttonStyles}
        innerStyles={{ Label: labelStyles }}
        variant={MdsButtonVariant.Transparent}
        label="Learn more"
        onClick={guestStore.interface.openSignUpUi}
      />
    </div>
  );
});

const containerStyles = css({
  alignItems: "center",
  background: mdsColors().blue.x600,
  color: mdsColors().white,
  display: "flex",
  height: GUEST_MODE_BANNER_HEIGHT,
  justifyContent: "center",
  width: "100%",
});

const textStyles = css({
  color: mdsColors().white,
  fontSize: mdsFontSizes().small,
  lineHeight: mdsFontSizes().medium,
  paddingRight: "10px",
});

const buttonStyles = css({
  border: "none",
  paddingLeft: "10px",
  paddingRight: "10px",
  textDecoration: "underline",
  "&:hover": {
    background: mdsColors().transparent,
  },
});

const labelStyles = css({
  color: mdsColors().white,
});
