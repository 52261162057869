import { useEffect, EffectCallback, useRef } from "react";

/**
 * This is a workaround for the fact that in React18, the default is for useEffect to fire
 * twice on mount. (In StrictMode.)
 */
export const useEffectOnMount = (effect: EffectCallback) => {
  const mounted = useRef(false);
  const dispose = useRef<() => void>();

  useEffect(() => {
    if (mounted.current) {
      // After the second call we can dispose.
      try {
        return dispose.current;
      } finally {
        dispose.current = undefined;
      }
    }

    mounted.current = true;

    dispose.current = effect() ?? undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
