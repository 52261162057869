import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { clientEnvModule } from "@/modules/client-env";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import { ISyncOperation } from "@/store/sync/operations/types";
import { SyncModelData, SyncUpdate } from "@/store/sync/types";

export interface BaseSyncOperationParams<T extends ISyncOperation = ISyncOperation> {
  store: AppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number;
  triggerSuccessToast?: boolean;
}

export abstract class BaseSyncOperation<
  SyncOperation extends ISyncOperation,
> extends BaseSyncOperationGeneric<SyncOperation> {
  protected store: AppStore;
  abstract get operationKind(): SyncOperation["operation_kind"];

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
    triggerSuccessToast,
  }: BaseSyncOperationParams<SyncOperation>) {
    super({
      store,
      payload,
      operationId,
      committedAt,
      latestSpaceAccountSequenceId,
      triggerSuccessToast,
    });
    this.store = store;
  }

  public generateSyncOperation(): SyncOperation {
    const operation: SyncOperation = {
      id: this.operationId,
      client_id: clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperation;
    return operation;
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    return [];
  }

  get successToastMessage(): React.ReactNode {
    return null;
  }

  public execute(): void {
    const syncOp = this.generateSyncOperation();
    if (!syncOp) return;
    this.store.sync.actionQueue.push(this);
    const optimisticUpdates = this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      this.store.sync.actionQueue.applyOptimisticUpdate(syncOp.id, optimisticUpdate);
    }
    const toastContent = this.successToastMessage;
    if (toastContent) {
      toastModule.triggerToast({ content: toastContent, toastId: this.operationId });
    }
  }
}
