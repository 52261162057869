import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useEffect, FC, useState } from "react";
import Modal from "react-modal";

import { css, cx } from "@/domains/emotion";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { mdsColors } from "@/design-system/foundations/colors";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { usePublicAppStore } from "@/store";
import { ZIndex } from "@/domains/design/constants";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

export interface MdsModalProps extends EmotionClassStyles {
  children: ReactNode;
  rootElementId?: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  /** If this is missing handleCloseModal is called when unmounting. */
  handleUnmountModal?: () => void;
  fullscreen?: boolean;
  showOverflow?: boolean;
  modalContentStyles?: Modal.Styles["content"];
  modalOverlayStyles?: Modal.Styles["overlay"];
}

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const closeTimeoutMS = 200;

export const MdsModal: FC<MdsModalProps> = observer(
  ({
    children,
    rootElementId = "root",
    isModalOpen,
    handleCloseModal,
    handleUnmountModal,
    modalContentStyles = {},
    modalOverlayStyles = {},
    className,
    fullscreen,
    showOverflow,
  }) => {
    const { publicStore } = usePublicAppStore();

    const [content, setContent] = useState(children);
    useEffect(() => {
      // Make sure content is not updated when closing modal due to closeTimeoutMS.
      if (isModalOpen) setContent(children);
    }, [children, isModalOpen]);

    if (isModalOpen) {
      publicStore.interface.withBackNavigationOnEscapeDisabled();
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
      event => {
        if (event.key !== `Escape`) return;

        handleCloseModal();
      },
      [handleCloseModal]
    );

    const handleUnmount = handleUnmountModal ?? handleCloseModal;
    useEffectOnMount(() => {
      /**
       * We bind the modal to our app's root element.
       * See: https://reactcommunity.org/react-modal/accessibility/
       */
      const targetId = `#${rootElementId}`;
      Modal.setAppElement(targetId);

      // Avoid re-opening if a parent component is unmounted and then mounted again later.
      return () => {
        handleUnmount();
      };
    });

    /**
     * We leverage `onAfterOpen` and `onAfterClose` to modify the document-scrolling css.
     *
     * For more details, see: https://github.com/reactjs/react-modal/issues/829
     *
     * For a cross-platform solution, we can consider copying some code from:
     * - https://github.com/willmcpo/body-scroll-lock
     *
     * (Note that the body-scroll-lock library seems to be somewhat unmaintained, and is very
     * small, so it'd probably just make sense to pull the code into our project directly.)
     */
    const onAfterOpen = useCallback(() => {
      document.body.style.overflow = "hidden";
    }, []);

    const onAfterClose = useCallback(() => {
      document.body.style.overflow = "unset";
    }, []);

    const combinedContentStyles = cx(contentStyles, fullscreen && fullscreenStyles, className);

    const inlineModalContentStyles: Modal.Styles["content"] = {
      ...(showOverflow && { overflow: "visible" }),
      ...modalContentStyles,
    };

    const inlineModalOverlayStyles: Modal.Styles["content"] = {
      ...modalOverlayStyles,
    };

    const combinedInlineModalStyles: Modal.Styles = {
      content: inlineModalContentStyles,
      overlay: inlineModalOverlayStyles,
    };

    return (
      <Modal
        isOpen={isModalOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={handleCloseModal}
        onAfterClose={onAfterClose}
        style={combinedInlineModalStyles}
        className={{
          base,
          afterOpen,
          beforeClose,
        }}
        overlayClassName={{
          base: overlayBase,
          afterOpen: overlayAfterOpen,
          beforeClose: overlayBeforeClose,
        }}
        closeTimeoutMS={closeTimeoutMS}
      >
        <div onKeyDown={onKeyDown} className={combinedContentStyles}>
          {content}
        </div>
      </Modal>
    );
  }
);

const base = css({
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  borderRadius: mdsBorderRadius().large,
  backgroundColor: mdsColors().grey.x0,
  padding: 0,
  opacity: 0,
  transition: `all ${closeTimeoutMS}ms`,
  transform: "translate(-50%, -50%) scale(0.98)",
  boxShadow: "rgba(0,0,0,0.0) 0 0 16px",
});

const afterOpen = css({
  opacity: 1,
  transform: "translate(-50%, -50%) scale(1)",
  boxShadow: "rgba(0,0,0,0.04) 0 0 16px",
});

const beforeClose = css({
  opacity: "0 !important",
  transform: "translate(-50%, -50%) scale(0.98) !important",
  boxShadow: "rgba(0,0,0,0.0) 0 0 16px",
});

const overlayBase = css({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: ZIndex.StandardModal,
  opacity: 0,
  transition: `all ${closeTimeoutMS}ms`,
  background: "rgba(0, 0, 0, 0.08)",
});

const overlayAfterOpen = css({
  opacity: 1,
});

const overlayBeforeClose = css({
  opacity: "0 !important",
});

const fullscreenStyles = css({
  height: "100dvh",
  width: "100vw",
});
