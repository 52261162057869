import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { api } from "@/modules/api";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

export const SettingsAccountMigrationImporterSection: FC = observer(() => {
  const { store } = useAppStore();

  const importAccountContentFromV1 = useCallback(async () => {
    const isConfirmed = confirm(
      "Are you sure you want to import your notes and collections from Mem 1.0?"
    );

    if (!isConfirmed) {
      return;
    }

    const result = await api.post("/v2/migration/import-account-content", {
      body: {
        config: {
          generate_unique_v2_ids: false,
        },
      },
    });

    if (result.error || !result.data) {
      alert("An error occurred.");
      return;
    }

    console.log(result);
    alert(
      "Import started successfully! Note that this may take up to 1 hour to complete. You'll now be redirected to your notes."
    );
    store.navigation.goToNotes();
  }, [store.navigation]);

  return (
    <>
      <p>
        Import your notes and collections created in Mem 1.0 to jumpstart your experience in Mem
        2.0.
      </p>
      <h3>How it works</h3>
      <ul>
        <li>
          <b>Only notes and collections you created will be imported.</b> Notes and collections
          shared with you by others will not be imported.
        </li>
        <li>
          <b>Importing may take up to 1 hour.</b> The time is dependent on the number of notes and
          collections you have.
        </li>
        <li>
          <b>You cannot undo this import.</b> To remove imported notes or collections, you will need
          to manually delete the imported notes and collections. You can multi-select notes to
          delete them in bulk.
        </li>
        <li>
          <b>Content will be imported from Mem 1.0 account with the same email.</b> If you need to
          import content from another Mem 1.0 account, please contact support.
        </li>
      </ul>
      <br />
      <br />
      <MdsButton
        variant={MdsButtonVariant.Tertiary}
        label="Import all notes and collections from Mem 1.0"
        onClick={importAccountContentFromV1}
      />
    </>
  );
});
