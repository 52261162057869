import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, makeObservable, observable, runInAction } from "mobx";
import { AsyncResult } from "@/modules/async-result/types";
import { Uuid } from "@/domains/global/identifiers";
import { asyncResultModule } from "@/modules/async-result";
import { BaseError } from "@/domains/errors";
import { objectModule } from "@/modules/object";
import pRetry from "p-retry";
import { api } from "@/modules/api";
import { PlatformAccountState } from "@/store/platform-info/types";
import { StripeSetupIntentObservable } from "@/store/platform-subscription/StripeSetupIntentObservable";
import { PlatformSubscriptionBillingStrategy } from "@/store/platform-subscription/types";
import { logger } from "@/modules/logger";

export class AppStorePlatformSubscriptionStore extends AppSubStore {
  fetchStripeSetupIntentState: AsyncResult<StripeSetupIntentObservable>;
  createAndChargeStripeSubscriptionState: AsyncResult<Uuid>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.fetchStripeSetupIntentState = asyncResultModule.setInitial();
    this.createAndChargeStripeSubscriptionState = asyncResultModule.setInitial();

    makeObservable(this, {
      fetchStripeSetupIntentState: observable,
      createAndChargeStripeSubscriptionState: observable,
      fetchStripeSetupIntent: action,
      createAndChargeStripeSubscription: action,
    });
  }

  async fetchStripeSetupIntent() {
    if (this.fetchStripeSetupIntentState.called) {
      return;
    }

    runInAction(() => {
      this.fetchStripeSetupIntentState.called = true;
      this.fetchStripeSetupIntentState.error = undefined;
      this.fetchStripeSetupIntentState.loading = true;
    });

    const response = await api.post("/v2/me/subscriptions/stripe/setup-intent", {
      body: undefined,
    });

    if (response.data) {
      const responseData = response.data;

      runInAction(() => {
        const stripeSetupIntent = new StripeSetupIntentObservable({
          stripeSetupIntentId: responseData.stripe_setup_intent_id,
          stripeSetupIntentClientSecret: responseData.stripe_setup_intent_client_secret,
          stripeCustomerId: responseData.stripe_customer_id,
        });

        this.fetchStripeSetupIntentState.data = stripeSetupIntent;
      });
    } else {
      const err = new BaseError({
        message: "Failed to fetch stripe setup intent for current account.",
        info: { result: objectModule.safeAsJson(response) },
      });

      runInAction(() => (this.fetchStripeSetupIntentState.error = err));
    }

    runInAction(() => (this.fetchStripeSetupIntentState.loading = false));
  }

  async createAndChargeStripeSubscription({
    billingStrategy,
  }: {
    billingStrategy: PlatformSubscriptionBillingStrategy;
  }) {
    await pRetry(async () => {
      const response = await api.post("/v2/me/subscriptions/stripe/charge", {
        body: {
          billing_strategy: billingStrategy,
        },
      });

      if (response.data) {
        const responseData = response.data;

        runInAction(() => {
          logger.debug({
            message:
              "[AppStorePlatformSubscriptionStore] [createAndChargeStripeSubscription] Stripe Subscription Created",
            info: {
              stripeSubscriptionId: responseData.stripe_subscription_id,
            },
          });

          this.store.platformInfo.accountState = PlatformAccountState.Active;
        });

        await this.store.platformInfo.fetchPlatformInfo();
      }

      if (response.error) {
        const err = new BaseError({
          message:
            "[AppStorePlatformSubscriptionStore] [createAndChargeStripeSubscription] Failed.",
          info: { result: objectModule.safeAsJson(response) },
        });

        throw err;
      }
    });
  }
}
