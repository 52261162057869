import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { ObservableMap, action, computed, makeObservable, observable, runInAction } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { BaseError } from "@/domains/errors";
import { objectModule } from "@/modules/object";
import { ApiSchema, api } from "@/modules/api";
import {
  PlatformAccountState,
  PlatformOnboardingState,
  PlatformStripeSubscriptionStatus,
  PlatformTrialState,
} from "@/store/platform-info/types";
import { PlatformTrialInfoObservable } from "@/store/platform-info/PlatformTrialInfoObservable";
import { PlatformStripeSubscriptionInfoObservable } from "@/store/platform-info/PlatformStripeSubscriptionInfoObservable";
import { PlatformOnboardingInfoObservable } from "@/store/platform-info/PlatformOnboardingInfoObservable";
import { PlatformOnboardingInfoProfileObservable } from "@/store/platform-info/PlatformOnboardingInfoProfileObservable";
import pRetry from "p-retry";
import {
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
  buildAsyncData,
} from "@/domains/async/AsyncData";
import { logger } from "@/modules/logger";

export class AppStorePlatformInfoStore extends AppSubStore {
  fetchPlatformInfoState = buildAsyncData<boolean>({});

  accountState: PlatformAccountState;
  trialInfo: PlatformTrialInfoObservable;
  onboardingInfo: PlatformOnboardingInfoObservable;
  stripeSubscriptionMap: ObservableMap<Uuid, PlatformStripeSubscriptionInfoObservable>;

  subscribeNowInterstitialIsOpen: boolean;
  subscribeNowInterstitialOpenedManually: boolean;
  stripeSubscriptionWasCompletedSuccessfully: boolean;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    // TODO: Temporarily set all accounts to active for Alpha
    // PREVIOUSLY: this.accountState = PlatformAccountState.Initial;
    this.accountState = PlatformAccountState.Active;

    this.stripeSubscriptionMap = new ObservableMap();

    this.trialInfo = new PlatformTrialInfoObservable({
      trialEnd: null,
      // TODO: Temporarily set all accounts to trial completed for Alpha
      // PREVIOUSLY trialState: PlatformTrialState.Unknown
      trialState: PlatformTrialState.TrialCompleted,
    });

    this.onboardingInfo = new PlatformOnboardingInfoObservable({
      // TODO: Temporarily set all accounts to onboarding completed for Alpha
      // PREVIOUSLY: onboardingState: PlatformOnboardingState.Unknown
      onboardingState: PlatformOnboardingState.OnboardingCompleted,
      onboardingProfile: new PlatformOnboardingInfoProfileObservable({
        desiredUseCases: null,
        jobRole: null,
        referrer: null,
      }),
      store: this.store,
    });

    this.subscribeNowInterstitialIsOpen = false;
    this.subscribeNowInterstitialOpenedManually = false;
    this.stripeSubscriptionWasCompletedSuccessfully = false;

    makeObservable(this, {
      fetchPlatformInfoState: observable,
      accountState: observable,
      trialInfo: observable,
      onboardingInfo: observable,
      stripeSubscriptionMap: observable,
      subscribeNowInterstitialIsOpen: observable,
      subscribeNowInterstitialOpenedManually: observable,
      stripeSubscriptionWasCompletedSuccessfully: observable,
      shouldShowStripeSubscriptionInterstitial: computed,
      shouldShowTrialInfoBadge: computed,

      stripeSubscriptionCompletedSuccessfully: action,
      openSubscribeNowInterstitial: action,
      dismissStripeSubscriptionInterstitial: action,
      fetchPlatformInfo: action,
      upsertOnboardingProfile: action,
      completeOnboardingAndActivatePlatformTrial: action,
      updatePlatformTrialInfoFromRemoteData: action,
      updateOnboardingInfoFromRemoteData: action,
      initialize: action,
    });
  }

  get shouldShowStripeSubscriptionInterstitial() {
    return this.subscribeNowInterstitialIsOpen;
  }

  get shouldShowTrialInfoBadge() {
    if (this.accountState === PlatformAccountState.Active) {
      return false;
    }

    /**
     * If the trial is in an `unknown` state, it hasn't been set up yet.
     */
    if (this.trialInfo.trialState === PlatformTrialState.Unknown) {
      return false;
    }

    return true;
  }

  stripeSubscriptionCompletedSuccessfully = () => {
    this.stripeSubscriptionWasCompletedSuccessfully = true;
  };

  openSubscribeNowInterstitial = () => {
    this.subscribeNowInterstitialIsOpen = true;
    this.subscribeNowInterstitialOpenedManually = true;
  };

  dismissStripeSubscriptionInterstitial = () => {
    this.subscribeNowInterstitialIsOpen = false;
    this.subscribeNowInterstitialOpenedManually = false;
  };

  async fetchPlatformInfo() {
    runInAction(() => {
      this.fetchPlatformInfoState = asyncDataLoading({});
    });

    const response = await api.get("/v2/me/platform-info", {});

    if (response.data) {
      const responseData = response.data;

      runInAction(async () => {
        this.accountState = responseData.account_state as PlatformAccountState;

        this.updateOnboardingInfoFromRemoteData({
          data: responseData.onboarding_info,
        });

        this.updatePlatformTrialInfoFromRemoteData({
          data: responseData.trial_info,
        });

        const subscriptions = responseData.stripe_subscriptions;
        this.stripeSubscriptionMap.clear();

        for (const subscriptionInfo of subscriptions) {
          const subscriptionObservable = new PlatformStripeSubscriptionInfoObservable({
            id: subscriptionInfo.id,
            stripeSubscriptionId: subscriptionInfo.stripe_subscription_id,
            stripeSubscriptionStatus:
              subscriptionInfo.stripe_subscription_status as PlatformStripeSubscriptionStatus,
          });

          this.stripeSubscriptionMap.set(subscriptionObservable.id, subscriptionObservable);
        }
      });
    } else {
      logger.error({
        message: "Failed to fetch platform info for current account.",
        info: { result: objectModule.safeAsJson(response) },
      });

      runInAction(() => {
        this.fetchPlatformInfoState = asyncDataFailed({});
      });
    }

    runInAction(() => {
      this.fetchPlatformInfoState = asyncDataLoaded(this.fetchPlatformInfoState, true);
    });
  }

  async upsertOnboardingProfile({
    onboardingProfile,
  }: {
    onboardingProfile: PlatformOnboardingInfoProfileObservable;
  }) {
    await pRetry(
      async () => {
        const response = await api.post("/v2/me/platform-info/onboarding-info", {
          body: {
            onboarding_profile: {
              desired_use_cases: onboardingProfile.desiredUseCases,
              job_role: onboardingProfile.jobRole,
              referrer: onboardingProfile.referrer,
            },
          },
        });

        if (response.data) {
          this.updateOnboardingInfoFromRemoteData({
            data: response.data.onboarding_info,
          });
        }

        if (response.error) {
          const err = new BaseError({
            message: "Failed to save onboarding info for current account.",
            info: { result: objectModule.safeAsJson(response) },
          });

          throw err;
        }
      },
      {
        retries: 3,
        factor: 2,
        minTimeout: 500,
        onFailedAttempt: () => {},
      }
    );
  }

  async completeOnboardingAndActivatePlatformTrial() {
    await pRetry(async () => {
      const response = await api.post("/v2/me/platform-info/complete-and-activate-trial", {
        body: undefined,
      });

      if (response.data) {
        const responseData = response.data;

        this.updateOnboardingInfoFromRemoteData({
          data: responseData.onboarding_info,
        });

        this.updatePlatformTrialInfoFromRemoteData({
          data: responseData.trial_info,
        });
      }

      if (response.error) {
        const err = new BaseError({
          message: "Failed to save platform info for current account.",
          info: { result: objectModule.safeAsJson(response) },
        });

        throw err;
      }
    });
  }

  updatePlatformTrialInfoFromRemoteData({ data }: { data: ApiSchema["PlatformInfoTrial"] }) {
    runInAction(() => {
      const updatedTrialInfo = PlatformTrialInfoObservable.createFromRemoteData({
        data,
      });

      this.trialInfo.trialState = updatedTrialInfo.trialState;
      this.trialInfo.trialEnd = updatedTrialInfo.trialEnd;
    });
  }

  updateOnboardingInfoFromRemoteData({ data }: { data: ApiSchema["PlatformInfoOnboarding"] }) {
    runInAction(() => {
      const updatedOnboardingInfo = PlatformOnboardingInfoObservable.createFromRemoteData({
        data,
        store: this.store,
      });

      this.onboardingInfo.onboardingState = updatedOnboardingInfo.onboardingState;
      this.onboardingInfo.onboardingProfile = updatedOnboardingInfo.onboardingProfile;
      this.onboardingInfo.activeOnboardingStage =
        PlatformOnboardingInfoObservable.calculateOnboardingStage({
          onboardingProfile: this.onboardingInfo.onboardingProfile,
        });
    });
  }

  async initialize() {
    // TODO: Temporarily set all accounts to active for Alpha
    // PREVIOUSLY await this.fetchPlatformInfo();
  }
}
