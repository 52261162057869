import { useEffect } from "react";
import { makeObservable, observable, computed, runInAction, action } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { SettingsTabId } from "@/store/pages/SettingsPageStore/types";
import { MdsTabBarSection } from "@/design-system/components/tab-bar";

export class SettingsPageStore extends AppSubStore {
  selectedTabId: SettingsTabId;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    this.selectedTabId = SettingsTabId.PersonalPage; // default
    makeObservable(this, {
      selectedTabId: observable,
      setSelectedTabId: action,
      tabBarSections: computed,
      useSettingsPageParams: action,
    });
  }

  setSelectedTabId(tabId: SettingsTabId) {
    runInAction(() => {
      this.selectedTabId = tabId;
    });
  }

  get tabBarSections(): MdsTabBarSection[] {
    const sections = [
      {
        id: "general-section",
        label: "General",
        items: [
          {
            id: SettingsTabId.EmailPreferencesPage,
            label: "Email preferences",
            onClick: () => this.store.navigation.goToSettingsEmailPreferences(),
          },
        ],
      },
      {
        id: "billing-section",
        label: "Billing",
        items: [
          {
            id: SettingsTabId.SubscriptionManagementPage,
            label: "Subscription management",
            onClick: () => this.store.navigation.goToSettingsSubscriptionManagement(),
          },
        ],
      },
      {
        id: "account-section",
        label: "Account",
        items: [
          {
            id: SettingsTabId.AccountManagementPage,
            label: "Account management",
            onClick: () => this.store.navigation.goToSettingsAccountManagement(),
          },
          {
            id: SettingsTabId.AccountMigrationPage,
            label: "Import from Mem 1.0",
            onClick: () => this.store.navigation.goToSettingsAccountMigration(),
          },
          // {
          //   id: SettingsTabId.ExportsPage,
          //   label: "Export mems",
          //   onClick: () => this.store.navigation.goToSettingsExports(),
          // },
        ],
      },
    ];

    if (this.store.debug.debugModeEnabled) {
      sections.push({
        id: "debug-section",
        label: "Debug",
        items: [
          {
            id: SettingsTabId.DebugAccountPage,
            label: "Debug account management",
            onClick: () => this.store.navigation.goToSettingsDebugAccount(),
          },
        ],
      });
    }

    return sections;
  }

  useSettingsPageParams() {
    const settingsAccountManagementMatch = useMatch(
      appRoutes.settingsAccountManagement({ starPattern: true })
    );
    const settingsEmailPreferencesMatch = useMatch(
      appRoutes.settingsEmailPreferences({ starPattern: true })
    );
    const settingsSubscriptionManagementMatch = useMatch(
      appRoutes.settingsSubscriptionManagement({ starPattern: true })
    );
    const settingsExportsMatch = useMatch(appRoutes.settingsExports({ starPattern: true }).path);

    useEffect(() => {
      if (settingsAccountManagementMatch) {
        this.setSelectedTabId(SettingsTabId.AccountManagementPage);
      } else if (settingsEmailPreferencesMatch) {
        this.setSelectedTabId(SettingsTabId.EmailPreferencesPage);
      } else if (settingsSubscriptionManagementMatch) {
        this.setSelectedTabId(SettingsTabId.SubscriptionManagementPage);
      } else if (settingsExportsMatch) {
        this.setSelectedTabId(SettingsTabId.ExportsPage);
      } else {
        this.setSelectedTabId(SettingsTabId.PersonalPage);
      }
    }, [
      settingsAccountManagementMatch,
      settingsEmailPreferencesMatch,
      settingsSubscriptionManagementMatch,
      settingsExportsMatch,
    ]);
  }
}
