import {
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
  toJS,
  action,
  computed,
} from "mobx";
import { api } from "@/modules/api";
import { AccountObservable } from "@/store/account/observables/AccountObservable";
import { cache } from "@/modules/cache";
import { CacheKey } from "@/modules/cache/constants";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { AsyncResult } from "@/modules/async-result/types";
import { Uuid } from "@/domains/global/identifiers";
import { asyncResultModule } from "@/modules/async-result";
import { BaseError, RuntimeAssertionError } from "@/domains/errors";
import { objectModule } from "@/modules/object";

export class AppStoreAccountStore extends AppSubStore {
  accountMap: ObservableMap<Uuid, AccountObservable>;
  fetchMyAccountState: AsyncResult<Uuid>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.accountMap = new ObservableMap();
    this.fetchMyAccountState = asyncResultModule.setInitial();

    makeObservable<
      AppStoreAccountStore,
      "getAccount" | "fetchMyAccount" | "persistMyAccountToCache"
    >(this, {
      getAccount: false,
      fetchMyAccount: action,
      persistMyAccountToCache: action,
      hydrateMyAccountFromCache: action,
      initialize: action,
      myAccountId: computed,
      myAccount: computed,
      updateMyAccountSettings: action,
      resetState: action,
      accountMap: observable,
      fetchMyAccountState: observable,
    });
  }

  private getAccount(accountId: Uuid): AccountObservable | undefined {
    return this.accountMap.get(accountId);
  }

  private async fetchMyAccount() {
    runInAction(() => {
      this.fetchMyAccountState.called = true;
      this.fetchMyAccountState.error = undefined;
      this.fetchMyAccountState.loading = true;
    });

    const result = await api.get("/v1/me/account", {});

    if (result.data) {
      const accountData = result.data;

      const { id, profile_email_address, profile_display_name, profile_photo_url, ...settings } =
        accountData;
      if (!id) {
        throw new RuntimeAssertionError({
          message: "[AccountStore.fetchMyAccount] Account data did not contain an id.",
          info: { accountData },
        });
      }

      runInAction(() => {
        const account = new AccountObservable({
          id,
          profileEmailAddress: profile_email_address,
          profileDisplayName: profile_display_name,
          profilePhotoUrl: profile_photo_url ?? null,
          settings,
        });

        this.accountMap.set(account.id, account);
        this.fetchMyAccountState.data = account.id;
      });
    } else {
      const err = new BaseError({
        message: "Failed to fetch current account.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.fetchMyAccountState.error = err;
      });
    }

    runInAction(() => {
      this.fetchMyAccountState.loading = false;
    });

    await this.persistMyAccountToCache();
  }

  private async persistMyAccountToCache() {
    await cache.set(CacheKey.MyAccount, toJS(this.myAccount));
  }

  async hydrateMyAccountFromCache(): Promise<boolean> {
    const myAccount = await cache.get(CacheKey.MyAccount);

    if (!myAccount) {
      return false;
    }

    const account = new AccountObservable({
      id: myAccount.id,
      profileEmailAddress: myAccount.profileEmailAddress,
      profileDisplayName: myAccount.profileDisplayName,
      profilePhotoUrl: myAccount.profilePhotoUrl ?? null,
      settings: myAccount.settings,
    });

    runInAction(() => {
      this.accountMap.set(account.id, account);
      this.fetchMyAccountState.data = account.id;
    });

    return true;
  }

  async initialize() {
    const fetchMyAccountPromise = this.fetchMyAccount();
    const hydrationSuccess = await this.hydrateMyAccountFromCache();

    /**
     * If we were able to hydrate from cache, we don't need to wait for the API call.
     */
    if (hydrationSuccess) {
      return;
    }

    await fetchMyAccountPromise;

    if (this.fetchMyAccountState.error) {
      throw new RuntimeAssertionError({
        message: "[AccountStore.initialize] Failed.",
        info: { originalError: objectModule.safeErrorAsJson(this.fetchMyAccountState.error) },
      });
    }
  }

  get myAccountId(): Uuid {
    const accountId = this.fetchMyAccountState.data;

    if (!accountId) {
      throw new RuntimeAssertionError({
        message:
          "[AccountStore.myAccountId] accountId was not set - make sure that `initialize` was called.",
      });
    }

    return accountId;
  }

  get myAccount(): AccountObservable {
    const account = this.getAccount(this.myAccountId);

    if (!account) {
      throw new RuntimeAssertionError({
        message:
          "[AccountStore.myAccount] account was not set - make sure that `initialize` was called.",
      });
    }

    return account;
  }

  async updateMyAccountSettings(settings: Record<string, unknown>) {
    const saved = await this.myAccount.updateSettings(settings);
    if (saved) {
      await this.persistMyAccountToCache();
    }
  }

  resetState() {
    runInAction(() => {
      this.accountMap = new ObservableMap();
      this.fetchMyAccountState = asyncResultModule.setInitial();
    });
  }
}
