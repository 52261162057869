import { CoercionError } from "@/domains/errors";
import { Uuid } from "@/domains/global/identifiers";
import { _validateAndNormalizeUuid } from "@/modules/uuid/validation/validateAndNormalize";
import { isString } from "lodash-es";

export const _parseUuidValue = (value: unknown): Uuid => {
  if (!isString(value)) {
    throw new CoercionError({
      message: `[_parseUuidValue]: Could not parse Uuid with value - ${value}`,
    });
  }

  try {
    const parsedValue = _validateAndNormalizeUuid({
      uuidString: value,
    });

    return parsedValue;
  } catch (unknownErr) {
    const err = unknownErr as Error;

    throw new CoercionError({
      message: `[parseValue]: Uuid could not be parsed => ${value}`,
      originalError: err,
    });
  }
};
