import { Color } from "@/modules/color/types";

interface RgbColor {
  red: number;
  green: number;
  blue: number;
}

/**
 * Implementation inspired by the color-hash package.
 * https://github.com/zenozeng/color-hash/blob/main/lib/colors.ts
 */
export const _rgbColorToHex = ({ rgbColor }: { rgbColor: RgbColor }): string => {
  let hex = "#";

  const rgbArray = [rgbColor.red, rgbColor.green, rgbColor.blue];

  rgbArray.forEach(function (value) {
    if (value < 16) {
      hex += 0;
    }

    hex += value.toString(16);
  });

  return hex;
};

/**
 * Implementation inspired by the color-hash package.
 * https://github.com/zenozeng/color-hash/blob/main/lib/colors.ts
 */
export const _colorToRgbColor = ({ color }: { color: Color }): RgbColor => {
  let H = color.hue;
  const S = color.saturation;
  const L = color.lightness;

  H /= 360;

  const q = L < 0.5 ? L * (1 + S) : L + S - L * S;
  const p = 2 * L - q;

  const rgbArray = [H + 1 / 3, H, H - 1 / 3].map(function (color) {
    if (color < 0) {
      color++;
    }
    if (color > 1) {
      color--;
    }
    if (color < 1 / 6) {
      color = p + (q - p) * 6 * color;
    } else if (color < 0.5) {
      color = q;
    } else if (color < 2 / 3) {
      color = p + (q - p) * 6 * (2 / 3 - color);
    } else {
      color = p;
    }
    return Math.round(color * 255);
  });

  return {
    red: rgbArray[0],
    green: rgbArray[1],
    blue: rgbArray[2],
  };
};

export const _colorToHex = ({ color }: { color: Color }): string => {
  const rgbColor = _colorToRgbColor({ color });

  const hex = _rgbColorToHex({ rgbColor });

  return hex;
};
