import { makeObservable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { CollectionMetadataModelData } from "@/store/collections/types";
import { CollectionMetadataObservable } from "@/store/collections/CollectionMetadataObservable";
import { resolveCollectionMetadataModelUuid } from "@/modules/uuid/sync-models/resolveCollectionMetadataModelUuid";

export class AppStoreCollectionMetadataStore extends BaseSyncModelStore<
  CollectionMetadataObservable,
  CollectionMetadataModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.CollectionMetadata, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      getCollectionMetadataObservableByCollectionId: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<CollectionMetadataModelData>) {
    return new CollectionMetadataObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  getCollectionMetadataObservableByCollectionId(
    collectionId: Uuid
  ): CollectionMetadataObservable | undefined {
    const collectionMetadataId = resolveCollectionMetadataModelUuid({ collectionId });
    return this.pool.get(collectionMetadataId);
  }
}
