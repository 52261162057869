import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { usePublicAppStore } from "@/store";
import { isNull } from "lodash-es";

export const useLogOutPageLogic = () => {
  const { publicStore } = usePublicAppStore();

  useEffectOnMount(() => {
    publicStore.auth.logOut();
  });

  /**
   * Once the auth info is cleared, we should redirect to the home page.
   */
  const shouldRedirect = isNull(publicStore.auth.activeAuthStrategy);

  return { shouldRedirect };
};
