import { ApiAuthStrategy } from "@/domains/auth/types";
import { PublicAppSubStoreArgs, PublicAppSubStore } from "@/store/types";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { api } from "@/modules/api";
import { enumModule } from "@/modules/enum";
import { BaseError } from "@/domains/errors";
import {
  AsyncData,
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
  buildAsyncData,
} from "@/domains/async/AsyncData";
import { GuestInfo } from "@/store/auth/types";
import { isString } from "lodash-es";
import { toastModule } from "@/modules/toast";
import { appRoutes } from "@/app/router";

export class AppStoreAuthStore extends PublicAppSubStore {
  activeAuthToken: string | null = null;
  activeAuthStrategy: ApiAuthStrategy | null = null;
  initializationState = buildAsyncData<boolean>({});
  authenticateAccountUsingGoogleOAuthCodeState = buildAsyncData<boolean>({});
  authenticateAccountUsingEmailPasswordState = buildAsyncData<boolean>({});
  guestInfo?: GuestInfo;

  constructor(injectedDeps: PublicAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      initialize: action,
      processSearchParams: action,
      storeAuthToken: action,
      clearAuthToken: action,
      logOut: action,
      authenticateAccountUsingGoogleOAuthCode: action,
      authenticateAccountUsingEmailPassword: action,
      activeAuthToken: observable,
      activeAuthStrategy: observable,
      initializationState: observable,
      authenticateAccountUsingGoogleOAuthCodeState: observable,
      authenticateAccountUsingEmailPasswordState: observable,
      guestInfo: observable,
      isAuthenticated: computed,
      isStandardMode: computed,
      isGuestMode: computed,
      isGuestModeOrStandardMode: computed,
      updateInitializationState: action,
      updateAuthenticateAccountUsingGoogleOAuthCodeState: action,
      updateAuthenticateAccountUsingEmailPasswordState: action,
    });

    reaction(() => this.activeAuthToken, api.setAuthToken);
  }

  get isAuthenticated() {
    return this.isStandardMode;
  }

  get isStandardMode() {
    return this.activeAuthStrategy === ApiAuthStrategy.Account;
  }

  get isGuestMode() {
    return this.activeAuthStrategy === ApiAuthStrategy.GuestAccount;
  }

  get isGuestModeOrStandardMode() {
    return this.isStandardMode || this.isGuestMode;
  }

  updateInitializationState = (f: (state: AsyncData<boolean>) => AsyncData<boolean>) => {
    this.initializationState = f(this.initializationState);
  };

  updateAuthenticateAccountUsingGoogleOAuthCodeState = (
    f: (state: AsyncData<boolean>) => AsyncData<boolean>
  ) => {
    this.authenticateAccountUsingGoogleOAuthCodeState = f(
      this.authenticateAccountUsingGoogleOAuthCodeState
    );
  };

  updateAuthenticateAccountUsingEmailPasswordState = (
    f: (state: AsyncData<boolean>) => AsyncData<boolean>
  ) => {
    this.authenticateAccountUsingEmailPasswordState = f(
      this.authenticateAccountUsingEmailPasswordState
    );
  };

  initialize = async () => {
    this.updateInitializationState(asyncDataLoading);

    try {
      const accountInfo = await localDb.global.getCurrentAuthInfo();

      if (accountInfo?.authToken && accountInfo?.authStrategy) {
        const validValues: string[] = enumModule.values(ApiAuthStrategy);

        if (validValues.includes(accountInfo.authStrategy)) {
          runInAction(() => {
            this.activeAuthToken = accountInfo.authToken;
            this.activeAuthStrategy = accountInfo.authStrategy as ApiAuthStrategy;
          });
        }
      }
    } finally {
      this.updateInitializationState(asyncData => asyncDataLoaded(asyncData, true));
    }
  };

  processSearchParams = (searchParams: URLSearchParams) => {
    if (this.isAuthenticated) return;

    const guestInfoParam = searchParams.get("guest_info");
    if (!guestInfoParam) return;

    const json = JSON.parse(atob(decodeURIComponent(guestInfoParam)));

    runInAction(() => {
      this.guestInfo = {
        spaceAccountId: getStringFromJson(json, "invitee_guest_account_id"),
        inviter: {
          spaceAccountId:
            getStringFromJson(json, "inviter_space_account_id") ?? "fake-inviter-space-account-id",
          profileEmailAddress: "",
          profileDisplayName: getStringFromJson(json, "inviter_display_name") ?? "Someone",
          profilePhotoUrl: getStringFromJson(json, "inviter_photo_url"),
        },
        noteTitle: getStringFromJson(json, "note_title"),
      };
      this.activeAuthToken = getStringFromJson(json, "guest_access_token") ?? null;
      this.activeAuthStrategy = ApiAuthStrategy.GuestAccount;
    });
  };

  storeAuthToken = async ({
    accountId,
    authToken,
    authStrategy,
  }: {
    accountId: Uuid;
    authToken: string;
    authStrategy: ApiAuthStrategy;
  }) => {
    try {
      await localDb.global.setCurrentAuthInfo({ accountId, authToken, authStrategy });
    } catch (unknownErr) {
      /**
       * We currently ignore persistance failures, although the user
       * would have to log in again next time they use the app.
       */
      logger.warn({
        message: "[authenticateAccountUsingGoogleOAuthCode] Failed to persist auth token.",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
    }
  };

  clearAuthToken = async () => {
    try {
      await localDb.global.setCurrentAuthInfo({
        accountId: null,
        authToken: null,
        authStrategy: null,
      });
    } catch (unknownErr) {
      logger.warn({
        message: "[authenticateAccountUsingGoogleOAuthCode] Failed to clear auth token.",
        info: { err: objectModule.safeErrorAsJson(unknownErr as BaseError) },
      });
    }
  };

  logOut = async () => {
    try {
      await this.clearAuthToken();

      runInAction(() => {
        this.activeAuthStrategy = null;
        this.activeAuthToken = null;
        this.authenticateAccountUsingGoogleOAuthCodeState = buildAsyncData({});
      });
    } catch (unknownErr) {
      logger.error({
        message: "",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
    }

    /**
     * Reload after logging out. Forces re-initialization.
     * @TODO Clean this up.
     *
     */
    setTimeout(() => {
      window.location.replace("/");
    }, 300);
  };

  authenticateAccountUsingGoogleOAuthCode = async ({
    code,
    redirectURI,
    isSignUp,
  }: {
    code: string;
    redirectURI: string;
    isSignUp: boolean;
  }) => {
    this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncDataLoading);

    try {
      const result = await (async () => {
        if (isSignUp) {
          return await api.post("/v2/auth/google/sign-up", {
            body: {
              google_oauth_code: code,
              redirect_uri: redirectURI,
              client_kind: "MEM_WEB_CLIENT",
            },
          });
        }

        return await api.post("/v2/auth/google/log-in", {
          body: {
            google_oauth_code: code,
            redirect_uri: redirectURI,
            client_kind: "MEM_WEB_CLIENT",
          },
        });
      })();

      if (!result.data) {
        throw new BaseError({
          message: "[_authActionAuthenticateAccountUsingGoogleOAuthCode] Failed.",
        });
      }

      const authenticatedAccountId = result.data.account_id;
      const token = result.data.oauth_access_token;
      const strategy = ApiAuthStrategy.Account;

      await this.storeAuthToken({
        accountId: authenticatedAccountId,
        authToken: token,
        authStrategy: strategy,
      });

      this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncData =>
        asyncDataLoaded(asyncData, true)
      );
      runInAction(() => {
        this.activeAuthToken = token;
        this.activeAuthStrategy = strategy;
      });
    } catch (unknownErr) {
      toastModule.triggerToast({ content: "Failed to login." });
      logger.error({
        message: "",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
      // Redirect to login page if authentication fails
      this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncDataFailed);
      setTimeout(() => window.location.assign(appRoutes.logIn({}).path), 500);
    }

    /**
     * Reload after authenticating. Forces re-initialization.
     * @TODO Clean this up.
     *
     */
    // window.location.replace("/");
  };

  authenticateAccountUsingEmailPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    this.updateAuthenticateAccountUsingEmailPasswordState(asyncDataLoading);

    try {
      const result = await api.post("/v2/auth/email-password/log-in", {
        body: { email_address: email, password },
      });

      if (!result.data) {
        throw new BaseError({
          message: "[authenticateAccountUsingEmailPassword] Failed.",
        });
      }

      const authenticatedAccountId = result.data.account_id;
      const token = result.data.oauth_access_token;
      const strategy = ApiAuthStrategy.Account;

      await this.storeAuthToken({
        accountId: authenticatedAccountId,
        authToken: token,
        authStrategy: strategy,
      });

      this.updateAuthenticateAccountUsingEmailPasswordState(asyncData =>
        asyncDataLoaded(asyncData, true)
      );
      runInAction(() => {
        this.activeAuthToken = token;
        this.activeAuthStrategy = strategy;
      });
    } catch (unknownErr) {
      toastModule.triggerToast({ content: "Failed to login." });
      logger.error({
        message: "",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
      this.updateAuthenticateAccountUsingEmailPasswordState(asyncDataFailed);
      setTimeout(() => window.location.assign(appRoutes.logIn({}).path), 500);
    }
  };
}

const getStringFromJson = (json: Record<string, unknown>, key: string) => {
  const s = json[key];
  if (isString(s)) return s;
};
