import { motion, AnimationProps } from "framer-motion";

interface Props extends React.PropsWithChildren, AnimationProps {
  shouldAnimate: boolean;
}

export function Animator({ shouldAnimate, children, ...props }: Props) {
  if (!shouldAnimate) {
    return <>{children}</>;
  }

  return <motion.div {...props}>{children} </motion.div>;
}
