import { enumModule } from "@/modules/enum";
import {
  HOME_PARAM_KEY_SORT_ORDER,
  HomeParamKey,
  HomeParams,
  HomeSortByKind,
  HomeFacetFilter,
  HomeFilter,
  HomeLensKind,
  DEFAULT_HOME_LENS,
  DEFAULT_HOME_SORT_BY_KIND,
} from "@/modules/url-params/home-params/types";
import { compact, isArray, isNull, isString } from "lodash-es";
import queryString, { ParsedQuery } from "query-string";

export const stringifyHomeParams = ({
  homeParams,
}: {
  homeParams: Partial<HomeParams>;
}): string => {
  const encodedParams = encodeHomeParams({ homeParams });

  return queryString.stringify(encodedParams, {
    sort: (itemLeft, itemRight) => {
      const matchingLeftKey = enumModule.findMatchingStringValue(HomeParamKey, itemLeft);
      const matchingRightKey = enumModule.findMatchingStringValue(HomeParamKey, itemRight);

      /**
       * We maintain a stable sort even if one of the keys is not found.
       */
      if (!matchingLeftKey) {
        return -1;
      }

      if (!matchingRightKey) {
        return 1;
      }

      return (
        HOME_PARAM_KEY_SORT_ORDER[matchingLeftKey] - HOME_PARAM_KEY_SORT_ORDER[matchingRightKey]
      );
    },
    encode: true,
    arrayFormat: "bracket",
    skipNull: true,
  });
};

export const encodeHomeParams = ({
  homeParams,
}: {
  homeParams: Partial<HomeParams>;
}): ParsedQuery => {
  /**
   * TODO - In the future, we can use the `query-string` library to do better
   * encoding here - e.g. using an easily readable format for arrays instead
   * of JSON stringification.
   */
  const encodedLens = (() => {
    if (!homeParams.lens) {
      return null;
    }

    if (homeParams.lens === DEFAULT_HOME_LENS) {
      return null;
    }

    return homeParams.lens.toString();
  })();

  const encodedSortBy = (() => {
    if (!homeParams.sortBy) {
      return null;
    }

    if (homeParams.sortBy === DEFAULT_HOME_SORT_BY_KIND) {
      return null;
    }

    return homeParams.sortBy.toString();
  })();

  const encodedFilters = (() => {
    if (!homeParams.filters) {
      return null;
    }

    return homeParams.filters.map(filter => JSON.stringify(filter));
  })();

  const encodedFacetFilters = (() => {
    if (!homeParams.facetFilters) {
      return null;
    }

    return homeParams.facetFilters.map(facetFilter => JSON.stringify(facetFilter));
  })();

  return {
    [HomeParamKey.Lens]: encodedLens,
    [HomeParamKey.SortBy]: encodedSortBy,
    [HomeParamKey.Filter]: encodedFilters,
    [HomeParamKey.FacetFilter]: encodedFacetFilters,
  };
};

export const decodeHomeParams = ({ searchQuery }: { searchQuery: ParsedQuery }): HomeParams => {
  const decodedLens = (() => {
    const lensValue = searchQuery[HomeParamKey.Lens];

    if (!isString(lensValue)) {
      return DEFAULT_HOME_LENS;
    }

    const matchingValue = enumModule.findMatchingStringValue(HomeLensKind, lensValue);

    if (matchingValue) {
      return matchingValue;
    }

    return DEFAULT_HOME_LENS;
  })();

  const decodedSortBy = (() => {
    const sortByValue = searchQuery[HomeParamKey.SortBy];

    if (!isString(sortByValue)) {
      return DEFAULT_HOME_SORT_BY_KIND;
    }

    const matchingValue = enumModule.findMatchingStringValue(HomeSortByKind, sortByValue);

    if (matchingValue) {
      return matchingValue;
    }

    return DEFAULT_HOME_SORT_BY_KIND;
  })();

  const decodedFilters = (() => {
    const filterValue = searchQuery[HomeParamKey.Filter];

    if (!isArray(filterValue)) {
      return [];
    }

    const parsedFilters = filterValue.map(filter => {
      if (isNull(filter)) {
        return null;
      }

      const parsedFilter = JSON.parse(filter);

      /**
       * Probably better to use something like `zod` here
       * in the future.
       */
      return parsedFilter as HomeFilter;
    });

    return compact(parsedFilters);
  })();

  const decodedFacetFilters = (() => {
    const facetFilterValue = searchQuery[HomeParamKey.FacetFilter];

    if (!isArray(facetFilterValue)) {
      return [];
    }

    const parsedFacetFilters = facetFilterValue.map(facetFilter => {
      if (isNull(facetFilter)) {
        return null;
      }

      const parsedFacetFilter = JSON.parse(facetFilter);

      /**
       * Probably better to use something like `zod` here
       * in the future.
       */
      return parsedFacetFilter as HomeFacetFilter;
    });

    return compact(parsedFacetFilters);
  })();

  return {
    lens: decodedLens,
    sortBy: decodedSortBy,
    filters: decodedFilters,
    facetFilters: decodedFacetFilters,
  };
};

export const parseHomeParams = ({ searchQueryStr }: { searchQueryStr: string }): HomeParams => {
  const searchQuery = queryString.parse(searchQueryStr, {
    decode: true,
    arrayFormat: "bracket",
  });

  return decodeHomeParams({ searchQuery });
};
