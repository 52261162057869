import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsAccountMigrationDebugSection } from "@/pages/settings/components/SettingsAccountMigrationDebugSection";
import { SettingsAccountMigrationImporterSection } from "@/pages/settings/components/SettingsAccountMigrationImporterSection";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export const SettingsAccountMigrationPage: FC = observer(() => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Import from Mem 1.0">
        <div className={wrapperStyles}>
          <SettingsAccountMigrationImporterSection />
          <SettingsAccountMigrationDebugSection />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

const wrapperStyles = css({
  fontSize: 14,
  lineHeight: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: mdsColors().grey.x700,
});
