import { uuidModule } from "@/modules/uuid";
import {
  FavoriteItemModelData,
  FavoriteItemUpsertedSyncUpdateValue,
} from "@/store/favorite-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class UpdateFavoriteItemSortKeyOperation extends BaseSyncOperation<IUpdateFavoriteItemSortKeyOperation> {
  get operationKind(): SyncOperationKind {
    return "UPDATE_FAVORITE_ITEM_SORT_KEY";
  }

  public generateOptimisticUpdates() {
    const favoriteItemObservable = this.store.favoriteItems.get(this.payload.favorite_item_id);
    if (!favoriteItemObservable) return [];

    const value: FavoriteItemUpsertedSyncUpdateValue = {
      model_id: this.payload.favorite_item_id,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
      model_data: {
        ...favoriteItemObservable.modelData,
        sort_key: this.payload.sort_key,
      },
      model_scopes: favoriteItemObservable.modelScopes,
    };
    const syncUpdate: SyncUpdate<FavoriteItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
