import { FC } from "react";
import { css } from "@/domains/emotion";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { NotificationPreference } from "@/store/pages/SettingsEmailPreferencesPageStore/types";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { mdsColors } from "@/design-system/foundations";

const emailNotificationPreferenceLabels: { [key in NotificationPreference]: string } = {
  EMAIL_WEEKLY_NEWSLETTER: "Weekly tips and updates from Mem",
  EMAIL_USER_SHARE: "Mems shared with me",
};

export const SettingsEmailPreferencesPage: FC = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.settingsEmailPreferencesPage;
  const accountNotificationPreferencesData = page.accountNotificationPreferencesData.data;

  const toggleEmailNotificationPreference = async (key: NotificationPreference) => {
    if (!accountNotificationPreferencesData) return;
    const isEnabled = accountNotificationPreferencesData[key];
    await page.updateNotificationPreference(key, !isEnabled);
  };

  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Email preferences">
        <div className={wrapperStyles}>
          {Object.entries(emailNotificationPreferenceLabels).map(([key, label]) => {
            const preference = key as NotificationPreference;
            return (
              <div key={key} className={selectorStyles}>
                <label className={labelStyles}>{label}</label>
                <input
                  type="checkbox"
                  checked={accountNotificationPreferencesData?.[preference]}
                  className={togglerStyles}
                  onChange={() => toggleEmailNotificationPreference(preference)}
                  disabled={!accountNotificationPreferencesData}
                />
              </div>
            );
          })}
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

const wrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  fontSize: 14,
  lineHeight: "20px",
  width: "100%",
  "div:first-child": {
    marginTop: 0,
  },
});

const labelStyles = css({
  color: mdsColors().grey.x700,
});

const togglerStyles = css({
  appearance: "none",
  border: "none",
  cursor: "pointer",
  width: "36px",
  height: "20px",
  backgroundColor: "#dfe1e4",
  borderRadius: "72px",
  flexShrink: 0,
  margin: "0px",
  position: "relative",
  transition: "all 100ms ease-out 0s",

  ":before": {
    position: "absolute",
    content: '""',
    inset: "-6px",
  },
  ":after": {
    content: '""',
    position: "absolute",
    top: "2px",
    left: "2px",
    background: "#fff",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    transition: "all 100ms ease-out 0s",
  },
  ":checked": {
    backgroundColor: "#3B6DF1",

    "&::after": {
      left: "18px",
      background: "#F5F8FF",
    },
  },
});

const selectorStyles = css({
  marginTop: "24px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});
