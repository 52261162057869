import { MdsTabBarHorizontalItem } from "@/design-system/components/tab-bar-horizontal/MdsTabBarHorizontalItem";
import { MdsTabBarHorizontalProps } from "@/design-system/components/tab-bar-horizontal/types";
import styled from "@emotion/styled";

export const MdsTabBarHorizontal = ({ tabs }: MdsTabBarHorizontalProps) => {
  return (
    <Wrapper>
      {tabs.map(tab => (
        <MdsTabBarHorizontalItem key={tab.id} {...tab} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
});
