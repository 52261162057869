import { AuthOverlay } from "@/app/overlays";
import { SidePanelRouter } from "@/app/router/SidePanelRouter";
import { SplitView } from "@/components/layout";
import { Sidebar } from "@/components/layout/components/sidebar/Sidebar";
import { TopBar } from "@/components/layout/components/TopBar";
import { mdsMediaQueries, mdsSpacings } from "@/design-system/foundations";
import { isMac } from "@/domains/platform/isMac";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { useCallback, useLayoutEffect, useMemo } from "react";
import { Outlet } from "react-router";
import styled from "@emotion/styled";

interface StandardModeAppLayoutOutletProps {}

interface KeyboardShortcut {
  condition: ({ event }: { event: KeyboardEvent }) => boolean;
  key: string;
  action: ({ event }: { event: KeyboardEvent }) => void;
}

export const StandardModeAppLayoutOutlet = observer<StandardModeAppLayoutOutletProps>(() => {
  const { store, pageStore } = useAppStore();

  const keyboardShortcuts: KeyboardShortcut[] = useMemo(
    () => [
      {
        condition: ({ event }) =>
          store.platformInfo.onboardingInfo.onboardingIsCompleted &&
          !pageStore.quickSearchModal.isOpen &&
          (isMac() ? event.ctrlKey : event.altKey),
        key: "n",
        action: store.notes.composeNewNote,
      },
      {
        condition: ({ event }) =>
          store.platformInfo.onboardingInfo.onboardingIsCompleted &&
          (isMac() ? event.metaKey : event.ctrlKey),
        key: "k",
        action: pageStore.quickSearchModal.toggleIsOpen,
      },
      {
        condition: ({ event }) =>
          store.platformInfo.onboardingInfo.onboardingIsCompleted &&
          !pageStore.quickSearchModal.isOpen &&
          (isMac() ? event.metaKey : event.ctrlKey),
        key: "j",
        action: store.navigation.goToChat,
      },
      {
        condition: ({ event }) =>
          store.platformInfo.onboardingInfo.onboardingIsCompleted &&
          // Only available when back button is visible (on CollectionView and NoteView pages)
          (!!store.routing.noteIdParam || !!store.routing.collectionIdParam) &&
          !store.publicAppStore.interface.backNavigationOnEscapeDisabled &&
          !pageStore.collectionsViewPage.listState.isSelectModeActive &&
          !event.shiftKey &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.metaKey,
        key: "Escape",
        action: store.navigation.goBack,
      },
    ],
    [pageStore, store]
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      for (const shortcut of keyboardShortcuts) {
        if (event.key === shortcut.key && shortcut.condition({ event })) {
          event.preventDefault();
          event.stopPropagation();

          shortcut.action({ event });

          return;
        }
      }
    },
    [keyboardShortcuts]
  );

  useLayoutEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown, pageStore.quickSearchModal, store.navigation]);

  return (
    <>
      <AuthOverlay />
      <Wrapper>
        <Sidebar />
        <Main>
          <TopBar />
          <SplitView sidePanel={<SidePanelRouter />}>
            <CenterPanel>
              <Outlet />
            </CenterPanel>
          </SplitView>
        </Main>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div({
  height: "100%",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#FDFBFE",
});

const Main = styled.div({
  height: "100%",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  padding: mdsSpacings().sm,
  [mdsMediaQueries().rules.tablet]: {
    paddingLeft: 0,
  },
  [mdsMediaQueries().rules.desktop]: {
    paddingLeft: 0,
  },
});

const CenterPanel = styled.div({
  width: "100%",
  height: "100%",
});
