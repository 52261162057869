import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import {
  InboundSyncState,
  InboundSyncStatus,
  OutboundSyncState,
  OutboundSyncStatus,
} from "@/components/sync/types";
import { MdsBadge } from "@/design-system/components/badge";
import {
  MdsBadgeKind,
  MdsBadgeSize,
  MdsBadgeVariant,
} from "@/design-system/components/badge/types";
import { MdsIconKind } from "@/design-system/components/icon";

interface SyncStatusIndicatorContentProps extends EmotionClassStyles {
  inboundSyncState: InboundSyncState;
  outboundSyncState: OutboundSyncState;
}

export const SyncStatusIndicatorContent: FC<SyncStatusIndicatorContentProps> = observer(
  ({ inboundSyncState, outboundSyncState }) => {
    const { tooltipLabel, badgeLabel, badgeIconKind } = useMemo(() => {
      if (
        inboundSyncState.status === InboundSyncStatus.PausedOffline ||
        outboundSyncState.status === OutboundSyncStatus.PausedOffline
      ) {
        return {
          badgeIconKind: MdsIconKind.CloudSlash,
          badgeLabel: "Syncing paused",
          tooltipLabel: "Syncing will resume automatically when you’re online",
        };
      }

      if (outboundSyncState.status === OutboundSyncStatus.PausedUnknown) {
        /** We may want to tweak this copy in the future to differentiate from the above. */
        return {
          badgeIconKind: MdsIconKind.CloudSlash,
          badgeLabel: "Syncing paused",
          tooltipLabel: "Syncing will resume automatically when you’re online",
        };
      }

      if (
        inboundSyncState.status === InboundSyncStatus.Bootstrapping ||
        inboundSyncState.status === InboundSyncStatus.PerformingInitialSync ||
        inboundSyncState.status === InboundSyncStatus.Syncing
      ) {
        return {
          badgeIconKind: MdsIconKind.Spinner,
          badgeLabel: "Syncing",
          tooltipLabel: "Changes made on other devices are still being loaded",
        };
      }

      /** Shouldn't happen, but including for completeness. */
      return {
        badgeLabel: "Unknown",
        badgeIconKind: MdsIconKind.Question,
        tooltipLabel: "Sync status unknown",
      };
    }, [inboundSyncState.status, outboundSyncState.status]);

    const tooltipConfig = useMemo(() => {
      return {
        label: tooltipLabel,
        placement: MdsTooltipPlacement.Bottom,
      };
    }, [tooltipLabel]);

    return (
      <MdsBadge
        label={badgeLabel}
        iconKind={badgeIconKind}
        size={MdsBadgeSize.Medium}
        kind={MdsBadgeKind.Tertiary}
        variant={MdsBadgeVariant.Light}
        tooltipConfig={tooltipConfig}
      />
    );
  }
);
