import { observer } from "mobx-react-lite";
import { FC } from "react";
import { AuthCardGoogleButtonBase } from "@/components/auth/google/AuthCardGoogleButtonBase";

export interface AuthCardLogInWithGoogleButtonProps {
  onClick: () => void;
}

export const AuthCardLogInWithGoogleButton: FC<AuthCardLogInWithGoogleButtonProps> = observer(
  ({ onClick }) => {
    return (
      <AuthCardGoogleButtonBase
        label="Log in with Google"
        onClick={onClick}
        testId="log-in-with-google-button"
      />
    );
  }
);
