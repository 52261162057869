import { AccountInfoDetails } from "@/components/layout/components/account-info/AccountInfoDetails";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface AccountInfoProps {
  profilePhotoUrl: string | null;
  profileDisplayName: string;
  profileEmailAddress: string;
}

export const AccountInfo: FC<AccountInfoProps> = observer(
  ({ profilePhotoUrl, profileDisplayName, profileEmailAddress }) => {
    return (
      <div className={accountInfoStyles}>
        <AccountProfileImageContent
          size={ProfileSize.Large}
          photoUrl={profilePhotoUrl}
          displayName={profileDisplayName}
        />
        <div className={columnStyles}>
          <div className={accountInfoRowStyles}>
            <div className={displayNameStyles}>{profileDisplayName}</div>
          </div>
          <div className={accountInfoRowStyles}>
            <AccountInfoDetails profileEmailAddress={profileEmailAddress} />
          </div>
        </div>
      </div>
    );
  }
);

const accountInfoStyles = css({
  alignItems: "center",
  display: "flex",
  paddingTop: 8,
  paddingLeft: 8,
  paddingRight: 8,
  paddingBottom: 4,
});

const accountInfoRowStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const columnStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  marginLeft: 8,
});

const displayNameStyles = css({
  color: "#3F424D",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "-0.01em",
  lineHeight: "16px",
  maxWidth: 152,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
