import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { MdsPanelHeaderButton } from "@/design-system/components/panel-header/MdsPanelHeaderButton";
import { MdsPanelResponsive } from "@/design-system/components/panel/MdsPanelResponsive";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { withLineBreaks } from "@/domains/html/withLineBreaks";
import { renderArrayWithAnds } from "@/domains/string/renderArrayWithAnds";
import { useAppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { observer } from "mobx-react-lite";
import { useMemo, useCallback } from "react";

interface CollectionsViewPageHeaderButtonProps {
  collection: CollectionObservable;
}

export const CollectionsViewPageHeaderShareButton = observer<CollectionsViewPageHeaderButtonProps>(
  ({ collection }) => {
    const { pageStore } = useAppStore();
    const page = pageStore.collectionsViewPage;

    const { tooltipLabel, iconKind } = useMemo(() => {
      const labels = collection.sharedWithLabels;

      if (collection.hasPendingShare) {
        return {
          tooltipLabel: "Access changes will be applied when you’re online",
          iconKind: MdsIconKind.Spinner,
        };
      }

      if (!labels?.length) {
        if (collection.isShared)
          return {
            tooltipLabel: "Shared with no one",
            iconKind: MdsIconKind.Shared,
          };
        return {
          tooltipLabel: "Private to only me",
          iconKind: MdsIconKind.Lock,
        };
      }

      return {
        tooltipLabel: withLineBreaks(
          `Shared with ${renderArrayWithAnds(
            labels.length >= 5 ? [...labels.slice(0, 3), `${labels.length - 2} others`] : labels
          )}`
        ),
        iconKind: MdsIconKind.Shared,
      };
    }, [collection.sharedWithLabels, collection.isShared, collection.hasPendingShare]);

    const handleClick = useCallback(() => {
      page.shareSheetModal.open({
        id: collection.id,
        entityKind: ShareSheetEntityKind.Collection,
      });
    }, [page.shareSheetModal, collection.id]);

    const tooltipConfig = useMemo(
      () => ({
        label: tooltipLabel,
        placement: MdsTooltipPlacement.Top,
        delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
      }),
      [tooltipLabel]
    );

    return (
      <>
        <MdsPanelResponsive minWidth={mdsPanelBreakpoint}>
          <MdsPanelHeaderButton
            variant={MdsButtonVariant.Tertiary}
            iconKind={iconKind}
            onClick={handleClick}
            label="Share"
            tooltipConfig={tooltipConfig}
            isDisabled={!collection.canWrite}
          />
        </MdsPanelResponsive>
        <MdsPanelResponsive maxWidth={mdsPanelBreakpoint}>
          <MdsIconButton
            size={MdsIconButtonSize.Small}
            variant={MdsIconButtonVariant.Transparent}
            iconKind={iconKind}
            onClick={handleClick}
            tooltipConfig={tooltipConfig}
            isDisabled={!collection.canWrite}
          />
        </MdsPanelResponsive>
      </>
    );
  }
);
