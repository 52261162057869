import { MakeCollectionPrivateModalStore } from "@/components/modal/make-collection-private/MakeCollectionPrivateStore";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { inflect } from "inflection";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

export const MakeCollectionPrivateModal = observer<{
  store: MakeCollectionPrivateModalStore;
}>(({ store }) => {
  const collaboratorText = useMemo(() => {
    const numCollaborators = store.collection?.sharedWithLabels.length;
    if (!numCollaborators) return "";
    return `, which will remove ${numCollaborators} ${inflect("collaborator", numCollaborators)} currently sharing this collection`;
  }, [store.collection?.sharedWithLabels.length]);

  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={store.isOpen}
      handleCloseModal={() => store.close()}
    >
      <div className={modalContainerStyles}>
        <div className={headerStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Make this collection private?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
        </div>
        <MdsText
          lineHeight={MdsTextSize.Small}
          color={mdsColors().grey.x600}
          size={MdsTextSize.Small}
        >
          You are about to make this collection private{collaboratorText}. This action will make the
          collection visible only to you and may result in losing access to some notes not
          individually shared with you.
        </MdsText>
        <div className={buttonSectionStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.Tertiary}
            onClick={() => store.close()}
          />
          <MdsButton
            label="Make private"
            variant={MdsButtonVariant.Danger}
            onClick={() => store.handleSubmit()}
          />
        </div>
      </div>
    </MdsModal>
  );
});

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  padding: "30px",
  maxWidth: 500,
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
