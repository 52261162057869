import { ChatMessageSectionValue } from "@/domains/chat";
import styled from "@emotion/styled";
import { convertHtmlToSafeRenderableMarkdown, MemCommonMarkdown } from "@mem-labs/common-editor";

interface Props {
  data: ChatMessageSectionValue;
}

export function UserMessage({ data }: Props): JSX.Element {
  // user messages are HTML, so we conver them to Markdown before rendering them as such
  const { content } = data;
  const markdownContent = convertHtmlToSafeRenderableMarkdown({ htmlContent: content });

  return <Markdown className="user-message">{markdownContent}</Markdown>;
}

const Markdown = styled(MemCommonMarkdown)({
  width: "100%",
});
