import { AppStoreConstructorArgs, AppSubStore, AppSubStoreArgs } from "@/store/types";
import { makeAutoObservable, runInAction } from "mobx";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { AppStoreQueriesCacheStore } from "@/store/queries/AppStoreQueriesCacheStore";
import { AppStoreSpaceStore } from "@/store/space/AppStoreSpaceStore";
import { AppStoreSpaceAccountStore } from "@/store/space-account/AppStoreSpaceAccountStore";
import { AppStoreRoutingStore } from "@/store/routing/AppStoreRoutingStore";
import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";
import { AppStoreAccountStore } from "@/store/account/AppStoreAccountStore";
import pRetry from "p-retry";
import { initializeAccountObservability } from "@/store/utils/initialization";
import { BaseError, StoreError } from "@/domains/errors";
import { PublicAppStore } from "@/store/PublicAppStore";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import localDb from "@/domains/local-db";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStorePlatformInfoStore } from "@/store/platform-info/AppStorePlatformInfoStore";
import { AppStorePlatformSubscriptionStore } from "@/store/platform-subscription/AppStorePlatformSubscriptionStore";
import { AppStoreSyncStore } from "@/store/sync/AppStoreSyncStore";
import { AppStoreNoteStore } from "@/store/note/AppStoreNoteStore";
import { AppStoreCollectionsStore } from "@/store/collections/AppStoreCollectionsStore";
import { AppStoreCollectionItemsStore } from "@/store/collection-items/AppStoreCollectionItemsStore";
import { AppStoreFavoriteItemsStore } from "@/store/favorite-items/AppStoreFavoriteItemsStore";
import { AppStoreSavedSearchesStore } from "@/store/saved-searches/AppStoreSavedSearchesStore";
import { AppStoreSpaceAccountNotesStore } from "@/store/recent-items/AppStoreSpaceAccountNotesStore";
import { AppStoreSpaceAccountCollectionsStore } from "@/store/recent-items/AppStoreSpaceAccountCollectionsStore";
import { AppStoreRecentItemsStore } from "@/store/recent-items/AppStoreRecentItemsStore";
import { AppStoreContactsStore } from "@/store/contacts/AppStoreContactsStore";
import { AppStoreCollectionMetadataStore } from "@/store/collections/AppStoreCollectionMetadataStore";
import { AppStoreSpaceAccountContactsStore } from "@/store/contacts/AppStoreSpaceAccountContactsStore";
import { ModalsStore } from "@/store/modals/ModalsStore";
import { ProactiveLostAccessModalStore } from "@/store/modals/ProactiveLostAccessModalStore";
import { AppStoreChatConversationStore } from "@/store/chat/ChatConversationStore";
import { AppStoreChatMessageStore } from "@/store/chat/ChatMessageStore";
import { AppStoreSpaceAccountChatMessagesStore } from "@/store/chat/AppStoreSpaceAccountChatMessagesStore";
import { AppStoreNoteContentDocumentStore } from "@/store/note/AppStoreNoteContentDocumentStore";
import { AppStoreSidePanelStore } from "@/store/routing/AppStoreSidePanelStore";

export class AppStore {
  account: AppStoreAccountStore;
  chatConversations: AppStoreChatConversationStore;
  chatMessages: AppStoreChatMessageStore;
  collectionItems: AppStoreCollectionItemsStore;
  collectionMetadata: AppStoreCollectionMetadataStore;
  collections: AppStoreCollectionsStore;
  contacts: AppStoreContactsStore;
  favoriteItems: AppStoreFavoriteItemsStore;
  modals: ModalsStore;
  navigation: AppStoreNavigationStore;
  notes: AppStoreNoteStore;
  noteContentDocuments: AppStoreNoteContentDocumentStore;
  platformInfo: AppStorePlatformInfoStore;
  platformSubscription: AppStorePlatformSubscriptionStore;
  proactiveLostAccessModal: ProactiveLostAccessModalStore;
  publicAppStore: PublicAppStore;
  queriesCache: AppStoreQueriesCacheStore;
  recentItems: AppStoreRecentItemsStore;
  routing: AppStoreRoutingStore;
  savedSearches: AppStoreSavedSearchesStore;
  sidePanel: AppStoreSidePanelStore;
  spaceAccountChatMessages: AppStoreSpaceAccountChatMessagesStore;
  spaceAccountCollections: AppStoreSpaceAccountCollectionsStore;
  spaceAccountContacts: AppStoreSpaceAccountContactsStore;
  spaceAccountNotes: AppStoreSpaceAccountNotesStore;
  spaceAccounts: AppStoreSpaceAccountStore;
  spaces: AppStoreSpaceStore;
  sync: AppStoreSyncStore;

  accountLoadingState: AsyncResult<boolean> = asyncResultModule.setLoading();
  mountedState: AsyncResult<boolean> = asyncResultModule.setLoading();

  constructor({ publicAppStore, api, pusher }: AppStoreConstructorArgs) {
    const injectedDeps: AppSubStoreArgs = { store: this, api, pusher };

    this.publicAppStore = publicAppStore;

    this.account = new AppStoreAccountStore(injectedDeps);
    this.chatConversations = new AppStoreChatConversationStore(injectedDeps);
    this.chatMessages = new AppStoreChatMessageStore(injectedDeps);
    this.collectionItems = new AppStoreCollectionItemsStore(injectedDeps);
    this.collectionMetadata = new AppStoreCollectionMetadataStore(injectedDeps);
    this.collections = new AppStoreCollectionsStore(injectedDeps);
    this.contacts = new AppStoreContactsStore(injectedDeps);
    this.favoriteItems = new AppStoreFavoriteItemsStore(injectedDeps);
    this.modals = new ModalsStore(injectedDeps);
    this.navigation = new AppStoreNavigationStore(injectedDeps);
    this.notes = new AppStoreNoteStore(injectedDeps);
    this.noteContentDocuments = new AppStoreNoteContentDocumentStore(injectedDeps);
    this.platformInfo = new AppStorePlatformInfoStore(injectedDeps);
    this.platformSubscription = new AppStorePlatformSubscriptionStore(injectedDeps);
    this.queriesCache = new AppStoreQueriesCacheStore();
    this.recentItems = new AppStoreRecentItemsStore(injectedDeps);
    this.routing = new AppStoreRoutingStore(injectedDeps);
    this.savedSearches = new AppStoreSavedSearchesStore(injectedDeps);
    this.sidePanel = new AppStoreSidePanelStore(injectedDeps);
    this.spaceAccountChatMessages = new AppStoreSpaceAccountChatMessagesStore(injectedDeps);
    this.spaceAccountCollections = new AppStoreSpaceAccountCollectionsStore(injectedDeps);
    this.spaceAccountContacts = new AppStoreSpaceAccountContactsStore(injectedDeps);
    this.spaceAccountNotes = new AppStoreSpaceAccountNotesStore(injectedDeps);
    this.spaceAccounts = new AppStoreSpaceAccountStore(injectedDeps);
    this.spaces = new AppStoreSpaceStore(injectedDeps);
    this.sync = new AppStoreSyncStore(injectedDeps);

    makeAutoObservable(this);

    // We need this to be already observable to react to changes its observable props.
    this.proactiveLostAccessModal = new ProactiveLostAccessModalStore(injectedDeps);
  }

  get auth() {
    return this.publicAppStore.auth;
  }

  get interface() {
    return this.publicAppStore.interface;
  }

  get debug() {
    return this.publicAppStore.debug;
  }

  /**
   * Fetches data which is critical to our logged-in routes:
   * - Account info for the current user
   * - Spaces for the current user
   * - SpaceAccounts for the current user
   *
   * Note that the UI will block while these promises complete.
   * (Ideally, they implement some form of caching/persistence)
   */
  async initialize() {
    try {
      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setLoading();
      });

      if (!this.auth.isAuthenticated) {
        throw new StoreError({
          message: "User must be authenticated to initialize the AppStore.",
        });
      }

      const retryOptions = {
        retries: 2,
        minDelay: 1000,
        factor: 2,
      };

      const promises = [
        pRetry(() => this.account.initialize(), retryOptions),
        pRetry(() => this.spaces.initialize(), retryOptions),
        pRetry(() => this.spaceAccounts.initialize(), retryOptions),
        pRetry(() => this.platformInfo.initialize(), retryOptions),
      ];

      /**
       * If it fails even after retries, we throw.
       */
      await Promise.all(promises);

      /**
       * Trigger any side-effects related to initializing the UI client.
       * (Wiring up user info to our error-monitor, etc.)
       */
      await initializeAccountObservability({ store: this });
      await localDb.initializeUserDb();

      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setReady(true);
      });
    } catch (unknownErr) {
      const err = unknownErr as BaseError;

      logger.error({
        message: "[initialize] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err) },
      });

      runInAction(() => {
        this.accountLoadingState = asyncResultModule.setError(err);
      });

      throw err;
    }
  }

  /**
   * Represents when the store is ready for use by child components.
   */
  get readyState(): AsyncResult<boolean> {
    const isError = this.accountLoadingState.error || this.mountedState.error;
    const isReady = this.accountLoadingState.data && this.mountedState.data;

    return {
      called: true,
      loading: !isReady,
      error: isError,
      data: isReady,
    } as AsyncResult<boolean>;
  }

  /**
   * Right now this happens in the "TopBarOutlet" component...
   * We should clean that up.
   */
  useInitializeAppStoreEffects = () => {
    this.queriesCache.useCacheCancellation();
    this.routing.useSyncRoutingParams();
    this.navigation.useSyncNavigation();

    useEffectOnMount(() => {
      const asyncLogic = async () => {
        await this.sync.initialize();

        /**
         * We set this in a useEffect because it should happen
         * -after- the initial render, so the `useEffect` hooks
         * from above have a chance to run first.
         */
        runInAction(() => {
          this.mountedState = asyncResultModule.setReady(true);
        });
      };

      asyncLogic();

      /**
       * We kick off any initializers on-mount.
       */
      return () => {
        if (import.meta.hot) {
          return;
        }
        /**
         * We only trigger the `unmount` side-effect if we're not hot-reloading.
         * During hot-reload, we can just keep the state as-is.
         */
        this.debug.resetState();
        this.account.resetState();
        this.spaces.resetState();
        this.spaceAccounts.resetState();
        this.routing.resetState();
      };
    });
  };

  public forceHardReload = async () => {
    await fetch(window.location.href, {
      headers: {
        Pragma: "no-cache",
        Expires: "-1",
        "Cache-Control": "no-cache",
      },
    });
    window.location.reload();
  };

  public forceUpgradeClient = () => {
    // Do not clear queue
    localDb.syncUpdates.clear();
    localDb.sync.clear();
    setTimeout(() => {
      this.forceHardReload();
    }, 300);
  };

  public resetStorageAndReload = () => {
    localDb.queue.clear();
    localDb.syncUpdates.clear();
    localDb.sync.clear();

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  public resetAndReinitializeSync = () => {
    this.stopSync();
    this.resetSync();
    this.sync.initialize();
  };

  stopSync = () => {
    for (const value of Object.values(this)) {
      if (value instanceof AppSubStore) {
        value.stopSync();
      }
    }
  };

  resetSync = () => {
    for (const value of Object.values(this)) {
      if (value instanceof AppSubStore) {
        value.resetSync();
      }
    }
  };
}
