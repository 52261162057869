import {
  FeedbackResponseKind,
  FeedbackUserSuggestions,
  FeedbackUserText,
  SpaceAccountChatMessageStore,
} from "@/domains/chat/space-account-chat-message-store";
import { SpaceAccountChatMessageObservable } from "@/store/chat/SpaceAccountChatMessageObservable";
import { SpaceAccountChatMessageModelData } from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { makeObservable, action } from "mobx";
import { ProvideChatMessageFeedbackOperation } from "../sync/operations/chat/ProvideChatMessageFeedbackOperation";

export class AppStoreSpaceAccountChatMessagesStore
  extends BaseSyncModelStore<SpaceAccountChatMessageObservable, SpaceAccountChatMessageModelData>
  implements SpaceAccountChatMessageStore
{
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountChatMessage, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      provideFeedback: action,
      removeFeedback: action,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountChatMessageModelData>
  ): SpaceAccountChatMessageObservable {
    return new SpaceAccountChatMessageObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  async provideFeedback(
    chatMessageId: string,
    responseKind: FeedbackResponseKind,
    userText?: FeedbackUserText,
    userSuggestions?: FeedbackUserSuggestions
  ) {
    new ProvideChatMessageFeedbackOperation({
      store: this.store,
      payload: {
        chat_message_id: chatMessageId,
        feedback_response_kind: responseKind,
        feedback_selected_suggestions: userSuggestions ?? [],
        feedback_user_text: userText,
        feedback_responded_at: new Date().toISOString(),
      },
    }).execute();
  }

  async removeFeedback(chatMessageId: string) {
    new ProvideChatMessageFeedbackOperation({
      store: this.store,
      payload: {
        chat_message_id: chatMessageId,
        feedback_response_kind: null,
        feedback_selected_suggestions: [],
        feedback_user_text: null,
        feedback_responded_at: new Date().toISOString(),
      },
    }).execute();
  }
}
