import { observer } from "mobx-react-lite";
import { ShareSheetCollectionPermissionRow } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetCollectionPermissionRow";
import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntryCollection,
  SyncModelPermissionEntryEmail,
  SyncModelPermissionEntryGuestAccount,
  SyncModelPermissionEntrySpaceAccount,
  SyncModelPermissionEntryStatus,
  SyncModelPermissionEntryWithStatus,
} from "@/domains/sync-scopes/types";
import { ShareSheetSpaceAccountPermissionRow } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetSpaceAccountPermissionRow";
import { ShareSheetEmailPermissionRow } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetEmailPermissionRow";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { noop } from "lodash-es";

interface ShareSheetPermissionRowProps {
  permission: SyncModelPermissionEntryWithStatus;
  isLoading: boolean;
  isGuestMode: boolean;
  canUpdateAccess: boolean;
  canRevokeAccess: boolean;
  handleRevokeAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleUpdateAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleCancelOperation: (syncOperationId: string) => void;
  shareSheetModalStore: ShareSheetModalStore;
}

export const ShareSheetPermissionRow = observer<ShareSheetPermissionRowProps>(
  ({
    permission,
    isLoading,
    isGuestMode,
    canUpdateAccess,
    canRevokeAccess,
    handleRevokeAccess,
    handleUpdateAccess,
    handleCancelOperation,
    shareSheetModalStore,
  }) => {
    switch (permission.scope_kind) {
      case "COLLECTION_SCOPE":
        return (
          <ShareSheetCollectionPermissionRow
            permission={
              permission as SyncModelPermissionEntryCollection & SyncModelPermissionEntryStatus
            }
            isLoading={isLoading || permission.status === "PENDING"}
            canUpdateAccess={!isGuestMode && canUpdateAccess}
            canRevokeAccess={!isGuestMode && canRevokeAccess}
            handleRevokeAccess={handleRevokeAccess}
            handleUpdateAccess={handleUpdateAccess}
            handleCancelOperation={handleCancelOperation}
          />
        );
      case "SPACE_ACCOUNT_SCOPE":
        return (
          <ShareSheetSpaceAccountPermissionRow
            permission={
              permission as SyncModelPermissionEntrySpaceAccount & SyncModelPermissionEntryStatus
            }
            isLoading={isLoading || permission.status === "PENDING"}
            canUpdateAccess={!isGuestMode && canUpdateAccess}
            canRevokeAccess={!isGuestMode && canRevokeAccess}
            handleRevokeAccess={handleRevokeAccess}
            handleUpdateAccess={handleUpdateAccess}
            handleCancelOperation={handleCancelOperation}
            shareSheetModalStore={shareSheetModalStore}
          />
        );
      case "EMAIL_SCOPE":
        return (
          <ShareSheetEmailPermissionRow
            permission={
              permission as SyncModelPermissionEntryEmail & SyncModelPermissionEntryStatus
            }
            isLoading={isLoading || permission.status === "PENDING"}
            handleRevokeAccess={noop}
            handleUpdateAccess={noop}
            handleCancelOperation={handleCancelOperation}
          />
        );
      case "GUEST_ACCOUNT_SCOPE":
        return (
          <ShareSheetEmailPermissionRow
            permission={
              permission as SyncModelPermissionEntryGuestAccount & SyncModelPermissionEntryStatus
            }
            isLoading={isLoading || permission.status === "PENDING"}
            handleRevokeAccess={handleRevokeAccess}
            handleUpdateAccess={handleUpdateAccess}
            handleCancelOperation={handleCancelOperation}
          />
        );
      default:
        return null;
    }
  }
);
