import { ChatView } from "@/components/chat/ChatView";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface ChatPageProps {}

export const ChatPage: FC<ChatPageProps> = observer(() => {
  const { store } = useAppStore();

  // the chat page should not have an open side panel
  useEffectOnMount(() => {
    store.sidePanel.close();
  });

  return <ChatView chatHistory={store.chatMessages.chatHistory} />;
});
