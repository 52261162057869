import { RuntimeInitializationError } from "@/domains/errors";
import { GLOBAL_PUSHER_CLIENT_CONFIG } from "@/modules/pusher-client/constants";

export const _fetchPusherClientInstance = () => {
  const { pusherInstance } = GLOBAL_PUSHER_CLIENT_CONFIG;

  if (!pusherInstance) {
    throw new RuntimeInitializationError({
      message: `
        The globally shared pusherInstance has not yet been initialized.\
        You can do so using => pusherClientModule.initialize()
      `,
    });
  }

  return pusherInstance;
};
