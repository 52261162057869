import { observer } from "mobx-react-lite";

import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";

interface ConfirmEmptyTrashModalProps {}

export const ConfirmEmptyTrashModal = observer<ConfirmEmptyTrashModalProps>(
  function ConfirmEmptyTrashModal() {
    const { pageStore } = useAppStore();
    const page = pageStore.trashPage;
    return (
      <MdsModal
        rootElementId="root"
        isModalOpen={page.showConfirmModal}
        handleCloseModal={page.closeConfirmModal}
        className={modalStyles}
      >
        <div className={headerStyles}>
          <div className={headingStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Empty trash now?
            </MdsText>
          </div>
          <MdsIconButton
            variant={MdsIconButtonVariant.Transparent}
            iconKind={MdsIconKind.Exit}
            onClick={page.closeConfirmModal}
          />
        </div>
        <MdsText
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
          color={mdsColors().grey.x600}
        >
          {"Make sure you want to empty Trash now, permanently deleting all items in Trash. "}
          <span className={warningTextStyles}>You cannot undo this action.</span>
        </MdsText>
        <div className={buttonsStyles}>
          {!page.isEmptyingTrash && (
            <MdsButton
              label="Cancel"
              variant={MdsButtonVariant.TextTertiaryOutlined}
              onClick={page.closeConfirmModal}
            />
          )}
          <MdsButton
            isDisabled={page.isEmptyingTrash}
            isLoading={page.isEmptyingTrash}
            label="Empty trash now"
            variant={MdsButtonVariant.Danger}
            onClick={page.emptyTrash}
          />
        </div>
      </MdsModal>
    );
  }
);

const modalStyles = css({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  maxWidth: "600px",
  padding: "24px",
});

const headerStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const headingStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
});

const warningTextStyles = css({
  color: mdsColors().grey.x600,
  fontWeight: mdsFontWeights().semiBold,
});

const buttonsStyles = css({
  alignSelf: "flex-end",
  display: "flex",
  gap: "8px",
});
