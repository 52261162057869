import {
  faWhatsapp,
  faTelegram,
  faApple,
  faWindows,
  faEtsy,
  faVimeo,
  faHubspot,
} from "@fortawesome/free-brands-svg-icons";
// import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faBoxOpen, faGripDotsVertical } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowLeft,
  faBookmark as faBookmarkAlt,
  faCaretSquareLeft,
  faCaretSquareRight,
  faFileAlt,
  faCheckSquare,
  faCheckCircle as farCheckCircle,
  faCirclePlus,
  faArrowCircleUp,
  faCircle,
  faSquare,
  faGlobe,
  faStar as farStar,
  faWindowMaximize as farWindowMaximize,
  faClipboardQuestion,
  faClock as faClockEmpty,
  faExclamationCircle as farExclamationCircle,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileCode,
  faFileArchive,
  faPenToSquare,
  faTimes,
  faEllipsisH,
  faArrowRight,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faInfoCircle,
  faSearch,
  faClock,
  faTrashAlt,
  faTrash,
  faSignOut,
  faDownload,
  faUpload,
  faCloudUpload,
  faLinkSimple,
  faEnvelope,
  faEnvelopeOpen,
  faCopy,
  faUserPlus,
  faBellSlash,
  faArchive,
  faCalendarAlt,
  faCalendarTimes,
  faTags,
  faShare,
  faVideo,
  faKeyboard,
  faGift,
  faQuestionCircle,
  faLightbulb,
  faCommentDots,
  faBook,
  faCog,
  faCogs,
  faPen,
  faBan,
  faGlobeAmericas,
  faUserFriends,
  faUser,
  faListCheck,
  faPlateUtensils,
  faRoad,
  faMessageLines,
  faUsers,
  faHashtag,
  faStream,
  faInbox,
  faCalendar,
  faLayerGroup as faLayerGroupAlt,
  faProjectDiagram as faProjectDiagramAlt,
  faShapes,
  faScribble,
  faBuilding,
  faExternalLink,
  faChevronLeft,
  faChevronRight,
  faAddressCard,
  faArrowRightFromBracket,
  faCircleCheck,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowDown,
  faArrowUp,
  faEyeSlash,
  faMerge,
  faPlus,
  faGrid2,
  faGrid2Plus,
  faHome,
  faBolt as faBoltRegular,
  faReply as faRegularReply,
  faPartyHorn,
  faRedo,
  faMessageExclamation,
  faPaperPlaneTop as faPaperPlaneAlt,
  faThumbsDown,
  faArrowsRotate,
  faLoader,
  faCommentAltExclamation,
  faBarsFilter,
  faMobileButton,
  faArrowDownToSquare,
  faGif,
  faFilm,
  faQuoteLeft,
  faImage,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAdjust,
  faAlignLeft,
  faArrowTrendUp,
  faBars,
  faBolt,
  faBookmark,
  faBroom,
  faCaretDown,
  faChevronDown,
  faCaretRight,
  faChartPie,
  faCheck,
  faCheckCircle,
  faClipboardCheck,
  faCode,
  faColumns,
  faCommentExclamation,
  faCompress,
  faCopy as fasCopy,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faExpand,
  faThumbtack,
  faFeather,
  faFish,
  faGrid2 as faGrid2Solid,
  faHeart,
  faHistory,
  faLayerGroup,
  faLevelUpAlt,
  faLock,
  faMagicWandSparkles,
  faMapMarkerAlt,
  faMobile,
  faMobileAlt,
  faMoneyBill,
  faKey,
  faWifi,
  faMoon,
  faPaintBrush,
  faPaperPlane,
  faParagraph,
  faPlay,
  faProjectDiagram,
  faReply,
  faStar as fasStar,
  faStickyNote,
  faSun,
  faTag,
  faToggleOff,
  faToggleOn,
  faTrashRestoreAlt,
  faUniversity,
  faUserMinus,
  faWater,
  faWindowMinimize,
  faSort,
  // faUser as fasUser,
  faCloudUploadAlt,
  faPaperclip,
  faFileCsv,
  faSave,
  faEye,
  faLinkSimple as fasLinkSimple,
  faLinkSimpleSlash,
  faSparkles,
  faUndo,
  faSidebarFlip,
  faMessageLines as faMessageLinesFilled,
  faQuestion,
  faCheckSquare as fasCheckSquare,
  faMobileScreenButton,
  faWifiExclamation,
  faStop,
  faMessage,
  faCommentSms,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { mapValues } from "lodash-es";
import React from "react";

import AirbnbIcon from "@/assets/icons/brands/airbnb.svg?react";
import AmazonIcon from "@/assets/icons/brands/amazon.svg?react";
import AsanaIcon from "@/assets/icons/brands/asana.svg?react";
import DocusignIcon from "@/assets/icons/brands/docusign.svg?react";
import DropboxIcon from "@/assets/icons/brands/dropbox.svg?react";
import FigmaIcon from "@/assets/icons/brands/figma.svg?react";
import GiphyIcon from "@/assets/icons/brands/giphy.svg?react";
import GitHubIcon from "@/assets/icons/brands/github.svg?react";
import GoogleCalendarIcon from "@/assets/icons/brands/google-calendar.svg?react";
import GoogleDocsIcon from "@/assets/icons/brands/google-docs.svg?react";
import GoogleDriveIcon from "@/assets/icons/brands/google-drive.svg?react";
import GoogleIcon from "@/assets/icons/brands/google-icon.svg?react";
import GoogleSheetsIcon from "@/assets/icons/brands/google-sheets.svg?react";
import GoogleSlidesIcon from "@/assets/icons/brands/google-slides.svg?react";
import LinkedinIcon from "@/assets/icons/brands/linkedin.svg?react";
import LoomIcon from "@/assets/icons/brands/loom.svg?react";
import MediumIcon from "@/assets/icons/brands/medium.svg?react";
import MemIcon from "@/assets/icons/brands/mem.svg?react";
import SlackIcon from "@/assets/icons/brands/slack.svg?react";
import TodoistIcon from "@/assets/icons/brands/todoist.svg?react";
import TwitterIcon from "@/assets/icons/brands/twitter.svg?react";
import YelpIcon from "@/assets/icons/brands/yelp.svg?react";
import YouTubeIcon from "@/assets/icons/brands/youtube.svg?react";
import ZapierIcon from "@/assets/icons/brands/zapier.svg?react";
import NotFoundIcon from "@/assets/icons/other/not-found.svg?react";
import SeparatorIcon from "@/assets/icons/other/separator.svg?react";

const iconMappingList = {
  // Google: faGoogle,
  Actions: faBolt,
  Add: faPlus,
  AddUser: faUserPlus,
  Adjust: faAdjust,
  Aggregate: faLayerGroup,
  Airbnb: AirbnbIcon,
  Amazon: AmazonIcon,
  Apple: faApple,
  Asana: AsanaIcon,
  Send: faPaperPlaneAlt,
  ArrowFromRight: faChevronDoubleLeft,
  ArrowLeft: faArrowLeft,
  ArrowToRight: faChevronDoubleRight,
  Attachment: faPaperclip,
  Author: faFeather,
  Back: faArrowLeft,
  Ban: faBan,
  Block: faParagraph,
  Book: faBook,
  Bookmark: faBookmark,
  BookmarkAlt: faBookmarkAlt,
  BoxOpen: faBoxOpen,
  Breadcrumb: faCaretRight,
  Browse: faGlobeAmericas,
  BugReport: faCommentAltExclamation,
  Calendar: faCalendar,
  CalendarAlt: faCalendarAlt,
  Celebrate: faPartyHorn,
  Check: faCheck,
  Checkbox: faCheckSquare,
  CheckboxSolid: fasCheckSquare,
  CheckboxEmpty: faSquare,
  ChevronLeft: faChevronLeft,
  ChevronRight: faChevronRight,
  CirclePlus: faCirclePlus,
  CircleUp: faArrowCircleUp,
  Clean: faBroom,
  Clock: faClock,
  ClockEmpty: faClockEmpty,
  Close: faTimes,
  Cloud: faCloudUploadAlt,
  CloudAlt: faCloudUpload,
  Code: faCode,
  Cogs: faCogs,
  CollapseAccordion: faAngleUp,
  CollapseMenu: faCaretSquareLeft,
  Comment: faMessageLines,
  CommentFilled: faMessageLinesFilled,
  Company: faBuilding,
  Compose: faPenToSquare, //faPenAlt,
  ComposeInline: faPenToSquare, //fasEdit,
  ContactUs: faCommentDots,
  Continue: faAngleRight,
  Copy: faCopy,
  CopySolid: fasCopy,
  Create: faPlus,
  CreateFrom: faCopy,
  CsvFile: faFileCsv,
  Daily: faSun,
  Danger: faExclamationCircle,
  DangerAlt: farExclamationCircle,
  Dark: faMoon,
  Date: faCalendarAlt,
  Delete: faTrashAlt,
  Details: faInfoCircle,
  DisconnectCalendar: faCalendarTimes,
  DisconnectEmail: faUserMinus,
  Document: faFileAlt,
  Docusign: DocusignIcon,
  Done: faArchive,
  LoadMore: faArrowUp,
  Download: faDownload,
  Downvote: faThumbsDown,
  Dropbox: DropboxIcon,
  Dropdown: faCaretDown,
  DropdownChevron: faChevronDown,
  DropdownCollapsed: faCaretRight,
  Edit: faPen, //fasEdit,
  Etsy: faEtsy,
  ExcelSpreadsheet: faFileExcel,
  Expand: faExpand,
  ExpandAccordion: faAngleDown,
  ExpandAlt: faArrowUpRightAndArrowDownLeftFromCenter,
  ExpandMenu: faCaretSquareRight,
  ExternalLink: faExternalLink,
  Fact: faSparkles, //faBookSparkles,
  FeatureRequest: faLightbulb,
  Figma: FigmaIcon,
  FigmaPrototype: FigmaIcon,
  Film: faFilm,
  Filter: faBarsFilter,
  Finished: faCheckCircle,
  FinishedAlt: faCircleCheck,
  Flows: faWater,
  Format: faPaintBrush,
  Forward: faArrowRight,
  Gif: faGif,
  Gift: faGift,
  Giphy: GiphyIcon,
  GitHub: GitHubIcon,
  Google: GoogleIcon,
  GoogleCalendar: GoogleCalendarIcon,
  GoogleDocs: GoogleDocsIcon,
  GoogleDrive: GoogleDriveIcon,
  GoogleSheets: GoogleSheetsIcon,
  GoogleSlides: GoogleSlidesIcon,
  Grip: faGripDotsVertical,
  Group: faUsers,
  Heart: faHeart,
  Help: faQuestionCircle,
  Hide: faEyeSlash,
  History: faHistory,
  Home: faHome,
  HtmlFile: faFileCode,
  Hubspot: faHubspot,
  Image: faImage,
  Inbox: faInbox,
  Invite: faPaperPlane,
  Keyboard: faKeyboard,
  Later: faClock,
  LayerGroup: faLayerGroupAlt,
  Leave: faSignOut,
  LevelUp: faLevelUpAlt,
  Light: faSun,
  Link: faLinkSimple, //faLink,
  LinkedIn: LinkedinIcon,
  LinkSolid: fasLinkSimple,
  Location: faMapMarkerAlt,
  LogOut: faSignOut,
  Loom: LoomIcon,
  Magic: faMagicWandSparkles,
  Mail: faEnvelope,
  MailOpen: faEnvelopeOpen,
  Medium: MediumIcon,
  Mem: MemIcon,
  Menu: faBars,
  Merge: faMerge,
  MessageExclamation: faMessageExclamation,
  Message: faMessage,
  Minimize: faCompress,
  MinimizeAlt: faArrowDownLeftAndArrowUpRightToCenter,
  Mobile: faMobile,
  Money: faMoneyBill,
  More: faEllipsisH,
  Mute: faBellSlash,
  Network: faWifi,
  Note: faFileAlt, //faStickyNote,
  NotFound: NotFoundIcon,
  Now: faListCheck,
  Numeric: faChartPie,
  OpenInPrimary: farWindowMaximize,
  OpenInSidebar: faColumns,
  OpenInSidebarAlt: faArrowRightFromBracket,
  Password: faKey,
  Pdf: faFilePdf,
  Pen: faPen,
  People: faUser,
  PersonCard: faAddressCard,
  Phone: faMobileAlt,
  PhoneSms: faMobileButton,
  Pin: faThumbtack,
  Plan: faRoad,
  Play: faPlay,
  PowerpointPresentation: faFilePowerpoint,
  Private: faLock,
  ProductUpdates: faGift,
  ProjectDiagram: faProjectDiagram,
  Published: faGlobeAmericas,
  Question: faQuestion,
  Quote: faQuoteLeft,
  Recipe: faPlateUtensils,
  Related: faProjectDiagram,
  RelatedAlt: faProjectDiagramAlt,
  Reply: faReply,
  ReplyRegular: faRegularReply,
  ReportFeedback: faCommentExclamation,
  Restore: faTrashRestoreAlt,
  Retry: faRedo,
  Salmon: faFish,
  Save: faSave,
  Scribble: faScribble,
  Search: faSearch,
  Separator: SeparatorIcon,
  Settings: faCog,
  Shapes: faShapes,
  Share: faShare,
  Shared: faUserFriends,
  Sidebar: faSidebarFlip,
  Spinner: faLoader,
  Slack: SlackIcon,
  Sort: faSort,
  Space: faCircle,
  Sparkle: faSparkles,
  Star: fasStar,
  StarEmpty: farStar,
  Stop: faStop,
  StickyNote: faStickyNote,
  Streams: faStream,
  Subtopic: faCaretRight,
  Success: faCheckCircle,
  Survey: faClipboardQuestion,
  Swap: faExchangeAlt,
  Sync: faArrowsRotate,
  SyncingPaused: faExclamationSquare,
  Tag: faTag,
  Tags: faTags,
  Tasks: faListCheck,
  Telegram: faTelegram,
  Texts: faMobileScreenButton,
  Action: faBoltRegular,
  Template: faAlignLeft,
  Timeline: faStream,
  Todos: faClipboardCheck,
  ToggleOff: faToggleOff,
  ToggleOn: faToggleOn,
  Topic: faHashtag,
  Tour: faInfoCircle,
  Trash: faTrashAlt,
  TrashSimple: faTrash,
  Trending: faArrowTrendUp,
  ArrowDown: faArrowDown,
  ArrowUp: faArrowUp,
  Tutorial: faBook,
  Tutorials: faUniversity,
  Twitter: TwitterIcon,
  Undo: faUndo,
  University: faUniversity,
  Unlink: faLinkSimpleSlash,
  Upload: faUpload,
  Import: faArrowDownToSquare,
  Todoist: TodoistIcon,
  User: faUser,
  Verified: farCheckCircle,
  Video: faVideo,
  View: faEye,
  Vimeo: faVimeo,
  Warning: faExclamationTriangle,
  Website: faGlobe,
  WhatsApp: faWhatsapp,
  WifiExclamation: faWifiExclamation,
  WindowMinimize: faWindowMinimize,
  Windows: faWindows,
  WordDocument: faFileWord,
  Yelp: YelpIcon,
  YouTube: YouTubeIcon,
  Zapier: ZapierIcon,
  ZipFile: faFileArchive,
  Collection: faGrid2,
  CollectionSolid: faGrid2Solid,
  CollectionAdd: faGrid2Plus,
  SMS: faCommentSms,
} as const;

type ValidIconComponentProps = Omit<FontAwesomeIconProps, "icon">;

// Transforms our iconMap into:
// {
//   IconOne: React.memo(() => <FontAwesomeIcon icon={faIconOne} />)
//   IconTwo: React.memo(() => <FontAwesomeIcon icon={faIconTwo} />)
//   ...
// }
//
// Useful for when we want to use specific icon components directly
// eg.  <div><IconTwo /> Some Stuff</div>

const memoizedIcons = mapValues(iconMappingList, iconDefinition =>
  React.memo((iconProps: ValidIconComponentProps) => {
    /** This was introduces during the JS -> TS migration. */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (iconDefinition.prefix) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <FontAwesomeIcon {...iconProps} icon={iconDefinition as any} />;
    }

    /** This was introduces during the JS -> TS migration. */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return iconDefinition({ height: "1em", width: "1em", ...iconProps });
  })
);

export default memoizedIcons;
