import React, { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ErrorBox } from "@/components/error-handling";
import { ScreenOverlay } from "@/components/overlay/ScreenOverlay";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";

interface ErrorOverlayProps {
  isVisible: boolean;
}

export const ErrorOverlay: FC<ErrorOverlayProps> = ({ isVisible }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={overlayStyles}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <ScreenOverlay>
            <ErrorBox />
          </ScreenOverlay>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const overlayStyles = css({
  position: "absolute",
  zIndex: ZIndex.ScreenOverlay,
});
