import { FC } from "react";
import { MdsIcon } from "@/design-system/components/icon";
import { MdsItemListActionButtonRowData } from "@/design-system/components/item-list/types";
import { css, cx } from "@/domains/emotion";
import { mdsColors, mdsFontSizes, mdsLineHeights, mdsSpacings } from "@/design-system/foundations";

export interface MdsItemListActionButtonRowProps {
  data: MdsItemListActionButtonRowData;
  className?: string;
}

export const MdsItemListActionButtonRow: FC<MdsItemListActionButtonRowProps> = ({
  data,
  className,
}) => {
  const { icon, label, onClick, listState } = data.payload;
  const handleMouseEnter = () => listState?.highlightItem(undefined);
  return (
    <button
      className={cx(wrapperStyles, className)}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
    >
      <div className={iconWrapperStyles}>
        <MdsIcon kind={icon} innerStyles={{ Icon: { className: iconStyles } }} />
      </div>
      <span className={labelStyles}>{label}</span>
    </button>
  );
};

const wrapperStyles = css({
  height: 48,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: 4,
  paddingRight: 16,
  transition: "0.08s ease",
  backgroundColor: mdsColors().grey.x0,
  color: mdsColors().grey.x400,
  "&:hover": {
    backgroundColor: mdsColors().grey.x25,
    color: mdsColors().grey.x600,
    borderRadius: "8px",
  },
  "&:active": {
    backgroundColor: mdsColors().grey.x25,
  },
  ":not(:hover)": {
    opacity: 0.75,
  },
  gap: mdsSpacings().xs,
  userSelect: "none",
});

const iconWrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 36,
  padding: mdsSpacings().md,
});

const iconStyles = css({
  fontSize: 16,
  color: mdsColors().grey.x400,
});

const labelStyles = css({
  fontSize: mdsFontSizes().small,
  lineHeight: mdsLineHeights().xsmall,
  color: mdsColors().grey.x400,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  cursor: "pointer",
});
