import styled from "@emotion/styled";
import { Link, LinkProps } from "react-aria-components";

export interface IMdsLinkProps extends React.PropsWithChildren<LinkProps> {}

export function MdsLink({ children, ...rest }: IMdsLinkProps) {
  return <StyledLink {...rest}>{children}</StyledLink>;
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.colors.grey.x500,
  textDecoration: "underline",
  cursor: "pointer",
  textUnderlineOffset: 3,

  "&[data-disabled]": {
    cursor: "not-allowed",
    color: theme.colors.grey.x300,
  },
}));
