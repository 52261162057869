import { Promisable } from "@/domains/common/types";
import { RuntimeInitializationError } from "@/domains/errors";
import { uuidModule } from "@/modules/uuid";
import { mapValues } from "lodash-es";

const GLOBAL_MANAGER_MAP = new Map<string, unknown>();

export const _createManager = <
  TInstance,
  TInstanceArgs extends Record<string, unknown>,
  TModuleMethods extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (instance: TInstance) => (...args: any) => any;
  },
>(
  {
    initializer,
  }: {
    initializer: (args: TInstanceArgs) => Promisable<TInstance>;
  },
  moduleMethods?: TModuleMethods
) => {
  const globalManagerInstanceId = uuidModule.generate();

  GLOBAL_MANAGER_MAP.set(globalManagerInstanceId, undefined);

  const reinitialize = async (args: TInstanceArgs) => {
    const targetInstance = await initializer(args);

    GLOBAL_MANAGER_MAP.set(globalManagerInstanceId, targetInstance);

    return targetInstance;
  };

  const instance = () => {
    const targetInstance = GLOBAL_MANAGER_MAP.get(globalManagerInstanceId) as undefined | TInstance;

    if (!targetInstance) {
      throw new RuntimeInitializationError({
        message: `[_createManager]: Manager instance not initialized`,
      });
    }

    return targetInstance;
  };

  const initialize = async (args: TInstanceArgs) => {
    const targetInstance = GLOBAL_MANAGER_MAP.get(globalManagerInstanceId) as undefined | TInstance;

    if (!targetInstance) {
      return await reinitialize(args);
    }

    return targetInstance;
  };

  const mappedModuleMethods = mapValues(moduleMethods, method => {
    type UnwrappedMethod = ReturnType<typeof method>;

    const modifiedMethod = (...args: Parameters<UnwrappedMethod>[]) => {
      const targetInstance = instance();

      const unwrappedMethod = method(targetInstance) as UnwrappedMethod;

      return unwrappedMethod(...args);
    };

    return modifiedMethod;
  });

  const castedModules = mappedModuleMethods as {
    [key in keyof TModuleMethods]: ReturnType<TModuleMethods[key]>;
  };

  return {
    ...castedModules,
    initialize,
    reinitialize,
    instance,
  };
};
