import { Maybe } from "@/domains/common/types";
import localDb from "@/domains/local-db";
import { PusherEventKind } from "@/domains/pusher/constants";
import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store";
import { ModalDefinitionKind } from "@/store/modals/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import { FetchValue } from "@/store/queries/types";
import { AppStoreBaseSyncStore } from "@/store/sync/AppStoreBaseSyncStore";
import { AppSyncActionQueue } from "@/store/sync/AppSyncActionQueue";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  ListSyncUpdatesResponse,
  SyncUpdate,
  SyncModelData,
  SyncModelKind,
  BootstrapSyncUpdateResponse,
} from "@/store/sync/types";
import {
  generateSyncActionSpaceScopedPusherChannelKey,
  generateSyncActionSpaceAccountScopedPusherChannelKey,
  standardizeSyncId,
} from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import { isClientUpgradeError } from "@/store/utils/errors";
import { debounce } from "lodash-es";
import { action, makeObservable, observable, override, runInAction } from "mobx";
import { AbortError } from "p-retry";
import { Channel } from "pusher-js";

export class AppStoreSyncStore extends AppStoreBaseSyncStore<
  AppStore,
  AppSyncActionQueue,
  ListSyncUpdatesResponse
> {
  private spaceScopedPusherChannel: Maybe<Channel>;
  private spaceAccountScopedPusherChannel: Maybe<Channel>;

  constructor(injectedDeps: AppSubStoreArgs<AppStore>) {
    super(injectedDeps);
    this.actionQueue = this.createSyncActionQueue();

    makeObservable<this, "spaceScopedPusherChannel" | "spaceAccountScopedPusherChannel">(this, {
      spaceScopedPusherChannel: observable,
      spaceAccountScopedPusherChannel: observable,

      createSyncActionQueue: false,
      finishProcessingQueryResponse: override,
      syncQuery: override,
      subscribe: override,
      unsubscribe: override,
      processSyncUpdate: override,
      hydrateFromStorage: override,
      fetchBootstrapEvents: override,
      saveBootstrapEvents: override,
      initialize: override,

      queryForSyncActions: action,
      handleClientUpgradeError: action,
    });
  }

  createSyncActionQueue() {
    return new AppSyncActionQueue({
      getSpaceId: () => this.store.spaces.myPersonalSpaceId,
      api: this.api,
      pusher: this.pusher,
      store: this.store,
    });
  }

  get syncQuery() {
    const id = `sync-updates`;
    type QueryValue = ListSyncUpdatesResponse;
    const fetchValue: FetchValue<QueryValue> = async signal => {
      if (signal.aborted) return;
      console.debug("[SYNC][AppStoreSyncStore] Querying for sync actions...");

      const spaceId = this.store.spaces.myPersonalSpaceId;
      const response = await api.get(`/v2/sync/updates`, {
        params: {
          query: {
            last_sync_id: this.lastSyncId,
            space_id: spaceId,
          },
        },
      });

      if (signal.aborted) return;
      if (response.error) {
        if (isClientUpgradeError(response.error)) {
          this.handleClientUpgradeError();
          throw new AbortError("CLIENT_UPGRADE_REQUIRED");
        }
        throw new Error("[SYNC][AppStoreSyncStore] syncQuery error: " + response.error);
      }
      return { data: response.data };
    };

    const createQuery = () =>
      new QueryObservable<QueryValue>({
        auth: this.store.auth,
        queriesCache: this.store.queriesCache,
        id,
        refreshInterval: this.pollingInterval,
        fetchValue,
      });

    return this.store.queriesCache.get<QueryValue>(id, createQuery);
  }

  finishProcessingQueryResponse(data: ListSyncUpdatesResponse) {
    if (data?.latest_space_account_sequence_id) {
      this.latestSpaceAccountSequenceId = data.latest_space_account_sequence_id;
      this.actionQueue.confirmSyncUpdatesUntil(data.latest_space_account_sequence_id);
    }
  }

  public queryForSyncActions = debounce(async () => this.syncQuery?.forceRefetch(), 250, {
    maxWait: 1000,
  });

  public async initialize() {
    await super.initialize();
    this.store.noteContentDocuments.preloadAll();
  }

  subscribe() {
    if (this.spaceScopedPusherChannel && this.spaceAccountScopedPusherChannel) return;
    console.debug("[SYNC][AppStoreSyncStore] Initializing sync actions pusher subscription...");

    const spaceId = this.store.spaces.myPersonalSpaceId;
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    if (!spaceAccountId) {
      console.warn("[SYNC][AppStoreSyncStore] Skipping sync actions pusher subscription...");
      return;
    }

    const spaceScopedPusherChannelKey = generateSyncActionSpaceScopedPusherChannelKey({ spaceId });
    const spaceAccountScopedPusherChannelKey = generateSyncActionSpaceAccountScopedPusherChannelKey(
      { spaceAccountId }
    );
    this.spaceAccountScopedPusherChannel = this.pusher.subscribe(
      spaceAccountScopedPusherChannelKey
    );
    this.spaceScopedPusherChannel = this.pusher.subscribe(spaceScopedPusherChannelKey);
    this.spaceAccountScopedPusherChannel.bind(
      PusherEventKind.SYNC_UPDATE_PUBLISHED,
      this.queryForSyncActions
    );
    this.spaceScopedPusherChannel.bind(
      PusherEventKind.SYNC_UPDATE_PUBLISHED,
      this.queryForSyncActions
    );
  }

  public unsubscribe() {
    this.spaceAccountScopedPusherChannel?.unsubscribe();
    this.spaceScopedPusherChannel?.unsubscribe();
    this.spaceAccountScopedPusherChannel = undefined;
    this.spaceScopedPusherChannel = undefined;
  }

  processSyncUpdate(update: SyncUpdate<SyncModelData>, options?: { hydrating?: boolean }) {
    const modelKindToStoreMap: Record<
      SyncModelKind,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BaseSyncModelStore<any, SyncModelData> | undefined
    > = {
      CHAT_CONVERSATION: this.store.chatConversations,
      CHAT_MESSAGE: this.store.chatMessages,
      COLLECTION: this.store.collections,
      COLLECTION_ITEM: this.store.collectionItems,
      COLLECTION_METADATA: this.store.collectionMetadata,
      CONTACT: this.store.contacts,
      FAVORITE_ITEM: this.store.favoriteItems,
      NOTE: this.store.notes,
      NOTE_CONTENT_DOCUMENT: this.store.noteContentDocuments,
      SAVED_SEARCH: this.store.savedSearches,
      SPACE_ACCOUNT_CHAT_MESSAGE: this.store.spaceAccountChatMessages,
      SPACE_ACCOUNT_COLLECTION: this.store.spaceAccountCollections,
      SPACE_ACCOUNT_CONTACT: this.store.spaceAccountContacts,
      SPACE_ACCOUNT_NOTE: this.store.spaceAccountNotes,
    };

    const store = modelKindToStoreMap[update.value.model_kind as SyncModelKind];
    if (store) store.processSyncUpdate(update);

    this.lastSyncId = standardizeSyncId(update.sync_id); // sync_ids can have two uuids
    if (!options?.hydrating) localDb.syncUpdates.save(update.sync_id, update);

    console.debug("[SYNC][AppStoreSyncStore] Processed sync update:", update);
  }

  async hydrateFromStorage() {
    console.debug("[SYNC][AppStoreSyncStore] Hydrating from storage...");
    const [syncUpdates] = await Promise.all([
      localDb.syncUpdates.getAll(),
      this.actionQueue.hydrateFromStorage(),
    ]);
    runInAction(() => {
      for (const update of syncUpdates) {
        this.processSyncUpdate(update, { hydrating: true });
      }
    });
    console.debug("[SYNC][AppStoreSyncStore] Hydrated from storage:", {
      syncUpdates,
      lastSyncId: this.lastSyncId,
    });
  }

  async fetchBootstrapEvents() {
    let lastSyncId: string | undefined = undefined;
    let nextPageToken: string | undefined = undefined;
    const allEvents: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        const response: BootstrapSyncUpdateResponse = await api.get(`/v2/sync/updates/bootstrap`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              bootstrap_session_cursor: nextPageToken,
              exclude_sync_models_kinds: [SyncModelKind.NoteContentDocument],
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) {
            this.handleClientUpgradeError();
          }
        }

        const actions = response.data?.results || [];
        allEvents.push(...actions);
        nextPageToken = response.data?.bootstrap_session_cursor || undefined;
        lastSyncId = standardizeSyncId(response.data?.bootstrap_session_final_sync_id);
      } catch (e) {
        logger.warn({
          message: "[SYNC][AppStoreSyncStore] Error bootstrapping",
          info: objectModule.safeAsJson({ e }),
        });
        lastSyncId = undefined;
        break;
      }
    } while (nextPageToken);

    return { lastSyncId, allEvents };
  }

  saveBootstrapEvents(allEvents: SyncUpdate<SyncModelData>[]) {
    return localDb.syncUpdates.saveMany(allEvents);
  }

  handleClientUpgradeError() {
    this.store.modals.addModal({
      kind: ModalDefinitionKind.SyncError,
      syncError: {
        title: "Client upgrade required",
        message: "Please update the app to continue",
        resetActionLabel: "Upgrade",
        modalActionHandler: () => {
          this.store.forceUpgradeClient();
        },
      },
    });
  }
}
