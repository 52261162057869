import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { FadeInAnimator } from "@/design-system/components/animation";
import { observer } from "mobx-react-lite";
import React from "react";

export interface Filter {
  id: string;
  content: React.ReactNode;
}

export interface FadeInFadeHorizontalScrollProps {
  className?: string;
  filters: Filter[];
}

export const FadeInFadeHorizontalScroll = observer<FadeInFadeHorizontalScrollProps>(
  function FadeInFadeHorizontalScroll({ className, filters }) {
    if (!filters.length) return null;
    return (
      <FadeInAnimator
        element={
          <FadeHorizontalScroll className={className}>
            {filters.map(e => (
              <React.Fragment key={e.id}>{e.content}</React.Fragment>
            ))}
          </FadeHorizontalScroll>
        }
      ></FadeInAnimator>
    );
  }
);
