import { Editor } from "@tiptap/core";
import { BubbleMenu, BubbleMenuProps } from "@tiptap/react";
import { observer } from "mobx-react-lite";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  MemCommonEditorAction,
  MemCommonEditorActionKind,
  MemCommonEditorEventKind,
  MemCommonEditorEvent,
  MemCommonEditorInstanceRef,
  MemCommonEditorRpcKind,
  MemCommonEditorToolbarKind,
} from "@mem-labs/common-editor";
import { css, cx } from "@/domains/emotion";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { MdsIconKind } from "@/design-system/components/icon";
import { ZIndex } from "@/domains/design/constants";
import { actions } from "@/actions";
import styled from "@emotion/styled";
import { isMac } from "@/domains/platform/isMac";

export interface FormattingToolbarProps {
  editor: Editor;
  editorRef: MemCommonEditorInstanceRef;
  shouldShow: () => MemCommonEditorToolbarKind;
}

export const FormattingToolbar: FC<FormattingToolbarProps> = observer(
  ({ editor, editorRef, shouldShow: commonEditorShouldShow }) => {
    const [count, setCount] = useState(0);
    const [url, setUrl] = useState(undefined as string | undefined);
    const urlRef = useRef(url);
    urlRef.current = url;

    const [selectionUrl, setSelectionUrl] = useState(undefined as string | undefined);
    const selectionUrlRef = useRef(selectionUrl);
    selectionUrlRef.current = selectionUrl;

    useLayoutEffect(() => {
      const updateSelectionUrl = () => {
        if (editor.state.selection.empty && !editor.isActive("link")) {
          setSelectionUrl(undefined);
          setUrl(undefined);
          return;
        }
        const attributes = editor.getAttributes("link");
        const href = attributes?.href;
        setSelectionUrl(href);
      };

      editor.on("update", updateSelectionUrl);
      editor.on("selectionUpdate", updateSelectionUrl);
      return () => {
        editor.off("update", updateSelectionUrl);
        editor.off("selectionUpdate", updateSelectionUrl);
      };
    }, [editor]);

    const handleUrlInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const input = e.currentTarget;
      const parent = input.parentElement;
      if (!parent) return;

      const focusStealingElement = e.relatedTarget;
      if (
        parent.contains(focusStealingElement) ||
        // User clicked on other parts of the bubble menu.
        focusStealingElement?.className === "tippy-box"
      ) {
        input.focus();
        return;
      }

      setUrl(undefined);
    };

    const reFocus = () => {
      editorRef.current?.editor.commands.focus();
    };

    const action = (event: MemCommonEditorAction) => () => {
      if (!editorRef.current?.editor.options.editable) return;

      editorRef.current.dispatchAction(event);
      setCount(prev => prev + 1);
      reFocus();
    };

    const event = (event: MemCommonEditorEvent) => () => {
      if (!editorRef.current?.editor.options.editable) return;

      editorRef.current.publishEvent(event);
      setCount(prev => prev + 1);
      reFocus();
    };

    const handleNewHyperlink = (e: MouseEvent) => {
      const { selection } = editor.state;
      if (editor?.isActive("link") || !selection || selection.empty) {
        return false;
      }

      setUrl("");
      e.stopPropagation();
      return true;
    };

    const handleCopyHyperlink = () => {
      if (!editorRef.current || !selectionUrlRef.current) return;

      actions.copyUrlToClipboard({ url: selectionUrlRef.current });
      reFocus();
    };

    const handleEditHyperlink = () => {
      if (!editorRef.current || !selectionUrlRef.current) return;

      setUrl(selectionUrlRef.current);
    };

    const handleRemoveHyperlink = () => {
      if (!editorRef.current || !selectionUrlRef.current) return;

      editorRef.current.dispatchAction({
        kind: MemCommonEditorActionKind.SetHyperlink,
        payload: { url: undefined },
      });
      reFocus();
    };

    const handleSaveHyperlink = () => {
      if (!editorRef.current) return;

      editorRef.current.dispatchAction({
        kind: MemCommonEditorActionKind.SetHyperlink,
        payload: { url },
      });

      setUrl(undefined);
      reFocus();
    };

    const handleUrlInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Enter":
          handleSaveHyperlink();
          break;
        case "Escape":
          setUrl(undefined);
          editor.commands.focus();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    };

    const [showLinkToolbar, setShowLinkToolbar] = useState(false);
    const [showFormattingToolbar, setShowFormattingToolbar] = useState(false);

    const shouldShow: BubbleMenuProps["shouldShow"] = useCallback(
      ({ editor }: { editor: Editor }) => {
        if (!editor.options.editable) {
          return false;
        }
        /**
         * BubbleMenu expects a boolean return value but common-editor returns the kind of toolbar
         * that should be shown. So here we have side-effects to remember which toolbar should be
         * active as handling it manually would require matching the logic internal to BubbleMenu
         * that watches editor events.
         */
        switch (commonEditorShouldShow()) {
          case MemCommonEditorToolbarKind.Disabled:
            return false;
          case MemCommonEditorToolbarKind.None:
            setShowLinkToolbar(false);
            setShowFormattingToolbar(false);
            // HACK: Render the toolbar with display: none to have clicks working after mouseup.
            return true;
          case MemCommonEditorToolbarKind.Formatting:
            setShowLinkToolbar(false);
            setShowFormattingToolbar(true);
            return true;
          case MemCommonEditorToolbarKind.Link:
            setShowLinkToolbar(true);
            setShowFormattingToolbar(false);
            return true;
        }
      },
      [commonEditorShouldShow]
    );

    const tippyRef =
      useRef<
        Parameters<NonNullable<NonNullable<BubbleMenuProps["tippyOptions"]>["onCreate"]>>[0]
      >();

    const hiddenRef = useRef(true);

    useLayoutEffect(() => {
      /**
       * Make the toolbar uninteractible while dragging the selection.
       *
       * In general we want it to be interactible so that the user can click on the link,
       * hover works, etc.
       * When updating the selection the toolbar is moved to the new position until it reaches the
       * top. During this time we don't need toolbar interactivity.
       *
       * It also causes problems. Since it's anchored after the editor, hovering over it extends
       * the selection to its position, selecting everything up to the end of the document. If the
       * toolbar is above the text it also inverts the selection.
       *
       * To avoid that we disable interactivity (pointer-events CSS property) between
       * mousedown and mouseup.
       *
       * But we cannot change tippy's interactivity while it's hidden (e.g, editor is blurred) as
       * that locks it in an uninteratible state.
       */
      const onMouseDown = () => {
        if (hiddenRef.current) return;
        tippyRef.current?.setProps({ interactive: false });
      };

      const onMouseUp = () => {
        if (hiddenRef.current) return;
        tippyRef.current?.setProps({ interactive: true });
      };

      // Watching the editor gives us events inside it, avoiding toolbar events.
      editor.view.dom.addEventListener("mousedown", onMouseDown);
      // Watching the document gives us all events so if the mouse is lifted outside the editor
      // we also get the event.
      document.addEventListener("mouseup", onMouseUp);

      return () => {
        editor.view.dom.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
      };
    }, [editor]);

    const editUrlToolbar = typeof url === "string";
    const urlFocusedToolbar = !editUrlToolbar && showLinkToolbar;
    const defaultToolbar = !editUrlToolbar && !showLinkToolbar;
    const show = showLinkToolbar || showFormattingToolbar;

    const showRef = useRef(show);
    showRef.current = show;

    useLayoutEffect(() => {
      const onKeyDown = (e: KeyboardEvent) => {
        // Cmd+k / Ctrl+k: open link editor.
        if (showRef.current && e.key === "k" && (isMac() ? e.metaKey : e.ctrlKey)) {
          setUrl("");
          e.stopPropagation();
          return true;
        }
      };

      // Capture to run before the callback that toogles quick search modal.
      document.addEventListener("keydown", onKeyDown, { capture: true });
      return () => {
        document.removeEventListener("keydown", onKeyDown);
      };
    }, []);

    return (
      <StyledBubbleMenu
        show={show}
        updateDelay={0}
        tippyOptions={{
          onCreate: instance => {
            tippyRef.current = instance;
          },
          onDestroy: () => {
            tippyRef.current = undefined;
          },
          onHidden: () => {
            hiddenRef.current = true;
          },
          onShown: () => {
            hiddenRef.current = false;
          },
          interactive: true,
          delay: [40, 0],
          duration: [80, 20],
          popperOptions: {
            placement: "top-start",
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          },
          maxWidth: "530px",
          zIndex: ZIndex.FloatingBar,
        }}
        editor={editor}
        shouldShow={shouldShow}
      >
        {editUrlToolbar && (
          <div className={cx(rowStyles, urlRowStyles)}>
            <input
              autoFocus
              type="text"
              value={url}
              onBlur={handleUrlInputBlur}
              onFocus={e => e.target.select()}
              onChange={e => setUrl(e.target.value)}
              onKeyDown={handleUrlInputKeyDown}
              placeholder="Enter link"
            />
            <div className={separatorStyles} />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Check}
              tooltipConfig={{
                label: "Save link",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={handleSaveHyperlink}
            />
          </div>
        )}

        {urlFocusedToolbar && (
          <div className={cx(rowStyles, urlRowStyles)}>
            <a
              href={selectionUrl}
              target="_blank"
              className={cx(urlActionStyles, flex1Styles)}
              rel="noreferrer"
            >
              {selectionUrl}
            </a>
            <div className={separatorStyles} />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Copy}
              tooltipConfig={{
                label: "Copy link",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={cx(buttonStyles, css({ marginLeft: "-4px" }))}
              onClick={handleCopyHyperlink}
            />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Pen}
              tooltipConfig={{
                label: "Edit link",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={handleEditHyperlink}
            />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Trash}
              tooltipConfig={{
                label: "Remove link",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={handleRemoveHyperlink}
            />
          </div>
        )}

        {defaultToolbar && (
          <div className={rowStyles}>
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.H1}
              tooltipConfig={{
                label: "Large header",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleHeaderOneTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.H2}
              tooltipConfig={{
                label: "Medium header",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleHeaderTwoTextFormat,
                payload: null,
              })}
            />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Bold}
              tooltipConfig={{
                label: "Bold",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleBoldTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Italic}
              tooltipConfig={{
                label: "Italicize",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleItalicTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Underline}
              tooltipConfig={{
                label: "Underline",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleUnderlineTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Strikethrough}
              tooltipConfig={{
                label: "Strike-through",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleStrikethroughTextFormat,
                payload: null,
              })}
            />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.NumberedList}
              tooltipConfig={{
                label: "Numbered list",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleOrderedListTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.List}
              tooltipConfig={{
                label: "Bulleted list",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleBulletListTextFormat,
                payload: null,
              })}
            />
            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Tasks}
              tooltipConfig={{
                label: "To-do list",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.ToggleChecklistTextFormat,
                payload: null,
              })}
            />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.RemoveFormat}
              tooltipConfig={{
                label: "Remove formatting",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={action({
                kind: MemCommonEditorActionKind.RemoveAllTextFormat,
                payload: null,
              })}
            />

            <div className={separatorStyles} />

            <MdsButton
              focusable={false}
              variant={MdsButtonVariant.TextTertiary}
              label={null}
              iconKind={MdsIconKind.Link}
              tooltipConfig={{
                label: "Add link",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: 0.8,
              }}
              className={buttonStyles}
              onClick={handleNewHyperlink}
            />
            <Disabled>
              <MdsButton
                focusable={false}
                variant={MdsButtonVariant.TextTertiary}
                label={null}
                iconKind={MdsIconKind.Image}
                tooltipConfig={{
                  label: "Add image",
                  placement: MdsTooltipPlacement.Top,
                  delaySeconds: 0.8,
                }}
                className={buttonStyles}
                onClick={event({
                  kind: MemCommonEditorEventKind.RpcRequest,
                  payload: {
                    kind: MemCommonEditorRpcKind.InsertImage,
                    rpcId: `insert-image-${count}`,
                    payload: {
                      requestTimestamp: Date.now(),
                      queryString: "",
                    },
                  },
                })}
                data-tooltip=""
              />
            </Disabled>
            <Disabled>
              <MdsButton
                focusable={false}
                variant={MdsButtonVariant.TextTertiary}
                label={null}
                iconKind={MdsIconKind.Table}
                className={buttonStyles}
                tooltipConfig={{
                  label: "Add table",
                  placement: MdsTooltipPlacement.Top,
                  delaySeconds: 0.8,
                }}
              />
            </Disabled>
            <Disabled>
              <MdsButton
                focusable={false}
                variant={MdsButtonVariant.TextTertiary}
                label={null}
                iconKind={MdsIconKind.Paperclip}
                className={buttonStyles}
                onClick={event({
                  kind: MemCommonEditorEventKind.RpcRequest,
                  payload: {
                    kind: MemCommonEditorRpcKind.InsertImage,
                    rpcId: `insert-file-${count}`,
                    payload: {
                      requestTimestamp: Date.now(),
                      queryString: "",
                    },
                  },
                })}
                tooltipConfig={{
                  label: "Add file",
                  placement: MdsTooltipPlacement.Top,
                  delaySeconds: 0.8,
                }}
              />
            </Disabled>

            <Disabled>
              <div className={separatorStyles} />
            </Disabled>

            <Disabled>
              <MdsButton
                focusable={false}
                variant={MdsButtonVariant.TextTertiary}
                label="Smart Edit"
                iconKind={MdsIconKind.Sparkle}
                tooltipConfig={{
                  label: "Smart Edit",
                  placement: MdsTooltipPlacement.Top,
                  delaySeconds: 0.8,
                }}
                className={cx(buttonStyles, smartEditStyles)}
              />
            </Disabled>
          </div>
        )}
      </StyledBubbleMenu>
    );
  }
);

const flex1Styles = css({
  flex: 1,
});

const urlActionStyles = css({
  color: `#3cf`,
});

const separatorStyles = css({
  border: `0.5px solid var(--color-grey-200)`,
  borderRadius: "100px",
  height: 0,
  margin: "0 -4px",
  transform: `rotate(90deg)`,
  width: "20px",
});

interface StyledBubbleMenuProps {
  show: boolean;
}

const StyledBubbleMenu = styled(BubbleMenu)<StyledBubbleMenuProps>(({ show }) => ({
  background: `var(--color-grey-0)`,
  border: `.5px solid var(--color-grey-200)`,
  borderRadius: "8px",
  boxShadow: "#455c6830 0 3px 8px",
  cursor: "text",
  // HACK: Render it with display: none to have clicks working after mouseup.
  display: show ? "auto" : "none",
  minWidth: "280px",
  padding: "4px",
  transition: "visibility 0.2s",
  width: "fit-content",
}));

const urlRowStyles = css({
  gap: "4px",
  padding: "0px 2px",
  userSelect: "none",

  a: {
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 6px",
    whiteSpace: "nowrap",
    color: "var(--url-color)",
  },

  input: {
    background: "transparent",
    color: `var(--color-grey-600)`,
    flex: 1,
    fontSize: "13px",
    lineHeight: "26px",
    outline: "none",
    padding: "8px 6px",
    height: "26px",
  },

  span: {
    color: `var(--color-grey-600)`,
    fontSize: "14px",
    fontWeight: "400",
  },
});

const rowStyles = css({
  alignItems: "center",
  display: "flex",
});

const buttonStyles = css({
  alignItems: "center",
  background: "none",
  border: "none",
  color: `var(--color-grey-600)`,
  cursor: "pointer",
  display: "flex",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "16px",
  outline: "none",
  padding: "6px",
  position: "relative",
  transition: "ease-in-out 0.1s",
  whiteSpace: "nowrap",
  width: "fit-content",

  svg: {
    height: "16px",
    width: "16px",
  },

  ":hover": {
    background: "var(--color-grey-50)",
    borderRadius: "4px",
    transition: "0.1s ease-in all",
  },
});

const smartEditStyles = css({
  padding: "6px",

  ".mds-btn-icon, .mds-btn-label": {
    color: "#F22E61",
  },
});

const Disabled: FC<PropsWithChildren> = () => null;
