import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { CollectionViewPageListEmpty } from "@/pages/collections-view/components/CollectionsViewPageListEmpty";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useEffect } from "react";

export const CollectionsViewPageList = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsViewPage;
  const collection = page.collectionObservable;
  page.listState.useListStateEffects();

  useEffect(() => {
    if (!collection) return;
    collection.addToRecents();
  }, [collection]);

  if (!collection) return null;
  if (collection.itemList.allItems.length === 0) return <CollectionViewPageListEmpty />;
  return (
    <>
      <MdsVirtualizedItemList rows={page.collectionItemRows} listState={page.listState} />
      {page.listState.multiSelectUi}
    </>
  );
});
