import { Uuid } from "@/domains/global/identifiers";
import { uuidModule } from "@/modules/uuid";

/**
 * Generated once on initial app boot.
 */
const UNIQUE_SYNC_CLIENT_ID = uuidModule.generate();

export const clientEnvClientId = (): Uuid => {
  return UNIQUE_SYNC_CLIENT_ID;
};
