import { observer } from "mobx-react-lite";
import { FC } from "react";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { isUndefined, minBy } from "lodash-es";
import { DateTime } from "luxon";
import { Maybe } from "@/domains/common/types";

interface SyncStatusLastSyncedTextProps extends EmotionClassStyles {
  inboundLastSyncedAt: Maybe<DateTime>;
  outboundLastSyncedAt: Maybe<DateTime>;
}

export const SyncStatusLastSyncedText: FC<SyncStatusLastSyncedTextProps> = observer(
  ({ inboundLastSyncedAt, outboundLastSyncedAt }) => {
    const minSyncedAt = minBy([inboundLastSyncedAt, outboundLastSyncedAt], syncedAt =>
      isUndefined(syncedAt) ? Infinity : syncedAt.toMillis()
    );

    if (!minSyncedAt) {
      return <div>Haven&apos;t synced yet</div>;
    }

    return <div>Last synced at {minSyncedAt.toLocal().toLocaleString(DateTime.TIME_SIMPLE)}</div>;
  }
);
