import { NoteViewer } from "@/components/note/viewer/NoteViewer";
import { SpringAnimator } from "@/design-system/components/animation";
import { HoveringState } from "@/design-system/components/item-list/rows/item-preview/HoveringState";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css, keyframes } from "@/domains/emotion";
import { getNoteMetadata } from "@/store/note/metadata";
import { INoteObservable } from "@/store/note/types";
import { useAppStore } from "@/store/utils/hooks";
import {
  FloatingNode,
  FloatingPortal,
  Placement,
  flip,
  offset,
  safePolygon,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";

export interface FloatingNotePreviewCardProps {
  state: HoveringState;
  noteObservable?: INoteObservable;
  placement?: Placement;
  highlightText?: string;
}

export const FloatingNotePreview = observer<FloatingNotePreviewCardProps>(
  ({ noteObservable, state, placement, highlightText }) => {
    const { store } = useAppStore();
    const nodeId = useFloatingNodeId();

    const { x, y, refs, strategy, context } = useFloating({
      nodeId,
      strategy: "fixed",
      middleware: [flip(), offset({ mainAxis: 18 })],
      open: true,
      onOpenChange: value => {
        if (value) {
          /** We don't want to open on hover states. */
          return;
        }
      },
      placement: placement ?? "bottom-end",
    });

    useEffect(() => {
      if (state.hoveredRect) {
        refs.setPositionReference({
          getBoundingClientRect() {
            return state.hoveredRect!;
          },
        });
      }
    }, [refs, state.hoveredRect]);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(context, {
        move: false,
        handleClose: safePolygon(),
        delay: {
          close: 100,
        },
      }),
      useDismiss(context),
    ]);

    const portalContent = useMemo(() => {
      if (!noteObservable) {
        return false;
      }
      return (
        <SpringAnimator
          className={previewStyles}
          onClick={e => e.stopPropagation()}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: ZIndex.FloatingNotePreview,
          }}
          {...getFloatingProps()}
          onMouseLeave={state.handleMouseLeave}
          onMouseEnter={state.handleMouseEnter}
        >
          <NoteViewer
            className={editorStyles}
            noteObservable={noteObservable}
            goToMention={store.navigation.goToMention}
            showMetadata={false}
            footer={<div className={paddingStyles} />}
            highlightText={highlightText}
          />
          <div className={footerStyles}>
            <MdsText
              className={footerTitleStyles}
              size={MdsTextSize.XXSmall}
              lineHeight={MdsTextSize.Small}
              weight={MdsTextWeight.SemiBold}
            >
              {noteObservable.title}
            </MdsText>
            <MdsText
              size={MdsTextSize.XXSmall}
              lineHeight={MdsTextSize.XSmall}
              className={metadataStyles}
            >
              {getNoteMetadata({ note: noteObservable, updateOnly: true })}
            </MdsText>
          </div>
        </SpringAnimator>
      );
    }, [
      getFloatingProps,
      highlightText,
      noteObservable,
      refs.setFloating,
      state.handleMouseEnter,
      state.handleMouseLeave,
      store,
      strategy,
      x,
      y,
    ]);

    return (
      <FloatingNode id={nodeId}>
        <div ref={refs.setReference} {...getReferenceProps()}></div>
        <FloatingPortal>
          <AnimatePresence>{portalContent}</AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

const PREVIEW_HEIGHT = "300px";
const FOOTER_HEIGHT = "50px";

const growAndFadeAnimation = keyframes({
  from: {
    opacity: 0,
    transform: "scale(0.97)",
  },
  to: {
    opacity: 1,
    transform: "scale(1)",
  },
});

const previewStyles = css({
  animation: `${growAndFadeAnimation} .2s ease-out`,
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: `rgba(69, 92, 104, 0.19) 0px 6px 10px`,
  border: `1px solid rgb(243, 243, 245)`,
  height: PREVIEW_HEIGHT,
  userSelect: "text",
  width: "400px",
  // When we are on a narrow screen, we want to limit the size to the available space,
  // considering all padding/margins outside the element. This is used in list views.
  maxWidth: "calc(100vw - 60px)",
  zIndex: ZIndex.HoverCard,
});

const editorStyles = css({
  height: `calc(${PREVIEW_HEIGHT} - ${FOOTER_HEIGHT})`,
  padding: "10px 10px 0 10px",
  overflowY: "auto",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

const paddingStyles = css({
  height: "10px",
});

const footerStyles = css({
  backgroundColor: `rgb(248, 248, 250)`,
  height: FOOTER_HEIGHT,
  padding: "8px 10px",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
});

const footerTitleStyles = css({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  lineHeight: "16px",
});

const metadataStyles = css({
  color: mdsColors().grey.x500,
});
