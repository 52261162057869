import { makeObservable, observable } from "mobx";
import { ApiSchema } from "@/modules/api";

export class PlatformOnboardingInfoProfileObservable {
  desiredUseCases: string[] | null;
  jobRole: string | null;
  referrer: string | null;

  constructor({
    desiredUseCases,
    jobRole,
    referrer,
  }: {
    desiredUseCases: string[] | null;
    jobRole: string | null;
    referrer: string | null;
  }) {
    this.desiredUseCases = desiredUseCases;
    this.jobRole = jobRole;
    this.referrer = referrer;

    makeObservable(this, {
      desiredUseCases: observable,
      jobRole: observable,
      referrer: observable,
    });
  }

  public static createFromRemoteData({
    data,
  }: {
    data: ApiSchema["PlatformInfoOnboardingProfile"];
  }) {
    return new PlatformOnboardingInfoProfileObservable({
      desiredUseCases: data.desired_use_cases ?? null,
      jobRole: data.job_role ?? null,
      referrer: data.referrer ?? null,
    });
  }
}
