import { observer } from "mobx-react-lite";
import { MdsSpacer } from "@/design-system/components/spacer";
import { css } from "@/domains/emotion";
import { useAppStore, useGuestAppStore } from "@/store";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { NoteEditorTopBarShareButton } from "@/components/note/editor/top-bar/NoteEditorTopBarShareButton";
import { NoteEditorTopBarExtraActionsDropdownButton } from "@/components/note/editor/top-bar/NoteEditorTopBarExtraActionsDropdownButton";
import { MdsIconButton, MdsIconButtonSize } from "@/design-system/components/icon-button";
import { NoteTopBarTrashedNoteActionButtons } from "@/components/note/editor/top-bar/NoteTopBarTrashedNoteActionButtons";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { ZIndex } from "@/domains/design/constants";
import { INoteObservable } from "@/store/note/types";
import { PageElementRouter } from "@/app/outlets/PageElementRouter";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import styled from "@emotion/styled";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { mdsPanelBreakpoint } from "@/design-system/foundations";

interface NoteEditorTopBarProps {
  note?: INoteObservable;
  inSidePanel?: boolean;
}

export const NoteEditorTopBar = observer<NoteEditorTopBarProps>(function NoteEditorTopBar({
  note,
  inSidePanel,
}) {
  return (
    <PageElementRouter
      standardModeElement={<StandardNoteEditorTopBar note={note} inSidePanel={inSidePanel} />}
      guestModeElement={<GuestNoteEditorTopBar note={note} />}
    />
  );
});

const StandardNoteEditorTopBar = observer<NoteEditorTopBarProps>(function StandardNoteEditorTopBar({
  note,
  inSidePanel,
}) {
  const { pageStore, store } = useAppStore();
  const collections = note?.collectionList?.allCollections || [];
  const hasCollections = collections.length > 0;

  if (!note) return null;
  return (
    <MdsPanelHeader
      icon={<NoteIcon kind={MdsIconKind.Document} />}
      onBackClick={inSidePanel ? store.sidePanel.goUp : store.navigation.goUp}
      heading={note?.title}
      headingTextProps={{
        size: MdsTextSize.Small,
        lineHeight: MdsTextSize.XSmall,
        weight: MdsTextWeight.Medium,
      }}
      subheading={
        hasCollections ? (
          <MdsItemListRowFeaturedContent
            collections={sortCollectionsForChips(note.collectionList?.allCollections || [])}
          />
        ) : null
      }
      actions={
        !note.isTrashed ? (
          <>
            <MdsPanelResponsiveBtn
              fullSizeLabel="Organize"
              fullSizeIcon={MdsIconKind.Collection}
              collapsedIcon={MdsIconKind.Collection}
              collapsedTooltipConfig={{
                label: "Organize",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
              }}
              threshold={mdsPanelBreakpoint}
              onClick={() =>
                pageStore.notesViewPage.addToCollectionModal?.open({ noteIds: [note.id] })
              }
            />
            <NoteEditorTopBarShareButton
              note={note}
              shareSheetModal={pageStore.notesViewPage.shareSheetModal}
            />
            <MdsIconButton
              size={MdsIconButtonSize.Small}
              iconKind={note?.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack}
              onClick={() => note?.toggleFavorite()}
              tooltipConfig={{
                label: note.isFavorited ? "Unpin from sidebar" : "Pin to sidebar",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
              }}
              isDisabled={!note}
            />
            <NoteEditorTopBarExtraActionsDropdownButton note={note} />
          </>
        ) : note.isOwnedByMe ? (
          <NoteTopBarTrashedNoteActionButtons note={note} />
        ) : null
      }
    />
  );
});

const GuestNoteEditorTopBar = observer<NoteEditorTopBarProps>(function GuestNoteEditorTopBar({
  note,
}) {
  const { guestStore } = useGuestAppStore();
  return (
    <div className={topBarStyles}>
      <MdsSpacer />
      <NoteEditorTopBarShareButton note={note} shareSheetModal={guestStore.shareSheetModal} />
    </div>
  );
});

export const NOTE_EDITOR_TOP_BAR_HEIGHT = "62px";

const topBarStyles = css({
  alignItems: "center",
  display: "flex",
  gap: "4px",
  height: NOTE_EDITOR_TOP_BAR_HEIGHT,
  left: 0,
  // Use padding, not margin.
  padding: "6px 16px 0 16px",
  position: "relative",
  top: 0,
  width: "100%",
  zIndex: ZIndex.NavBar, // Above the editor so it scrolls under.

  "@media (max-width: 900px)": {
    padding: "0 8px",
  },
});

const NoteIcon = styled(MdsIcon)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.colors.grey.x50,
  borderRadius: theme.borderRadius.mediumLarge,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    fontSize: 20,
    color: theme.colors.grey.x500,
  },
}));
