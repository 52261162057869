import { FC } from "react";
import ReactLottie from "react-lottie";

import { InterstitialOverlay } from "@/components/overlay";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import SubscribedJson from "@/components/subscriptions/Subscribed.json";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { mdsLineSpacings } from "@/design-system/foundations/typography";

interface HasStripeSubscriptionInterstitialOverlayContentProps {
  isOpen: boolean;
  onCloseInterstitialHandler: () => void;
}

export const HasStripeSubscriptionInterstitialOverlayContent: FC<
  HasStripeSubscriptionInterstitialOverlayContentProps
> = ({ isOpen, onCloseInterstitialHandler }) => {
  return (
    <InterstitialOverlay isOpen={isOpen} handleClose={onCloseInterstitialHandler}>
      <div className={containerStyles}>
        <div className={headerStyles}>You’ve subscribed to Mem</div>
        <ReactLottie
          options={{ animationData: SubscribedJson, loop: false }}
          height="161px"
          width="192px"
        />
        <div className={infoStyles}>
          You’re all set. Your subscription is set to renew monthly. You can manage your
          subscription in Settings.
        </div>
        <MdsButton
          label="Go to Mem"
          size={MdsButtonSize.Large}
          className={closeStyles}
          variant={MdsButtonVariant.Primary}
          onClick={onCloseInterstitialHandler}
        />
      </div>
    </InterstitialOverlay>
  );
};

const containerStyles = css({
  alignItems: "center",
  background: "white",
  borderColor: mdsColors().grey.x50,
  borderRadius: "24px",
  borderStyle: "solid",
  borderWidth: "1px",
  boxShadow: "0px 11px 24px -8px rgba(69, 78, 104, 0.20)",
  color: mdsColors().grey.x600,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  maxWidth: "580px",
  padding: 40,
  width: "100%",

  "@media (min-width: 672px) and (min-height: 632px)": {
    justifyContent: "center",
  },
});

const headerStyles = css({
  color: mdsColors().grey.x600,
  display: "flex",
  fontSize: "24px",
  fontWeight: 700,
  justifyContent: "center",
  letterSpacing: mdsLineSpacings().large,
  lineHeight: "32px",
  textAlign: "center",
});

const infoStyles = css({
  color: mdsColors().grey.x500,
  fontHeight: 400,
  fontSize: "16px",
  letterSpacing: mdsLineSpacings().medium,
  lineHeight: "24px",
  paddingBottom: "16px",
  textAlign: "center",
});

const closeStyles = css({
  borderRadius: "8px",
  background: `var(--color-brand-400, #F22E61)`,

  "&:hover": {
    background: `var(--color-brand-400, #F22E61)`,
  },
});
