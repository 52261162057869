export enum SidePanelState {
  SPLIT_VIEW = "SPLIT_VIEW",
  MAIN_PANEL_ONLY = "MAIN_PANEL_ONLY",
  SIDE_PANEL_ONLY = "SIDE_PANEL_ONLY",
}

export enum SidePanelTab {
  CHAT = "CHAT",
  COPILOT = "COPILOT",
  OTHER = "OTHER",
}

export type SidePanelRoute = SidePanelNoteDetailRoute | SidePanelChatRoute;

export enum SidePanelRouteType {
  NOTE_DETAIL = "NOTE_DETAIL",
  CHAT = "CHAT",
}

export type SidePanelNoteDetailRoute = {
  type: SidePanelRouteType.NOTE_DETAIL;
  noteId: string;
};

export type SidePanelChatRoute = {
  type: SidePanelRouteType.CHAT;
};
