import { observer } from "mobx-react-lite";
import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InboundSyncStatus, OutboundSyncStatus } from "@/components/sync/types";
import { SyncStatusIndicatorContent } from "@/components/sync/SyncStatusIndicatorContent";
import { useAppStore } from "@/store";

interface SyncStatusIndicatorProps {}

const INBOUND_STATUSES = [InboundSyncStatus.PerformingInitialSync];
const OUTBOUND_STATUSES = [OutboundSyncStatus.PausedOffline, OutboundSyncStatus.PausedUnknown];

export const SyncStatusIndicator: FC<SyncStatusIndicatorProps> = observer(() => {
  const { store } = useAppStore();

  const { inboundSyncState, outboundSyncState } = store.sync;

  const isVisible =
    INBOUND_STATUSES.includes(inboundSyncState.status) ||
    OUTBOUND_STATUSES.includes(outboundSyncState.status);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: 5 }}
        >
          <SyncStatusIndicatorContent
            inboundSyncState={inboundSyncState}
            outboundSyncState={outboundSyncState}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
});
