import { GLOBAL_ENV } from "@/modules/global-state/globalEnv";
import { isEmpty, toSafeInteger } from "lodash-es";
import { DateTime } from "luxon";

export const clientEnvBuildInfo = () => {
  const gitCommitSha = GLOBAL_ENV.GITHUB_SHA ?? "Unknown";
  const buildTimestamp = toSafeInteger(GLOBAL_ENV.BUILD_TIMESTAMP);

  const gitCommitValue = (() => {
    if (!gitCommitSha || isEmpty(gitCommitSha)) {
      return "Unknown";
    }

    return gitCommitSha;
  })();

  return {
    buildInfo: {
      gitCommitSha: gitCommitValue,
      buildTimestamp: DateTime.fromMillis(buildTimestamp),
    },
  };
};
