import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { NoteObservable } from "@/store/note/NoteObservable";

export function hasAccessToNoteAfterRemovingFromCollection({
  store,
  note,
  collection,
}: {
  store: AppStore;
  note: NoteObservable;
  collection: CollectionObservable;
}) {
  const notePermissions = note.permissions;
  for (const permission of notePermissions) {
    if (permission.role_kind === "REVOKED") continue;
    if (permission.scope_kind === "COLLECTION_SCOPE") {
      const collectionId = permission.collection_id;
      if (permission.collection_id === collection.id) continue;
      const collectionObservable = store.collections.get(collectionId);
      if (collectionObservable?.isAvailable) return true;
    }
    if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") {
      const myPersonalSpaceAccountId = store.spaceAccounts.myPersonalSpaceAccountId;
      if (permission.space_account_id === myPersonalSpaceAccountId) return true;
    }
  }
  return false;
}
