import { isNull, isObject, isString, isUndefined } from "lodash-es";
import { JsonObject } from "@/domains/common/types";

import { _parseObjectFromJson } from "@/modules/object/parsers/fromJson";
import { _transformObjectToJson } from "@/modules/object/toJson";

export const _transformObjectToJsonFriendlyFormat = <TValue extends JsonObject = JsonObject>(
  value: Record<string, unknown>
): TValue => {
  const str = _transformObjectToJson(value);

  const obj = _parseObjectFromJson<TValue>(str);

  return obj;
};

const safeFailureMessage = `Unable to serialize json value.`;

export const _safeTransformObjectToJsonFriendlyFormat = <TValue extends JsonObject = JsonObject>(
  value?: Record<string, unknown>
): JsonObject => {
  try {
    if (isUndefined(value)) {
      return {};
    }

    if (isNull(value)) {
      return {};
    }

    return _transformObjectToJsonFriendlyFormat<TValue>(value);
  } catch (_unknownErr) {
    if (isString(value)) {
      return { error: safeFailureMessage, stringValue: value.toString() };
    }

    if (isObject(value)) {
      try {
        /**
         * Only grab the objects own properties.
         */
        const simplifiedStr = JSON.stringify(value, Object.getOwnPropertyNames(value));
        const simplifiedValue = _parseObjectFromJson<TValue>(simplifiedStr);

        return simplifiedValue;
      } catch (_nestedError) {
        /**
         * If one of their own properties is recursive, we just return.
         */
        return { error: safeFailureMessage, errorKeys: Object.keys(value) };
      }
    }

    return {
      error: safeFailureMessage,
    };
  }
};
