import { observer } from "mobx-react-lite";
import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useAppStore } from "@/store";
import { ConfirmEmptyTrashModal } from "@/pages/trash/ConfirmEmptyTrashModal";
import { MdsIconKind } from "@/design-system/components/icon";
import { DeleteNotesPermanentlyModal } from "@/components/modal/delete-notes-permanently/DeleteNotesPermanentlyModal";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { MdsPanelHeaderButton } from "@/design-system/components/panel-header/MdsPanelHeaderButton";

interface TrashPageProps {}

export const TrashPage = observer<TrashPageProps>(function TrashPage() {
  const { pageStore } = useAppStore();
  const page = pageStore.trashPage;
  page.listState.useListStateEffects();

  return (
    <>
      <DeleteNotesPermanentlyModal store={page.deleteNotePermanentlyModal} />
      <ConfirmEmptyTrashModal />
      <MdsPanelHeader
        heading="Trash"
        icon={MdsIconKind.Trash}
        actions={
          <MdsPanelHeaderButton
            label="Empty trash"
            iconKind={MdsIconKind.Trash}
            variant={MdsButtonVariant.Tertiary}
            onClick={page.openConfirmModal}
          />
        }
      />
      <MdsPanelContainer>
        <MdsVirtualizedItemList rows={page.itemRows} listState={page.listState} />
        {page.listState.multiSelectUi}
      </MdsPanelContainer>
    </>
  );
});
