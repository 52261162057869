import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { SyncModelKind } from "@/store/sync/types";

export const resolveSpaceAccountContactSyncModelUuid = ({
  spaceAccountId,
  contactId,
}: {
  spaceAccountId: Uuid;
  contactId: Uuid;
}): Uuid => {
  return resolveSyncModelNamespacedUuid({
    modelKind: { value: SyncModelKind.SpaceAccountContact },
    uuids: [spaceAccountId, contactId],
  });
};
