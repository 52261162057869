import { mdsPanelWidths, mdsSidebarWidths } from "@/design-system/foundations";
import {
  SidePanelRoute,
  SidePanelRouteType,
  SidePanelState,
  SidePanelTab,
} from "@/store/routing/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable, ObservableMap } from "mobx";

export class AppStoreSidePanelStore extends AppSubStore {
  sidePanelFocused: boolean;
  sidePanelState: SidePanelState;

  activeTabId: SidePanelTab;
  tabs: ObservableMap<SidePanelTab, SidePanelRoute[]>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.sidePanelState = SidePanelState.SPLIT_VIEW;
    this.sidePanelFocused = false;

    this.activeTabId = SidePanelTab.CHAT;
    this.tabs = new ObservableMap<SidePanelTab, SidePanelRoute[]>();

    makeObservable(this, {
      // SIDE PANEL STATE
      sidePanelState: observable,
      canRenderSplitView: computed,
      isMainPanelOpen: computed,
      isSidePanelOpen: computed,
      isSplitView: computed,

      // SIDE PANEL FOCUS
      sidePanelFocused: observable,
      setSidePanelFocused: action,

      // TABS
      activeTabId: observable,
      tabs: observable,
      activeRoute: computed,
      activeTabStack: computed,
      defaultTabRoute: computed,

      // ACTIONS
      switchTab: action,
      navigateTo: action,
      toggle: action,
      open: action,
      close: action,
      goUp: action,
      goToNote: action,
    });
  }

  // SIDE PANEL STATE
  get isSplitView() {
    return this.sidePanelState === SidePanelState.SPLIT_VIEW;
  }

  get isMainPanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (!this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.MAIN_PANEL_ONLY || this.isSplitView;
    }
  }

  get isSidePanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.SIDE_PANEL_ONLY || this.isSplitView;
    }
  }

  get canRenderSplitView() {
    if (!this.store.interface.dimensionsState.data) return true;
    const { min, gutter } = mdsPanelWidths();
    const { collapsed } = mdsSidebarWidths();
    return this.store.interface.dimensionsState.data?.width > min * 2 + gutter * 2 + collapsed;
  }

  // SIDE PANEL FOCUS
  setSidePanelFocused = (focused: boolean) => {
    this.sidePanelFocused = focused;
  };

  // TABS
  get activeRoute(): SidePanelRoute {
    return this.activeTabStack[this.activeTabStack.length - 1] || this.defaultTabRoute;
  }

  get activeTabStack(): SidePanelRoute[] {
    return this.tabs.get(this.activeTabId) || [this.defaultTabRoute];
  }

  get defaultTabRoute(): SidePanelRoute {
    return { type: SidePanelRouteType.CHAT };
  }

  // ACTIONS
  switchTab = (tabId: SidePanelTab) => {
    this.activeTabId = tabId;
  };

  goUp = () => {
    const activeStack = this.tabs.get(this.activeTabId);
    if (activeStack && activeStack.length > 0) {
      activeStack.pop();
    } else {
      this.close();
    }
  };

  navigateTo = (route: SidePanelRoute) => {
    const activeStack = this.tabs.get(this.activeTabId);
    if (!activeStack) {
      this.tabs.set(this.activeTabId, [route]);
    } else {
      activeStack.push(route);
    }
  };

  toggle = () => {
    if (this.isSplitView) this.close();
    else this.open();
  };

  open = () => {
    if (!this.canRenderSplitView) this.sidePanelFocused = true;
    this.sidePanelState = SidePanelState.SPLIT_VIEW;
  };

  close = () => {
    this.sidePanelFocused = false;
    this.sidePanelState = SidePanelState.MAIN_PANEL_ONLY;
  };

  goToNote = ({ noteId }: { noteId: string }) => {
    this.navigateTo({ type: SidePanelRouteType.NOTE_DETAIL, noteId });
  };
}
