import localforage from "localforage";

import { LocalStorageKey } from "@/modules/local-storage/constants";
import { LocalStoragePendingRedirectPath } from "@/modules/local-storage/types";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";

export const _writeLocalStoragePendingRedirectPath = async (
  value: LocalStoragePendingRedirectPath
): Promise<void> => {
  try {
    await localforage.setItem<Partial<LocalStoragePendingRedirectPath>>(
      LocalStorageKey.PendingRedirectPath,
      value
    );
  } catch (unknownErr) {
    const err = unknownErr as Error;

    logger.warn({
      message: "[_writeLocalStoragePendingRedirectPath] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });
  }
};

export const _clearLocalStoragePendingRedirectPath = async (): Promise<void> => {
  try {
    await localforage.removeItem(LocalStorageKey.PendingRedirectPath);
  } catch (unknownErr) {
    const err = unknownErr as Error;

    logger.warn({
      message: "[_clearLocalStoragePendingRedirectPath] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });
  }
};

export const _readLocalStoragePendingRedirectPath =
  async (): Promise<LocalStoragePendingRedirectPath> => {
    try {
      const result = await localforage.getItem<Partial<LocalStoragePendingRedirectPath>>(
        LocalStorageKey.PendingRedirectPath
      );

      const pathname = result?.pathname;

      if (!pathname) {
        return { pathname: null };
      }

      return { pathname };
    } catch (unknownErr) {
      const err = unknownErr as Error;

      logger.warn({
        message: "[_readLocalStoragePendingRedirectPath] Failed.",
        info: {
          err: objectModule.safeErrorAsJson(err),
        },
      });

      return { pathname: null };
    }
  };
