import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import {
  NoteContentDocumentDeletedSyncUpdateValue,
  NoteContentDocumentModelData,
  NoteDeletedSyncUpdateValue,
  NoteModelData,
} from "@/store/note/types";
import {
  SpaceAccountNoteDeletedSyncUpdateValue,
  SpaceAccountNoteModelData,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IDeleteNoteOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";
export class DeleteNoteOperation extends BaseSyncOperation<IDeleteNoteOperation> {
  get operationKind(): SyncOperationKind {
    return "DELETE_NOTE";
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const existingNote = this.store.notes.getNoteObservableById({ noteId: this.payload.id });
    if (!existingNote) return [];

    const syncUpdates = [];

    // Cascade CollectionItems
    const collectionItems = existingNote.collectionList.allCollectionItems;
    for (const collectionItem of collectionItems) {
      const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
        model_id: collectionItem.id,
        model_kind: "COLLECTION_ITEM",
        model_version: collectionItem.modelVersion,
      };
      const collectionItemUpdate: SyncUpdate<CollectionItemModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: collectionItemValue,
      };
      syncUpdates.push(collectionItemUpdate);
    }

    // Cascade SpaceAccountNote
    const spaceAccountNote = existingNote.spaceAccountNote;
    if (spaceAccountNote) {
      const spaceAccountNoteValue: SpaceAccountNoteDeletedSyncUpdateValue = {
        model_id: spaceAccountNote.id,
        model_kind: "SPACE_ACCOUNT_NOTE",
        model_version: spaceAccountNote.modelVersion,
      };
      const spaceAccountNoteUpdate: SyncUpdate<SpaceAccountNoteModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: spaceAccountNoteValue,
      };
      syncUpdates.push(spaceAccountNoteUpdate);
    }

    // Cascade FavoriteItem
    const favoriteItem = existingNote.favoriteItem;
    if (favoriteItem) {
      const favoriteItemValue: FavoriteItemDeletedSyncUpdateValue = {
        model_id: favoriteItem.id,
        model_kind: "FAVORITE_ITEM",
        model_version: favoriteItem.modelVersion,
      };
      const favoriteItemUpdate: SyncUpdate<FavoriteItemModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: favoriteItemValue,
      };
      syncUpdates.push(favoriteItemUpdate);
    }

    // Finally, delete the note and its content document
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.id,
    });
    const noteContentDocumentValue: NoteContentDocumentDeletedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
    };
    const noteContentDocumentDeleted: SyncUpdate<NoteContentDocumentModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: noteContentDocumentValue,
    };
    syncUpdates.push(noteContentDocumentDeleted);

    const noteValue: NoteDeletedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: existingNote.modelVersion,
    };
    const noteDeleted: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: noteValue,
    };
    syncUpdates.push(noteDeleted);

    return syncUpdates;
  }

  execute(): void {
    const existingNote = this.store.notes.get(this.payload.id);
    if (!existingNote) return;

    this.title = existingNote.title || UNTITLED_NOTE_TITLE;
    super.execute();
  }

  private title = UNTITLED_NOTE_TITLE;

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be deleted. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to permanently delete {this.mediumTitle}. Please contact the
        note owner.
      </>
    );
  }

  public handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be deleted. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
