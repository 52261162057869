import styled from "@emotion/styled";
import { MdsText, MdsTextProps, MdsTextSize } from "@/design-system/components/text";
import { ChatMessageSectionValue } from "@/domains/chat";
import { MdsCard, MdsCardKind, MdsCardProps, MdsCardRadius } from "@/design-system/components/card";
import { observer } from "mobx-react-lite";
import { FactCardSources } from "@/components/messages/item/section/fact-card/sources";

interface Props {
  data: ChatMessageSectionValue;
  dataTestId?: string;
  isIncomplete?: boolean;
}

export const FactCard = observer(function ({ data, dataTestId, isIncomplete }: Props): JSX.Element {
  const { label, value, sources } = data;

  return (
    <Wrapper
      kind={MdsCardKind.SmallShadow}
      radius={MdsCardRadius.Large}
      dataTestId={`${dataTestId}-fact-card`}
    >
      <Label size={MdsTextSize.Medium}>{label}</Label>
      <Value size={MdsTextSize.XXLarge} isIncomplete={isIncomplete}>
        {value}
      </Value>
      <FactCardSources sources={sources} />
    </Wrapper>
  );
});

const Wrapper = styled(MdsCard)<MdsCardProps>(({ theme }) => ({
  padding: theme.spacing.md,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  maxWidth: "100%",
  minWidth: "300px",
}));

const Label = styled(MdsText)<MdsTextProps>(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontWeight: theme.fontWeights.semiBold,
}));

interface ValueProps extends MdsTextProps {
  isIncomplete?: boolean;
}

const Value = styled(MdsText)<ValueProps>(({ theme, isIncomplete }) => ({
  color: isIncomplete ? theme.colors.grey.x500 : theme.colors.grey.x900,
  size: MdsTextSize.Large,
  fontWeight: theme.fontWeights.semiBold,
}));
