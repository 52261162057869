import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";

const wrapperStyles = css({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const headerTextStyles = css({
  marginBottom: 20,
  fontWeight: 800,
});

const errorMessageTextStyles = css({
  marginBottom: 20,
});

interface ErrorBoxProps {
  message?: string;
}

export const ErrorBox: FC<ErrorBoxProps> = observer(({ message }) => {
  const forceRefreshPage = useCallback(() => {
    window.location.replace("/");
  }, []);

  const messageContent = useMemo(() => {
    if (!message) {
      return null;
    }

    return <div className={errorMessageTextStyles}>Message: &quot;{message}&quot;</div>;
  }, [message]);

  return (
    <div className={wrapperStyles}>
      <div className={headerTextStyles}>Something went wrong!</div>
      {messageContent}
      <MdsButton
        variant={MdsButtonVariant.TextTertiaryOutlined}
        label="Refresh Page"
        onClick={forceRefreshPage}
      />
    </div>
  );
});
