import { computed, makeObservable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountCollectionModelData } from "@/store/recent-items/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreSpaceAccountCollectionsStore extends BaseSyncModelStore<
  SpaceAccountCollectionObservable,
  SpaceAccountCollectionModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountCollection, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      getSpaceAccountCollectionObservableById: false,
      allSpaceAccountCollections: computed,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountCollectionModelData>
  ): SpaceAccountCollectionObservable {
    return new SpaceAccountCollectionObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get allSpaceAccountCollections(): SpaceAccountCollectionObservable[] {
    return Array.from(this.pool.values());
  }

  public getSpaceAccountCollectionObservableById({
    spaceAccountCollectionId,
  }: {
    spaceAccountCollectionId?: Uuid;
  }): SpaceAccountCollectionObservable | undefined {
    return spaceAccountCollectionId ? this.pool.get(spaceAccountCollectionId) : undefined;
  }
}
