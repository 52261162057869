export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export class ControlledSleep {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _resolve: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _promise: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _timeout: any;

  constructor() {
    this._resolve;
    this._promise;
    this._timeout;
  }

  async sleep(ms: number) {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._promise = undefined;
      this._resolve();
    }, ms);
    if (!this._promise) {
      this._promise = new Promise(resolve => {
        this._resolve = resolve;
      });
    }
    return await this._promise;
  }

  async awake() {
    return await this.sleep(0);
  }
}

export class Hibernator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _resolve: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _promise: any;

  constructor() {
    this._resolve;
    this._promise;
  }

  async sleep() {
    if (!this._promise) {
      this._promise = new Promise(resolve => {
        this._resolve = resolve;
      });
    }
    return await this._promise;
  }

  async awake() {
    this._promise = undefined;
    if (this._resolve) {
      this._resolve();
    }
  }
}
