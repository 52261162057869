import styled from "@emotion/styled";

interface Props {
  isVisible: boolean;
}

export function Sending({ isVisible }: Props): JSX.Element {
  return <StyledDiv isVisible={isVisible}>Sending...</StyledDiv>;
}

interface StyledDivProps {
  isVisible: boolean;
}

const StyledDiv = styled.div<StyledDivProps>(({ theme, isVisible }) => ({
  textAlign: "right",
  margin: "0.25rem 0",
  color: theme.colors.grey.x500,
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes.xsmall,
  opacity: isVisible ? 1 : 0,
  transition: "opacity 0.3s, transform 0.3s",
  transform: isVisible ? "translateY(0)" : "translateY(10px)",
}));
