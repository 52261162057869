import { moduleFactory } from "@/factories/module";
import {
  _writeLocalStoragePendingRedirectPath,
  _clearLocalStoragePendingRedirectPath,
  _readLocalStoragePendingRedirectPath,
} from "@/modules/local-storage/redirect";

export const localStorageModule = moduleFactory.create({
  writePendingRedirectPath: _writeLocalStoragePendingRedirectPath,
  clearPendingRedirectPath: _clearLocalStoragePendingRedirectPath,
  readPendingRedirectPath: _readLocalStoragePendingRedirectPath,
});
