export enum MdsDotPulseLoaderKind {
  Light = "light",
  Dark = "dark",
  Brand = "brand",
}

export enum MdsDotPulseLoaderSize {
  Small = "small",
  Medium = "medium",
}

export enum MdsSkeletonLoaderKind {
  Light = "light",
  Dark = "dark",
}

export enum MdsSkeletonLoaderShape {
  Rectangle = "rectangle",
  Circle = "circle",
  Square = "square",
}
