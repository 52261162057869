import { Uuid } from "@/domains/global/identifiers";
import general from "@/domains/local-db/global/general/adapter";
import config from "@/domains/local-db/global/general/config";

export const setCurrentAuthInfo = async ({
  accountId,
  authToken,
  authStrategy,
}: {
  accountId: Uuid | null;
  authToken: string | null;
  authStrategy: string | null;
}) => {
  return Promise.all([
    general.set(config.CURRENT_ACCOUNT_ID_KEY, {
      [config.CURRENT_ACCOUNT_ID_KEY]: accountId,
    }),

    general.set(config.CURRENT_AUTH_TOKEN_KEY, {
      [config.CURRENT_AUTH_TOKEN_KEY]: authToken,
    }),

    general.set(config.CURRENT_AUTH_STRATEGY_KEY, {
      [config.CURRENT_AUTH_STRATEGY_KEY]: authStrategy,
    }),
  ]);
};
