import { FC, useMemo } from "react";
import { isEmpty } from "lodash-es";
import { css } from "@/domains/emotion";
import { FadeInOutAnimator } from "@/design-system/components/animation";

const errorMessageContainerStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "nowrap",
  position: "absolute",
  color: "#df1b41",
  bottom: -21,
  left: 0,
  userSelect: "none",
});

const errorMessageTextStyles = css({
  fontSize: "11px",
  marginLeft: 2,
});

interface MdsTextFieldErrorProps {
  errorMessage?: string;
}

export const MdsTextFieldError: FC<MdsTextFieldErrorProps> = ({ errorMessage }) => {
  const isErrorVisible = !isEmpty(errorMessage);

  const errorMessageElement = useMemo(() => {
    if (!errorMessage) {
      return null;
    }

    return (
      <div className={errorMessageContainerStyles}>
        <div className={errorMessageTextStyles}>{errorMessage}</div>
      </div>
    );
  }, [errorMessage]);

  return (
    <FadeInOutAnimator
      animationConfig={{
        duration: 80,
      }}
      isVisible={isErrorVisible}
      element={errorMessageElement}
    />
  );
};
