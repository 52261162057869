import { Uuid } from "@/domains/global/identifiers";
import { CollectionMetadataModelData } from "@/store/collections/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";

export class CollectionMetadataObservable extends BaseSyncModel<CollectionMetadataModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<CollectionMetadataModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      title: computed,
    });
  }

  get title(): string {
    return this.data.model_data.title;
  }
}
