import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";
import { NavigateFunction } from "react-router";

export const goBack = (store: INavigationStore, navigate: NavigateFunction) => {
  store.virtuosoStateStack.pop();
  const head = store.stack.pop();

  if (!head) return;

  navigate(-1);
};

export type INavigationStore = Pick<AppStoreNavigationStore, "stack" | "virtuosoStateStack">;
