import { action, computed, makeObservable, observable } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { INoteObservable } from "@/store/note/types";
import { actions } from "@/actions";

export class DeleteNotePermanentlyModalStore extends AppSubStore {
  private handleConfirm?: () => void;
  private onClose?: () => void;
  public notes: INoteObservable[] = [];

  constructor(
    injectedDeps: AppSubStoreArgs,
    { handleConfirm, onClose }: { handleConfirm?: () => void; onClose?: () => void }
  ) {
    super(injectedDeps);

    this.handleConfirm = handleConfirm;
    this.onClose = onClose;
    makeObservable<this, "handleConfirm" | "onClose">(this, {
      handleConfirm: false,
      onClose: false,

      notes: observable,
      isOpen: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get isOpen() {
    return !!this.notes.length;
  }

  public handleSubmit() {
    if (!this.notes.length) return;
    this.handleConfirm?.();
    actions.deleteNotesPermanently({ notes: this.notes, store: this.store });
    this.close();
    this.store.navigation.goBack();
  }

  public open({ notes }: { notes: INoteObservable[] }) {
    this.notes = notes;
  }

  public close() {
    this.notes = [];
    this.onClose?.();
  }
}
