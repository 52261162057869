import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { SyncModelKind } from "@/store/sync/types";

export const resolveFavoriteItemSyncModelUuid = ({
  spaceAccountId,
  itemId,
}: {
  spaceAccountId: Uuid;
  itemId: Uuid;
}): Uuid => {
  return resolveSyncModelNamespacedUuid({
    modelKind: { value: SyncModelKind.FavoriteItem },
    uuids: [spaceAccountId, itemId],
  });
};
