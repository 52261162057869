import { css, cx } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PlatformTrialState } from "@/store/platform-info/types";
import { DateTime, Duration } from "luxon";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { TrialInfoBadgeIconWithLabel } from "@/components/trial/badge/TrialInfoBadgeIconWithLabel";
import { ceil, isNull, round } from "lodash-es";
import { MdsTooltip } from "@/design-system/components/tooltip";
import { useTrialingTooltipConfig } from "@/components/trial/badge/TrialInfoTooltipConfig";

interface TrialInfoBadgeContentProps extends EmotionClassStyles {
  trialEnd: DateTime | null;
  trialState: PlatformTrialState;
  subscribeNowHandler: () => void;
}

export const TrialInfoBadgeContent: FC<TrialInfoBadgeContentProps> = observer(
  ({ trialEnd, subscribeNowHandler, className }) => {
    /**
     * Create a timer that fires every hour to update the trial days left label.
     */
    const [everyHour, setEveryHour] = useState(0);

    useEffect(() => {
      setInterval(
        () => {
          setEveryHour(everyHour => everyHour + 1);
        },
        Duration.fromObject({ hours: 1 }).as("milliseconds")
      );
    }, []);

    const numTrialDaysRemaining: number | null = useMemo(() => {
      if (isNull(trialEnd)) {
        return null;
      }

      const daysRemaining = trialEnd.diffNow("days").days;

      /** We round first to adjust for floating-point + datetime issues. */
      const roundedRange = round(daysRemaining, 1);
      return ceil(roundedRange);
    }, [trialEnd]);

    const trialDaysLeftLabel = useMemo(() => {
      if (isNull(numTrialDaysRemaining)) {
        return "";
      }

      if (numTrialDaysRemaining === 0 || numTrialDaysRemaining === 1) {
        return "Trial ends today";
      }

      if (numTrialDaysRemaining === 2) {
        return "Trial ends tomorrow";
      }

      if (numTrialDaysRemaining > 2) {
        return `${numTrialDaysRemaining} days left in trial`;
      }

      return "";
      /** We want this to update when `everyHour` changes. */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [everyHour, numTrialDaysRemaining]);

    const [tooltipState, setTooltipState] = useState(false);

    const setTooltipOpen = useCallback(() => {
      setTooltipState(true);
    }, [setTooltipState]);

    const setTooltipClosed = useCallback(() => {
      setTooltipState(false);
    }, [setTooltipState]);

    const wrappedSubscribeNowHandler = useCallback(() => {
      subscribeNowHandler();
      setTooltipClosed();
    }, [setTooltipClosed, subscribeNowHandler]);

    const tooltipConfig = useTrialingTooltipConfig({
      subscribeNowHandler: wrappedSubscribeNowHandler,
    });

    return (
      <div
        className={cx(trialButtonStyles, className)}
        onMouseEnter={setTooltipOpen}
        onMouseLeave={setTooltipClosed}
      >
        <MdsTooltip open={tooltipState} config={tooltipConfig} tooltipClassName={tooltipStyles}>
          <TrialInfoBadgeIconWithLabel label={trialDaysLeftLabel} />
        </MdsTooltip>
      </div>
    );
  }
);

const trialButtonStyles = css({
  margin: "0 8px",
  cursor: "default",
  maxWidth: 180,
});

const tooltipStyles = css({
  borderRadius: 16,
});
