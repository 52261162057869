import { useCallback, FC } from "react";

import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsItemAction } from "@/design-system/constants/items/types";
import { Uuid } from "@/domains/global/identifiers";
import { ReactMouseEvent } from "@/domains/react/types";

export interface MdsItemListRowActionProps {
  itemId: Uuid;
  action?: MdsItemAction;
}

const wrapperStyles = css({
  pointerEvents: "auto",
});

export const MdsItemListRowAction: FC<MdsItemListRowActionProps> = ({ action, itemId }) => {
  const handleClick = useCallback(
    (event: ReactMouseEvent) => {
      event.stopPropagation();
      action?.onClick({ itemId });
    },
    [action, itemId]
  );

  if (!action) {
    return null;
  }

  return (
    <div className={wrapperStyles}>
      <MdsButton
        label={action.label}
        variant={MdsButtonVariant.TextTertiary}
        onClick={handleClick}
      />
    </div>
  );
};
