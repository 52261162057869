import { GuestAppSubStore, GuestAppSubStoreArgs } from "@/store/types";
import { action, makeObservable, observable } from "mobx";

export class AppStoreGuestInterfaceStore extends GuestAppSubStore {
  isGreetingUiVisible = true;
  isSignUpUiVisible = false;

  constructor(injectedDeps: GuestAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      isGreetingUiVisible: observable,
      isSignUpUiVisible: observable,
      closeGreetingUi: action,
      closeSignUpUi: action,
      openSignUpUi: action,
      resetState: action,
    });
  }

  closeGreetingUi = () => {
    this.isGreetingUiVisible = false;
  };

  closeSignUpUi = () => {
    this.isSignUpUiVisible = false;
  };

  openSignUpUi = () => {
    this.isSignUpUiVisible = true;
  };

  resetState = () => {
    this.isGreetingUiVisible = false;
    this.isSignUpUiVisible = false;
  };
}
