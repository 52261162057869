import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { NoteObservable } from "@/store/note/NoteObservable";

export const addNotesToCollection = async ({
  notes,
  collection,
  store,
}: {
  notes: NoteObservable[];
  collection: CollectionObservable;
  store: AppStore;
}) => {
  if (!notes.length) return;

  notes.forEach(note =>
    collection.itemList.addItem({
      itemId: note.id,
      itemKind: "NOTE",
      triggerSuccessToast: notes.length === 1,
    })
  );

  if (notes.length === 1) return;

  toastModule.triggerToast({
    content: (
      <div>
        {`${notes.length === 1 ? notes[0].title : "Notes"} added to `}
        <a onClick={() => store.navigation.goToCollection({ collectionId: collection.id })}>
          {collection.title || UNTITLED_COLLECTION_TITLE}
        </a>
        {"."}
      </div>
    ),
  });
};
