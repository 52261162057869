import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AppCore } from "@/app/core/AppCore";

export const mountApp = () => {
  const documentRoot = document.getElementById("root") as HTMLElement;

  createRoot(documentRoot).render(
    <StrictMode>
      <AppCore />
    </StrictMode>
  );
};
