import { computed, makeObservable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStoreArgs } from "@/store/types";
import { ContactModelData } from "@/store/contacts/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { resolveContactSyncModelUuid } from "@/modules/uuid/sync-models/resolveContactModelUuid";

export class AppStoreContactsStore extends BaseSyncModelStore<ContactObservable, ContactModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Contact, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      getContactObservableById: false,
      getContactObservableByContactSpaceAccountId: false,
      getMatchingContacts: false,
      allContacts: computed,
      directContacts: computed,
      indirectContacts: computed,
    });
  }

  createSyncModel(data: SyncUpdateValue<ContactModelData>) {
    return new ContactObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  getContactObservableByContactSpaceAccountId(
    contactSpaceAccountId: Uuid
  ): ContactObservable | undefined {
    const id = resolveContactSyncModelUuid({ spaceAccountId: contactSpaceAccountId });
    return this.pool.get(id);
  }

  getContactObservableById(id: Uuid): ContactObservable | undefined {
    return this.pool.get(id);
  }

  getMatchingContacts = (query: string): ContactObservable[] => {
    const needle = query.trim().toLowerCase();
    if (!needle) return [];

    const contacts: ContactObservable[] = [];
    for (const contact of this.directContacts) {
      const haystack = `${contact.profileDisplayName.toLowerCase()} ${contact.profileEmailAddress.toLowerCase()}`;
      if (haystack.includes(needle)) contacts.push(contact);
    }
    return contacts;
  };

  get allContacts(): ContactObservable[] {
    return Array.from(this.pool.values());
  }

  get directContacts(): ContactObservable[] {
    return this.allContacts.filter(contact => contact.isDirect);
  }

  get indirectContacts(): ContactObservable[] {
    return this.allContacts.filter(contact => !contact.isDirect);
  }
}
