import { isArray } from "lodash-es";

import { RuntimeParseError } from "@/domains/errors";
import { _extractValueFromObject } from "@/modules/object/extractors/extractValue";

export const _extractArrayFromObject = (value: unknown, prop: string): unknown[] => {
  const extractedValue = _extractValueFromObject(value, prop);

  if (!isArray(extractedValue)) {
    throw new RuntimeParseError({
      message: "[_extractArrayFromObject] extractedValue was not an array.",
      info: {
        extractedValue,
        value,
        prop,
      },
    });
  }

  return extractedValue;
};
