import React, { FC, ReactNode } from "react";
import { ZIndex } from "@/domains/design/constants";
import { useHorizontalScroll, ScrollDirection } from "@/domains/react/useHorizontalScroll";
import { css, cx } from "@/domains/emotion";
import { ScrollButton } from "@/components/horizontal-scroll/ScrollButton";

interface FadeHorizontalScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const FadeHorizontalScroll: FC<FadeHorizontalScrollProps> = ({
  children,
  className,
  ...props
}) => {
  const {
    scrollContainerRef,
    scrollState,
    handleScroll,
    startScrolling,
    stopScrolling,
    scrollForDuration,
  } = useHorizontalScroll();

  return (
    <div className={wrapperStyles}>
      <ScrollButton
        direction={ScrollDirection.Left}
        scrollState={scrollState}
        startScrolling={startScrolling}
        stopScrolling={stopScrolling}
        scrollForDuration={scrollForDuration}
      />
      <div className={cx(leftShadowStyles, scrollState.leftVisible && leftShadowVisibleStyles)} />
      <div
        {...props}
        className={cx(horizontalStyles, className)}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        {children}
      </div>
      <div
        className={cx(rightShadowStyles, scrollState.rightVisible && rightShadowVisibleStyles)}
      />
      <ScrollButton
        direction={ScrollDirection.Right}
        scrollState={scrollState}
        startScrolling={startScrolling}
        stopScrolling={stopScrolling}
        scrollForDuration={scrollForDuration}
      />
    </div>
  );
};

const wrapperStyles = css({
  position: "relative",
});

const horizontalStyles = css({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  overflowX: "auto",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const leftShadowStyles = css({
  userSelect: "none",
  pointerEvents: "none",
  background: "linear-gradient(90deg,rgba(255, 255, 255,1) 20%,rgba(255, 255, 255, 0) 100%)",
  bottom: 0,
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: 100,
  zIndex: ZIndex.Shadow,
  opacity: 0,
  transition: "opacity 0.2s ease",
});

const leftShadowVisibleStyles = css({
  opacity: 1,
});

const rightShadowStyles = css({
  userSelect: "none",
  pointerEvents: "none",
  background: "linear-gradient(270deg,rgba(255, 255, 255,1) 0%, rgba(255, 255, 255, 0) 80%)",
  bottom: 0,
  height: "100%",
  position: "absolute",
  right: 0,
  top: 0,
  width: 100,
  zIndex: ZIndex.Shadow,
  opacity: 0,
  transition: "opacity 0.2s ease",
});

const rightShadowVisibleStyles = css({
  opacity: 1,
});
