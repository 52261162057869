import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useEventListener } from "usehooks-ts";
import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import {
  MdsDropdownContent,
  MdsDropdownContentProps,
} from "@/design-system/components/dropdown/MdsDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { AppStore, useAppStore } from "@/store";
import { css, cx } from "@/domains/emotion";
import { useMemo, useState } from "react";
import { actions } from "@/actions";
import { isMac } from "@/domains/platform/isMac";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { DateTime } from "luxon";
import { generateRecentDateString } from "@/domains/date/date";
import { mdsColors } from "@/design-system/foundations";
import { DropdownAnimation } from "@/components/dropdown-animation";

export interface SuggestedSearchesListProps {
  className?: string;
  currentSearchQuery: string;
  limit: number;
  onClick?: () => void;
}

export const SuggestedSearchesList = observer<SuggestedSearchesListProps>(function RecentItems({
  currentSearchQuery,
  className,
  limit,
  onClick,
}) {
  const { store, pageStore } = useAppStore();
  const { suggestions, handleSearch, updateSelectedItemId } = pageStore.quickSearchModal;

  const listRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onKeyDown = getKeyDownHandler(
    selectedIndex,
    Math.min(suggestions.length, limit),
    setSelectedIndex,
    getAction(suggestions, store, selectedIndex, handleSearch, onClick)
  );

  const onHover: MdsDropdownContentProps["onHover"] = ({ itemId }) => {
    setSelectedIndex(suggestions.findIndex(s => s.id === itemId));
  };

  useEventListener("keydown", onKeyDown, listRef);
  useEffect(() => {
    if (selectedIndex === -1) {
      updateSelectedItemId();
      return;
    }

    const item = suggestions[selectedIndex];
    if (item.type === SearchSuggestionType.NOTE) {
      updateSelectedItemId(item.id);
    } else {
      updateSelectedItemId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const contentList: MdsDropdownContentList = useMemo(() => {
    const filteredItems = suggestions.slice(0, limit);
    return {
      items: [
        {
          id: "header",
          text: "Suggestions",
          kind: MdsDropdownItemKind.Detail,
        },
        {
          id: `search-${currentSearchQuery}`,
          kind: MdsDropdownItemKind.Button,
          label: `Search for ${currentSearchQuery}`,
          iconKind: MdsIconKind.Search,
          className: cx({}, selectedIndex === -1 && quickSearchSelectedItemStyles),
          onClick: getClickHandler(null, store, handleSearch, onClick),
        },

        ...filteredItems.map((item, index) => {
          const isSelected = selectedIndex === index;
          const combinedItemClassName = cx({}, isSelected && quickSearchSelectedItemStyles);

          const selectedLabelDetail = generateRecentDateString(
            DateTime.fromISO(item.lastViewedAt || ""),
            {
              skipFullDayName: true,
            }
          );

          const button: MdsDropdownButtonItem = {
            id: item.id,
            kind: MdsDropdownItemKind.Button,
            label: item.label || "Untitled",
            isSelected,
            selectedLabelDetail,
            iconKind: getIcon(item),
            className: combinedItemClassName,
            iconSize: 16,
            onClick: getClickHandler(item, store, handleSearch, onClick),
          };

          return button;
        }),
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery, limit, selectedIndex, suggestions]);

  return (
    <DropdownAnimation>
      <MdsDropdownContent contentList={contentList} className={className} onHover={onHover} />
    </DropdownAnimation>
  );
});

const quickSearchSelectedItemStyles = css({
  background: mdsColors().grey.x50,
});

const getIcon = (item: SearchSuggestion) =>
  item.type === SearchSuggestionType.NOTE ? MdsIconKind.Document : MdsIconKind.Collection;

// TODO: following "Recents" pattern here but we can re-do Dropdown as set of links and allow browser to take care of opening new page natively
const getClickHandler =
  (
    item: SearchSuggestion | null,
    store: AppStore,
    handleSearch: () => void,
    onClick?: () => void
  ) =>
  ({
    event,
  }: {
    event?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>;
  }) => {
    if (!item) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        handleSearch();
      } else {
        handleSearch();
        onClick?.();
      }

      return;
    }

    if (item.type === SearchSuggestionType.NOTE) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        actions.openNoteInNewTab({ noteId: item.id });
      } else {
        store.navigation.goToNote({ noteId: item.id });
        onClick?.();
      }
    }
    if (item.type === SearchSuggestionType.COLLECTION) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        actions.openCollectionInNewTab({ collectionId: item.id });
      } else {
        store.navigation.goToCollection({ collectionId: item.id });
        onClick?.();
      }
    }
  };

const getKeyDownHandler =
  (
    selectedIndex: number,
    limit: number,
    setSelectedIndex: Dispatch<SetStateAction<number>>,
    onAction: () => void
  ) =>
  (e: KeyboardEvent) => {
    if (e.key == "Enter") {
      e.preventDefault();
      onAction();
      return;
    }

    let newIndex = selectedIndex;
    if (e.key === "ArrowDown") {
      newIndex = selectedIndex + 1;
    }

    if (e.key === "ArrowUp") {
      newIndex = selectedIndex - 1;
    }

    // wrap around
    if (newIndex > limit - 1) {
      newIndex = -1;
    } else if (newIndex < -1) {
      newIndex = limit - 1;
    }

    setSelectedIndex(newIndex);
  };

const getAction =
  (
    suggestions: SearchSuggestion[],
    store: AppStore,
    selectedIndex: number,
    handleSearch: () => void,
    onClick?: () => void
  ) =>
  () => {
    if (selectedIndex === -1) {
      handleSearch();
      return;
    }

    const item = suggestions[selectedIndex];
    if (item.type === SearchSuggestionType.NOTE) {
      store.navigation.goToNote({ noteId: item.id });
    } else if (item.type === SearchSuggestionType.COLLECTION) {
      store.navigation.goToCollection({ collectionId: item.id });
    }

    onClick?.();
  };
