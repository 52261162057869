import { NodeEnvironment } from "@/domains/common/types";
import { GLOBAL_ENV } from "@/modules/global-state/globalEnv";

export const clientEnvNodeEnvironment = (): NodeEnvironment => {
  const globalNodeEnv = GLOBAL_ENV.NODE_ENV;

  switch (globalNodeEnv) {
    case NodeEnvironment.Production:
      return NodeEnvironment.Production;
    case NodeEnvironment.Test:
      return NodeEnvironment.Test;
    case NodeEnvironment.Development:
      return NodeEnvironment.Development;
    default:
      throw new Error(`Invalid NODE_ENV: ${globalNodeEnv}`);
  }
};

export const clientEnvNodeEnvironmentIsProd = (): boolean => {
  return clientEnvNodeEnvironment() === NodeEnvironment.Production;
};

export const clientEnvNodeEnvironmentIsDev = (): boolean => {
  return clientEnvNodeEnvironment() === NodeEnvironment.Development;
};

export const clientEnvNodeEnvironmentIsTest = (): boolean => {
  return clientEnvNodeEnvironment() === NodeEnvironment.Test;
};
