import { GuestModeSyncErrorOverlay } from "@/components/error-handling/SyncErrorOverlay";
import { GuestModeGreetingInterstitialOverlayContent } from "@/components/guest-mode/GuestModeGreetingInterstitialOverlayContent";
import { RequiresSignUpInterstitialOverlayContent } from "@/components/guest-mode/RequiresSignUpInterstitialOverlayContent";
import { googleIdentityModule } from "@/modules/google-identity";
import { useGuestAppStore } from "@/store/utils/hooks";
import { observer } from "mobx-react-lite";

export const GuestModeOverlay = observer(() => {
  const { guestStore } = useGuestAppStore();
  const onSignUp = () => googleIdentityModule.triggerLogIn();
  return (
    <>
      <RequiresSignUpInterstitialOverlayContent
        isOpen={guestStore.interface.isSignUpUiVisible}
        onClose={guestStore.interface.closeSignUpUi}
        onSignUp={onSignUp}
      />
      <GuestModeGreetingInterstitialOverlayContent
        sharingAccount={guestStore.guestAccount.sharingAccount}
        sharingNoteTitle={guestStore.guestAccount.sharingNoteTitle}
        isOpen={guestStore.interface.isGreetingUiVisible}
        onClose={guestStore.interface.closeGreetingUi}
        onSignUp={onSignUp}
      />
      <GuestModeSyncErrorOverlay />
    </>
  );
});
