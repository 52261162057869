import { Opaque } from "type-fest";
import { isString } from "lodash-es";

interface CreateRouteConfig {
  starPattern?: boolean;
}

export type AppRoute = Opaque<{ path: string }, "AppRoute">;

/**
 * Examples:
 *   createRoute("/some-string/xyz")
 *   createRoute<{ data: someId }>(({ data }) => "/some-string/xyz/${data}")
 */
export const createAppRoute =
  <
    TParams,
    TPathTransformer extends string | ((params: TParams) => string) = TParams extends Record<
      string,
      string
    >
      ? (params: TParams) => string
      : string,
  >(
    pathOrTransformer: TPathTransformer
  ) =>
  ({
    params,
    starPattern,
  }: TPathTransformer extends string
    ? CreateRouteConfig & { params?: undefined }
    : CreateRouteConfig & { params: TParams }): AppRoute => {
    const path = (() => {
      if (isString(pathOrTransformer)) {
        return pathOrTransformer;
      }

      /** At this point, we know the params arg must be of type TParams. */
      return pathOrTransformer(params as TParams);
    })();

    if (starPattern) {
      const starPath = `${path}/*`;

      /** Remove any duplicate slashes. */
      return { path: starPath.replace(/\/\//g, "/") } as AppRoute;
    }

    return { path } as AppRoute;
  };
