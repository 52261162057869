import { MakeCollectionPrivateShareSheetModalStore } from "@/components/modal/share-sheet/MakeCollectionPrivateShareSheetModalStore";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";

export const MakeCollectionPrivateShareSheetContent = observer<{
  store: MakeCollectionPrivateShareSheetModalStore;
}>(function MakeCollectionPrivateShareSheetContent({ store }) {
  const targetDisplayNameText = store.targetDisplayName
    ? `${store.targetDisplayName}, the last collaborator`
    : "the last collaborator";

  return (
    <div className={modalContainerStyles}>
      <div className={headerStyles}>
        <div className={titleStyles}>
          <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
            Make this collection private?
          </MdsText>
        </div>
        <MdsSpacer />
        <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
      </div>
      <MdsText
        lineHeight={MdsTextSize.Small}
        color={mdsColors().grey.x600}
        size={MdsTextSize.Small}
      >
        You are about to remove {targetDisplayNameText}. Do you wish to keep this collection shared
        with only yourself, or make it to private? Converting to private will make it visible to you
        alone and may result in losing access to some notes not individually shared with you.
      </MdsText>
      <div className={buttonSectionStyles}>
        <MdsButton
          label="Cancel"
          variant={MdsButtonVariant.Tertiary}
          onClick={() => store.close()}
        />
        <div className={buttonSectionRightStyles}>
          <MdsButton
            label="Keep as Shared"
            variant={MdsButtonVariant.Tertiary}
            onClick={() => store.handleKeepCollectionShared()}
          />
          <MdsButton
            label="Make Private"
            variant={MdsButtonVariant.Danger}
            onClick={() => store.handleMakeCollectionPrivate()}
          />
        </div>
      </div>
    </div>
  );
});

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  padding: "30px",
  maxWidth: 500,
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const buttonSectionRightStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
});
