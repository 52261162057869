import { animated, useTransition } from "@react-spring/web";
import React, { ReactNode, FC, useEffect, useState } from "react";

import { AnimationConfig } from "@/design-system/components/animation/types";
import { _parseAnimationTransitionConfig } from "@/design-system/components/animation/utils/parseAnimationTransitionConfig";
import { SHARED_ANIMATION_DIV_STYLES } from "@/design-system/components/animation/utils/sharedAnimationStyles";
import { cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";

export interface FadeInOutAnimatorProps extends EmotionClassStyles {
  element: ReactNode;
  isVisible: boolean;
  transitionDelay?: number;
  animationConfig?: AnimationConfig;
}

export const FadeInOutAnimator: FC<FadeInOutAnimatorProps> = ({
  element,
  isVisible,
  animationConfig,
  className,
}) => {
  /**
   * We keep track of the "previous" element so that we can continue rendering it
   * while the new element is fading out.
   */
  const [targetElement, setTargetElement] = useState<ReactNode>(element);
  // const [previousElement, setPreviousElement] = useState<ReactNode>(null);

  useEffect(() => {
    if (isVisible) {
      setTargetElement(element);
    }
  }, [element, isVisible]);

  // useEffect(() => {
  //   if (element) {
  //     setPreviousElement(element);
  //   }
  // }, [element]);

  const transitionConfig = _parseAnimationTransitionConfig({
    animationConfig,
  });

  const transitions = useTransition(isVisible, {
    initial: { itemVisibility: isVisible ? 1 : 0 },
    from: { itemVisibility: 0 },
    enter: { itemVisibility: 1 },
    leave: { itemVisibility: 0 },
    delay: animationConfig?.delay ?? 0,
    config: transitionConfig,
  });

  const combinedStyles = cx(SHARED_ANIMATION_DIV_STYLES, className);

  return transitions((props, item) => {
    /**
     * This will be 'true' or 'false' based on `isVisible`
     */
    const shouldRender = item;
    const renderedElement = shouldRender ? targetElement : null;

    return (
      <animated.div
        style={{
          pointerEvents: props.itemVisibility.to(val => (val > 0.5 ? "auto" : "none")),
          opacity: props.itemVisibility.to({ range: [0, 1], output: [0, 1] }),
        }}
        className={combinedStyles}
      >
        {renderedElement}
      </animated.div>
    );
  });
};
