import React, { useMemo, FC } from "react";

import { TAB_BAR_ITEM_HORIZONTAL_PADDING } from "@/design-system/components/tab-bar/constants";
import { MdsTabBarItemContent } from "@/design-system/components/tab-bar/MdsTabBarItemContent";
import { MdsTabBarSection } from "@/design-system/components/tab-bar/types";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors, mdsFontSizes, mdsFontWeights } from "@/design-system/foundations";

export interface MdsTabBarSectionContentProps extends EmotionClassStyles {
  selectedItemId: string;
  section: MdsTabBarSection;
}

const wrapperStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingBottom: 20,
  width: "100%",
});

const sectionStyles = css({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  height: 16,
  marginBottom: 6,
  fontSize: mdsFontSizes().xxsmall,
  color: mdsColors().grey.x500,
  fontWeight: mdsFontWeights().bold,
  paddingLeft: TAB_BAR_ITEM_HORIZONTAL_PADDING,
  paddingRight: TAB_BAR_ITEM_HORIZONTAL_PADDING,
  userSelect: "none",
});

const itemContentStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
});

export const MdsTabBarSectionContent: FC<MdsTabBarSectionContentProps> = ({
  selectedItemId,
  section,
  className,
}) => {
  const combinedWrapperStyles = cx(wrapperStyles, className);

  const itemContent = useMemo(
    () =>
      section.items.map(item => (
        <MdsTabBarItemContent key={item.id} selectedItemId={selectedItemId} item={item} />
      )),
    [section.items, selectedItemId]
  );

  return (
    <div className={combinedWrapperStyles}>
      <div className={sectionStyles}>{section.label}</div>
      <div className={itemContentStyles}>{itemContent}</div>
    </div>
  );
};
