import { CoercionError } from "@/domains/errors";
import { _validateAndNormalizeUuid } from "@/modules/uuid/validation/validateAndNormalize";
import { isString } from "lodash-es";

export const _serializeUuid = (value: unknown): string => {
  if (!isString(value)) {
    throw new CoercionError({
      message: `[_serializeUuid]: Could not serialize Uuid with value - ${value}`,
    });
  }

  /**
   * We normalize the UUIDs we return to ensure our API is consistent.
   */
  const serializedValue = _validateAndNormalizeUuid({
    uuidString: value,
  });

  return serializedValue;
};
