import { easings } from "@react-spring/web";
import { useMemo } from "react";

import { AnimationConfig } from "@/design-system/components/animation/types";

export const _parseAnimationTransitionConfig = ({
  animationConfig,
}: {
  animationConfig?: AnimationConfig;
}) => {
  const transitionConfig = useMemo(
    () => ({
      duration: animationConfig?.duration ?? 300,
      easing: animationConfig?.easing ?? easings.easeInOutSine,
    }),
    [animationConfig?.duration, animationConfig?.easing]
  );

  return transitionConfig;
};
