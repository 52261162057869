import { actions } from "@/actions";
import { DropdownAnimation } from "@/components/dropdown-animation";
import { MdsDraggableList } from "@/design-system/components/draggable-list/MdsDraggableList";
import { MdsDraggableListItem } from "@/design-system/components/draggable-list/types";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { isMac } from "@/domains/platform/isMac";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";

interface FavoritesListProps {
  className?: string;
  animate?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
}

export const FavoritesList = observer<FavoritesListProps>(function FavoritesList({
  className,
  animate = true,
  onSelect,
  onClick,
}) {
  const { store } = useAppStore();

  const pins = store.favoriteItems.sortedFavoriteItems;

  const location = useLocation();
  const fullPath = location.pathname + location.search;

  const items: MdsDraggableListItem[] = pins.map(item => ({
    id: item.id,
    label: item.item?.title || "Untitled",
    isActive: item.path === fullPath,
    iconKind: (() => {
      switch (item.itemKind) {
        case "NOTE":
          return MdsIconKind.Document;
        case "COLLECTION":
          return MdsIconKind.Collection;
        case "SAVED_SEARCH":
          return MdsIconKind.Search;
        default:
          return MdsIconKind.Question;
      }
    })(),
    onClick: ({ event }) => {
      switch (item.itemKind) {
        case "NOTE": {
          if (isMac() ? event?.metaKey : event?.ctrlKey) {
            actions.openNoteInNewTab({ noteId: item.itemId });
          } else {
            onSelect?.();
            store.navigation.goToNote({ noteId: item.itemId });
          }
          onClick?.();
          break;
        }
        case "COLLECTION": {
          if (isMac() ? event?.metaKey : event?.ctrlKey) {
            actions.openCollectionInNewTab({ collectionId: item.itemId });
          } else {
            onSelect?.();
            store.navigation.goToCollection({ collectionId: item.itemId });
          }
          onClick?.();
          break;
        }
        case "SAVED_SEARCH": {
          if (isMac() ? event?.metaKey : event?.ctrlKey) {
            actions.openSearchInNewTab({ savedSearchId: item.itemId, store });
          } else {
            onSelect?.();
            store.navigation.goToSavedSearch({ savedSearchId: item.itemId });
          }
          onClick?.();
          break;
        }
      }
    },
  }));

  const contentList: MdsDropdownContentList = {
    items: [
      {
        id: "header",
        text: "Pinned",
        kind: MdsDropdownItemKind.Detail,
      },
      {
        id: "list",
        kind: MdsDropdownItemKind.Other,
        content: (
          <MdsDraggableList items={items} reorderItems={store.favoriteItems.reorderFavoriteItems} />
        ),
      },
    ],
  };

  const contentListEmpty: MdsDropdownContentList = {
    items: [
      {
        id: "header",
        text: "Pinned",
        kind: MdsDropdownItemKind.Detail,
      },
      {
        id: "list",
        kind: MdsDropdownItemKind.Other,
        content: (
          <div className={emptyListTextStyles}>
            Your pinned notes, collections, and searches appear here. Favorite something by pressing
            the pin icon.
          </div>
        ),
      },
    ],
  };

  return (
    <DropdownAnimation skipAnimation={!animate}>
      <MdsDropdownContent
        contentList={pins.length === 0 ? contentListEmpty : contentList}
        className={className}
      />
    </DropdownAnimation>
  );
});

const emptyListTextStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().small,
  whiteSpace: "wrap",
  lineHeight: "20px",
  padding: "0 8px 8px 8px",
});
