import { action, computed, makeObservable, observable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore, GuestAppStore } from "@/store";
import { GetCollection } from "@/components/modal/share-sheet/ShareSheetModalStore";

export class MakeCollectionPrivateShareSheetModalStore {
  private store: AppStore | GuestAppStore;
  private getCollection?: GetCollection;

  public collectionId: Uuid | undefined = undefined;
  public targetSpaceAccountId: Uuid | undefined = undefined;

  constructor({
    store,
    getCollection,
  }: {
    store: AppStore | GuestAppStore;
    getCollection?: GetCollection;
  }) {
    this.store = store;
    this.getCollection = getCollection;

    makeObservable<this, "store" | "getCollection">(this, {
      store: false,
      getCollection: false,

      collectionId: observable,
      targetSpaceAccountId: observable,

      isOpen: computed,
      collection: computed,
      targetDisplayName: computed,

      handleKeepCollectionShared: action,
      handleMakeCollectionPrivate: action,
      open: action,
      close: action,
    });
  }

  get isOpen() {
    return this.collection && !!this.targetSpaceAccountId;
  }

  get collection() {
    if (!this.collectionId) return undefined;
    return this.getCollection?.({ collectionId: this.collectionId });
  }

  get targetDisplayName(): string | undefined {
    if (!this.targetSpaceAccountId) return;
    const contact = this.store.contacts.getContactObservableByContactSpaceAccountId(
      this.targetSpaceAccountId
    );
    return contact?.profileDisplayName;
  }

  public handleKeepCollectionShared() {
    if (!this.collection || !this.targetSpaceAccountId) return;
    this.collection.revokeAccessViaSpaceAccount({
      targetSpaceAccountId: this.targetSpaceAccountId,
    });
    this.close();
  }

  public handleMakeCollectionPrivate() {
    if (!this.collection) return;
    this.collection.makePrivate();
    this.close();
  }

  public open({
    collectionId,
    targetSpaceAccountId,
  }: {
    collectionId?: Uuid;
    targetSpaceAccountId?: Uuid;
  }) {
    this.collectionId = collectionId;
    this.targetSpaceAccountId = targetSpaceAccountId;
  }

  public close() {
    this.collectionId = undefined;
  }
}
