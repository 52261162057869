import { mdsColors } from "@/design-system/foundations";
import { css, keyframes } from "@/domains/emotion";
import { memo } from "react";

export interface MdsSpinnerProps {
  size: number;
}

export const MdsSpinner = memo<MdsSpinnerProps>(function MdsSpinner({ size }) {
  return (
    <div className={containerStyles} style={{ height: size, width: size }}>
      <div className={spinnerStyles} />
    </div>
  );
});

const spin = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
});

const containerStyles = css({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

const spinnerStyles = css({
  animation: `${spin} 1s linear infinite`,
  border: "2px solid transparent",
  borderRadius: "50%",
  borderTopColor: mdsColors().grey.x600,
  height: "100%",
  maskImage: "linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 65%, rgba(0,0,0,0) 100%)",
  width: "100%",
});
