import { isNull, isUndefined } from "lodash-es";

export const _transformObjectToJson = (value: Record<string, unknown>): string => {
  return JSON.stringify(value);
};

export const _maybeTransformObjectToJson = (
  value: Record<string, unknown> | null | undefined
): string | undefined => {
  if (isUndefined(value) || isNull(value)) {
    return undefined;
  }

  return _transformObjectToJson(value);
};
