import { observer } from "mobx-react-lite";
import { FC } from "react";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { css } from "@/domains/emotion";
import { MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { mdsFontSizes } from "@/design-system/foundations";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon";

export interface AuthCardEmailPasswordButtonBaseProps {
  onClick: () => void;
  label: string;
}

export const AuthCardEmailPasswordButtonBase: FC<AuthCardEmailPasswordButtonBaseProps> = observer(
  ({ onClick, label }) => {
    return (
      <MdsButton
        label={null}
        size={MdsButtonSize.Large}
        variant={MdsButtonVariant.TextTertiaryOutlined}
        onClick={onClick}
        className={buttonStyles}
      >
        <div className={actionTextStyles}>
          <MdsIcon kind={MdsIconKind.Envelope} className={actionIconStyles} />
          {label}
        </div>
      </MdsButton>
    );
  }
);

const actionTextStyles = css({
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  fontSize: mdsFontSizes().medium,
});

const actionIconStyles = css({
  marginRight: 8,
});

const buttonStyles = css({
  width: 200,
});
