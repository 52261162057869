import { FloatingTree } from "@floating-ui/react";
import React, { ReactNode, useEffect, FC } from "react";

import { _injectGlobalProviders } from "@/components/styling/utils/globalStyles";

export interface MdsGlobalProviderProps {
  children: ReactNode;
}

export const MdsGlobalProvider: FC<MdsGlobalProviderProps> = ({ children }) => {
  useEffect(() => {
    _injectGlobalProviders();
  }, []);

  return <FloatingTree>{children}</FloatingTree>;
};
