import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { SettingsDebugAccountInfoForm } from "@/pages/settings/components/debug-account-info-form/SettingsDebugAccountInfoForm";
import { FC } from "react";

export const SettingsDebugAccountPage: FC = () => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Debug Account">
        <div>
          <SettingsDebugAccountInfoForm />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
};
