import { MdsHoverContentGroup } from "@/design-system/components/hover-content/MdsHoverContentGroup";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { mdsColors, mdsSpacings, mdsSidebarWidths } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import styled from "@emotion/styled";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { MdsHorizontalDivider } from "@/design-system/components/divider";
import {
  MdsHoverContent,
  MdsHoverContentPlacement,
} from "@/design-system/components/hover-content/MdsHoverContent";
import { FavoritesList } from "@/components/layout/components/FavoritesList";
import { RecentsList } from "@/components/layout/components/RecentsList";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { SidebarDropdownMenu } from "@/components/layout/components/sidebar/SidebarDropdownMenu";
import { css } from "@/domains/emotion";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { ProfileVariant } from "@/components/layout/components/account-profile/types";

export const SidebarCollapsed = observer(
  ({
    toggleSidebar,
    shouldHideToggle,
  }: {
    toggleSidebar: () => void;
    shouldHideToggle: boolean;
  }) => {
    const { store } = useAppStore();

    const isChatPath = useMatch(appRoutes.chat({ starPattern: false }));
    const isNotesPath = useMatch(appRoutes.notesList({ starPattern: false }));
    const isCollectionsPath = useMatch(appRoutes.collections({ starPattern: false }));

    const isTouchDevice = store.interface.isTouchDevice;
    const { profileDisplayName, profilePhotoUrl } = store.account.myAccount;

    const hoverGroup = useMemo(() => new MdsHoverContentGroup(), []);
    const closeAll = useCallback(() => hoverGroup.hideAll({}), [hoverGroup]);

    return (
      <SidebarWrapper>
        <SidebarTopWrapper>
          <MdsIconButton
            iconKind={MdsIconKind.Sidebar}
            onClick={toggleSidebar}
            className={shouldHideToggle ? hiddenIconButtonStyles : undefined}
          />
        </SidebarTopWrapper>
        <SidebarMainWrapper>
          <MdsHoverContent
            isTouchDevice={isTouchDevice}
            group={hoverGroup}
            content={() => <SidebarDropdownMenu className={dropdownStyles} displayAccountInfo />}
            placement={MdsHoverContentPlacement.Right}
          >
            <AccountProfileImageContent
              size={ProfileSize.Standard}
              photoUrl={profilePhotoUrl}
              displayName={profileDisplayName}
              variant={ProfileVariant.Square}
            />
          </MdsHoverContent>
          <ComposeIconButton
            iconKind={MdsIconKind.ComposeInline}
            onClick={store.notes.composeNewNote}
            variant={MdsIconButtonVariant.Outlined}
            tooltipConfig={{
              content: "Create note",
              placement: MdsTooltipPlacement.Right,
            }}
          />
          <MdsIconButton
            iconKind={MdsIconKind.Message}
            onClick={store.navigation.goToChat}
            className={isChatPath ? activeContentListItemStyles : undefined}
            tooltipConfig={{
              content: "Chat",
              placement: MdsTooltipPlacement.Right,
            }}
          />
          <MdsHorizontalDivider />
          <MdsIconButton
            iconKind={MdsIconKind.Notebook}
            onClick={() => store.navigation.goToNotes()}
            className={isNotesPath ? activeContentListItemStyles : undefined}
            tooltipConfig={{
              content: "Notes",
              placement: MdsTooltipPlacement.Right,
            }}
          />
          <MdsIconButton
            iconKind={MdsIconKind.Collection}
            onClick={store.navigation.goToCollections}
            className={isCollectionsPath ? activeContentListItemStyles : undefined}
            tooltipConfig={{
              content: "Collections",
              placement: MdsTooltipPlacement.Right,
            }}
          />
          <MdsHorizontalDivider />
          <MdsHoverContent
            isTouchDevice={isTouchDevice}
            group={hoverGroup}
            content={() => (
              <RecentsList onClick={closeAll} animate={false} className={dropdownStyles} />
            )}
            placement={MdsHoverContentPlacement.Right}
          >
            <MdsIconButton iconKind={MdsIconKind.Clock} />
          </MdsHoverContent>
          <MdsHoverContent
            isTouchDevice={isTouchDevice}
            group={hoverGroup}
            content={() => (
              <FavoritesList onClick={closeAll} animate={false} className={dropdownStyles} />
            )}
            placement={MdsHoverContentPlacement.Right}
          >
            <MdsIconButton iconKind={MdsIconKind.Thumbtack} />
          </MdsHoverContent>
        </SidebarMainWrapper>
        <SidebarBottomWrapper> </SidebarBottomWrapper>
      </SidebarWrapper>
    );
  }
);

const SidebarWrapper = styled.div({
  color: `#323640`,
  display: `flex`,
  flexDirection: `column`,
  height: `100dvh`,
  opacity: 1,
  transition: `opacity 0.25s ease-in-out, transform 0.25s ease-in-out`,
  width: mdsSidebarWidths().collapsed,
  flexShrink: 0,
  zIndex: ZIndex.Sidebar,
});

const SidebarMainWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: mdsSpacings().sm,
  gap: mdsSpacings().md,
});

const SidebarTopWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: mdsSpacings().sm,
  gap: mdsSpacings().md,
  marginBottom: mdsSpacings().md,
});

const SidebarBottomWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  marginTop: "auto",
});

const ComposeIconButton = styled(MdsIconButton)({
  backgroundColor: mdsColors().white,
});

const dropdownStyles = css({
  width: `calc(${mdsSidebarWidths().expanded}px - ${mdsSpacings().md} * 2)`,
});

const hiddenIconButtonStyles = css({
  opacity: 0,
});

const activeContentListItemStyles = css({
  backgroundColor: mdsColors().grey.x50,
});
