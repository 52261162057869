import { useCallback } from "react";
import styled from "@emotion/styled";
import { MdsItem, MdsItemKind } from "@/design-system/components/item-list/types";
import { CollectionIcon } from "@/design-system/components/item-list/rows/icon/collection";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note";

interface Props {
  item: MdsItem;
  isSelected?: boolean;
  isHighlighted?: boolean;
  itemRef: React.MutableRefObject<MdsItem>;
  handleItemClick: (event: React.MouseEvent) => void;
}

export function Icon({ item, itemRef, isSelected, isHighlighted, handleItemClick }: Props) {
  const toggleSelected = useCallback(
    (event: React.MouseEvent) => {
      if (event.shiftKey) {
        handleItemClick(event);
        return;
      }
      event.stopPropagation();
      const item = itemRef.current;
      item.listState?.toggleItemSelection(item.id);
    },
    [handleItemClick, itemRef]
  );

  return (
    <Wrapper>
      {item.kind === MdsItemKind.Collection && (
        <CollectionIcon
          itemId={item.id}
          isSelected={isSelected}
          isHighlighted={isHighlighted}
          toggleSelected={toggleSelected}
        />
      )}
      {item.kind === MdsItemKind.Note && (
        <NoteIcon
          isSelected={isSelected}
          isHighlighted={isHighlighted}
          toggleSelected={toggleSelected}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 36,
  justifySelf: "center",
  padding: `0 ${theme.spacing.sm}`,
}));
