import { observer } from "mobx-react-lite";
import { useMemo, useCallback } from "react";
import { useAppStore } from "@/store";
import { APP_CONFIG } from "@/domains/global/config";
import { uuidModule } from "@/modules/uuid";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { SidebarDropdownMenuContent } from "@/components/layout/components/sidebar/SidebarDropdownMenuContent";

export const SidebarDropdownMenu = observer(
  ({
    onClick,
    className,
    displayAccountInfo,
  }: {
    onClick?: () => void;
    className?: string;
    displayAccountInfo?: boolean;
  }) => {
    const { store } = useAppStore();

    const handleMobile = useCallback(() => {
      window.open(APP_CONFIG.URLS.IOS_APP, "_blank");
    }, []);

    const handleLogOut = useCallback(() => {
      onClick?.();
      store.navigation.goToLogOut();
    }, [onClick, store.navigation]);

    const handleOpenSettings = useCallback(() => {
      onClick?.();
      store.navigation.goToSettings();
    }, [onClick, store.navigation]);

    const handleOpenImports = useCallback(() => {
      onClick?.();
      store.navigation.goToImportFrom1dot0();
    }, [onClick, store.navigation]);

    const handleOpenIntegrations = useCallback(() => {
      onClick?.();
      store.navigation.goToIntegrations();
    }, [onClick, store.navigation]);

    const handleTrashClick = useCallback(() => {
      onClick?.();
      store.navigation.goToTrash();
    }, [onClick, store.navigation]);

    const handleNavigateToAdminPanel = useCallback(() => {
      onClick?.();
      store.navigation.goToAdmin();
    }, [onClick, store.navigation]);

    const handleToggleDebugging = useCallback(() => {
      onClick?.();
      store.sync.actionQueue.toggleDebugging();
    }, [onClick, store.sync.actionQueue]);

    const handleToggleNoteContentPreloading = useCallback(async () => {
      onClick?.();
      store.noteContentDocuments.togglePreloading();
    }, [onClick, store.noteContentDocuments]);

    const handleResetSync = useCallback(() => {
      store.resetStorageAndReload();
    }, [store]);

    const handlePushFailedSyncOperation = useCallback(() => {
      const operation = new TrashNoteOperation({
        store,
        payload: { id: "invalid-id" },
      });
      store.sync.actionQueue.push(operation);
      onClick?.();
    }, [onClick, store]);

    const handlePushPermissionDeniedSyncOperation = useCallback(() => {
      const operation = new UpdateCollectionOperation({
        store,
        payload: {
          id: uuidModule.generate(),
          title: "Test Title",
          description: "Test Description",
        },
      });
      store.sync.actionQueue.push(operation);
      onClick?.();
    }, [onClick, store]);

    const handleNavigateToHelpAndSupport = useCallback(() => {
      window.open(APP_CONFIG.URLS.HELP_AND_SUPPORT, "_blank");
    }, []);

    const handlePauseSync = useCallback(() => {
      store.sync.actionQueue.pause();
    }, [store.sync.actionQueue]);

    const handleResumeSync = useCallback(() => {
      store.sync.actionQueue.resume();
    }, [store.sync.actionQueue]);

    const { inboundSyncState, outboundSyncState } = store.sync;
    const { profilePhotoUrl, profileDisplayName, profileEmailAddress } = store.account.myAccount;
    const { isPreloadingDisabled: isNoteContentPreloadingDisabled } = store.noteContentDocuments;
    const isDebugMode = store.debug.debugModeEnabled;

    const handlers = useMemo(
      () => ({
        handleMobile,
        handleLogOut,
        handleOpenSettings,
        handleOpenImports,
        handleOpenIntegrations,
        handleTrashClick,
        handleNavigateToAdminPanel,
        handleToggleDebugging,
        handleResetSync,
        handleToggleNoteContentPreloading,
        handlePushFailedSyncOperation,
        handlePushPermissionDeniedSyncOperation,
        handleNavigateToHelpAndSupport,
        handlePauseSync,
        handleResumeSync,
      }),
      [
        handleMobile,
        handleLogOut,
        handleOpenSettings,
        handleOpenImports,
        handleOpenIntegrations,
        handleTrashClick,
        handleNavigateToAdminPanel,
        handleToggleDebugging,
        handleResetSync,
        handleToggleNoteContentPreloading,
        handlePushFailedSyncOperation,
        handlePushPermissionDeniedSyncOperation,
        handleNavigateToHelpAndSupport,
        handlePauseSync,
        handleResumeSync,
      ]
    );

    return (
      <SidebarDropdownMenuContent
        className={className}
        isDebugMode={isDebugMode}
        syncInfo={{
          inboundSyncState,
          outboundSyncState,
          isNoteContentPreloadingDisabled,
        }}
        accountInfo={
          displayAccountInfo
            ? {
                profilePhotoUrl,
                profileDisplayName,
                profileEmailAddress,
              }
            : undefined
        }
        handlers={handlers}
      />
    );
  }
);
