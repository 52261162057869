import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionItemKind } from "@/store/collection-items/types";
import { NoteObservable } from "@/store/note/NoteObservable";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";
import { WithAppStore } from "@/store/types";

import { makeObservable, computed, action, observable } from "mobx";

export class CollectionItemListObservable {
  private store: AppStore;
  private collectionId: Uuid;

  constructor({ collectionId, store }: { collectionId: Uuid } & WithAppStore) {
    this.store = store;
    this.collectionId = collectionId;

    makeObservable<this, "store" | "collectionId">(this, {
      store: false,
      collectionId: observable,
      size: computed,
      allItems: computed,
      allCollectionItems: computed,
      allItemObjectIds: computed,
      hasItem: false,
      findItem: false,
      addItem: action,
      removeItem: action,
      deleteAll: action,
    });
  }

  get size() {
    return this.allItems.length;
  }

  get allItems(): NoteObservable[] {
    return this.allCollectionItems
      .map(item => item.item)
      .filter(
        (item): item is NoteObservable =>
          !!item && !item.isTrashed && !item.isDeleted && item.canAccess
      );
  }

  get allCollectionItems(): CollectionItemObservable[] {
    return this.store.collectionItems.collectionsToCollectionItems.get(this.collectionId) ?? [];
  }

  get allItemObjectIds(): Set<Uuid> {
    return new Set(this.allCollectionItems.map(item => item.itemId) ?? []);
  }

  public hasItem({ itemId }: { itemId: string }) {
    return this.allCollectionItems.some(item => item.itemId === itemId);
  }

  public findItem({ itemId }: { itemId: string }) {
    return this.allCollectionItems.find(item => item.itemId === itemId);
  }

  public addItem({
    itemId,
    itemKind,
    triggerSuccessToast,
  }: {
    itemId: string;
    itemKind: CollectionItemKind;
    triggerSuccessToast?: boolean;
  }) {
    if (this.hasItem({ itemId })) return;
    if (itemKind === "NOTE") {
      new AddNoteToCollectionOperation({
        store: this.store,
        payload: {
          collection_id: this.collectionId,
          note_id: itemId,
        },
        triggerSuccessToast,
      }).execute();
    } else throw new Error("[CollectionItemListObservable] Unsupported item kind");
  }

  public removeItem({ itemId }: { itemId: string }) {
    const collectionItem = this.findItem({ itemId });
    if (!collectionItem) return;
    if (collectionItem.itemKind === "NOTE") {
      new RemoveNoteFromCollectionOperation({
        store: this.store,
        payload: {
          collection_id: this.collectionId,
          note_id: itemId,
        },
      }).execute();
    } else throw new Error("[CollectionItemListObservable] Unsupported item kind");
  }

  public deleteAll() {
    for (const item of this.allItems) this.removeItem({ itemId: item.id });
  }
}
