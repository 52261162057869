import { ValidationError } from "@/domains/errors";
import { Uuid } from "@/domains/global/identifiers";
import { validationModule } from "@/modules/validation";

/**
 * Normalizes UUID character casing.
 * Eg.
 * - AABC45... => aabc45...
 * - aAbC78... => aabc45...
 * - aabc45... => aabc45...
 *
 * We use lowercase alphabet characters to match how PostgreSQL represents UUIDs.
 *
 * Some languages (like swift) use an all-uppercase alphabet for UUIDs, so we need
 * to make sure we normalize them.
 */
export const _validateAndNormalizeUuid = ({ uuidString }: { uuidString: string }): Uuid => {
  if (!validationModule.isUuid(uuidString)) {
    throw new ValidationError({
      message: `[_validateAndNormalizeUuid] Invalid uuidString=${uuidString}`,
    });
  }

  return uuidString.toLowerCase();
};
