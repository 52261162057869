import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsCarouselItem } from "@/design-system/components/item-list/types";
import { AppStore } from "@/store/AppStore";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export const getMdsCarouselItemForCollection = ({
  collection,
  store,
}: {
  collection: Pick<CollectionObservable, "id" | "label" | "overview">;
  store: AppStore;
}): MdsCarouselItem => ({
  id: collection.id,
  label: collection.label,
  secondary: collection.overview ?? "",
  icon: () => {
    return <CollectionIcon collectionId={collection.id} size={32} />;
  },
  onClick: ({ itemId }: { itemId: string }) => {
    store.navigation.goToCollection({ collectionId: itemId });
  },
});
