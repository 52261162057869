import { CoercionError } from "@/domains/errors";
import { isString } from "lodash-es";
import { DateTime } from "luxon";

export const _parseDateTimeValue = (value: unknown): DateTime => {
  if (!isString(value)) {
    throw new CoercionError({
      message: `[_parseDateTimeValue]: Could not parse DateTime with value - ${value}`,
    });
  }

  try {
    return DateTime.fromISO(value);
  } catch {
    throw new CoercionError({
      message: `[_parseDateTimeValue]: DateTime was not valid - ${value}`,
    });
  }
};
