import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, FC } from "react";

import { ClassNamesArg, css, cx, keyframes } from "@/domains/emotion";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { iconDefinitionMapping } from "@/design-system/components/icon/iconDefinitionMapping";
import { mdsColors } from "@/design-system/foundations";

export interface MdsIconStylingProps {
  className?: ClassNamesArg;
  innerStyles?: {
    Icon?: {
      className?: ClassNamesArg;
    };
  };
}

export interface MdsIconProps extends MdsIconStylingProps {
  kind: MdsIconKind;
}

export const MdsIcon: FC<MdsIconProps> = memo(({ kind, className, innerStyles }) => {
  const iconDefinition = iconDefinitionMapping[kind];

  return (
    <div className={cx(wrapperStyles, className)}>
      <FontAwesomeIcon
        icon={iconDefinition}
        className={cx(
          fsStyles,
          innerStyles?.Icon?.className,
          kind === MdsIconKind.Spinner && spinnerStyles
        )}
      />
    </div>
  );
});

const wrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: 16,
  width: 16,
  color: mdsColors().grey.x700, // Applied here to allow .mds-btn-icon to override style
});

const fsStyles = css({
  fontSize: "14px",
  lineHeight: 1,
  userSelect: "none",
  draggable: "false",
});

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const spinnerStyles = css({
  animation: `${spin} 1s linear infinite`,
});
