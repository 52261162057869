import { ErrorBox } from "@/components/error-handling/ErrorBox";
import { LoadingOverlay } from "@/components/overlay";
import { useGoogleOAuthRedirectPageBehavior } from "@/pages/google-oauth-redirect/utils/behavior";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

interface GoogleOAuthRedirectProps {}

export const GoogleOAuthRedirectPage: FC<GoogleOAuthRedirectProps> = observer(() => {
  const state = useGoogleOAuthRedirectPageBehavior();

  if (state.failedAt) {
    /**
     * @todo - Can we add better handling based on the error kind here?
     */
    return <ErrorBox message="An unknown error occurred." />;
  }

  return <LoadingOverlay isVisible={true} />;
});
