import { mdsBorderRadius, mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { ReactNode } from "react";
import { ToastOptions, toast } from "react-toastify";

export const triggerToast = ({
  content,
  toastId,
}: {
  content: ReactNode;
  toastId?: ToastOptions["toastId"];
}) => {
  if (toastId && toast.isActive(toastId)) {
    toast.update(toastId, { render: content });
    return;
  }

  toast.info(content, {
    toastId,
    position: "bottom-left",
    className: toastStyles,
    bodyClassName: toastBodyStyles,
    progressClassName: toastProgressStyles,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

/**
 * We use `!important` to override the default react-toastify styles.
 */
const toastStyles = css({
  zIndex: ZIndex.ToastNotification,
  background: "#fff !important",
  minHeight: "48px !important",
  cursor: "default !important",
  borderRadius: `${mdsBorderRadius().mediumLarge}px !important`,
  color: `${mdsColors().grey.x50} !important`,
  fontSize: `${mdsFontSizes().small} !important`,
  fontFamily: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  userSelect: "none",

  a: {
    color: "#3b6df1 !important",
    display: "inline-block !important",
    cursor: "pointer !important",
    fontWeight: "bold !important",

    "&:hover": {
      color: "#152759",
    },
  },
});

const toastBodyStyles = css({
  cursor: "default !important",
});

const toastProgressStyles = css({
  opacity: "0 !important",
});
