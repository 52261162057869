import { raiseCriticalError } from "@/domains/critical-errors/critical-errors";
import table from "@/domains/local-db/account/syncUpdates/adapter";
import { objectModule } from "@/modules/object";
import { Uuid } from "@/domains/global/identifiers";
import { DataFlusher } from "@/domains/data-flushing/DataFlusher";
import { SyncModelData, SyncUpdate } from "@/store/sync/types";
import { logger } from "@/modules/logger";

type FlusherState = Record<Uuid, SyncUpdate<SyncModelData>>;

const flusher = new DataFlusher<FlusherState>({
  onFlush: async data => {
    const ids: Uuid[] = [];
    const values: SyncUpdate<SyncModelData>[] = [];
    Object.entries(data).forEach(([id, action]) => {
      values.push(action);
      ids.push(id);
    });
    await table.setAllByIds(ids, values);
  },
  onError: err => {
    logger.error({
      message: "[CDE][Dexie] Repeatedly failed to flush syncActions",
      info: { error: objectModule.safeErrorAsJson(err as Error) },
    });
    return raiseCriticalError({ message: "Unable to save syncActions to your hard drive." });
  },
  options: {
    urgency: 2,
  },
});

export const save = (id: string, action: SyncUpdate<SyncModelData>) =>
  flusher.mutateData(data => {
    data[id] = action;
  });

export const saveMany = async (actions: SyncUpdate<SyncModelData>[]) => {
  flusher.mutateData(data => {
    actions.forEach(action => {
      data[action.sync_id] = action;
    });
  });
};

// TODO: this should be integrated with flusher to be accessible by non-admins.
export const clear = () => table.clearAll();
