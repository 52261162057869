import { appRoutes } from "@/app/router";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";

export const copyCollectionLinkToClipboard = async ({ collectionId }: { collectionId: string }) => {
  await copyTextToClipboard({
    text: `${window.location.origin}${appRoutes.collectionsView({ params: { collectionId } }).path}`,
  });
  toastModule.triggerToast({ content: "Link copied to clipboard" });
};
