import { css } from "@/domains/emotion";
import { FC, memo } from "react";

interface MdsSpacerProps {
  flexGrow?: number;
}

export const MdsSpacer: FC<MdsSpacerProps> = memo(({ flexGrow = 1 }) => {
  const className = css({
    flex: "1",
    flexGrow,
  });

  return <div className={className} />;
});
