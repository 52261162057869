import { animated, useTransition } from "@react-spring/web";
import React, { ReactNode, FC } from "react";

import { AnimationConfig } from "@/design-system/components/animation/types";
import { _parseAnimationTransitionConfig } from "@/design-system/components/animation/utils/parseAnimationTransitionConfig";
import { SHARED_ANIMATION_DIV_STYLES } from "@/design-system/components/animation/utils/sharedAnimationStyles";
import { cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";

export interface FadeInAnimatorProps extends EmotionClassStyles {
  element: ReactNode;
  transitionDelay?: number;
  animationConfig?: AnimationConfig;
}

export const FadeInAnimator: FC<FadeInAnimatorProps> = ({
  element,
  animationConfig,
  className,
}) => {
  const transitionConfig = _parseAnimationTransitionConfig({
    animationConfig,
  });

  const transitions = useTransition(true, {
    initial: { itemVisibility: 0 },
    from: { itemVisibility: 0 },
    enter: { itemVisibility: 1 },
    leave: { itemVisibility: 0 },
    delay: animationConfig?.delay ?? 0,
    config: transitionConfig,
  });

  const combinedStyles = cx(SHARED_ANIMATION_DIV_STYLES, className);

  return transitions(({ itemVisibility }) => {
    return (
      <animated.div
        style={{
          pointerEvents: itemVisibility.to(val => (val > 0.5 ? "auto" : "none")),
          opacity: itemVisibility.to({ range: [0, 1], output: [0, 1] }),
        }}
        className={combinedStyles}
      >
        {element}
      </animated.div>
    );
  });
};
