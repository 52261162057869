import { DeleteNotePermanentlyModalStore } from "@/components/modal/delete-notes-permanently/DeleteNotePermanentlyModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { LeaveNoteMessageModalStore } from "@/store/pages/NotesViewPageStore/LeaveNoteMessageModalStore";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import { Maybe } from "@/domains/common/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { createProxyWithIsDeleted } from "@/hooks/useObjectAfterDeletion";
import { MountedStore } from "@/store/pages/MountedStore";
import { NoteObservable } from "@/store/note/NoteObservable";

export class NotesViewPageStore extends AppSubStore implements INotesViewPageStore<NoteObservable> {
  deleteNotePermanentlyModal: DeleteNotePermanentlyModalStore;
  shareSheetModal: ShareSheetModalStore;
  leaveNoteMessageModal: LeaveNoteMessageModalStore;
  addToCollectionModal: AddToCollectionModalStore;

  mountState = new MountedStore();

  /** This is a proxy with isDeleted=true after the note is gone. */
  noteObservable: Maybe<NoteObservable>;
  couldAccessNoteOnMount: Maybe<boolean>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.deleteNotePermanentlyModal = new DeleteNotePermanentlyModalStore(injectedDeps, {});
    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });

    makeObservable(this, {
      deleteNotePermanentlyModal: false,
      shareSheetModal: false,
      leaveNoteMessageModal: false,
      addToCollectionModal: false,
      mountState: false,
      useEffects: false,
      goToMention: false,
      amIMounted: computed,
      myAccount: computed,
      noteObservable: observable,
      couldAccessNoteOnMount: observable,
      isUpToDate: computed,
      note: computed,
      noteExists: computed,
      showNotFound: computed,
      setNoteObservable: action,
      setCouldAccessNoteOnMount: action,
    });

    // We need this to be already observable to react to changes its observable props.
    this.leaveNoteMessageModal = new LeaveNoteMessageModalStore(this);

    reaction(
      () => ({ note: this.note, amIMounted: this.amIMounted }),
      ({ note: maybeNote, amIMounted }, { note: previousNote }) => {
        const note = (() => {
          if (maybeNote) return maybeNote;
          if (!previousNote || !amIMounted) return;

          return createProxyWithIsDeleted(previousNote);
        })();
        if (note && this.noteObservable?.id !== note.id) {
          note.addToRecents();
        }
        this.setNoteObservable(note);
      }
    );

    reaction(
      () => this.noteObservable?.canAccess,
      (canAccess, previousCanAccess) => {
        const couldAccessNoteOnMount = (() => {
          // Getting access later is fine.
          if (canAccess) return true;
          // Prefer the previous value if it's already set.
          if (typeof previousCanAccess === "boolean") return previousCanAccess;
          // Either undefined (_unknown_) or false.
          return canAccess;
        })();
        this.setCouldAccessNoteOnMount(couldAccessNoteOnMount);
      }
    );
  }

  goToMention: INotesViewPageStore["goToMention"] = ({ id, mentionKind, url }) => {
    this.store.navigation.goToMention({ id, mentionKind, url });
  };

  setNoteObservable(noteObservable?: NoteObservable) {
    this.noteObservable = noteObservable;
  }

  setCouldAccessNoteOnMount(couldAccessNoteOnMount?: boolean) {
    this.couldAccessNoteOnMount = couldAccessNoteOnMount;
  }

  get amIMounted() {
    return this.mountState.isMounted;
  }

  get isUpToDate() {
    return this.store.sync.isUpToDate;
  }

  get myAccount() {
    return this.store.spaceAccounts.myPersonalSpaceAccount;
  }

  get note() {
    if (!this.mountState.isMounted) return;
    const noteId = this.store.routing.noteIdParam;
    if (!noteId) return;
    return this.store.notes.getNoteObservableById({ noteId });
  }

  get showNotFound() {
    if (!this.mountState.isMounted) return false;

    if (this.couldAccessNoteOnMount === false) return true;

    if (this.leaveNoteMessageModal.isModalOpen) return false;

    return !this.noteExists;
  }

  get noteExists(): boolean {
    // Return true when it's unknown.
    if (!this.mountState.isMounted || !this.store.routing.noteIdParam) {
      return true;
    }

    const doesNotExist = this.store.notes.doesNotExist(this.store.routing.noteIdParam);
    return doesNotExist !== true;
  }

  useEffects() {
    this.mountState.useEffects();
  }
}
