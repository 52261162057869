import { observer } from "mobx-react-lite";
import { FC } from "react";
import { TrialInfoBadgeWrapper } from "@/components/trial/badge/TrialInfoBadgeWrapper";

interface TrialInfoBadgeProps {
  visible: boolean;
}

export const TrialInfoBadge: FC<TrialInfoBadgeProps> = observer(({ visible }) => {
  if (!visible) {
    return null;
  }

  return <TrialInfoBadgeWrapper />;
});
