import Icons from "@/assets/icons/Icons";
import { css, cx } from "@/domains/emotion";
import { memo } from "react";

export const MemLogoWithShadow = memo(() => {
  return (
    <div className={containerStyles}>
      <Icons.Mem className={cx(logoStyles, blurryStyles)} />
      <Icons.Mem className={logoStyles} />
    </div>
  );
});

const containerStyles = css({
  position: "relative",
  height: "62px",
  width: "56px",
});

const blurryStyles = css({
  opacity: "0.5",
  filter: "blur(7.5px)",
  top: 6,
});

const logoStyles = css({
  left: 0,
  position: "absolute",
  height: "56px",
  top: 0,
  width: "56px",
});
