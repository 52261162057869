import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { FC } from "react";

export const SettingsExportsPage: FC = () => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Export mems">
        <div />
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
};
