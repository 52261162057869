import { urlParamsModule } from "@/modules/url-params";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { uuidModule } from "@/modules/uuid";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import {
  SavedSearchDeletedSyncUpdateValue,
  SavedSearchModelData,
} from "@/store/saved-searches/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveSavedSearchFromFavoritesOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export interface RemoveSavedSearchFromFavoritesOperationParams
  extends BaseSyncOperationParams<IRemoveSavedSearchFromFavoritesOperation> {
  savedSearchQueryString: string;
}

export class RemoveSavedSearchFromFavoritesOperation extends BaseSyncOperation<IRemoveSavedSearchFromFavoritesOperation> {
  get operationKind(): SyncOperationKind {
    return "REMOVE_SAVED_SEARCH_FROM_FAVORITES";
  }

  private savedSearchQueryString: string;

  constructor({
    savedSearchQueryString,
    ...params
  }: RemoveSavedSearchFromFavoritesOperationParams) {
    super(params);
    this.savedSearchQueryString = savedSearchQueryString;
  }

  get savedSearchParams(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.savedSearchQueryString,
    });
  }

  get savedSearchLabel() {
    return this.savedSearchParams.queryString;
  }

  get successToastMessage() {
    return <>Removed “{this.savedSearchLabel}” from your pins</>;
  }

  public generateOptimisticUpdates() {
    const savedSearchObservable = this.store.savedSearches.get(this.payload.saved_search_id);
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const favoriteItemId = uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.saved_search_id,
    });
    const favoriteItemObservable = this.store.favoriteItems.get(favoriteItemId);
    if (!favoriteItemObservable || !savedSearchObservable) return [];

    const optimisticUpdates = [];

    const savedSearchDeletedValue: SavedSearchDeletedSyncUpdateValue = {
      model_id: this.payload.saved_search_id,
      model_kind: "SAVED_SEARCH",
      model_version: savedSearchObservable.modelVersion,
    };
    const savedSearchDeletedUpdate: SyncUpdate<SavedSearchModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: savedSearchDeletedValue,
    };
    optimisticUpdates.push(savedSearchDeletedUpdate);

    const favoriteItemDeletedValue: FavoriteItemDeletedSyncUpdateValue = {
      model_id: favoriteItemId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
    };
    const favoriteItemDeletedUpdate: SyncUpdate<FavoriteItemModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: favoriteItemDeletedValue,
    };
    optimisticUpdates.push(favoriteItemDeletedUpdate);

    return optimisticUpdates;
  }

  protected getToastMessage() {
    return `Added “${this.savedSearchLabel}” search results could not be removed from your pins. If this error continues, please contact support.`;
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }
}
