import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon";

interface CollectionChipProps {
  collection?: CollectionObservable;
  inline?: boolean;
}

export const CollectionChip: FC<CollectionChipProps> = ({ collection, inline }) => {
  return (
    <div className={cx(chipStyles, inline && inlineStyles)}>
      {!inline && <CollectionIcon size={24} collectionId={collection?.id} />}
      {inline && (
        <>
          <MdsIcon
            kind={MdsIconKind.Collection}
            innerStyles={{ Icon: { className: inlineChipIconStyles } }}
            className={inlineStyles}
          />{" "}
        </>
      )}
      <MdsText
        className={inline ? inlineStyles : undefined}
        size={MdsTextSize.Large}
        weight={MdsTextWeight.Bold}
      >
        {collection?.title || UNTITLED_COLLECTION_TITLE}
      </MdsText>
    </div>
  );
};

const chipStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
  background: mdsColors().grey.x25,
  padding: "4px 6px",
  borderRadius: 8,
});

const inlineChipIconStyles = css({
  fontSize: 16,
});

const inlineStyles = css({
  display: "inline",
});
