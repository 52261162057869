import { FC } from "react";

import { InterstitialOverlay } from "@/components/overlay";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { AuthFooter } from "@/components/auth";
import { AuthCardGoogleButtonBase } from "@/components/auth/google/AuthCardGoogleButtonBase";
import { MemLogoWithShadow } from "@/components/static/MemLogoWithShadow";
import { GuestSharingAccountInfo } from "@/store/guest-account/types";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsButtonShape, MdsButtonSize } from "@/design-system/components/button/types";
import { mdsLineSpacings } from "@/design-system/foundations/typography";

interface GuestModeGreetingInterstitialOverlayContentProps {
  isOpen: boolean;
  sharingAccount?: GuestSharingAccountInfo;
  sharingNoteTitle: string;
  onSignUp: () => void;
  onClose: () => void;
}

export const GuestModeGreetingInterstitialOverlayContent: FC<
  GuestModeGreetingInterstitialOverlayContentProps
> = ({ isOpen, sharingAccount, sharingNoteTitle, onSignUp, onClose }) => {
  return (
    <InterstitialOverlay isOpen={isOpen} handleClose={onClose}>
      <div className={containerStyles}>
        <MemLogoWithShadow />
        <div className={smallerGapStyles}>
          <div className={headerStyles}>
            {sharingAccount?.profileDisplayName ?? "Someone"} shared “{sharingNoteTitle}” with you
          </div>
          <MdsButton
            shape={MdsButtonShape.Round}
            size={MdsButtonSize.Large}
            variant={MdsButtonVariant.FilledDark}
            label="Continue as guest"
            onClick={onClose}
          />
        </div>
        <div className={dividerStyles}>
          <div className={hrStyles} />
        </div>
        <div className={smallerGapStyles}>
          <div className={infoStyles}>
            Or, sign up to access all of Mem—the AI notes app that keeps you organized—with a 7-day
            free trial
          </div>
          <AuthCardGoogleButtonBase label="Sign up with Google" onClick={onSignUp} />
          <AuthFooter />
        </div>
      </div>
    </InterstitialOverlay>
  );
};

const containerStyles = css({
  alignItems: "center",
  background: "white",
  borderColor: mdsColors().grey.x50,
  borderRadius: "24px",
  borderStyle: "solid",
  borderWidth: "1px",
  boxShadow: `0px 11px 24px -8px rgba(69, 78, 104, 0.20)`,
  color: mdsColors().grey.x600,
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  justifyContent: "flex-start",
  letterSpacing: mdsLineSpacings().large,
  lineHeight: "32px",
  maxWidth: "640px",
  padding: 40,
  width: "100%",

  "@media (min-width: 672px) and (min-height: 632px)": {
    justifyContent: "center",
  },
});

const smallerGapStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const headerStyles = css({
  color: mdsColors().grey.x600,
  display: "flex",
  fontSize: "24px",
  fontWeight: 700,
  justifyContent: "center",
  letterSpacing: mdsLineSpacings().large,
  lineHeight: "32px",
  textAlign: "center",
});

const dividerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: "4px 0px",
  width: "100%",
});

const hrStyles = css({
  background: mdsColors().grey.x100,
  height: "1px",
  width: "100%",
});

const infoStyles = css({
  color: mdsColors().grey.x500,
  fontHeight: 400,
  fontSize: "16px",
  letterSpacing: mdsLineSpacings().medium,
  lineHeight: "24px",
  textAlign: "center",
});
