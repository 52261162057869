import { Navigate, NavigateProps, useParams, To, Params } from "react-router-dom";

type NavigateWithParamsProps = Omit<NavigateProps, "to"> & {
  to: To | ((params: Readonly<Params<string>>) => To);
};

export function NavigateWithParams({ to, ...rest }: NavigateWithParamsProps) {
  const params = useParams();
  const toValue = typeof to === "function" ? to(params) : to;
  return <Navigate to={toValue} {...rest} />;
}
