import { DateTime } from "luxon";
import { TimelineWithInboxStatusGroup, TimelineWithInboxStatusItem } from "./types";

export const groupLens = (items: TimelineWithInboxStatusItem[]): TimelineWithInboxStatusGroup[] => {
  const groupIndicies = new Map<string, number>();
  const groups: TimelineWithInboxStatusGroup[] = [];

  items.forEach(item => {
    const groupTitle = getGroupTitle(item);
    const groupIndex = groupIndicies.get(groupTitle);
    let group = groupIndex !== undefined ? groups[groupIndex] : undefined;
    if (!group) {
      group = {
        title: groupTitle,
        items: [],
      };
      const newGroupIndex = groups.length;
      groups.push(group);
      groupIndicies.set(groupTitle, newGroupIndex);
    }
    group.items.push(item);
  });

  return groups;
};

const getDateTimeMarkers = () => {
  const now = DateTime.now();
  const today = now.startOf("day");
  const yesterday = now.minus({ days: 1 }).startOf("day");
  const thisWeek = now.startOf("week");
  const lastWeek = now.minus({ weeks: 1 }).startOf("week");
  const thisMonth = now.startOf("month");

  return { today, yesterday, thisWeek, lastWeek, thisMonth };
};

const getGroupTitle = (item: TimelineWithInboxStatusItem): string => {
  const { today, yesterday, thisWeek, lastWeek, thisMonth } = getDateTimeMarkers();
  const itemDateTime = DateTime.fromJSDate(item.dateTime);
  const itemDay = itemDateTime.startOf("day");

  if (itemDay >= today) {
    return "Today";
  }
  if (itemDay >= yesterday) {
    return "Yesterday";
  }
  if (itemDay >= thisWeek) {
    return "Earlier this week";
  }
  if (itemDay >= lastWeek) {
    return "Last week";
  }
  if (itemDay >= thisMonth) {
    return "Earlier this month";
  }

  // Luxon uses toFormat() method for formatting dates
  return itemDay.toFormat("MMMM yyyy");
};
