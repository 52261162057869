import { api } from "@/modules/api";
import {
  GLOBAL_PUSHER_APP_CLUSTER,
  GLOBAL_PUSHER_APP_KEY,
  GLOBAL_PUSHER_CLIENT_CONFIG,
} from "@/modules/pusher-client/constants";
import { PusherClient } from "@/modules/pusher-client/types";
import Pusher from "pusher-js";
import { ChannelAuthorizationData } from "pusher-js/types/src/core/auth/options";

export const _initializePusher = async (): Promise<PusherClient> => {
  if (GLOBAL_PUSHER_CLIENT_CONFIG.pusherInstance) return GLOBAL_PUSHER_CLIENT_CONFIG.pusherInstance;

  const pusherInstance = new Pusher(GLOBAL_PUSHER_APP_KEY, {
    cluster: GLOBAL_PUSHER_APP_CLUSTER,
    authorizer: function (channel) {
      return {
        authorize: async function (socketId, callback) {
          const response = await api.post("/v2/pusher/auth", {
            body: { socket_id: socketId, channel_name: channel.name },
          });
          if (response.data) {
            const channelAuthorizationData: ChannelAuthorizationData = {
              auth: response.data.auth,
            };
            callback(null, channelAuthorizationData);
          } else {
            callback(new Error("Failed to authenticate with Pusher"), null);
          }
        },
      };
    },
  });

  GLOBAL_PUSHER_CLIENT_CONFIG.pusherInstance = pusherInstance;

  return pusherInstance;
};
