import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { mdsLineSpacings } from "@/design-system/foundations/typography";
import { css } from "@/domains/emotion";

export const authCardStyles = {
  actionSection: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    margin: "24px 0",
  }),
  altText: css({
    fontSize: mdsFontSizes().medium,
    color: mdsColors().grey.x500,
    letterSpacing: mdsLineSpacings().medium,
    lineHeight: "24px",
    textAlign: "center",
  }),
  link: css({
    color: mdsColors().blue.x500,
    textDecoration: "underline",
  }),
};
