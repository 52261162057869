import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { makeObservable, computed, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreCollectionItemsStore extends BaseSyncModelStore<
  CollectionItemObservable,
  CollectionItemModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.CollectionItem, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      collectionsToCollectionItems: computed,
      itemsToCollectionItems: computed,
      resetSync: override,
    });
  }

  createSyncModel(data: SyncUpdateValue<CollectionItemModelData>): CollectionItemObservable {
    return new CollectionItemObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get collectionsToCollectionItems(): Map<Uuid, CollectionItemObservable[]> {
    const output = new Map<Uuid, CollectionItemObservable[]>();
    for (const collectionItem of this.pool.values()) {
      if (output.has(collectionItem.collectionId)) {
        output.get(collectionItem.collectionId)?.push(collectionItem);
      } else {
        output.set(collectionItem.collectionId, [collectionItem]);
      }
    }
    return output;
  }

  get itemsToCollectionItems(): Map<Uuid, CollectionItemObservable[]> {
    const output = new Map<Uuid, CollectionItemObservable[]>();
    for (const collectionItem of this.pool.values()) {
      if (output.has(collectionItem.itemId)) {
        output.get(collectionItem.itemId)?.push(collectionItem);
      } else {
        output.set(collectionItem.itemId, [collectionItem]);
      }
    }
    return output;
  }

  public resetSync() {
    this.remotePool.clear();
  }
}
