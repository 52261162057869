import { Uuid } from "@/domains/global/identifiers";
import { PusherDomainPrefix, PusherServicePrefix } from "@/domains/pusher/constants";
import { validationModule } from "@/modules/validation";

export function generateSyncActionSpaceScopedPusherChannelKey({ spaceId }: { spaceId: Uuid }) {
  const uppercaseSpaceId = spaceId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.SPACE_SCOPED_SYNC_UPDATE}@${uppercaseSpaceId}`;
}

export function generateSyncActionCollectionScopedPusherChannelKey({
  collectionId,
}: {
  collectionId: Uuid;
}) {
  const uppercaseCollectionId = collectionId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.COLLECTION_SCOPED_SYNC_UPDATE}@${uppercaseCollectionId}`;
}

export function generateSyncActionSpaceAccountScopedPusherChannelKey({
  spaceAccountId,
}: {
  spaceAccountId: Uuid;
}) {
  const uppercaseSpaceAccountId = spaceAccountId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.SPACE_ACCOUNT_SCOPED_SYNC_UPDATE}@${uppercaseSpaceAccountId}`;
}

export function generateSyncActionChatConversationScopedPusherChannelKey({
  chatConversationId,
}: {
  chatConversationId: Uuid;
}) {
  const uppercaseChatConversationId = chatConversationId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.CHAT_CONVERSATION_SCOPED_SYNC_UPDATE}@${uppercaseChatConversationId}`;
}

export function generateChatConversationLiveSyncUpdatePusherChannelKey({
  chatConversationId,
}: {
  chatConversationId: string;
}) {
  const uppercaseChatConversationId = chatConversationId.toUpperCase();
  return `${PusherServicePrefix.AUTHENTICATED_BACKEND_API}@${PusherDomainPrefix.CHAT_CONVERSATION_LIVE_SYNC_UPDATE}@${uppercaseChatConversationId}`;
}

export function standardizeSyncId(syncId: string | undefined | null) {
  if (!syncId) return undefined;
  const [prefix, uuid] = syncId.split("::");
  if (prefix !== "SYNC") throw new Error("Invalid sync id");
  if (!validationModule.isUuid(uuid)) throw new Error("Invalid sync id");
  return `${prefix}::${uuid.toUpperCase()}`;
}
