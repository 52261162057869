import { RuntimeParseError } from "@/domains/errors";
import { validationModule } from "@/modules/validation";

export const _extractValueFromObject = (value: unknown, prop: string): unknown => {
  if (!validationModule.objectHasKey(value, prop)) {
    throw new RuntimeParseError({
      message: "[_extractValueFromObject] value did not have the required property.",
      info: {
        value,
        prop,
      },
    });
  }

  return value[prop];
};
