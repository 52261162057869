import { ProfileSize } from "@/components/layout/components/account-profile";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileVariant } from "@/components/layout/components/account-profile/types";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { mdsColors } from "@/design-system/foundations";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";

interface AccountInfoProps {
  profileSize?: ProfileSize;
  withDropdownIcon?: boolean;
  profilePhotoUrl: string | null;
  profileDisplayName: string;
  profileEmailAddress?: string;
}

export const SidebarAccountInfo: FC<AccountInfoProps> = observer(
  ({
    profilePhotoUrl,
    profileDisplayName,
    profileEmailAddress,
    withDropdownIcon = false,
    profileSize = ProfileSize.Standard,
  }) => {
    return (
      <Wrapper>
        <AccountProfileImageContent
          size={profileSize}
          variant={ProfileVariant.Square}
          photoUrl={profilePhotoUrl}
          displayName={profileDisplayName}
        />
        <Column>
          <AccountInfoRow>
            <MdsText
              size={MdsTextSize.Small}
              weight={MdsTextWeight.SemiBold}
              color={mdsColors().grey.x600}
              lineHeight={MdsTextSize.XSmall}
            >
              {profileDisplayName}
            </MdsText>
            {withDropdownIcon && <MdsIcon kind={MdsIconKind.AngleDown} />}
          </AccountInfoRow>
          {profileEmailAddress && (
            <AccountInfoRow>
              <MdsText
                size={MdsTextSize.XXSmall}
                color={mdsColors().grey.x500}
                weight={MdsTextWeight.Medium}
                lineHeight={MdsTextSize.XXSmall}
              >
                {profileEmailAddress}
              </MdsText>
            </AccountInfoRow>
          )}
        </Column>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const AccountInfoRow = styled.div({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  marginLeft: 8,
  gap: "2px",
});
