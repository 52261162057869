import { CustomError } from "@/domains/errors/kinds/custom/CustomError";
import { AppErrorCode } from "@/domains/errors/constants";
import { ErrorOptions } from "@/domains/errors/types";
import { JsonObject } from "@/domains/common/types";

export class BaseError extends CustomError {
  public statusCode: number;
  public code: AppErrorCode;
  public info?: JsonObject;
  public originalError?: Error;

  public constructor({
    code = AppErrorCode.UNKNOWN,
    statusCode = 500,
    info,
    message,
    originalError,
  }: ErrorOptions) {
    super(message);

    this.code = code;
    this.info = info;
    this.statusCode = statusCode;
    this.originalError = originalError;
  }
}
