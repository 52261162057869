import { observer } from "mobx-react-lite";
import { css } from "@/domains/emotion";
import React from "react";

export interface StandardModePaddingProps {
  children: React.ReactNode;
}

export const StandardModePadding = observer<StandardModePaddingProps>(({ children }) => {
  return <div className={paddingStyles}>{children}</div>;
});

const paddingStyles = css({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
  margin: "0 auto",
  width: "100%",
});
