import { AppSubStoreArgs } from "@/store/types";
import { SavedSearchObservable } from "@/store/saved-searches/SavedSearchObservable";
import { SavedSearchModelData } from "@/store/saved-searches/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreSavedSearchesStore extends BaseSyncModelStore<
  SavedSearchObservable,
  SavedSearchModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SavedSearch, ...injectedDeps });
  }

  createSyncModel(data: SyncUpdateValue<SavedSearchModelData>): SavedSearchObservable {
    return new SavedSearchObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  getSavedSearchObservableById({
    savedSearchId,
  }: {
    savedSearchId: string;
  }): SavedSearchObservable | undefined {
    return this.pool.get(savedSearchId);
  }
}
