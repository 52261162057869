/**
 * Module implementation heavily inspired by:
 *   https://github.com/juliangruber/is-mobile
 */

import { DevicePlatform } from "@/modules/device/types";

const MOBILE_REGEX =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
const TABLET_REGEX = /android|ipad|playbook|silk/i;
const EXCLUDED_REGEX = /CrOS/;

export const _determineDevicePlatform = (): DevicePlatform => {
  const userAgent = window.navigator.userAgent;

  if (!userAgent) {
    return DevicePlatform.Unknown;
  }

  if (EXCLUDED_REGEX.test(userAgent)) {
    return DevicePlatform.Mobile;
  }

  if (MOBILE_REGEX.test(userAgent)) {
    return DevicePlatform.Mobile;
  }

  if (TABLET_REGEX.test(userAgent)) {
    return DevicePlatform.Mobile;
  }

  /**
   * Extra check for some devices.
   */
  if (
    navigator.maxTouchPoints > 1 &&
    userAgent.includes("Macintosh") &&
    userAgent.includes("Safari")
  ) {
    return DevicePlatform.Mobile;
  }

  /** Default to web. */
  return DevicePlatform.Web;
};
