import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { NoteObservable } from "@/store/note/NoteObservable";

export const removeNoteFromCollection = async ({
  note,
  collection,
  store,
}: {
  note: NoteObservable;
  collection: CollectionObservable;
  store: AppStore;
}) => {
  note.collectionList.removeCollection({ collectionId: collection.id });
  toastModule.triggerToast({
    content: (
      <div>
        {"Note removed from "}
        <a onClick={() => store.navigation.goToCollection({ collectionId: collection.id })}>
          {collection.label}
        </a>
        {"."}
      </div>
    ),
  });
};
