import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { logger } from "@/modules/logger";
import { GuestNoteObservable } from "@/store/guest-note/GuestNoteObservable";
import { NoteModelData } from "@/store/note/types";
import { SyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { GuestAppSubStore, GuestAppSubStoreArgs } from "@/store/types";
import { makeObservable, observable, action } from "mobx";

export class AppStoreGuestNoteStore extends GuestAppSubStore {
  note?: GuestNoteObservable;

  constructor(injectedDeps: GuestAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      createSyncModel: false,
      getNoteObservableById: false,
      processSyncUpdate: action,
      grantAccessToNote: action,
      reset: action,
      note: observable,
    });
  }

  createSyncModel(updateValue: SyncUpdateValue<NoteModelData>): GuestNoteObservable {
    return new GuestNoteObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  getNoteObservableById = ({ noteId }: { noteId?: Uuid }): Maybe<GuestNoteObservable> => {
    return this.note?.id === noteId ? this.note : undefined;
  };

  public processSyncUpdate(update: SyncUpdate<NoteModelData>) {
    if (this.note?.id && this.note.id !== update.value.model_id) {
      logger.error({
        message: "[AppStoreGuestNoteStore] processSyncUpdate: note IDs do not match",
        info: {
          existingNoteId: this.note.id,
          newNoteId: update.value.model_id,
        },
      });
      return;
    }
    switch (update.kind) {
      case "ACL_UPSERTED":
      case "UPSERTED": {
        const updateValue = update.value as SyncUpdateValue<NoteModelData>;
        if (this.note?.id === updateValue.model_id) {
          this.note.updateFromRemote({ data: updateValue });
        } else if (!this.note) {
          this.note = this.createSyncModel(updateValue);
        }
        break;
      }
      case "DELETED": {
        this.note?.deleteFromRemote();
        break;
      }
      case "ACL_REVOKED": {
        this.note?.setCanAccess(true);
        break;
      }
    }
  }

  public grantAccessToNote() {
    console.log("[AppStoreNoteStore] grantAccessToNote - Not Implemented Yet");
  }

  reset = () => {
    this.note = undefined;
  };
}
