import { Uuid } from "@/domains/global/identifiers";
import { SpaceAccountContactModelData } from "@/store/contacts/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";

export class SpaceAccountContactObservable extends BaseSyncModel<SpaceAccountContactModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountContactModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      lastSharedWithAt: computed,
    });
  }

  get lastSharedWithAt(): string | null {
    return this.data.model_data.last_shared_with_at;
  }
}
