import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";
import { IProvideChatMessageFeedbackOperation } from "@/store/sync/operations/types";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import {
  SpaceAccountChatMessageModelData,
  SpaceAccountChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import { generateDefaultOwnerScopes } from "../helpers/common";
import { uuidModule } from "@/modules/uuid";

export class ProvideChatMessageFeedbackOperation extends BaseSyncOperation<IProvideChatMessageFeedbackOperation> {
  get operationKind(): SyncOperationKind {
    return "PROVIDE_CHAT_MESSAGE_FEEDBACK";
  }
  generateOptimisticUpdates(): SyncUpdate<SpaceAccountChatMessageModelData>[] {
    const spaceAccountChatMessageUuid = resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.payload.chat_message_id,
    });
    const spaceAccountChatMessage = this.store.spaceAccountChatMessages.get(
      spaceAccountChatMessageUuid
    );
    const chatMessage = this.store.chatMessages.get(this.payload.chat_message_id);
    if (!chatMessage) return [];

    // Upsert SpaceAccountChatMessage
    const spaceAccountChatMessageValue: SpaceAccountChatMessageUpsertedSyncUpdateValue = {
      model_id: spaceAccountChatMessageUuid,
      model_kind: "SPACE_ACCOUNT_CHAT_MESSAGE",
      model_version: spaceAccountChatMessage?.modelVersion ?? 0,
      model_data: {
        space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        saved_as_note_at: spaceAccountChatMessage?.modelData.saved_as_note_at ?? null,
        saved_as_note_id: spaceAccountChatMessage?.modelData.saved_as_note_id ?? null,
        sections: spaceAccountChatMessage?.modelData.sections ?? [],

        chat_message_id: this.payload.chat_message_id,
        feedback_responded_at: new Date().toISOString(),
        feedback_response: this.payload.feedback_response_kind || null,
        feedback_user_text: this.payload.feedback_user_text ?? null,
        feedback_selected_suggestions: this.payload.feedback_selected_suggestions ?? [],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const spaceAccountChatMessageUpsertedSyncUpdate: SyncUpdate<SpaceAccountChatMessageModelData> =
      {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: spaceAccountChatMessageValue,
      };

    return [spaceAccountChatMessageUpsertedSyncUpdate];
  }
  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send feedback. Please try again.");
  }
  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to provide feedback on that message.");
  }
  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to send feedback. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }
  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send feedback. Please try again.");
  }
}
