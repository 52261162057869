import { observer } from "mobx-react-lite";
import { APP_ROUTER } from "@/app/router/routes";
import { FC } from "react";

import { RouterProvider } from "react-router-dom";

interface AppRouterProps {}

export const AppRouter: FC<AppRouterProps> = observer(() => {
  return <RouterProvider router={APP_ROUTER} />;
});
