import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";

export interface MessageModal {
  title: string;
  message: string;
  buttonLabel: string;
  handleClose(): void;
  handleButtonClick(): void;
}

export enum ModalDefinitionKind {
  Message = "Message",
  SyncError = "SyncError",
  ShareSheet = "ShareSheet",
}

export type MessageModalDefinition = {
  kind: ModalDefinitionKind.Message;
  modal: MessageModal;
};

export type SyncErrorModalDefinition = {
  kind: ModalDefinitionKind.SyncError;
  syncError: SyncErrorModalFields;
};

export type ShareSheetModalDefinition = {
  kind: ModalDefinitionKind.ShareSheet;
  store: ShareSheetModalStore;
};

export type ModalDefinition =
  | MessageModalDefinition
  | SyncErrorModalDefinition
  | ShareSheetModalDefinition;
