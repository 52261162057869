import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { css, cx } from "@/domains/emotion";
import { ceil, clamp } from "lodash-es";
import { useMemo, FC } from "react";

export interface MdsProgressBarProps {
  progressPercentage: number;
}

const progressBarWrapper = css({
  backgroundColor: mdsColors().grey.x200,
  height: 4,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: mdsBorderRadius().maximum,
  overflow: "hidden",
});

const progressBarStyles = css({
  height: "100%",
  backgroundColor: mdsColors().primary.x500,
});

export const MdsProgressBar: FC<MdsProgressBarProps> = ({ progressPercentage }) => {
  const progressPercentageNumber = useMemo(() => {
    const clampedNumber = clamp(progressPercentage, 0, 1);

    return ceil(clampedNumber * 100);
  }, [progressPercentage]);

  const progressPercentageStyles = css({
    width: `${progressPercentageNumber}%`,
  });

  const combinedProgressBarStyles = cx(progressBarStyles, progressPercentageStyles);

  return (
    <div className={progressBarWrapper}>
      <div className={combinedProgressBarStyles} />
    </div>
  );
};
