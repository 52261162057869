import { AppStoreAuthStore } from "@/store/auth/AppStoreAuthStore";
import { PublicAppStoreConstructorArgs, PublicAppSubStoreArgs } from "@/store/types";
import { makeAutoObservable, runInAction } from "mobx";
import { AppStoreInterfaceStore } from "@/store/interface/AppStoreInterfaceStore";
import { asyncResultModule } from "@/modules/async-result";
import { AsyncResult } from "@/modules/async-result/types";
import { BaseError } from "@/domains/errors";
import { AppStoreDebugStore } from "@/store/debug/AppStoreDebugStore";

/**
 * A version of our AppStore which is accessible before the user is logged in.
 */
export class PublicAppStore {
  auth: AppStoreAuthStore;
  interface: AppStoreInterfaceStore;
  debug: AppStoreDebugStore;

  initializationState: AsyncResult<boolean> = asyncResultModule.setLoading();

  constructor({ api, pusher }: PublicAppStoreConstructorArgs) {
    const injectedDeps: PublicAppSubStoreArgs = { store: this, api, pusher };

    this.auth = new AppStoreAuthStore(injectedDeps);
    this.interface = new AppStoreInterfaceStore(injectedDeps);
    this.debug = new AppStoreDebugStore(injectedDeps);

    makeAutoObservable(this);
  }

  async initialize() {
    try {
      runInAction(() => {
        this.initializationState = asyncResultModule.setLoading();
      });

      await this.interface.initialize();
      await this.debug.initialize();
      await this.auth.initialize();

      runInAction(() => {
        this.initializationState = asyncResultModule.setReady(true);
      });
    } catch (unknownErr) {
      const err = unknownErr as BaseError;

      runInAction(() => {
        this.initializationState = asyncResultModule.setError(err);
      });

      throw err;
    }
  }

  useInitializePublicStoreEffects({ searchParams }: { searchParams: URLSearchParams }) {
    this.interface.useScreenSizeUpdater();
    this.auth.processSearchParams(searchParams);
  }
}
