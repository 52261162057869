import { FC, PropsWithChildren, ReactNode, useLayoutEffect, useMemo } from "react";
import styled from "@emotion/styled";
import {
  getPanelGroupElement,
  PanelGroup,
  PanelGroupProps,
  PanelResizeHandle,
} from "react-resizable-panels";
import useMeasure from "react-use/lib/useMeasure";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import useThrottledCallback from "@/domains/react/useThrottledCallback";
import { MdsPanel } from "@/design-system/components/panel/MdsPanel";
import { mdsPanelWidths } from "@/design-system/foundations";

interface SplitViewProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  hasBorder?: boolean;
  animate?: boolean;
  transition?: boolean;
  dataTestId?: string;
  sidePanel?: ReactNode;
}

const SPLIT_VIEW_PANEL_GROUP_ID = "split-view-panel-group";
const PANEL_COUNT = 2;

export const SplitView: FC<SplitViewProps> = observer(
  ({ children, hasBorder = false, dataTestId = "", sidePanel }) => {
    const { store } = useAppStore();
    const { isSidePanelOpen, isMainPanelOpen, isSplitView, canRenderSplitView } = store.sidePanel;
    const showResizeHandle = isSplitView && canRenderSplitView;

    const [setContainerRef, { width }] = useMeasure<HTMLElement>();
    useLayoutEffect(() => {
      const el = getPanelGroupElement(SPLIT_VIEW_PANEL_GROUP_ID);
      if (el) setContainerRef(el);
    });

    const minPanelWidth = useMemo(
      () => (mdsPanelWidths().min / (width - mdsPanelWidths().gutter * PANEL_COUNT)) * 100,
      [width]
    );

    const focusMainPanel = useThrottledCallback(() => {
      store.sidePanel.setSidePanelFocused(false);
    }, 500);

    const focusSidePanel = useThrottledCallback(() => {
      store.sidePanel.setSidePanelFocused(true);
    }, 500);

    return (
      <Container id={SPLIT_VIEW_PANEL_GROUP_ID} direction="horizontal" hasSidePanel={isSplitView}>
        {isMainPanelOpen && (
          <MainPanel
            id="main"
            order={1}
            data-test-id={dataTestId}
            defaultSize={60}
            hasBorder={hasBorder}
            onFocus={focusMainPanel}
            onClick={focusMainPanel}
            minSize={minPanelWidth}
          >
            <CenterPanel>{children}</CenterPanel>
          </MainPanel>
        )}
        {showResizeHandle && (
          <StyledPanelResizeHandle>
            <ResizeHandle />
          </StyledPanelResizeHandle>
        )}
        {isSidePanelOpen && (
          <MdsPanel
            id="right"
            order={2}
            defaultSize={40}
            onFocus={focusSidePanel}
            onClick={focusSidePanel}
            minSize={minPanelWidth}
          >
            {sidePanel}
          </MdsPanel>
        )}
      </Container>
    );
  }
);

interface ContainerProps extends PanelGroupProps {
  hasSidePanel: boolean;
}

const Container = styled(PanelGroup, {
  shouldForwardProp: prop => prop !== "hasSidePanel",
})<ContainerProps>(({ hasSidePanel }) =>
  hasSidePanel
    ? {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }
    : {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: "100%",
        width: "100%",
      }
);

interface MainPanelProps {
  hasBorder: boolean;
}

const MainPanel = styled(MdsPanel, {
  shouldForwardProp: prop => prop !== "hasBorder",
})<MainPanelProps>(({ hasBorder }) => ({
  alignSelf: "center",
  boxSizing: "border-box",
  display: "flex",
  height: "100%",
  overflow: "hidden",

  ...(hasBorder
    ? {
        "@media (max-width: 899px)": {
          paddingRight: "8px",
          width: "100%",
        },

        "@media (min-width: 900px)": {
          border: "1px solid #f8f8fa",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          boxShadow: "0px 2px 4px rgba(69, 79, 104, 0.1)",
        },
      }
    : {
        "@media (max-width: 900px)": {
          width: "calc(100% - 16px)",
        },
      }),
}));

const CenterPanel = styled.div({
  boxSizing: "border-box",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",
  position: "relative",
});

const StyledPanelResizeHandle = styled(PanelResizeHandle)({
  borderRadius: "100px",
  cursor: "ew-resize",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",

  [`&:hover, &[data-resize-handle-state="hover"], &[data-resize-handle-state="drag"]`]: {
    background: "#f6f7f9",
    transition: `0.2s ease-in`,

    "> div": {
      borderColor: "gray",
    },
  },
});

const ResizeHandle = styled.div({
  alignItems: "center",
  background: "transparent",
  borderColor: "gray",
  borderLeft: "2px solid transparent",
  borderRight: "2px solid transparent",
  boxSizing: "border-box",
  display: "flex",
  height: "8px",
  marginLeft: "1px",
  marginRight: "1px",
  overflow: "hidden",
  textAlign: "center",
  width: "6px",
});
