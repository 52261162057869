import { moduleFactory } from "@/factories/module";
import { _colorToHex } from "@/modules/color/colorToHex";
import { _hexToColor, _safeHexToColor } from "@/modules/color/hexToColor";
import { hsluvToColor } from "@/modules/color/hsluvToColor";
import { _hashStringToColor } from "@/modules/color/stringToColor";

export const colorModule = moduleFactory.create({
  hashStringToColor: _hashStringToColor,
  colorToHex: _colorToHex,
  hexToColor: _hexToColor,
  safeHexToColor: _safeHexToColor,
  hsluvToColor: hsluvToColor,
});
