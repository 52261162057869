import { Uuid } from "@/domains/global/identifiers";
import { ApiClient } from "@/modules/api";
import { logger } from "@/modules/logger";
import {
  ApiQueryFetchDataHandler,
  ApiQueryNextPageAvailabilityChecker,
} from "@/store/queries/types";
import { PaginatableApiQueryObservable } from "@/store/queries/common/PaginatableApiQueryObservable";
import {
  SearchEngineParams,
  SearchEngineRequest,
  SearchEngineResponse,
  SearchEngineResult,
} from "@/modules/url-params/search-engine-params/types";

const DEFAULT_SEARCH_ENGINE_PAGE_SIZE = 40;

export const createSearchEngineApiQueryObservable = ({
  getSpaceId,
  searchParams: { queryString, sortBy, filters, facetFilters },
  apiClient,
}: {
  getSpaceId: () => Uuid;
  searchParams: SearchEngineParams;
  apiClient: ApiClient;
}): PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult> => {
  const isEmptyQuery = !queryString && !filters.length && !facetFilters.length;

  const handleFetchPageData: ApiQueryFetchDataHandler<SearchEngineResponse> = async ({
    latestRequest,
  }) => {
    if (isEmptyQuery) {
      logger.debug({
        message: "[generateSearchEngineQueryObservable] Skipping - Empty Query.",
      });

      return;
    }

    const searchSessionCursor = latestRequest?.pagination_info?.search_session_cursor ?? undefined;

    if (latestRequest && !searchSessionCursor) {
      logger.debug({
        message: "[generateSearchEngineQueryObservable] Skipping - No More Pages.",
      });

      return;
    }

    const body: SearchEngineRequest = {
      space_id: getSpaceId(),
      pagination_info: {
        page_size: DEFAULT_SEARCH_ENGINE_PAGE_SIZE,
        sort_by: sortBy,
      },
      query: queryString,
      filters: filters,
      facet_filters: facetFilters,
      config: {
        supported_section_kinds: ["HEADING", "ITEM", "CAROUSEL"],
      },
    };

    const response = await apiClient.post(`/v2/search`, {
      params: {
        query: {
          space_id: body.space_id,
          search_session_cursor: searchSessionCursor,
        },
      },
      body,
    });

    if (response.error) {
      throw new Error(`[handleFetchData] Failed. response=${response.error}`);
    }

    return response.data;
  };

  const checkNextPageAvailability: ApiQueryNextPageAvailabilityChecker<SearchEngineResponse> = ({
    latestRequest,
  }) => {
    if (!latestRequest) {
      return true;
    }

    return Boolean(latestRequest.pagination_info.search_session_cursor);
  };

  return new PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult>({
    handleFetchPageData,
    checkNextPageAvailability,
    mapPageDataToItems: async ({ data }) => data.results,
    config: {
      retries: 1,
    },
  });
};
