import { FC } from "react";
import { InterstitialOverlay } from "@/components/overlay";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { SubscriptionPlanSelectorButton } from "@/components/subscriptions/plan-selector/SubscriptionPlanSelectorButton";
import {
  StripePaymentForm,
  StripePaymentFormProps,
} from "@/components/subscriptions/form/StripePaymentForm";
import { observer } from "mobx-react-lite";
import { StripeSubscriptionPlan } from "@/components/subscriptions/types";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsSpacer } from "@/design-system/components/spacer";

export interface StripeSubscriptionInterstitialOverlayContentProps extends StripePaymentFormProps {
  isOpen: boolean;
  openedManually: boolean;
  activePlan: StripeSubscriptionPlan;
  plans: StripeSubscriptionPlan[];
  setActivePlan: (plan: StripeSubscriptionPlan) => void;
  getHelpHandler: () => void;
  onCloseInterstitialHandler: () => void;
}

export const StripeSubscriptionInterstitialOverlayContent: FC<StripeSubscriptionInterstitialOverlayContentProps> =
  observer(
    ({
      isOpen,
      openedManually,
      plans,
      activePlan,
      setActivePlan,
      stripeFormInfoState,
      stripeFormPaymentSuccessHandler,
      getHelpHandler,
      onCloseInterstitialHandler,
    }) => {
      return (
        <InterstitialOverlay isOpen={isOpen} handleClose={onCloseInterstitialHandler}>
          <div className={container}>
            <div className={headerStyles}>
              {openedManually ? "Subscribe to Mem" : "Subscribe to create new notes in Mem"}
            </div>
            <div className={subHeaderStyles}>
              {openedManually
                ? "Select a plan now and ensure an uninterrupted experience with Mem, the AI notes app that keeps you organized."
                : "Your 7-day trial has ended. Subscribe now to continue creating new notes. You’ll still be able to access all of your existing notes."}
            </div>
            <div className={sectionStyles}>
              <span className={sectionHeaderStyles}>Selected plan</span>
              {plans.map(plan => {
                return (
                  <SubscriptionPlanSelectorButton
                    key={plan.billingStrategy}
                    plan={plan}
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                  />
                );
              })}
            </div>
            <div className={sectionStyles}>
              <span className={sectionHeaderStyles}>Payment details</span>
              <StripePaymentForm
                activePlan={activePlan}
                stripeFormInfoState={stripeFormInfoState}
                stripeFormPaymentSuccessHandler={stripeFormPaymentSuccessHandler}
              />
            </div>
            <div className={footerStyles}>
              <MdsSpacer />
              <MdsButton
                label="Get help with Mem"
                onClick={getHelpHandler}
                iconKind={MdsIconKind.Help}
                variant={MdsButtonVariant.TextTertiary}
              />
              <MdsSpacer />
            </div>
          </div>
        </InterstitialOverlay>
      );
    }
  );

const container = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  maxWidth: "580px",
  width: "100%",
  color: mdsColors().grey.x600,
  borderRadius: 24,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: mdsColors().grey.x50,
  background: "white",
  boxShadow: "0px 11px 24px -8px rgba(69, 78, 104, 0.20)",
  padding: 40,
  "@media (min-width: 672px) and (min-height: 632px)": {
    justifyContent: "center",
  },
});

const headerStyles = css({
  display: "flex",
  justifyContent: "center",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 700,
  color: mdsColors().grey.x600,
  textAlign: "center",
});

const subHeaderStyles = css({
  display: "flex",
  justifyContent: "center",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: mdsColors().grey.x600,
  margin: "8px 0px",
  textAlign: "center",
});

const sectionStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
});

const footerStyles = css({
  marginTop: 20,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: "8px",
  width: "100%",
});

const sectionHeaderStyles = css({
  fontSize: "14px",
  lineHeight: "16px",
  alignSelf: "flex-start",
  width: "100%",
  fontWeight: 600,
  color: mdsColors().grey.x600,
  margin: "24px 0 0",
});
