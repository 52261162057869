import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { generateShortDateString } from "@/domains/date/date";
import { DateTime } from "luxon";

export interface DeleteSharedNotesModalProps {
  store: DeleteSharedNotesModalStore;
}

export const DeleteSharedNotesModal = observer<DeleteSharedNotesModalProps>(({ store }) => {
  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={store.isOpen && !!store.notes.length}
      handleCloseModal={() => store.close()}
    >
      <div className={modalContainerStyles}>
        <div className={headerStyles}>
          <div className={titleStyles}>
            <MdsIcon kind={MdsIconKind.Trash} />
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Delete {store.notes.length || ""} shared note{store.notes.length > 1 ? "s" : ""}?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
        </div>
        {store.notes.length === 1 && (
          <div className={metadataStyles}>
            <MdsText>{store.notes[0].title}</MdsText>
            <MdsText className={dateStyles}>
              {generateShortDateString(DateTime.fromISO(store.notes[0].modifiedAt))}
            </MdsText>
          </div>
        )}
        <div className={contentStyles}>
          {store.notes.length === 1 &&
            "If you delete this note, collaborators will still be able to access it for up to 30 days or until you clear the Trash. Once permanently deleted, nobody will be able to access it."}
          {store.notes.length > 1 &&
            "Once notes are moved to trash, collaborators on shared notes will still be able to access it for 30 days or until you clear the trash. Once permanently deleted, nobody will be able to access them."}
        </div>
        <div className={buttonSectionStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.Tertiary}
            onClick={() => store.close()}
          />
          <MdsButton
            label="Delete"
            variant={MdsButtonVariant.Brand}
            onClick={() => store.handleSubmit()}
          />
        </div>
      </div>
    </MdsModal>
  );
});

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "30px",
  maxWidth: 500,
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});

const metadataStyles = css({
  alignItems: "center",
  background: "#f3f3f5",
  borderLeft: "4px solid transparent",
  borderRadius: "4px",
  boxShadow: "#0003 1px 1px 3px",
  color: "#323640",
  display: "flex",
  fontSize: "14px",
  gap: "12px",
  height: "36px",
  lineHeight: "20px",
  margin: 0,
  padding: "8px 10px",
  userSelect: "none",
  width: "100%",
});

const dateStyles = css({
  color: mdsColors().grey.x400,
});
