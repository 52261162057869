import React from "react";
import { observer } from "mobx-react-lite";
import {
  GridOption,
  GridSelectionForm,
} from "@/components/onboarding/components/GridSelectionForm";
import { useAppStore } from "@/store";

export const SelectJobStage = observer(() => {
  const { store } = useAppStore();

  const handleSubmit = async ({ selectedOptions }: { selectedOptions: GridOption[] }) => {
    const jobRole = selectedOptions[0]?.id;
    if (!jobRole) return;
    store.platformInfo.onboardingInfo.setJobRole({ jobRole });
    await store.platformInfo.onboardingInfo.saveAndContinue();
  };

  return (
    <GridSelectionForm
      shuffle={true}
      prompt="What best describes your work?"
      sub="Select the role that best describes you:"
      multiselect={false}
      options={roles}
      onSubmit={handleSubmit}
    />
  );
});

const roles: GridOption[] = [
  {
    id: "ceo_entrepreneur",
    label: "CEO / Entrepreneur",
  },
  {
    id: "executive_manager",
    label: "Executive / Manager",
  },
  {
    id: "consultant",
    label: "Consultant",
  },
  {
    id: "product_manager",
    label: "Product Manager",
  },
  {
    id: "content_creator_writer",
    label: "Content Creator / Writer",
  },
  {
    id: "researcher",
    label: "Researcher",
  },
  {
    id: "investor",
    label: "Investor",
  },
  {
    id: "retired_unemployed",
    label: "Retired / Unemployed",
  },
  {
    id: "sales_representative",
    label: "Sales Representative",
  },
];
