import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { css } from "@/domains/emotion";
import { MdsVerticalDivider } from "@/design-system/components/divider";
import { MdsTabBar } from "@/design-system/components/tab-bar";
import { mdsColors } from "@/design-system/foundations";
import { useAppStore } from "@/store";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsIconKind } from "@/design-system/components/icon";
import styled from "@emotion/styled";
import { MdsPanelContainer } from "@/design-system/components/panel";

interface SettingsGeneralPageProps {
  children: React.ReactNode;
}

export const SettingsLayout: FC<SettingsGeneralPageProps> = observer(({ children }) => {
  const { pageStore } = useAppStore();
  const page = pageStore.settingsPage;
  page.useSettingsPageParams();

  return (
    <>
      <MdsPanelHeader heading="Settings" icon={MdsIconKind.Settings} />
      <Wrapper>
        <Container>
          <TabBarContainer>
            <MdsTabBar
              sections={page.tabBarSections}
              selectedItemId={page.selectedTabId}
              className={tabBarStyleOverrides}
            />
          </TabBarContainer>
          <MdsVerticalDivider className={dividerStyles} />
          <ContentContainer>{children}</ContentContainer>
        </Container>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div({
  height: "100%",
  maxHeight: "100%",
  overflowY: "scroll",
  paddingBottom: "64px",
});

const Container = styled(MdsPanelContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingBlock: theme.spacing.lg,
  "@container mds-panel (max-width: 750px)": {
    flexDirection: "column",
    height: "auto",
  },
}));

const ContentContainer = styled.div(({ theme }) => ({
  height: "100%",
  width: "100%",
  scrollbarWidth: "none",
  "@container mds-panel (max-width: 750px)": {
    paddingInline: theme.spacing.sm,
  },
}));

const dividerStyles = css({
  marginLeft: 16,
  marginRight: 24,
  "@container mds-panel (max-width: 750px)": {
    display: "none",
  },
});

const TabBarContainer = styled.div({
  width: "230px",
  "@container mds-panel (max-width: 750px)": {
    width: "100%",
    marginBottom: 32,
    borderBottom: `1px solid ${mdsColors().grey.x100} !important`,
  },
});

const tabBarStyleOverrides = css({
  width: "230px !important",
  "@container mds-panel (max-width: 750px)": {
    height: "auto",
  },
});
