/**
 * @description Truncate a string to a certain length
 */
export const truncate = (str: string, length: number): string => {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }

  return str;
};
