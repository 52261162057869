import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import { CollectionDeletedSyncUpdateValue, CollectionModelData } from "@/store/collections/types";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import {
  SpaceAccountCollectionDeletedSyncUpdateValue,
  SpaceAccountCollectionModelData,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IDeleteCollectionOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class DeleteCollectionOperation extends BaseSyncOperation<IDeleteCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "DELETE_COLLECTION";
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const existingCollection = this.store.collections.get(this.payload.id);
    if (!existingCollection) return [];

    const optimisticUpdates = [];

    for (const collectionItem of existingCollection.itemList.allCollectionItems) {
      const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
        model_id: collectionItem.id,
        model_kind: "COLLECTION_ITEM",
        model_version: collectionItem.modelVersion,
      };
      const collectionItemUpdate: SyncUpdate<CollectionItemModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: collectionItemValue,
      };
      optimisticUpdates.push(collectionItemUpdate);
    }

    // Cascade FavoriteItem
    const favoriteItem = existingCollection.favoriteItem;
    if (favoriteItem) {
      const favoriteItemValue: FavoriteItemDeletedSyncUpdateValue = {
        model_id: favoriteItem.id,
        model_kind: "FAVORITE_ITEM",
        model_version: favoriteItem.modelVersion,
      };
      const favoriteItemUpdate: SyncUpdate<FavoriteItemModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: favoriteItemValue,
      };
      optimisticUpdates.push(favoriteItemUpdate);
    }

    // Cascade SpaceAccountCollection
    const spaceAccountCollection = existingCollection.spaceAccountCollection;
    if (spaceAccountCollection) {
      const spaceAccountCollectionValue: SpaceAccountCollectionDeletedSyncUpdateValue = {
        model_id: spaceAccountCollection.id,
        model_kind: "SPACE_ACCOUNT_COLLECTION",
        model_version: spaceAccountCollection.modelVersion,
      };
      const spaceAccountCollectionUpdate: SyncUpdate<SpaceAccountCollectionModelData> = {
        sync_id: uuidModule.generate(),
        committed_at: this.committedAt,
        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: spaceAccountCollectionValue,
      };
      optimisticUpdates.push(spaceAccountCollectionUpdate);
    }

    // Finally, delete Collection
    const value: CollectionDeletedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: existingCollection.modelVersion,
    };
    const optimisticUpdate: SyncUpdate<CollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value,
    };
    optimisticUpdates.push(optimisticUpdate);

    return optimisticUpdates;
  }

  execute() {
    const existingCollection = this.store.collections.get(this.payload.id);
    if (!existingCollection) return;

    this.title = existingCollection.title || UNTITLED_COLLECTION_TITLE;
    super.execute();
  }

  private title = UNTITLED_COLLECTION_TITLE;

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be deleted. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to permanently delete {this.mediumTitle}. Please contact the
        collection owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be deleted. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
