import {
  mdsBorderRadius,
  mdsTransitions,
  mdsColors,
  mdsFontSizes,
  mdsLineHeights,
  mdsFontWeights,
  mdsLineSpacings,
  mdsSpacings,
  mdsSidebarWidths,
  mdsBorders,
  mdsShadows,
} from "@/design-system/foundations";
import { Theme } from "@emotion/react";

export const mdsCommonTheme: Theme = {
  colors: {
    ...mdsColors(),
  },

  fontSizes: {
    ...mdsFontSizes(),
  },

  fontWeights: {
    ...mdsFontWeights(),
  },

  borderRadius: {
    ...mdsBorderRadius(),
  },

  lineHeights: {
    ...mdsLineHeights(),
  },

  lineSpacings: {
    ...mdsLineSpacings(),
  },

  transitions: {
    ...mdsTransitions(),
  },

  spacing: {
    ...mdsSpacings(),
  },

  sidebar: {
    ...mdsSidebarWidths(),
  },

  borders: {
    ...mdsBorders(),
  },

  shadows: {
    ...mdsShadows(),
  },
};
