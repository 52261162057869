import styled from "@emotion/styled";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsText, MdsTextProps } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { FadeInAnimator } from "@/design-system/components/animation";

export const NoteSavedIndicator = () => {
  return (
    <FadeInAnimator
      element={
        <Wrapper>
          <MdsIcon kind={MdsIconKind.CheckCircleSolid} className={iconStyles} />
          <Label>Saved</Label>
        </Wrapper>
      }
    />
  );
};

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing.xs,
  alignItems: "center",
  height: "100%",
}));

const Label = styled(MdsText)<MdsTextProps>(({ theme }) => ({
  color: theme.colors.grey.x700,
  fontWeight: theme.fontWeights.semiBold,
  size: theme.fontSizes.small,
}));

const iconStyles = css({
  color: "#0D9488",
});
