import { FC } from "react";
import { observer } from "mobx-react-lite";
import EmptyCollections from "@/assets/images/empty-collections.svg";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsButtonShape, MdsButtonSize } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";

export const CollectionViewPageListEmpty: FC = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsViewPage;

  const canWrite =
    !page.collectionObservable?.isDeleted && page.collectionObservable?.canWrite == true;

  return (
    <EmptyContainer>
      <img src={EmptyCollections} alt="No collections" />
      <EmptyText>
        No notes in this collection yet.{" "}
        {canWrite && "Create a new note in this collection or add an existing one."}
      </EmptyText>
      {canWrite && (
        <MdsButton
          label="Create note in collection"
          variant={MdsButtonVariant.FilledDark}
          shape={MdsButtonShape.Square}
          size={MdsButtonSize.Medium}
          onClick={() => page.handleCreateNewNote()}
          iconKind={MdsIconKind.ComposeInline}
        />
      )}
    </EmptyContainer>
  );
});
