import { MdsText, MdsTextWeight } from "@/design-system/components/text";
import { MdsTextStylingMode } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { GuestAppStore } from "@/store";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { BaseSyncOperationGuestMode } from "@/store/sync/operations/BaseSyncOperationGuestMode";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import {
  IGrantNoteAclViaEmailAddressOperationGuestMode,
  IGrantNoteAclViaEmailAddressOperation,
  GuestModeSupportedSyncOperationKind,
} from "@/store/sync/operations/types";
import { SyncCustomErrorData } from "@/store/sync/types";

export class GrantNoteAclViaEmailAddressOperation extends BaseSyncOperation<IGrantNoteAclViaEmailAddressOperation> {
  get operationKind(): GuestModeSupportedSyncOperationKind {
    return "GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS";
  }

  get successToastMessage() {
    return (
      <>
        Sharing {this.mediumTitle} with {this.mediumEmail}...
      </>
    );
  }

  get title() {
    return (
      (this.store instanceof GuestAppStore
        ? this.store.note.getNoteObservableById({ noteId: this.payload.id })
        : this.store.notes.get(this.payload.id)
      )?.title || UNTITLED_NOTE_TITLE
    );
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  get mediumEmail() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.payload.email_address}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumEmail} could not be shared. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>You do not have permissions to share {this.mediumEmail}. Please contact the note owner.</>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumEmail} could not be shared. If this error continues, please contact support.</>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}

export class GrantNoteAclViaEmailAddressOperationGuestMode extends BaseSyncOperationGuestMode<
  IGrantNoteAclViaEmailAddressOperation,
  IGrantNoteAclViaEmailAddressOperationGuestMode
> {
  get operationKind(): GuestModeSupportedSyncOperationKind {
    return "GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS";
  }
}
