import { injectGlobalStyles } from "@mem-labs/common-editor";
import { backendApiClientManager } from "@/manager/backend-api-client";
import { cacheClientManager } from "@/manager/cache-client";
import { datadogLoggingClientManager } from "@/manager/datadog-logging-client";
import { loggerClientManager } from "@/manager/logging-client";

export const initializeGlobalState = async () => {
  injectGlobalStyles();
  await backendApiClientManager.initialize({});
  await cacheClientManager.initialize({});
  await datadogLoggingClientManager.initialize({});
  await loggerClientManager.initialize({});
};
