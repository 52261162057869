import { FC } from "react";
import { observer } from "mobx-react-lite";
import { StripeSubscriptionPlan } from "@/components/subscriptions/types";
import { StripeFormPaymentSuccessHandler } from "@/components/subscriptions/form/types";
import {
  StripePaymentFormWrapper,
  StripePaymentFormWrapperProps,
} from "@/components/subscriptions/form/StripePaymentFormWrapper";
import { css } from "@/domains/emotion";
import { STRIPE_PAYMENT_FORM_MIN_HEIGHT_PX } from "@/components/subscriptions/form/constants";

export interface StripePaymentFormProps
  extends Omit<
    StripePaymentFormWrapperProps,
    "stripeClientSecret" | "totalText" | "billingStrategy"
  > {
  activePlan: StripeSubscriptionPlan;
  stripeFormPaymentSuccessHandler: StripeFormPaymentSuccessHandler;
}

export const StripePaymentForm: FC<StripePaymentFormProps> = observer(
  ({ activePlan, stripeFormInfoState, ...props }) => {
    return (
      <div className={wrapperStyles}>
        <StripePaymentFormWrapper
          {...props}
          billingStrategy={activePlan.billingStrategy}
          totalText={activePlan.dueTodayText}
          stripeFormInfoState={stripeFormInfoState}
        />
      </div>
    );
  }
);

const wrapperStyles = css({
  minHeight: STRIPE_PAYMENT_FORM_MIN_HEIGHT_PX,
});
