import { animationsModule } from "@/modules/animations";
import { clamp } from "lodash-es";
import { useCallback, useRef, useState, useEffect, useLayoutEffect } from "react";

export enum ScrollDirection {
  Left = "left",
  Right = "right",
}

const SCROLL_CONFIG = {
  // Starting amount of pixels to scroll for each frame (speed)
  initialAmount: 1,
  // Maximum amount of pixels to scroll for each frame (speed)
  maxAmount: 6,
  // Milliseconds to reach max speed (acceleration)
  accelerationDuration: 150,
} as const;

export interface ScrollState {
  leftVisible: boolean;
  rightVisible: boolean;
  activeButton: ScrollDirection | null;
}

export function useHorizontalScroll() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [scrollState, setScrollState] = useState<ScrollState>({
    leftVisible: false,
    rightVisible: false,
    activeButton: null,
  });

  const scrollIntervalRef = useRef<number | null>(null);
  const scrollStartTimeRef = useRef<number | null>(null);

  const updateScrollButtonsVisibility = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollLeft, scrollWidth, clientWidth } = container;

    setScrollState(prevState => ({
      ...prevState,
      leftVisible: scrollLeft > 0,
      rightVisible: scrollLeft < scrollWidth - clientWidth - 1,
    }));
  }, []);

  const scrollHorizontally = useCallback(
    (direction: ScrollDirection, timestamp: number) => {
      const container = scrollContainerRef.current;
      if (!container) return;

      if (scrollStartTimeRef.current === null) {
        scrollStartTimeRef.current = timestamp;
      }

      const elapsedTime = timestamp - scrollStartTimeRef.current;
      const progress = Math.min(elapsedTime / SCROLL_CONFIG.accelerationDuration, 1);
      const currentAmount = animationsModule.easeInCubic({
        currentTime: progress,
        startValue: SCROLL_CONFIG.initialAmount,
        changeInValue: SCROLL_CONFIG.maxAmount - SCROLL_CONFIG.initialAmount,
        duration: 1,
      });

      const scrollAmount = direction === ScrollDirection.Left ? -currentAmount : currentAmount;

      // Calculate the new scrollLeft value
      const newScrollLeft = container.scrollLeft + scrollAmount;

      // Clamp the scrollLeft value using Lodash
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const clampedScrollLeft = clamp(newScrollLeft, 0, maxScrollLeft);

      // Set the clamped value
      container.scrollLeft = clampedScrollLeft;

      updateScrollButtonsVisibility();
    },
    [updateScrollButtonsVisibility]
  );

  const startScrolling = useCallback(
    (direction: ScrollDirection) => {
      setScrollState(prevState => ({ ...prevState, activeButton: direction }));
      scrollStartTimeRef.current = null;

      if (scrollIntervalRef.current !== null) {
        return;
      }

      const scroll = (timestamp: number) => {
        scrollHorizontally(direction, timestamp);
        scrollIntervalRef.current = requestAnimationFrame(scroll);
      };

      scrollIntervalRef.current = requestAnimationFrame(scroll);
    },
    [scrollHorizontally]
  );

  const stopScrolling = useCallback(() => {
    setScrollState(prevState => ({ ...prevState, activeButton: null }));

    if (scrollIntervalRef.current === null) {
      return;
    }

    cancelAnimationFrame(scrollIntervalRef.current);
    scrollIntervalRef.current = null;
    scrollStartTimeRef.current = null;
  }, []);

  const scrollForDuration = useCallback(
    (direction: ScrollDirection, duration: number) => {
      startScrolling(direction);
      setTimeout(() => stopScrolling(), duration);
    },
    [startScrolling, stopScrolling]
  );

  useEffect(() => {
    return () => {
      if (scrollIntervalRef.current !== null) {
        cancelAnimationFrame(scrollIntervalRef.current);
      }
    };
  }, []);

  useLayoutEffect(() => {
    updateScrollButtonsVisibility();
    const container = scrollContainerRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver(updateScrollButtonsVisibility);
    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [updateScrollButtonsVisibility]);

  return {
    scrollContainerRef,
    scrollState,
    handleScroll: updateScrollButtonsVisibility,
    startScrolling,
    stopScrolling,
    scrollForDuration,
  };
}
