import { appRoutes } from "@/app/router";
import { useLogOutPageLogic } from "@/pages/log-out/utils/useLogOutPageLogic";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Navigate } from "react-router-dom";

interface LogOutPageProps {}

export const LogOutPage: FC<LogOutPageProps> = observer(() => {
  const { shouldRedirect } = useLogOutPageLogic();

  if (shouldRedirect) {
    return <Navigate to={appRoutes.root({}).path} />;
  }

  /**
   * We display a blank screen while logging the user out.
   */
  return null;
});
