import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store/AppStore";
import {
  SpaceAccountCollectionModelData,
  SpaceAccountCollectionUpsertedSyncUpdateValue,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IMarkCollectionViewedOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class MarkCollectionViewedOperation extends BaseSyncOperation<IMarkCollectionViewedOperation> {
  get operationKind(): SyncOperationKind {
    return "MARK_COLLECTION_VIEWED";
  }

  public generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    return MarkCollectionViewedOperation.generateOptimisticUpdatesFor({
      store: this.store,
      collectionId: this.payload.collection_id,
      committedAt: this.committedAt,
    });
  }

  public static generateOptimisticUpdatesFor({
    store,
    collectionId,
    committedAt,
  }: {
    store: AppStore;
    collectionId: string;
    committedAt: string;
  }): SyncUpdate<SyncModelData>[] {
    const spaceAccountId = store.spaceAccounts.myPersonalSpaceAccountId;
    const spaceAccountCollectionId = uuidModule.resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId,
      collectionId,
    });
    const spaceAccountCollectionObservable =
      store.spaceAccountCollections.get(spaceAccountCollectionId);

    const value: SpaceAccountCollectionUpsertedSyncUpdateValue = {
      model_id: spaceAccountCollectionId,
      model_kind: "SPACE_ACCOUNT_COLLECTION",
      model_version: spaceAccountCollectionObservable?.modelVersion ?? 0,
      model_data: {
        space_account_id: spaceAccountId,
        collection_id: collectionId,
        last_viewed_at: committedAt,
        last_added_to_at: spaceAccountCollectionObservable?.modelData.last_added_to_at || null,
        last_removed_from_at:
          spaceAccountCollectionObservable?.modelData.last_removed_from_at || null,
      },
      model_scopes: spaceAccountCollectionObservable
        ? spaceAccountCollectionObservable.modelScopes
        : [generateDefaultOwnerScopes({ store })],
    };
    const syncUpdate: SyncUpdate<SpaceAccountCollectionModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: committedAt,
      locally_committed_at: committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
