export const renderArrayWithAnds = (array: string[]) => {
  if (!array.length) {
    return "";
  }

  if (array.length === 1) {
    return array[0];
  }

  if (array.length === 2) {
    return `${array[0]} and ${array[1]}`;
  }

  const preOxfordComma = array.slice(0, array.length - 1).join(", ");
  return `${preOxfordComma}, and ${array[array.length - 1]}`;
};
