import React, { FC } from "react";
import { css, cx } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";

interface SettingsSubsectionTitleProps {
  title: string;
  className?: string;
}

export const SettingsSubsectionTitle: FC<SettingsSubsectionTitleProps> = ({ className, title }) => {
  return <div className={cx(wrapperStyles, className)}>{title}</div>;
};

const wrapperStyles = css({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: mdsColors().grey.x700,
  marginBottom: 24,
});
