import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsModal, type MdsModalProps } from "@/design-system/components/modal";
import {
  mdsBorderRadius,
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsMediaQueries,
  mdsSpacings,
} from "@/design-system/foundations";
import styled from "@emotion/styled";
import { FeedbackChip } from "./feedback-chip";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";

const NEGATIVE_FEEDBACK_OPTIONS = [
  "Don't like style",
  "Not factually correct",
  "Refused when it shouldn't have",
  "Harmful or unsafe",
  "Didn't reference personal notes",
  "Other",
];

interface Props extends Omit<MdsModalProps, "children"> {
  isPositive: boolean;
  onSubmit: (suggestedFeedback: string | undefined, userText: string) => void;
}

export const ChatMessageFeedbackModal = observer(
  ({ isPositive, onSubmit, handleCloseModal, ...modalProps }: Props) => {
    const [selectedFeedback, setSelectedFeedback] = useState<number>(-1);
    const [userText, setUserText] = useState<string>("");

    const wrappedHandleCloseModal = () => {
      setSelectedFeedback(-1);
      setUserText("");
      handleCloseModal();
    };

    const handleClickChip = (index: number) => {
      if (selectedFeedback === index) {
        // un-set the selected chip
        setSelectedFeedback(-1);
      } else {
        setSelectedFeedback(index);
      }
    };

    const handleChangeUserText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setUserText(e.target.value);
    };

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      wrappedHandleCloseModal();
    };

    const handleSubmitFeedback = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSelectedFeedback(-1);
      setUserText("");
      onSubmit(NEGATIVE_FEEDBACK_OPTIONS[selectedFeedback], userText);
    };

    return (
      <MdsModal handleCloseModal={wrappedHandleCloseModal} {...modalProps}>
        <ModalBodyWrapper>
          <ModalTitlebar>
            <Title>Submit feedback</Title>
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={wrappedHandleCloseModal} />
          </ModalTitlebar>
          <form onSubmit={handleSubmitFeedback}>
            <Body>
              {isPositive && (
                <FeedbackPrompt>Tell us more why this response was satisfying:</FeedbackPrompt>
              )}
              {!isPositive && (
                <>
                  <FeedbackPrompt>
                    Tell us more why this response was not satisfying:
                  </FeedbackPrompt>
                  <Chips>
                    {NEGATIVE_FEEDBACK_OPTIONS.map((text, i) => (
                      <FeedbackChip
                        key={text}
                        onClick={e => {
                          e.preventDefault();
                          handleClickChip(i);
                        }}
                        isSelected={selectedFeedback === i}
                      >
                        {text}
                      </FeedbackChip>
                    ))}
                  </Chips>
                </>
              )}
              <FeedbackTextArea
                placeholder="Share more details below..."
                name="userText"
                value={userText}
                onChange={handleChangeUserText}
              />
              <Buttons>
                <MdsButton
                  label="Cancel"
                  variant={MdsButtonVariant.Outlined}
                  onClick={handleCancel}
                />
                <MdsButton
                  type="submit"
                  label="Submit"
                  variant={MdsButtonVariant.FilledDark}
                  onClick={handleSubmitFeedback}
                />
              </Buttons>
            </Body>
          </form>
        </ModalBodyWrapper>
      </MdsModal>
    );
  }
);

const ModalBodyWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  maxHeight: "85dvh",
  minWidth: "90dvw",
  maxWidth: "90dvw",
  padding: mdsSpacings().md,
  [mdsMediaQueries().rules.tablet]: {
    minWidth: "500px",
  },
  [mdsMediaQueries().rules.desktop]: {
    padding: mdsSpacings().lg,
    minWidth: "650px",
  },
});

const ModalTitlebar = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: mdsSpacings().sm,
});

const Title = styled.h2({
  fontSize: mdsFontSizes().large,
  fontWeight: mdsFontWeights().bold,
  lineHeight: "1rem",
  margin: 0,
});

const FeedbackTextArea = styled.textarea({
  border: `1px solid ${mdsColors().grey.x100}`,
  borderRadius: mdsBorderRadius().medium,
  minHeight: "72px",
  padding: mdsSpacings().sm,
  width: "100%",
  "&::placeholder": {
    color: mdsColors().grey.x400,
  },
});

const Body = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: mdsSpacings().sm,
});

const Chips = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: mdsSpacings().sm,
});

const Buttons = styled.div({
  display: "flex",
  justifyContent: "end",
  flexDirection: "row",
  gap: mdsSpacings().sm,
});

const FeedbackPrompt = styled.p({
  fontSize: mdsFontSizes().small,
  color: mdsColors().chat.header,
  margin: `${mdsSpacings().xs} 0 0 0`,
  fontWeight: mdsFontWeights().regular,
  fontsize: mdsFontSizes().small,
});
