import { css, cx } from "@/domains/emotion";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors, mdsLineHeights } from "@/design-system/foundations";

type TProps = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
};

export function EmptyText({ className, children }: TProps): JSX.Element {
  return (
    <MdsText
      size={MdsTextSize.Small}
      weight={MdsTextWeight.Regular}
      className={cx(textStyles, className)}
    >
      {children}
    </MdsText>
  );
}

const textStyles = css({
  width: 330,
  color: mdsColors().grey.x500,
  textAlign: "center",
  marginBottom: 10,
  lineHeight: mdsLineHeights().small,
});
