import { Maybe } from "@/domains/common/types";
import { CriticalErrorCode, CriticalErrorHandler } from "@/domains/critical-errors/types";

let registeredHandlers: CriticalErrorHandler[] = [];

export const onCriticalError = (handler: CriticalErrorHandler) => {
  registeredHandlers.push(handler);
  return () => {
    registeredHandlers = registeredHandlers.filter(
      registeredHandler => registeredHandler !== handler
    );
  };
};

export const raiseCriticalError = ({ message }: { message: string }) => {
  registeredHandlers.forEach(handler => {
    handler({ message });
  });
};

let criticalErrorCode: Maybe<CriticalErrorCode>;

export const getCriticalErrorCode = () => criticalErrorCode;

export const setCriticalErrorCode = (code: Maybe<CriticalErrorCode>) => {
  criticalErrorCode = code;
};
