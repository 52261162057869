import { errorHandlingModule } from "@/modules/error-handling";
import { logger } from "@/modules/logger";
import { ApiRequestConfig, ApiRequestFetchDataHandler } from "@/store/queries/types";
import { isUndefined } from "lodash-es";
import { makeAutoObservable, runInAction } from "mobx";
import pRetry from "p-retry";
import { JsonObject } from "type-fest";

export interface ApiRequestObservableArgs<TData extends JsonObject> {
  handleFetchData: ApiRequestFetchDataHandler<TData>;
  config: ApiRequestConfig;
}

export class ApiRequestObservable<TData extends JsonObject> {
  private handleFetchData: ApiRequestFetchDataHandler<TData>;
  private config: ApiRequestConfig;
  private abortController: AbortController = new AbortController();

  isIdle = true;
  isCalled = false;
  isLoading = false;
  isSuccess = false;
  isSkipped = false;
  isError = false;
  isCancelled = false;
  data: TData | undefined = undefined;
  error: Error | null = null;

  constructor({ handleFetchData, config }: ApiRequestObservableArgs<TData>) {
    this.handleFetchData = handleFetchData;
    this.config = config;
    makeAutoObservable(this);
  }

  fetchData = async () => {
    if (this.isLoading) {
      return;
    }

    runInAction(() => {
      this.isIdle = false;
      this.isCalled = true;
      this.isLoading = true;
      this.isSuccess = false;
      this.isError = false;
      this.isCancelled = false;
      this.error = null;
    });

    try {
      await pRetry(
        async () => {
          const result = await this.handleFetchData({
            signal: this.abortController.signal,
          });

          /**
           * If the result is undefined, we consider it as a "skipped" request.
           */
          if (isUndefined(result)) {
            runInAction(() => {
              this.isSkipped = true;
            });

            return;
          }

          runInAction(() => {
            this.data = result;
            this.isSuccess = true;
          });
        },
        {
          onFailedAttempt: error => {
            logger.warn({
              message: `[ApiRequestObservable] [onFailedAttempt] Failed: ${error.message}`,
            });
          },
          retries: this.config.retries ?? 3,
          signal: this.abortController.signal,
        }
      );
    } catch (unknownError) {
      runInAction(() => {
        if (this.abortController.signal.aborted) {
          this.isCancelled = true;
          return;
        }

        const error = errorHandlingModule.parseError({ unknownError });
        this.isError = true;
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  cancel = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  };
}
