const TABLE_NAME = "syncUpdates";
const FIELD_COMMITTED_AT = "committed_at";
const TABLE_SCHEMA = `,${FIELD_COMMITTED_AT}`;

export default {
  TABLE_NAME,
  TABLE_SCHEMA,

  FIELD_COMMITTED_AT,
};
