import { MemCommonEditorStore } from "@/components/note/store/MemCommonEditorStore";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { MouseEventHandler } from "react";

interface NoteEditorMetadataProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  store: MemCommonEditorStore;
}

export const NoteEditorMetadata = observer<NoteEditorMetadataProps>(function NoteEditorMetadata({
  onClick,
  store,
}) {
  return (
    <MdsTooltip
      config={{
        label: store.metadataTooltip,
        placement: MdsTooltipPlacement.TopLeft,
        delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
      }}
      contentClassName={metadataStyles}
    >
      <span onClick={onClick}>{store.metadata}</span>
    </MdsTooltip>
  );
});

const metadataStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  height: "32px",
  minHeight: "32px",
  paddingLeft: "var(--extra-padding-left)",
  lineHeight: "20px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
