import { NodeEnvironment } from "@/domains/common/types";

interface ParsedGlobalEnv {
  NODE_ENV?: NodeEnvironment;
  GITHUB_SHA?: string;
  BUILD_TIMESTAMP: string;
  PACKAGE_VERSION: string;
}

/**
 * Vite injects a __GLOBAL_ENV__ as part of the build stage.
 * We can parse it out here.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const GLOBAL_ENV: ParsedGlobalEnv = __GLOBAL_ENV__;

/**
 * Vite also injects certain environment variables.
 * We expose them here.
 */
export const VITE_USE_LOCAL_BACKEND_API = import.meta.env.VITE_USE_LOCAL_BACKEND_API === "true";
export const VITE_USE_LOCAL_MEM_API = import.meta.env.VITE_USE_LOCAL_MEM_API === "true";
export const VITE_USE_BETA_API = import.meta.env.VITE_USE_BETA_API === "true";
