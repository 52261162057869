import {
  flip,
  FloatingNode,
  FloatingPortal,
  Placement as FloatingPlacement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
} from "@floating-ui/react";
import { ReactNode, useMemo, FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { ZIndex } from "@/domains/design/constants";
import { AnimatePresence } from "framer-motion";
import { SpringAnimator } from "@/design-system/components/animation";

type Placement = "above" | "below" | "below-right-alignment" | "right";

export interface MdsDropdownProps extends EmotionClassStyles {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  contentList: MdsDropdownContentList;
  children: ReactNode;
  placement?: Placement;
  innerStyles?: {
    ContentContainer?: {
      className?: string;
    };
  };
}

const getPlacement = (placement?: Placement): FloatingPlacement => {
  switch (placement) {
    case "above":
      return "top-start";
    case "below":
      return "bottom-start";
    case "below-right-alignment":
      return "bottom-end";
    case "right":
      return "right-start";
  }
  return "bottom-start";
};

const contentContainerStyles = css({
  position: "relative",
});

const rightAlignContentStyles = css({
  position: "absolute",
  right: 0,
  height: "fit-content",
});

export const MdsDropdown: FC<MdsDropdownProps> = ({
  className,
  children,
  isOpen,
  onOpenChange,
  contentList,
  placement,
  innerStyles,
}) => {
  const nodeId = useFloatingNodeId();

  /**
   * We can make some improvements to the hover handling here.
   *
   * See the docs for more details:
   * - https://floating-ui.com/docs/useFloating
   */
  const { x, y, refs, strategy, context } = useFloating({
    nodeId,
    open: isOpen,
    onOpenChange: value => {
      if (value) {
        /** We don't want to open on hover states. */
        return;
      }

      onOpenChange(false);
    },
    placement: getPlacement(placement),
    middleware: [flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { toggle: true }),
    useDismiss(context),
  ]);

  const contentStyles = placement === "below-right-alignment" ? rightAlignContentStyles : undefined;

  const portalContent = useMemo(() => {
    return (
      <AnimatePresence>
        {isOpen && (
          <SpringAnimator
            className={css({ width: 0 })}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: ZIndex.Dropdown,
            }}
            innerStyle={{
              marginTop: "4px",
              transformOrigin: "top center",
              willChange: "transform",
              position: "absolute",
            }}
            {...getFloatingProps()}
          >
            <div className={cx(contentContainerStyles, innerStyles?.ContentContainer?.className)}>
              <MdsDropdownContent
                className={contentStyles}
                onOpenChange={onOpenChange}
                contentList={contentList}
              />
            </div>
          </SpringAnimator>
        )}
      </AnimatePresence>
    );
  }, [
    contentList,
    contentStyles,
    getFloatingProps,
    innerStyles?.ContentContainer?.className,
    isOpen,
    onOpenChange,
    refs,
    strategy,
    x,
    y,
  ]);

  return (
    <FloatingNode id={nodeId}>
      <div ref={refs.setReference} {...getReferenceProps()} className={className}>
        {children}
      </div>
      <FloatingPortal>{portalContent}</FloatingPortal>
    </FloatingNode>
  );
};
