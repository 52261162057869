import { computed, makeObservable, observable } from "mobx";

export class StripeSetupIntentObservable {
  stripeSetupIntentId: string;
  stripeSetupIntentClientSecret: string;
  stripeCustomerId: string;

  constructor({
    stripeSetupIntentId,
    stripeSetupIntentClientSecret,
    stripeCustomerId,
  }: {
    stripeSetupIntentId: string;
    stripeSetupIntentClientSecret: string;
    stripeCustomerId: string;
  }) {
    this.stripeSetupIntentId = stripeSetupIntentId;
    this.stripeSetupIntentClientSecret = stripeSetupIntentClientSecret;
    this.stripeCustomerId = stripeCustomerId;

    makeObservable(this, {
      stripeSetupIntentId: observable,
      stripeSetupIntentClientSecret: observable,
      stripeCustomerId: observable,
      stripeClientSecret: computed,
    });
  }

  get stripeClientSecret() {
    return this.stripeSetupIntentClientSecret;
  }
}
