import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store/AppStore";
import { NoteModelData, NoteUpsertedSyncUpdateValue } from "@/store/note/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { IRestoreNoteOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, SyncUpdate } from "@/store/sync/types";

export class RestoreNoteOperation extends BaseSyncOperation<IRestoreNoteOperation> {
  get operationKind(): SyncOperationKind {
    return "RESTORE_NOTE";
  }

  generateOptimisticUpdates(): SyncUpdate<NoteModelData>[] {
    const existingNote = this.store.notes.getNoteObservableById({ noteId: this.payload.id });
    if (!existingNote) return [];

    const value: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: existingNote.modelVersion,
      model_data: {
        ...existingNote.modelData,
        trashed_at: null,
      },
      model_scopes: existingNote.modelScopes,
    };
    const syncUpdate: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  execute() {
    const existingNote = this.store.notes.get(this.payload.id);
    if (!existingNote) return;
    super.execute();
  }

  get successToastMessage() {
    const note = this.store.notes.get(this.payload.id);
    if (!note?.isOwnedByMe || !this.triggerSuccessToast) return null;
    return (
      <>
        <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
          {note.title || UNTITLED_NOTE_TITLE}
        </MdsText>{" "}
        restored from <a onClick={() => this.store.navigation.goToTrash()}>Trash</a>
      </>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.getSyncErrorModalFieldsGenerator("INVALID"));
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerModal(this.getSyncErrorModalFieldsGenerator("PERMISSION_DENIED"));
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  private getSyncErrorModalFieldsGenerator(kind: SyncCustomErrorData["kind"]) {
    return (store: AppStore) => new RestoreNoteSyncErrorModalFieldsGenerator(store, this.id, kind);
  }
}

class RestoreNoteSyncErrorModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected kind: SyncCustomErrorData["kind"];

  constructor(store: AppStore, id: string, kind: SyncCustomErrorData["kind"]) {
    super(store);

    this.id = id;
    this.kind = kind;
  }

  get includeContactSupportButton() {
    return this.kind === "UNKNOWN";
  }

  get title() {
    const note = this.store.notes.get(this.id);
    switch (this.kind) {
      case "INVALID":
      case "UNKNOWN":
        return `“${note?.title || UNTITLED_NOTE_TITLE}” could not be restored`;
      case "PERMISSION_DENIED":
        return `You do not have permissions to restore “${note?.title || UNTITLED_NOTE_TITLE}”`;
    }
  }

  get message() {
    switch (this.kind) {
      case "INVALID":
        return `The note was permanently deleted`;
      case "PERMISSION_DENIED":
        return `Please contact the note owner`;
      case "UNKNOWN":
        return `If this error continues, please contact support`;
    }
  }

  modalActionHandler = () => {
    this.store.sync.actionQueue.skipAndRevertOperationById(this.id);
  };
}
