import { uuidModule } from "@/modules/uuid";
import {
  ChatMessageUpsertedSyncUpdate,
  ChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { validateChatMessageContext } from "@/store/sync/operations/helpers/chat";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ISubmitChatMessageOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SyncUpdate,
} from "@/store/sync/types";

export class SubmitChatMessageOperation extends BaseSyncOperation<ISubmitChatMessageOperation> {
  get operationKind(): SyncOperationKind {
    return "SUBMIT_CHAT_MESSAGE";
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    const chatMessageUpdateValue: ChatMessageUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "CHAT_MESSAGE",
      model_version: 0,
      model_data: {
        id: this.payload.id,
        chat_conversation_id: this.payload.chat_conversation_id,
        locally_created_at: this.committedAt,
        sent_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        status: "PROCESSING",
        sources: [],
        contexts: this.payload.contexts.map(ctx =>
          validateChatMessageContext(this.payload.id, ctx)
        ),
        sections: [
          {
            id: uuidModule.generate(),
            chat_message_id: this.payload.id,
            kind: "TEXT",
            status: "PROCESSING",
            value: { kind: "TEXT", schema_version: 1, content: this.payload.content },
          },
        ],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const chatMessageUpdate: ChatMessageUpsertedSyncUpdate = {
      sync_id: this.payload.id,
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: chatMessageUpdateValue,
    };
    return [chatMessageUpdate];
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send message. Please try again.");
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to chat in this conversation.");
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to send message. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send message. Please try again.");
  }
}
