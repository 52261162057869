import { mdsMediaQueries } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";

export const TOP_BAR_DEFAULT_HEIGHT = "68px";
export const TOP_BAR_SMALL_DEVICES_HEIGHT = "60px";

/**
 * @deprecated - We need to clean this up...
 */
export const topBarStyles = css({
  ".app-top-bar-button.global-compose-button": {
    background: "linear-gradient(214.1deg, #eb2487 7.03%, #f93939 93.99%)",
    boxShadow: "0px 5px 9px 0px #f22e611f",
    color: "#f22e61",
    height: "36px",
    width: "112px",
    gap: "4px",
    fontWeight: 600,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    borderRadius: "100px",
    justifyContent: "center",
    marginRight: "8px",

    [mdsMediaQueries().rules.phone]: {
      width: "70px",
    },
  },
  ".app-top-bar-button.global-compose-button svg": {
    transform: "translate(1px, -1px)",
  },
  ".app-top-bar-button.global-compose-button:hover": {
    color: "linear-gradient(214.1deg, #f93939 7.03%, #eb2487 93.99%)",
    boxShadow: "0px 8px 10px 0px #f22e611f",
    transition: "0.2s ease-in",
  },
  ".light-mode .app-top-bar-button.global-compose-button": { color: "#fff" },
  ".light-mode .app-top-bar-button.global-compose-button:hover": {
    background: "linear-gradient(214.1deg, #f93939 7.03%, #eb2487 93.99%)",
    boxShadow: "0px 6px 12px 0px #f22e6136",
    transition: "0.2s ease-in",
  },
  ".light-mode .app-top-bar-button.global-compose-button:active": {
    transform: "scale(0.98)",
    transitionDuration: "0.1s",
  },
  ".app-top-bar": {
    display: "flex",
    alignItems: "center",
    // Replaced the original implementation.
    // padding: "12px 24px 12px 40px !important",
    padding: "12px 12px 20px 12px !important",
    zIndex: ZIndex.NavBar,
    justifyContent: "flex-start",
    height: TOP_BAR_DEFAULT_HEIGHT,
    minHeight: TOP_BAR_DEFAULT_HEIGHT,
    width: "100% !important",
    maxWidth: "100%",

    [mdsMediaQueries().exclusion.rules.desktop]: {
      borderBottom: "1px solid #E8E9ED",
      height: TOP_BAR_SMALL_DEVICES_HEIGHT,
      minHeight: TOP_BAR_SMALL_DEVICES_HEIGHT,
      paddingBottom: "12px !important",
    },
  },
  ".app-top-bar.is-desktop": { paddingLeft: "70px" },
  ".app-top-bar-button": {
    lineHeight: "24px",
    width: "24px",
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "15px",
    fontSize: "16px",
    position: ["relative", "relative"],
    transition: "transform 0.2s, color 0.2s",
    transformStyle: "flat",
    WebkitTransformStyle: "flat",
    MozTransformStyle: "flat",
    zIndex: 3,
    userSelect: "none",
    flexShrink: 0,
  },
  ".menu-pane .app-top-bar-button.toggle-menu": {
    position: "absolute",
    left: "16px",
    margin: "0",
    zIndex: 150,
  },
  ".app-top-bar.is-sidebar-locked .app-top-bar-button.toggle-menu": {
    display: "none",
  },
  ".app-top-bar-button.user-profile-image:not(.toggle-menu)": {
    display: "flex",
    alignItems: "center",
    zIndex: 4,
    width: "32px",
    height: "32px",
    cursor: "default",
  },
  ".app-top-bar.is-desktop > .app-top-bar-button.toggle-menu": {
    marginLeft: "30px",
  },
  "@media (max-width: 900px)": [
    {
      ".app-top-bar.is-desktop > .app-top-bar-button.toggle-menu": {
        marginLeft: "0",
      },
    },
    { ".app-top-bar-hint": { display: "none" } },
  ],
  ".app-top-bar-search-container .app-top-bar-button.star-button": {},
  ".app-top-bar-search-container .app-top-bar-button.bright": {
    color: "#ffd700",
  },
  ".app-top-bar-search-container .app-top-bar-button.bright:hover": {
    color: "#ffd700",
  },
  ".app-top-bar-button.user-settings": {
    overflow: "visible",
    borderRadius: "0",
  },
  ".app-top-bar-button.user-settings .img": {
    borderRadius: "50%",
    color: "#fff",
    width: "24px",
    height: "24px",
    lineHeight: "24px",
    margin: "3px",
    fontSize: "13px",
  },
  ".app-top-bar-button.back-button": {
    marginLeft: "0px",
    marginRight: "10px",
    marginTop: "8px",
  },
  ".app-top-bar-button.forward-button": {
    marginLeft: "0",
    marginRight: "16px",
    marginTop: "8px",
  },
  ".app-top-bar-button:not(.disabled).active,\n.app-top-bar-button:active,\n.app-top-bar-button:hover:active,\n.app-top-bar-button.modal-open,\n.app-top-bar-button.modal-open:hover\n  .app-top-bar-search-container\n  .app-top-bar-button:not(.disabled).active,\n.app-top-bar-search-container .app-top-bar-button:active,\n.app-top-bar-search-container .app-top-bar-button:hover:active,\n.app-top-bar-search-container .app-top-bar-button.modal-open,\n.app-top-bar-search-container .app-top-bar-button.modal-open:hover":
    {
      transform: "scale(1)",
      transition: "none",
    },
  ".app-top-bar-button.nav-button.disabled": {
    opacity: 0.5,
    cursor: "default",
  },
  ".app-top-bar-button.nav-button.disabled:hover": { transform: "scale(1)" },
  ".app-top-bar-button.logo:hover": { background: "inherit" },
  ".app-top-bar-button.logo .img": {
    background: 'url("../mem-logo-white.png")',
    backgroundSize: "cover",
    height: "24px",
    width: "24px",
    borderRadius: "0",
  },
  ".app-top-bar-button:hover": { color: "#323640", transition: "0.2s" },
  ".app-top-bar-search-container": {
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "16px",
    height: "48px",
    flex: 10,
    display: "flex",
    alignItems: "center",
    margin: "0 12px",
    cursor: "pointer",
    position: "relative",
    maxWidth: "calc(100vw - 328px)",
    transition: "border-radius 2s",
    paddingRight: "16px",
    color: "#fff",
    border: "1px solid #f3f3f5",
    boxShadow: "0px 2px 20px 0px #f3f3f5",
    width: "80%",
    maxHeight: "56px",
  },
  ".app-top-bar.is-sidebar-locked": {
    marginLeft: "auto",
    paddingRight: "24px",
    width: "100%",
    maxWidth: "calc(100vw - 241px)",
  },
  ".app-top-bar-search-container:hover": {
    background: "rgba(255, 255, 255, 0.2)",
  },
  ".app-top-bar-search-container .app-top-bar-button": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  ".app-top-bar-search-container .app-top-bar-button:hover": {
    color: "#fff",
  },
  ".app-top-bar-search-container input": {
    background: "transparent",
    outline: "0",
    border: "none",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0",
    padding: "0",
    color: "#fff",
    borderRadius: "0",
    flex: 1,
  },
  ".app-top-bar-search-container input::placeholder": {
    color: "rgba(255, 255, 255, 0.85)",
  },
  ".app-top-bar-search-container input:-ms-input-placeholder": {
    color: "rgba(255, 255, 255, 0.85)",
  },
  ".app-top-bar-search-container input::-ms-input-placeholder": {
    color: "rgba(255, 255, 255, 0.85)",
  },
  ".app-top-bar-search-container .input": {
    background: "transparent",
    outline: "0",
    border: "none",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0",
    padding: "0",
    color: "rgba(255, 255, 255, 0.5)",
    borderRadius: "0",
    flex: 1,
    userSelect: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ".app-top-bar-button.user-settings:hover": {
    transform: ["scale(1.05)", "scale(1)"],
  },
  ".app-top-bar-button.keyboard-shortcuts": {
    overflow: "visible",
    borderRadius: "0",
  },
  ".app-top-bar-button.keyboard-shortcuts:hover": { transform: "scale(1.05)" },
  ".app-top-bar-hint-group": {
    display: "flex",
    alignItems: "center",
    margin: "-2px 0px -2px 6px",
  },
  ".app-top-bar-hint-group > *": { margin: "2px" },
  ".app-top-bar-hint": {
    borderRadius: "2px",
    color: "rgba(255, 255, 255, 0.7)",
    background: "rgba(255, 255, 255, 0.1)",
    fontSize: "13px",
    lineHeight: "20px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "normal",
  },
  ".win32.app-top-bar-hint": { padding: "0 6px", width: "auto" },
  ".light-mode .app-top-bar-hint": {
    color: "#caccd3",
    border: "1px solid #caccd3",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 500,
  },
  ".dark-mode .app-top-bar": { background: "#2e2f38" },
  ".dark-mode .app-top-bar-button": { color: "#9ea1b2" },
  ".light-mode .app-top-bar-search-container .app-top-bar-button.bright": {
    color: "#ffd700",
  },
  ".light-mode .app-top-bar-search-container .app-top-bar-button.bright:hover": {
    color: "#ffd700",
  },
  ".light-mode .app-top-bar": {
    paddingTop: "10px",
    background: "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
  },
  ".light-mode .app-top-bar-button": { color: "#3f424d" },
  ".light-mode .app-top-bar-button.logo .img": {
    background: 'url("../mem-logo.png")',
    backgroundSize: "cover",
  },
  ".light-mode .app-top-bar-search-container": {
    background: "#fff",
    WebkitTransition: "0.2s",
    paddingRight: "16px",
  },
  ".light-mode .app-top-bar-search-container:hover": {
    background: "rgba(232, 233, 237, 0.65)",
    WebkitTransition: "0.2s",
  },
  ".light-mode .app-top-bar-search-container .app-top-bar-button": {
    color: "#696e7d",
  },
  ".app-top-bar-search-container .app-top-bar-button.search-button": {
    margin: "0 8px 0 16px",
    width: "16px",
    height: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".light-mode .app-top-bar-search-container .app-top-bar-button:hover": {
    color: "#3f424d",
  },
  ".light-mode .app-top-bar-search-container .input": {
    color: "#9397a5",
    fontWeight: 400,
  },
  ".light-mode .app-top-bar-search-container input::placeholder": {
    color: "#9397a5",
    fontSize: "16px",
    lineHeight: "24px",
  },
  ".light-mode .app-top-bar-search-container input:-ms-input-placeholder": {
    color: "#9397a5",
  },
  ".light-mode .app-top-bar-search-container input::-ms-input-placeholder": {
    color: "#9397a5",
  },
  ".light-mode .app-top-bar-search-container input": { color: "#3f424d" },
});
