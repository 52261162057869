import { DateTime } from "luxon";

export enum InboundSyncStatus {
  Bootstrapping = "BOOTSTRAPPING",
  PerformingInitialSync = "PERFORMING_INITIAL_SYNC",
  Syncing = "SYNCING",
  Idle = "IDLE",
  PausedOffline = "PAUSED_OFFLINE",
  Unknown = "UNKNOWN",
}

export enum OutboundSyncStatus {
  Idle = "IDLE",
  Syncing = "SYNCING",
  Pending = "PENDING",
  PausedOffline = "PAUSED_OFFLINE",
  PausedUnknown = "PAUSED_UNKNOWN",
  Unknown = "UNKNOWN",
}

export type InboundSyncState = {
  status: InboundSyncStatus;
  info: {
    lastSyncedAt: DateTime | undefined;
  };
};

export type OutboundSyncState = {
  status: OutboundSyncStatus;
  info: {
    lastSyncedAt: DateTime | undefined;
    pendingOperationCount: number;
    earliestPendingOperationCommittedAt: DateTime | undefined;
  };
};
