import React, { FC } from "react";
import { Outlet } from "react-router";
import { SettingsLayout } from "@/pages/settings/components/SettingsLayout";

export const SettingsLayoutOutlet: FC = () => {
  return (
    <SettingsLayout>
      <Outlet />
    </SettingsLayout>
  );
};
