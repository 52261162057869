/**
 * Global Declarations.
 */
/// <reference types="vite-plugin-svgr/client" />

/**
 * Imports (Ordered).
 */
import { mountApp } from "@/app/mount";
import { globalStateModule } from "@/modules/global-state";
import { globalStateService as commonEditorGlobalStateModule } from "@mem-labs/common-editor";
/**
 * App Setup.
 */
const startApp = async () => {
  await globalStateModule.initialize();
  await commonEditorGlobalStateModule.initialize();

  mountApp();
};

startApp();
