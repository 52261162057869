import {
  FloatingTooltipOptions,
  useFloatingTooltip,
} from "@/design-system/components/tooltip/utils/useFloatingTooltip";
import { FloatingTooltipContext } from "@/design-system/components/tooltip/utils/useFloatingTooltipContext";

/**
 * Implementation heavily inspired from the floating-ui example docs here:
 * - https://floating-ui.com/docs/tooltip
 *
 * Prefer to use MdsTooltip directly, instead of using this.
 */
export function FloatingTooltip({
  children,
  ...options
}: { children: React.ReactNode } & FloatingTooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useFloatingTooltip(options);

  return (
    <FloatingTooltipContext.Provider value={tooltip}>{children}</FloatingTooltipContext.Provider>
  );
}
