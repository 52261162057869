/**
 * This is a version of the modulo operator, which also handles negative numbers.
 *
 * Normal JS
 *  - -5 % 7 = -5
 *
 * This version:
 * - -5 % 7 = 2
 */
export const modNumber = ({ numberA, numberB }: { numberA: number; numberB: number }) =>
  ((numberA % numberB) + numberB) % numberB;
