import { SyncModelPermissionEntryWithStatus } from "@/domains/sync-scopes/types";
import { filter } from "lodash-es";

export const getGrantedSpaceAccountIdsForPermissions = ({
  permissions,
}: {
  permissions: SyncModelPermissionEntryWithStatus[];
}) => {
  return filter(
    permissions.map(
      permission =>
        permission.role_kind !== "REVOKED" &&
        permission.scope_kind === "SPACE_ACCOUNT_SCOPE" &&
        permission.space_account_id
    )
  ) as string[];
};
