import { moduleFactory } from "@/factories/module";
import { googleIdentityTriggerLogIn } from "@/modules/google-identity/logIn";
import { _googleIdentityParseOAuthCode } from "@/modules/google-identity/parseOAuthCode";
import { googleIdentityTriggerSignUp } from "@/modules/google-identity/signUp";

export const googleIdentityModule = moduleFactory.create({
  triggerLogIn: googleIdentityTriggerLogIn,
  triggerSignUp: googleIdentityTriggerSignUp,
  parseOAuthCode: _googleIdentityParseOAuthCode,
});
