import { NoteObservableEditor } from "@/components/note/NoteObservableEditor";
import {
  NOTE_EDITOR_TOP_BAR_HEIGHT,
  NoteEditorTopBar,
} from "@/components/note/editor/top-bar/NoteEditorTopBar";
import { NoteEditorMode } from "@/components/note/types";
import { NoteSkeleton } from "@/components/skeleton/NoteSkeleton";
import { mdsMediaQueries } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { IContactModel } from "@/store/contacts/types";
import { INoteObservable } from "@/store/note/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

interface NoteEditorProps {
  isSidePanelOpen: boolean;
  getAvailableChips?: ChatHistory["getAvailableChips"];
  goToMention?: INotesViewPageStore["goToMention"];
  noteObservable?: INoteObservable;
  myAccount?: IContactModel;
  inSidePanel?: boolean;
}

export const NoteEditor = observer<NoteEditorProps>(function NoteEditor({
  isSidePanelOpen,
  getAvailableChips,
  goToMention,
  noteObservable,
  myAccount,
  inSidePanel,
}) {
  const showSkeleton = noteObservable && !noteObservable.isNoteContentDocumentLoaded;
  useTitle(noteObservable);

  if (!noteObservable) return null;
  return (
    <div className={fullHeight}>
      <NoteEditorTopBar note={noteObservable} inSidePanel={inSidePanel} />
      {showSkeleton && <NoteSkeleton />}
      {!showSkeleton && noteObservable && (
        <NoteObservableEditor
          autoFocus={!isSidePanelOpen}
          className={editorStyles}
          getAvailableChips={getAvailableChips}
          goToMention={goToMention}
          noteObservable={noteObservable}
          myAccount={myAccount}
          mode={NoteEditorMode.Editable}
        />
      )}
    </div>
  );
});

const fullHeight = css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  width: "100%",
});

// Note: left padding is always 16px larger than right padding due to the scrollbar.
// Only issue is on mobile where the scrollbar is overlay it's not part of the width calculations.
const editorStyles = css({
  "--extra-padding-left": `56px`,
  "--extra-padding-right": `56px`,
  background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)) 0 100%`,
  backgroundRepeat: "no-repeat",
  backgroundColor: "#fff",
  backgroundSize: "100% 30px, 100% 30px, 100% 12px, 100% 12px",
  backgroundAttachment: "local, local, scroll, scroll",
  boxSizing: "border-box",
  cursor: "text",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: `calc(100% - ${NOTE_EDITOR_TOP_BAR_HEIGHT})`,
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  // Consistent sizing whether the scrollbar is visible or not.
  scrollbarGutter: "stable",

  "--extra-padding-bottom": "200px",

  [mdsMediaQueries().rules.tablet]: {
    "--extra-padding-left": `40px`,
    "--extra-padding-right": `40px`,
    "--extra-padding-bottom": "100px",
  },

  [mdsMediaQueries().rules.phone]: {
    "--extra-padding-left": `16px`,
    "--extra-padding-right": `16px`,
    "--extra-padding-bottom": "100px",
  },
});

/** Preserve the default document title */
const defaultTitle = document.title;

/** Prefix document title with Note title */
const useTitle = (note: INoteObservable | undefined): void => {
  document.title = note ? `${note?.title} - Mem` : defaultTitle;
  useEffect(() => {
    return () => {
      document.title = defaultTitle;
    };
  }, []);
};
