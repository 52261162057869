import { useState, useCallback, FormEventHandler } from "react";

import { MdsTextFieldProps } from "@/design-system/components/text-field/MdsTextField";
import {
  OnBlur,
  OnChange,
  OnFocus,
  ReactBlurEvent,
  ReactChangeEvent,
  ReactFocusEvent,
  ReactKeyboardEvent,
} from "@/design-system/constants/handlers/types";
import { KeyboardCode } from "@/design-system/constants/keyboard/types";

interface UseMdsTextFieldInputChangeHandlersArgs
  extends Pick<
    MdsTextFieldProps,
    "onFocus" | "onBlur" | "onChange" | "onEnterKeyPress" | "onEscapeKeyPress" | "autoFocus"
  > {}

export const _useMdsTextFieldChangeHandlers = ({
  onFocus,
  onBlur,
  onChange,
  onEnterKeyPress,
  onEscapeKeyPress,
  autoFocus = false,
}: UseMdsTextFieldInputChangeHandlersArgs) => {
  const [internalIsActive, setInternalIsActive] = useState<boolean>(autoFocus);
  const [valueModified, setValueModified] = useState<boolean>(false);

  const onFocusHandler: OnFocus<ReactFocusEvent<HTMLInputElement>> = useCallback(
    event => {
      setInternalIsActive(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const onBlurHandler: OnBlur<ReactBlurEvent<HTMLInputElement>> = useCallback(
    event => {
      setInternalIsActive(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  const onChangeHandler: OnChange<ReactChangeEvent<HTMLInputElement>> = useCallback(
    event => {
      setValueModified(true);
      onChange?.(event);
    },
    [onChange]
  );

  const onKeyPressHandler: FormEventHandler<HTMLInputElement> = useCallback(
    (event: ReactKeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.code;
      if (keyCode === KeyboardCode.Enter) {
        onEnterKeyPress?.(event);
      }
      if (keyCode === KeyboardCode.Escape) {
        onEscapeKeyPress?.(event);
      }
    },
    [onEnterKeyPress, onEscapeKeyPress]
  );

  return {
    internalIsActive,
    onFocusHandler,
    onBlurHandler,
    onChangeHandler,
    onKeyPressHandler,
    valueModified,
  };
};
